import { handleActions } from 'redux-actions';

import { ticketResultInitialStatePlatform } from 'vokzal-platform/components/ticket_result/reducer';

let ticketResultInitialState = Object.assign({},  ticketResultInitialStatePlatform())

const ticketResultReducer = handleActions({}, ticketResultInitialState)

export default ticketResultReducer

