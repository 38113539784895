import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import i18n from 'vokzal-platform/i18n'

import { showContentLink } from 'vokzal-platform/managers/navigation'

import { fillScheduleGroupBusAction } from '../schedule_bus/action'

import PediaOrderBlockBusView from 'vokzal-platform/components/pedia/order_block_bus/view'

PediaOrderBlockBusView.propTypes = {
  scheduleGroup : PropTypes.string.isRequired,
  orderButtonTitle : PropTypes.string.isRequired,
  showOrderForm : PropTypes.bool.isRequired,
  onOrderClick : PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const orderButtonTitle = i18n.t('schedule_order');
  return { orderButtonTitle, show: state.step3_bus.show_modal }
}

const mapDispatchToProps = dispatch => {
  return {
    onOrderClick: (scheduleGroup) => {
      dispatch(fillScheduleGroupBusAction(scheduleGroup));
    }
  }
}


const PediaOrderBlockBusContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaOrderBlockBusView)

export default PediaOrderBlockBusContainer