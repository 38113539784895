import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import i18n from 'vokzal-platform/i18n';

import {formDataChangedAction, formInputCleanAction} from '../action'
import { showCountiesModalAction } from '../countries_modal/action'

import TextSelectView from 'vokzal-platform/components/step4/text_select/view';
import { FormSelectPropType } from '../../../../src/types/';

TextSelectView.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(FormSelectPropType).isRequired,
  label: PropTypes.string.isRequired,
  fieldsetClass: PropTypes.string,
  onSelectChange: PropTypes.func.isRequired
};

class GenderSelectContainer extends Component {
  componentDidMount() {
    // const { items, onExpand, urlWithParams, onDataLoaded, loaded } = this.props;
    // this.props.onNameChange(1, 2);
  }

  componentDidUpdate (prevProps) {
    // console.log('prevProps ** ', prevProps.userData);
    // this.props.onNameChange(11, 22);
    // if (prevProps.userData.fio !== this.props.segments || prevProps.departureDate !== this.props.departureDate) {
    //   this.props.onNameChange(11, 22);
    // }
  }

  render() {
    return <TextSelectView {...this.props} />;
  }
}

const mapStateToPropsGender = (state, ownProps) => {

  const p = state.step3_passengers.passengers;

  const name = 'gender';
  let value = p[ownProps.ticketNumber - 1][name];

  const words = p[ownProps.ticketNumber - 1]['fio'].split(" ");

  let words2 = words.filter(n => n);

  if ((words2.length === 3 || words2.length === 4) && p[ownProps.ticketNumber - 1]['fio_gender'] !== p[ownProps.ticketNumber - 1]['fio']) {

    const w = words2[1].slice(-1).toLowerCase();
    const o = words2[2].slice(-3).toLowerCase();
    const g = (w === 'а' || w === 'я') ? 'F' : 'M';

    value = (o === 'вич' && g === 'M') || (o === 'вна' && g === 'F') ? g : '';

    p[ownProps.ticketNumber - 1][name] = value;
    p[ownProps.ticketNumber - 1]['fio_gender'] = p[ownProps.ticketNumber - 1]['fio'];
  }

  return {
    name, value,
    values: [
      { title: '', value: '' },
      { title: i18n.t('gender_male'), value: 'M' },
      { title: i18n.t('gender_female'), value: 'F' },
    ],
    label: i18n.t('gender'),
  }
};

const mapStateToPropsDocumentType = (state, ownProps) => {

  const p = state.step3_passengers.passengers[ownProps.ticketNumber - 1];

  const name = 'document_type';
  const value = p[name];

  const values = (p.type === 'adult')
    ? state.step4_documents.document_types
    : state.step4_documents.document_types.filter(o =>  ['СР', 'ЗЗ', 'ЗП'].indexOf(o.value) !== -1);

  return {
    name, value,
    values,
    label: i18n.t('document_type'),
  }
};

const mapDispatchToPropsGender = dispatch => {
  return {
    onSelectChange: (ticket_number, value) => {
      dispatch(formDataChangedAction({ position: ticket_number, field: 'gender', value }));
    },
    onNameChange: (value) => {
      // dispatch для пола
      // console.log('onNameChange', ticket_number, value, 'ownprops === ', ownprops);
    },
  }
}

const mapDispatchToPropsDocumentType = dispatch => {
  return {
    onSelectChange: (ticket_number, value) => {
      if (value === 'ЗЗ') {
        dispatch(showCountiesModalAction({ ticket_number }))
      }

      dispatch(formDataChangedAction({ position: ticket_number, field: 'document_type', value }));

      dispatch(formDataChangedAction({position: ticket_number, field: 'document_number', value: ''}));
      dispatch(formInputCleanAction({position: ticket_number, field: 'document_number'}));
    },
  }
}


GenderSelectContainer = connect(
  mapStateToPropsGender,
  mapDispatchToPropsGender
)(GenderSelectContainer);

export default GenderSelectContainer;

export const DocumentTypeSelectContainer = connect(
  mapStateToPropsDocumentType,
  mapDispatchToPropsDocumentType
)(TextSelectView);

