import React from 'react';
import PropTypes from 'prop-types';

import { SeatNumbersKupePropType } from '../../../../src/types/';

import CabinPlace from '../../../../src/components/step3/cabin_place';
import CabinPlaceHorizontal from '../../../../src/components/step3/cabin_place_horizontal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMale, faFemale } from '@fortawesome/free-solid-svg-icons'
import {getSexInfo} from "../../../../src/utils/functions";
import i18n from 'vokzal-platform/i18n';
import CabinType from '../../../../src/components/step3/cabin_type';

const KupeCabinView = ({ carNumber, title, cabinInfo, sexInfo, secondFloor = 0, twoStorey, carSelected, kupeNumber, kupeMF, cabins, onSelectMF, selectedFloor }) => {

    let freeCupe = carSelected.female > 0 ? getSexInfo(carSelected, cabins[kupeNumber], twoStorey) : '';
    const mfSelect = freeCupe === 'free' && !kupeMF && title === kupeNumber && selectedFloor === secondFloor ? <CabinType/> : '';

    let icons = '';
    if (!sexInfo) {
    } else if (sexInfo === 'Ж') {
        icons = <div className="sex"><span className="female" data-title="Женское"><FontAwesomeIcon icon={faFemale}/></span></div>;
    } else if (sexInfo === 'М') {
        icons = <div className="sex"><span className="male" data-title="Мужское"><FontAwesomeIcon icon={faMale} /></span></div>
    } else if (sexInfo === 'С') {
        icons = <div className="sex"><span className="female" data-title="Смешанное"><FontAwesomeIcon icon={faFemale} /></span><span className="male" data-title="Смешанное"><FontAwesomeIcon icon={faMale} /></span></div>
    }

    const plusSeat = secondFloor === 1 ? 80 : 0;
    const secondFloorClass = twoStorey === 1 ? 'two-floor' : '';
    const firstKupeClass = title === 0 ? 'first-kupe' : '';
    const lastKupeClass = title === 8 || (title === 7 && twoStorey) ? 'last-kupe' : '';

    return (

    <div className={'kupe ' + secondFloorClass + ' ' + firstKupeClass + lastKupeClass}>

        <div className="cabin kupe-cabin">

            <div className="cabin-general">
                <CabinPlaceHorizontal carNumber={carNumber} seatNumber={cabinInfo.centerTopLower + plusSeat} type="center-top" isUpper={false}/>
                <CabinPlaceHorizontal carNumber={carNumber} seatNumber={cabinInfo.centerTopUpper + plusSeat} type="center-top" isUpper={true}/>
                {icons}
                <CabinPlaceHorizontal carNumber={carNumber} seatNumber={cabinInfo.centerBottomUpper + plusSeat} type="center-bottom" isUpper={true}/>
                <CabinPlaceHorizontal carNumber={carNumber} seatNumber={cabinInfo.centerBottomLower + plusSeat} type="center-bottom" isUpper={false}/>
            </div>
            <div className="cabin-title small">{i18n.t('car_map_kupe') + ' ' + (title + 1)}</div>
        </div>

        <div className="train-car-map-cabin train-car-select-seats-map-block d-flex justify-content-between align-items-center rounded mb-2 d-block d-lg-none">
            <div className="train-car-map-cabin-title small pl-3">{i18n.t('car_map_kupe') + ' ' + (title + 1)}</div>

            <div className="train-car-map-cabin-place-block train-car-map-cabin-center-place-block d-flex flex-column">
                <CabinPlace carNumber={carNumber} seatNumber={cabinInfo.centerTopLower + plusSeat} type="center-top" isUpper={false}/>
                <CabinPlace carNumber={carNumber} seatNumber={cabinInfo.centerTopUpper + plusSeat} type="center-top" isUpper={true}/>
                {icons}
                <CabinPlace carNumber={carNumber} seatNumber={cabinInfo.centerBottomUpper + plusSeat} type="center-bottom" isUpper={true}/>
                <CabinPlace carNumber={carNumber} seatNumber={cabinInfo.centerBottomLower + plusSeat} type="center-bottom" isUpper={false}/>
            </div>
        </div>
        { mfSelect }
    </div>
)};

KupeCabinView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  cabinInfo : SeatNumbersKupePropType,
};

module.exports = KupeCabinView;