// @flow

import { createAction } from 'redux-actions'

export const STEP2_BUSES_SEGMENTS_COMPLETE_ACTION = 'STEP2/SEARCH/BUSES/SEGMENTS'
export const step2BusesSegmentsCompleteAction     = createAction(STEP2_BUSES_SEGMENTS_COMPLETE_ACTION)

export const STEP2_BUSES_SEARCH_COMPLETE_ACTION   = 'STEP2/SEARCH/BUSES/COMPLETE'
export const step2BusesSearchCompleteAction       = createAction(STEP2_BUSES_SEARCH_COMPLETE_ACTION)

export const STEP2_BUSES_SEARCH_CLEANUP_ACTION    = 'STEP2/SEARCH/BUSES/CLEANUP'
export const step2BusesSearchCleanupAction        = createAction(STEP2_BUSES_SEARCH_CLEANUP_ACTION)