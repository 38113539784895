import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import BlogTitleView from 'vokzal-platform/components/blog/title/view'

BlogTitleView.propTypes = {
  type : PropTypes.oneOf(['single_post', 'posts', 'help_single_post']).isRequired,
  title : PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  let title = '';

  if (ownProps.type === 'single_post') {
    title = (state.blog_single_post.data) ? state.blog_single_post.data.title : null;
  } else if (ownProps.type === 'help_single_post') {
    title = (state.help.data) ? state.help.data.title : null;
  } else {
    title = state.blog_posts.title;
  }
  return { title }
};

const BlogPostsContainer = connect(
  mapStateToProps
)(BlogTitleView);

export default BlogPostsContainer