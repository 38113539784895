// @flow
import PropTypes from 'prop-types';
import { SearchResultBusPropType } from '../../../../src/types/';

import BusPricesView from 'vokzal-platform/components/step2/bus_prices/view'

BusPricesView.propTypes = {
  busItem: SearchResultBusPropType.isRequired
};

const BusPrices = BusPricesView

export default BusPrices