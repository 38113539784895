import React from 'react';

import SapsanCabin from '../../../../../src/components/step3/sapsan/base/sapsan_cabin';
import SapsanDoubleLuggageCabin from '../../../../../src/components/step3/sapsan/base/sapsan_double_luggage_cabin';
import SapsanWardrobeCabinWithSeats from '../../../../../src/components/step3/sapsan/base/sapsan_wardrobe_cabin_with_seats';
import SapsanCabinDelimiter from '../../../../../src/components/step3/sapsan/base/sapsan_cabin_delimiter';
import SapsanToilet from '../../../../../src/components/step3/sapsan/base/sapsan_toilet';
import LastochkaToilet from "../../lastochka/lastprem_w1_v2/view";
import SapsanChildrenCabin from '../../../../../src/components/step3/sapsan/base/sapsan_children_cabin';

const SapsanW10V2ClassMapView = ({ carNumber, cabins }) => (
  <div className="train-car-select-seats-map rounded pt-2">
    <SapsanDoubleLuggageCabin carNumber={carNumber} />

    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[0]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[1]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[2]} />

      <SapsanChildrenCabin carNumber={carNumber} cabinInfo1={cabins[3]} cabinInfo2={cabins[4]} cabinInfo3={cabins[5]}  cabinInfo4={cabins[6]} table1={true} />



      <SapsanCabin carNumber={carNumber} cabinInfo={cabins[7]} />
      <div className="table table-left"></div>
      <div className="table table-right"></div>
      <div className="clear"></div>
      <SapsanCabin carNumber={carNumber} cabinInfo={cabins[8]} />
      <SapsanCabin carNumber={carNumber} cabinInfo={cabins[9]} />
      <SapsanCabin carNumber={carNumber} cabinInfo={cabins[10]} />
      <SapsanCabin carNumber={carNumber} cabinInfo={cabins[11]} />
      <SapsanCabin carNumber={carNumber} cabinInfo={cabins[12]} />

  </div>
);

module.exports = SapsanW10V2ClassMapView;