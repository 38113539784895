// @flow
import { handleActions, combineActions } from 'redux-actions';

import { step3InitialState, step3PassengerInitialState } from './train_seats/reducer';
import { PASSENGERS_INCREMENT_ACTION, PASSENGERS_DECREMENT_ACTION } from './passengers_count_row/action';
import { SEAT_SELECT_ACTION, SEAT_DESELECT_ACTION, SEAT_CLEAR_ACTION, KUPE_SELECT_ACTION, SHOW_PRELOADER_ACTION, HIDE_PRELOADER_ACTION } from './action';
import { BUS_SEAT_SELECT_ACTION, BUS_SEAT_DESELECT_ACTION } from '../step3bus/action';
import { FORM_DATA_CHANGED_ACTION, FORM_DATA_VALIDATED_ACTION, FORM_DATA_CLEANUP_ACTION, FORM_INPUT_FOCUS_ACTION, FORM_FOCUS_LOST_ACTION, FORM_INPUT_CLEAN_ACTION  } from '../step4/action';

import { cloneByType, countByType } from '../../utils/passengers';


export const Step3PassengersReducer = handleActions({
  [combineActions(PASSENGERS_INCREMENT_ACTION,PASSENGERS_DECREMENT_ACTION)]: (state, action) => {

    if (action.meta.counter > 0) {
      let passenger = cloneByType(action.payload)

      if (passenger !== null) {
        state.passengers.push(passenger)
      }
    } else {
      const i = state.passengers.map((o, index) => (o.type === action.payload ? index : null)).reduce((a, b) => (Math.max(a,b)))
      state.passengers.splice(i, 1);

      if (action.payload === 'adult' && countByType(state.passengers, 'adult') < countByType(state.passengers, 'infant')) {
        const i = state.passengers.map((o, index) => (o.type === 'infant' ? index : null)).reduce((a, b) => (Math.max(a,b)))
        state.passengers.splice(i, 1);
      }
    }

    const p = state.passengers;
    const selected_count = countByType(p, 'adult') + countByType(p, 'child');

    const can_select_seat = state.selected_seats.length < selected_count;
    if (state.selected_seats.length > selected_count) {
      state.selected_seats.splice(0, 1);
    }

    return { ...state, can_select_seat };
  },
  [combineActions(SEAT_SELECT_ACTION, BUS_SEAT_SELECT_ACTION)]: (state, action) => {

    const p = state.passengers;
    const selected_count = countByType(p, 'adult') + countByType(p, 'child');

    let can_select_seat = state.selected_seats.length < selected_count;

    if (can_select_seat && state.selected_seats.indexOf(action.payload) === -1) {
      state.selected_seats.push(action.payload);
    }
    can_select_seat = state.selected_seats.length < selected_count;
    return { ...state, can_select_seat };
  },
  [combineActions(SEAT_DESELECT_ACTION, BUS_SEAT_DESELECT_ACTION)]: (state, action) => {
    let ax = -1;
    for(let i = 0, len = state.selected_seats.length; i < len; i++) {
      if (state.selected_seats[i].seatNumber === action.payload) {
        ax = i;
        break;
      }
    }
    state.selected_seats.splice(ax, 1);

    const can_select_seat = state.selected_seats.length < state.passengers.length;
    const select_mf = state.selected_seats.length === 0 ? '' : state.select_mf;
    return { ...state, can_select_seat, select_mf };
  },
  [SEAT_CLEAR_ACTION]: (state, action) => {
    return { ...state, selected_seats : [], can_select_seat : true, select_mf : '' };
  }, [KUPE_SELECT_ACTION]: (state, action) => {
    let select_mf = action.payload;
    return { ...state, select_mf };
  },
  [FORM_DATA_VALIDATED_ACTION]: (state, action) => {
    let passenger = state.passengers[action.payload.position - 1];
    passenger.validators[action.payload.field] = action.payload.valid;
    passenger.errors[action.payload.field] = action.payload.error;

    return { ...state }
  },
  [FORM_INPUT_CLEAN_ACTION]: (state, action) => {
    let passenger = state.passengers[action.payload.position - 1];
    // удалить
    delete passenger.validators[action.payload.field];
    delete passenger.errors[action.payload.field];

    return { ...state }
  },
  [FORM_FOCUS_LOST_ACTION]: (state, action) => {
    let passenger = state.passengers[action.payload.position - 1];
    passenger.focus = '';

    return { ...state }
  },
  [FORM_INPUT_FOCUS_ACTION]: (state, action) => {
    let passenger = state.passengers[action.payload.position - 1];
    passenger.focus = action.payload.field;

    return { ...state }
  },
  [FORM_DATA_CHANGED_ACTION]: (state, action) => {
    let passenger = state.passengers[action.payload.position - 1];
    passenger[action.payload.field] = action.payload.value;

    if (!passenger.counters.hasOwnProperty(action.payload.field)) {
      passenger.counters[action.payload.field] = 0;
    }
    passenger.counters[action.payload.field]++;

    return { ...state }
  },
  [FORM_DATA_CLEANUP_ACTION]: (state, action) => {
    let passenger = state.passengers[action.payload - 1];

    state.passengers[action.payload - 1] = cloneByType(passenger.type);

    return { ...state }
  },
}, {
  passengers: step3PassengerInitialState.passengers,
  selected_seats: step3PassengerInitialState.selected_seats,
  can_select_seat: true,
  select_mf: ''
})

export const Step3PreloaderReducer = handleActions({
  [combineActions(SHOW_PRELOADER_ACTION, HIDE_PRELOADER_ACTION)]: (state, action) => {
    return { ...state, show: action.meta.show }
  },
}, { show: step3InitialState.car_categories.length === 0 })