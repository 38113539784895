import React, { Component } from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types';
import { NewsPropType } from '../../../../src/types';

import { postStoreUrlAction } from '../../blog/single_post/action';

import NewsRowView from 'vokzal-platform/components/home/news_row/view'

NewsRowView.propTypes = {
  newsItem : NewsPropType.isRequired,
  onLinkClick : PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return { show : state.news.show, items : state.news.items, }
};

const mapDispatchToProps = dispatch => {
    return {
      onLinkClick : (item, event) => {
        dispatch(postStoreUrlAction(item.url))
      }
    }
};


const NewsRowContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsRowView);

export default NewsRowContainer