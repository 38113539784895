import { post_process_schedule, make_schedule_groups } from '../../../../src/api/fetch_pedia_station';

export const scheduleInitialStatePlatform = () => {
  let initialState = {}

  if (typeof pedia_station_data !== 'undefined') {

    initialState.schedule = post_process_schedule(pedia_station_data.schedule);
    initialState.schedule_groups = make_schedule_groups(pedia_station_data.station_id, initialState.schedule);
    initialState.loaded = true;
  } else if (typeof pedia_trains_list !== 'undefined') {
    initialState.schedule = post_process_schedule(pedia_trains_list);
    initialState.loaded = true;
  } else if (typeof pedia_train !== 'undefined') {
    initialState.schedule = post_process_schedule([pedia_train.train]);
    initialState.loaded = true;
  }

  return initialState;
}