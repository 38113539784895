import React from 'react';

import Smart from 'smart-plurals';
import i18n from 'vokzal-platform/i18n';

const BusPricesVIew = ({ busItem, abt = false }) => {
  let seats = null;
  if (busItem.hasOwnProperty('available_seats') && busItem.available_seats) {
    seats = busItem.available_seats;
    const russian = Smart.Plurals.getRule('ru');
    seats = i18n.t('remaining') + ' ' + seats + ' ' + russian(seats, ['место', 'места', 'мест']);
  }

  return (
    <div className="bus-prices d-flex justify-content-between align-items-center pl-2 pr-2 pt-1 pb-2 ml-md-5 mr-md-5">
      <div className="bus-prices-seats small font-light">{seats}</div>
      <div className="bus-prices-price small font-weight-bold font-medium-light">
          {abt ? '~' : ''}{busItem.price} &#8381;
      </div>
    </div>
  );
};

module.exports = BusPricesVIew;