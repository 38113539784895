import React from 'react';

const SeatedSidePlaceView = ({ carNumber, seatNumber, isFront, state, onSeatClick, className }) => {

  if (!className) {
    className = 'm-2';
  }

  className += (isFront) ? ' ' : ' flex-row-reverse';
  className += (state === 'busy' || state === 'selected') ? ' seat-' + state : '';
  className += (state === 'unavailable') ? ' seat-busy' : '';

  let seatInfo = seatNumber;
  if (state === 'busy') {
    seatInfo = <div className="car-state-icon car-state-icon-busy mt-1" />;
  } else if (state === 'selected') {
    seatInfo = <div className="car-state-icon car-state-icon-selected mt-1" />;
  }

  className += ' after-no';

  return (
    <div className={"train-car-map-place rounded text-center p-1 d-flex " + className} onClick={() => onSeatClick(carNumber, seatNumber, state)}>
      <div className="train-car-map-place-icon h-100" />
      <div className="train-car-map-place-title font-weight-medium small m-auto">{seatInfo}</div>
    </div>
  );
};

module.exports = SeatedSidePlaceView;