
export default function getMask(document) {
  let document_mask = undefined

  switch (document) {
    case "ПН":
      document_mask = "[00] [00] [000000]"
      break
    case "СР":
      document_mask = "[aaaaaaaa] № [000000]"
      break
    case "ЗЗ":
      document_mask = undefined
      break
    case "ЗП":
      document_mask = "[00] № [0000009999]"
      break
    case "ВБ":
      document_mask = "[AA] № [0000009999]"
      break
    case "ПМ":
      document_mask = "[AA] № [0000009999]"
      break
    default:
      document_mask = undefined
      console.log("unknown document type!")
      break
  }

  return document_mask
}