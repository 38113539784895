// @flow
import { handleActions } from 'redux-actions'
import {
  SET_STATION_FROM,
  SET_STATION_TO,
  SHOW_DATE_TO,
  SET_DATE_FROM,
  SET_DATE_TO,
  QUICK_SELECT,
  FILL_DATE_IF_NOT_SET,
  SET_GEO_LOCATION,
  STATIONS_BY_GEO_LOCATION,
  SET_STATION_FROM_INFO,
  SET_STATION_TO_INFO,
  SET_DATE_FROM_INFO,
  SET_QUICK_START,
  SET_STEP2_ACTION,
  SET_STEP3_ACTION,
  SET_STEP4_ACTION,
  SET_TYPE,
  SET_TYPE_DATA
} from './action'

import moment from 'moment'

import { StationEmpty } from '../../../types'

import { initialStatePlatform } from 'vokzal-platform/components/home/search_form/reducer';

let initialState = {
  from: StationEmpty,
  to: StationEmpty,
  date_from: moment().add(1, 'days'),
  from_info: StationEmpty,
  to_info: StationEmpty,
  date_from_info: moment().add(1, 'days'),
  date_to: null,
  show_date_from: true,
  show_date_to: false,
  show_add_date_to: true,
  inputs_disabled : true,
  geo_coords : null,
  geo_enabled: true,
  geo_fetched: false,
  quick_start: null,
  step2: null,
  step3: null,
  step4: null,
  type: null,
  type_data: {}
}

initialState = Object.assign({}, initialState, initialStatePlatform())


const SearchReducer = handleActions({
  [SET_STATION_FROM]: (state, action) => {
    return { ...state, from: action.payload }
  },
  [SET_STATION_TO]: (state, action) => {
    return { ...state, to: action.payload }
  },
  [SHOW_DATE_TO]: (state, action) => {
    return { ...state, show_date_to: true, show_add_date_to: false }
  },
  [SET_DATE_FROM]: (state, action) => {
    return { ...state, date_from: action.payload }
  },
  [SET_DATE_TO]: (state, action) => {
    return { ...state, date_to: action.payload }
  },
  [FILL_DATE_IF_NOT_SET]: (state, action) => {
    const date_from = (state.date_from) ? state.date_from :  action.payload;
    return { ...state, date_from }
  },
  [QUICK_SELECT]: (state, action) => {
    let new_state = {}
    if (action.payload.direction === 'from') {
        new_state = { from: action.payload.station }
    } else if (action.payload.direction === 'to') {
        new_state = { to: action.payload.station, to_info: action.payload.station }
    } else {
        new_state = { from: action.payload.station, from_info: action.payload.station }
    }
    return { ...state, ...new_state }
  },
  [SET_GEO_LOCATION]: (state, action) => {
    return {...state, geo : action.payload }
  },
  [STATIONS_BY_GEO_LOCATION]: (state, action) => {
    return {...state, from : action.payload.station }
  },
  [SET_STATION_FROM_INFO]: (state, action) => {
    return { ...state, from_info: action.payload }
  },
  [SET_STATION_TO_INFO]: (state, action) => {
    return { ...state, to_info: action.payload }
  },
  [SET_DATE_FROM_INFO]: (state, action) => {
    return { ...state, date_from_info: action.payload }
  },
  [SET_QUICK_START]: (state, action) => {
    return { ...state, quick_start: action.payload }
  },
  [SET_STEP2_ACTION]: (state, action) => {
    const step2 = action.payload.step2;
    return { ...state, step2};
  },
  [SET_STEP3_ACTION]: (state, action) => {
    const step3 = action.payload.step3;
    return { ...state, step3};
  },
  [SET_STEP4_ACTION]: (state, action) => {
    const step4 = action.payload.step4;
    return { ...state, step4};
  },
  [SET_TYPE]: (state, action) => {
    return { ...state, type: action.payload};
  },
  [SET_TYPE_DATA]: (state, action) => {
    return { ...state, type_data: action.payload};
  },
}, initialState)

export default SearchReducer