import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

const BootstrapInlineButton = (props) => (
    <div id={props.id} className={props.className} onClick={props.onClick}>
        {/*<i className="material-icons align-middle">{props.children}</i>*/}
        <div className="arrows">
            <span className="icon-right">
                <FontAwesomeIcon icon={faLongArrowAltRight} />
            </span>
            <span className="icon-left">
                <FontAwesomeIcon icon={faLongArrowAltLeft} />
            </span>
        </div>
    </div>
);

module.exports = BootstrapInlineButton;