// @flow
import React, { Component } from 'react';

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetch_popular_stations } from '../../../api/fetch_popular_stations';
import { popularStationsUrlWithParams } from '../../../utils/url_params';

import { StationPropType } from '../../../../src/types/';

import { quickSelectAction } from "../search_form/action"
import { hideQuickSelectFromAction, showQuickSelectToAction, hideQuickSelectToAction, showQuickSelectFromAction, fetchQuickSelectFromAction, fetchQuickSelectToAction } from "./action"

import { quickSelectResultLimiterPlatform, quick_select_from_enable_swiper, quick_select_to_enable_swiper } from 'vokzal-platform/components/home/quick_select_stations/manager'
import QuickSelectStationsView from 'vokzal-platform/components/home/quick_select_stations/view'

QuickSelectStationsView.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  stations: PropTypes.arrayOf(StationPropType).isRequired,
  onQuickSelectClick: PropTypes.func.isRequired
};

import { gaEvent } from 'vokzal-platform/ga'

class QuickSelectionContainer extends Component {

    state = { stationsWidth: 0 };
    updateDimensions() {
        let elmnt = document.getElementById(this.props.id);
        let w = 0;
        if (elmnt && elmnt.clientWidth > 0) {
            w = elmnt.clientWidth; // ширина блока
            if (elmnt.clientHeight > 0 && Math.abs(this.state.stationsWidth - w) > 100) {
                this.setState({ stationsWidth : w });
            }
        }
    }

  componentDidMount() {
    if (this.props.stations.length > 0) {
      (this.props.type === 'from') ? quick_select_from_enable_swiper() : quick_select_from_enable_swiper();
    }
  }

  componentDidUpdate() {
    if (this.props.visible && this.props.reference && this.props.stations.length === 0) {
      fetch_popular_stations(popularStationsUrlWithParams(this.props.reference.id), this.props.onQuickSelectLoad);
    }
      window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
      return <QuickSelectStationsView {...this.props}/>;
  }
}

const mapStateToPropsFrom = state => {
  return {
    id: "quick-select-from",
    type: "from",
    visible: state.quick_select_stations.from_show,
    reference: state.quick_select_stations.from_reference,
    stations: quickSelectResultLimiterPlatform(state.quick_select_stations.from_items)
  }
}

const mapStateToPropsTo = state => {
  return {
    id: "quick-select-to",
    type: "to",
    visible: state.quick_select_stations.to_show,
    reference: state.quick_select_stations.to_reference,
    stations: quickSelectResultLimiterPlatform(state.quick_select_stations.to_items)
  }
}

const mapDispatchToPropsFrom = dispatch => {
  return {
      onQuickSelectLoad: (timer, stations) => {
        dispatch(fetchQuickSelectFromAction(stations))
        quick_select_from_enable_swiper();
      },
      onQuickSelectClick: station => {
          dispatch(quickSelectAction({ station, direction: "from" }))
          dispatch(hideQuickSelectFromAction())
          dispatch(showQuickSelectToAction(station))
          gaEvent("Home", "Click on from", "Quick Select")
      }
  }
}

const mapDispatchToPropsTo = dispatch => {
  return {
      onQuickSelectLoad: (timer, stations) => {
        dispatch(fetchQuickSelectToAction(stations))
        quick_select_to_enable_swiper();
      },
      onQuickSelectClick: station => {
          dispatch(quickSelectAction({ station, direction: "to" }))
          dispatch(hideQuickSelectToAction())
          gaEvent("Home", "Click on to", "Quick Select")
      }
  }
}

export const QuickSelectionContainerFrom = connect(
  mapStateToPropsFrom,
  mapDispatchToPropsFrom
)(QuickSelectionContainer);


export const QuickSelectionContainerTo = connect(
  mapStateToPropsTo,
  mapDispatchToPropsTo
)(QuickSelectionContainer);