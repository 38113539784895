import queryString from 'query-string';

import moment from 'moment'
moment.locale('ru');
import {
    searchFormUrlParamsTrain,
    searchFormUrlParamsCarCategory,
    searchFormUrlToPassengersDataTrain
} from '../../../../src/utils/url_params'

export const initialStatePlatform = () => {

  let initialState = {}

  if (location.pathname.startsWith('/step2/alternative') && typeof train_search_form !== 'undefined') {
    const f = train_search_form.from;
    const t = train_search_form.to;
    initialState.from      = (typeof f !== 'undefined') ? { id : parseInt(f.station_id), station_name : f.name, city_name: f.city } : {};
    initialState.to        = (typeof t !== 'undefined') ? { id : parseInt(t.station_id), station_name : t.name, city_name: t.city } : {};
    initialState.date_from = moment(train_search_form.departureDate, 'DD.MM.YYYY');
  } else if (location.pathname.startsWith('/step')) {
    const queryObject = queryString.parse(location.search);
    const from = { id : parseInt(queryObject.fromCode), station_name : queryObject.from, city_name: queryObject.fromCodeFor };
    const to = { id : parseInt(queryObject.toCode),   station_name : queryObject.to,   city_name: queryObject.toCodeFor };
    const date_from = moment(queryObject.departureDate, 'DD.MM.YYYY');

    initialState.from      = from;
    initialState.to        = to;
    initialState.date_from = date_from;

    initialState.from_info      = from;
    initialState.to_info        = to;
    initialState.date_from_info = date_from;

  } else if (location.pathname.startsWith('/raspisanie') || location.pathname.startsWith('/confirmation')){

    const from = { id : parseInt(fromName.id), station_name : fromName.station, city_name: fromName.city_name  };
    const to = { id : parseInt(toName.id), station_name : toName.station, city_name: toName.city_name  };
    initialState.from      = from;
    initialState.to        = to;

    initialState.from_info      = from;
    initialState.to_info        = to;
    if(typeof departureDate !== 'undefined') {
      initialState.date_from = moment(departureDate, 'DD.MM.YYYY');
      initialState.date_from_info = moment(departureDate, 'DD.MM.YYYY');
     }
  }

  if (location.hash === '#geomsk') {
    initialState.geo_coords = { latitude : 55.674, longitude : 37.601 };
  } else if (location.hash === '#nogeo') {
    initialState.geo_enabled = false;
  }

  if( typeof fromName !== 'undefined' && initialState.from.city_name === undefined) {
    initialState.from.station_name = fromName.station;
    initialState.from.city_name = fromName.city;
  }
  if(typeof toName !== 'undefined' && initialState.to.city_name === undefined) {
    initialState.to.station_name = toName.station;
    initialState.to.city_name = toName.city;
  }

  if (Object.keys(initialState).length !== 0) {
    initialState.step2 = searchFormUrlParamsTrain(initialState);

    const queryObject = queryString.parse(location.search);
      if(typeof er !== 'undefined' ) {
          initialState.to.station_name = toName.station;
          initialState.to.city_name = toName.city;
      }
    if(queryObject.carCategory || (typeof fromName !== 'undefined' && typeof carCategory !== 'undefined' && typeof departureDate !== 'undefined')){
        const category = queryObject.carCategory ? queryObject.carCategory : carCategory;
        const s = queryObject.service ? queryObject.service : (typeof service !== 'undefined' ? service : null);
        let params = {
            search: initialState,
            step2_details: {
                train: {
                    number: queryObject.trainNumber ? queryObject.trainNumber : trainNumber,
                    er: queryObject.er ? queryObject.er : (typeof er !== 'undefined' ? er : null),
                    sapsan: queryObject.sapsan ? queryObject.sapsan : (typeof sapsan !== 'undefined' ? sapsan : null)
                }
            }
        };
        initialState.step3 = searchFormUrlParamsCarCategory(params, category, s);
    }
  }

    if (location.pathname.startsWith('/confirmation')) {

        if(typeof fromName !== 'undefined' && typeof carCategory !== 'undefined' && typeof departureDate !== 'undefined' && typeof trainNumber !== 'undefined'){

            let url_params = {};
            url_params.adultsCount = typeof adultsCount !== 'undefined' ? adultsCount : null;
            url_params.childsCount = typeof childsCount !== 'undefined' ? childsCount : null;
            url_params.infantsCount = typeof infantsCount !== 'undefined' ? infantsCount : null;

            url_params.er = typeof er !== 'undefined' ? er : null;
            url_params.upper = typeof upper !== 'undefined' ? upper : 0;
            url_params.lower = typeof lower !== 'undefined' ? lower : 0;
            url_params.seatsRange = typeof seatsRange !== 'undefined' ? seatsRange : null;
            url_params.seatsList = typeof seatsList !== 'undefined' ? seatsList : null;
            url_params.sex = typeof sex !== 'undefined' ? sex : 'С';
            url_params.departureDate = typeof departureDate !== 'undefined' ? departureDate : null;
            url_params.fromCode = fromName.id;
            url_params.toCode = typeof toName !== 'undefined' ? toName.id : null;

            url_params.trainNumber = trainNumber;
            if (typeof sapsan !== 'undefined') {
                url_params.sapsan = sapsan;
            }

            url_params.carCategory = carCategory;
            url_params.carNumber = typeof carNumber !== 'undefined' ? carNumber : null;


            /*     const p = state.step3_passengers;
                 const s = state.search;
                 const bus = state.step2_details_bus.bus;

                 const seats = (p.selected_seats.length > 0 && p.passengers.length > 0)
                     ? p.selected_seats.map((item) => (item.seatNumber)).reduce((accumulator, currentValue) => (accumulator + ',' + currentValue))
                     : '';

                 let url_params = {};
                 url_params.adultsCount = countByType(p.passengers, 'adult');
                 url_params.childsCount = countByType(p.passengers, 'child');

                 url_params.seatsRange = seats;
                 url_params.seatsList = seats;
                 url_params.busID = bus.id;
                 url_params.departureDate = s.date_from.format('DD.MM.YYYY');
                 url_params.fromCode = s.from.id;
                 url_params.toCode = s.to.id;

                 url_params.fromBusStop = bus.departure.id;
                 url_params.toBusStop = bus.arrival.id;
                 url_params.busNumber = bus.run_number_url;*/



            initialState.step4 = searchFormUrlToPassengersDataTrain(null, url_params);
        }
    }

  return initialState;
}