export const step2AlternativeInitialStatePlatform = () => {
  let initialState = {}

  if (typeof train_search_form !== 'undefined' && train_search_form) {
    initialState.data_loaded = true;
    initialState.train_search_form = train_search_form;
  }

  if (typeof bus_search_form !== 'undefined' && bus_search_form) {
    initialState.data_loaded = true;
    initialState.bus_search_form = bus_search_form;
  }

  return initialState;
}
