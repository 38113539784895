import React from 'react';

import StrijCabin from '../../../../../src/components/step3/strij/base/strij_cabin';
import StrijCabinDelimiter from '../../../../../src/components/step3/strij/base/strij_cabin_delimiter';
import StrijToiletsCabin from '../../../../../src/components/step3/strij/base/strij_toilets_cabin';

const StrijEconomMapView = ({ carNumber, cabins }) => (
  <div className="train-car-select-seats-map rounded pt-2">

      <StrijCabin carNumber={carNumber} cabinInfo={cabins[0]} />
      <StrijCabin carNumber={carNumber} cabinInfo={cabins[1]} />
      <StrijCabin carNumber={carNumber} cabinInfo={cabins[2]} />
      <StrijCabin carNumber={carNumber} cabinInfo={cabins[3]} />
      <StrijCabin carNumber={carNumber} cabinInfo={cabins[4]} />
      <StrijCabin carNumber={carNumber} cabinInfo={cabins[5]} />
      <StrijCabin carNumber={carNumber} cabinInfo={cabins[6]} />
      <StrijCabin carNumber={carNumber} cabinInfo={cabins[7]} />

      <StrijCabinDelimiter />

      <StrijCabin carNumber={carNumber} cabinInfo={cabins[8]} />

      <StrijToiletsCabin />
  </div>
);

module.exports = StrijEconomMapView;