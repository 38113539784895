import React from 'react';

class StationModalRowView extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.props.onClick(this.props);
  }

  render() {
    return (
      <div className="station-details-item list-group-item d-flex flex-column p-0 pt-3" data-state={this.props} onClick={this.handleClick}>
        <div className="d-flex justify-content-between">
          <h4 className="mb-1 text-truncate">{this.props.station_name}</h4>
          <h5 className="country mb-1 ml-3">{this.props.country}</h5>
        </div>

        <div className="d-flex justify-content-between">
          <h6 className="station m-0 text-truncate small">{this.props.city_name}</h6>
          <h6 className="region m-0 ml-3 text-truncate font-light small">{this.props.region}</h6>
        </div>
      </div>
    );
  }
}

module.exports = StationModalRowView;