import React from 'react';
import DatesSelectItem from '../../../../src/components/step2/dates_select_item';

const DatesSelectView = ({ dates, swiperEnabled }) => (
  <div className="dates-select-block p-2 mb-4">
    <div className="dates-select-title h4 font-medium-light mb-0 pr-1 pl-1">Дата отправления</div>
    <div className={"dates-select d-flex font-medium-light justify-content-between" + ((swiperEnabled) ? ' swiper-container' : '')}>
      <div className="swiper-wrapper">
        {dates.map((d, index) => (
          <DatesSelectItem key={index} date={d} swiperEnabled={swiperEnabled} />
         ))}
      </div>
    </div>
  </div>
);

module.exports = DatesSelectView;