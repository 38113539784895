import queryString from "query-string";
import moment from "moment";
import {cloneByType} from "../../../../src/utils/passengers";

export const step3TrainSeatsInitialStatePlatform = () => {
  const queryObject = queryString.parse(location.search);
  let step3InitialState = {};
  let active = 0;
  if (typeof category !== 'undefined'  && category) {
    step3InitialState.car_categories = category.map((c, index) => {
      if (!active && queryObject.carCategory === c.type) {
        active = index;
      }
      c.fakeID = index;
      return c;
    });
    step3InitialState.car_category_selected = step3InitialState.car_categories[active];
    step3InitialState.car_selected = step3InitialState.car_category_selected.cars[0];

    if (step3InitialState.car_selected.hasOwnProperty('freeSeats') && Array.isArray(step3InitialState.car_selected.freeSeats)) {
      step3InitialState.car_selected.freeSeats = step3InitialState.car_selected.freeSeats.map(el => Number(el).toString());
    }

    step3InitialState.show = true;
  }
  return step3InitialState;
}

export const step3PassengersTrainSeatsInitialStatePlatform = () => {
  const queryObject = queryString.parse(location.search);
  let initialState = {};
  let selected_seats = [];
  let passengers = [cloneByType('adult')];
  if (queryObject.seatsList) {
    const seats = queryObject.seatsList.split(',');
    seats.forEach((item) => {
      const isUpper = (queryObject.carCategory === 'П' || queryObject.carCategory === 'К') && !(item % 2);
      selected_seats.push({carNumber: parseInt(queryObject.carNumber), seatNumber: parseInt(item), isUpper});
    });
    for (let i = 1; i < queryObject.adultsCount; i++) {
      passengers.push(cloneByType('adult'));
    }
    for (let i = 0; i < queryObject.childsCount; i++) {
      passengers.push(cloneByType('child'));
    }
    for (let i = 0; i < queryObject.infantsCount; i++) {
      passengers.push(cloneByType('infant'));
    }
    initialState.checkUpper = true;
  }
  initialState.selected_seats = selected_seats;
  initialState.passengers = passengers;

  return initialState;
}