import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { extractParentHelpLink } from 'vokzal-platform/managers/navigation'

import { helpStoreUrlAction } from '../action';

import HelpSinglePostContentView from 'vokzal-platform/components/help/single_post_content/view'

HelpSinglePostContentView.propTypes = {
  title : PropTypes.string.isRequired,
  content : PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  url: state.help.url,
  title: state.help.data.title,
  content: state.help.data.content
});

const mapDispatchToProps = dispatch => (
  {
    onBack: url => {
      const permalink = extractParentHelpLink(url);

      dispatch(helpStoreUrlAction(permalink));
    },

  }
);


const HelpSinglePostContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpSinglePostContentView);

export default HelpSinglePostContentContainer