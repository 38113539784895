export default {

  menu_search_tickets: 'Найти билеты',
  menu_my_order: 'Мои заказы',
  menu_my_passengers: 'Мои пассажиры',
  menu_ticket_refund: 'Возврат билета',
  menu_customer_support: 'Клиентская поддержка',
  menu_help: 'Справочная',
  menu_news: 'Новости',

  about: 'О компании',
  help: 'Помощь',
  blog: 'Блог',
  copyright: '© ООО «Вокзал.РУ» 2009 - 2023',
  inn: 'ИНН: 9705098457/КПП: 770501001/ОГРН: 1177746532908',
  rzd_no: 'Не является ресурсом ОАО «РЖД»',
  rzd1: 'Данные, используемые на сайте, включая стоимость электронных ж/д билетов, а также расписание поездов взяты из официальных источников. Ж/д билеты предоставляются партнерами, в том числе с использованием веб-систем ООО «РЖД - Цифровые Пассажирские Решения» и ООО «УФС». Стоимость билетов указана с учетом сервисного сбора. Итоговая стоимость отображена на экране подтверждения покупки.',
  rzd2: 'Вы находитесь на сайте субагента, который осуществляет оформление электронных проездных и перевозочных документов и услуг от имени железнодорожных перевозчиков на основании договора № ФПК-22-316 от 27.12.2022 года, заключенный между ООО «РЖД - Цифровые пассажирские решения» и АО «ФПК», и Договор № ИМ-314 о предоставлении услуг с использованием Веб-системы от 29.12.2017 года, заключенный между ООО «РЖД - Цифровые пассажирские решения» и ООО «УФС».',

  any: 'Любой',

  departure: 'Отправление',

  search_title: 'Электронные билеты на поезд здесь',
  search_button: 'НАЙТИ БИЛЕТЫ',

  station_from: 'Откуда',
  station_to: 'Куда',

  date_from: 'Дата туда',
  date_to: 'Дата обратно',

  date_to_add: "Добавить обратную дату",

  select_destination: "Куда едем?",

  trip_date: "Дата поездки",

  ad_bus: "Новинка! Автобусные билеты на Vokzal.ru",

  for_meet: 'Встречающим',
  ticket_refund: 'Возврат билета',
  encyclopedia_of_passengers: 'Энциклопедия пассажира',

  popular_cities: "Популярные направления",
  popular_routes: "Популярные маршруты",
  news: "Новости",
  trains: "Поезда",
  buses: "Автобусы",
  vokzals_stations: "Вокзалы и станции",

  goodbye: "Счастливого пути!",
  got_questions: "Возникли вопросы?",
  contact_us: "Свяжитесь с сотрудником отдела поддержки:",
  phone: "+7 (495) 740-4000",
  phone_min: "(495) 740-4000",
  support: "Отдел поддержки:",
  email_support: "support@vokzal.ru",

  departure_time_msk_arrival_time_local: 'Отправление по московскому, прибытие по местному времени.',

  car_soft: 'МЯГК',
  car_lux: 'СВ',
  car_kupe: 'Купе',
  car_platskart: 'Плац',
  car_seat: 'Сид',
  car_shared: 'Общий',

  car_sapsan_econom: 'Эконом',
  car_sapsan_econom_plus: 'Эконом+',
  car_sapsan_business: 'Бизнес класс',
  car_sapsan_first: 'Первый класс',
  car_sapsan_bistro: 'Вагон-бистро',
  car_sapsan_meeting_room: 'Купе-переговорная',
  car_sapsan_base: 'Базовый',
  car_sapsan_kupe_sut: 'Купе-сьют',
  car_sapsan_family: 'Семейный',

  whos_going: 'Кто поедет?',
  whos_going_adults: 'Взрослых билетов',
  whos_going_children: 'Детских 5-10 лет',
  whos_going_children_bus: 'Детских до 12 лет',
  whos_going_infants: 'Детских до 5-ти лет',

  select_seats: 'Уточните места и класс обслуживания',
  train_car: 'Вагон',

  upper_seats: 'Верхние полки',
  lower_seats: 'Нижние полки',

  toilet: 'Туалет',
  conductor_place: 'Купе проводника',
  smoking_area: 'Место для курения',

  car_map_kupe: 'Купе',
  car_map_platskart: 'Плацкарта',

  car_soft_name: 'Мягкий',
  car_sv_name: 'Люкс',
  car_kupe_name: 'Купе',
  car_platskart_name: 'Плацкарт',
  car_seat_name: 'Сидячий',
  car_shared_name: 'Общий',
  first_floor: 'Нижний этаж',
  second_floor: 'Верхний этаж',

  korridor: 'Корридор',

  seats: 'Места',
  seat: 'Место',
  seat_upper: 'Верхнее',
  seat_lower: 'Нижнее',

  book_seats: 'Далее',

  remaining: 'Осталось',

  step4_requirements: 'В соответствии с приказом Министерства транспорта РФ от 19.07.2012 №243 с 1 декабря 2013 при оформлении электронных билетов необходимо указывать пол пассажира, а также дату его рождения.',
  person_agreement: 'Нажимая на кнопку "Оформить заказ", вы даете согласие на обработку своих персональных данных',

  rub: 'руб.',
  for: 'Для',

  fio: 'ФИО(полностью)',
  gender: 'Пол',
  gender_male: 'Мужской',
  gender_female: 'Женский',
  birth_date: 'Дата рождения',
  birth_city: 'Город рождения',
  document_type: 'Тип документа',
  document_number: 'Номер документа',
  document_country: 'Страна выдачи',

  ticket: 'Билет',
  tickets: 'Билеты',
  ticket_adult: 'Взрослый',
  ticket_child: 'Детский',
  ticket_infant: 'Детский',

  registration: 'Регистрация и оплата',
  pasengers: 'Пассажиры и места',
  choose_transport: 'Выберите транспорт',

  train: "Поезд",
  bus: "Автобус",

  confirmation_contact_details: 'Контакты для подтверждения заказа',
  confirmation_email: 'Почта',
  confirmation_mobile_phone: 'Телефон',
  confirmation_attention: 'Оповестить меня об изменениях в графике движения поезда',
  email: 'Адрес электронной почты',
  password: 'Пароль',
  renew: 'Восстановить',
  renew_password: 'Восстановить пароль',
  login: 'Войти',
  register: 'Зарегистрироваться',
  save: 'Сохранить',
  password_text: 'не менее 8 символов, включая строчные, заглавные буквы и цифры',
  register_agreement: 'Нажимая кнопку, вы даете согласие на обработку своих персональных данных',
  profile_agreement: 'Нажимая кнопку "Сохранить", вы даете согласие на обработку своих персональных данных',
  submit: 'Отправить',

  total_price: 'Общая стоимость', // Общая стоимость
  price_description: 'Точная стоимость при бронировании',
  tax_included: 'Включая налоги и сервисные сборы',

  payment_type: 'Способ оплаты',
  make_order: 'Оформить заказ',

  clear_all: 'Стереть всe',
  remove: 'Удалить',

  payment_intellectmoney: 'VISA, MasterCard, МИР',
  payment_euroset: 'Наличными',
  payment_webmoney: 'Webmoney',
  payment_yandex: 'Яндекс.Деньги',

  payment_info_intellectmoney: 'Через сайт процессингового центра IntellectMoney',
  payment_info_euroset: 'В салонах Евросеть и Связной',

  payment_text_intellectmoney: 'Передача данных на авторизационный сервер Банка производится в зашифрованном виде с соблюдением всех необходимых мер безопасности.',
  payment_text_euroset: 'Мы зарегистрируем ваш заказ и выдадим его номер, который необходимо предъявить для оплаты кассиру любого салона Евросеть или Связной и уточнить, что вы хотите оплатить ЖД билеты с сайта vokzal.ru. После оплаты Вы получите маршрут-квитанцию с реквизитами поездки и инструкциями по проезду.',

  select_country: 'Выберите страну выдачи документа',
  remove_passenger: 'Удалить',
  free: 'Бесплатно',

  tarif: 'Тариф',

  tickets_reserved: 'Заказ сделан, осталось его оплатить!',
  minutes_short: 'мин.',
  confirmation_agreement: 'Нажимая оплатить заказ вы принимаете условия договора оферты и подтверждаете итоговую сумму к оплате, включая все налоги и сборы в размере ',
  pay_order: 'Оплатить заказ',
  confirmation_hurry: 'Поторопитесь, мы Вас ждем!',
  confirmation_oferta: 'Оплатив заказ, вы, тем самым, соглашаетесь с условиями договора оферты, политикой конфиденциальности и сервисным сбором, включённым в цену.',

  bus_map_notification: 'Схема автобуса не совпадает с реальной рассадкой пассажиров',

  auto_seats: 'Автоматический выбор мест',
  auto_sheme: 'Точная схема мест для данного вагона, к сожалению, отсутствует.',
  auto_select: 'Места будут подобраны автоматически.',

  reserve_timeout: "Время резервирования истекло. Попробуйте зарезервировать билет снова",
  order_number: "Ваш заказ",
  order_price: "Сумма к оплате:",

  sapsan_wardrobe: "гардероб",
  sapsan_luggage: "багаж",

  sapsan_service_area: "Служебный отсек",
  sapsan_bar: "Бар",
  sapsan_tambur: "Тамбур",
  sapsan_minibar: "Мини-бар",
  sapsan_kupe_placeholder: "Первый класс 1В",
  sapsan_meeting_room_placeholder: "Купе-переговорная 1Р",

  strij_bathroom: "Душ и туалет",

  lastochka_service_area: "Служебный отсек",

  schedule_title: "Расписание поездов, следующих через",
  schedule_order: "Купить билет",
  select_data_and_schedule_order: "Выбрать дату и купить билет",
  select_departure_date: "Выбрать дату отправления",

  schedule_bus_title: "Расписание автобусов, следующих через",

  change_search_params: "Вернуться к поиску",
  stations_catalog: "Справочник станций",


  tickets_return: "Возврат билетов",
  tickets_return_code: "14-ти значный номер заказа",
  tickets_return_mobile_phone: "Телефон, указанный в заказе",
  check_order: "Проверить заказ",

  process_ticket_return: "Выполнить возврат",
  new_order: "Сделать новый заказ",
  ordering: "Бронируем билеты",

  check_passenger_data: 'Пожалуйста, проверьте правильность ввода данных о пассажирах',
  start_again: 'Пожалуйста, попробуйте ещё раз',

  document_date_error: "ОШИБКА!",

  document_number_info_ПН: 'Введите значение номера документа по примеру: 45 11 951214',
  document_number_info_СР: 'Введите римские цифры латинскими буквами. № и дефисы вводить не нужно. <br /> Пример: VII-АП-627818',
  document_number_info_ПМ: 'Серия: две римские цифры (в латинском регистре), номер: семь цифр. Пример: II6278187',
  document_number_info_ВП: 'Серия: две буквы кириллицей, номер: семь цифр. Пример: АА6278187',

  validation_error_birthday: 'Введите корректную дату рождения в формате ДД.ММ.ГГГГ Пример: 10.07.1982',
  validation_error_birthday_adult: 'Пассажиру на дату поездки должно быть более 10 лет',
  validation_error_birthday_child: 'Возраст ребенка не должен превышать 10 лет на дату поездки',
  validation_error_birthday_infant: 'Возраст ребенка не должен превышать 5 лет на дату поездки',
  validation_error_birthday_now: 'Дата рождения не должна быть больше текущей',
  validation_error_birthday_passport: 'До 14 лет не может быть паспорта',

  validation_error_fio_cyrillic: 'Укажите фамилию, имя и отчество на кириллице',
  validation_error_passport_rf: 'Введите серию и номер паспорта (10 цифр)',
  validation_error_passport_zagran: 'Введите серию и номер паспорта (9 цифр)',

  validation_error_child_document: 'Введите номер документа, Римские цифры вводите английскими буквами. Символ «№» вводить не нужно. Пример: VII-АП-627818',

  review_clean: 'Чистота',
  review_comfort: 'Комфорт',
  review_service: 'Обслуживание',
  review_price_quality: 'Соотношение цена-качество',
  review_general: 'Общее впечатление о поезде',
  rating: 'Рейтинг',

}