import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from "prop-types";
import BootstrapLabeledInput from '../../base/bootstrap_labeled_input';
import i18n from "../../../i18n";

const RenewModalView = ({ show, onCloseClick, onClick, text, className, title, email, previous_email, email_error, error, onEmailChanged, onSubmit, onDataLoaded, onError, success }) => {

    return (
        <div>
            <div className={className} onClick={() => onClick()}>
                {text}
            </div>

            <Modal show={show} animation={false} dialogClassName="auth-modal" backdropClassName="show resp-search-modal">

                <Modal.Header>
                    <Modal.Title id="contained-modal-title-lg" componentClass="h5">{title}</Modal.Title>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseClick}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>

                <Modal.Body bsClass="modal-body pt-0">

                    {success ? <p className="success-message">{success}</p> : null}

                    <span className="label">{i18n.t('email')}</span>
                    <BootstrapLabeledInput fieldsetClass={email_error ? "input-invalid" : ""}
                                           name="email" value={email} required={true}
                                           onChange={(value, inputElement) => onEmailChanged(value, email, inputElement)} />
                    {email_error ? <span className="label error">{email_error}</span> : null}
                    {error ? <span className="label error">{error}</span> : null}
                    <div className="standart-button centered mb-3 mt-4" onClick={() => onSubmit(email, onDataLoaded, onError)}>
                        <span>{i18n.t('renew')}</span>
                    </div>

                </Modal.Body>

            </Modal>
        </div>
    )};

RenewModalView.propTypes = {
    show: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
};

module.exports = RenewModalView;




