// @flow
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

import { setDateFromAction, setDateFromInfoAction } from '../../home/search_form/action';
import { step2TrainsSearchCleanupAction} from '../../step2/trains_search_result/action';
import { step2BusesSearchCleanupAction } from '../../step2/buses_search_result/action';

import { step2AlternativeChangeDateAction } from '../alternative/action';

import DatesSelectItemView from 'vokzal-platform/components/step2/dates_select_item/view'

DatesSelectItemView.propTypes = {
  date: momentPropTypes.momentObj.isRequired,
  weekday: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  holiday: PropTypes.bool.isRequired,
  month: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  swiperEnabled: PropTypes.bool.isRequired,
  onDateClick: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {

  const d = ownProps.date;

  const weekday = d.format('dd');
  const day = d.format('D');
  const month  = d.format('MMM');

  const holiday = (d.day() === 6) || (d.day() === 0);

  const selected = d.isSame(state.search.date_from, 'day');

  return { holiday, weekday, day, month, selected }
}

const mapDispatchToProps = dispatch => {
  return {
    onDateClick: d => {
      dispatch(step2AlternativeChangeDateAction(d.format('DD.MM.YYYY')));
      dispatch(setDateFromAction(d));
      dispatch(setDateFromInfoAction(d));
      dispatch(step2TrainsSearchCleanupAction());
      dispatch(step2BusesSearchCleanupAction());
    }
  }
}

const DatesSelectItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DatesSelectItemView)

export default DatesSelectItemContainer