import React from 'react';

import { Modal } from 'react-bootstrap';

import TrainDetailsBody from '../../../../src/components/step2/train_details_body';

const TrainDetailsView = ({ show, trainType, onCloseClick }) => (
  <Modal show={show} animation={false} dialogClassName="m-0 h-100 resp-search-modal train-details-modal" backdropClassName="show resp-search-modal">
    <Modal.Header bsClass="modal-header pt-2 pb-0">
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseClick}>
        <span aria-hidden="true">&times;</span>
      </button>
    </Modal.Header>
    <Modal.Body bsClass="modal-body pt-0">

      <TrainDetailsBody />
    </Modal.Body>
  </Modal>
);

module.exports = TrainDetailsView;