import moment from 'moment'

import arrCountries from './countries'

import {
  searchCountryRequestAction,
  searchCountryResponseAction
} from '../components/step4/countries_modal/action'

export const searchCounties = function (dispatch, searchString) {
  const requested_at = moment();
  dispatch(searchCountryRequestAction({input_value: searchString, requested_at}));
  let counties = arrCountries.filter((obj) => ( obj.title.toLowerCase().indexOf(searchString.toLowerCase()) === 0 ));
  dispatch(searchCountryResponseAction({input_value: searchString, requested_at, counties}))
}
