import { transform_document_types, transform_payment_types } from '../../../../src/api/fetch_document_types';

export const initialStatePlatform = () => {

  let initialState = {}

  if (typeof document_types !== 'undefined') {
    initialState.document_types = transform_document_types(document_types);
    initialState.payment_types = transform_payment_types(payment_types);
    initialState.stop_buy = stop_buy;
  }

  return initialState;
}