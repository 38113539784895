import { handleActions } from 'redux-actions'
import { newsInitialStatePlatform } from 'vokzal-platform/components/home/news/reducer';

import { HOME_SHOW_NEWS, HOME_FETCH_NEWS } from './action'

let homeNewsInitialState = { items: [], show : false };

homeNewsInitialState = Object.assign({}, homeNewsInitialState, newsInitialStatePlatform())

const NewsReducer = handleActions({
    [HOME_FETCH_NEWS]: (state, action) => {
        return {...state, items : action.payload};
    },
}, homeNewsInitialState)

export default NewsReducer