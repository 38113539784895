// @flow
import PropTypes from 'prop-types';
import { SearchResultTrainPropType, Step2UiFlagsPropType } from '../../../../src/types';

import { connect } from 'react-redux'


import TrainRowView from 'vokzal-platform/components/step2/train_row/view'

TrainRowView.propTypes = {
  trainItem : SearchResultTrainPropType.isRequired,
  uiFlags : Step2UiFlagsPropType,
  onTrainClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({})


const TrainRowContainer = connect(
  mapStateToProps
)(TrainRowView)

export default TrainRowContainer