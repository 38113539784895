// @flow
import moment from 'moment'
moment.locale('ru')

import { combineReducers } from 'redux'

import MainReducer from '../components/reducer'
import AboutReducer from '../components/home/about/reducer'
import HomeExtraTextReducer from '../components/home/extra_text/reducer'
import HeadMenuReducer from '../components/shared/menu/reducer'
import HeadMenuPediaReducer from '../components/shared/menu_pedia/reducer'
import SearchReducer from '../components/home/search_form/reducer'
import QuickSelectStationsReducer from '../components/home/quick_select_stations/reducer'
import StationModalReducer from '../components/home/station_modal/reducer'
import DatesModalReducer from '../components/home/dates_modal/reducer'
import OfferModalReducer from '../components/confirmation/offer_modal/reducer'
import PersonModalReducer from '../components/step4/person_modal/reducer'
import LoginModalReducer from '../components/shared/login_modal/reducer'
import RegisterModalReducer from '../components/shared/register_modal/reducer'
import RenewModalReducer from '../components/shared/renew_modal/reducer'
import ContentTreeReducer from '../components/home/content_tree/reducer'
import TrainsReducer from '../components/home/trains/reducer'
import StationsReducer from '../components/home/stations/reducer'
import PopularCitiesReducer from '../components/home/popular_city/reducer'
import NewsReducer from '../components/home/news/reducer'
import FooterLinksReducer from '../components/home/footer_links/reducer'
import IndicatorReducer from '../components/home/indicator/reducer'

import PediaStationReducer from '../components/pedia/station/reducer'
import PediaStationBusReducer from '../components/pedia/station_bus/reducer'
import PediaRegionReducer from '../components/pedia/region/reducer'
import PediaRegionMapReducer from '../components/pedia/region_map/reducer'
import PediaRailroadReducer from '../components/pedia/railroad/reducer'
import PediaRailroadDetailsReducer from '../components/pedia/railroad_details/reducer'
import PediaRailroadMapReducer from '../components/pedia/railroad_map/reducer'
import PediaStationsListReducer from '../components/pedia/stations_list/reducer'
import PediaScheduleReducer from '../components/pedia/schedule/reducer'
import PediaScheduleBusReducer from '../components/pedia/schedule_bus/reducer'
import PediaRaspisanieReducer from '../components/pedia/raspisanie/reducer'
import PediaRaspisanieBusReducer from '../components/pedia/raspisanie_bus/reducer'
import PediaTrainsListReducer from '../components/pedia/trains_list/reducer'
import PediaTrainReducer from '../components/pedia/train/reducer'
import PediaBusReducer from '../components/pedia/bus/reducer'
import PediaTabGroupReducer from '../components/pedia/tab_group/reducer'

import PediaSalepointsReducer from '../components/pedia/salepoints/reducer'

import TicketReturnFormReducer from '../components/ticket_return/return_form/reducer'

import BlogPostsReducer from '../components/blog/posts/reducer'
import BlogSinglePostReducer from '../components/blog/single_post/reducer'

import HelpReducer from '../components/help/reducer'

import TestWdReducer from '../components/test_wd/reducer'

// import StepInfoReducer from '../components/shared/step_info/reducer'

import {
    Step2Reducer,
    Step2PreloaderReducer,
    Step2BusReducer
} from '../components/step2/reducer'

import Step2AlternativeReducer from '../components/step2/alternative/reducer'
import Step2TabsReducer from '../components/step2/search_result_tabs/reducer'

import TrainDetailsReducer from '../components/step2/train_details/reducer'
import BusDetailsReducer from '../components/step2/bus_row/reducer'

import { Step3PassengersReducer, Step3PreloaderReducer } from '../components/step3/reducer'
import { Step3TrainSeatsReducer } from '../components/step3/train_seats/reducer'
import { Step3BusSeatsReducer } from '../components/step3bus/bus_seats/reducer'
import { Step3BusReducer } from '../components/step3bus/reducer'
import { Step4PassengerDataReducer } from '../components/step4/reducer'
import { Step4DocumentsReducer } from '../components/step4/user_list/reducer'
import { Step4ContactsReducer } from '../components/step4/contact_details/reducer'
import { ConrimationPageReducer, ConrimationPreloaderReducer } from '../components/confirmation/reducer'

import CountriesModalReducer from '../components/step4/countries_modal/reducer'

import UserReducer from '../components/user/reducer'
import ReviewReducer from '../components/user/review/reducer'
import ReviewsReducer from '../components/reviews/reducer'
import TicketResultReducer from "../components/ticket_result/reducer";
import AdminReducer from '../components/admin/reducer'


export default combineReducers({
    main: MainReducer,
    about: AboutReducer,
    home_extra_text: HomeExtraTextReducer,
    menu: HeadMenuReducer,
    menu_pedia: HeadMenuPediaReducer,
    search: SearchReducer,
    quick_select_stations: QuickSelectStationsReducer,
    station_modal: StationModalReducer,
    dates_modal: DatesModalReducer,
    offer_modal: OfferModalReducer,
    person_modal: PersonModalReducer,
    login_modal: LoginModalReducer,
    register_modal: RegisterModalReducer,
    renew_modal: RenewModalReducer,
    countries_modal: CountriesModalReducer,
    content_tree: ContentTreeReducer,
    trains: TrainsReducer,
    stations: StationsReducer,
    popular_cities: PopularCitiesReducer,
    news: NewsReducer,
    footer_links: FooterLinksReducer,
    indicator: IndicatorReducer,
    // step_info: StepInfoReducer,
    step2: Step2Reducer,
    step2_alternative: Step2AlternativeReducer,
    step2_details: TrainDetailsReducer,
    step2_preloader: Step2PreloaderReducer,
    step2_tabs: Step2TabsReducer,
    step2_bus: Step2BusReducer,
    step2_details_bus: BusDetailsReducer,
    step3_passengers: Step3PassengersReducer,
    step3_categories: Step3TrainSeatsReducer,
    step3_bus_seats: Step3BusSeatsReducer,
    step3_bus: Step3BusReducer,
    step3_preloader: Step3PreloaderReducer,
    step4_passenger_data: Step4PassengerDataReducer,
    step4_documents: Step4DocumentsReducer,
    step4_contacts: Step4ContactsReducer,
    confirmation: ConrimationPageReducer,
    confrmation_preloader: ConrimationPreloaderReducer,

    pedia_stations_list: PediaStationsListReducer,
    pedia_station: PediaStationReducer,
    pedia_station_bus: PediaStationBusReducer,
    pedia_region: PediaRegionReducer,
    pedia_region_map: PediaRegionMapReducer,
    pedia_railroad: PediaRailroadReducer,
    pedia_railroad_details: PediaRailroadDetailsReducer,
    pedia_railroad_map: PediaRailroadMapReducer,
    pedia_schedule: PediaScheduleReducer,
    pedia_schedule_bus: PediaScheduleBusReducer,
    pedia_raspisanie: PediaRaspisanieReducer,
    pedia_raspisanie_bus: PediaRaspisanieBusReducer,
    pedia_trains_list: PediaTrainsListReducer,
    pedia_train: PediaTrainReducer,
    pedia_bus: PediaBusReducer,
    pedia_tab_group: PediaTabGroupReducer,
    pedia_salepoints: PediaSalepointsReducer,

    ticket_return: TicketReturnFormReducer,

    blog_posts: BlogPostsReducer,
    blog_single_post: BlogSinglePostReducer,

    help: HelpReducer,
    ticket_result: TicketResultReducer,

    test_wd: TestWdReducer,

    user: UserReducer,
    review: ReviewReducer,
    reviews: ReviewsReducer,
    admin: AdminReducer,
    stats: AdminReducer,
})