import { createAction } from 'redux-actions'

export const LOAD_PEDIA_RAILROAD              = 'PEDIA/RAILROAD/LOAD'
export const load_pedia_railroad_action       = createAction(LOAD_PEDIA_RAILROAD);

export const HIDE_PRELOADER_ACTION            = 'PEDIA/PRELOADER/HIDE'
export const hidePreloaderAction              = createAction(HIDE_PRELOADER_ACTION, (show) => show, () => ({ show : false }))

export const RAILROAD_URL_STORE_ACTION        = 'PEDIA/RAILROAD/URL/STORE'
export const railroadUrlStoreAction           = createAction(RAILROAD_URL_STORE_ACTION)

export const RAILROAD_FETCH_COMPLETE_ACTION   = 'PEDIA/RAILROAD/FETCH/COMPLETE'
export const railroadFetchCompleteAction      = createAction(RAILROAD_FETCH_COMPLETE_ACTION)