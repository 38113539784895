// @flow
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { showMenuLink } from 'vokzal-platform/managers/navigation'

import { HeadMenuPropType } from '../../../../src/types';

import HeadMenuView from 'vokzal-platform/components/shared/menu/view'

HeadMenuView.propTypes = {
  showMenu: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(HeadMenuPropType).isRequired,
  stepNumber: PropTypes.number,
  onMenuClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => {

  const showMenu = state.main.application_type === 'web';

  return { showMenu, items : state.menu.items }
}

const mapDispatchToProps = dispatch => {
  return {
    onMenuClick: menuItem => {
      showMenuLink(menuItem);
    }
  }
}

const HeadMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeadMenuView)

export default HeadMenuContainer