import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import { PediaSalePointPropType } from '../../../types';

import PediaSalepointsItemView from 'vokzal-platform/components/pedia/salepoints_item/view'

PediaSalepointsItemView.propTypes = {
  
};

const mapStateToProps = state => {

  return {  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}


const PediaSalepointsItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaSalepointsItemView)

export default PediaSalepointsItemContainer