// @flow
import PropTypes from 'prop-types';
import { SeatNumbersSeatedPropType } from '../../../../../types/index';

import SapsanMeetingRoomView from 'vokzal-platform/components/step3/sapsan/base/sapsan_meeting_room/view'

SapsanMeetingRoomView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  cabinInfo1 : SeatNumbersSeatedPropType.isRequired,
  cabinInfo2 : SeatNumbersSeatedPropType.isRequired,
};

const SapsanMeetingRoom = SapsanMeetingRoomView

export default SapsanMeetingRoom