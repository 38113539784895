import React from 'react';

import SeatedCabinPlace from '../../../../../../src/components/step3/seated_cabin_place';
import SapsanWardrobe from '../../../../../../src/components/step3/sapsan/base/sapsan_wardrobe';
import SapsanToilet from '../../../../../../src/components/step3/sapsan/base/sapsan_toilet';

const SapsanWardrobeCabinWithToiletAndSeatsView = ({ carNumber, wardrobeTitle, cabinInfo, autoHeightWardrobe, autoHeightToilet }) => (
  <div className="sapsan-wardrobe-cabin d-flex justify-content-between mb-2">

    <SapsanWardrobe title={wardrobeTitle} autoHeight={autoHeightWardrobe} />

    <div className="d-flex flex-column">
      <div className="train-car-map-seated-cabin d-flex justify-content-between mb-2">
        <div className="train-car-map-seated-cabin-place-block d-flex">
          { (cabinInfo.rightSide !== null)   ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.rightSide.number}   isFront={cabinInfo.rightSide.isFront} />   : null }
          { (cabinInfo.rightWindow !== null) ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.rightWindow.number} isFront={cabinInfo.rightWindow.isFront} /> : null }
        </div>
      </div>

      <SapsanToilet autoHeight={autoHeightToilet} />
    </div>

  </div>
);

module.exports = SapsanWardrobeCabinWithToiletAndSeatsView;