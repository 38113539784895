import { handleActions } from 'redux-actions'

import {
  ADMIN_SET_VALUE,
  ADMIN_REVIEWS_FETCH_COMPLETE_ACTION,
  ADMIN_REVIEW_DELETE_ACTION,
  ADMIN_STATS_FETCH_COMPLETE_ACTION
} from '../../components/admin/action'

const UserReducer = handleActions({
  [ADMIN_SET_VALUE]: (state, action) => {
    return { ...state, ...action.payload  }
  },
  [ADMIN_REVIEWS_FETCH_COMPLETE_ACTION]: (state, action) => {
    const reviews = action.payload.response.reviews;
    return {...state, reviews, reviews_loaded: true  }
  },
  [ADMIN_REVIEW_DELETE_ACTION]: (state, action) => {
    const reviews = state.reviews.filter(
        (item) => (item.id !== action.payload)
    );
    return {...state, reviews  }
  },
  [ADMIN_STATS_FETCH_COMPLETE_ACTION]: (state, action) => {
    const stats = action.payload.response.result;
    const users = action.payload.response.users;
    const reviews = action.payload.response.reviews;
    return {...state, stats, stats_loaded: true  }
  },

}, {
  reviews: {},
  reviews_loaded: false,
  loaded: false,
  message: '',
  stats: {},
  stats_loaded: false,
})

export default UserReducer
