import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import i18n from 'vokzal-platform/i18n';
import ErrorMessage from "../../step2/view";
import { getCookie } from "../../../../src/utils/functions";

class ErrorMessageView extends Component {
    render () {
        const { message, canGoBack, info, step2, page = '' } = this.props;

        // if (page && window.location.hostname != 'test2.vokzal.ru') {
        //     const goalParams = {clientId: getCookie('_ym_uid')};
        //     // yaCounter582565.reachGoal(`"${page}"`, goalParams);
        // }


        let messageFormat = message.replace('&rarr;', '→');
        messageFormat = messageFormat.split('→');
        let message1 = '';
        let message2 = '';
        if (messageFormat.length === 2) {
            message1 = messageFormat[0];
            message2 = messageFormat[1];
        } else {
            message1 = message;
        }
        const elements = info ? Object.keys(info).map((item) => {
            return (
                <li key={item} className="item">
                    <a href={info[item]}>{item}</a>
                </li>
            );
        }) : '';

        return (
            <div className="content-w error-message-block">
                <div className="error-message text-center mb-4">
                    {message1}
                    <a href='/'>{ page === 'confirmation' ? i18n.t('start_again') : ''}</a>
                    {message2 ?
                        <span className="arrow">→</span>
                        : null}
                    {message2}
                </div>
                {elements.length > 0 ?
                    <div className="error-message text-center mb-4">
                        <p>Есть прямые поезда по этому маршруту на соседние даты: </p>
                        <ul className="list-group">
                            { elements }
                        </ul>
                    </div>
                    : null}
                {
                    (canGoBack)
                        ?
                        <div className="error-message-button">
                            <div className="back-button text-center font-weight-medium text-uppercase rounded mb-2 mr-2 ml-2 p-3">
                                <div className="back-button-label">
                                    <Link to="/">{i18n.t('change_search_params')}</Link>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        );
    }
}

module.exports = ErrorMessageView;