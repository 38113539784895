import {handleActions} from 'redux-actions'

import {
    SHOW_LOGIN_MODAL_ACTION,
    HIDE_LOGIN_MODAL_ACTION
} from './action'

const LoginModalReducer = handleActions({
    [SHOW_LOGIN_MODAL_ACTION]: (state, action) => {
        return {...state, show: action.meta.show}
    },
    [HIDE_LOGIN_MODAL_ACTION]: (state, action) => {
        return {...state, show: action.meta.show}
    },
}, {show: false})

export default LoginModalReducer