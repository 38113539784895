import React from 'react';

import i18n from 'vokzal-platform/i18n';

import { FioInput, DateInput, BirthCityInput, PassportInput, EmailInput, PhoneInput } from '../../../../src/components/step4/text_input';
import DateInputError from '../../../../src/components/step4/date_input_error';
import DocumentHelper from '../../../../src/components/step4/document_helper';
import CountryTextInput from '../../../../src/components/step4/country_text_input';
import { GenderSelect, DocumentTypeSelect } from '../../../../src/components/step4/text_select';
import {DeleteForever, ArrowDownward} from '@material-ui/icons';

const UserFormView = ({ type, ticketNumber, ticketPrice, priceDiapason, seat, userData, isCleanupButton, stopBuy, onCleanupClick, onDeleteClick, isBus }) => {

  let seatInfo = null;
  if (seat) {
    seatInfo = <div className="place-number">
                 <div className="text-uppercase font-weight-bold font-medium-light">{i18n.t('seat')} {seat.seatNumber}</div>
                 <div className="small font-light">
                   {isBus ? null : (seat.isUpper ? i18n.t('seat_upper') : i18n.t('seat_lower'))}
                 </div>
               </div>
  }

  let buy = <div className="user-form-header-title text-uppercase font-medium-light">
          {i18n.t('ticket')} {ticketNumber}. <br className="d-md-none"/>
          {i18n.t('ticket_' + type)}
          {stopBuy}
      </div>;
  if (stopBuy === true) {
      buy = <div className="user-form-header-title text-uppercase font-medium-light error-message-buy">
          Извините, сервис временно не работает.
      </div>
  }

    if (priceDiapason.hasOwnProperty('upper') && priceDiapason.hasOwnProperty('lower')) {
        if (seat.isUpper) {
            ticketPrice = priceDiapason.upper
        } else {
            ticketPrice = priceDiapason.lower
        }
        if (type === 'child') {
            ticketPrice = Math.round(ticketPrice / 2);
        }
    }

  return (
    <div className="user-form">

        <div className="row m-side-0">
            <div className="col-80">
                <div className="user-form-header d-flex">
                    {buy}
                    {seatInfo}
                </div>

                <div className="user-form-body border-top-0">
                    <div className="font-medium-light font-weight-medium small">{i18n.t('for')}</div>

                    <div className="row user-form-body-block pb-2 pt-2">
                        <div className="col-md-7 pr-md-0">
                            <FioInput ticketNumber={ticketNumber} />
                            <div className="form-inline">
                                <GenderSelect fieldsetClass="col-6" ticketNumber={ticketNumber} />
                                <DateInput fieldsetClass="col-6" ticketNumber={ticketNumber} />
                                <DateInputError ticketNumber={ticketNumber} />
                            </div>
                        </div>
                        <div className="col-md-5 pl-md-0">
                            {/*<BirthCityInput ticketNumber={ticketNumber} />*/}
                            <DocumentTypeSelect ticketNumber={ticketNumber} />
                            <PassportInput ticketNumber={ticketNumber} className="mb-md-0"/>
                            <DocumentHelper ticketNumber={ticketNumber} />
                        </div>
                        <div className="col-md-12">
                            <CountryTextInput ticketNumber={ticketNumber} />
                        </div>

                    {/*    <div className="col-md-7 pr-md-0">
                            <EmailInput ticketNumber={ticketNumber} />
                        </div>
                        <div className="col-md-5 pl-md-0">
                            <PhoneInput ticketNumber={ticketNumber} />
                        </div>*/}
                    </div>
                </div>
            </div>
            <div className="col-15">

                <div className="user-form-footer">
                    <div className="d-none d-md-flex flex-column justify-content-between vertical-line">
                        <div className="user-form-footer-circle circle-left" />
                        <div className="user-form-footer-center ">
                            <span className="user-form-footer-dashed-line align-middle d-inline-block" />
                        </div>
                        <div className="user-form-footer-circle circle-right" />
                    </div>


                    <div className="user-form-footer-details-price h4 font-weight-bold font-medium-light text-uppercase mb-0 d-none d-md-block">
                        {(ticketPrice) ? ticketPrice + ' ' + i18n.t('rub') : i18n.t('free')}
                    </div>

                    <div className="user-form-footer-details-clear font-weight-medium small d-none d-md-block" onClick={() => { (isCleanupButton) ? onCleanupClick(ticketNumber) : onDeleteClick(ticketNumber) }}>
                        { (isCleanupButton) ? i18n.t('clear_all') : i18n.t('remove_passenger') }
                        {/*<i className="material-icons">delete_forever</i>*/}
                        <DeleteForever />
                    </div>




                    <div className="d-flex justify-content-between d-md-none">
                        <div className="user-form-footer-circle circle-left" />
                        <div className="user-form-footer-center w-100">
                            <span className="user-form-footer-dashed-line w-100 align-middle d-inline-block" />
                        </div>
                        <div className="user-form-footer-circle circle-right" />
                    </div>

                    <div className="user-form-footer-details border-top-0 d-flex justify-content-between pl-3 pr-3 pb-3 pt-2 d-md-none">
                        <div className="user-form-footer-details-price h4 font-medium-light text-uppercase mb-0">
                            {(ticketPrice) ? ticketPrice + ' ' + i18n.t('rub') : i18n.t('free')}
                        </div>

                        <div className="user-form-footer-details-clear d-flex align-items-center font-weight-medium small" onClick={() => { (isCleanupButton) ? onCleanupClick(ticketNumber) : onDeleteClick(ticketNumber) }}>
                            { (isCleanupButton) ? i18n.t('clear_all') : i18n.t('remove_passenger') }
                            {/*<i className="material-icons">delete_forever</i>*/}
                            <DeleteForever />
                        </div>
                    </div>

                </div>

            </div>
        </div>




      <div className="user-form-separator">
        {/*<i className="material-icons">arrow_downward</i>*/}
          <ArrowDownward />
      </div>
    </div>
  );
}

module.exports = UserFormView;