// @flow
import React from 'react';

import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import TicketReturnView from 'vokzal-platform/components/ticket_return/view'

TicketReturnView.propTypes = {
  errorMessage: PropTypes.string,
};

const mapStateToProps = state => {

    return {
      errorMessage: (state.ticket_return.fetch_error) ? state.ticket_return.fetch_error.message : null,
    }
}

const TicketReturnContainer = connect(
    mapStateToProps
)(TicketReturnView)

export default TicketReturnContainer