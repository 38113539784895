import {connect} from 'react-redux'
import PersonModalView from 'vokzal-platform/components/step4/person_modal/view'
import {hidePersonModalAction, showPersonModalAction} from "../../step4/person_modal/action";

const mapStateToProps = state => {
    return {
        show: state.person_modal.show
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCloseClick: () => {
            dispatch(hidePersonModalAction());
        },
        onClick: () => {
            dispatch(showPersonModalAction())
        },
    }
};

const PersonModalContainter = connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonModalView)

export default PersonModalContainter