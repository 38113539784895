import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import { secondsToTime } from '../../../utils/date_utils';

import { decrementTimerAction } from '../action';

import ReserveTimerView from 'vokzal-platform/components/confirmation/reserve_timer/view'

ReserveTimerView.propTypes = {
  showTimer : PropTypes.bool.isRequired,
  elapsedTime : PropTypes.string,
};


class ReserveTimerContainter extends Component {

  state = { intervalID: null };

  componentDidMount() {
    if (this.props.onDecrementTimer && this.state.intervalID === null) {
      let intervalID = setInterval(this.props.onDecrementTimer, 1000);
      this.setState({ intervalID });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalID);
    this.setState({ intervalID : null });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.showTimer && this.state.intervalID) {
      clearInterval(this.state.intervalID);
      this.setState({ intervalID : null });
    }
  }

  render() {
    return <ReserveTimerView {...this.props} />;
  }
}


const mapStateToProps = state => {

  const showTimer = state.confirmation.timer > 0;
  let elapsedTime = '';

  if (showTimer) {
    const timeObj = secondsToTime(state.confirmation.timer);
    elapsedTime = timeObj.m + ':' + timeObj.s;
  }

  return {
    showTimer, elapsedTime
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDecrementTimer : () => {
      dispatch(decrementTimerAction());
    }
  }
}


ReserveTimerContainter = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReserveTimerContainter)

export default ReserveTimerContainter