import { connect } from 'react-redux'

import { searchFormUrlParamsTrain } from '../../../utils/url_params';

import { perform_search } from '../../../../src/components/home/quick_select_button/action';
import QuickSelectButton from 'vokzal-platform/components/home/quick_search_button/view';

import { gaEvent } from 'vokzal-platform/ga'

const mapStateToProps = state => {
    return {
        urlParams : searchFormUrlParamsTrain(state.search),
        searchFormState : state.search
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSearchButtonClick: (searchFormState) => {
            dispatch(perform_search(searchFormState));
            gaEvent("Home", "Click", "Search")
        }
    }
};

const QuickSelectButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(QuickSelectButton);

export default QuickSelectButtonContainer