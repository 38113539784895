import React, {Fragment, lazy, Suspense} from 'react';

import HeadMenu from '../../../src/components/shared/menu';
import { Step3PreLoader } from '../../../src/components/shared/preloader';

import StepInfo from '../../../src/components/shared/step_info';

import { PassengersCountTrain } from '../../../src/components/step3/passengers_count';
import TrainSeats from '../../../src/components/step3/train_seats';
const OrderButton = lazy(() => import('../../../src/components/step3/order_button'));
const UfsLogo = lazy(() => import('../../../src/components/shared/ufs_logo'));


const Step3View = () => (
    <Fragment>
          <div className="step3 gradient-background pb-4">
                <HeadMenu stepNumber={3} />

                <StepInfo stepNumber={3}/>

                <PassengersCountTrain />

                <Step3PreLoader />

                <TrainSeats />

              <Suspense fallback={<div>Загрузка...</div>}>
                  <OrderButton />
              </Suspense>

              <Suspense fallback={<div>Загрузка...</div>}>
                  <UfsLogo className="pt-4" />
              </Suspense>
          </div>
    </Fragment>
);

module.exports = Step3View;