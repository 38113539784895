// @flow
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment'
moment.locale('ru')

import DatesSelectView from 'vokzal-platform/components/step2/dates_select/view'

import { dates_select_enable_swiper } from 'vokzal-platform/components/step2/dates_select/manager'


DatesSelectView.propTypes = {
  dates: PropTypes.arrayOf(momentPropTypes.momentObj).isRequired,
  swiperEnabled: PropTypes.bool.isRequired
};

const mapStateToProps = state => {

  const swiperEnabled = true;

  const d = (swiperEnabled) ? [...new Array(90).keys()] : [...new Array(7).keys()];

  let dates = d.map(i => (moment().add(i, 'days')));

  if (swiperEnabled) {
    const diff = state.search.date_from.diff(moment(), 'days');
    dates_select_enable_swiper(diff - 2);
  }

  return { dates, swiperEnabled }
}

const mapDispatchToProps = dispatch => {
  return {}
}

const DatesSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DatesSelectView)

export default DatesSelectContainer