import React from 'react';
import QuickSelectButton from '../../../../src/components/home/quick_select_button/container';

const QuickSelectStationsView = ({ id, type, visible, stations, onQuickSelectClick }) => {
    let elmnt = document.getElementById(id);
    let w = 0;
    let sum = 0;
    if (elmnt && elmnt.clientWidth > 0) {
        w = elmnt.clientWidth; // ширина блока
    }

    if (!visible) {
        return null;
    }

    return (
        <div id={id} className={"quick-select-stations quick-select-stations-"+type+" swiper-container"}>
            <div className="swiper-wrapper">
            {
                stations.map(function (m, index) {
                    m.key = index;
                    sum = sum + m.station_name.length * 6.5 + 20;

                    return sum > w ? null : <QuickSelectButton {...m} onClick={() => onQuickSelectClick(m)}/>;
                })
            }
            </div>

            <div className="swiper-button-prev swiper-button-disabled pt-2">
                <div className="font-medium-light text-center">...</div>
            </div>
            <div className="swiper-button-next swiper-button-disabled pt-2">
                <div className="font-medium-light text-center">...</div>
            </div>

            <div className="swiper-pagination" />
        </div>
    );
};

module.exports = QuickSelectStationsView;