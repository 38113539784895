import React from 'react';
import {Link} from "react-router-dom";

import SectionRow from '../base/section_row/view';

const NewsRowView = ({ newsItem, onLinkClick }) => (
    <SectionRow className="col-md-4 news-row">
        <div className="news-date font-light small">{newsItem.created}</div>
        <Link to={newsItem.url} onClick={(e) => { onLinkClick(newsItem, e) }}>{newsItem.title}</Link>
    </SectionRow>
);

module.exports = NewsRowView;