// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import PediaRegionView from 'vokzal-platform/components/pedia/region/view'
import {fetch_pedia_region} from "../../../api/fetch_pedia_region";
import {hidePreloaderAction, regionFetchCompleteAction} from "./action";
import { stationUrlStoreAction } from '../station/action';

import { PediaStationsGroupItemPropType } from '../../../types';

PediaRegionView.propTypes = {
  loaded: PropTypes.bool.isRequired,
  urlWithParams: PropTypes.string,
  title : PropTypes.string.isRequired,
  country : PropTypes.string.isRequired,
  groups : PropTypes.arrayOf(PediaStationsGroupItemPropType).isRequired,
  onLinkClick : PropTypes.func.isRequired,
};

class PediaRegionContainer extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      fetch_pedia_region(this.props.urlWithParams, this.props.onDataLoaded);
    }
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    return <PediaRegionView {...this.props} />;
  }
}


const mapStateToProps = state => {

     return {
       loaded: state.pedia_region.loaded,
       urlWithParams: state.pedia_region.urlWithParams,
       title: state.pedia_region.title,
       country: state.pedia_region.country,
       groups: state.pedia_region.groups,
    }
}

const mapDispatchToProps = dispatch => {

    return {
      onDataLoaded : (time_spent, response) => {
        dispatch(hidePreloaderAction());
        dispatch(regionFetchCompleteAction({ time_spent, response }));
      },
      onLinkClick : (item, event) => {
        dispatch(stationUrlStoreAction(item.url));
      }
    }
}


PediaRegionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PediaRegionContainer)

export default PediaRegionContainer