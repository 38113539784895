import { handleActions } from 'redux-actions'

import { busInitialStatePlatform } from 'vokzal-platform/components/pedia/bus/reducer';

import { LOAD_PEDIA_BUS_LIST, BUS_URL_STORE_ACTION, BUS_FETCH_COMPLETE_ACTION } from './action';

const defaultState = {
  loaded : false, currentTab: 'info', urlWithParams: null, image: { caption: "default" },
}

const initialState = Object.assign({}, defaultState, busInitialStatePlatform())

const PediaBusReducer = handleActions({
    [LOAD_PEDIA_BUS_LIST]: (state, action) => {
      return {...state}
    },
    [BUS_FETCH_COMPLETE_ACTION]: (state, action) => {
      const loaded = true;

      const title = action.payload.response.train.title;
      const subTitle = action.payload.response.train.ab;

      const route = action.payload.response.route;
      const train = action.payload.response.train;

      return {...state, loaded, title, subTitle, train, route }
    },
    [BUS_URL_STORE_ACTION]: (state, action) => {
      let new_state = {}
      if (state.urlWithParams !== action.payload) {
        new_state = defaultState;
      }
      new_state.urlWithParams = action.payload;

      return {...state, ...new_state}
    },
}, initialState)

export default PediaBusReducer