import React from 'react';

const SeatedCabinPlaceView = ({ carNumber, seatNumber, isFront, state, onSeatClick, className }) => {

  if (!className) {
    className = 'm-2';
  }

  className += (isFront) ? ' train-car-map-place-front' : ' train-car-map-place-back';
  className += (state === 'busy' || state === 'selected') ? ' seat-' + state : '';
  className += (state === 'unavailable') ? ' seat-busy' : '';

  let seatInfo = seatNumber;
  if (state === 'busy') {
    seatInfo = <div className="car-state-icon car-state-icon-busy" />;
  } else if (state === 'selected') {
    seatInfo = <div className="car-state-icon car-state-icon-selected" />;
  }

  className += ' after-no';

  return (
    <div className={"train-car-map-place rounded text-center pt-2 " + className} onClick={() => onSeatClick(carNumber, seatNumber, state)}>
      <div className="train-car-map-place-title font-weight-medium small">
        {seatInfo}
      </div>
    </div>
  );
};

module.exports = SeatedCabinPlaceView;