import { make_station_groups, make_features } from '../../../../src/api/fetch_pedia_region';

export const regionInitialStatePlatform = () => {
  let initialState = {}

  if (typeof pedia_region !== 'undefined') {
    initialState.currentTab = 'info';

    initialState.loaded = true;

    initialState.title = pedia_region.title;
    initialState.country = pedia_region.country;
    initialState.stations = pedia_region.stations;
    initialState.groups = make_station_groups(pedia_region.stations);
    initialState.features = make_features(pedia_region.stations);
  }

  return initialState;
}