import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'vokzal-platform/i18n';

import { SeatNumbersPlatskartPropType } from '../../../../src/types/';

import PlatskartCabin from '../../../../src/components/step3/platskart_cabin';


const PlatskartMapView = ({ carNumber, cabins }) => (
    <div>
        <div className="train-car-select-seats-map-horizontal row">
            <div className="info">
                <p className="toilet">{i18n.t('toilet')}</p>
                <p className="conductor">
                    {i18n.t('conductor_place').replace(" ", "\n")}
                </p>
            </div>
            {
                cabins.map((cabin, index) => (
                    <PlatskartCabin key={index} carNumber={carNumber} title={i18n.t('car_map_platskart') + ' ' + (index + 1)} cabinInfo={cabin} />
                ))
            }
            <div className="info">
                <p className="toilet">{i18n.t('toilet')}</p>
            </div>
        </div>

        <div className="train-car-select-seats-map rounded p-2 d-block d-lg-none">
            <div className="train-car-map-service-block d-flex align-items-end flex-column">
                <div className="train-car-select-seats-map-block train-car-map-text train-car-map-toilet rounded small text-center w-40 p-1 mb-2">{i18n.t('toilet')}</div>
                <div className="train-car-select-seats-map-block train-car-map-text train-car-map-conductor-place rounded small text-capitalize text-center w-60 mb-2 p-1">
                    {i18n.t('conductor_place').replace(" ", "\n")}
                </div>
            </div>
            {
                cabins.map((cabin, index) => (
                    <PlatskartCabin key={index} carNumber={carNumber} title={i18n.t('car_map_platskart') + ' ' + (index + 1)} cabinInfo={cabin} />
                ))
            }
            <div className="train-car-map-service-block-bottom d-flex justify-content-between">
                <div className="train-car-select-seats-map-block train-car-map-text train-car-map-smoking-area rounded small text-center w-50 pt-3 pl-4 pr-4 pb-3 invisible">{i18n.t('smoking_area')}</div>
                <div className="train-car-select-seats-map-block train-car-map-text train-car-map-toilet rounded small text-capitalize text-center w-35">
                    {i18n.t('toilet')}
                </div>
            </div>
        </div>
    </div>

);

PlatskartMapView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(
    SeatNumbersPlatskartPropType
  ).isRequired
};

module.exports = PlatskartMapView;