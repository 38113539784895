import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SeatedMapView from 'vokzal-platform/components/step3/seated_map/view'
import { SeatNumbersSeatedPropType } from '../../../../src/types/';

SeatedMapView.propTypes = {
    carNumber : PropTypes.number.isRequired,
    cabins : PropTypes.arrayOf(
        SeatNumbersSeatedPropType
    ).isRequired
};

const mapStateToProps = state => {
    const carSelected = state.step3_categories.car_selected;

    if (carSelected.hasOwnProperty('freeSeats') && Array.isArray(carSelected.freeSeats)) {
        carSelected.freeSeats = carSelected.freeSeats.map(el => Number(el).toString());
    }

    return { carSelected }
};

const SeatedMapContainer = connect(
    mapStateToProps
)(SeatedMapView);

export default SeatedMapContainer