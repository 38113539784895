import React, {Fragment} from 'react';

import SapsanCabin from '../../../../../../src/components/step3/sapsan/base/sapsan_cabin';
import {ChildFriendly} from "@material-ui/icons";

const SapsanChildrenCabinView = ({ carNumber, cabinInfo1, cabinInfo2, cabinInfo3, cabinInfo4 = '', table1 = false }) => (
  <div className="sapsan-children-cabin rounded mt-3 ml-1 mr-1">
    <SapsanCabin carNumber={carNumber} cabinInfo={cabinInfo1} inSubBlock={true} />

      {table1 ? <Fragment><div className="table table-left"></div>
      <div className="table table-right"></div>
      <div className="clear"></div></Fragment> : null}

    <SapsanCabin carNumber={carNumber} cabinInfo={cabinInfo2} inSubBlock={true} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabinInfo3} inSubBlock={true} />
      {cabinInfo4 ? <SapsanCabin carNumber={carNumber} cabinInfo={cabinInfo4} inSubBlock={true} /> : null}

    <div className="sapsan-children-cabin-logo">
      {/*<i className="material-icons">child_friendly</i>*/}
        <ChildFriendly />
    </div>
  </div>
);

module.exports = SapsanChildrenCabinView;