import moment from 'moment';

import { AdultEmpty, ChildEmpty, InfantEmpty } from '../types/';

import { cloneObject } from './object';

import {
  sanitizeAlphaRuEn,
  sanitizeAlphaRu,
  sanitizeAlphaEn,
  sanitizeDate,
  sanitizePassportRF,
  sanitizePassportZagran,
  isValidPassportRF,
  isValidPassportZagran,
  sanitizeChildDocument,
  isValidChildDocument
} from './text_sanitizer';
import i18n from "../../web/i18n";

export const cloneByType = (type) => {

  //https://medium.com/@tkssharma/objects-in-javascript-object-assign-deep-copy-64106c9aefab
  //Object.assign не работает

  let passenger = null;

  switch (type) {
    case 'adult'  : passenger = cloneObject(AdultEmpty); break;
    case 'child'  : passenger = cloneObject(ChildEmpty); break;
    case 'infant' : passenger = cloneObject(InfantEmpty); break;
  }

  return passenger;
}

export const countByType = (passengers, type) => (
  passengers.map((o, index) => (o.type === type ? index : null)).filter((value) => (value !== null)).length
)

export const isForeignPassport = (passenger) => (
  passenger.document_type === 'ЗЗ'
)

export const isZagranPassport = (passenger) => (
  passenger.document_type === 'ЗП'
)

export const isChildDocument = (passenger) => (
  passenger.document_type === 'СР'
)

export const isPassport = (passenger) => (
  passenger.document_type === 'ПН'
)

export const getUrlPassengerType = (type) => {
  let url_type = (type === 'adult') ? 'adults' : '';
  url_type = (type === 'child') ? 'childs' : url_type;
  url_type = (type === 'infant') ? 'infants' : url_type;

  return url_type;
}

const passengerToUrlTranslation = {
  'gender' : 'sex',
  'birth_date' : 'birthdate',
  'document_type' : 'documentType',
  'document_number' : 'documentNumber',
  // 'birth_city' : 'birthplace',
  'document_country' : 'citizenship'
}

const passengerToUrlIgnore = [ 'document_country_title', 'type' ]

export const passengerToUrlObject = (passenger, base_key) => {
  return Object.keys(passenger).map((key) => {
    if (passengerToUrlIgnore.indexOf(key) !== -1) {
      return;
    }

    let translated_key = (passengerToUrlTranslation.hasOwnProperty(key)) ? passengerToUrlTranslation[key] : key;

    const k = base_key + '[' + translated_key + ']';

    let v = passenger[key];
    if (key === 'gender') {
      v = v.toLowerCase();
    }

    let obj = {}
    obj[k] = v;

    return obj;
  }).filter((val) => ( val !== undefined ));
}

export const isValidBirthday = (date) => {
  let isValid = true;

  const d = moment(date, "DD.MM.YYYY");
  if (!d.isValid()) {
    isValid = false;
  }

  return isValid;
}

export const isValidBirthdayByTicketType = (type, date, ticketDate) => {

  let isValid = false;

  const d = moment(date, "DD.MM.YYYY");
  if (!d.isValid()) {
    return false;
  }

  const td = moment(ticketDate, "DD.MM.YYYY");

  switch (type) {
    case 'adult'  : isValid = isValidBirthdayAdult(d, td); break;
    case 'child'  : isValid = isValidBirthdayChild(d, td); break;
    case 'infant' : isValid = isValidBirthdayInfant(d, td); break;
  }

  return isValid;
}

const isValidBirthdayAdult = (date, ticketDate) => {

  const years = ticketDate.diff(date, 'years');
  return years >= 10 && years < 120;
}

const isValidBirthdayChild = (date, ticketDate) => {

  const years = ticketDate.diff(date, 'years');
  return years < 10;
}

const isValidBirthdayInfant = (date, ticketDate) => {

  const years = ticketDate.diff(date, 'years');
  return years > 0 && years < 5;
}

const ignoreFullFillment = ['document_country_title', 'focus']
export const validateFullFillment = (passenger, travelDate = '') => {
  let isValid = true;

  let valid = true;

  const validationRule = validationRulePassport(passenger);
  if (validationRule === 'passport') {
    valid = isValidPassportRF(passenger['document_number']);
  } else if (validationRule === 'passport_zagran') {
    valid = isValidPassportZagran(passenger['document_number']);
  } else if (validationRule === 'child_document') {
    valid = isValidChildDocument(passenger['document_number']);
  }
  const docValid = !valid ? 0 : 1;

  const dateValid = travelDate && isValidBirthdayByTicketType(passenger['type'], passenger['birth_date'], travelDate);

  for (let key in passenger) {

    if (passenger.hasOwnProperty(key)) {
      if (ignoreFullFillment.indexOf(key) !== -1) {
        continue;
      }

      isValid = isValid && !(passenger[key] === null || passenger[key] === '');

      if(key === 'fio' && passenger['document_type'] !== 'ЗП') {

        const words = passenger[key].split(" ");
        let words2 = words.filter(n => n);

        if (words2.length === 3 || words2.length === 4) {

          const w = words2[1].slice(-1).toLowerCase();
          const o = words2[2].slice(-3).toLowerCase();
          const g = (w === 'а' || w === 'я') ? 'F' : 'M';

          valid = (o === 'вич' && g === 'M') || (o === 'вна' && g === 'F') ? g : '';

          if ((valid || passenger['gender']) && docValid && dateValid) {
            return true;
          } else {
            return false;
          }
        }
      }

    }
  }

  return isValid;
}


// export const validateFullFillment = (passenger) => {
//
//   let isValid = true;
//   for (let key in passenger) {
//     if (passenger.hasOwnProperty(key)) {
//       if (ignoreFullFillment.indexOf(key) !== -1) {
//         continue;
//       }
//
//       isValid = isValid && !(passenger[key] === null || passenger[key] === '')
//     }
//   }
//
//   return isValid;
// }

export const validationRulePassport = p => {

  let validationRule

  if (isForeignPassport(p)) {
    validationRule = 'passport_foreigh';
  } else if (isZagranPassport(p)) {
    validationRule = 'passport_zagran';
  } else if (isChildDocument(p)) {
    validationRule = 'child_document';
  } else if (isPassport(p)) {
    validationRule = 'passport';
  }

  return validationRule;
}

export const documentTypes = {
    'ПН': 'Паспорт РФ',
    'СР': 'Свидетельство о рождении',
    'ЗЗ': 'Иностранный документ',
    'ЗП': 'Заграничный паспорт',
    'ВБ': 'Военный билет',
    'ПМ': 'Паспорт моряка'
}

export const carType = {
    'О': 'Общий',
    'П': 'Плацкарт',
    'К': 'Купе',
    'С': 'Сидячий',
    'М': 'Мягкий',
    'Л': 'Люкс'
}
