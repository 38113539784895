import queryString from 'query-string';
import moment from 'moment';

import { gaClientID } from 'vokzal-platform/ga'

import { countByType, getUrlPassengerType, passengerToUrlObject } from './passengers';
import {StationEmpty} from "../types";

import {isEmpty} from "../utils/object";
import {checkLogin} from "./functions";
import getBrowserFingerprint from 'get-browser-fingerprint';

const searchFormUrlParams = (searchFormState, urlParams) => {

  urlParams.departureDate = '';

    // if (searchFormState.date_from) {
    //     urlParams.departureDate = (typeof searchFormState.date_from === 'object')
    //         ? searchFormState.date_from.format('DD.MM.YYYY')
    //         : searchFormState.date_from;
    // }
  if (searchFormState.date_from_info) {
    urlParams.departureDate = (typeof searchFormState.date_from === 'object')
      ? searchFormState.date_from_info.format('DD.MM.YYYY')
      : searchFormState.date_from_info;
  }

  urlParams.adultsCount = 1;
  urlParams.childsCount = 0;
  urlParams.infantsCount = 0;

  return queryString.stringify(urlParams);
};

export const searchFormUrlParamsTrainAlternative = (form) => {

  const f = form.from;
  const t = form.to;

  if (typeof t === 'undefined' && typeof b === 'undefined') {
    return "";
  }

  const from = { id: f.station_id };
  const to = { id: t.station_id };

  const date_from = moment(form.departureDate, 'DD.MM.YYYY');

  let z = { from, to, date_from }
  return searchFormUrlParamsTrain(z)
}

export const searchFormUrlParamsTrain = (searchFormState) => {
  let urlParams = {};

  // const from = searchFormState.from;
  const from = searchFormState.from_info;

  urlParams.fromCode = from.id;

  if (from.station_name) {
    urlParams.from = from.station_name;
    urlParams.fromCodeFor = from.station_name;
  }

  // const to = searchFormState.to;
  const to = searchFormState.to_info;

  urlParams.toCode = to.id;

  if (to.station_name) {
    urlParams.to = to.station_name;
    urlParams.toCodeFor = to.station_name;
  }

  return "/step2/index?" + searchFormUrlParams(searchFormState, urlParams);
};

export const searchFormUrlParamsStep2BusAlternative = (searchFormState) => {

  const t = searchFormState.train_search_form;
  const b = searchFormState.bus_search_form;

  if ((t === null || isEmpty(t)) && (b === null || isEmpty(b))) {
    return "/";
  }

  let urlParams = {};
  if (t.hasOwnProperty("from") && t.hasOwnProperty("to")) {
    urlParams.fromCode = t;
    urlParams.toCode = t;
  }

  if (b.hasOwnProperty("from") && b.hasOwnProperty("to")) {
    urlParams.fromBusStop = b.from.id;
    urlParams.toBusStop = b.to.id;
  }

  if (b.hasOwnProperty("departureDate")) {
    urlParams.departureDate = b.departureDate;
  } else if (t.hasOwnProperty("departureDate")) {
    urlParams.departureDate = t.departureDate;
  }

  return '/step2/alternative/?' + queryString.stringify(urlParams);
}

export const searchFormUrlParamsStep2Bus = (searchFormState) => {
  let urlParams = {};

  const from = searchFormState.from_info;
  const to = searchFormState.to_info;

  if (from === StationEmpty || to === StationEmpty) {
    return "/";
  }


  urlParams.fromCode = from.id;

  if (from.station_name) {
    urlParams.from = from.station_name;
    urlParams.fromCodeFor = from.station_name;

  }

  urlParams.toCode = to.id;

  if (to.station_name) {
    urlParams.to = to.station_name;
    urlParams.toCodeFor = to.station_name;
  }

  return "/step2/index?" + searchFormUrlParams(searchFormState, urlParams) + "#bus";
}

export const searchFormUrlParamsBus = (busSegment, searchFormState) => {

  let urlParams = {};
  urlParams.fromBusStop = busSegment.departure_bus_stop_id;
  urlParams.toBusStop = busSegment.arrival_bus_stop_id;

  searchFormState.date_from_info = searchFormState.departureDate.format('DD.MM.YYYY');

  return "/step2/bus/?" + searchFormUrlParams(searchFormState, urlParams);
};

export const searchFormUrlParamsBusDetails = (searchFormState, bus) => {

  const s = searchFormState;

  let url_params = {};

  url_params.departureDate = s.date_from.format('DD.MM.YYYY');
  url_params.fromCode = typeof s.from !== "undefined" ? s.from.id : null;
  url_params.toCode = typeof s.to !== "undefined" ? s.to.id : null;
  url_params.fromBusStop = bus.departure.id;
  url_params.toBusStop = bus.arrival.id;
  url_params.busNumber = bus.run_number_url;
  url_params.busID = bus.id;

  return "/step3/bus/?" + queryString.stringify(url_params) + '&' + searchFormUrlParams(searchFormState, {});
};

export const searchFormUrlBus = (searchFormState, bus, id, run_number_url) => {

  const s = searchFormState;
  let url_params = {};
  url_params.departureDate = s.date_from.format('DD.MM.YYYY');
  url_params.fromBusStop = bus.departure.busStop.parent_id ? bus.departure.busStop.parent_id : bus.departure.id;
  url_params.toBusStop = bus.arrival.busStop.parent_id ? bus.arrival.busStop.parent_id : bus.arrival.id;
  url_params.busNumber = run_number_url;
  url_params.busID = id;
  url_params.adultsCount = 1;
  url_params.childsCount = 0;

  return "/step3/bus/?" + queryString.stringify(url_params);
};

export const searchFormUrlParamsCarCategory = (state, carCategory, service) => {

  const s = state.search;
  const t = state.step2_details.train;

  let url_params = {};
  url_params.trainNumber = t.number;
  url_params.er = t.er;
  url_params.sapsan = t.sapsan;
  // url_params.departureDate = moment(t.departure.timeFull).format('DD.MM.YYYY');
  url_params.departureDate = s.date_from.format('DD.MM.YYYY');
  url_params.fromCode = s.from.id;
  url_params.toCode = s.to.id;
  url_params.carCategory = carCategory;
  url_params.filter = 'all';
  if (service !== null) {
    url_params.service = service;
  }

  return "/step3/index?" + queryString.stringify(url_params);
};

export const searchFormUrlParamsCarCategoryTrain = (state, carCategory, service, trainNumber, sapsan, er) => {

  const s = state.search;
  const t = state.step2_details.train;

  let url_params = {};

  url_params.trainNumber = trainNumber;
  url_params.er = er;
  url_params.sapsan = sapsan;

  url_params.departureDate = s.date_from.format('DD.MM.YYYY');
  url_params.fromCode = s.from.id;
  url_params.toCode = s.to.id;
  url_params.carCategory = sapsan===1 ? 'С' : carCategory;
  url_params.filter = 'all';
  if (service !== null) {
    url_params.service = service;
  }

  return "/step3/index?" + queryString.stringify(url_params);
};

export const searchFormUrlParamsStep3 = (state, trainNumber) => {

  const s = state.search;

  if (s.from.id === 0 || s.to.id === 0) {
    return null;
  }

  let url_params = {};
  url_params.trainNumber = trainNumber;
  url_params.departureDate = (s.date_from) ? s.date_from.format('DD.MM.YYYY') : moment().format('DD.MM.YYYY');
  url_params.fromCode = s.from.id;
  url_params.toCode = s.to.id;

  return "/step3/index?" + queryString.stringify(url_params);
};

export const searchFormUrlToPassengersDataTrain = (state, params = '') => {

  if(params) {
    return "/step4/index?" + queryString.stringify(params);
  }

  const c = state.step3_categories;
  const p = state.step3_passengers;
  const s = state.search;
  const t = state.step2_details.train;

  let url_params = {};
  url_params.adultsCount = countByType(p.passengers, 'adult');
  url_params.childsCount = countByType(p.passengers, 'child');
  url_params.infantsCount = countByType(p.passengers, 'infant');

  url_params.er = t.er;

  if (p.selected_seats.length > 0) {
    const seatRangeMin = p.selected_seats.map((item) => (item.seatNumber)).reduce((accumulator, currentValue) => ((accumulator > currentValue) ? currentValue : accumulator));
    const seatRangeMax = p.selected_seats.map((item) => (item.seatNumber)).reduce((accumulator, currentValue) => ((accumulator < currentValue) ? currentValue : accumulator));

    url_params.seatsRange = seatRangeMin + '-' + seatRangeMax;
    url_params.seatsList = p.selected_seats.map((item) => (item.seatNumber)).reduce((accumulator, currentValue) => (accumulator + ',' + currentValue));

    const isUpper = p.selected_seats.map((item) => (item.isUpper)).reduce((accumulator, currentValue) => (accumulator || currentValue === true), false);
    const isLower = p.selected_seats.map((item) => (item.isUpper)).reduce((accumulator, currentValue) => (accumulator || currentValue === false), false);

    url_params.upper = (isUpper) ? 1 : 0;
    url_params.lower = (isLower) ? 1 : 0;
  }

  if (c.car_selected) {
    url_params.carNumber = c.car_selected.number;
  }

  if (c.car_category_selected) {
    url_params.service = c.car_category_selected.service.code;
    url_params.sex = 'С';

    const carSelected = state.step3_categories.car_selected;
    if (p.selected_seats.length > 0 && state.step3_categories.car_selected.trainType === 'usual' && carSelected.female > 0){
      const seat = p.selected_seats[0].seatNumber.toString();
      if (p.select_mf) {
        url_params.sex = p.select_mf;
      } else if (carSelected.freeSeatsFemale.indexOf( seat ) !== -1) {
        url_params.sex = 'Ж';
      } else if (carSelected.freeSeatsMale.indexOf( seat ) !== -1) {
        url_params.sex = 'М';
      }
    }
  }

  url_params.departureDate = s.date_from.format('DD.MM.YYYY');
  url_params.fromCode = s.from.id;
  url_params.toCode = s.to.id;
  url_params.trainNumber = t.number;
  url_params.sapsan = t.sapsan;

  if(c.car_selected !== null) {
    url_params.carCategory = c.car_selected.category;
  }

  return "/step4/index?" + queryString.stringify(url_params);
};

export const searchFormUrlToPassengersDataBus = (state, data) => {

  const p = state.step3_passengers;
  const s = state.search;
  const bus = state.step2_details_bus.bus;

  const seats = (p.selected_seats.length > 0 && p.passengers.length > 0)
    ? p.selected_seats.map((item) => (item.seatNumber)).reduce((accumulator, currentValue) => (accumulator + ',' + currentValue))
    : '';

  let url_params = {};
  url_params.adultsCount = countByType(p.passengers, 'adult');
  url_params.childsCount = countByType(p.passengers, 'child');

  url_params.seatsRange = seats;
  url_params.seatsList = seats;

  url_params.departureDate = s.date_from.format('DD.MM.YYYY');
  if (s.from.id) {
    url_params.fromCode = s.from.id;
  }
  if (s.to.id) {
    url_params.toCode = s.to.id;
  }

  if (!(JSON.stringify(data) == "{}")){
      url_params.busID = data.busID;
      url_params.fromBusStop = data.fromBusStop;
      url_params.toBusStop = data.toBusStop;
      url_params.busNumber = data.busNumber;
  } else {
      url_params.busID = bus.id;
      url_params.fromBusStop = bus.departure.id;
      url_params.toBusStop = bus.arrival.id;
      url_params.busNumber = bus.run_number_url;
  }

  // если пользователь не выбрал и в автобусе нет автоматического выбора мест
  if(!url_params.seatsList && !state.step3_bus_seats.auto_select) {
    const count = url_params.adultsCount + url_params.childsCount;
    const all_seats  = state.step3_bus_seats.seats;
    if(all_seats.length >= count){
      // берём первые места по количеству пассажиров
      const auto_seats = all_seats.slice(0, count).join(',');
      url_params.seatsRange = auto_seats;
      url_params.seatsList = auto_seats;
    }
  }

  return "/step4/index?" + queryString.stringify(url_params);
};

export const searchFormUrlToPassengersData = (state, type, data) => {
  return type !== 'train' ? searchFormUrlToPassengersDataBus(state, data) : searchFormUrlToPassengersDataTrain(state);
}

export const searchFormUrlToConfirm = (state) => {
  return "/confirmation/index";
}

export const confirmationUrlWithParams = (state) => {
  let url_params = {};
  url_params.email = state.step4_contacts.email;
  url_params.phone = state.step4_contacts.mobile_phone;
  url_params.payment_system = state.step4_documents.payment_type;
  url_params.prontoaccount = '';
  url_params.remotecheckin = 1;
  if (state.step4_contacts.attention === 1) {
    url_params.attention = 'on';
  }

  if (state.step2_details.car_category) {
    url_params.type_car = state.step2_details.car_category;
  }

  url_params.ga_client_id = gaClientID();
  url_params.lk_status = checkLogin(state) ? 0 : state.step4_contacts.lk_status;
  url_params.fingerprint = getBrowserFingerprint();

  // const sign = state.step3_categories.car_category_selected.sign.code;
  // url_params.car_sign = sign !== null && sign.indexOf('МЖ') !== -1;

  state.step3_passengers.passengers.map((obj, index) => {
    let base_key = 'passengers[' + getUrlPassengerType(obj.type) + '][' + (index + 1) + ']';
    const transformedObj = passengerToUrlObject(obj, base_key);

    transformedObj.map((obj) => {
      Object.keys(obj).map((key) => {
        url_params[key] = obj[key];
      });
    })
  });

  // console.log('confirmationUrlWithParams ', url_params);

  //http://vokzal.localhost/step4/index?adultsCount=1&carCategory=%D0%9F&carNumber=05&childsCount=0&departureDate=14.01.2020&fromCode=2000000&infantsCount=0&lower=0&sapsan=0&seatsList=44&seatsRange=44-44&service=3%D0%AD&sex=%D0%A1&toCode=2014000&trainNumber=126%D0%AD%D0%90&upper=1
  // console.log()

  return queryString.stringify(url_params);
}

export const paymentFormUrl = (state) => {
  return state.confirmation.order_url + '?order_id=' + state.confirmation.order_id;
}

export const urlParamsTicketReturn = (state) => {

  const o = state.order_number.replace(/[^0-9]+/g, '');
  const m = state.mobile_phone.replace(/[^0-9]+/g, '');

  return '/return/confirmation' + '?pin=' + o + '&phoneOrOrderId=' + m;
}

export const urlParamsProcessTicketReturn = (state) => {

  const o = state.order_number.replace(/[^0-9]+/g, '');
  const m = state.mobile_phone.replace(/[^0-9]+/g, '');

  return '/return/result' + '?pin=' + o + '&phoneOrOrderId=' + m;
}

export const geoUrlWithParams = ({ latitude, longitude }) => {
  let url_params = {};
  url_params.latitude = latitude;
  url_params.longitude = longitude;

  return '/index/geo?' + queryString.stringify(url_params);
}

export const popularStationsUrlWithParams = (stationID) => {

  return '/popular_stations/'+stationID.toString().substr(0, 3)+'/'+stationID+'.full.js';
}

export const  parseGET = () => {
    // Обработка GET-запросов
    let tmp = [];    // два вспомогательных
    let tmp2 = [];  // массива
    let get = [];

    let url = window.location.search;	// строка GET запроса
    if(url !== '') {
        tmp = (url.substr(1)).split('&');	// разделяем переменные
        for(let i = 0; i < tmp.length; i++) {
            tmp2 = tmp[i].split('=');		// массив param будет содержать
            get[tmp2[0]] = tmp2[1];		// пары ключ(имя переменной)->значение
        }
    }
    return get;
}

export const busId = (searchFormState, bus) => {

  let url_params = {};
  url_params.departureDate = searchFormState.date_from.format('DD.MM.YYYY');
  url_params.fromBusStop = bus.departure.busStop.parent_id ? bus.departure.busStop.parent_id : bus.departure.id;
  url_params.toBusStop = bus.arrival.busStop.parent_id ? bus.arrival.busStop.parent_id : bus.arrival.id;

  return "/step3/getbusid?" + queryString.stringify(url_params);
};
