import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { SeatSelectedPropType } from '../../../../src/types/';

import { SeatEmpty } from '../../../../src/types/';
import SelectedSeatsView from 'vokzal-platform/components/step3/selected_seats/view'

SelectedSeatsView.propTypes = {
  seats : PropTypes.arrayOf(SeatSelectedPropType).isRequired
};


const mapStateToProps = state => {

  const p = state.step3_passengers;
  const seats_cnt = p.adults + p.children + p.infants;

  let seats = state.step3_passengers.selected_seats.slice(0).sort((a, b) => (a.seatNumber > b.seatNumber));
  while (seats.length < seats_cnt) {
    seats.push(SeatEmpty);
  }

  return { seats };
};

const SelectedSeatsContainer = connect(
  mapStateToProps,
)(SelectedSeatsView);

export default SelectedSeatsContainer