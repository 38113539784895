// @flow

import { handleActions } from 'redux-actions';

import { CAR_CATEGORY_SELECT_ACTION, CAR_NUMBER_SELECT_ACTION, CAR_CATEGORY_SEARCH_ACTION, RESET_STATE_ACTION } from './action';

// import initialState_debug from "./initialState_debug"
let defaultState = { car_categories: [], car_category_selected: null, car_selected: null, show: false };

import { step3TrainSeatsInitialStatePlatform, step3PassengersTrainSeatsInitialStatePlatform } from 'vokzal-platform/components/step3/train_seats/reducer';

let initialState = Object.assign({}, defaultState, step3TrainSeatsInitialStatePlatform())

export const step3InitialState = initialState;
export const step3PassengerInitialState = step3PassengersTrainSeatsInitialStatePlatform();

export const Step3TrainSeatsReducer = handleActions({
  [RESET_STATE_ACTION]: (state, action) => {
    return { ...state, ...defaultState };
  },
  [CAR_CATEGORY_SEARCH_ACTION]: (state, action) => {
    let receidedData = { show: true };
    receidedData.car_categories = action.payload.category.map((c, index) => {
      c.fakeID = index;
      return c;
    });

    receidedData.car_category_selected = receidedData.car_categories.filter((c) => {
      if (action.payload.car_service) {
        return c.service.code === action.payload.car_service

      } else if (action.payload.car_category) {
        return c.cars[0].category === action.payload.car_category
      } else {
        return true;
      }
    })[0];
    receidedData.car_selected = receidedData.car_category_selected.cars[0];
    if (receidedData.car_selected.hasOwnProperty('freeSeats') && Array.isArray(receidedData.car_selected.freeSeats)) {
      receidedData.car_selected.freeSeats = receidedData.car_selected.freeSeats.map(el => Number(el).toString());
    }

    return { ...state, ...receidedData };
  },
  [CAR_NUMBER_SELECT_ACTION]: (state, action) => {
    const car_selected = state.car_category_selected.cars.filter(
      (item, index, array) => (item.number === action.payload)
    )[0];

    if (car_selected.hasOwnProperty('freeSeats') && Array.isArray(car_selected.freeSeats)) {
      car_selected.freeSeats = car_selected.freeSeats.map(el => Number(el).toString());
    }

    return { ...state, car_selected };
  },
  [CAR_CATEGORY_SELECT_ACTION]: (state, action) => {
    const car_category_selected = state.car_categories.filter(
        (item, index, array) => {
          return 'changeForType' in action.payload && action.payload.changeForType ? item.type === action.payload.changeForType : (item.fakeID === action.payload.fakeID)
        }
    )[0];
    if ('changeForType' in action.payload && !action.payload.changeForType) {
        return { ...state};
    }
    const car_selected = car_category_selected.cars[0];

    if (car_selected.hasOwnProperty('freeSeats') && Array.isArray(car_selected.freeSeats)) {
      car_selected.freeSeats = car_selected.freeSeats.map(el => Number(el).toString());
    }

    return { ...state, car_category_selected, car_selected };
  },
}, initialState)
  //initialState_debug)
