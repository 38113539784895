export const aboutInitialStatePlatform = () => {
  let initialState = {}

  if (typeof about !== 'undefined') {
    initialState.prefs = about.prefs;
    initialState.content = about.content;

    initialState.show = true;
  }

  return initialState;
}