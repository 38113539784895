import React from 'react';
import { webAndroidTextMaskFix } from 'vokzal-platform/managers/navigation'

class BootstrapInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            touch: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    handleKeyDown(event) {
        // console.log('event handleKeDown ', event);
    }

    handleTouchEnd(event) {
        // alert('handleTouchEnd ');
        // console.log('event handleTouchEnd ', event);
        // this.setState({touch: true});




        const value = event.target.value;

        // alert('event onKeyDown ' + event.key + ' ' + event.keyCode + ' ' + event.which);

        // alert(value);

        this.setState({value: value});
        // alert('handleKeyUp ' + event);

        // if (this.state.touch) {
        //     alert('mobile ');
        // } else {
        //     alert('desktop ');
        // }
        // alert(event.key);

        // console.log('event handleKeyUp ', event.target.value, event.key);

        if(this.props.hasOwnProperty("onKeyUp") ) {

            // alert(event.key);

            this.props.onKeyUp(value, this.inputElement);
        }


    }

    handleTouchStart(event) {
        // alert('handleTouchStart ');
        // console.log('event handleTouchEnd ', event);
        this.setState({touch: true});
    }


    handleChange(event) {

        const value = event.target.value;

        this.setState({value: value});

        if(this.props.hasOwnProperty("onChange")) {
            this.props.onChange(value, this.inputElement);
        }
    }

    handleFocus() {
      if(this.props.hasOwnProperty("onFocus")) {
        this.props.onFocus();
      }
    }

    handleBlur() {

      if(this.props.hasOwnProperty("onBlur")) {
        this.props.onBlur();
      }
    }

    handleKeyPress (event) {
        const value = event.target.value;
        if(this.props.hasOwnProperty("onKeyPress")) {
            this.props.onKeyPress(value, this.inputElement);
        }
    }

    handleKeyUp (event) {
        const value = event.target.value;
        this.setState({value: value});
        if(this.props.hasOwnProperty("onKeyUp")) {
            this.props.onKeyUp(value, this.inputElement);
        }
    }

    componentDidMount() {
      webAndroidTextMaskFix(this.inputElement, this.state.value);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value
        });

        if (this.props.is_disabled) {
          this.inputElement.blur();

          // hello from safari on iOS
          setTimeout(() => { this.inputElement.blur() }, 100);
        }
    }

    render() {
        const area = this.props.type !== 'textarea';
        return (
            area ? <input type={(this.props.type) ? this.props.type : 'text' } name={this.props.name}
                   id={this.props.id} className={(this.props.className || '') + ' form-control'}
                   placeholder={this.props.placeholder} required={this.props.required} autoFocus={this.props.autoFocus}
                   value={this.state.value} readOnly={this.props.is_disabled}
                   onBlur={this.handleBlur} onFocus={this.handleFocus} onChange={this.handleChange}
                   maxLength={this.props.maxlength}
                   autoComplete={this.props.autocomplete ? this.props.autocomplete : 'on'}
                   onKeyPress={event => {
                       if (event.key === 'Enter') {
                           this.handleKeyPress(event);
                       } else if (event.key === 'Backspace') {
                            // console.log(event);
                           // alert('event ' + event.key);
                       }
                   }}
                   // onKeyDown={this.handleKeyDown}
                   onKeyDown={event => {
                       // alert('event onKeyDown ' + event.key + ' ' + event.keyCode + ' ' + event.which);
                       // console.log('handleKeyDown ', event.key);
                       // alert('handleKeyDown ' + event.key + ' = ' + event.keyCode);
                       // if (event.key === 'Backspace') {
                       //     return false;
                       // }
                   }}
                   // onTouchEnd={event => {
                   //     console.log('handleKeyDown ', event.key);
                   //     alert('onTouchEnd ' + event.key + ' = ' + event.keyCode);
                   // }}
                   // onTouchStart={() => this.handleTouchStart()}
                   // onTouchEnd={event => this.handleTouchEnd(event)}
                   onKeyUp={event => this.handleKeyUp(event)}
                   ref={(inputElement) => (this.inputElement = inputElement)}
            /> : <textarea  name={this.props.name}
                        id={this.props.id} className={(this.props.className || '') + ' form-control'}
                        placeholder={this.props.placeholder} required={this.props.required} autoFocus={this.props.autoFocus}
                        readOnly={this.props.is_disabled}
                        onBlur={this.handleBlur} onFocus={this.handleFocus} onChange={this.handleChange}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                this.handleKeyPress(event);
                            }
                        }}
                            rows={this.props.rows}

                            onKeyUp={event => this.handleKeyUp(event)}
                        ref={(inputElement) => (this.inputElement = inputElement)}
            >{this.state.value}</textarea>
        );
    }
}

module.exports = BootstrapInput;