// @flow
import { handleActions, combineActions } from 'redux-actions';

import {
  BLOG_POST_STORE_URL_ACTION,
  BLOG_POST_STORE_ACTION,
  SHOW_PRELOADER_ACTION, HIDE_PRELOADER_ACTION
} from './action';

import { blogSinglePostInitialStatePlatform } from 'vokzal-platform/components/blog/single_post/reducer';

let blogSinglePostInitialState = {
  error: null,
  post_url: null,
  data: null,
  data_loaded: false,
}
blogSinglePostInitialState = Object.assign({}, blogSinglePostInitialState, blogSinglePostInitialStatePlatform())

const BlogSinglePostReducer = handleActions({
  [BLOG_POST_STORE_URL_ACTION]: (state, action) => {
    return { ...state, post_url: action.payload, data_loaded: false }
  },
  [BLOG_POST_STORE_ACTION]: (state, action) => {
    return { ...state, data_loaded: true, data: action.payload }
  },
  [combineActions(SHOW_PRELOADER_ACTION, HIDE_PRELOADER_ACTION)]: (state, action) => {
    return { ...state, show: action.meta.show }
  },
}, blogSinglePostInitialState)

export default BlogSinglePostReducer

