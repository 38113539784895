import React from 'react';
import i18n from 'vokzal-platform/i18n';

import ErrorMessage from '../../../../src/components/shared/error_message';

import PassengerInfo from '../../../../src/components/ticket_return/passenger_info';
import ProcessButton from '../../../../src/components/ticket_return/process_button';


const TicketReturnInfoView = ({ orderID, orderNumber, orderAmount, order, vokzalComission, rzdFine, errorMessage }) => (
    <div className="return-ticket-details">

      <div className="return-ticket-text h1 font-weight-light m-3">
        Заказ №{orderID} (на сумму {orderAmount}р.)
      </div>

      <div className="page-section rounded m-2 mt-4 p-2">

        <div className="section-title mb-2">
          Номер заказа:
        </div>

        <div className="font-weight-medium font-medium-light h2 mt-2 mb-4">
          {orderNumber}
        </div>

        <div className="font-medium-light font-weight-medium mb-4">
          {order.train} {order.from} - {order.to}
          <div>{i18n.t('departure')}: {order.departure_date}</div>
        </div>


        <div className="font-weight-bold text-uppercase mb-2">
          {i18n.t('train_car')} {order.car}.
        </div>

        <div className="">
          {order.passengers.map((p, index) => (
            <PassengerInfo key={index} fio={p.fio} seat={p.seat} tarif={p.tarif} />
          ))}
        </div>

      </div>

      <div className="page-section rounded m-2 mt-4 p-2">

        <div className="section-title mb-2">
          Обращаем Ваше внимание:
        </div>

        При оформлении возврата удерживается:
        <ul>
          <li>сервисный сбор vokzal.ru - <span className="font-weight-medium">{vokzalComission}р.</span></li>
          <li>сбор за возврат - <span className="font-weight-medium">{rzdFine}р.</span></li>
          <li>половина или полная стоимость плацкарты (если до отправления поезда меньше 8 или меньше 2 часов соответственно)</li>
        </ul>

        <p>
          Возврат денежных средств будет осуществляться строго на карту, с которой осуществлялся платёж. Срок возврата зависит от банка, выпустившего карту и может составлять до 30 дней.
        </p>

        <span className="font-weight-medium">Вы уверены что хотите сделать возврат?</span>

        <ProcessButton />
      </div>

      {(errorMessage) ? <ErrorMessage message={errorMessage} canGoBack={false} /> : null}
    </div>
);

module.exports = TicketReturnInfoView;