import React from 'react';
import HeadMenuPedia from '../../../src/components/shared/menu_pedia';

import i18n from "../../i18n";
import FooterInfo from '../../../src/components/shared/footer_info';
import { Link } from 'react-router-dom';

const TicketResultView = ({er, pin}) => (
  <div className="pedia ticket-result">
      <div className="pedia-block">
          <div className="pedia-header-block pb-5">
            <HeadMenuPedia />
          </div>
          <div className="order-block ">
              <div className="h1 font-weight-light m-3">
                  Поздравляем, ваши билеты куплены!
              </div>

              <div className="content-w">
                  <div className="row">
                      <div className="col-md-6">
                          <div className="order-number">
                              <p className="font-weight-light head">Номер заказа:</p>
                              <p className="number">{pin}</p>
                              <p className="text">Билеты мы отправили на вашу электронную почту.</p>
                          </div>
                          <Link to="/" className="universal-button text-center font-weight-medium text-uppercase d-none d-md-block">
                              <div className={"order-button-label"}>
                                  Купить ещё билеты
                              </div>
                          </Link>
                      </div>
                      <div className="col-md-6 pt-md-4 pt-lg-4 pt-xl-4">
                          {er ? <p className="text">Вы успешно прошли электронную регистрацию.</p> : null}

                          {er ? <p>Для проезда предъявите документ удостоверяющий личность и билет в распечатанном виде или на мобильном устройстве.</p>
                              : <p>Для проезда предъявите документ удостоверяющий личность и билет(необходимо получить в любой кассе на вокзале в пределах Российской Федерации или с помощью терминалов самообслуживания на вокзалах крупных городов.).</p>}

                          <Link to="/" className="universal-button text-center font-weight-medium text-uppercase d-md-none">
                              <div className={"order-button-label"}>
                                  Купить ещё билеты
                              </div>
                          </Link>
                      </div>
                  </div>
              </div>

          </div>
          <FooterInfo titleText={i18n.t('goodbye')} />
      </div>

  </div>
);

module.exports = TicketResultView;