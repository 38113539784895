export const SeatNumbersSapsanEconomPlus = [
  { leftWindow : { number : 3,  isFront : false},   leftSide : { number : 4,  isFront : false},   rightSide : { number : 2,  isFront : false}, rightWindow : { number : 1,  isFront : false} },
  { leftWindow : { number : 7,  isFront : false},   leftSide : { number : 8,  isFront : false},   rightSide : { number : 6,  isFront : false}, rightWindow : { number : 5,  isFront : false} },
  { leftWindow : { number : 11, isFront : false},   leftSide : { number : 12, isFront : false},   rightSide : { number : 10, isFront : false}, rightWindow : { number : 9,  isFront : false} },
  { leftWindow : { number : 15, isFront : false},   leftSide : { number : 16, isFront : false},   rightSide : { number : 14, isFront : false}, rightWindow : { number : 13, isFront : false} },
  { leftWindow : { number : 21, isFront : false},   leftSide : { number : 22, isFront : false},   rightSide : { number : 18, isFront : false}, rightWindow : { number : 17, isFront : false} },

  { leftWindow : { number : 23, isFront : true},    leftSide : { number : 24, isFront : true},    rightSide : { number : 20, isFront : true},  rightWindow : { number : 19, isFront : true}  },
  { leftWindow : { number : 27, isFront : true},    leftSide : { number : 28, isFront : true},    rightSide : { number : 26, isFront : true},  rightWindow : { number : 25, isFront : true}  },
  { leftWindow : { number : 31, isFront : true},    leftSide : { number : 32, isFront : true},    rightSide : { number : 30, isFront : true},  rightWindow : { number : 29, isFront : true}  },

  { leftWindow : { number : 33, isFront : true},    leftSide : { number : 34, isFront : true},    rightSide : null,                            rightWindow : null                            },
  { leftWindow : { number : 35, isFront : true},    leftSide : { number : 36, isFront : true},    rightSide : null,                            rightWindow : null                            },

  { leftWindow : { number : 41, isFront : false},   leftSide : { number : 42, isFront : false},   rightSide : { number : 38, isFront : false}, rightWindow : { number : 37, isFront : false} },
  { leftWindow : { number : 43, isFront : true},    leftSide : { number : 44, isFront : true},    rightSide : { number : 40, isFront : true},  rightWindow : { number : 39, isFront : true}  },

  { leftWindow : null,                              leftSide : null,                              rightSide : { number : 52, isFront : false}, rightWindow : { number : 51, isFront : false} },
  { leftWindow : { number : 49, isFront : true},    leftSide : null,                              rightSide : { number : 46, isFront : false}, rightWindow : { number : 45, isFront : false} },
  { leftWindow : null,                              leftSide : null,                              rightSide : { number : 48, isFront : false}, rightWindow : { number : 47, isFront : false} },
]

export const SeatNumbersSapsanEconom = [
  { leftWindow : { number : 3,  isFront : false},   leftSide : { number : 4,  isFront : false},   rightSide : { number : 2,  isFront : false}, rightWindow : { number : 1,  isFront : false} },
  { leftWindow : { number : 7,  isFront : false},   leftSide : { number : 8,  isFront : false},   rightSide : { number : 6,  isFront : false}, rightWindow : { number : 5,  isFront : false} },
  { leftWindow : { number : 11, isFront : false},   leftSide : { number : 12, isFront : false},   rightSide : { number : 10, isFront : false}, rightWindow : { number : 9,  isFront : false} },
  { leftWindow : { number : 15, isFront : false},   leftSide : { number : 16, isFront : false},   rightSide : { number : 14, isFront : false}, rightWindow : { number : 13, isFront : false} },
  { leftWindow : { number : 21, isFront : false},   leftSide : { number : 22, isFront : false},   rightSide : { number : 18, isFront : false}, rightWindow : { number : 17, isFront : false} },

  { leftWindow : { number : 23, isFront : true},    leftSide : { number : 24, isFront : true},    rightSide : { number : 20, isFront : true},  rightWindow : { number : 19, isFront : true}  },
  { leftWindow : { number : 27, isFront : true},    leftSide : { number : 28, isFront : true},    rightSide : { number : 26, isFront : true},  rightWindow : { number : 25, isFront : true}  },
  { leftWindow : { number : 31, isFront : true},    leftSide : { number : 32, isFront : true},    rightSide : { number : 30, isFront : true},  rightWindow : { number : 29, isFront : true}  },
  { leftWindow : { number : 35, isFront : true},    leftSide : { number : 36, isFront : true},    rightSide : { number : 34, isFront : true},  rightWindow : { number : 33, isFront : true}  },
  { leftWindow : { number : 39, isFront : true},    leftSide : { number : 40, isFront : true},    rightSide : { number : 38, isFront : true},  rightWindow : { number : 37, isFront : true}  },
  { leftWindow : { number : 43, isFront : true},    leftSide : { number : 44, isFront : true},    rightSide : { number : 42, isFront : true},  rightWindow : { number : 41, isFront : true}  },

  { leftWindow : { number : 45, isFront : true},    leftSide : { number : 46, isFront : true},    rightSide : null,                            rightWindow : null                            },
  { leftWindow : { number : 47, isFront : true},    leftSide : { number : 48, isFront : true},    rightSide : null,                            rightWindow : null                            },

  { leftWindow : { number : 51, isFront : false},   leftSide : { number : 52, isFront : false},   rightSide : { number : 50, isFront : false}, rightWindow : { number : 49, isFront : false} },
  { leftWindow : { number : 55, isFront : false},   leftSide : { number : 56, isFront : false},   rightSide : { number : 54, isFront : false}, rightWindow : { number : 53, isFront : false} },
  { leftWindow : { number : 61, isFront : false},   leftSide : { number : 62, isFront : false},   rightSide : { number : 58, isFront : false}, rightWindow : { number : 57, isFront : false} },

  { leftWindow : { number : 63, isFront : true},    leftSide : { number : 64, isFront : true},    rightSide : { number : 60, isFront : true},  rightWindow : { number : 59, isFront : true}  },
  { leftWindow : null,                              leftSide : null,                              rightSide : { number : 66, isFront : true},  rightWindow : { number : 65, isFront : true}  },
]

export const SeatNumbersSapsanBuffet = [
  { leftWindow : { number : 1,  isFront : false},   leftSide : { number : 2,  isFront : false},   rightSide : { number : 6,  isFront : false}, rightWindow : { number : 5,  isFront : false} },
  { leftWindow : { number : 3,  isFront : true},    leftSide : { number : 4,  isFront : true},    rightSide : { number : 8,  isFront : true},  rightWindow : { number : 7,  isFront : true} },
  { leftWindow : { number : 9,  isFront : false},   leftSide : { number : 10, isFront : false},   rightSide : { number : 14, isFront : false}, rightWindow : { number : 13, isFront : false} },
  { leftWindow : { number : 11, isFront : true},    leftSide : { number : 12, isFront : true},    rightSide : { number : 16, isFront : true},  rightWindow : { number : 15, isFront : true} },
  { leftWindow : { number : 17, isFront : false},   leftSide : { number : 18, isFront : false},   rightSide : { number : 22, isFront : false}, rightWindow : { number : 21, isFront : false} },
  { leftWindow : { number : 19, isFront : true},    leftSide : { number : 20, isFront : true},    rightSide : { number : 24, isFront : true},  rightWindow : { number : 23, isFront : true} },
  { leftWindow : { number : 25, isFront : false},   leftSide : { number : 26, isFront : false},   rightSide : { number : 30, isFront : false}, rightWindow : { number : 29, isFront : false} },
  { leftWindow : { number : 27, isFront : true},    leftSide : { number : 28, isFront : true},    rightSide : { number : 32, isFront : true},  rightWindow : { number : 31, isFront : true} },
  { leftWindow : { number : 33, isFront : false},   leftSide : { number : 34, isFront : false},   rightSide : { number : 38, isFront : false}, rightWindow : { number : 37, isFront : false} },
  { leftWindow : { number : 35, isFront : true},    leftSide : { number : 36, isFront : true},    rightSide : { number : 40, isFront : true},  rightWindow : { number : 39, isFront : true} },
]

export const SeatNumbersSapsanBusinessClass = [
  { leftWindow : { number : 3,  isFront : false},   leftSide : { number : 4,  isFront : false},   rightSide : { number : 2,  isFront : false}, rightWindow : { number : 1,  isFront : false} },
  { leftWindow : { number : 7,  isFront : false},   leftSide : { number : 8,  isFront : false},   rightSide : { number : 6,  isFront : false}, rightWindow : { number : 5,  isFront : false} },
  { leftWindow : { number : 11, isFront : false},   leftSide : { number : 12, isFront : false},   rightSide : { number : 10, isFront : false}, rightWindow : { number : 9,  isFront : false} },
  { leftWindow : { number : 15, isFront : false},   leftSide : { number : 16, isFront : false},   rightSide : { number : 14, isFront : false}, rightWindow : { number : 13, isFront : false} },
  { leftWindow : { number : 21, isFront : false},   leftSide : { number : 22, isFront : false},   rightSide : { number : 18, isFront : false}, rightWindow : { number : 17, isFront : false} },

  { leftWindow : { number : 23, isFront : true},    leftSide : { number : 24, isFront : true},    rightSide : { number : 20, isFront : true},  rightWindow : { number : 19, isFront : true}  },
  { leftWindow : { number : 27, isFront : true},    leftSide : { number : 28, isFront : true},    rightSide : { number : 26, isFront : true},  rightWindow : { number : 25, isFront : true}  },
  { leftWindow : { number : 31, isFront : true},    leftSide : { number : 32, isFront : true},    rightSide : { number : 30, isFront : true},  rightWindow : { number : 29, isFront : true}  },
  { leftWindow : { number : 35, isFront : true},    leftSide : { number : 36, isFront : true},    rightSide : { number : 34, isFront : true},  rightWindow : { number : 33, isFront : true}  },

  { leftWindow : { number : 39, isFront : true},    leftSide : { number : 40, isFront : true},    rightSide : null,                            rightWindow : null                            },
  { leftWindow : { number : 41, isFront : true},    leftSide : { number : 42, isFront : true},    rightSide : null,                            rightWindow : null                            },
  { leftWindow : { number : 43, isFront : true},    leftSide : { number : 44, isFront : true},    rightSide : null,                            rightWindow : null                            },

  { leftWindow : { number : 49, isFront : false},   leftSide : { number : 50, isFront : false},   rightSide : { number : 46, isFront : false}, rightWindow : { number : 45, isFront : false} },
  { leftWindow : { number : 51, isFront : true},    leftSide : { number : 52, isFront : true},    rightSide : { number : 48, isFront : true},  rightWindow : { number : 47, isFront : true}  },
]

export const SeatNumbersSapsan2RClass = [
  { leftWindow : { number : 3,  isFront : false},   leftSide : { number : 4,  isFront : false},   rightSide : { number : 2,  isFront : false}, rightWindow : { number : 1,  isFront : false} },
  { leftWindow : { number : 7,  isFront : false},   leftSide : { number : 8,  isFront : false},   rightSide : { number : 6,  isFront : false}, rightWindow : { number : 5,  isFront : false} },
  { leftWindow : { number : 11, isFront : false},   leftSide : { number : 12, isFront : false},   rightSide : { number : 10, isFront : false}, rightWindow : { number : 9,  isFront : false} },
  { leftWindow : { number : 15, isFront : false},   leftSide : { number : 16, isFront : false},   rightSide : { number : 14, isFront : false}, rightWindow : { number : 13, isFront : false} },
  { leftWindow : { number : 21, isFront : false},   leftSide : { number : 22, isFront : false},   rightSide : { number : 18, isFront : false}, rightWindow : { number : 17, isFront : false} },

  { leftWindow : { number : 23, isFront : true},    leftSide : { number : 24, isFront : true},    rightSide : { number : 20, isFront : true},  rightWindow : { number : 19, isFront : true}  },
  { leftWindow : { number : 27, isFront : true},    leftSide : { number : 28, isFront : true},    rightSide : { number : 26, isFront : true},  rightWindow : { number : 25, isFront : true}  },
  { leftWindow : { number : 31, isFront : true},    leftSide : { number : 32, isFront : true},    rightSide : { number : 30, isFront : true},  rightWindow : { number : 29, isFront : true}  },
  { leftWindow : { number : 35, isFront : true},    leftSide : { number : 36, isFront : true},    rightSide : { number : 34, isFront : true},  rightWindow : { number : 33, isFront : true}  },

  { leftWindow : { number : 39, isFront : true},    leftSide : { number : 40, isFront : true},    rightSide : { number : 38, isFront : true},  rightWindow : { number : 37, isFront : true} },
  { leftWindow : { number : 43, isFront : true},    leftSide : { number : 44, isFront : true},    rightSide : { number : 42, isFront : true},  rightWindow : { number : 41, isFront : true} },

  { leftWindow : { number : 45, isFront : true},    leftSide : { number : 46, isFront : true},    rightSide : null,                            rightWindow : null                            },
  { leftWindow : { number : 47, isFront : true},    leftSide : { number : 48, isFront : true},    rightSide : null,                            rightWindow : null                            },
  { leftWindow : { number : 51, isFront : false},    leftSide : { number : 52, isFront : false},    rightSide : { number : 50, isFront : false},  rightWindow : { number : 49, isFront : false}  },
  { leftWindow : { number : 55, isFront : false},    leftSide : { number : 56, isFront : false},    rightSide : { number : 54, isFront : false},  rightWindow : { number : 53, isFront : false}  },
  { leftWindow : { number : 61, isFront : false},    leftSide : { number : 62, isFront : false},    rightSide : { number : 58, isFront : false},  rightWindow : { number : 57, isFront : false}  },

  { leftWindow : { number : 63, isFront : true},    leftSide : { number : 64, isFront : true},    rightSide : { number : 60, isFront : true},  rightWindow : { number : 59, isFront : true} },
  { leftWindow : null,    leftSide : null,    rightSide : { number : 66, isFront : true},  rightWindow : { number : 65, isFront : true} },
]

export const SeatNumbersSapsan1GClass = [
  { leftWindow : { number : 3,  isFront : false},   leftSide : { number : 4,  isFront : false},   rightSide : { number : 2,  isFront : false}, rightWindow : { number : 1,  isFront : false} },
  { leftWindow : { number : 7,  isFront : false},   leftSide : { number : 8,  isFront : false},   rightSide : { number : 6,  isFront : false}, rightWindow : { number : 5,  isFront : false} },
  { leftWindow : { number : 11, isFront : false},   leftSide : { number : 12, isFront : false},   rightSide : { number : 10, isFront : false}, rightWindow : { number : 9,  isFront : false} },
  { leftWindow : { number : 15, isFront : false},   leftSide : { number : 16, isFront : false},   rightSide : { number : 14, isFront : false}, rightWindow : { number : 13, isFront : false} },
  { leftWindow : { number : 21, isFront : false},   leftSide : { number : 22, isFront : false},   rightSide : { number : 18, isFront : false}, rightWindow : { number : 17, isFront : false} },

  { leftWindow : { number : 23, isFront : true},    leftSide : { number : 24, isFront : true},    rightSide : { number : 20, isFront : true},  rightWindow : { number : 19, isFront : true}  },
  { leftWindow : { number : 27, isFront : true},    leftSide : { number : 28, isFront : true},    rightSide : { number : 26, isFront : true},  rightWindow : { number : 25, isFront : true}  },
  { leftWindow : { number : 31, isFront : true},    leftSide : { number : 32, isFront : true},    rightSide : { number : 30, isFront : true},  rightWindow : { number : 29, isFront : true}  },
  { leftWindow : { number : 35, isFront : true},    leftSide : { number : 36, isFront : true},    rightSide : { number : 34, isFront : true},  rightWindow : { number : 33, isFront : true}  },

  { leftWindow : { number : 39, isFront : true},    leftSide : { number : 40, isFront : true},    rightSide : { number : 38, isFront : true},  rightWindow : { number : 37, isFront : true} },


  { leftWindow : { number : 41, isFront : true},    leftSide : { number : 42, isFront : true},    rightSide : null,                            rightWindow : null                            },
  { leftWindow : { number : 43, isFront : true},    leftSide : { number : 44, isFront : true},    rightSide : null,                            rightWindow : null                            },

  { leftWindow : { number : 49, isFront : false},    leftSide : null,    rightSide : null,  rightWindow : { number : 45, isFront : false}  },
  { leftWindow : { number : 51, isFront : true},    leftSide : null,    rightSide : null,  rightWindow : { number : 47, isFront : true}  },

]

export const SeatNumbersSapsanFirstClass = [
  { leftWindow : { number : 3,  isFront : false},   leftSide : null,                              rightSide : null,                            rightWindow : null                            },
  { leftWindow : { number : 7,  isFront : false},   leftSide : null,                              rightSide : null,                            rightWindow : null                            },
  { leftWindow : { number : 11, isFront : false},   leftSide : null,                              rightSide : null,                            rightWindow : null                            },
  { leftWindow : { number : 15, isFront : false},   leftSide : null,                              rightSide : null,                            rightWindow : null                            },
  { leftWindow : { number : 19, isFront : false},   leftSide : null,                              rightSide : null,                            rightWindow : null                            },
  { leftWindow : { number : 21, isFront : false},   leftSide : null,                              rightSide : null,                            rightWindow : null                            },

  { leftWindow : null,                              leftSide : null,                              rightSide : { number : 2,  isFront : false}, rightWindow : { number : 1,  isFront : false} },
  { leftWindow : null,                              leftSide : null,                              rightSide : { number : 6,  isFront : false}, rightWindow : { number : 5,  isFront : false} },
  { leftWindow : null,                              leftSide : null,                              rightSide : { number : 10, isFront : false}, rightWindow : { number : 9,  isFront : false} },
  { leftWindow : null,                              leftSide : null,                              rightSide : { number : 10, isFront : true},  rightWindow : { number : 13, isFront : true}  },
  { leftWindow : null,                              leftSide : null,                              rightSide : { number : 18, isFront : true},  rightWindow : { number : 17, isFront : true}  },
  { leftWindow : null,                              leftSide : null,                              rightSide : { number : 24, isFront : true},  rightWindow : { number : 23, isFront : true}  },

  { leftWindow : null,                              leftSide : null,                              rightSide : { number : 28, isFront : false}, rightWindow : { number : 27, isFront : false} },
  { leftWindow : null,                              leftSide : null,                              rightSide : { number : 30, isFront : true},  rightWindow : { number : 29, isFront : true}  },
]


export const SeatNumbersSapsanW10V2Class = [
  { leftWindow : { number : 47, isFront : true},    leftSide : { number : 48, isFront : true},    rightSide : null,  rightWindow : { number : 49, isFront : true} },
  { leftWindow : { number : 45,  isFront : true},   leftSide : { number : 46,  isFront : true},   rightSide : null, rightWindow : null },
  { leftWindow : { number : 51,  isFront : true},   leftSide : { number : 46,  isFront : true},   rightSide : null, rightWindow : null },
  { leftWindow : { number : 39, isFront : false},    leftSide : { number : 40, isFront : false},    rightSide : { number : 44, isFront : false},  rightWindow : { number : 43, isFront : false} },
  { leftWindow : { number : 37, isFront : true},    leftSide : { number : 38, isFront : true},    rightSide : { number : 42, isFront : true},  rightWindow : { number : 41, isFront : true} },
  { leftWindow : null,    leftSide : null,    rightSide : { number : 36, isFront : true},  rightWindow : { number : 35, isFront : true} },
  { leftWindow : null,    leftSide : null,    rightSide : { number : 34, isFront : true},  rightWindow : { number : 33, isFront : true} },
  { leftWindow : { number : 19, isFront : false},    leftSide : { number : 20, isFront : false},    rightSide : { number : 24, isFront : false},  rightWindow : { number : 23, isFront : false} },
  { leftWindow : { number : 17, isFront : true},    leftSide : { number : 18, isFront : true},    rightSide : { number : 22, isFront : true},  rightWindow : { number : 21, isFront : true} },
  { leftWindow : { number : 13, isFront : true},    leftSide : { number : 14, isFront : true},    rightSide : { number : 16, isFront : true},  rightWindow : { number : 15, isFront : true} },
  { leftWindow : { number : 9, isFront : true},    leftSide : { number : 10, isFront : true},    rightSide : { number : 12, isFront : true},  rightWindow : { number : 11, isFront : true} },
  { leftWindow : { number : 5, isFront : true},    leftSide : { number : 6, isFront : true},    rightSide : { number : 8, isFront : true},  rightWindow : { number : 7, isFront : true} },
  { leftWindow : { number : 1, isFront : true},    leftSide : { number : 2, isFront : true},    rightSide : { number : 4, isFront : true},  rightWindow : { number : 3, isFront : true} },
]