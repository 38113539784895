import { handleActions } from 'redux-actions';

import { STEP4_DOCUMENT_TYPES_LOADED_ACTION } from './action';
import { STEP4_PAYMENT_TYPE_CHANGED_ACTION } from '../payment_type_option/action';

import { initialStatePlatform } from 'vokzal-platform/components/step4/user_list/reducer';

let initialState = { document_types : [], payment_types : [], payment_type : 'intellectmoney', stop_buy: false }
initialState = Object.assign({}, initialState, initialStatePlatform())

export const Step4DocumentsReducer = handleActions({
  [STEP4_DOCUMENT_TYPES_LOADED_ACTION]: (state, action) => {
    return { ...state, ...action.payload }
  },
  [STEP4_PAYMENT_TYPE_CHANGED_ACTION]: (state, action) => {
    return { ...state, payment_type : action.payload }
  },
}, initialState)