import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import i18n from 'vokzal-platform/i18n';

import { counterIncrementAction, counterDecrementAction } from './action';
import { countByType } from '../../../utils/passengers';

import PassengersCountRowView from 'vokzal-platform/components/step3/passengers_count_row/view';

const mapStateToPropsAdult = state => {

  const p = state.step3_passengers;

  const type = 'adult';
  const counter = countByType(p.passengers, type)

  const canIncrement = p.passengers.length < 4;
  const canDecrement = counter > 1;

  return {
    title: i18n.t('whos_going_adults'),
    price : state.step2_details.price.adult,
    type, counter, canIncrement, canDecrement,
  }
};

const mapStateToPropsChildren = state => {
  const queryObject = queryString.parse(location.search);
  const p = state.step3_passengers;

  const type = "child";
  const counter = countByType(p.passengers, type)

  const canIncrement = p.passengers.length < 4;
  const canDecrement = counter > 0;

  return {
    title:   queryObject.busID ? i18n.t('whos_going_children_bus') : i18n.t('whos_going_children'),
    type : "child",
    price : 0,
    counter, canIncrement, canDecrement,
  }
};

const mapStateToPropsInfants = state => {

  const p = state.step3_passengers;

  const type = "infant";

  const adults  = countByType(p.passengers, 'adult')
  const counter = countByType(p.passengers, type)

  const canIncrement = adults > counter && p.passengers.length < 4;
  const canDecrement = counter > 0;

  return {
    title: i18n.t('whos_going_infants'),
    price : 0,
    type, counter, canIncrement, canDecrement,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onCounterIncrement : (operationAllowed, operationType) => {
      if (operationAllowed) {
        dispatch(counterIncrementAction(operationType));
      }
    },
    onCounterDecrement : (operationAllowed, operationType) => {
      if (operationAllowed) {
        dispatch(counterDecrementAction(operationType));
      }
    }
  }
};

export const AdultsCountContainer = connect(
  mapStateToPropsAdult,
  mapDispatchToProps
)(PassengersCountRowView);

export const ChildrenCountContainer = connect(
  mapStateToPropsChildren,
  mapDispatchToProps
)(PassengersCountRowView);

export const InfantsCountContainer = connect(
  mapStateToPropsInfants,
  mapDispatchToProps
)(PassengersCountRowView);