import { handleActions } from 'redux-actions'

import { scheduleInitialStatePlatform } from 'vokzal-platform/components/pedia/schedule/reducer';

import { TOGGLE_SCHEDULE_BLOCK, FILL_SCHEDULE_GROUP, SCHEHULE_FETCH_COMPLETE_ACTION } from "./action";
import { SHOW_ORDER_FORM } from "../order_block/action";

let initialState = { loaded: false }

initialState = Object.assign({}, initialState, scheduleInitialStatePlatform())

const PediaScheduleReducer = handleActions({
    [SCHEHULE_FETCH_COMPLETE_ACTION]: (state, action) => {
      return { ...state, ...action.payload }
    },
    [TOGGLE_SCHEDULE_BLOCK]: (state, action) => {
      const city = action.payload;

      state.schedule_groups[city].isExpanded = !state.schedule_groups[city].isExpanded;
      return { ...state }
    },
    [FILL_SCHEDULE_GROUP]: (state, action) => {
      const city = action.payload;
      if (state.schedule_groups
          && state.schedule_groups.hasOwnProperty(city)
          // && state.schedule_groups[city].items.length === 0
      ) {

        state.schedule_groups[city].items = state.schedule.filter(item => (
          item.from.city === city || item.to.city === city
        ));
      }

      return { ...state }
    },
    [SHOW_ORDER_FORM]: (state, action) => {
      const schedule = state.schedule.map(t => {
        t.showOrderForm = (t.number === action.payload);
        return t;
      });
      return { ...state, schedule }
    },
}, initialState)

export default PediaScheduleReducer