import React from 'react';

import BootstrapLabel from '../../base/bootstrap_label'
import PlusButton from '../../../../src/components/shared/plus_button'

const AddDateTo = ({ show, label, onButtonClick }) => {
    if (show === false) {
        return null;
    }

    return (
        <fieldset className="col-6 form-group pr-0" onClick={() => onButtonClick()}>
            <div className="active form-control text-right p-0 pt-3 pr-2">
                <PlusButton />
            </div>

            <BootstrapLabel className="add-date-to-label">{label}</BootstrapLabel>
        </fieldset>
    );
};

module.exports = AddDateTo;