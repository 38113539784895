
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { userAction } from './action'

import UserView from 'vokzal-platform/components/test_wd/view'

// import {search_trains} from '../../api/search_trains'
// import {user_test} from '../../api/user_test'

UserView.propTypes = {
  val: PropTypes.string,
  log_rows: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => {


  // console.info('UserContainer mapStateToProps');
  //
  // search_trains('/step2/index?adultsCount=1&childsCount=0&departureDate=26.04.2019&from=%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0&fromCode=2000000&fromCodeFor=%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0&infantsCount=0&to=%D0%92%D0%BE%D1%80%D0%BE%D0%BD%D0%B5%D0%B6&toCode=2014000&toCodeFor=%D0%92%D0%BE%D1%80%D0%BE%D0%BD%D0%B5%D0%B6');

  // user_test('/api/auth/testp');
  // user_login('/api/auth');

  // user_login('/api/auth');


  return {
    val: state.test_wd.value,
    log_rows: state.test_wd.log_rows
  }
}

const mapDispatchToProps = dispatch => {

  return {
    onChange: (e) => {
      dispatch(userAction(e))
    }
  }
}
//
// class UserContainer extends Component{
//   componentDidMount() {
//
//     console.info('UserContainer componentDidMount');
//
//     search_trains('/step2/index?adultsCount=1&childsCount=0&departureDate=26.04.2019&from=%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0&fromCode=2000000&fromCodeFor=%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0&infantsCount=0&to=%D0%92%D0%BE%D1%80%D0%BE%D0%BD%D0%B5%D0%B6&toCode=2014000&toCodeFor=%D0%92%D0%BE%D1%80%D0%BE%D0%BD%D0%B5%D0%B6');
//   }
// }

const UserContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserView)

export default UserContainer