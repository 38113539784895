import {connect} from 'react-redux'
import RenewModalView from 'vokzal-platform/components/shared/renew_modal/view'
import {hideRenewModalAction, showRenewModalAction} from "../../shared/renew_modal/action";
import {userSetValueAction} from "../../user/action";
import email_validator from 'email-validator';
import {user_renew} from "../../../api/user_renew";
import i18n from 'vokzal-platform/i18n';

const mapStateToProps = state => {
    return {
        show: state.renew_modal.show,
        title: i18n.t('renew_password'),
        email: state.user.email,
        email_error: state.user.email_error,
        error: state.user.error,
        success: state.user.message,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCloseClick: () => {
            dispatch(hideRenewModalAction());
        },
        onClick: () => {
            dispatch(showRenewModalAction())
        },
        onEmailChanged: (email) => {
            dispatch(userSetValueAction({email}))
        },
        onDataLoaded : (time_spent, response) => {
            dispatch(userSetValueAction({message: response.message}))
            dispatch(userSetValueAction({error: ''}));
        },
        onError : (error) => {
            dispatch(userSetValueAction({error}))
        },
        onSubmit: (email, onDataLoaded, onError) => {
            // email = email.trim();

            if(!email) {
                dispatch(userSetValueAction({email_error: 'Укажите электронную почту'}))
            } else if(!email_validator.validate(email)) {
                dispatch(userSetValueAction({email_error: 'Укажите корректную электронную почту'}))
            } else {
                dispatch(userSetValueAction({email_error: ''}));
                user_renew('/api/forgot', {email}, onDataLoaded, onError);
            }
        }
    }
};

const RenewModalContainter = connect(
    mapStateToProps,
    mapDispatchToProps
)(RenewModalView)

export default RenewModalContainter