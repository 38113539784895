import React, { Component } from 'react';
import { connect } from 'react-redux';

import { train_car_enable_swiper } from 'vokzal-platform/components/step3/train_car/manager';
import TrainCarView from 'vokzal-platform/components/step3/train_car/view';
import {setActiveCategory, step2SelectCarTypeAction} from '../../../../src/components/step2/train_details/action';
import {select_category} from "../train_seats/action";
import {clear_selected_seat} from "../action";

class TrainCarContainer extends Component {

  componentDidMount() {
    if (this.props.swiperEnabled) {
      train_car_enable_swiper()
    }
  }

  render() {
    return <TrainCarView {...this.props} />;
  }
}


const mapStateToProps = state => {

  const c = state.step3_categories.car_category_selected;
  const cc = state.step3_categories.car_selected;

  let cars = c.cars.map(
    (item) => ({ carNumber: item.number, isActive: item.number === cc.number, categoryFakeID: c.fakeID })
  );
  const prices = {};
  state.step3_categories.car_categories.map(i => {
    if(!prices.hasOwnProperty(i.type)){
      prices[i.type] = i.price.withBedding;
    }});


  const swiperEnabled = cars.length >= 6;


  const car_category = state.step2_details.car_category;
  let tmp_categories = (c.length > 0) ? c.slice(0).sort((a, b) => (b.price.withBedding - a.price.withBedding)) : [];

  //TODO split by 3 (or dynamic 2 if 4 elements)
  while (tmp_categories.length > 0) {
    const t = tmp_categories.splice(0, 2).map((item) => {

      const seatsCnt = item.seatsCount + ' ' + russian(item.seatsCount, ['место', 'места', 'мест']);
      const isActive = state.step3_categories.car_category_selected.fakeID === item.fakeID;
      const code = item.service.code;

      const sign = item.tags.hasOwnProperty('female');

      return { fakeID: item.fakeID, code, title : item.name, price : item.price.withBedding, seatsCnt, isActive, sign, type: item.type } ;
    });
  }
  return { cars, swiperEnabled, carCategory: car_category, prices }
};

const mapDispatchToProps = dispatch => {
  return {
    onTypeChange : (type, prices) => {
      dispatch(setActiveCategory({ type }));
      dispatch(select_category({changeForType: type}));
      dispatch(clear_selected_seat());
      const price = prices[type];
      dispatch(step2SelectCarTypeAction({ carCategory: type, service: null, prices:  {
          adult: price,
          child: Math.round(price / 2)
        } }));
    },
  }
}

TrainCarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrainCarContainer);

export default TrainCarContainer