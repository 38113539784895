import React from 'react';

import i18n from 'vokzal-platform/i18n';

import { PediaPreLoader } from '../../../../src/components/shared/preloader';


import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';
import PediaHeaderImage from '../../../../src/components/pedia/header_image';
import PediaHeader from '../../../../src/components/pedia/header';

import FooterInfo from '../../../../src/components/shared/footer_info/index';
import FooterLinks from '../../../../src/components/home/footer_links/index';

const PediaSalepointsCashDeskView = ({ stationFullName, cashDesks }) => {

  if (cashDesks.length === 0) {
    return null;
  }

  return (
    <div className="pedia-salepoint-cashdesk mt-4">

      <div className="h5 p-2 mb-0 font-medium-light font-weight-normal text-uppercase">Время работы и услуги билетных касс на станции {stationFullName}</div>

      <table className="table mt-2">
        <thead>
          <tr>
            <th className="border-top-0">Касса</th>
            <th className="border-top-0">Операции</th>
          </tr>
        </thead>
        <tbody>
          {cashDesks.map((c, index) => (
            [
              <tr key={index * 2}>
                <td className={(c.comment) ? 'border-bottom-0' : null}>
                  <div className="text-nowrap">{c.number}</div>
                  <div className="small">{(c.workTime) ? <div className="font-light">{c.workTime}</div> : null}</div>
                </td>
                <td className={(c.comment) ? 'border-bottom-0' : null}>
                  <ul className="pl-3 mb-0">
                    {(c.creditCardSupport) ? <li>Оплата картой</li> : null}
                    {(c.returnSupport) ? <li>Возврат билета</li> : null}
                    {(c.eticketSupport) ? <li>	Оформление электронных билетов</li> : null}
                  </ul>
                </td>
              </tr>,
              (c.comment) ? <tr key={index*2 + 1}><td colSpan="2" className="pt-0 small border-top-0">{c.comment}</td></tr> : null
            ]
          ))}
        </tbody>
      </table>
    </div>
)
}

module.exports = PediaSalepointsCashDeskView;