import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import i18n from 'vokzal-platform/i18n';

import StrijToiletView from 'vokzal-platform/components/step3/strij/base/strij_toilet/view'

StrijToiletView.propTypes = {
  title: PropTypes.string.isRequired
};

const mapStateToProps = state => ({ title : i18n.t('toilet') })

const StrijToiletContainer = connect(
  mapStateToProps
)(StrijToiletView)

export default StrijToiletContainer