// @flow
import { handleActions, combineActions } from 'redux-actions';

import {
  BLOG_POSTS_STORE_URL_ACTION, BLOG_POSTS_STORE_ACTION,
  SHOW_PRELOADER_ACTION, HIDE_PRELOADER_ACTION
} from './action';

import { blogPostsInitialStatePlatform } from 'vokzal-platform/components/blog/posts/reducer';

let blogPostsInitialState = {
  error: null,
  data_loaded: false,
  title: null,
  posts: [],
  pagination: null,
  url: null,
  counter: 0
}
blogPostsInitialState = Object.assign({}, blogPostsInitialState, blogPostsInitialStatePlatform())

const BlogPostsReducer = handleActions({
  [BLOG_POSTS_STORE_URL_ACTION]: (state, action) => {
    const counter = state.counter + 1;
    return { ...state, url: action.payload, data_loaded: false, title: null, posts: [], counter }
  },
  [BLOG_POSTS_STORE_ACTION]: (state, action) => {
    const title = action.payload.title;
    const posts = action.payload.posts;
    const pagination = action.payload.pagination;
    return { ...state, title, posts, pagination, data_loaded: true }
  },
  [combineActions(SHOW_PRELOADER_ACTION, HIDE_PRELOADER_ACTION)]: (state, action) => {
    return { ...state, show: action.meta.show }
  },
}, blogPostsInitialState)

export default BlogPostsReducer
