import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVk, faOdnoklassniki, faFacebookF, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { YMInitializer } from 'react-yandex-metrika';

const Footer = ({ about, help, blog, copyright, inn, rzd_no, rzd1, rzd2 }) => (
    <div className="page-section footer">
        <div className="row rzd-text">
            <div className="col-md-6">{rzd1}</div>
            <div className="col-md-6">{rzd2}</div>
        </div>
        <div className="row">
            <div className="col-md-4 col-lg-3 menu">
                <ul>
                    <li><a href="/index/about">{about}</a></li>
                    <li><a href="/help">{help}</a></li>
                    <li><a href="/blog">{blog}</a></li>
                </ul>
            </div>
            <div className="col-md-4 col-lg-5 social-icons">
                <ul>
                    <li className="fb"><a href="https://www.facebook.com/vokzal.ru"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                    <li className="vk"><a href="https://vk.com/vokzalru"><FontAwesomeIcon icon={faVk} /></a></li>
                    <li className="ok"><a href="https://ok.ru/group/54404616356066"><FontAwesomeIcon icon={faOdnoklassniki} /></a></li>
                    <li className="tw"><a href="http://twitter.com/vokzalru"><FontAwesomeIcon icon={faTwitter} /></a></li>
                    <li className="yo"><a href="https://www.youtube.com/channel/UCTSQD7hlgPfjP7jk8E8KirQ"><FontAwesomeIcon icon={faYoutube} /></a></li>
                </ul>
            </div>
            <div className="col-md-4 inn">
                {copyright}<br/>
                {rzd_no}<br/>
                {inn}
            </div>
        </div>

        <YMInitializer accounts={[582565]} options={{webvisor: true}}/>
    </div>

);

module.exports = Footer;