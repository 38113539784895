import moment from 'moment'
import {vokzal_config} from '../config'
import queryString from "query-string";
import getBrowserFingerprint from 'get-browser-fingerprint';

export const user_renew = (searchUrlWithParams, post, onDataLoaded, onError) => {
    const requested_at = moment();
    let fetchParams = vokzal_config.rest_api.fetchParams;

    fetchParams.body = queryString.stringify({...post, fingerprint: getBrowserFingerprint()});
    fetchParams.method = 'POST';

    fetch(vokzal_config.rest.base_url + searchUrlWithParams, fetchParams)
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.hasOwnProperty('error')) {
                onError(Object.assign(responseJson.error));
            } else {
                onDataLoaded(moment().diff(requested_at), responseJson);
            }
        });
}