// @flow
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { showMenuLink } from 'vokzal-platform/managers/navigation'

import { HeadMenuPropType } from '../../../../src/types';

import HeadMenuPediaView from 'vokzal-platform/components/shared/menu_pedia/view'

HeadMenuPediaView.propTypes = {
  items: PropTypes.arrayOf(HeadMenuPropType).isRequired,
  onMenuClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return { items : state.menu_pedia.items }
}

const mapDispatchToProps = dispatch => {
  return {
    onMenuClick: menuItem => {
      showMenuLink(menuItem);
    }
  }
}

const HeadMenuPediaContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeadMenuPediaView)

export default HeadMenuPediaContainer