// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { PediaSalePointsStationInfoPropType, PediaSalePointPropType } from '../../../types';

import PediaSalepointsView from 'vokzal-platform/components/pedia/salepoints/view'

PediaSalepointsView.propTypes = {
  loaded: PropTypes.bool.isRequired,
  station_info: PediaSalePointsStationInfoPropType,
  sale_points : PropTypes.arrayOf(PediaSalePointPropType),
};

const mapStateToProps = state => {
  return {
    loaded: state.pedia_salepoints.loaded,
    station_info: state.pedia_salepoints.station_info,
    sale_points: state.pedia_salepoints.sale_points,
  };
}

const mapDispatchToProps = dispatch => {

    return {

    }
}


const PediaSalepointsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PediaSalepointsView)

export default PediaSalepointsContainer