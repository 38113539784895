import React from 'react';

import i18n from 'vokzal-platform/i18n'

import LastochkaCabin from '../../../../../src/components/step3/lastochka/base/lastochka_cabin';
import LastochkaLuggage from '../../../../../src/components/step3/lastochka/base/lastochka_luggage';
import LastochkaLeftCabin from '../../../../../src/components/step3/lastochka/base/lastochka_left_cabin';
import LastochkaRightCabin from '../../../../../src/components/step3/lastochka/base/lastochka_right_cabin';
import LastochkaCabinDelimiter from '../../../../../src/components/step3/lastochka/base/lastochka_cabin_delimiter';
import LastochkaToilet from '../../../../../src/components/step3/lastochka/base/lastochka_toilet';

const Lastprem_w5_v1View = ({ carNumber, cabins }) => (
  <div className="train-car-select-seats-map rounded pt-2">

    <div className="train-car-select-seats-map-block train-car-map-text lastocha-service-area rounded small d-flex align-items-center rounded m-2">
      <div className="m-auto">{i18n.t('lastochka_service_area')}</div>
    </div>

      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[0]} />
       <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[1]} />
      <LastochkaCabinDelimiter />

      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[2]} />
      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[3]} />
      <div className="table table-left"></div>
      <div className="table table-right"></div>
      <div className="clear"></div>

      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[4]} />


      <div className="d-flex justify-content-between">
          <div>
              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[5]} />
              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[6]} />
              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[7]} />
              <LastochkaCabinDelimiter />
              <div className="train-car-select-seats-map-block train-car-map-text sapsan-toilet d-flex align-items-center rounded small ml-2 mr-2 mt-3 sapsan-toilet-height-standard">
                  <div className="sapsan-toilet-title m-auto">Гардероб</div>
              </div>
          </div>

          <div>
              <LastochkaLuggage className="mt-3" />
              <div className="clear"></div>

              <LastochkaToilet className="mt-3" />
              <LastochkaToilet className="mt-3" />

          </div>
      </div>



      <LastochkaCabinDelimiter />


      <div className="d-flex justify-content-between">
            <div>
                <LastochkaLuggage className="mt-3" />
                <LastochkaRightCabin carNumber={carNumber} cabinInfo={cabins[9]} />
            </div>


          <div>
              <LastochkaRightCabin carNumber={carNumber} cabinInfo={cabins[8]} />
              <div className="table table-right"></div>
              <div className="clear"></div>
          </div>
      </div>

      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[10]} />
      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[11]} />
      <div className="table table-left"></div>
      <div className="table table-right"></div>

      <div className="clear"></div>
      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[12]} />
      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[13]} />

  </div>
);

module.exports = Lastprem_w5_v1View;