import React from 'react';

import SapsanCabin from '../../../../../../src/components/step3/sapsan/base/sapsan_cabin';
import {Pets} from "@material-ui/icons";

const SapsanAnimalsCabinView = ({ carNumber, cabinInfo }) => (
  <div className="sapsan-animals-cabin rounded mt-2 ml-1 mr-1">
    <SapsanCabin carNumber={carNumber} cabinInfo={cabinInfo} inSubBlock={true} />

    <div className="sapsan-animals-cabin-logo">
        <div className="animal-icon" data-title="Разрешён провоз животных">
            <Pets />
        </div>
    </div>
  </div>
);

module.exports = SapsanAnimalsCabinView;