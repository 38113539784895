import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap';

// import 'material-design-icons';

import 'bootstrap/dist/css/bootstrap.min.css';

import './css/fonts/fonts.css';
import './css/responsive.css';

import AppRouter from './routes';

function App(props) {

    return (
        <AppRouter />
    );
}

ReactDOM.render(<App />, document.getElementById('root'));