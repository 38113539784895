import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { ticket_return } from '../../../api/fetch_ticket_return'
import { urlParamsProcessTicketReturn } from '../../../utils/url_params'

import { showTicketReturnResult } from 'vokzal-platform/managers/navigation'

import { canSubmitTicketReturnAction, errorTicketReturnDetailsAction } from '../return_form/action';


import ProcessButtonView from 'vokzal-platform/components/ticket_return/process_button/view'

ProcessButtonView.propTypes = {
  isEnabled : PropTypes.bool.isRequired,
  urlWithParams: PropTypes.string,
  onButtonClick : PropTypes.func.isRequired,
  onDataLoaded : PropTypes.func.isRequired,
  onError : PropTypes.func.isRequired,
};

const mapStateToProps = state => {

  const isEnabled = state.ticket_return.can_submit_form;
  const urlWithParams = urlParamsProcessTicketReturn(state.ticket_return);
  return { isEnabled, urlWithParams };
};

const mapDispatchToProps = dispatch => {
  return {
    onButtonClick : (isEnabled, urlWithParams, onDataLoaded, onError) => {
      if (isEnabled) {
        dispatch(canSubmitTicketReturnAction(false))
        ticket_return(urlWithParams, onDataLoaded, onError);
      }
    },
    onDataLoaded : (time_spent, response, urlWithParams) => {
      showTicketReturnResult(dispatch, urlWithParams);
    },
    onError : (error) => {
      dispatch(errorTicketReturnDetailsAction(error));
    }
  }
};

const ProcessButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessButtonView);

export default ProcessButtonContainer