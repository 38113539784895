import { handleActions } from 'redux-actions'

import { stationsListInitialStatePlatform } from 'vokzal-platform/components/pedia/stations_list/reducer';

import { STATIONS_FETCH_COMPLETE_ACTION, STATIONS_URL_STORE_ACTION, TOGGLE_STATIONS_GROUP_BLOCK } from "./action";

const defaultState = {
  loaded : false, urlWithParams: '/stanciya', station_groups : [],
}

const initialState = Object.assign({}, defaultState, stationsListInitialStatePlatform())

const PediaStationsListReducer = handleActions({
    [TOGGLE_STATIONS_GROUP_BLOCK]: (state, action) => {

      const station_groups = state.station_groups.map((group) => {
        group.isExpanded = (action.payload === group.title) ? !group.isExpanded : group.isExpanded
        return group;
      });

      return { ...state, station_groups }
    },
    [STATIONS_FETCH_COMPLETE_ACTION]: (state, action) => {

      return {...state, loaded : true, station_groups: action.payload.response}
    },
    [STATIONS_URL_STORE_ACTION]: (state, action) => {

      let new_state = {}
      if (state.urlWithParams !== action.payload) {
        new_state = defaultState;
      }
      new_state.urlWithParams = action.payload;

      return {...state, ...new_state}
    },
}, initialState)

export default PediaStationsListReducer