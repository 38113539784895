import React from 'react';
import { Collapse } from 'react-bootstrap'

import PediaOrderFormShortBus from '../../../../src/components/pedia/order_form_short_bus';
import PediaBusModal from '../../../../src/components/pedia/bus_modal';

const PediaOrderBlockBusView = ({ scheduleGroup, bus, orderButtonTitle, showOrderForm, onOrderClick, show }) => {
    bus.arrival.busStop = {};
    bus.departure.busStop = {};
    bus.arrival.busStop.id = bus.arrival.parent_id ? bus.arrival.parent_id : bus.arrival.id;
    bus.departure.busStop.id = bus.departure.parent_id ? bus.departure.parent_id : bus.departure.id;
    bus.ab = bus.departure.name + ' - ' + bus.arrival.name;
    return (
      <div className="pedia-schedule-order mt-3">
          {show ? <PediaBusModal className="bus-list"/> : null}
        {
          (!showOrderForm) ?
              <button type="button" className="btn btn-outline-success btn-block text-uppercase font-weight-medium" onClick={() => onOrderClick(scheduleGroup, bus)}>
                {orderButtonTitle}
              </button>
            : null
        }

        <Collapse in={showOrderForm}>
          <div>
            <PediaOrderFormShortBus bus={bus} />
          </div>
        </Collapse>
      </div>
    )};

module.exports = PediaOrderBlockBusView;