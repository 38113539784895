import React from 'react';

import i18n from 'vokzal-platform/i18n';
import PageSection from '../base/section/view';
import SectionTitle from '../base/section_title/view';
import FooterLinksRow from '../../../../src/components/home/footer_links_row';
import {cols6, order6} from "../../../../src/utils/functions";

const FooterLinksView = ({ items }) => {
    if (items.length === 0) {
        return null;
    }
    let result = cols6(items.length);
    let data = [...items];

    return <PageSection className="footer-links">
        <SectionTitle className="font-weight-medium">{i18n.t('popular_routes')}</SectionTitle>
        <div className="row">
            {
                result.map((elem, index) => {
                    let list = data.splice(0, elem);
                    return <Block list={list} i={index} key={index}/>
                })
            }
        </div>
    </PageSection>
};

const Block = ({ list, i }) => {
    let order = order6(i);
    return <div className={"popular-block col-md-6 col-lg-4 " + order}>
        {list.map((footerLinkItem, index) => (
            <FooterLinksRow key={index} footerLinkItem={footerLinkItem} />
        ))}
    </div>
};

module.exports = FooterLinksView;