import React from 'react';

import PediaOrderFormShortBus from '../../../../src/components/pedia/order_form_short_bus';

import Smart from 'smart-plurals';
const russian = Smart.Plurals.getRule('ru');
const stopsPlurals = ['остановку', 'остановки', 'остановок'];
import { getWayTimeOutputFormat } from '../../../../src/utils/functions';
import PediaBusModal from '../../../../src/components/pedia/bus_modal';

const PediaBusInfoView = ({ bus, run_info, max_station_time_city_name, max_station_time, price, stops_count, show }) => (
  <div className="pedia-train-info">
      {show ? <PediaBusModal className="bus-list"/> : null}
    <div className="page-section pb-4">
      <h3 className="section-title">Курсирует</h3>

      <div className="d-flex flex-wrap no-gutters pt-2">
        <div className="col-6 d-flex align-items-start flex-column">
          <div className="small">Время отправления от станции <a href={"/stanciya-bus/" + run_info.departure_url_name + "/" + bus.departure.busStop.id}>{run_info.departure_name}</a></div>
          <div className="h4 font-weight-bold mb-0 mt-auto">{run_info.departure_time}</div>
        </div>
        <div className="col-6 d-flex align-items-end flex-column">
          <div className="small">Прибытие на станцию <a href={"/stanciya-bus/" + run_info.arrival_url_name + "/" + bus.arrival.busStop.id}>{run_info.arrival_name}</a></div>
          <div className="h4 font-weight-bold mb-0 mt-auto">{run_info.arrival_time}</div>
        </div>
      </div>

      <PediaOrderFormShortBus bus={bus} />
    </div>
      <div className="page-section mt-4">
      <p>Посмотреть расписание и купить билет на автобус <a href={"/raspisanie-bus/" + run_info.departure_url_name + "/" + run_info.arrival_url_name + "/" + bus.departure.busStop.id + "/" + bus.arrival.busStop.id}>{run_info.departure_name} - {run_info.arrival_name}</a> Без номера</p>
      </div>

    <div className="page-section mt-4">
      <h3 className="section-title">Описание</h3>
      <div>
        <p>
          Автобус {run_info.run_number} отправляется от станции {run_info.departure_name} - {run_info.departure_time}, прибывает на станцию {run_info.arrival_name} - {run_info.arrival_time}
        </p>

        <p>
          {
            (stops_count > 0)
              ? 'На пути следования автобус делает ' + stops_count + ' ' + russian(stops_count, stopsPlurals) + '. '
              : 'На пути следования автобус не делает остановок. '
          }
            Время в пути: {getWayTimeOutputFormat(run_info.time_in_way, true)}.
        </p>

        {
            (price)
              ? <div>
                  <p>Средняя стоимость билета на автобус {run_info.run_number} составляет {avgPrice}, из них: </p>
                  <ul>
                    {priceList.map((p, index) => <li key={index}>{p}</li>)}
                  </ul>
                </div>
              : null
        }

        <p>
            Забронируйте прямо сейчас билет на автобус {run_info.run_number} на сайте Вокзал.ру за 2 минуты.
        </p>
      </div>
    </div>

    <div className="pt-4">
      <PediaOrderFormShortBus bus={bus} />
    </div>
  </div>
);

module.exports = PediaBusInfoView;