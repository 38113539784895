import React from 'react';
import BusRow from '../../../../src/components/step2/bus_row';

import { Step2AlternativePreLoader } from '../../../../src/components/shared/preloader';

const BusesSearchResultAlternativeView = ({ showPreloader, buses }) => (
  <div>
    <div className="search-results mr-2 ml-2 mb-4 pt-2 d-flex flex-column">
      {
        buses.map(function(m, index){
          return (<BusRow key={index} busItem={m} />);
        })
      }
    </div>

    <Step2AlternativePreLoader show={showPreloader} />
  </div>
);

module.exports = BusesSearchResultAlternativeView;