// @flow
import { handleActions } from 'redux-actions'

import {
  STEP4_SHOW_ACTION,
  FORM_BUTTON_STATE_ACTION,
  FORM_STORE_ERROR_ACTION,
  FORM_RESET_ERROR_ACTION,
  FORM_DATA_CHANGED_ACTION
} from './action'

import getDocumentMask from './document_mask'

export const Step4PassengerDataReducer = handleActions({
  [STEP4_SHOW_ACTION]: (state, action) => {
    return { ...state, }
  },
  [FORM_BUTTON_STATE_ACTION]: (state, action) => {
    return { ...state, button_state: action.payload }
  },
  [FORM_STORE_ERROR_ACTION]: (state, action) => {
    return { ...state, error: action.payload }
  },
  [FORM_RESET_ERROR_ACTION]: (state, action) => {
    return { ...state, error: null }
  }
  ,
  [FORM_DATA_CHANGED_ACTION]: (state, action) => {

    return { ...state, document_mask: action.payload.field === "document_type" ? getDocumentMask(action.payload.value) : undefined }
  }
}, { button_state: 'NEW', error: null })
