import React from 'react';

import PageSection from '../base/section/view';
import SectionTitle from '../base/section_title/view';
import PopularCityRow from '../../../../src/components/home/popular_city_row';

import i18n from 'vokzal-platform/i18n';

const PopularCityView = ({ show, items }) => {

  if (!show) {
    return null;
  }

  return (
      <PageSection className="popular-city">
          <SectionTitle>{i18n.t('popular_cities')}</SectionTitle>

          {
              items.map(function(m, index){
                  return (<PopularCityRow key={index} popularCityItem={m} />);
              })
          }
      </PageSection>
  );
}
module.exports = PopularCityView;