const stdSeat = (number,  isFront) => (
  { number,  isFront, type : 'std'}
)

const kupeSeat = (number,  isFront) => (
  { number,  isFront, type : 'kupe'}
)

const sideSeat = (number,  isFront, isDisabled) => (
  { number,  isFront, type : (isDisabled) ? 'side-disabled-person' : 'side'}
)

const reverseIsFront = (seat) => {

  if (seat !== null) {
    let s = Object.assign({}, seat);
    s.isFront = !s.isFront;

    return s;
  } else {
    return seat;
  }
}

// export const SeatNumbersStrijLuxCar = [
//   { centerTopLower : kupeSeat(1, true),  centerBottomLower : kupeSeat(2, false),  inverted : false },
//   { centerTopLower : kupeSeat(3, true),  centerBottomLower : kupeSeat(4, false),  inverted : true  },
//   { centerTopLower : kupeSeat(5, true),  centerBottomLower : kupeSeat(6, false),  inverted : false },
//   { centerTopLower : kupeSeat(7, true),  centerBottomLower : kupeSeat(8, false),  inverted : true  },
//   { centerTopLower : kupeSeat(9, true),  centerBottomLower : kupeSeat(10, false), inverted : false },
// ]

export const SeatNumbersStrijLuxCar = [
  { leftWindow : null,   leftSide : null, rightSide : stdSeat(1, true), rightWindow : stdSeat(2, true)   },
  { leftWindow : null,   leftSide : null, rightSide : stdSeat(3, false), rightWindow : stdSeat(4, false)   },
  { leftWindow : null,   leftSide : null, rightSide : stdSeat(5, true), rightWindow : stdSeat(6, true)   },
  { leftWindow : null,   leftSide : null, rightSide : stdSeat(7, false), rightWindow : stdSeat(8, false)   },
  { leftWindow : null,   leftSide : null, rightSide : stdSeat(9, true), rightWindow : stdSeat(10, true)   },
]

export const SeatNumbersStrijFirstClassCar = [
  { leftWindow : stdSeat(3, false),   leftSide : null,                rightSide : null,                rightWindow : stdSeat(1, false)   },
  { leftWindow : stdSeat(7, false),   leftSide : null,                rightSide : stdSeat(6, false),   rightWindow : stdSeat(5, false)   },
  { leftWindow : stdSeat(11, false),  leftSide : null,                rightSide : stdSeat(10, false),  rightWindow : stdSeat(9, false)   },
  { leftWindow : stdSeat(15, false),  leftSide : null,                rightSide : stdSeat(14, false),  rightWindow : stdSeat(13, false)  },
  { leftWindow : stdSeat(19, false),  leftSide : null,                rightSide : stdSeat(18, false),  rightWindow : stdSeat(17, false)  },
  { leftWindow : stdSeat(23, false),  leftSide : null,                rightSide : stdSeat(22, false),  rightWindow : stdSeat(21, false)  },
  { leftWindow : stdSeat(27, false),  leftSide : null,                rightSide : stdSeat(26, false),  rightWindow : stdSeat(25, false)  },
]

export const SeatNumbersStrijEconomCar = [
  { leftWindow : stdSeat(1, false),   leftSide : stdSeat(2, false),   rightSide : stdSeat(4, false),   rightWindow : stdSeat(3, false)   },
  { leftWindow : stdSeat(5, false),   leftSide : stdSeat(6, false),   rightSide : stdSeat(8, false),   rightWindow : stdSeat(7, false)   },
  { leftWindow : stdSeat(9, false),   leftSide : stdSeat(10, false),  rightSide : stdSeat(12, false),  rightWindow : stdSeat(11, false)  },
  { leftWindow : stdSeat(13, false),  leftSide : stdSeat(14, false),  rightSide : stdSeat(16, false),  rightWindow : stdSeat(15, false)  },
  { leftWindow : stdSeat(17, false),  leftSide : stdSeat(18, false),  rightSide : stdSeat(20, false),  rightWindow : stdSeat(19, false)  },
  { leftWindow : stdSeat(21, false),  leftSide : stdSeat(22, false),  rightSide : stdSeat(24, false),  rightWindow : stdSeat(23, false)  },
  { leftWindow : stdSeat(25, false),  leftSide : stdSeat(26, false),  rightSide : stdSeat(28, false),  rightWindow : stdSeat(27, false)  },
  { leftWindow : stdSeat(29, false),  leftSide : stdSeat(30, false),  rightSide : stdSeat(34, false),  rightWindow : stdSeat(33, false)  },
  { leftWindow : stdSeat(31, true),   leftSide : stdSeat(32, true),   rightSide : stdSeat(36, true),   rightWindow : stdSeat(35, true)   },
]

export const SeatNumbersStrizh_1_2k_v1 = [
  { centerTopUpper : null,  centerTopLower : null,  centerBottomUpper : 15,  centerBottomLower : 11 },
  { centerTopUpper : 25,  centerTopLower : 21,  centerBottomUpper : 26,  centerBottomLower : 22 },
  { centerTopUpper : 35, centerTopLower : 31,  centerBottomUpper : 36, centerBottomLower : 32 },
  { centerTopUpper : 45, centerTopLower : 41, centerBottomUpper : 46, centerBottomLower : 42 },
  { centerTopUpper : 55, centerTopLower : 51, centerBottomUpper : 56, centerBottomLower : 52 },
]

export const SeatNumbersStrizh_1_1u_v1 = [
  { centerTopUpper : null,  centerTopLower : null,  centerBottomUpper : 32,  centerBottomLower : 36 },
  { centerTopUpper : 35,  centerTopLower : 31,  centerBottomUpper : null,  centerBottomLower : null },
  { centerTopUpper : null, centerTopLower : null,  centerBottomUpper : 26, centerBottomLower : 22 },
  { centerTopUpper : 25, centerTopLower : 21, centerBottomUpper : null, centerBottomLower : null },
  { centerTopUpper : null, centerTopLower : null, centerBottomUpper : 16, centerBottomLower : 12 },
  { centerTopUpper : 15, centerTopLower : 11, centerBottomUpper : null, centerBottomLower : null },

]

// STRIZH_1_1I_V1 - 15, 17
// STRIZH_1_1A_V1 - 14, 16, 18
export const SeatNumbersStrizh_1_1a_v1 = [
  { centerTopUpper : null,  centerTopLower : null,  centerBottomUpper : 15,  centerBottomLower : 11 },
  { centerTopUpper : 16,  centerTopLower : 12,  centerBottomUpper : null,  centerBottomLower : null },
  { centerTopUpper : null, centerTopLower : null,  centerBottomUpper : 25, centerBottomLower : 21 },
  { centerTopUpper : 26, centerTopLower : 22, centerBottomUpper : null, centerBottomLower : null },
  { centerTopUpper : null, centerTopLower : null, centerBottomUpper : 35, centerBottomLower : 31 },
]
