import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from "prop-types";
import {searchFormUrlBus} from "../../../../src/utils/url_params";

const PediaBusModalView = ({ show, onCloseClick, className, buses, searchFormState, onClickBus }) => (
  <div className={className}>

      <Modal show={show} animation={false} dialogClassName="h-100 oferta-modal" backdropClassName="show resp-search-modal">

          <Modal.Header>
              <div className="d-flex flex-column">
                  <div className="p-0 d-flex justify-content-between">
                      <Modal.Title id="contained-modal-title-lg" componentClass="h5"><strong>Выберите автобус</strong></Modal.Title>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onCloseClick() }>
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
              </div>
          </Modal.Header>

          <Modal.Body bsClass="modal-body pt-0">
              <div className="link-group">
                  {

                      buses.map(function(m, index){
                          m.key = index;
                          let step3Url = searchFormUrlBus(searchFormState.type_data.form, searchFormState.type_data.bus, m.id, m.run_number_url);
                          return <p key={index} onClick={() => onClickBus(step3Url, m.price)}>{`Время отправления ${m.departure.time}. Стоимость ${m.price.price}р.`}</p>;
                      })
                  }
              </div>
              <button className="close close-bottom" onClick={() => onCloseClick()}>Закрыть</button>
          </Modal.Body>
      </Modal>
  </div>
);

PediaBusModalView.propTypes = {
    show: PropTypes.bool.isRequired,
    onCloseClick: PropTypes.func.isRequired,
};

module.exports = PediaBusModalView;