import React from 'react';
import BootstrapLabeledInput from '../../base/bootstrap_labeled_input';

const CountryTextInputView = ({ show, ticketNumber, name, value, label, fieldsetClass, className, placeholder, onCountryClick }) => {

  if (!show) {
      return null;
  }

  return <BootstrapLabeledInput fieldsetClass={fieldsetClass} placeholder={placeholder} name={name} value={value}
                                className={"font-medium-light " + (className || '')} label={label} required={true}
                                onClick={() => { onCountryClick() }}
  />
};

module.exports = CountryTextInputView;