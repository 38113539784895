// @flow

import { connect } from 'react-redux'

import HomeExtraTextView from 'vokzal-platform/components/home/extra_text/view'

const mapStateToProps = (state, ownProps) => (
  { extra_html: state.home_extra_text.extra_html }
)

const HomeExtraTextContainer = connect(
    mapStateToProps
)(HomeExtraTextView)

export default HomeExtraTextContainer