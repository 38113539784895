import { createAction } from 'redux-actions'

export const USER                    = 'USER'
export const userAction               = createAction(USER)

export const USER_LOGIN                    = 'USER/LOGIN'
export const userLoginAction               = createAction(USER_LOGIN)

export const USER_SET_VALUE                    = 'USER/SET/VALUE'
export const userSetValueAction               = createAction(USER_SET_VALUE)

export const USER_SET_PROFILE_VALUE                    = 'USER/SET/PROFILE/VALUE'
export const userSetProfileValueAction               = createAction(USER_SET_PROFILE_VALUE)

export const USER_ORDERS_FETCH_COMPLETE_ACTION       = 'USER/ORDERS/FETCH/COMPLETE'
export const userOrdersFetchCompleteAction          = createAction(USER_ORDERS_FETCH_COMPLETE_ACTION)

export const USER_ORDER_FETCH_COMPLETE_ACTION       = 'USER/ORDER/FETCH/COMPLETE'
export const userOrderFetchCompleteAction          = createAction(USER_ORDER_FETCH_COMPLETE_ACTION)

export const USER_PASSENGERS_FETCH_COMPLETE_ACTION       = 'USER/PASSENGERS/FETCH/COMPLETE'
export const userPassengersFetchCompleteAction          = createAction(USER_PASSENGERS_FETCH_COMPLETE_ACTION)

export const USER_PASSENGER_DELETE_ACTION       = 'USER/PASSENGER/DELETE'
export const userPassengerDeleteAction          = createAction(USER_PASSENGER_DELETE_ACTION)

export const USER_ORDER_CHECK_PASSENGER_ACTION       = 'USER/ORDER/CHECK/PASSENGER'
export const userOrderCheckPassengerAction          = createAction(USER_ORDER_CHECK_PASSENGER_ACTION)

export const USER_ORDER_CHECK_ER_ACTION       = 'USER/ORDER/CHECK/ER'
export const userOrderCheckErAction          = createAction(USER_ORDER_CHECK_ER_ACTION)

export const USER_ORDER_DELETE_ACTION       = 'USER/ORDER/DELETE'
export const userOrderDeleteAction          = createAction(USER_ORDER_DELETE_ACTION)

// export const SHOW_USER_TAB                 = 'USER/TAB/SHOW'
// export const showUserTabAction          = createAction(SHOW_USER_TAB);