import React from 'react';

import BootstrapInput from './bootstrap_input';
import BootstrapLabel from './bootstrap_label';

const BootstrapLabeledInput = (props) => {

    if (props.show === false) {
        return null;
    }

    return (
        <fieldset id={(props.id) ?  props.id+"-fieldset" : null} className={(props.fieldsetClass || '') + " form-group"} onClick={props.onClick}>
            <BootstrapInput {...props} className={(props.className || '') + ((props.value) ? " active" :"")} />
            {props.label ? <BootstrapLabel>{props.label}</BootstrapLabel> : null}
        </fieldset>
    );
}

module.exports = BootstrapLabeledInput;