import React from 'react';

import { BlogPreLoader } from '../../../../src/components/shared/preloader';
import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';

import BlogTitle from '../../../../src/components/blog/title';
import BlogSinglePostContent from '../../../../src/components/blog/single_post_content';


const BlogSinglePostView = ({ data_loaded }) => (

  <div className="blog">
    <div className="blog-block">
      <div className="blog-header-block">
        <HeadMenuPedia />

        <BlogTitle type="single_post" />
      </div>

      <div className="blog-content pt-3">
        {(data_loaded) ? <BlogSinglePostContent /> : null}
      </div>

      <BlogPreLoader show={!data_loaded} />
    </div>

  </div>
);

module.exports = BlogSinglePostView;