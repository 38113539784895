import React from 'react';

import { RatingBus } from '../../../../src/components/step2/rating';
import { DepartureArrivalBus } from '../../../../src/components/step2/departure_arrival';

const BusInfoView = ({ busItem }) => (
  <div className="bus-info ml-2 mt-2 mr-2 mb-1 ml-md-5 mr-md-5">
    <div className="d-flex justify-content-between w-100">
      <div className="bus-details w-100 mt-1">
        <div className="bus-name d-flex no-gutters mb-1">
          <div className="col-1 mr-1">
            <div className="bus-logo" />
          </div>
          <div className="col-11">
            <div className="font-weight-medium font-medium-light text-truncate">
              {busItem.run_number} {busItem.transport}
            </div>
          </div>
        </div>

        <div className="text-capitalize font-weight-medium small mb-1">{busItem.departure.name} &mdash; {busItem.arrival.name}</div>
        <div className="d-flex align-items-center mb-1">
          <div className="font-light small text-capitalize text-nowrap">{busItem.carrier}</div>
          <RatingBus ratingItem={null} />
        </div>
      </div>

    </div>

    <DepartureArrivalBus busItem={busItem} />
  </div>
);

module.exports = BusInfoView;