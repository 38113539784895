import merge from 'deepmerge';

import default_config from './default.config'
import development from './development.config'
import stage from './stage.config'
import production from './production.config'

import env from 'vokzal-platform/env';

let c = {};

if (env === 'development') {
    c = merge(default_config, development);
} else if (env === 'stage') {
    c = merge(default_config, stage);
} else if (env === 'production') {
    c = merge(default_config, production);
} else {
    c = merge(default_config);
}

//костыль, иначе не пашет (из-за копирования объекта)
c.rest.fetchParams.headers = new Headers({"Accept" : "text/json"})

c.rest_api.fetchParams.headers = new Headers({"Content-Type" : "application/x-www-form-urlencoded; charset=utf-8"})
// c.rest_api.fetchParams.headers = new Headers({"Accept" : "text/json"})

export const vokzal_config = c;