// @flow
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { SearchResultTabPropType } from '../../../../src/types/'

import SearchResultTabsView from 'vokzal-platform/components/step2/search_result_tabs/view'

SearchResultTabsView.propTypes = {
  items : PropTypes.arrayOf(SearchResultTabPropType).isRequired
};

const mapStateToProps = state => {
  return { items: state.step2_tabs.tabs, counter: state.step2_tabs.counter }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

const SearchResultTabsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultTabsView)

export default SearchResultTabsContainer