import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import StrizhKupeCabinView from 'vokzal-platform/components/step3/strij/strizh_kupe_cabin/view'
import {SeatNumbersByCarTypes, SeatNumbersKupePropType, SeatNumbersByCarSchema} from '../../../../../src/types/';
import {kupe_select_mf} from "../../action";

StrizhKupeCabinView.propTypes = {
    carNumber : PropTypes.number.isRequired,
    cabins : PropTypes.arrayOf(SeatNumbersKupePropType).isRequired
};

const mapStateToProps = state => {

    const carSelected = state.step3_categories.car_selected;
    const seatsSelected = state.step3_passengers.selected_seats;
    const trainType = state.step3_categories.car_selected.trainType;
    const carType = state.step3_categories.car_selected.category;
    const carSchema = state.step3_categories.car_selected.schema;
    const carService = state.step3_categories.car_category_selected.service.code;
    const cabins = SeatNumbersByCarSchema[trainType].hasOwnProperty(carSchema) ? SeatNumbersByCarSchema[trainType][carSchema] : SeatNumbersByCarTypes[trainType][carService];

    let kupeNumber; // номер купе для данного места
    if (seatsSelected.length > 0) {

        const seat = seatsSelected[0].seatNumber;
        cabins.forEach(function(item, i) {
            for (let key in item) {
                // тело цикла выполняется для каждого свойства объекта
                // if (item[key] === seatsSelected[0].seatNumber) {
                if (item[key] === seat) {
                    kupeNumber = i;
                    break;
                }
            }
            return;
        });
    }
    const kupeMF = state.step3_passengers.select_mf;

    return { carSelected, kupeNumber, kupeMF, cabins }
};

const mapDispatchToProps = dispatch => {
    return {
        onSelectMF : (param) => {
            dispatch(kupe_select_mf(param));
        },
    }
};

const StrizhKupeCabinContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StrizhKupeCabinView);

export default StrizhKupeCabinContainer