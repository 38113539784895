import React, {lazy, Suspense} from 'react';
import i18n from 'vokzal-platform/i18n';

import TrainSeatClass from '../../../../src/components/step3/train_seat_class';
import TrainSeatClassInfo from '../../../../src/components/step3/train_seat_class_info';
import TrainCar from '../../../../src/components/step3/train_car';
import TrainMap from '../../../../src/components/step3/train_map';
const SelectedSeats = lazy(() => import('../../../../src/components/step3/selected_seats'));

const TrainSeatsView = ({ seatClasses, carCategory }) => {
    let categories = new Set();

    return(
  <div className="trains-seats page-section">
       <div className="section-title">{i18n.t('select_seats')}</div>

      <div className="trains-seats-class-block">
        {
          seatClasses.map((items, indexItems) => (
            <div key={indexItems} className="trains-seats-class-row d-flex no-gutters">
              {
                items.map((item, indexItem) => {
                    categories.add(item.type);
                    return !carCategory || item.type === carCategory ? <TrainSeatClass key={indexItem} categoryItem={item} carCategory={carCategory} /> : null
                })
              }
            </div>
          ))
        }
      </div>
      <TrainSeatClassInfo />

      <TrainCar categories={categories}/>

      <TrainMap />

      <Suspense fallback={<div>Загрузка...</div>}>
          <SelectedSeats />
      </Suspense>
  </div>
)};

module.exports = TrainSeatsView;