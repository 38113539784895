import moment from 'moment';

import { vokzal_config } from '../config';

export const ticket_return = (searchUrlWithParams, onDataLoaded, onError) => {
    const requested_at = moment();

    fetch(vokzal_config.rest.base_url + searchUrlWithParams, vokzal_config.rest.fetchParams)
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.hasOwnProperty('error')) {
            onError(Object.assign({searchUrlWithParams}, responseJson.error));
          } else {
            onDataLoaded(moment().diff(requested_at), responseJson, searchUrlWithParams);
          }
        });
};