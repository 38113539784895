import React from 'react';

import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'

import logger from 'redux-logger'

import createHistory from 'history/createBrowserHistory'
import { Route } from 'react-router'

import { gaPageView } from './ga'

import { ConnectedRouter, routerMiddleware, push } from 'react-router-redux'

import Home from '../src/components/home';
import HomeExtraText from '../src/components/home/extra_text';
import About from '../src/components/home/about';
import TestWD from '../src/components/test_wd';
import Step2 from '../src/components/step2';
import Step2Alternative from '../src/components/step2/alternative';
import Step3 from '../src/components/step3';
import Step3Bus from '../src/components/step3bus';
import Step4 from '../src/components/step4';
import Confirmation from '../src/components/confirmation';
import TicketReturn from '../src/components/ticket_return';
import TicketReturnConfirmation from '../src/components/ticket_return/confirmation';
import TicketReturnResult from '../src/components/ticket_return/result';

import TicketResult from '../src/components/ticket_result';

import PediaStationsList from '../src/components/pedia/stations_list';
import PediaStation from '../src/components/pedia/station';
import PediaRegion from '../src/components/pedia/region';

import PediaStationBus from '../src/components/pedia/station_bus';

import PediaTrainsList from '../src/components/pedia/trains_list';
import PediaTrain from '../src/components/pedia/train';

import PediaBus from '../src/components/pedia/bus';

import PediaSalepoints from '../src/components/pedia/salepoints';

import PediaRailroad from '../src/components/pedia/railroad';
import PediaRailroadDetails from '../src/components/pedia/railroad_details';

import PediaRaspisanie from '../src/components/pedia/raspisanie';
import PediaRaspisanieBus from '../src/components/pedia/raspisanie_bus';

import BlogPosts from '../src/components/blog/posts';
import BlogSinglePost from '../src/components/blog/single_post';

import Help from '../src/components/help';

import User from '../src/components/user';
import Profile from '../src/components/user/profile';
import Orders from '../src/components/user/orders';
import Order from '../src/components/user/order';
import Passengers from '../src/components/user/passengers';
import Activate from '../src/components/user/activate';
import Review from '../src/components/user/review';
import Unsubscribe from '../src/components/user/unsubscribe';
import Admin from '../src/components/admin';
import Stats from '../src/components/admin/stats';
import Header from '../src/components/shared/header';
import Footer from '../src/components/shared/footer';

const history = createHistory();
const router = routerMiddleware(history);

history.listen((location, action) => {
    // location is an object like window.location
  gaPageView(location.pathname + location.search);

  window.scrollTo(0, 0)
})

// import vokzalAppReducers from './reducers'
import vokzalAppReducers from '../../app/src/reducers/app_reducers';
let store = createStore(vokzalAppReducers, applyMiddleware(router));
// let store = createStore(vokzalAppReducers, applyMiddleware(router, logger));

export default () => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <div>
                <div className="content">
                    <Header />
                    <Route path='/'                    component={Home}              exact={true} />
                    <Route path='/testWD'              component={TestWD}            exact={true} />
                    <Route path='/step2/index'         component={Step2}             exact={false} />
                    <Route path='/step2/alternative'   component={Step2Alternative}  exact={false} />
                    <Route path='/step3/index'         component={Step3}             exact={false} />
                    <Route path='/step3/bus'           component={Step3Bus}          exact={false} />
                    <Route path='/step4/'              component={Step4}             exact={false} />
                    <Route path='/confirmation/'       component={Confirmation}      exact={false} />

                    <Route path='/return'              component={TicketReturn}             exact={true} />
                    <Route path='/return?pin'          component={TicketReturn}             exact={false} />
                    <Route path='/return/confirmation' component={TicketReturnConfirmation} exact={false} />
                    <Route path='/return/result'       component={TicketReturnResult}       exact={false} />

                    <Route path='/stanciya'                component={PediaStationsList} exact={true} />
                    <Route path='/region/'                 component={PediaRegion}       exact={false} />
                    <Route path='/stanciya/:title/:id'     component={PediaStation}      exact={false} />
                    <Route path='/stanciya-bus/:title/:id' component={PediaStationBus}   exact={false} />

                    <Route path='/salepointinfo'           component={PediaSalepoints} exact={false} />

                    <Route path='/poezda'              component={PediaTrainsList}   exact={true} />
                    <Route path='/poezda/:title'       component={PediaTrain}        exact={false} />

                    <Route path='/bus/:title1/:title2/:titleBus/:id' component={PediaBus}    exact={false} />

                    <Route path='/doroga'              component={PediaRailroad}         exact={true} />
                    <Route path='/doroga/:title/:id'   component={PediaRailroadDetails}  exact={false} />

                    <Route path='/raspisanie/:title1/:title2/:id1/:id2'     component={PediaRaspisanie}        exact={false} />
                    <Route path='/raspisanie-bus/:title1/:title2/:id1/:id2' component={PediaRaspisanieBus}        exact={false} />

                    <Route path='/blog/category'                 component={BlogPosts}             exact={false} />
                    <Route path='/blog/'                         component={BlogPosts}             exact={true} />
                    <Route path='/blog/page/:page'               component={BlogPosts}             exact={true} />
                    <Route path='/blog/:title'                   component={BlogSinglePost}        exact={true} />
                    <Route path='/blog/:year/:month/:day/:title' component={BlogSinglePost}        exact={true} />

                    <Route path='/help'                          component={Help}                  exact={true} />
                    <Route path='/help/:url'                     component={Help}                  exact={false} />

                    <Route path='/index/about'                   component={About}                 exact={true} />


                    <Route path='/:page(raspisanie-poezdov|bronirovanie-zhd-biletov|elektronnye-zhd-bilety|zakaz-zhd-biletov|pokupka-zhd-biletov|tseny-na-zhd-bilety|mesta-bilety)'
                           component={HomeExtraText}         exact={false} />

                    {/*<Route path='/user'         component={User} exact={true} />*/}
                    <Route path='/user/profile' component={Profile} exact={true} />
                    <Route path='/user/orders' component={Orders} exact={true} />
                    <Route path='/user/orders/:number' component={Order} exact={false} />
                    <Route path='/user/passengers' component={Passengers} exact={true} />
                    <Route path='/user/activate' component={Activate} exact={true} />

                    <Route path='/result/success' component={TicketResult} exact={true} />

                    <Route path='/user/review' component={Review} exact={true} />
                    <Route path='/user/unsubscribe' component={Unsubscribe} exact={true} />
                    <Route path='/user/admin' component={Admin} exact={true} />
                    <Route path='/user/admin/stats' component={Stats} exact={true} />
                    {/*<Route path='/user/review/:code' component={Review} exact={false} />*/}
                </div>

                <Footer />
            </div>
        </ConnectedRouter>
    </Provider>
);