import React from 'react';

import SeatedCabinPlace from '../../../../src/components/step3/seated_cabin_place';

const SeatedCabinView = ({ carNumber, title, cabinInfo, secondFloor }) => {
    const plusSeat = secondFloor === 1 ? 80 : 0;
    return (
    <div className="kupe">
        <div className="train-car-map-seated-cabin align-items-end flex-column d-none d-lg-flex ">
            <div className="train-car-map-seated-cabin-place-block">
                { (cabinInfo.rightWindow !== null) ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.rightWindow.number + plusSeat} isFront={cabinInfo.rightWindow.isFront} className="horizontal-place" /> : null }
                { (cabinInfo.rightSide !== null) ?   <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.rightSide.number + plusSeat}   isFront={cabinInfo.rightSide.isFront} className="horizontal-place" />   : null }
            </div>

            <div className="train-car-map-seated-cabin-place-block side">
                { (cabinInfo.leftSide !== null)   ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.leftSide.number + plusSeat}   isFront={cabinInfo.leftSide.isFront} className="horizontal-place" />   : null }
                { (cabinInfo.leftWindow !== null) ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.leftWindow.number + plusSeat} isFront={cabinInfo.leftWindow.isFront} className="horizontal-place" /> : null }
            </div>
        </div>



        <div className="train-car-map-seated-cabin d-flex justify-content-between d-lg-none">
            <div className="train-car-map-seated-cabin-place-block d-flex">
                { (cabinInfo.leftWindow !== null) ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.leftWindow.number + plusSeat} isFront={cabinInfo.leftWindow.isFront} /> : null }
                { (cabinInfo.leftSide !== null)   ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.leftSide.number + plusSeat}   isFront={cabinInfo.leftSide.isFront} />   : null }
            </div>

            <div className="train-car-map-seated-cabin-place-block d-flex">
                { (cabinInfo.rightSide !== null) ?   <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.rightSide.number + plusSeat}   isFront={cabinInfo.rightSide.isFront} />   : null }
                { (cabinInfo.rightWindow !== null) ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.rightWindow.number + plusSeat} isFront={cabinInfo.rightWindow.isFront} /> : null }
            </div>
        </div>



    </div>
)};

module.exports = SeatedCabinView;