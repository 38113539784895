import React from 'react';
import PropTypes from 'prop-types';

import CabinPlace from '../../../../../src/components/step3/cabin_place';
import CabinPlaceHorizontal from '../../../../../src/components/step3/cabin_place_horizontal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMale, faFemale, faFaucet, faShower } from '@fortawesome/free-solid-svg-icons'
import {getSexInfo} from "../../../../../src/utils/functions";
import i18n from 'vokzal-platform/i18n';
import CabinType from '../../../../../src/components/step3/cabin_type';

const StrizhKupeCabinView = ({ carNumber, title, cabinInfo, sexInfo, carSelected, kupeNumber, kupeMF, cabins, onSelectMF, cabinType }) => {

    let freeCupe = carSelected.female > 0 ? getSexInfo(carSelected, cabins[kupeNumber]) : '';
    const mfSelect = freeCupe === 'free' && !kupeMF && title === kupeNumber  ? <CabinType/> : '';
    let icons = '';
    if (!sexInfo) {
    } else if (sexInfo === 'Ж') {
        icons = <div className="sex"><span className="female" data-title="Женское"><FontAwesomeIcon icon={faFemale}/></span></div>;
    } else if (sexInfo === 'М') {
        icons = <div className="sex"><span className="male" data-title="Мужское"><FontAwesomeIcon icon={faMale} /></span></div>
    } else if (sexInfo === 'С') {
        icons = <div className="sex"><span className="female" data-title="Смешанное"><FontAwesomeIcon icon={faFemale} /></span><span className="male" data-title="Смешанное"><FontAwesomeIcon icon={faMale} /></span></div>
    }

    const firstKupeClass = title === 0 ? 'first-kupe' : '';
    const lastKupeClass = title === 8 || (title === 7 ) ? 'last-kupe' : '';
    const sideClass = cabinInfo.centerTopLower > 0 && cabinInfo.centerTopUpper > 0 ? 'right' : 'left';
    const sideClassMobile = cabinInfo.centerTopLower > 0 && cabinInfo.centerTopUpper > 0 ? 'wc-bottom' : 'wc-top';

    return (

    <div className={'kupe ' + firstKupeClass + lastKupeClass}>

        <div className="cabin kupe-cabin strizh">

            <div className="cabin-general">
                {cabinInfo.centerTopLower > 0 ?
                    <CabinPlaceHorizontal carNumber={carNumber} seatNumber={cabinInfo.centerTopLower} type="center-top" isUpper={false}/> : null}

                {cabinInfo.centerTopUpper > 0 ?
                    <CabinPlaceHorizontal carNumber={carNumber} seatNumber={cabinInfo.centerTopUpper} type="center-top" isUpper={true}/> : null}

                {cabinType === 1 ? <div className={'wc-cabin ' + sideClass} data-title="Душ"><FontAwesomeIcon icon={faShower} size="2x" /></div>
                    : <div className="faucet" data-title="Умывальник"><FontAwesomeIcon icon={faFaucet} /></div>}

                {icons}
                {cabinInfo.centerBottomUpper > 0 ?
                    <CabinPlaceHorizontal carNumber={carNumber} seatNumber={cabinInfo.centerBottomUpper} type="center-bottom" isUpper={true}/> : null}
                {cabinInfo.centerBottomLower > 0 ?
                    <CabinPlaceHorizontal carNumber={carNumber} seatNumber={cabinInfo.centerBottomLower} type="center-bottom" isUpper={false}/> : null}
            </div>
            <div className="cabin-title small">{i18n.t('car_map_kupe') + ' ' + (title + 1)}</div>
        </div>

        <div className="train-car-map-cabin train-car-select-seats-map-block d-flex justify-content-between align-items-center strizh rounded mb-2 d-block d-lg-none">
            <div className="train-car-map-cabin-title small pl-3">{i18n.t('car_map_kupe') + ' ' + (title + 1)}</div>

            <div className="train-car-map-cabin-place-block train-car-map-cabin-center-place-block d-flex flex-column">
                {cabinInfo.centerTopLower > 0 ? <CabinPlace carNumber={carNumber} seatNumber={cabinInfo.centerTopLower} type="center-top" isUpper={false}/> : null}
                {cabinInfo.centerTopUpper > 0 ? <CabinPlace carNumber={carNumber} seatNumber={cabinInfo.centerTopUpper} type="center-top" isUpper={true}/> : null}

                {cabinType === 1 ? <div className={'wc-cabin ' + sideClassMobile} data-title="Душ">
                        <FontAwesomeIcon icon={faShower} size="2x"/>
                </div>
                    : <div className="faucet" data-title="Умывальник"><FontAwesomeIcon icon={faFaucet} /></div>}

                {icons}
                {cabinInfo.centerBottomUpper > 0 ?
                    <CabinPlace carNumber={carNumber} seatNumber={cabinInfo.centerBottomUpper} type="center-bottom" isUpper={true}/> : null}
                {cabinInfo.centerBottomLower > 0 ?
                    <CabinPlace carNumber={carNumber} seatNumber={cabinInfo.centerBottomLower} type="center-bottom" isUpper={false}/> : null}
            </div>
        </div>
        { mfSelect }
    </div>
)};

StrizhKupeCabinView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

module.exports = StrizhKupeCabinView;