import React, { Component } from 'react';
import { connect } from 'react-redux'

import PassengersView from 'vokzal-platform/components/user/passengers/view'
import {user_request} from "../../../api/user_request";
import {userPassengersFetchCompleteAction, userPassengerDeleteAction} from "../action";


class PassengersContainer extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      user_request('/api/passengers', {}, this.props.onDataLoaded, this.props.onError);
    }
  }

  render() {
    return <PassengersView {...this.props} />;
  }
}

const mapStateToProps = ({user}) => {

  return {
    title: 'Редактировать',
    loaded: user.passengers_loaded ,
    passengers: Object.keys(user.passengers).length ? user.passengers : null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDataLoaded: (time_spent, response) => {
      dispatch(userPassengersFetchCompleteAction({ time_spent, response }));
    },
    onError: (error) => {
    },
    onDeletePassenger: (id, document) => {
      user_request('/api/passenger/delete', {document_number: document}, () => {
        dispatch(userPassengerDeleteAction(id))
      }, () => {});
    }
  }
}

PassengersContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PassengersContainer)

export default PassengersContainer