import React from 'react';

import i18n from 'vokzal-platform/i18n';

const RequirementsTextView = ({  }) => (
  <div className="user-form-requirements font-light small">
    {i18n.t('step4_requirements')}
  </div>
);

module.exports = RequirementsTextView;