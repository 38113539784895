import React from 'react';
import { connect } from 'react-redux'

import MenuView from 'vokzal-platform/components/user/menu/view'

const mapStateToProps = () => {
  const roles = localStorage.getItem('roles');
  const r = roles.split(",");
  return {
    profile: 'Мои данные',
    orders: 'Заказы',
    passengers: 'Пассажиры',
    admin: r.includes('ROLE_ADMIN') ? 'Админка' : ''
  }
}

const MenuContainer = connect(
    mapStateToProps
)(MenuView)

export default MenuContainer