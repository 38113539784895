import {
  FioInputContainer,
  DateInputContainer,
  BirthCityInputContainer,
  PassportInputContainer,
  EmailInputContainer,
  PhoneInputContainer,
} from './container'

export const FioInput = FioInputContainer
export const DateInput = DateInputContainer
export const BirthCityInput = BirthCityInputContainer
export const PassportInput = PassportInputContainer
export const EmailInput = EmailInputContainer
export const PhoneInput = PhoneInputContainer