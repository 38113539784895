import React from 'react';

import BlogPost from '../../../../src/components/blog/post';


const BlogPostsListView = ({ posts }) => (
  <div className="blog-posts-list">
    {posts.map((p, index) => (
      <BlogPost key={index} {...p} />
    ))}
  </div>
);

module.exports = BlogPostsListView;