import moment from 'moment';

import { vokzal_config } from '../config';

export const fetch_pedia_region = (urlWithParams, onDataLoaded) => {

  const requested_at = moment();

  fetch(vokzal_config.rest.base_url + urlWithParams, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {
      //TODO error handling

      responseJson.groups = make_station_groups(responseJson.stations);
      responseJson.features = make_features(responseJson.stations);

      onDataLoaded(moment().diff(requested_at), responseJson);
    });
};

export const make_features = (stations) => (
  stations.map((station, index) => (
    {
      type: "Feature", id: index,
      geometry: {type: "Point", coordinates: [station.coords.lon, station.coords.lat]},
      properties: {balloonContent: station.title, hintContent: station.title},
      options: {preset: 'islands#circleIcon', iconColor: '#3caa3c'}
    }
  ))
)

export const make_station_groups = (stations) => {
  const groups = stations.reduce((groups, item) => {
    const title = item.title.replace('Вокзал ', '').replace('Станция ', '').substr(0, 1);

    groups[title] = groups[title] || { title, links: [] };
    groups[title].links.push(item);

    return groups;
  }, {});

  return Object.values(groups);
}