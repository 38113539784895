import React from 'react';

import i18n from 'vokzal-platform/i18n'

import LastochkaCabin from '../../../../../src/components/step3/lastochka/base/lastochka_cabin';
import LastochkaCabinDelimiter from '../../../../../src/components/step3/lastochka/base/lastochka_cabin_delimiter';
import LastochkaToiletWithSeats from '../../../../../src/components/step3/lastochka/base/lastochka_toilet_with_seats';

const LastochkaFirstCarView = ({ carNumber, cabins }) => (
  <div className="train-car-select-seats-map rounded pt-2">

    <div className="train-car-select-seats-map-block train-car-map-text lastocha-service-area rounded small d-flex align-items-center rounded m-2">
      <div className="m-auto">{i18n.t('lastochka_service_area')}</div>
    </div>

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[0]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[1]} />

    <LastochkaCabinDelimiter />

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[2]} />

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[3]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[4]} />

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[5]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[6]} />

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[7]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[8]} />

    <LastochkaToiletWithSeats carNumber={carNumber} cabinInfo1={cabins[9]} cabinInfo2={cabins[10]} cabinInfo3={cabins[11]} />

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[12]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[13]} />
    <LastochkaCabinDelimiter />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[14]} />

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[15]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[16]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[17]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[18]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[19]} />

  </div>
);

module.exports = LastochkaFirstCarView;