import React, {lazy, Suspense} from 'react';

import i18n from 'vokzal-platform/i18n';

import { PediaPreLoader } from '../../../../src/components/shared/preloader';


import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';
import PediaHeaderImage from '../../../../src/components/pedia/header_image';
import PediaHeader from '../../../../src/components/pedia/header';

import PediaTabGroup from '../../../../src/components/pedia/tab_group';
import PediaTabContent from '../../../../src/components/pedia/tab_content';

const PediaStationMap = lazy(() => import('../../../../src/components/pedia/station_map'));
const PediaSchedule = lazy(() => import('../../../../src/components/pedia/schedule'));

const News = lazy(() => import('../../../../src/components/home/news/index'));
const FooterInfo = lazy(() => import('../../../../src/components/shared/footer_info/index'));
const FooterLinks = lazy(() => import('../../../../src/components/home/footer_links/index'));

const PediaStationView = ({ loaded, preview, description }) => (
    <div className="pedia">
        <div className="pedia-block">
          <div className="pedia-header-block">

            <HeadMenuPedia />
              <PediaHeaderImage />
            <PediaHeader />

            <PediaTabGroup type="station" />
          </div>

          <PediaTabContent type="info">
            {(preview) ? <div className="pedia-preview p-3" dangerouslySetInnerHTML={{__html: preview}} /> : null}
            {(description) ? <div className="pedia-description p-3" dangerouslySetInnerHTML={{__html: description}} /> : null}

              <Suspense fallback={<div>Загрузка...</div>}>
                  <PediaStationMap />
              </Suspense>
          </PediaTabContent>

          <PediaTabContent type="schedule">
              <Suspense fallback={<div></div>}>
                  <PediaSchedule />
              </Suspense>
          </PediaTabContent>

          <PediaPreLoader show={!loaded} />
        </div>

        <Suspense fallback={<div>Загрузка...</div>}>
            <FooterInfo titleText={i18n.t('goodbye')} />
        </Suspense>

        <Suspense fallback={<div>Загрузка...</div>}>
            <FooterLinks />
        </Suspense>

        <Suspense fallback={<div>Загрузка...</div>}>
            <News />
        </Suspense>
    </div>
);

module.exports = PediaStationView;