// @flow
import { createAction } from 'redux-actions'


export const STEP2_SHOW_ACTION             = 'STEP2/SHOW'
export const step2ShowAction               = createAction(STEP2_SHOW_ACTION)

export const STEP2_SHOW_INFO_ACTION        = 'STEP2/INFO/SHOW'
export const step2ShowInfoAction           = createAction('STEP2/INFO/SHOW')

export const SHOW_PRELOADER_ACTION         = 'STEP2/SEARCH/PRELOADER/SHOW'
export const showPreloaderAction           = createAction(SHOW_PRELOADER_ACTION, (show) => show, () => ({ show : true }))

export const HIDE_PRELOADER_ACTION         = 'STEP2/SEARCH/PRELOADER/HIDE'
export const hidePreloaderAction           = createAction(HIDE_PRELOADER_ACTION, (show) => show, () => ({ show : false }))

export const FETCH_BUS_SEGMENTS_ACTION     = 'STEP2/SEARCH/BUS/SEGMENTS/FETCH'
export const fetch_bus_segments_action     = createAction(FETCH_BUS_SEGMENTS_ACTION)

export const BUS_SEGMENTS_REQUEST_ACTION   = 'STEP2/SEARCH/BUS/SEGMENTS/REQUEST'
export const busSegmentsRequestAction      = createAction(BUS_SEGMENTS_REQUEST_ACTION)

export const BUS_SEGMENTS_RESPONSE_ACTION  = 'STEP2/SEARCH/BUS/SEGMENTS/RESPONSE'
export const busSegmentsResponseAction     = createAction(BUS_SEGMENTS_RESPONSE_ACTION)