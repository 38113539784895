import React, {lazy, Suspense} from 'react';

import i18n from 'vokzal-platform/i18n';

import { PediaPreLoader } from '../../../../src/components/shared/preloader';

import PediaHeaderImage from '../../../../src/components/pedia/header_image';
import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';
import PediaHeader from '../../../../src/components/pedia/header';

import PediaTabGroup from '../../../../src/components/pedia/tab_group';
import PediaTabContent from '../../../../src/components/pedia/tab_content';

import PediaTrainInfo from '../../../../src/components/pedia/train_info';

const FooterInfo = lazy(() => import('../../../../src/components/shared/footer_info/index'));

const StationModal = lazy(() => import('../../../../src/components/home/station_modal'));
const DatesModal = lazy(() => import('../../../../src/components/home/dates_modal'));
const PediaTrainRoute = lazy(() => import('../../../../src/components/pedia/train_route'));
const PediaTrainMap = lazy(() => import('../../../../src/components/pedia/train_map'));
const PediaTrainCalendar = lazy(() => import('../../../../src/components/pedia/train_calendar'));
const Reviews = lazy(() => import('../../../../src/components/reviews'));

const PediaTrainView = ({ loaded, trainNumber, loadedReviews }) => (
    <div className="pedia">
        <div className="pedia-block">
          <div className="pedia-header-block">

            <PediaHeaderImage />

            <HeadMenuPedia />

            <PediaHeader type="train" />

            <PediaTabGroup type="train" />

              <Suspense fallback={<div>Загрузка...</div>}>
                  <StationModal search_type="local" />
              </Suspense>

              <Suspense fallback={<div>Загрузка...</div>}>
                  <DatesModal />
              </Suspense>
          </div>

          {loaded ? <div>
            <PediaTabContent type="info" default="train">
              <PediaTrainInfo />
            </PediaTabContent>

            <PediaTabContent type="route">
                <Suspense fallback={<div>Загрузка...</div>}>
                    <PediaTrainRoute loaded={true} />
                </Suspense>
            </PediaTabContent>

              <PediaTabContent type="map">
                  <Suspense fallback={<div>Загрузка...</div>}>
                      <PediaTrainMap />
                  </Suspense>
              </PediaTabContent>

            <PediaTabContent type="schedule">
                <Suspense fallback={<div>Загрузка...</div>}>
                    <PediaTrainCalendar />
                </Suspense>
            </PediaTabContent>

              <PediaTabContent type="reviews">
                  <Suspense fallback={<div>Загрузка...</div>}>
                      <Reviews train={trainNumber} loaded={loadedReviews}/>
                  </Suspense>
              </PediaTabContent>
          </div> : null}

          <PediaPreLoader show={!loaded} />
        </div>

        <Suspense fallback={<div>Загрузка...</div>}>
            <FooterInfo titleText={i18n.t('goodbye')} />
        </Suspense>
    </div>
);

module.exports = PediaTrainView;