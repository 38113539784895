import React from 'react';

import SapsanCabin from '../../../../../src/components/step3/sapsan/base/sapsan_cabin';
import SapsanDoubleLuggageCabin from '../../../../../src/components/step3/sapsan/base/sapsan_double_luggage_cabin';
import SapsanWardrobeCabinWithSeats from '../../../../../src/components/step3/sapsan/base/sapsan_wardrobe_cabin_with_seats';
import SapsanWardrobeCabinWithToilet from '../../../../../src/components/step3/sapsan/base/sapsan_wardrobe_cabin_with_toilet';
import SapsanCabinDelimiter from '../../../../../src/components/step3/sapsan/base/sapsan_cabin_delimiter';

const SapsanBusinessClassMapView = ({ carNumber, cabins }) => (
  <div className="train-car-select-seats-map rounded pt-2">
    <SapsanDoubleLuggageCabin carNumber={carNumber} />

    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[0]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[1]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[2]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[3]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[4]} />
    <SapsanCabinDelimiter />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[5]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[6]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[7]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[8]} />
    <SapsanWardrobeCabinWithSeats carNumber={carNumber} cabinInfo1={cabins[9]} cabinInfo2={cabins[10]} cabinInfo3={cabins[11]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[12]} />
    <SapsanCabinDelimiter />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[13]} />

    <SapsanWardrobeCabinWithToilet carNumber={carNumber} />
  </div>
);

module.exports = SapsanBusinessClassMapView;