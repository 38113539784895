import React, {Fragment} from 'react';

import HeadMenu from '../../../src/components/shared/menu';
import { Step2PreLoader } from '../../../src/components/shared/preloader';

import ErrorMessage from '../../../src/components/shared/error_message';

import StepInfo from '../../../src/components/shared/step_info';
import SearchResultTabs from '../../../src/components/step2/search_result_tabs';
import TrainsSearchResult from '../../../src/components/step2/trains_search_result';
import BusesSearchResult from '../../../src/components/step2/buses_search_result';
import TrainDetails from '../../../src/components/step2/train_details';
import UfsLogo from '../../../src/components/shared/ufs_logo';
import SearchForm from '../../../src/components/shared/search_form';

const Step2View = ({ trainError, busError, otherDates }) => (
    <Fragment>
        <div className="step2 gradient-background">
            <HeadMenu stepNumber={2} />

            <StepInfo stepNumber={2} />

            <SearchForm />

            <SearchResultTabs />

            <div className="background-general">
                <Step2PreLoader />
                {(trainError) ? <ErrorMessage message={trainError} info={otherDates} page="step2"/> : <TrainsSearchResult />}
                <BusesSearchResult />

                <TrainDetails />

            </div>

            <UfsLogo hide={true}/>
        </div>
    </Fragment>
);

module.exports = Step2View;