import React from 'react';
import i18n from 'vokzal-platform/i18n';

import TextInfo from '../../../../src/components/confirmation/text_info';
import ReserveTimer from '../../../../src/components/confirmation/reserve_timer';
import OrderDetailsOffline from '../../../../src/components/confirmation/order_details_offline';
import OrderDetailsOnline from '../../../../src/components/confirmation/order_details_online';
import OrderChange from '../../../../src/components/confirmation/order_change';
import TextAgreement from '../../../../src/components/confirmation/text_agreement';
import OrderButton from '../../../../src/components/confirmation/order_button';
import FooterInfo from '../../../../src/components/shared/footer_info';

const OrderBlockView = ({ show, paymentType }) => {

  if (!show) {
    return null;
  }

  let block = null;

  if (paymentType === 'euroset') {
    block = <div className="content-w">
                <div className="row">
                    <div className="col-lg-7 pr-sm-2 pr-md-0 p-xs-0">
                        <OrderDetailsOffline />
                        <OrderChange />
                    </div>
                    <div className="col-lg-5">
                        <TextAgreement />
                    </div>
                </div>
            </div>
  } else {
    block = <div className="content-w">
              <ReserveTimer />
                <div className="row">
                    <div className="col-lg-7 pr-sm-2 pr-md-0">
                        <OrderDetailsOnline />
                        <OrderChange />
                    </div>
                    <div className="col-lg-5">
                        <TextAgreement />
                        <OrderButton />
                    </div>
                </div>
            </div>
  }


  return <div className="order-block">
            <TextInfo/>

            {block}

            <FooterInfo titleText={i18n.t('confirmation_hurry')} optionalText={i18n.t('confirmation_oferta')} />

        </div>;
};

module.exports = OrderBlockView;