import { connect } from 'react-redux'
import ConfirmationView from 'vokzal-platform/components/confirmation/view'

const mapStateToProps = state => ({
  show : state.confirmation.reserved
});

const ConfirmationContainer = connect(
  mapStateToProps
)(ConfirmationView);

export default ConfirmationContainer