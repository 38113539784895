import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetch_news } from './action'
import { fetch_footer_links } from '../footer_links/action'

import { fetch_static_data, format_news, format_footer_links } from '../../../api/home'

import PropTypes from 'prop-types';
import { NewsPropType } from '../../../../src/types/index';

import NewsView from 'vokzal-platform/components/home/news/view';

NewsView.propTypes = {
  show: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(NewsPropType).isRequired,
};


class NewsContainer extends Component {

  componentDidMount() {
    if (this.props.items.length === 0) {
      fetch_static_data(this.props.onDataLoaded);
    }
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return <NewsView {...this.props} />;
  }
}


const mapStateToProps = state => {
    return { show : state.news.show, items : state.news.items, }
};

const mapDispatchToProps = dispatch => {
    return {
        onDataLoaded: (time_spent, response) => {

            const news = format_news(response.news);
            const footer_links = format_footer_links(response.popular_routes);

            dispatch(fetch_news(news));
            dispatch(fetch_footer_links(footer_links));
        },
        onExpandClick: (contentTreeItem) => {
            // dispatch(toggleContentTreeAction(contentTreeItem));
        }
    }
};


NewsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsContainer);

export default NewsContainer