import React from 'react';

const DatesSelectItemView = ({ date, weekday, day, holiday, month, selected, swiperEnabled, onDateClick }) => {

  let myClass = (selected) ? " dates-select-item-selected rounded" : "";
  myClass += (holiday) ? ' dates-select-item-holiday' : '';
  myClass += (swiperEnabled) ? ' swiper-slide' : '';

  return (
    <div className={"dates-select-item d-flex flex-column align-items-center p-1" + myClass} onClick={() => { onDateClick(date) }}>
      <div className="dates-select-item-month">{month}</div>
      <div className="dates-select-item-day h3 mb-0">{day}</div>
      <div className="dates-select-item-weekday h5 mb-0">{weekday}</div>
    </div>
  );
}

module.exports = DatesSelectItemView;