import { handleActions } from 'redux-actions'

import { stationInitialStatePlatform } from 'vokzal-platform/components/pedia/station/reducer';

import { SHOW_PEDIA_TAB, STATION_FETCH_COMPLETE_ACTION, STATION_URL_STORE_ACTION } from "./action";

import { PediaDefaultImage } from '../../../types';

const defaultState = {
  loaded : false, urlWithParams: null, title: '', subTitle : '', description : '',
  image : PediaDefaultImage
}

const initialState = Object.assign({}, defaultState, stationInitialStatePlatform())

const PediaStationReducer = handleActions({
    [STATION_FETCH_COMPLETE_ACTION]: (state, action) => {

      if (!action.payload.response.image) {
        delete action.payload.response.image;
      }

      return {...state, loaded : true, ...action.payload.response}
    },
    [STATION_URL_STORE_ACTION]: (state, action) => {
      let new_state = {}
      if (state.urlWithParams !== action.payload) {
        new_state = defaultState;
      }
      new_state.urlWithParams = action.payload;

      return {...state, ...new_state}
    },
}, initialState)

export default PediaStationReducer