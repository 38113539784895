// @flow
import PropTypes from 'prop-types';
import { SeatNumbersSeatedPropType } from '../../../../../src/types/';

import SapsanW10V2ClassMapView from 'vokzal-platform/components/step3/sapsan/sapsan_w10_v2_class_map/view'

SapsanW10V2ClassMapView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersSeatedPropType).isRequired
};

const SapsanW10V2ClassMap = SapsanW10V2ClassMapView;

export default SapsanW10V2ClassMap