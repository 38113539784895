import moment from 'moment';

import { vokzal_config } from '../config';

export const fetch_popular_stations = (urlWithParams, onDataLoaded) => {
  const requested_at = moment();

  fetch(vokzal_config.rest.base_url + urlWithParams, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {
      //TODO error handling

      // responseJson = (responseJson.length > 100) ? responseJson.slice(0, 100) : responseJson;
      responseJson = (responseJson.length > 20) ? responseJson.slice(0, 20) : responseJson;

      onDataLoaded(moment().diff(requested_at), responseJson);
    });
};