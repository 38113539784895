import { handleActions } from 'redux-actions'

import { railroadInitialStatePlatform } from 'vokzal-platform/components/pedia/railroad/reducer';

import { RAILROAD_FETCH_COMPLETE_ACTION, RAILROAD_URL_STORE_ACTION } from "./action";

const defaultState = { loaded : false, urlWithParams: '/doroga/', groups : [] }

const initialState = Object.assign({}, defaultState, railroadInitialStatePlatform())

const PediaRailroadReducer = handleActions({
    [RAILROAD_FETCH_COMPLETE_ACTION]: (state, action) => {
      return {...state, loaded : true, groups: action.payload.response}
    },
    [RAILROAD_URL_STORE_ACTION]: (state, action) => {
      let new_state = {}
      if (state.urlWithParams !== action.payload) {
        new_state = defaultState;
      }
      new_state.urlWithParams = action.payload;

      return {...state, ...new_state}
    },
}, initialState)

export default PediaRailroadReducer