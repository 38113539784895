import React from 'react';
import { CarTypes } from '../../../../src/types/';

const SapsanTypeView = ({ priceType, priceItems }) => {

  if (!priceItems.hasOwnProperty(priceType)) {
    return null;
  }

  return (
    <div>{CarTypes[priceType]}</div>
  );
};

module.exports = SapsanTypeView;