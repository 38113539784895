import moment from 'moment'
import {vokzal_config} from '../config'
import queryString from "query-string";
import getBrowserFingerprint from 'get-browser-fingerprint';
import { setCookie } from "../utils/functions";

export const user_register = (searchUrlWithParams, post, onDataLoaded, onError) => {
    const requested_at = moment();
    let fetchParams = vokzal_config.rest_api.fetchParams;

    fetchParams.body = queryString.stringify({...post, fingerprint: getBrowserFingerprint()});

    fetchParams.method = 'POST';

    fetch(vokzal_config.rest.base_url + searchUrlWithParams, fetchParams)
        .then((response) => response.json())
        .then((responseJson) => {

            if (responseJson.hasOwnProperty('error')) {
                onError(Object.assign(responseJson.error));
            } else {
                console.warn('user_login response', responseJson);

                const options = {};
                // let remember = 0
                if (post.remember === 1) {
                    console.warn('REMEMBER');
                    // 60 дней в секундах
                    options['secure'] = true;
                    options['max-age'] = 5184000;
                    // remember = 1;
                    setCookie('refresh_token', responseJson.refresh_token, options);
                    setCookie('user_email', responseJson.email, options);
                    setCookie('remember_login', 1, options);
                } else {
                    setCookie('refresh_token', responseJson.refresh_token, options);
                    setCookie('user_email', responseJson.email, options);
                    setCookie('remember_login', 0, options);
                }
                // deleteCookie('refresh_token');
                localStorage.setItem('access_token', responseJson.access_token);
                localStorage.setItem('exp', responseJson.exp);
                localStorage.setItem('roles', responseJson.roles);
                // localStorage.setItem('remember', remember);
                onDataLoaded(moment().diff(requested_at), responseJson);
            }
        });
}
