import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLifeRing} from "@fortawesome/free-regular-svg-icons";

const PediaOrderFormShortBusView = ({ orderButtonTitle, bus, searchUrlWithParams, onSelectDateClick, error, wait }) => (
  <div className="pedia-schedule-order-form mb-2">
      {error && error.indexOf(bus.ab) !== -1 ? <div className="bus-error-message">{error}</div> : null}
      {wait ? <div className="wait wait-mini">
          <span className="icon"><FontAwesomeIcon icon={faLifeRing} /></span>
          <span className="icon-right"><FontAwesomeIcon icon={faLifeRing} /></span>
      </div> : <button type="button"
                       className={"btn btn-success btn-block text-uppercase"}
                       onClick={() => onSelectDateClick(bus)}
      >{orderButtonTitle}</button>}

  </div>
);

module.exports = PediaOrderFormShortBusView;