// @flow
import { handleActions, combineActions } from 'redux-actions';

import {
  STEP5_DECREMENT_TIMER_ACTION, STEP5_SET_TIMER_ACTION,
  SHOW_PRELOADER_ACTION, HIDE_PRELOADER_ACTION,
  STEP5_RESERVATION_COMPLETE_ACTION,
  STEP5_SEAT_CHANGED_ACTION
} from './action';

import { confirmationInitialStatePlatform } from 'vokzal-platform/components/confirmation/reducer';
let confirmationInitialState = {
  timer : 0,
  reserved : false,
  order_id : null,
  order_form : null,
  seat_changed : false
}

confirmationInitialState = Object.assign({}, confirmationInitialState, confirmationInitialStatePlatform())

export const ConrimationPageReducer = handleActions({
  [STEP5_SET_TIMER_ACTION]: (state, action) => {
    clearInterval(action.payload.interval_object);
    return { ...state, timer : action.payload.seconds }
  },
  [STEP5_RESERVATION_COMPLETE_ACTION]: (state, action) => {
    return { ...state, reserved : true, order_id : action.payload.orderID, order_url : action.payload.orderURL, order_form : action.payload.orderForm }
  },
  [STEP5_DECREMENT_TIMER_ACTION]: (state, action) => {
    const timer  = (state.timer > 0) ? state.timer - 1 : 0;
    return { ...state, timer }
  },
  [STEP5_SEAT_CHANGED_ACTION]: (state, action) => {
    return { ...state, seat_changed : true }
  },
}, confirmationInitialState)

export const ConrimationPreloaderReducer = handleActions({
  [combineActions(SHOW_PRELOADER_ACTION, HIDE_PRELOADER_ACTION)]: (state, action) => {
    return { ...state, show: action.meta.show }
  },
}, { show: true });
