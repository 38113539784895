import React from 'react';
import BootstrapInput from '../base/bootstrap_input';
import News from '../../../src/components/home/news';

const TestWdView = ({ val, log_rows, onChange }) => (
  <div>
    <BootstrapInput name="test_wd" value={val} required={true} onChange={(v, i) => { onChange(v, i) }} />

      <div className="container-fluid">
          <News />
      </div>


    <ul className="list-group">
      {log_rows.map((l, index) => (<li key={index}>{l}</li>))}
    </ul>
      <p>77777777</p>
  </div>
);

module.exports = TestWdView;