import React from 'react';
import i18n from 'vokzal-platform/i18n';

import PageSection from '../base/section/view';
import SectionTitle from '../base/section_title/view';

import NewsRow from '../../../../src/components/home/news_row';

const NewsView = ({ items }) => (
    <PageSection className="news">
        <SectionTitle>{i18n.t('news')}</SectionTitle>
<div className="row">
    {
        items.map(function(m, index){
            return (<NewsRow key={index} newsItem={m} />);
        })
    }
</div>

    </PageSection>
);

module.exports = NewsView;