import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BusMapView from 'vokzal-platform/components/step3bus/bus_map/view'

BusMapView.propTypes = {
  seatsGroups : PropTypes.array.isRequired
};

const mapStateToProps = state => {

  const a = state.step3_bus_seats.seats;

  let seatsGroups = []

  let i,j, chunk = 5;
  for (i=0,j=a.length; i<j; i+=chunk) {
    seatsGroups.push(a.slice(i,i+chunk));
  }

  return { seatsGroups }
};

const BusMapContainer = connect(
  mapStateToProps,
)(BusMapView);

export default BusMapContainer