import moment from 'moment'
moment.locale('ru')

import {vokzal_config} from '../config'

export const fetch_static_data = (onDataLoaded) => {
  const requested_at = moment()

  fetch(vokzal_config.rest.base_url, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {
      //TODO error handling

      onDataLoaded(moment().diff(requested_at), responseJson)
    })
}

export const format_news = (news) => (
  news.map((n) => (
    {
      title: n.post_title,
      content: n.post_content,
      created: moment(n.post_date).format("D MMMM, YYYY"),
      url : n.guid.replace('https://vokzal.ru', '')
    }
  ))
)

export const format_footer_links = (footer_links) => (
  footer_links.map((l) => {

    const text = l.from_city + ' - ' + l.to_city;
    const title = 'расписание поездов по маршруту ' + text;
    const url = 'https://vokzal.ru/raspisanie/'+l.from_city_trans+'/'+l.to_city_trans+'/'+l.from+'/'+l.to;

    return { title, text, url}
  })
)