import React from 'react';
import { Link } from 'react-router-dom';

import { TrainPropType } from '../../../../src/types/index';

const TrainRow = ({ trainItem }) => (
    <div className="train-row pb-2 col-6">
        <Link to="/train/"><img src={trainItem.img} alt={trainItem.title} /></Link>
    </div>
);

TrainRow.propTypes = {
    trainItem : TrainPropType.isRequired,
};

module.exports = TrainRow;