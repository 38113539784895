import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import i18n from 'vokzal-platform/i18n'

import { SearchFormFields } from '../../../types'

import { searchFormUrlParamsStep3, searchFormUrlParamsTrain } from '../../../../src/utils/url_params';
import { setTrainNumberAction } from '../../../../src/components/step2/train_details/action';
import { showDatesModalAction } from '../../../components/home/dates_modal/action'


import {showStep2Plain, showStep3} from 'vokzal-platform/managers/navigation'

import PediaOrderFormView from 'vokzal-platform/components/pedia/order_form/view'
import {
  setDateFromAction,
  setStationFromAction,
  setStationToAction, setStep2Action,
} from "../../home/search_form/action";
import {step2TrainsSearchCleanupAction} from "../../step2/trains_search_result/action";
import {step2BusesSearchCleanupAction} from "../../step2/buses_search_result/action";
import {showPreloaderAction} from "../../step2/action";
import {step2TabClickAction} from "../../step2/search_result_tabs/action";
import {perform_search} from "../../home/search_button/action";

PediaOrderFormView.propTypes = {
  orderButtonTitle : PropTypes.string.isRequired,
  searchUrlWithParams : PropTypes.string,
  trainNumber : PropTypes.string.isRequired,
  onButtonClick : PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const orderButtonTitle = i18n.t('select_data_and_schedule_order');

  // const searchUrlWithParams = searchFormUrlParamsStep3(state, ownProps.trainNumber)
  const searchUrlWithParams = searchFormUrlParamsTrain(state.search)

  return { orderButtonTitle, searchUrlWithParams, searchForm: state.search }
}

const mapDispatchToProps = dispatch => {
  return {
    onSelectDateClick: (trainNumber) => {

      const selected_input = SearchFormFields.FROM_DATE;
      const date = '';

      dispatch(setTrainNumberAction({ number : trainNumber }));

      dispatch(showDatesModalAction({selected_input, date}));
    },
    onButtonClick: (trainNumber, searchUrlWithParams, searchForm) => {

      dispatch(setDateFromAction(searchForm.date_from_info));

      if (searchForm.from.id === 0) {
        dispatch(setStationFromAction(searchForm.from_info));
      }

      if (searchForm.to.id === 0) {
        dispatch(setStationToAction(searchForm.to_info));
      }




      let urlParams = searchFormUrlParamsTrain(searchForm)
      showStep2Plain(dispatch, urlParams);
      dispatch(setStep2Action({ step2: urlParams }));

      dispatch(step2TrainsSearchCleanupAction());
      dispatch(step2BusesSearchCleanupAction());
      dispatch(showPreloaderAction());
      dispatch(step2TabClickAction('trains'));
      dispatch(perform_search(searchForm));



      // if (searchUrlWithParams) {
      //   dispatch(setTrainNumberAction({ number : trainNumber }));
      //   showStep3(dispatch, searchUrlWithParams);
      // }
    }
  }
}


const PediaOrderFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaOrderFormView)

export default PediaOrderFormContainer