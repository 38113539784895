import React from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

const PediaStationBusMapView = ({ show, mapState, title }) => {

  if (!show) {
    return null;
  }

  return (
    <div className="pedia-map">
      <div id="map" />

      <YMaps>
        <Map state={mapState} height={200} width="100%">
          <Placemark
            geometry={{
              coordinates: mapState.center
            }}
            properties={{
              hintContent: title,
              balloonContent: title
            }}
          />
        </Map>
      </YMaps>
    </div>
  )
}

module.exports = PediaStationBusMapView;