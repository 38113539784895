import React from 'react';

import i18n from 'vokzal-platform/i18n'

import SapsanCabin from '../../../../../src/components/step3/sapsan/base/sapsan_cabin';
import SapsanCabinDelimiter from '../../../../../src/components/step3/sapsan/base/sapsan_cabin_delimiter';

const SapsanBuffetView = ({ carNumber, cabins }) => (
    <div>

        <div className="train-car-select-seats-map-horizontal row">
            <div className="d-flex seat-map">
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[0]} />
                <SapsanCabinDelimiter />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[1]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[2]} />
                <SapsanCabinDelimiter />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[3]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[4]} />
                <SapsanCabinDelimiter />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[5]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[6]} />
                <SapsanCabinDelimiter />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[7]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[8]} />
                <SapsanCabinDelimiter />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[9]} />
            </div>

            <div className="info sapsan-bar rounded d-flex align-items-center">
                <p>{i18n.t('sapsan_bar')}</p>
            </div>
        </div>

        <div className="train-car-select-seats-map rounded pt-2 d-block d-lg-none">
            <SapsanCabin carNumber={carNumber} cabinInfo={cabins[0]} />
            <SapsanCabinDelimiter />
            <SapsanCabin carNumber={carNumber} cabinInfo={cabins[1]} />
            <SapsanCabin carNumber={carNumber} cabinInfo={cabins[2]} />
            <SapsanCabinDelimiter />
            <SapsanCabin carNumber={carNumber} cabinInfo={cabins[3]} />
            <SapsanCabin carNumber={carNumber} cabinInfo={cabins[4]} />
            <SapsanCabinDelimiter />
            <SapsanCabin carNumber={carNumber} cabinInfo={cabins[5]} />
            <SapsanCabin carNumber={carNumber} cabinInfo={cabins[6]} />
            <SapsanCabinDelimiter />
            <SapsanCabin carNumber={carNumber} cabinInfo={cabins[7]} />
            <SapsanCabin carNumber={carNumber} cabinInfo={cabins[8]} />
            <SapsanCabinDelimiter />
            <SapsanCabin carNumber={carNumber} cabinInfo={cabins[9]} />

            <div className="train-car-select-seats-map-block train-car-map-text sapsan-bar rounded small d-flex align-items-center rounded m-2">
                <div className="m-auto">{i18n.t('sapsan_bar')}</div>
            </div>
        </div>
    </div>

);

module.exports = SapsanBuffetView;