import React from 'react';
import { Train } from '@material-ui/icons';


import { RatingTrain } from '../../../../src/components/step2/rating';
import { DepartureArrivalTrain } from '../../../../src/components/step2/departure_arrival';
import {Link} from "react-router-dom";

const TrainInfoView = ({ trainItem, trainLogo, customUI }) => (
  <div className="col-md-6 train-info offset-lg-1">
    {customUI}
    <div className="d-flex justify-content-between">
      <div className="train-details w-75 mt-1">
        <div className="train-name d-flex">
          <Train className="train-icon"/>

          <div className="font-weight-medium font-medium-light ml-1 mb-1">
            <Link to={trainItem.numberUrl} > {trainItem.numberShort} </Link>
            {trainItem.name && trainItem.hasOwnProperty('nameUrl') && trainItem.nameUrl !== '' && trainItem.nameUrl !== 'undefined' ? <Link to={trainItem.nameUrl}>«{trainItem.name}»</Link> : null }
            {parseInt(trainItem.er) === 1 ? <span className="er" data-title="Для этого поезда возможна электронная регистрация">ЭР</span> : ''}
          </div>
        </div>
        <div className="text-capitalize font-weight-medium small mb-1 mt-md-2">{trainItem.from} &mdash; {trainItem.to}</div>
        <div className="d-flex align-items-center mb-1 mb-md-2">
          <div className="font-light small text-capitalize text-nowrap">{trainItem.category}</div>
          <RatingTrain ratingItem={trainItem.rating} />
        </div>
      </div>

      <div className={(trainLogo) ? "train-logo" : "w-25"}>
        {/*logo here*/}
        <div className={(trainLogo) ? "train-logo train-logo-" + trainLogo : "" }/>
      </div>
    </div>

    <DepartureArrivalTrain trainItem={trainItem} />
  </div>
);

module.exports = TrainInfoView;