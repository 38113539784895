// @flow
import PropTypes from 'prop-types';
import { SeatNumbersLastochkaPropType } from '../../../../../types';

import LastochkaRightCabinView from 'vokzal-platform/components/step3/lastochka/base/lastochka_right_cabin/view'

LastochkaRightCabinView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  cabinInfo : SeatNumbersLastochkaPropType
};

const LastochkaRightCabin = LastochkaRightCabinView

export default LastochkaRightCabin