import React from 'react';

import i18n from 'vokzal-platform/i18n';

import { AdultsCount, ChildrenCount, InfantsCount } from '../../../../src/components/step3/passengers_count_row';

const PassengersCountView = ({ showInfants }) => (
  <div className="passengers-count page-section">
      <div className="section-title">{i18n.t('whos_going')}</div>
      <div className="select-count d-flex mt-2">
        <AdultsCount />
        <ChildrenCount />
        {(showInfants) ? <InfantsCount /> : null}
      </div>

  </div>
);

module.exports = PassengersCountView;