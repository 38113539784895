import React from 'react';

import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';

import BlogTitle from '../../../../src/components/blog/title';
import BlogPostsContent from '../../../../src/components/blog/posts_content';

const BlogPostsView = ({ loaded }) => (
  <div className="blog">
    <div className="blog-block">
      <div className="blog-header-block">
        <HeadMenuPedia />

        <BlogTitle type="posts" />
      </div>

      <BlogPostsContent />
    </div>

  </div>
);

module.exports = BlogPostsView;