import React from 'react';

import { UpperSeatPrice, LowerSeatPrice } from '../../../../src/components/step3/seat_price';

import AutoSeatSelect from '../../../../src/components/step3/auto_seat_select';
import SvMap          from '../../../../src/components/step3/sv_map';
import KupeMap        from '../../../../src/components/step3/kupe_map';
import PlatskartMap   from '../../../../src/components/step3/platskart_map';
import SeatedMap      from '../../../../src/components/step3/seated_map';

import SapsanBusinessClassMap    from '../../../../src/components/step3/sapsan/sapsan_business_class_map';
import Sapsan2RClassMap    from '../../../../src/components/step3/sapsan/sapsan_2r_class_map';
import Sapsan1GClassMap    from '../../../../src/components/step3/sapsan/sapsan_1g_class_map';
import SapsanW10V2ClassMap    from '../../../../src/components/step3/sapsan/sapsan_w10_v2_class_map';
import SapsanBuffetMap           from '../../../../src/components/step3/sapsan/sapsan_buffet_map';
import SapsanEconomPlusMap       from '../../../../src/components/step3/sapsan/sapsan_econom_plus_map';
import SapsanEconomMap           from '../../../../src/components/step3/sapsan/sapsan_econom_map';
import { SapsanFirstClassMapKupe, SapsanFirstClassMapMeetingRoom } from '../../../../src/components/step3/sapsan/sapsan_first_class_map';

import LastochkaFirstCarMap      from '../../../../src/components/step3/lastochka/lastochka_first_car_map';
import LastochkaCenterCarMap     from '../../../../src/components/step3/lastochka/lastochka_center_car_map';
import Lastochka2GCarMap      from '../../../../src/components/step3/lastochka/lastochka_2g_car_map';
import Lastochka1PCarMap      from '../../../../src/components/step3/lastochka/lastochka_1p_car_map';
import Lastprem_w4_v2      from '../../../../src/components/step3/lastochka/lastprem_w4_v2';
import Lastprem_w2_v2      from '../../../../src/components/step3/lastochka/lastprem_w2_v2';
import Lastprem_w1_v2      from '../../../../src/components/step3/lastochka/lastprem_w1_v2';
import Lastprem_w2_v1      from '../../../../src/components/step3/lastochka/lastprem_w2_v1';
import Lastprem_w1_v1      from '../../../../src/components/step3/lastochka/lastprem_w1_v1';
import Lastprem_w5_v1      from '../../../../src/components/step3/lastochka/lastprem_w5_v1';

import Prigorod_19_v1      from '../../../../src/components/step3/lastochka/prigorod_19_v1';
import Prigorod_20_v1      from '../../../../src/components/step3/lastochka/prigorod_20_v1';
import Prigorod_21_v1      from '../../../../src/components/step3/lastochka/prigorod_21_v1';
import Prigorod_22_v1      from '../../../../src/components/step3/lastochka/prigorod_22_v1';
import Prigorod_23_v1      from '../../../../src/components/step3/lastochka/prigorod_23_v1';

import Strizh_1_2k_v1      from '../../../../src/components/step3/strij/strizh_1_2k_v1';
import Strizh_1_1u_v1      from '../../../../src/components/step3/strij/strizh_1_1u_v1';
import Strizh_1_1a_v1      from '../../../../src/components/step3/strij/strizh_1_1a_v1';

import StrijLuxMap      from '../../../../src/components/step3/strij/strij_lux_map';
import StrijFirstClassMap      from '../../../../src/components/step3/strij/strij_first_class_map';
import StrijEconomMap      from '../../../../src/components/step3/strij/strij_econom_map';

const TrainMapView = ({ trainType, carNumber, carType, carService, cabins, autoSelection, carSchema }) => {

  const noMap = (['usual', 'sapsan', 'lastochka', 'strij', 'prigorod'].indexOf(trainType) === -1);

  let addtionalInfo = null
  if (false) {
    addtionalInfo = <div className="train-car-select-seats-price-info rounded d-flex justify-content-around p-1">
                      <UpperSeatPrice />
                      <LowerSeatPrice />
                    </div>
  }

  return (
  <div className="train-car-select-seats-map-block">
    {addtionalInfo}

      {carSchema === 'PRIGOR_19_V1' ? <Prigorod_19_v1 carNumber={carNumber} cabins={cabins} /> : ''}
      {carSchema === 'PRIGOR_20_V1' ? <Prigorod_20_v1 carNumber={carNumber} cabins={cabins} /> : ''}
      {carSchema === 'PRIGOR_21_V1' ? <Prigorod_21_v1 carNumber={carNumber} cabins={cabins} /> : ''}
      {carSchema === 'PRIGOR_22_V1' ? <Prigorod_22_v1 carNumber={carNumber} cabins={cabins} /> : ''}
      {carSchema === 'PRIGOR_23_V1' ? <Prigorod_23_v1 carNumber={carNumber} cabins={cabins} /> : ''}


    {(trainType === 'usual'  && carType === 'Л') ? <SvMap        carNumber={carNumber} cabins={cabins} /> : ''}
    {(trainType === 'usual'  && carType === 'К') ? <KupeMap      carNumber={carNumber} cabins={cabins} /> : ''}
    {(trainType === 'usual'  && carType === 'П') ? <PlatskartMap carNumber={carNumber} cabins={cabins} /> : ''}
    {(trainType === 'usual'  && carType === 'С') ? <SeatedMap    carNumber={carNumber} cabins={cabins} /> : ''}

    {(trainType === 'sapsan' && carService === '1Р') ? <SapsanFirstClassMapMeetingRoom carNumber={carNumber} cabins={cabins} /> : ''}
    {(trainType === 'sapsan' && carService === '1В') ? <SapsanFirstClassMapKupe        carNumber={carNumber} cabins={cabins} /> : ''}
    {(trainType === 'sapsan' && carService === '1С') ? <SapsanBusinessClassMap         carNumber={carNumber} cabins={cabins} /> : ''}
    {(trainType === 'sapsan' && carService === '2Е') ? <SapsanBuffetMap                carNumber={carNumber} cabins={cabins} /> : ''}
    {(trainType === 'sapsan' && carService === '2В') ? <SapsanEconomPlusMap            carNumber={carNumber} cabins={cabins} /> : ''}
    {(trainType === 'sapsan' && carService === '2С') ? <SapsanEconomMap                carNumber={carNumber} cabins={cabins} /> : ''}
    {(trainType === 'sapsan' && carService === '2Р') ? <Sapsan2RClassMap         carNumber={carNumber} cabins={cabins} /> : ''}
    {(trainType === 'sapsan' && carService === '1Ж') ? <Sapsan1GClassMap         carNumber={carNumber} cabins={cabins} /> : ''}
    {(trainType === 'sapsan' && carService === '2Ю') ? <SapsanW10V2ClassMap      carNumber={carNumber} cabins={cabins} /> : ''}


    {/* LASTPREM_W1_V1 Спб - Петрозаводск*/}
    {/* LASTPREM_W2_V1 Спб - Петрозаводск*/}
    {/* LASTPREM_W3_V1 */}
    {/* LASTPREM_W4_V1 */}
    {/* LASTPREM_W5_V1 Спб - Петрозаводск */}

      {/* LASTPREM_W1_V2  Спб - Псков */}
      {/* LASTPREM_W2_V2 */}
      {/* LASTPREM_W3_V2 */}
      {/* LASTPREM_W4_V2 */}
      {/* LASTPREM_W5_V2 */}



      {carSchema === 'LASTOCHKA_W1_V1' || carSchema === 'LASTOCHKA_W5_V1' ? <LastochkaFirstCarMap carNumber={carNumber} cabins={cabins} /> : ''}
      {carSchema === 'LASTOCHKA_W2_V1' || carSchema === 'LASTOCHKA_W3_V1' || carSchema === 'LASTOCHKA_W4_V1' ? <LastochkaCenterCarMap carNumber={carNumber} cabins={cabins} /> : ''}

      {carSchema === 'LASTPREM_W1_V1' ? <Lastprem_w1_v1 carNumber={carNumber} cabins={cabins} /> : ''}

      {carSchema === 'LASTPREM_W2_V1' || carSchema === 'LASTPREM_W3_V1' || carSchema === 'LASTPREM_W4_V1' ? <Lastprem_w2_v1 carNumber={carNumber} cabins={cabins} /> : ''}

      {carSchema === 'LASTPREM_W5_V1' ? <Lastprem_w5_v1 carNumber={carNumber} cabins={cabins} /> : ''}

      {carSchema === 'LASTPREM_W1_V2' ? <Lastprem_w1_v2 carNumber={carNumber} cabins={cabins} /> : ''}

      {carSchema === 'LASTPREM_W2_V2' ? <Lastprem_w2_v2 carNumber={carNumber} cabins={cabins} /> : ''}

      {carSchema === 'LASTPREM_W3_V2' ? <Lastochka2GCarMap carNumber={carNumber} cabins={cabins} /> : ''}

      {carSchema === 'LASTPREM_W4_V2' ? <Lastprem_w4_v2 carNumber={carNumber} cabins={cabins} /> : ''}

      {carSchema === 'LASTPREM_W5_V2' ? <Lastochka1PCarMap carNumber={carNumber} cabins={cabins} /> : ''}


      {carSchema === 'STRIZH_1_2K_V1' ? <Strizh_1_2k_v1 carNumber={carNumber} cabins={cabins} /> : ''}
      {carSchema === 'STRIZH_1_1U_V1' ? <Strizh_1_1u_v1 carNumber={carNumber} cabins={cabins} /> : ''}
      {carSchema === 'STRIZH_1_1A_V1' || carSchema === 'STRIZH_1_1I_V1' ? <Strizh_1_1a_v1 carNumber={carNumber} cabins={cabins} /> : ''}

      {(trainType === 'strij' && (carService === '1Е' || carService === '1Э' || carService === '1Т'))
          && carSchema !== 'STRIZH_1_1U_V1'&& carSchema !== 'STRIZH_1_1A_V1'&& carSchema !== 'STRIZH_1_1I_V1'
          ? <StrijLuxMap carNumber={carNumber} cabins={cabins} /> : ''}
    {(trainType === 'strij' && carService === '1Р') ? <StrijFirstClassMap        carNumber={carNumber} cabins={cabins} /> : ''}
    {(trainType === 'strij' && (carService === '2С' || carService === '2В' || carService === '2Ж' || carService === '2Р')) ? <StrijEconomMap            carNumber={carNumber} cabins={cabins} /> : ''}


    {autoSelection === 1 ? <AutoSeatSelect carNumber={carNumber} text={true}/> : ''}

    {noMap ? <AutoSeatSelect carNumber={carNumber} /> : ''}
  </div>
);
}

module.exports = TrainMapView;