import React, {lazy, Suspense} from 'react';

import i18n from 'vokzal-platform/i18n';

import { PediaPreLoader } from '../../../../src/components/shared/preloader';
import PediaOrderFormShort from "../../../../src/components/pedia/order_form_short";


import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';
import PediaHeaderImage from '../../../../src/components/pedia/header_image';
import PediaHeader from '../../../../src/components/pedia/header';

import TrainRow from '../../../../src/components/step2/train_row';

const StationModal = lazy(() => import('../../../../src/components/home/station_modal'));
const DatesModal = lazy(() => import('../../../../src/components/home/dates_modal'));

import {getPriceFormat, getWordNumericForm} from '../../../../src/utils/functions';

const FooterInfo = lazy(() => import('../../../../src/components/shared/footer_info/index'));
const FooterLinks = lazy(() => import('../../../../src/components/home/footer_links/index'));

const PediaRaspisanieView = ({ loaded, preview, description, items, searchUrlWithParams, onTrainClick, onDateSelected, cityFrom, cityTo, averageDistance, averageWaytime, trainsCount, citiesPassthru, tariffs, backUrl }) => {
    let arr = [];
    tariffs.hasOwnProperty('П') && tariffs['П']['price'] > 0 ? arr.push(`цена билета в плацкартный вагон в среднем ${getPriceFormat(Math.round(tariffs['П']['price']))} ${getWordNumericForm(Math.round(tariffs['П']['price']), 'рубль', 'рубля', 'рублей')}`) : '';

    tariffs.hasOwnProperty('К') && tariffs['К']['price'] > 0 ? arr.push(`в вагон купе билет стоит в среднем ${getPriceFormat(Math.round(tariffs['К']['price']))} ${getWordNumericForm(Math.round(tariffs['К']['price']), 'рубль', 'рубля', 'рублей')}`) : '';

    tariffs.hasOwnProperty('О') && tariffs['О']['price'] > 0 ? arr.push(`в общем вагоне места в среднем стоят ${getPriceFormat(Math.round(tariffs['О']['price']))} ${getWordNumericForm(Math.round(tariffs['О']['price']), 'рубль', 'рубля', 'рублей')}`) : '';

    tariffs.hasOwnProperty('С') && tariffs['С']['price'] > 0 ? arr.push(`стоимость сидячих мест в среднем ${getPriceFormat(Math.round(tariffs['С']['price']))} ${getWordNumericForm(Math.round(tariffs['С']['price']), 'рубль', 'рубля', 'рублей')}`) : '';

    tariffs.hasOwnProperty('М') && tariffs['М']['price'] > 0 ? arr.push(`мягкие места стоят примерно ${getPriceFormat(Math.round(tariffs['М']['price']))} ${getWordNumericForm(Math.round(tariffs['М']['price']), 'рубль', 'рубля', 'рублей')}`) : '';

    tariffs.hasOwnProperty('Л') && tariffs['Л']['price'] > 0 ? arr.push(`билеты в вагон люкс стоят в среднем ${getPriceFormat(Math.round(tariffs['Л']['price']))} ${getWordNumericForm(Math.round(tariffs['Л']['price']), 'рубль', 'рубля', 'рублей')}`) : '';

    let average = '';
    if(averageWaytime && averageDistance){
        average = `Среднее расстояние от пункта отправления до места назначения - ${averageDistance} км, а время в пути: ${averageWaytime}.`;
    }else if(averageDistance){
        average = `Среднее расстояние от пункта отправления до места назначения - ${averageDistance} км.`;
    }else if(averageWaytime) {
        average = `Среднее время в пути от пункта отправления до места назначения: ${averageWaytime}.`;
    }

    const cities = [];
    if (citiesPassthru !== null) {
        citiesPassthru.forEach((item) => cities.push(`<a href="/stanciya/${item['city_name_url']}/${item['station_id']}">${item['city_name']}</a>`));
    }
    const citiesStr = `По пути следования поезда проходят через города и населённые пункты: ${cities.join(', ')}.`;

    return (
    <div className="pedia">
        <div className="pedia-block step2">
          <div className="pedia-header-block">
            <PediaHeaderImage />

            <HeadMenuPedia />

            <PediaHeader type="raspisanie" />
          </div>
           <div className="pedia-raspisanie background-general">
                <div className="search-results mr-2 ml-2 mb-4 pt-2 d-flex flex-column">
                    <div className="time-text font-light small pt-0">
                        {i18n.t('departure_time_msk_arrival_time_local')}
                    </div>
                    {items.map((t, index) => (
                        <div key={index} className="train-info-wrapper rounded mb-1">
                            <div key={index} className="row">
                                <div className="col-md-9">
                                    <TrainRow trainItem={t} onTrainClick={() => { onTrainClick(t) }} />
                                </div>
                                <div className="col-md-3 order-button">
                                    <PediaOrderFormShort train={t} />
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="train-info-wrapper rounded mb-3 mt-3 p-3">
                        <a href={backUrl}>Обратные ЖД билеты {cityTo} - {cityFrom}</a>
                    </div>

                    <div className="train-info-wrapper rounded mb-1 p-3">
                        <h3>Расписание движения поездов по маршруту {cityFrom} – {cityTo}</h3>

                        {tariffs.all > 0 ?
                            <div>
                                Самая низкая стоимость билета на поезд {cityFrom} – {cityTo} составляет {getPriceFormat(Math.round(tariffs['minPrice']))} {getWordNumericForm(Math.round(tariffs['minPrice']), 'рубль', 'рубля', 'рублей')}.
                                При этом:
                                <ul>
                                    {arr.map((t, index) =>  <li key={index} className="">{t}</li>)}
                                </ul>
                            </div>
                            : null}

                        {average ? <p>{average}</p> : null}

                        {  cities.length > 0 ? <div dangerouslySetInnerHTML={{__html: citiesStr}} /> : null}

                        {trainsCount ?  <p>Всего по маршруту {cityFrom} – {cityTo} курсирует {trainsCount} {getWordNumericForm(trainsCount, 'поезд', 'поезда', 'поездов')}.</p> : null}
                        <p>Забронируйте прямо сейчас ЖД-билет {cityFrom} – {cityTo} на сайте vokzal.ru за 2 минуты.</p>
                    </div>
                </div>
            </div>

            <PediaPreLoader show={!loaded} />

            <Suspense fallback={<div>Загрузка...</div>}>
                <StationModal search_type="local" />
            </Suspense>

            <Suspense fallback={<div>Загрузка...</div>}>
                <DatesModal onDateSelected={(date) => onDateSelected(searchUrlWithParams, date)} />
            </Suspense>

        </div>


        <Suspense fallback={<div>Загрузка...</div>}>
            <FooterInfo titleText={i18n.t('goodbye')} />
        </Suspense>

        <Suspense fallback={<div>Загрузка...</div>}>
            <FooterLinks />
        </Suspense>
    </div>
)};

module.exports = PediaRaspisanieView;