export const convert_station_obj = (stationObj) => {
  let station = {};
  station.id = parseInt(stationObj.station_id);
  station.station_name = stationObj.name;
  station.city_name = stationObj.city;
  station.region = stationObj.region;
  station.country = stationObj.country;
  station.url = '';

  return station;
}