import i18n from 'vokzal-platform/i18n';
export const cols6 = (count) => {
    const col = parseInt(count / 6);
    const diff = count % 6;

    let result = [];

    switch (diff) {
        case 0:
            result = [col, col, col, col, col, col];
            break;
        case 1:
            result = [col, col, col, col, col, col + 1];
            break;
        case 2:
            result = [col + 1, col + 1, col + 1, col + 1, col + 1, col - 3];
            break;
        case 3:
            result = [col + 1, col + 1, col + 1, col + 1, col + 1, col - 2];
            break;
        case 4:
            result = [col + 1, col + 1, col + 1, col + 1, col + 1, col - 1];
            break;
        case 5:
            result = [col + 1, col + 1, col + 1, col + 1, col + 1, col];
            break;
    }

    return result;
}

export const order6 = (i) => {
    let order = '';
    switch (i) {
        case 0:
            order = 'order-md-1 order-lg-1';
            break;
        case 1:
            order = 'order-md-3 order-lg-4';
            break;
        case 2:
            order = 'order-md-5 order-lg-2';
            break;
        case 3:
            order = 'order-md-2 order-lg-5';
            break;
        case 4:
            order = 'order-md-4 order-lg-3';
            break;
        case 5:
            order = 'order-md-6 order-lg-6';
            break;
    }

    return order;
}

// возвращает куки с указанным name или undefined, если ничего не найдено
export const getCookie = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const setCookie = (name, value, options = {}) => {
    options = {
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;

    console.info('result cookies', getCookie(name))
}

export const deleteCookie = (name) => {
    setCookie(name, "", {
        'max-age': -1
    })
}

export const logout = () => {
    console.info('LOGOUT');
    deleteCookie('refresh_token');
    deleteCookie('user_email');
    deleteCookie('remember_login');
    localStorage.setItem('access_token', '');
    localStorage.setItem('exp', '');
    localStorage.setItem('roles', '');
}

// возвращает тип купе - мужское, женское, смешанное или свободное в соответствующем вагоне
export const getSexInfo = (carSelected, cabin, secondFloor = 0) => {
    const plusSeat = secondFloor === 1 ? 80 : 0;
    let sexInfo = '';
    let seat;
    for (let key in cabin) {
        seat = (cabin[key] + plusSeat).toString();
        if (carSelected.freeSeatsFemale.indexOf( seat ) !== -1) {
            sexInfo = 'Ж';
            break;
        } else if (carSelected.freeSeatsMale.indexOf( seat ) !== -1) {
            sexInfo = 'М';
            break;
        } else if (carSelected.freeSeatsMF.indexOf( seat ) !== -1) {
            // еще не определённые места
            sexInfo = 'free';
        } else if (carSelected.freeSeats.indexOf( seat ) !== -1) {
            sexInfo = 'С';
        }
    }

    return sexInfo;
}

// возвращает название типа вагона
export const getCategoryType = (type) => {
    switch (type) {
        case 'О':
            return i18n.t('car_shared_name');
        case 'С':
            return i18n.t('car_seat_name');
        case 'П':
            return i18n.t('car_platskart_name');
        case 'К':
            return i18n.t('car_kupe_name');
        case 'Л':
            return i18n.t('car_sv_name');
        case 'М':
            return i18n.t('car_soft_name');
    }
}

// возвращает название типа вагона
export const enToRus = (value) => {
    let layoutEn = ["`","q","w","e","r","t","y","u","i","o","p","\\[","\\]","a","s","d","f","g","h","j","k","l",";","'","z","x","c","v","b","n","m","\,","\\.","\\/","~","Q","W","E","R","T","Y","U","I","O","P","\\{","\\}","A","S","D","F","G","H","J","K","L",":","\"","Z","X","C","V","B","N","M","<",">","\\?","\\|","@","#","\\$","\\^","&"];
    let layoutRu = ["ё","й","ц","у","к","е","н","г","ш","щ","з","х"  ,"ъ"  ,"ф","ы","в","а","п","р","о","л","д","ж","э","я","ч","с","м","и","т","ь","б","ю",".","Ё","Й","Ц","У","К","Е","Н","Г","Ш","Щ","З","Х","Ъ","Ф","Ы","В","А","П","Р","О","Л","Д","Ж","Э","Я","Ч","С","М","И","Т","Ь","Б","Ю","\,","/","\"","№",";",":","?"];
    let re = "";
    value = value.split('');

    let index;
    for (let i=0; i < value.length; i++)
    {
        index = layoutEn.indexOf(value[i]);
        if (index !== -1) {
            value[i] = layoutRu[index];
        }
    }
    return value.join('');
}

export const getPriceFormat = (str = '') => {
    let out;
    if (str.toString().indexOf('.') !== -1) {
        let [int, dec] = split('.');
        out = getPriceFormat(int) + '.' + getPriceFormat(dec);
        return out;
    } else {
        str += '';
        out = '';
        let strlen = str.length;
        for (let i = strlen - 1; i >= 0; i--) {
            out = str[i] + out;
            out = (strlen - i)%3 === 0 ? ' ' + out : out;
        }
        return out.trim();
    }
}

export const getWordNumericForm = (nNumber, strWordOne, strWordTwo, strWordFive) => {
    nNumber = Math.floor(Math.abs(nNumber)); // для корректной работы число должно быть целым положительным
    nNumber %= 100;
    if (10 <= nNumber && nNumber <= 19) {
        return strWordFive;
    }
    nNumber %= 10;
    if (nNumber === 1) {
        return strWordOne;
    }
    if (2 <= nNumber && nNumber <= 4) {
        return strWordTwo;
    }
    return strWordFive;
}

/**
 * @param wayTime минуты
 * @param fullMode сокращать или нет
 * @return string
 */
export const getWayTimeOutputFormat = ( wayTime, fullMode = false ) => {
    const days = Math.floor(wayTime / 1440);
    wayTime = wayTime % 1440;
    const hours = Math.floor(wayTime / 60);
    const minutes = wayTime % 60;
    let out = minutes + (fullMode ? getWordNumericForm(minutes, ' минута', ' минуты', ' минут') :'м');
    if (hours || days) {
        out = hours + (fullMode ? getWordNumericForm(hours, ' час', ' часа', ' часов') :'ч') + ' ' + out;
    }
    if (days) {
        out = days + (fullMode ? getWordNumericForm(minutes, ' день', ' дня', ' дней') :'д') + ' ' + out;
    }
    return out;
}

export const starReplace = (text, len = 2) => {
    return text.slice(0,len) + '*'.repeat(text.length - len * 2) + text.slice(-len)
}

export const checkLogin = (state) => {
    let refresh_token = getCookie('refresh_token');
    let user_email = getCookie('user_email');

    let email = state.user.hasOwnProperty('login') && state.user.login ? state.user.email : null;
    if (!email) {
        email = user_email && refresh_token ? user_email : null
    }
    return email;
}

export const getPlatform = () => {
    let userDeviceArray = [
        {device: 'Android', platform: /Android/},
        {device: 'iPhone', platform: /iPhone/},
        {device: 'iPad', platform: /iPad/},
        {device: 'Symbian', platform: /Symbian/},
        {device: 'Windows Phone', platform: /Windows Phone/},
        {device: 'Tablet OS', platform: /Tablet OS/},
        {device: 'Linux', platform: /Linux/},
        {device: 'Windows', platform: /Windows NT/},
        {device: 'Macintosh', platform: /Macintosh/}
    ];

    let platform = navigator.userAgent;

    for (let i in userDeviceArray) {
        if (userDeviceArray[i].platform.test(platform)) {
            return userDeviceArray[i].device;
        }
    }
    return 'Неизвестная платформа!' + platform;
}