import { preProcessPostUrls, preProcessPagination } from '../../../../src/utils/blog';

export const blogPostsInitialStatePlatform = () => {
  let initialState = {}

  if (typeof blog_title !== 'undefined') {
    initialState.title = blog_title;
  }

  if (typeof blog_posts !== 'undefined') {
    initialState.posts = preProcessPostUrls(blog_posts);
    initialState.data_loaded = true;
  }

  if (typeof blog_pagination !== 'undefined') {
    initialState.pagination = preProcessPagination(blog_pagination);
  }

  return initialState;
}