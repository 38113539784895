// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { FooterLinksPropType } from '../../../../src/types';
import { showFooterLink } from 'vokzal-platform/managers/navigation'

import i18n from 'vokzal-platform/i18n';


import PediaRailroadDetailsPopularRoutesView from 'vokzal-platform/components/pedia/railroad_details_popular_routes/view'

PediaRailroadDetailsPopularRoutesView.propTypes = {
  popular_routes : PropTypes.arrayOf(FooterLinksPropType).isRequired,
};


const mapStateToProps = state => {
     return {
       title: i18n.t('popular_routes'),
       popular_routes: state.pedia_railroad_details.popular_routes,
    }
}

const mapDispatchToProps = dispatch => {

    return {
      onLinkClick : (linkItem) => {
        showFooterLink(linkItem)
      }
    }
}


const PediaRailroadDetailsPopularRoutesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PediaRailroadDetailsPopularRoutesView)

export default PediaRailroadDetailsPopularRoutesContainer