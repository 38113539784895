import React, { Component } from 'react';

import { connect } from 'react-redux'

import AdminView from 'vokzal-platform/components/admin/view'
import {user_request} from "../../api/user_request";
import {adminReviewsFetchCompleteAction} from "../admin/action";
import {adminReviewDeleteAction, adminSetValueAction} from "../admin/action";

class AdminContainer extends Component{
  componentDidMount() {
    console.log('/api/reviews/admin', this.props);
    if (!this.props.loaded) {
      user_request('/api/reviews/admin', {}, this.props.onDataLoaded, this.props.onError);
    }
  }

  render() {
    return <AdminView {...this.props} />
  }
}

const mapStateToProps = ({admin}) => {
  const roles = localStorage.getItem('roles');
  const r = roles.split(",");
  return {
    title: 'Отзывы',
    admin: r.includes('ROLE_ADMIN'),
    reviews: admin.reviews,
    loaded: admin.reviews_loaded,
    message: admin.message
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDataLoaded: (time_spent, response) => {
      console.log('response admin/reviews', response);

      dispatch(adminReviewsFetchCompleteAction({ time_spent, response }));
    },
    onError: (error) => {

    },
    onPublish: (id, status) => {
      console.log('onPublish', id, status)
      user_request('/api/review/publish', {review_id: id, status: status}, () => {
        dispatch(adminReviewDeleteAction(id));
        dispatch(adminSetValueAction({message: status === 1 ? 'Отзыв опубликован' : 'Отзыв удалён'}));
      }, () => {});
    },
  }
}

AdminContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminContainer);

export default AdminContainer