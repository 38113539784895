import React from 'react';
import PropTypes from 'prop-types';

const TrainCarNumber = ({ carNumber, isActive, swiperEnabled, onCarNumberClick }) => (
  <div className={((isActive) ? 'active' : '') + " train-car-select-number " + ((swiperEnabled) ? 'swiper-slide' : '')} onClick={() => onCarNumberClick(!isActive, carNumber) }>
    <div className="font-medium-light p-2">{carNumber}</div>
  </div>
);

TrainCarNumber.propTypes = {
  carNumber: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  swiperEnabled: PropTypes.bool.isRequired,
  onCarNumberClick: PropTypes.func.isRequired,
};


module.exports = TrainCarNumber;