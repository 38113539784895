import { handleActions } from 'redux-actions'

import { SHOW_PEDIA_TAB } from "./action";

// const initialState = { currentTab: "info" }
const initialState = { currentTab: "schedule" }

const PediaTabGroupReducer = handleActions({
    [SHOW_PEDIA_TAB]: (state, action) => {
      return {...state, currentTab : action.payload}
    }
}, initialState)

export default PediaTabGroupReducer