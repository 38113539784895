const arrCountries = [
    { value : 'ABH', title : 'Абхазия' },
    { value : 'AUS', title : 'Австралия' },
    { value : 'AUT', title : 'Австрия' },
    { value : 'AZE', title : 'Азербайджан' },
    { value : 'ALB', title : 'Албания' },
    { value : 'DZA', title : 'Алжир' },
    { value : 'ASM', title : 'Американское Самоа' },
    { value : 'GBR', title : 'Англия (Соединенное королевство)' },
    { value : 'AIA', title : 'Ангилья' },
    { value : 'AGO', title : 'Ангола' },
    { value : 'AND', title : 'Андорра' },
    { value : 'ATA', title : 'Антарктида' },
    { value : 'ATG', title : 'Антигуа и Барбуда' },
    { value : 'ARG', title : 'Аргентина' },
    { value : 'ARM', title : 'Армения' },
    { value : 'ABW', title : 'Аруба' },
    { value : 'AFG', title : 'Афганистан' },
    { value : 'BHS', title : 'Багамы' },
    { value : 'BGD', title : 'Бангладеш' },
    { value : 'BRB', title : 'Барбадос' },
    { value : 'BHR', title : 'Бахрейн' },
    { value : 'BLR', title : 'Беларусь' },
    { value : 'BLZ', title : 'Белиз' },
    { value : 'BEL', title : 'Бельгия' },
    { value : 'BEN', title : 'Бенин' },
    { value : 'BMU', title : 'Бермуды' },
    { value : 'BGR', title : 'Болгария' },
    { value : 'BOL', title : 'Боливия, Многонациональное Государство' },
    { value : 'BES', title : 'Бонайре, Саба и Синт-Эстатиус' },
    { value : 'BIH', title : 'Босния и Герцеговина' },
    { value : 'BWA', title : 'Ботсвана' },
    { value : 'BRA', title : 'Бразилия' },
    { value : 'IOT', title : 'Британская территория в Индийском океане' },
    { value : 'BRN', title : 'Бруней-Даруссалам' },
    { value : 'BFA', title : 'Буркина-Фасо' },
    { value : 'BDI', title : 'Бурунди' },
    { value : 'BTN', title : 'Бутан' },
    { value : 'VUT', title : 'Вануату' },
    { value : 'HUN', title : 'Венгрия' },
    { value : 'VEN', title : 'Венесуэла Боливарианская Республика' },
    { value : 'VGB', title : 'Виргинские острова, Британские' },
    { value : 'VIR', title : 'Виргинские острова, США' },
    { value : 'VNM', title : 'Вьетнам' },
    { value : 'GAB', title : 'Габон' },
    { value : 'HTI', title : 'Гаити' },
    { value : 'GUY', title : 'Гайана' },
    { value : 'GMB', title : 'Гамбия' },
    { value : 'GHA', title : 'Гана' },
    { value : 'GLP', title : 'Гваделупа' },
    { value : 'GTM', title : 'Гватемала' },
    { value : 'GIN', title : 'Гвинея' },
    { value : 'GNB', title : 'Гвинея-Бисау' },
    { value : 'DEU', title : 'Германия' },
    { value : 'GGY', title : 'Гернси' },
    { value : 'GIB', title : 'Гибралтар' },
    { value : 'HND', title : 'Гондурас' },
    { value : 'HKG', title : 'Гонконг' },
    { value : 'GRD', title : 'Гренада' },
    { value : 'GRL', title : 'Гренландия' },
    { value : 'GRC', title : 'Греция' },
    { value : 'GEO', title : 'Грузия' },
    { value : 'GUM', title : 'Гуам' },
    { value : 'DNK', title : 'Дания' },
    { value : 'JEY', title : 'Джерси' },
    { value : 'DJI', title : 'Джибути' },
    { value : 'DMA', title : 'Доминика' },
    { value : 'DOM', title : 'Доминиканская Республика' },
    { value : 'EGY', title : 'Египет' },
    { value : 'ZMB', title : 'Замбия' },
    { value : 'ESH', title : 'Западная Сахара' },
    { value : 'ZWE', title : 'Зимбабве' },
    { value : 'ISR', title : 'Израиль' },
    { value : 'IND', title : 'Индия' },
    { value : 'IDN', title : 'Индонезия' },
    { value : 'JOR', title : 'Иордания' },
    { value : 'IRQ', title : 'Ирак' },
    { value : 'IRN', title : 'Иран, Исламская Республика' },
    { value : 'IRL', title : 'Ирландия' },
    { value : 'ISL', title : 'Исландия' },
    { value : 'ESP', title : 'Испания' },
    { value : 'ITA', title : 'Италия' },
    { value : 'YEM', title : 'Йемен' },
    { value : 'CPV', title : 'Кабо-Верде' },
    { value : 'KAZ', title : 'Казахстан' },
    { value : 'KHM', title : 'Камбоджа' },
    { value : 'CMR', title : 'Камерун' },
    { value : 'CAN', title : 'Канада' },
    { value : 'QAT', title : 'Катар' },
    { value : 'KEN', title : 'Кения' },
    { value : 'CYP', title : 'Кипр' },
    { value : 'KGZ', title : 'Киргизия' },
    { value : 'KIR', title : 'Кирибати' },
    { value : 'CHN', title : 'Китай' },
    { value : 'CCK', title : 'Кокосовые (Килинг) острова' },
    { value : 'COL', title : 'Колумбия' },
    { value : 'COM', title : 'Коморы' },
    { value : 'COG', title : 'Конго' },
    { value : 'COD', title : 'Конго, Демократическая Республика' },
    { value : 'PRK', title : 'Корея, Народно-Демократическая Республика' },
    { value : 'KOR', title : 'Корея, Республика' },
    { value : 'CRI', title : 'Коста-Рика' },
    { value : 'CIV', title : 'Кот д\'Ивуар' },
    { value : 'CUB', title : 'Куба' },
    { value : 'KWT', title : 'Кувейт' },
    { value : 'CUW', title : 'Кюрасао' },
    { value : 'LAO', title : 'Лаос' },
    { value : 'LVA', title : 'Латвия' },
    { value : 'LSO', title : 'Лесото' },
    { value : 'LBN', title : 'Ливан' },
    { value : 'LBY', title : 'Ливийская Арабская Джамахирия' },
    { value : 'LBR', title : 'Либерия' },
    { value : 'LIE', title : 'Лихтенштейн' },
    { value : 'LTU', title : 'Литва' },
    { value : 'LUX', title : 'Люксембург' },
    { value : 'MUS', title : 'Маврикий' },
    { value : 'MRT', title : 'Мавритания' },
    { value : 'MDG', title : 'Мадагаскар' },
    { value : 'MYT', title : 'Майотта' },
    { value : 'MAC', title : 'Макао' },
    { value : 'MWI', title : 'Малави' },
    { value : 'MYS', title : 'Малайзия' },
    { value : 'MLI', title : 'Мали' },
    { value : 'UMI', title : 'Малые Тихоокеанские отдаленные острова Соединенных Штатов' },
    { value : 'MDV', title : 'Мальдивы' },
    { value : 'MLT', title : 'Мальта' },
    { value : 'MAR', title : 'Марокко' },
    { value : 'MTQ', title : 'Мартиника' },
    { value : 'MHL', title : 'Маршалловы острова' },
    { value : 'MEX', title : 'Мексика' },
    { value : 'FSM', title : 'Микронезия, Федеративные Штаты' },
    { value : 'MOZ', title : 'Мозамбик' },
    { value : 'MDA', title : 'Молдова, Республика' },
    { value : 'MCO', title : 'Монако' },
    { value : 'MNG', title : 'Монголия' },
    { value : 'MSR', title : 'Монтсеррат' },
    { value : 'MMR', title : 'Мьянма' },
    { value : 'NAM', title : 'Намибия' },
    { value : 'NRU', title : 'Науру' },
    { value : 'NPL', title : 'Непал' },
    { value : 'NER', title : 'Нигер' },
    { value : 'NGA', title : 'Нигерия' },
    { value : 'NLD', title : 'Нидерланды' },
    { value : 'NIC', title : 'Никарагуа' },
    { value : 'NIU', title : 'Ниуэ' },
    { value : 'NZL', title : 'Новая Зеландия' },
    { value : 'NCL', title : 'Новая Каледония' },
    { value : 'NOR', title : 'Норвегия' },
    { value : 'ARE', title : 'Объединенные Арабские Эмираты' },
    { value : 'OMN', title : 'Оман' },
    { value : 'BVT', title : 'Остров Буве' },
    { value : 'IMN', title : 'Остров Мэн' },
    { value : 'NFK', title : 'Остров Норфолк' },
    { value : 'CXR', title : 'Остров Рождества' },
    { value : 'HMD', title : 'Остров Херд и острова Макдональд' },
    { value : 'CYM', title : 'Острова Кайман' },
    { value : 'COK', title : 'Острова Кука' },
    { value : 'TCA', title : 'Острова Теркс и Кайкос' },
    { value : 'PAK', title : 'Пакистан' },
    { value : 'PLW', title : 'Палау' },
    { value : 'PSE', title : 'Палестинская территория, оккупированная' },
    { value : 'PAN', title : 'Панама' },
    { value : 'VAT', title : 'Папский Престол (Государство — город Ватикан)' },
    { value : 'PNG', title : 'Папуа-Новая Гвинея' },
    { value : 'PRY', title : 'Парагвай' },
    { value : 'PER', title : 'Перу' },
    { value : 'PCN', title : 'Питкерн' },
    { value : 'POL', title : 'Польша' },
    { value : 'PRT', title : 'Португалия' },
    { value : 'PRI', title : 'Пуэрто-Рико' },
    { value : 'MKD', title : 'Республика Македония' },
    { value : 'REU', title : 'Реюньон' },
    { value : 'RUS', title : 'Россия' },
    { value : 'RWA', title : 'Руанда' },
    { value : 'ROU', title : 'Румыния' },
    { value : 'WSM', title : 'Самоа' },
    { value : 'SMR', title : 'Сан-Марино' },
    { value : 'STP', title : 'Сан-Томе и Принсипи' },
    { value : 'SAU', title : 'Саудовская Аравия' },
    { value : 'SWZ', title : 'Свазиленд' },
    { value : 'SHN', title : 'Святая Елена, Остров вознесения, Тристан-да-Кунья' },
    { value : 'MNP', title : 'Северные Марианские острова' },
    { value : 'BLM', title : 'Сен-Бартельми' },
    { value : 'MAF', title : 'Сен-Мартен' },
    { value : 'SEN', title : 'Сенегал' },
    { value : 'VCT', title : 'Сент-Винсент и Гренадины' },
    { value : 'LCA', title : 'Сент-Люсия' },
    { value : 'KNA', title : 'Сент-Китс и Невис' },
    { value : 'SPM', title : 'Сент-Пьер и Микелон' },
    { value : 'SRB', title : 'Сербия' },
    { value : 'SYC', title : 'Сейшелы' },
    { value : 'SGP', title : 'Сингапур' },
    { value : 'SXM', title : 'Синт-Мартен' },
    { value : 'SYR', title : 'Сирийская Арабская Республика' },
    { value : 'SVK', title : 'Словакия' },
    { value : 'SVN', title : 'Словения' },
    { value : 'USA', title : 'Соединенные Штаты' },
    { value : 'SLB', title : 'Соломоновы острова' },
    { value : 'SOM', title : 'Сомали' },
    { value : 'SDN', title : 'Судан' },
    { value : 'SUR', title : 'Суринам' },
    { value : 'SLE', title : 'Сьерра-Леоне' },
    { value : 'TJK', title : 'Таджикистан' },
    { value : 'THA', title : 'Таиланд' },
    { value : 'TWN', title : 'Тайвань (Китай)' },
    { value : 'TZA', title : 'Танзания, Объединенная Республика' },
    { value : 'TLS', title : 'Тимор-Лесте' },
    { value : 'TGO', title : 'Того' },
    { value : 'TKL', title : 'Токелау' },
    { value : 'TON', title : 'Тонга' },
    { value : 'TTO', title : 'Тринидад и Тобаго' },
    { value : 'TUV', title : 'Тувалу' },
    { value : 'TUN', title : 'Тунис' },
    { value : 'TKM', title : 'Туркмения' },
    { value : 'TUR', title : 'Турция' },
    { value : 'UGA', title : 'Уганда' },
    { value : 'UZB', title : 'Узбекистан' },
    { value : 'UKR', title : 'Украина' },
    { value : 'WLF', title : 'Уоллис и Футуна' },
    { value : 'URY', title : 'Уругвай' },
    { value : 'FRO', title : 'Фарерские острова' },
    { value : 'FJI', title : 'Фиджи' },
    { value : 'PHL', title : 'Филиппины' },
    { value : 'FIN', title : 'Финляндия' },
    { value : 'FLK', title : 'Фолклендские острова (Мальвинские)' },
    { value : 'FRA', title : 'Франция' },
    { value : 'GUF', title : 'Французская Гвиана' },
    { value : 'PYF', title : 'Французская Полинезия' },
    { value : 'ATF', title : 'Французские Южные территории' },
    { value : 'HRV', title : 'Хорватия' },
    { value : 'CAF', title : 'Центрально-Африканская Республика' },
    { value : 'TCD', title : 'Чад' },
    { value : 'MNE', title : 'Черногория' },
    { value : 'CZE', title : 'Чешская Республика' },
    { value : 'CHL', title : 'Чили' },
    { value : 'CHE', title : 'Швейцария' },
    { value : 'SWE', title : 'Швеция' },
    { value : 'SJM', title : 'Шпицберген и Ян Майен' },
    { value : 'LKA', title : 'Шри-Ланка' },
    { value : 'ECU', title : 'Эквадор' },
    { value : 'GNQ', title : 'Экваториальная Гвинея' },
    { value : 'ALA', title : 'Эландские острова' },
    { value : 'SLV', title : 'Эль-Сальвадор' },
    { value : 'ERI', title : 'Эритрея' },
    { value : 'EST', title : 'Эстония' },
    { value : 'ETH', title : 'Эфиопия' },
    { value : 'ZAF', title : 'Южная Африка' },
    { value : 'SGS', title : 'Южная Джорджия и Южные Сандвичевы острова' },
    { value : 'OST', title : 'Южная Осетия' },
    { value : 'SSD', title : 'Южный Судан' },
    { value : 'JAM', title : 'Ямайка' },
    { value : 'JPN', title : 'Япония' },
];

export default arrCountries