import React from 'react';

const SapsanExitDelimiterView = ({  }) => (
  <div className="sapsan-exit-delimiter-block d-flex justify-content-between">
    <div className="sapsan-exit-delimiter-block-part d-flex flex-column">
      <div className="sapsan-exit-delimiter rounded-right mb-auto" />
      <div className="sapsan-exit-delimiter rounded-right" />
    </div>

    <div className="sapsan-exit-delimiter-block-part d-flex flex-column">
      <div className="sapsan-exit-delimiter rounded-left mb-auto" />
      <div className="sapsan-exit-delimiter rounded-left" />
    </div>
  </div>
);

module.exports = SapsanExitDelimiterView;