
export const stationInitialStatePlatform = () => {
  let initialState = {}

  if (typeof pedia_station_bus_data !== 'undefined') {

    initialState.currentTab = 'info';

    initialState.loaded = true;

    initialState.id = pedia_station_bus_data.id;
    initialState.title = pedia_station_bus_data.title;
    initialState.subTitle = pedia_station_bus_data.subTitle;
    initialState.preview = pedia_station_bus_data.preview;
    initialState.description = pedia_station_bus_data.description;
    initialState.coords = pedia_station_bus_data.coords;

    if (pedia_station_bus_data.image) {
      initialState.image = pedia_station_bus_data.image;
    }
  }

  return initialState;
}