// @flow
import PropTypes from 'prop-types'

import ContentTreeRowView from 'vokzal-platform/components/shared/content_tree_row/view'

ContentTreeRowView.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  url: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  onExpandClick: PropTypes.func.isRequired
};

const ContentTreeRow = ContentTreeRowView

export default ContentTreeRow