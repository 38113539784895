import { createAction } from 'redux-actions';

export const CAR_CATEGORY_SELECT_ACTION = 'STEP3/CAR_CATEGORY/SELECT'
export const select_category            = createAction(CAR_CATEGORY_SELECT_ACTION);

export const CAR_NUMBER_SELECT_ACTION   = 'STEP3/CAR_NUMBER/SELECT'
export const select_car_number          = createAction(CAR_NUMBER_SELECT_ACTION);

export const CAR_CATEGORY_SEARCH_ACTION = 'STEP3/CAR_CATEGORY/SEARCH'
export const category_search_complete   = createAction(CAR_CATEGORY_SEARCH_ACTION);

export const RESET_STATE_ACTION         = 'STEP3/STATE/RESET'
export const reset_step3_state          = createAction(RESET_STATE_ACTION);