// @flow
import PropTypes from 'prop-types';
import { SeatNumbersSeatedPropType } from '../../../../../src/types/';

import SapsanBusinessClassMapView from 'vokzal-platform/components/step3/sapsan/sapsan_business_class_map/view'

SapsanBusinessClassMapView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersSeatedPropType).isRequired
};

const SapsanBusinessClassMap = SapsanBusinessClassMapView

export default SapsanBusinessClassMap