// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'


import PediaTrainsListView from 'vokzal-platform/components/pedia/trains_list/view'
import {fetch_pedia_trains_list} from "../../../api/fetch_pedia_trains_list";
import {hidePreloaderAction, trainsListFetchCompleteAction} from "./action";

import { ScheduleTrainPropType } from '../../../types';

PediaTrainsListView.propTypes = {
  loaded: PropTypes.bool.isRequired,
  urlWithParams: PropTypes.string,
  trains : PropTypes.arrayOf(ScheduleTrainPropType).isRequired
};

class PediaTrainsListContainer extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      fetch_pedia_trains_list(this.props.urlWithParams, this.props.onDataLoaded);
    }
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    return <PediaTrainsListView {...this.props} />;
  }
}


const mapStateToProps = state => {
     return {
       loaded: state.pedia_trains_list.loaded,
       urlWithParams: state.pedia_trains_list.urlWithParams,
       trains: state.pedia_schedule.schedule
    }
}

const mapDispatchToProps = dispatch => {

    return {
      onDataLoaded : (time_spent, response) => {
        dispatch(hidePreloaderAction());
        dispatch(trainsListFetchCompleteAction({ time_spent, response }));
      },
    }
}


PediaTrainsListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PediaTrainsListContainer)

export default PediaTrainsListContainer