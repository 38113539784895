import React from 'react';

import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import TicketResultView from 'vokzal-platform/components/ticket_result/view'

TicketResultView.propTypes = {
  // er: PropTypes.numeric.isRequired,
  pin: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  return {
    er: state.ticket_result.er,
    pin: state.ticket_result.pin,
    phone: state.ticket_result.phone
  }
}

const TicketResultContainer = connect(
  mapStateToProps,
)(TicketResultView);

export default TicketResultContainer