import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { paymentFormUrl } from '../../../utils/url_params';

import { paymentStep } from 'vokzal-platform/managers/navigation'
import OrderButtonView from 'vokzal-platform/components/confirmation/order_button/view'

OrderButtonView.propTypes = {
  urlParams : PropTypes.string.isRequired
};

const mapStateToProps = state => {

  const urlParams = paymentFormUrl(state);

  return { urlParams };
};

const mapDispatchToProps = dispatch => {
  return {
    onButtonClick : (urlParams) => {
      //TODO make request and validate output
      paymentStep(dispatch, urlParams)
    }
  }

};

const OrderButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderButtonView);

export default OrderButtonContainer