import React, { useState } from 'react';
import StarRatingComponent from 'react-star-rating-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAward, faDharmachakra } from '@fortawesome/free-solid-svg-icons'
import { faWaze, faGalacticRepublic, faFly } from "@fortawesome/free-brands-svg-icons";
import { faSun } from "@fortawesome/free-regular-svg-icons";
import BootstrapLabeledInput from '../../base/bootstrap_labeled_input';
import i18n from "../../../i18n";


const ReviewView = ({review, onChanged, onCheckRating, onSubmit, onDataLoaded, onError}) => {
    // let rating = 3;
    const [isShown, setIsShown] = useState(false);

    return (
        <div className="review">
            {review.check_error || review.edit_success ?
                <h5>Благодарим вас за отзыв</h5>
                : <div>
                    <h5>Поделитесь вашими впечатлениями от поездки</h5>
                    <div className="rating">
                        <p>{i18n.t('review_clean')}</p>
                        <StarRatingComponent
                            name="clean"
                            starCount={10}
                            // value={rating}
                            renderStarIcon={() => <span className="vote-icon"><FontAwesomeIcon icon={faWaze} /></span>}
                            onStarClick={(value, prevValue, name) => onCheckRating(value, name)}
                            starColor="#b4f112"
                            emptyStarColor="#b315b1"
                        />
                    </div>

                    <div className="rating">
                        <p>{i18n.t('review_comfort')}</p>
                        <StarRatingComponent
                            name="comfort"
                            starCount={10}
                            // value={rating}
                            renderStarIcon={() => <span className="vote-icon"><FontAwesomeIcon icon={faWaze} /></span>}
                            onStarClick={(value, prevValue, name) => onCheckRating(value, name)}
                            starColor="#b4f112"
                            emptyStarColor="#b315b1"
                        />
                    </div>

                    <div className="rating">
                        <p>{i18n.t('review_service')}</p>
                        <StarRatingComponent
                            name="service"
                            starCount={10}
                            // value={rating}
                            renderStarIcon={() => <span className="vote-icon"><FontAwesomeIcon icon={faWaze} /></span>}
                            onStarClick={(value, prevValue, name) => onCheckRating(value, name)}
                            starColor="#b4f112"
                            emptyStarColor="#b315b1"
                        />
                    </div>

                    <div className="rating">
                        <p>{i18n.t('review_price_quality')}</p>
                        <StarRatingComponent
                            name="price_quality"
                            starCount={10}
                            // value={rating}
                            renderStarIcon={() => <span className="vote-icon"><FontAwesomeIcon icon={faWaze} /></span>}
                            onStarClick={(value, prevValue, name) => onCheckRating(value, name)}
                            starColor="#b4f112"
                            emptyStarColor="#b315b1"
                        />
                    </div>

                    <div className="rating">
                        <p>{i18n.t('review_general')}</p>
                        <StarRatingComponent
                            name="general"
                            starCount={10}
                            // value={rating}
                            renderStarIcon={() => <span className="vote-icon"><FontAwesomeIcon icon={faWaze} /></span>}
                            onStarClick={(value, prevValue, name) => onCheckRating(value, name)}
                            starColor="#b4f112"
                            emptyStarColor="#b315b1"
                        />
                    </div>

                    <span className="label">Ваше имя</span>
                    <BootstrapLabeledInput name="firstname" value={review.firstname}
                                           onChange={(value, inputElement) => onChanged(value, review.firstname, inputElement)}  />
                    <span className="label">Отзыв</span>

                    <BootstrapLabeledInput name="message" type="textarea" rows="5" value={review.message}
                                           onChange={(value, inputElement) => onChanged(value, review.message, inputElement)}/>

                    <span className="label site" onClick={() => setIsShown(!isShown)}>Ваше мнение о работе сайта</span>
                    {isShown ? <span className="label site-info">Если у вас есть пожелания или замечания по работе нашего сервиса опишите их (не будет опубликовано)</span> : null}
                    {isShown ? <BootstrapLabeledInput name="site" type="textarea" rows="5" value={review.site} className={review.site_show ? '' : 'hidden'}
                                           onChange={(value, inputElement) => onChanged(value, review.site, inputElement)}/> : null}


                    <div className="standart-button centered mb-3 mt-4" onClick={() => onSubmit(review, onDataLoaded, onError)}>
                        <span>{i18n.t('submit')}</span>
                    </div>
                </div>
            }

        </div>
    )};

module.exports = ReviewView;