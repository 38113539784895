import React, { Component } from 'react';
import { connect } from 'react-redux'

import OrdersView from 'vokzal-platform/components/user/orders/view'
import {user_request} from "../../../api/user_request";
import {userOrdersFetchCompleteAction, userOrderDeleteAction, userSetValueAction} from "../action";


class OrdersContainer extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      user_request('/api/orders', {limit: 20}, this.props.onDataLoaded, this.props.onError);
    }
  }

  render() {
    return <OrdersView {...this.props} />;
  }
}

const mapStateToProps = ({user}) => {
  return {
    title: 'Редактировать',
    loaded: user.orders_loaded,
    orders: Object.keys(user.orders).length ? user.orders : null,
    current_page: user.orders_current_page,
    count: user.orders_pages,
    limit: user.orders_limit,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDataLoaded: (time_spent, response) => {
      dispatch(userOrdersFetchCompleteAction({ time_spent, response }));
    },
    onError: (error) => {
      // console.log('error', '*****')
    },
    onDeleteOrder: (id) => {
      user_request('/api/order/delete/' + id, {}, () => {
        dispatch(userOrderDeleteAction(id))
      }, () => {});
    },
    onPaginationClick: (page, limit, onDataLoaded, onError) => {
      dispatch(userSetValueAction({orders_current_page: page}))
      user_request('/api/orders', {page: page, limit: limit}, onDataLoaded, onError);
    }
  }
}

OrdersContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrdersContainer)

export default OrdersContainer