import React from 'react';
import { Collapse } from 'react-bootstrap'

import PediaOrderForm from '../../../../src/components/pedia/order_form';

const PediaOrderBlockView = ({ scheduleGroup, trainNumber, orderButtonTitle, showOrderForm, onOrderClick, one, type = false }) => (
  <div className="pedia-schedule-order mt-3">
    {
      (!showOrderForm) ?
          <button type="button" className="btn btn-outline-success btn-block text-uppercase font-weight-medium" onClick={() => onOrderClick(scheduleGroup, trainNumber, one, type)}>
            {orderButtonTitle}
          </button>
        : null
    }

    <Collapse in={showOrderForm}>
      <div>
        <PediaOrderForm trainNumber={trainNumber} />
      </div>
    </Collapse>
  </div>
);

module.exports = PediaOrderBlockView;