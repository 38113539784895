import React from 'react';

import SwitchStations from '../../../../src/components/home/switch_stations'
import { StationFrom, StationTo } from '../../../../src/components/home/station_input'
import { FromDate, ToDate } from '../../../../src/components/home/date_input'
import AddDateTo from '../../../../src/components/home/add_date_to'

import { QuickSelectionFrom, QuickSelectionTo } from '../../../../src/components/home/quick_select_stations'

import SearchButton from '../../../../src/components/home/search_button'

import StationModal from '../../../../src/components/home/station_modal'
import DatesModal from '../../../../src/components/home/dates_modal'

const SearchFormView = ({ title }) => (
  <div className="p-3">
      <h1 className="h4 search-title">{title}</h1>
    {/*<h1 className="h4 mb-4" dangerouslySetInnerHTML={{__html: title.replace(/(?:\r\n|\r|\n)/g, '<br />')}} />*/}

    <form className="home-search-form">
<div className="row">
    <div className="col-md-8">
        <div className="stations">
            <SwitchStations />
            <div className="row">
                <div className="col-sm-6">
                    <StationFrom />
                    <div className="d-block d-sm-none">
                        <QuickSelectionFrom />
                    </div>
                </div>
                <div className="col-sm-6">
                    <StationTo />
                </div>
            </div>
            <QuickSelectionTo />
        </div>
    </div>
    <div className="col-md-4">
        <div className="dates-select form-inline">
            <FromDate deletable={false} />
        </div>
    </div>
</div>

        <div className="row">
            <div className="col-md-4 offset-md-4 col-sm-6 offset-sm-3 ">
                <SearchButton />
            </div>
        </div>

          <StationModal />
          <DatesModal />
      </form>
  </div>
);

module.exports = SearchFormView;