import PropTypes from 'prop-types';

import { SeatNumbersSeatedPropType } from '../../../../src/types/';

import SeatedCabinView from 'vokzal-platform/components/step3/seated_cabin/view'

SeatedCabinView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  cabinInfo : SeatNumbersSeatedPropType,
};

const SeatedCabin = SeatedCabinView

export default SeatedCabin