import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import PropTypes from "prop-types";
import BootstrapLabeledInput from '../../base/bootstrap_labeled_input';
import i18n from "../../../i18n";

const LoginModalView = ({ show, onCloseClick, onClick, text, className, title, email, previous_email, email_error, password, previous_password, password_error, onEmailChanged, onPasswordChanged, onSubmit, onRememberChanged, remember, onClickRenew, onDataLoaded, onError, error }) => {

    return (
        <div>
            <div className={className} onClick={() => onClick()}>
                {text}
            </div>

            <Modal show={show} animation={false} dialogClassName="auth-modal" backdropClassName="show resp-search-modal">

                <Modal.Header>
                    <Modal.Title id="contained-modal-title-lg" componentClass="h5">{title}</Modal.Title>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseClick}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>

                <Modal.Body bsClass="modal-body pt-0">

                    <span className="label">{i18n.t('email')}</span>
                    <BootstrapLabeledInput fieldsetClass={email_error ? "input-invalid" : ""}
                                           name="email" value={email} required={true}
                                           onChange={(value, inputElement) => onEmailChanged(value, email, inputElement)} />
                    {email_error ? <span className="label error">{email_error}</span> : null}
                    <span className="label mt-3">{i18n.t('password')}</span>
                    <BootstrapLabeledInput fieldsetClass={password_error ? "input-invalid" : ""}
                                           name="password" value={password} required={true}
                                           maxlength="30"
                                           type="password"
                                           onChange={(value, inputElement) => onPasswordChanged(value, password, inputElement)} />
                    {password_error ? <span className="label error">{password_error}</span> : null}

                    <Form.Group controlId="formBasicCheckbox" className="mt-2 fs-92">
                        <Form.Check type="checkbox" label="Запомнить меня" checked={remember === 1 ? "checked" : null}
                                    onChange={() => onRememberChanged(remember)}
                        />
                    </Form.Group>

                    {error ? <span className="label error">{error}</span> : null}

                    <div className="standart-button centered mb-3 mt-4" onClick={() => onSubmit(email, password, remember, onDataLoaded, onError)}>
                        <span>{i18n.t('login')}</span>
                    </div>

                    <span className="link renew-password" onClick={() => onClickRenew()}>Забыли пароль?</span>
                </Modal.Body>

            </Modal>



        </div>
    )};

LoginModalView.propTypes = {
    show: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
};

module.exports = LoginModalView;




