import React from 'react';

import BootstrapSelect from './bootstrap_select';
import BootstrapLabel from './bootstrap_label';

class BootstrapLabeledSelect extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        if (this.props.show === false) {
            return null;
        }

        return (
            <fieldset className={(this.props.fieldsetClass || '') + " form-group"} onClick={this.props.onClick}>
                <BootstrapSelect {...this.props} />
                <BootstrapLabel id={this.props.id}>{this.props.label}</BootstrapLabel>
            </fieldset>
        );
    }
}

module.exports = BootstrapLabeledSelect;