import React from 'react';
import { connect } from 'react-redux';

import SeatPriceView from 'vokzal-platform/components/step3/seat_price/view';

import i18n from 'vokzal-platform/i18n';

const mapStateToPropsUpper = state => {

  return {
    title : i18n.t('upper_seats'),
    price : 1512,
    discount : 0,
    seatsCnt : '12 мест'
  }
};

const mapStateToPropsLower = state => {

  return {
    title : i18n.t('lower_seats'),
    price : 1211,
    discount : 0,
    seatsCnt : '10 мест'
  }
};

export const UpperSeatPriceContainer = connect(
  mapStateToPropsUpper
)(SeatPriceView);

export const LowerSeatPriceContainer = connect(
  mapStateToPropsLower
)(SeatPriceView);
