import React from 'react';
import SearchForm from '../../../../src/components/shared/search_form';

const PediaHeaderView = ({ title, subTitle, reverseColor, type, data }) => (
  <div className={"pedia-header ml-3 mr-3 " + ((reverseColor) ? "pedia-header-dark" : "" )}>

      {type === 'train' ? <p><a href="/">Vokzal.ru</a> → <a href="/poezda/">Поезда</a> → {data.trainType === 1 ? data.trainName : `Маршрут поезда ${title} ${subTitle}`}</p> : ''}

      <div className="mt-auto">
      <h1 className="h4 mb-2 font-medium-light font-weight-normal text-uppercase">{title}</h1>
    </div>

    <div className="">
      <h3 className="h5 mb-4 font-medium-light font-weight-normal">{subTitle}</h3>
    </div>

      <SearchForm />

  </div>
);

module.exports = PediaHeaderView;