import { handleActions } from 'redux-actions'

import { stationInitialStatePlatform } from 'vokzal-platform/components/pedia/station_bus/reducer';

import { STATION_BUS_FETCH_COMPLETE_ACTION, STATION_BUS_URL_STORE_ACTION } from "./action";

const defaultState = {
  loaded : false, urlWithParams: null, title: '', subTitle : '', description : '',
  image : { caption : "default", url : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAABlBMVEX///////9VfPVsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAACklEQVQImWNgAAAAAgAB9HFkpgAAAABJRU5ErkJggg==" }
}

const initialState = Object.assign({}, defaultState, stationInitialStatePlatform())

const PediaStationBusReducer = handleActions({
    [STATION_BUS_FETCH_COMPLETE_ACTION]: (state, action) => {

      if (!action.payload.response.image) {
        delete action.payload.response.image;
      }

      return {...state, loaded : true, ...action.payload.response}
    },
    [STATION_BUS_URL_STORE_ACTION]: (state, action) => {
      let new_state = {}
      if (state.urlWithParams !== action.payload) {
        new_state = defaultState;
      }
      new_state.urlWithParams = action.payload;

      return {...state, ...new_state}
    },
}, initialState)

export default PediaStationBusReducer