import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from "prop-types";

const PersonModalView = ({ show, onCloseClick, onClick, text, className }) => {

    return (
        <div>
            <div className={className} onClick={() => onClick()}>
                {text}
            </div>

            <Modal show={show} animation={false} dialogClassName="h-100 oferta-modal" backdropClassName="show resp-search-modal">

                <Modal.Header>
                    <div className="d-flex flex-column">
                        <div className="p-0 d-flex justify-content-between">
                            <Modal.Title id="contained-modal-title-lg" componentClass="h5"><strong>Соглашение об обработке персональных данных</strong></Modal.Title>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onCloseClick() }>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </Modal.Header>

                <Modal.Body bsClass="modal-body pt-0">
                        <p>Присоединяясь к настоящему Соглашению и оставляя свои данные на Сайте
                            vokzal.ru (далее – Сайт),
                            путем заполнения полей онлайн-заявки (регистрации) Пользователь: &nbsp;</p>
                        <p>• подтверждает, что все указанные им данные принадлежат лично
                            ему, &nbsp;</p>
                        <p>• подтверждает и признает, что им внимательно в полном объеме прочитано
                            Соглашение и условия
                            обработки его персональных данных, указываемых им в полях он-лайн заявки (регистрации),
                            текст соглашения и
                            условия обработки персональных данных ему понятны; &nbsp;</p>
                        <p>• дает согласие на обработку Сайтом предоставляемых в составе
                            информации персональных данных в
                            целях заключения между ним и Сайтом настоящего Соглашения, а также его последующего
                            исполнения; &nbsp;</p>
                        <p>• выражает согласие с условиями обработки персональных данных без
                            оговорок и ограничений.
                            &nbsp;</p>
                        <p>Пользователь дает свое согласие на обработку его персональных данных, а
                            именно совершение
                            действий, предусмотренных п. 3 ч. 1 ст. 3 Федерального закона от 27.07.2006 N 152-ФЗ "О
                            персональных данных", и
                            подтверждает, что, давая такое согласие, он действует свободно, своей волей и в своем
                            интересе. &nbsp; Согласие
                            Пользователя на обработку персональных данных является конкретным, информированным и
                            сознательным. &nbsp;</p>
                        <p>Настоящее согласие Пользователя признается исполненным в простой
                            письменной форме, на обработку
                            следующих персональных данных: фамилии, имени, отчества; числа, месяца, года рождения; места
                            рождения; вида и
                            номера документа, удостоверяющего личность; пола; гражданства; пункта отправления, пункта
                            назначения; даты
                            поездки; номерах телефонов; адресах электронной почты (E-mail). &nbsp;&nbsp;</p>
                        <p>Пользователь, предоставляет vokzal.ru право осуществлять следующие
                            действия (операции) с
                            персональными данными: сбор и накопление; хранение в течение установленных нормативными
                            документами сроков
                            хранения отчетности, но не менее трех лет, с момента даты прекращения пользования услуг
                            Пользователем; уточнение
                            (обновление, изменение); использование; уничтожение; обезличивание; передача по требованию
                            суда, в т.ч., третьим
                            лицам, с соблюдением мер, обеспечивающих защиту персональных данных от несанкционированного
                            доступа. &nbsp;
                            Указанное согласие действует бессрочно с момента предоставления данных и может быть отозвано
                            Вами путем подачи
                            заявления администрации сайта с указанием данных, определенных ст. 14 Закона «О персональных
                            данных». &nbsp;</p>
                        <p>Отзыв согласия на обработку персональных данных может быть осуществлен
                            путем направления
                            Пользователем соответствующего распоряжения в простой письменной форме на адрес электронной
                            почты (E-mail)
                            info@vokzal.ru</p>
                        <p>Сайт не несет ответственности за использование (как правомерное, так и
                            неправомерное) третьими
                            лицами Информации, размещенной Пользователем на Сайте, включая её воспроизведение и
                            распространение,
                            осуществленные всеми возможными способами. &nbsp;</p>
                        <p>Сайт имеет право вносить изменения в настоящее Соглашение. При внесении
                            изменений в актуальной
                            редакции указывается дата последнего обновления. Новая редакция Соглашения вступает в силу с
                            момента ее
                            размещения, если иное не предусмотрено новой редакцией Соглашения. &nbsp;</p>
                        <p>Действующая редакция всегда находится на странице по адресу:&nbsp;</p>
                        <p><a
                            href="https://vokzal.ru/help/confidential">https://vokzal.ru/help/confidential</a></p>
                        <p>К настоящему Соглашению и отношениям между пользователем и Сайтом,
                            возникающим в связи с
                            применением Соглашения подлежит применению право Российской Федерации. &nbsp;</p>
                    <button className="close close-bottom" onClick={() => onCloseClick()}>Закрыть</button>
                </Modal.Body>
            </Modal>



        </div>
)};

PersonModalView.propTypes = {
    show: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
};

module.exports = PersonModalView;




