import queryString from 'query-string';

import { sanitizeOrderNumber, sanitizeMobilePhone } from '../../../../src/utils/text_sanitizer';

export const returnTicketConfirmationInitialStatePlatform = () => {
  let initialState = {}
  if (typeof returnTicketConfirmationResult !== 'undefined') {
    initialState.show_form = false;
    initialState.details_loaded = true;
    initialState.details = returnTicketConfirmationResult;
  }

  if (location.pathname.startsWith('/return/confirmation')) {
    const queryObject = queryString.parse(location.search);

    const order = sanitizeOrderNumber(queryObject.pin);
    initialState.order_number = order.order_number;
    initialState.is_order_number_valid = order.is_order_number_valid;

    const phone = sanitizeMobilePhone(queryObject.phoneOrOrderId);
    initialState.mobile_phone = phone.mobile_phone;
    initialState.is_mobile_phone_valid = phone.is_mobile_phone_valid;
  }

  if (location.pathname.startsWith('/return') && location.search.startsWith('?pin')) {
    const queryObject = queryString.parse(location.search);

    const order = sanitizeOrderNumber(queryObject.pin);
    initialState.order_number = order.order_number;
    initialState.is_order_number_valid = order.is_order_number_valid;
  }

  return initialState;
}