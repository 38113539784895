import React from 'react';

import i18n from 'vokzal-platform/i18n';

import HeadMenu from '../../../src/components/shared/menu';

import ErrorMessage from '../../../src/components/shared/error_message';

import TicketReturnInfo from '../../../src/components/ticket_return/return_info';
import TicketReturnForm from '../../../src/components/ticket_return/return_form';
import TicketReturnDetails from '../../../src/components/ticket_return/return_details';

import FooterInfo from '../../../src/components/shared/footer_info';
import FooterLinks from '../../../src/components/home/footer_links';

const TicketReturnView = ({ errorMessage }) => (
    <div className="ticket-return gradient-background">
      <HeadMenu />

      <TicketReturnInfo />

      {(errorMessage) ? <ErrorMessage message={errorMessage} canGoBack={false} /> : null}

      <TicketReturnForm />

      <FooterInfo titleText={i18n.t('goodbye')} />
    </div>
);

module.exports = TicketReturnView;