import React from 'react';

class QuickSelectButton extends React.Component {
    constructor(props) {
        super(props);

        this.clickHandler = this.clickHandler.bind(this);
    }

    clickHandler(e) {

        if (!e.target.getAttribute("disabled")) {
            e.target.setAttribute("disabled", "disabled");
        } else {
            return;
        }

        e.target.setAttribute("disabled", "disabled");

        this.props.onClick(this.props);
    }

    render() {

        return (
          <div className="quick-select-button d-flex swiper-slide">
            <button type="button" className="btn btn-light btn-sm mr-2 p-1" data-id={this.props.id} data-name={this.props.station_name} onClick={this.clickHandler}>
                {this.props.station_name}
            </button>
          </div>
        );
    }
}

module.exports = QuickSelectButton;