import { connect } from 'react-redux'
import HelpPostsListView from 'vokzal-platform/components/help/posts_list/view'

import { extractParentHelpLink } from 'vokzal-platform/managers/navigation'

import { helpStoreUrlAction } from '../action';

import PropTypes from 'prop-types';
import { HelpPostListItemPropType } from '../../../../src/types/';

HelpPostsListView.propTypes = {
  canGoBack: PropTypes.bool.isRequired,
  subnodes : PropTypes.arrayOf(HelpPostListItemPropType),
  onBack: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  canGoBack: state.help.url !== "/help/",
  url: state.help.url,
  subnodes: state.help.data.subnodes
});

const mapDispatchToProps = dispatch => (
  {
    onBack: url => {
      const permalink = extractParentHelpLink(url);

      dispatch(helpStoreUrlAction(permalink));
    },

  }
);

const HelpPostsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpPostsListView);

export default HelpPostsListContainer