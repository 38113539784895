import React from 'react';

import Home from '../../../../src/components/home';

const HomeExtraTextView = ({ extra_html }) => (
    <div>
      <Home />

      <div className="m-4">
        <div className="home-extra-text" dangerouslySetInnerHTML={{__html: extra_html}} />
      </div>
    </div>
);

module.exports = HomeExtraTextView;