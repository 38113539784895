import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { SeatNumbersByCarTypes, TrainTypesPropType, SeatNumbersByCarSchema } from '../../../../src/types/';

import TrainMapView from 'vokzal-platform/components/step3/train_map/view'

TrainMapView.propTypes = {
  trainType : TrainTypesPropType.isRequired,
  carNumber : PropTypes.number.isRequired,
  carType : PropTypes.string.isRequired,
  carService : PropTypes.string.isRequired,
  cabins : PropTypes.arrayOf(Object).isRequired
};

const mapStateToProps = state => {

  let trainType = state.step3_categories.car_selected.trainType;

  const carType = state.step3_categories.car_selected.category;
  const carService = state.step3_categories.car_category_selected.service.code;

  const carNumber = parseInt(state.step3_categories.car_selected.number);
  const carSchema = state.step3_categories.car_selected.schema;

    if (process.env.NODE_ENV !== 'production') {
        console.log('carSchema --- ', carSchema);
    }

  if (carSchema && carSchema.indexOf('PRIGOR_') === 0) {
      trainType = 'prigorod';
  }

  let cabins = null;
  let autoSelection = 0;
  if (trainType === 'usual') {
    cabins = SeatNumbersByCarTypes[trainType][carType];
  } else if (trainType === 'sapsan' || trainType === 'strij') {

      if (trainType === 'strij' && SeatNumbersByCarSchema[trainType].hasOwnProperty(carSchema)) {
          cabins = SeatNumbersByCarSchema[trainType][carSchema];
      } else if (SeatNumbersByCarTypes[trainType].hasOwnProperty(carService)) {
          cabins = SeatNumbersByCarTypes[trainType][carService];
      } else {
          cabins = SeatNumbersByCarTypes[trainType][0];
          autoSelection = 1;
      }

  } else if (trainType === 'lastochka' || trainType === 'prigorod') {

    if (SeatNumbersByCarSchema[trainType].hasOwnProperty(carSchema)) {
      cabins = SeatNumbersByCarSchema[trainType][carSchema];
    } else {
      cabins = [];
      autoSelection = 1;
    }
  }

  return { trainType, carType, carService, carNumber, cabins, autoSelection, carSchema }
};

const TrainMapContainer = connect(
  mapStateToProps,
)(TrainMapView);

export default TrainMapContainer