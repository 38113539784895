// @flow
import { connect } from 'react-redux'

import Step3BusView from 'vokzal-platform/components/step3bus/view'

const mapStateToProps = state => {
  return {
    autoSelect: true
  }
}

const Step3BusContainer = connect(
  mapStateToProps
)(Step3BusView)

export default Step3BusContainer