// @flow

import { createAction } from 'redux-actions'

export const SET_STATION_FROM = "HOME/SEARCH_FORM/SET_STATION_FROM"
export const SET_STATION_TO = "HOME/SEARCH_FORM/SET_STATION_TO"
export const SHOW_QUICK_STATION_FROM = "HOME/SEARCH_FORM/QUICK_SELECT_FROM/SHOW"
export const SHOW_QUICK_STATION_TO = "HOME/SEARCH_TO/QUICK_SELECT_FROM/SHOW"

export const SHOW_DATE_TO = "HOME/SEARCH_FORM/SHOW_DATE_TO"
export const SET_DATE_FROM = "HOME/SEARCH_FORM/SET_DATE_FROM"
export const SET_DATE_TO = "HOME/SEARCH_FORM/SET_DATE_TO"
export const QUICK_SELECT = "HOME/SEARCH_FORM/QUICK_SELECT"
export const HIDE_DATES_MODAL = "HOME/SEARCH_FORM/QUICK_SELECT_TO/HIDE"
// export const SHOW_DATES_MODAL_ACTION   = 'HOME/SEARCH_FORM/MODAL/DATES/SHOW'
// export const HIDE_DATES_MODAL_ACTION   = 'HOME/SEARCH_FORM/MODAL/DATES/HIDE'
export const SEARCH_FORM = "HOME/SEARCH_FORM"

export const FILL_DATE_IF_NOT_SET = "HOME/SEARCH_FORM/DATE/FILL_IF_NOT_SET"

// export const SHOW_STATION_MODAL_ACTION = 'HOME/SEARCH_FORM/MODAL/STATION/SHOW'
// export const HIDE_STATION_MODAL_ACTION = 'HOME/SEARCH_FORM/MODAL/STATION/HIDE'

export const hide_dates_modal   = createAction(HIDE_DATES_MODAL, (show) => show, () => ({ show : false }))
export const search_form     = createAction(SEARCH_FORM)

export const searchFormAction     = createAction(SEARCH_FORM)
export const performSearchAction  = createAction('HOME/SEARCH_FORM/PERFORM_SEARCH') //FIXME

export const setStationFromAction = createAction(SET_STATION_FROM)
export const setStationToAction   = createAction(SET_STATION_TO)
export const setDateFromAction    = createAction(SET_DATE_FROM)
export const setDateToAction      = createAction(SET_DATE_TO)

export const showDateToAction     = createAction(SHOW_DATE_TO)

export const fillDateIfNotSetAction    = createAction(FILL_DATE_IF_NOT_SET)

// export const showStationModalAction = createAction(SHOW_STATION_MODAL_ACTION, (show) => show, () => ({ show : true }))
// export const hideStationModalAction = createAction(HIDE_STATION_MODAL_ACTION, (show) => show, () => ({ show : false }))

// export const showDatesModalAction   = createAction(SHOW_DATES_MODAL_ACTION, (show) => show, () => ({ show : true }))
// export const hideDatesModalAction   = createAction(HIDE_DATES_MODAL_ACTION, (show) => show, () => ({ show : false }))


export const quickSelectAction          = createAction(QUICK_SELECT)

export const showQuickStationFromAction  = createAction(SHOW_QUICK_STATION_FROM)
export const showQuickStationToAction  = createAction(SHOW_QUICK_STATION_TO)

export const SET_GEO_LOCATION = "HOME/SEARCH_FORM/GEO_LOCATION"
export const setGeoLocationAction  = createAction(SET_GEO_LOCATION)

export const STATIONS_BY_GEO_LOCATION = "HOME/SEARCH_FORM/STATIONS_BY_GEO_LOCATION"
export const stationsByGeoLocationAction  = createAction(STATIONS_BY_GEO_LOCATION)

export const SET_STATION_FROM_INFO = "HOME/SEARCH_FORM/SET_STATION_FROM_INFO"
export const SET_STATION_TO_INFO = "HOME/SEARCH_FORM/SET_STATION_TO_INFO"
export const SET_DATE_FROM_INFO = "HOME/SEARCH_FORM/SET_DATE_FROM_INFO"
export const setStationFromInfoAction = createAction(SET_STATION_FROM_INFO)
export const setStationToInfoAction   = createAction(SET_STATION_TO_INFO)
export const setDateFromInfoAction    = createAction(SET_DATE_FROM_INFO)

export const SET_QUICK_START = "HOME/SEARCH_FORM/SET_QUICK_START"
export const setQuickStart    = createAction(SET_QUICK_START)

export const SET_STEP2_ACTION                = 'STEP2/URL/SET'
export const setStep2Action                  = createAction(SET_STEP2_ACTION)

export const SET_STEP3_ACTION                = 'STEP3/URL/SET'
export const setStep3Action                  = createAction(SET_STEP3_ACTION)

export const SET_STEP4_ACTION                = 'STEP4/URL/SET'
export const setStep4Action                  = createAction(SET_STEP4_ACTION)

export const SET_TYPE_DATA                = 'HOME/SEARCH_FORM/SET_TYPE_DATA'
export const setTypeDataAction            = createAction(SET_TYPE_DATA)

export const SET_TYPE                     = 'HOME/SEARCH_FORM/SET_TYPE'
export const setTypeAction                = createAction(SET_TYPE)