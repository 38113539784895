// @flow

import { createAction } from 'redux-actions'

export const INDICATORVIEW_VISIBILITY = "INDICATORVIEW_VISIBILITY"
export const INDICATORVIEW_STARTWORKING = "INDICATORVIEW_STARTWORKING"

export const indicatorview_visibility  = createAction(INDICATORVIEW_VISIBILITY, (visible) => visible);

// export function indicatorview_visibility(visible) {
//   return {
//     type: INDICATORVIEW_VISIBILITY,
//     visible
//   }
// }

export function indicatorview_startworking() {
  return {
    type: INDICATORVIEW_STARTWORKING
  }
}