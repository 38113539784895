// @flow

import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { webAndroidTextMaskFix } from 'vokzal-platform/managers/navigation'
import TicketReturnFormView from 'vokzal-platform/components/ticket_return/return_form/view'

import { orderNumberChangedAction, mobilePhoneChangedAction } from './action';

import { sanitizeMobilePhone, sanitizeOrderNumber } from '../../../utils/text_sanitizer'

import i18n from 'vokzal-platform/i18n'

TicketReturnFormView.propTypes = {
  order_number: PropTypes.string.isRequired,
  is_order_number_valid: PropTypes.bool.isRequired,
  mobile_phone: PropTypes.string.isRequired,
  is_mobile_phone_valid: PropTypes.bool.isRequired,
  onOrderNumberChanged: PropTypes.func.isRequired,
  onMobilePhoneChanged: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
      order_number: state.ticket_return.order_number,
      is_order_number_valid: state.ticket_return.is_order_number_valid,
      mobile_phone: state.ticket_return.mobile_phone,
      is_mobile_phone_valid: state.ticket_return.is_mobile_phone_valid,
    }
};

const mapDispatchToProps = dispatch => {
    return {
      onOrderNumberChanged: (order_number, inputElement) => {

        const sanitized = sanitizeOrderNumber(order_number);
        dispatch(orderNumberChangedAction({order_number : '', is_order_number_valid : sanitized.is_order_number_valid}));
        dispatch(orderNumberChangedAction(sanitized));

        webAndroidTextMaskFix(inputElement, sanitized.order_number)
      },
      onMobilePhoneChanged : (mobile_phone, inputElement) => {

        const sanitized = sanitizeMobilePhone(mobile_phone);
        dispatch(orderNumberChangedAction({mobile_phone : '', is_mobile_phone_valid : sanitized.is_mobile_phone_valid}));
        dispatch(mobilePhoneChangedAction(sanitized));

        webAndroidTextMaskFix(inputElement, sanitized.mobile_phone)
      }
    }
}


const TicketReturnFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TicketReturnFormView)

export default TicketReturnFormContainer