// @flow
import PropTypes from 'prop-types';

import SapsanWardrobeView from 'vokzal-platform/components/step3/sapsan/base/sapsan_wardrobe/view'

SapsanWardrobeView.propTypes = {
  title: PropTypes.string.isRequired,
  autoHeight: PropTypes.bool.isRequired,
};

const SapsanWardrobe = SapsanWardrobeView

export default SapsanWardrobe