import moment from 'moment'

import { vokzal_config } from '../config';


export const fetch_segments = (searchUrlWithParams, onDataLoaded) => {
    const requested_at = moment();

    fetch(vokzal_config.rest.base_url + searchUrlWithParams, vokzal_config.rest.fetchParams)
        .then((response) => response.json())
        .then((responseJson) => {
            //TODO error handling
            onDataLoaded(moment().diff(requested_at), responseJson);
        });
};

export const search_buses = (searchUrlWithParams, onDataLoaded) => {
    const requested_at = moment();

    fetch(vokzal_config.rest.base_url + searchUrlWithParams, vokzal_config.rest.fetchParams)
        .then((response) => response.json())
        .then((responseJson) => {
            //TODO error handling
            onDataLoaded(moment().diff(requested_at), responseJson);
        });
};