import React from 'react';
import { Link } from 'react-router-dom';

const OrderChangeView = ({ step2, step3, step4 }) => {
  return <div className="order-change">
      {step2 ? <p><Link to={step2}>Выбрать другой поезд</Link></p> : null}
      {step3 ? <p><Link to={step3}>Выбрать другие места</Link></p> : null}
      {step4 ? <p><Link to={step4}>Изменить данные о пассажирах</Link></p> : null}
  </div>
}

module.exports = OrderChangeView;