import React from 'react';
import { connect } from 'react-redux';

import CabinPlaceHorizontalView from 'vokzal-platform/components/step3/cabin_place_horizontal/view'

import { select_seat, deselect_seat} from '../action';

const mapStateToProps = (state, ownProps) => {

  const isAvailable = state.step3_categories.car_selected.freeSeats.indexOf(ownProps.seatNumber.toString()) !== -1;
  let seatState = (isAvailable) ? 'available' : 'busy';

  if (seatState === 'available' && !state.step3_passengers.can_select_seat) {
    seatState = 'unavailable';
  }

  const isSelected = state.step3_passengers.selected_seats.filter(
    ((item, index, array) => ( item.seatNumber === ownProps.seatNumber ) )
  ).length === 1;
  seatState = (isSelected) ? 'selected' : seatState;

  return { state : seatState, priceDiapason: state.step3_categories.car_category_selected.price}
};

const mapDispatchToProps = dispatch => {
  return {
    onSeatClick : (carNumber, seatNumber, seatState, isUpper) => {
      if (seatState === 'selected') {
        dispatch(deselect_seat(seatNumber));
      } else if (seatState === 'available')  {
        dispatch(select_seat({carNumber, seatNumber, isUpper}));
      }

    }
  }
};

const CabinPlaceHorizontalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CabinPlaceHorizontalView);

export default CabinPlaceHorizontalContainer;

