import React from 'react';

const UfsLogo = ({loading, hide = false, className = ''}) => {

    return (
        loading && hide ? null : <div className={'ufs-logo ' + className}>
            {/*<img src="/img/ufs_l.gif" width="35" height="24" alt="" />*/}
            <span>С использованием технологии ООО "РЖД - Цифровые пассажирские решения"</span>
        </div>
)};

module.exports = UfsLogo;