// @flow
import PropTypes from 'prop-types';
import { SeatNumbersSeatedPropType } from '../../../../../types';

import SapsanCabinView from 'vokzal-platform/components/step3/sapsan/base/sapsan_cabin/view'

SapsanCabinView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  cabinInfo : SeatNumbersSeatedPropType,
  inSubBlock: PropTypes.bool,
  extraSpace: PropTypes.bool,
};

const SapsanCabin = SapsanCabinView

export default SapsanCabin