import React, { Component } from 'react';

import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { help_fetch_post } from '../../api/help_fetch_post'

import { helpStoreAction } from './action';


import HelpView from 'vokzal-platform/components/help/view'

HelpView.propTypes = {
  post_url : PropTypes.string.isRequired,
  data_loaded : PropTypes.bool.isRequired,
  has_subnodes : PropTypes.bool.isRequired,
};

class HelpContainer extends Component {

  componentDidMount() {
    if (!this.props.data_loaded && this.props.post_url) {
      help_fetch_post(this.props.post_url, this.props.onDataLoaded, this.props.onError);
    } else if (this.props.post_url !== this.props.location.pathname) {
      help_fetch_post(this.props.location.pathname, this.props.onDataLoaded, this.props.onError);
    }
  }

  componentDidUpdate() {
    if (!this.props.data_loaded && this.props.post_url) {
      help_fetch_post(this.props.post_url, this.props.onDataLoaded, this.props.onError);
    } else if (this.props.post_url !== this.props.location.pathname) {
      help_fetch_post(this.props.location.pathname, this.props.onDataLoaded, this.props.onError);
    }
  }

  render() {
    return <HelpView {...this.props} />;
  }
}


const mapStateToProps = state => {
  return {
    data_loaded: state.help.data_loaded,
    post_url: state.help.url,
    has_subnodes: state.help.data.subnodes && state.help.data.subnodes.length > 0
  }
}

const mapDispatchToProps = dispatch => (
  {
    onError: (post) => {
      // debugger
    },
    onDataLoaded: (time_spent, post) => {
      dispatch(helpStoreAction(post));
    },

  }
);


HelpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpContainer);

export default HelpContainer