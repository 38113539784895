import React from 'react';
import PropTypes from 'prop-types'

import { Modal } from 'react-bootstrap';

import DatesModalMonth from '../dates_modal_month/view';

import i18n from 'vokzal-platform/i18n'

const DatesModal = ({ show, selected_input, selected_date, onItemClick, onCloseClick, onDateSelected, searchFormState }) => (
    <Modal show={show} animation={false} dialogClassName="m-0 h-100 resp-search-modal" backdropClassName="show resp-search-modal">
        <Modal.Header>
            <Modal.Title id="contained-modal-title-lg" componentClass="h5">{i18n.t('trip_date')}</Modal.Title>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseClick}>
                <span aria-hidden="true">&times;</span>
            </button>
        </Modal.Header>
        <Modal.Body bsClass="modal-body pt-0">
            <div className="list-group">
                <DatesModalMonth offset="0" selectedDate={selected_date} handleClick={(date) => onItemClick(selected_input, date, onDateSelected, searchFormState)} />
                <DatesModalMonth offset="1" selectedDate={selected_date} handleClick={(date) => onItemClick(selected_input, date, onDateSelected, searchFormState)} />
                <DatesModalMonth offset="2" selectedDate={selected_date} handleClick={(date) => onItemClick(selected_input, date, onDateSelected, searchFormState)} />
                <DatesModalMonth offset="3" selectedDate={selected_date} handleClick={(date) => onItemClick(selected_input, date, onDateSelected, searchFormState)} />
            </div>
        </Modal.Body>
    </Modal>
);

DatesModal.propTypes = {
    show: PropTypes.bool.isRequired,
    selected_input: PropTypes.string.isRequired,
    selected_date: PropTypes.instanceOf(Date),
    onItemClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
};

module.exports = DatesModal;