import React from 'react';

import i18n from 'vokzal-platform/i18n'

import LastochkaCabin from '../../../../../src/components/step3/lastochka/base/lastochka_cabin';
import LastochkaLuggage from '../../../../../src/components/step3/lastochka/base/lastochka_luggage';
import LastochkaLeftCabin from '../../../../../src/components/step3/lastochka/base/lastochka_left_cabin';
import LastochkaRightCabin from '../../../../../src/components/step3/lastochka/base/lastochka_right_cabin';
import LastochkaCabinDelimiter from '../../../../../src/components/step3/lastochka/base/lastochka_cabin_delimiter';

const LastochkaCenterCarView = ({ carNumber, cabins }) => (
  <div className="train-car-select-seats-map rounded pt-2">

      <div className="train-car-select-seats-map-block train-car-map-text lastocha-service-area rounded small d-flex align-items-center rounded m-2">
          <div className="m-auto">{i18n.t('lastochka_service_area')}</div>
      </div>

      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[0]} />
      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[1]} />

      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[2]} />
      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[3]} />

      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[4]} />
      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[5]} />

      <LastochkaCabinDelimiter />

      <div className="d-flex justify-content-between">
          <div>
              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[6]} />
              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[7]} />
              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[8]} />
              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[9]} />

              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[10]} />
              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[11]} />
              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[12]} />
              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[13]} />

              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[14]} />
              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[15]} />
              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[16]} />
          </div>

          <div>
              <LastochkaRightCabin carNumber={carNumber} cabinInfo={cabins[6]} />
              <LastochkaRightCabin carNumber={carNumber} cabinInfo={cabins[7]} />
              <LastochkaRightCabin carNumber={carNumber} cabinInfo={cabins[8]} />
              <LastochkaRightCabin carNumber={carNumber} cabinInfo={cabins[9]} />

              <LastochkaLuggage />

              <LastochkaRightCabin carNumber={carNumber} cabinInfo={cabins[10]} />
              <LastochkaRightCabin carNumber={carNumber} cabinInfo={cabins[11]} />
              <LastochkaRightCabin carNumber={carNumber} cabinInfo={cabins[12]} />
              <LastochkaRightCabin carNumber={carNumber} cabinInfo={cabins[13]} />

              <LastochkaLuggage />

              <LastochkaRightCabin carNumber={carNumber} cabinInfo={cabins[14]} />
              <LastochkaRightCabin carNumber={carNumber} cabinInfo={cabins[15]} />
              <LastochkaRightCabin carNumber={carNumber} cabinInfo={cabins[16]} />
          </div>
      </div>

      <LastochkaCabinDelimiter />

      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[17]} />
      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[18]} />

      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[19]} />
      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[20]} />

      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[21]} />
      <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[22]} />
  </div>
);

module.exports = LastochkaCenterCarView;