import React from 'react';
import {RadioButtonChecked, RadioButtonUnchecked, Train} from "@material-ui/icons";

const PaymentTypeOptionView = ({ isActive, paymentType, onPaymentTypeClick }) => (
    <div className="payment-type-select payment-type-option" onClick={() => { onPaymentTypeClick(paymentType) }}>
      <div className="d-flex">
        <div className="select pl-2 pl-md-0">
          {/*<i className={"material-icons " + (isActive ? "active" : "not_active")}>{isActive ? "radio_button_checked" : "radio_button_unchecked" }</i>*/}
          {isActive ? <RadioButtonChecked className="active"/> : <RadioButtonUnchecked className="not_active"/> }
        </div>
        <div className="title font-weight-medium pl-2">
          {paymentType.title}
          <div className="images">
              { paymentType.images.map(
                  (image, index) => (<span key={index}><img src={"/img/" + image} alt="" /></span>)
              )}
          </div>

        </div>
      </div>
        <div className="info">{paymentType.info}</div>
        <div className="text">{paymentType.text}</div>
  </div>
);

module.exports = PaymentTypeOptionView;