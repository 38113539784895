import React from 'react';
import {Link} from "react-router-dom";

const MenuView = ({ reviews, stats, active }) => (
    <div className="page-section user-menu">
        <div className="row">
            <div className="col-md-12">
                <ul className="">
                    <li>{active === 'reviews' ? <span>{reviews}</span> : <Link to="/user/admin">{reviews}</Link>}</li>
                    <li>{active === 'stats' ? <span>{stats}</span> : <Link to="/user/admin/stats">{stats}</Link>}</li>
                </ul>
            </div>
        </div>
    </div>
);

module.exports = MenuView;