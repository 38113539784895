import moment from 'moment'
import {vokzal_config} from '../config'
import queryString from "query-string";

export const simple_request = (searchUrlWithParams, post, onDataLoaded, onError) => {

    const url = vokzal_config.rest.base_url + searchUrlWithParams;

    f(url, {...post}, onError, onDataLoaded);
}

const f = (url, post, onError, onDataLoaded) => {
    let fetchParams = vokzal_config.rest_api.fetchParams;

    fetchParams.body = queryString.stringify(post);

    fetchParams.method = 'POST';
    const requested_at = moment();
    fetch(url, fetchParams)
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.hasOwnProperty('error')) {
                onError(Object.assign(responseJson.error));
            } else {
                onDataLoaded(moment().diff(requested_at), responseJson);
            }
        });
}