import React from 'react';

import { BlogPreLoader } from '../../../../src/components/shared/preloader';
import BlogPostsList from '../../../../src/components/blog/posts_list';
import BlogPagination from '../../../../src/components/blog/pagination';

const BlogPostsContentView = ({ loaded }) => (
  <div className="blog-posts-content">
      <div className="blog-content p-3">
        {(loaded) ? <BlogPostsList /> : null}
        {(loaded) ? <BlogPagination  /> : null}
      </div>

      <BlogPreLoader show={!loaded} />
    </div>
);

module.exports = BlogPostsContentView;