import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import i18n from 'vokzal-platform/i18n';

import { SeatNumbersSeatedPropType } from '../../../../../types/index';

import SapsanWardrobeCabinWithToiletAndSeatsView from 'vokzal-platform/components/step3/sapsan/base/sapsan_wardrobe_cabin_with_toilet_and_seats/view'

SapsanWardrobeCabinWithToiletAndSeatsView.propTypes = {
  wardrobeTitle: PropTypes.string.isRequired,
  cabinInfo : SeatNumbersSeatedPropType,
  autoHeightWardrobe: PropTypes.bool.isRequired,
  autoHeightToilet: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({ wardrobeTitle : i18n.t('sapsan_luggage'), autoHeightWardrobe : true, autoHeightToilet : false })


const SapsanWardrobeCabinWithToiletAndSeatsContainer = connect(
  mapStateToProps
)(SapsanWardrobeCabinWithToiletAndSeatsView)

export default SapsanWardrobeCabinWithToiletAndSeatsContainer