import PropTypes from 'prop-types';

import Strizh_1_1u_v1View from 'vokzal-platform/components/step3/strij/strizh_1_1u_v1/view'

Strizh_1_1u_v1View.propTypes = {
    carNumber : PropTypes.number.isRequired,
};

const Strizh_1_1u_v1 = Strizh_1_1u_v1View

export default Strizh_1_1u_v1