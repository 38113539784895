import React from 'react';

import SapsanWardrobe from '../../../../../../src/components/step3/sapsan/base/sapsan_wardrobe';
import SapsanToilet from '../../../../../../src/components/step3/sapsan/base/sapsan_toilet';

const SapsanWardrobeCabinWithToiletView = ({ carNumber, wardrobeTitle, cabinInfo, autoHeightWardrobe, autoHeightToilet }) => (
  <div className="sapsan-wardrobe-cabin d-flex justify-content-between mb-2 mt-4 pt-4">

    <SapsanWardrobe title={wardrobeTitle} autoHeight={autoHeightWardrobe} />
    <SapsanToilet autoHeight={autoHeightToilet} />
  </div>
);

module.exports = SapsanWardrobeCabinWithToiletView;