import React from 'react';
import TrainRow from '../../../../src/components/step2/train_row';

import { Step2AlternativePreLoader } from '../../../../src/components/shared/preloader';


const TrainsSearchResultAlternativeView = ({ trains, showPreloader, onTrainClick }) => (
  <div>
    <div className="search-results mr-2 ml-2 mb-4 pt-2 d-flex flex-column">
      {
        trains.map((m, index) =>  (
          <div key={index} className="train-info-wrapper rounded mb-1 pb-1">
            <TrainRow trainItem={m} onTrainClick={() => onTrainClick(m) } />
          </div>
        ))
      }
    </div>

    <Step2AlternativePreLoader show={showPreloader} />
  </div>
);

module.exports = TrainsSearchResultAlternativeView;