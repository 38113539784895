import {connect} from 'react-redux'

import HeaderView from 'vokzal-platform/components/shared/header/view'
import {getCookie, logout} from "../../../utils/functions";
import {userSetValueAction} from "../../user/action";
import {user_request} from "../../../api/user_request";

const mapStateToProps = ({user}) => {
  let refresh_token = getCookie('refresh_token');
  let user_email = getCookie('user_email');

  let email = user.hasOwnProperty('login') && user.login ? user.email : null;
  if (!email) {
    email = user_email && refresh_token ? user_email : null
  }
  return {
    user: email
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDataLoaded : (time_spent, response, post) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Данные пользователя получены ', time_spent);
      }
      // запросить данные здесь в обоих случаях
      dispatch(userSetValueAction({profile: response}))
    },
    onError : (error) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Необходимо авторизоваться ', error);
      }
      // нужно перейти на страницу логина - вывести всплывающее окно
      // dispatch(userSetValueAction({error}))
    },
    onClickLogout : () => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Выход из личного кабинета ');
      }
      user_request('/api/logout', {}, () => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Токен успешно удалён ');
        }
        logout();
         dispatch(userSetValueAction({email: ''}))
        // в личном кабинете мы или на других страницах
        if (window.location.pathname.slice(1,5) === "user") {
          window.location.href="/";
        }
      },() => {
        logout();
         window.location.href="/";
      });
      // dispatch(userSetValueAction({error}))
    },
  }
}

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderView)

export default HeaderContainer