import React from 'react';

import i18n from 'vokzal-platform/i18n';

import { PediaPreLoader } from '../../../../src/components/shared/preloader';


import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';
import PediaHeaderImage from '../../../../src/components/pedia/header_image';
import PediaHeader from '../../../../src/components/pedia/header';

import PediaTabGroup from '../../../../src/components/pedia/tab_group';
import PediaTabContent from '../../../../src/components/pedia/tab_content';

import PediaStationBusMap from '../../../../src/components/pedia/station_bus_map';

import PediaScheduleBus from '../../../../src/components/pedia/schedule_bus';


import FooterInfo from '../../../../src/components/shared/footer_info';
import FooterLinks from '../../../../src/components/home/footer_links';
import News from '../../../../src/components/home/news/index';
const PediaStationBusView = ({ loaded, preview, description }) => (
    <div className="pedia">
        <div className="pedia-block">
          <div className="pedia-header-block">
            <PediaHeaderImage />

            <HeadMenuPedia />

            <PediaHeader type="bus" />

            <PediaTabGroup type="station" />
          </div>

          <PediaTabContent type="info">
            {(preview) ? <div className="pedia-preview p-3" dangerouslySetInnerHTML={{__html: preview}} /> : null}
            {(description) ? <div className="pedia-description p-3" dangerouslySetInnerHTML={{__html: description}} /> : null}

            <PediaStationBusMap />
          </PediaTabContent>

          <PediaTabContent type="schedule">
            <PediaScheduleBus />
          </PediaTabContent>

          <PediaPreLoader show={!loaded} />
        </div>

        <FooterInfo titleText={i18n.t('goodbye')} />
        <FooterLinks />
        <News />
    </div>
);

module.exports = PediaStationBusView;