import React from 'react';

import BootstrapInlineButton from '../../base/bootstrap_inline_button';
import { StationEmpty } from '../../../../src/types/';

const SwitchStationsView = ({ from, to, onSwitchClick }) => {

    if (from === StationEmpty || to === StationEmpty) {
        return null;
    }

    return (
      <BootstrapInlineButton id="stations-switch-button" className="rounded-circle rounded-button switch-button p-1" onClick={() => onSwitchClick(from, to)}>
        compare_arrows
      </BootstrapInlineButton>
    )
};

module.exports = SwitchStationsView;