import React from 'react';

import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';

import Edit from '../../../../src/components/user/edit';
import ChangePassword from '../../../../src/components/user/change_password';
import  Menu from '../../../../src/components/user/menu';

const ProfileView = () => (
    <div className="profile">
        <div className="pedia">
            <div className="pedia-block">
                <div className="pedia-header-block">

                    <HeadMenuPedia />

                    <Menu active="profile"/>

                    <h3>Профиль пользователя</h3>

                </div>

            </div>
        </div>
        <div className="page-section">
            <div className="row">
                <div className="col-md-6">
                    <Edit />
                </div>

                <div className="col-md-6">
                    <ChangePassword />
                </div>
            </div>
        </div>


    </div>
);

module.exports = ProfileView;