// @flow
import { createAction } from 'redux-actions'

export const SHOW_COUNTRIES_MODAL_ACTION    = "STEP4/MODAL/COUNTRIES/SHOW"
export const HIDE_COUNTRIES_MODAL_ACTION    = "STEP4/MODAL/COUNTRIES/HIDE"
export const SEARCH_COUNTRY_REQUEST_ACTION  = "STEP4/MODAL/COUNTRIES/SEARCH/REQUEST"
export const SEARCH_COUNTRY_RESPONSE_ACTION = "STEP4/MODAL/COUNTRIES/SEARCH/RESPONSE"

export const showCountiesModalAction        = createAction(SHOW_COUNTRIES_MODAL_ACTION, (show) => show, () => ({ show : true }))
export const hideCountiesModalAction        = createAction(HIDE_COUNTRIES_MODAL_ACTION, (show) => show, () => ({ show : false }))
export const searchCountryRequestAction     = createAction(SEARCH_COUNTRY_REQUEST_ACTION)
export const searchCountryResponseAction    = createAction(SEARCH_COUNTRY_RESPONSE_ACTION)
