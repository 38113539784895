import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import OrderDetailsOfflineView from 'vokzal-platform/components/confirmation/order_details_offline/view'

OrderDetailsOfflineView.propTypes = {
  is_bus_tickets: PropTypes.bool.isRequired,
  order_id: PropTypes.number.isRequired,
  order_form: PropTypes.object.isRequired,
  seat_changed: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    is_bus_tickets : state.confirmation.order_form.is_bus_tickets,
    order_id : state.confirmation.order_id,
    order_form : state.confirmation.order_form,
    seat_changed : state.confirmation.seat_changed,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}


const OrderDetailsOfflineContainter = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetailsOfflineView)

export default OrderDetailsOfflineContainter