import {handleActions} from 'redux-actions'

import {ContentTreeItems} from '../../../../data/home'

import {TOGGLE_CONTENT_TREE,} from './action'

const initialState = {
  items: ContentTreeItems,
  expanded: false
}

const ContentTreeReducer = handleActions({
  [TOGGLE_CONTENT_TREE]: (state, action) => {
    const items = state.items.map((item) => {
      if (item.id === action.payload.id) {
        action.payload.isExpanded = !action.payload.isExpanded
        return action.payload
      }
      return item
    })

    return {...state, items: items}
  },
}, initialState)

export default ContentTreeReducer