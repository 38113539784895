import { handleActions } from 'redux-actions';

import { BUS_SEARCH_ACTION, BUS_SEARCH_ERROR_ACTION } from './action';

let initialState = {  };

export const Step3BusReducer = handleActions({
    [BUS_SEARCH_ACTION]: (state, action) => {
        return {...state, ...action.payload};
    },
    [BUS_SEARCH_ERROR_ACTION]: (state, action) => {
        let text = action.payload.code === 600 ? `К сожалению, ${state.form.date_from.locale('ru').format('D MMMM')} нет автобусов по маршруту ${state.bus.ab}.` : '';

        return {...state, error: {code: action.payload.code, text: text}};
    },
}, initialState)