import moment from 'moment'

import {vokzal_config} from '../config'

//TODO combine actions for web and mobile
import {
  localStationsRequestAction,
  localStationsResponseAction
} from '../components/home/station_modal/action'

export const searchTrainRoute = function (dispatch, searchString) {

  const requested_at = moment()

  dispatch(localStationsRequestAction({input_value: searchString, requested_at}))

  fetch(vokzal_config.rest.base_url + "/poezda/_order-data/number/" + searchString, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {

      const stations = responseJson.stations;

      //TODO error handling
      dispatch(localStationsResponseAction({requested_at, stations}))
    })
}