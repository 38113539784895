import { handleActions } from 'redux-actions'

// { application_type: 'web' };

import { mainInitialStatePlatform } from 'vokzal-platform/components/reducer';

let initialState = { application_type: null };

initialState = Object.assign({}, initialState, mainInitialStatePlatform())

const MainReducer = handleActions({

}, initialState)

export default MainReducer