import { createAction } from 'redux-actions';

export const HELP_POST_STORE_URL_ACTION   = 'HELP/POST/STORE_URL'
export const helpStoreUrlAction           = createAction(HELP_POST_STORE_URL_ACTION);

export const HELP_TITLE_ACTION            = 'HELP/POST/TITLE'
export const helpTitleAction              = createAction(HELP_TITLE_ACTION);

export const HELP_POST_STORE_ACTION       = 'HELP/POST/STORE'
export const helpStoreAction              = createAction(HELP_POST_STORE_ACTION);

export const SHOW_PRELOADER_ACTION        = 'HELP/POSTS/PRELOADER/SHOW'
export const showPreloaderAction          = createAction(SHOW_PRELOADER_ACTION, (show) => show, () => ({ show : true }))

export const HIDE_PRELOADER_ACTION        = 'HELP/POSTS/PRELOADER/HIDE'
export const hidePreloaderAction          = createAction(HIDE_PRELOADER_ACTION, (show) => show, () => ({ show : false }))