// @flow
import { handleActions } from 'redux-actions'
import {
  SHOW_QUICK_SELECT_FROM,
  SHOW_QUICK_SELECT_TO,
  HIDE_QUICK_SELECT_FROM,
  HIDE_QUICK_SELECT_TO,
  FETCH_QUICK_SELECT_FROM,
  FETCH_QUICK_SELECT_TO,
} from './action'

import { PopularStationsFrom } from '../../../../data/popular_stations_from_full'
import { StationEmpty } from '../../../types/index'

let initialState = {
  from_items: PopularStationsFrom,
  from_show: true,
  from_reference: StationEmpty,
  to_items: [],
  to_show: false,
  to_reference: null
}

const QuickSelectStationsReducer = handleActions({
  [SHOW_QUICK_SELECT_FROM]: (state, action) => {
    const from_show = true;
    const from_reference = Object.assign({}, action.payload);

    return { ...state, from_show, from_reference }
  },
  [SHOW_QUICK_SELECT_TO]: (state, action) => {

    const to_show = true;
    const to_reference = Object.assign({}, action.payload);

    return {...state, to_show, to_reference }
  },
  [HIDE_QUICK_SELECT_FROM]: (state, action) => {
    return {...state, from_show : false }
  },
  [HIDE_QUICK_SELECT_TO]: (state, action) => {
    return {...state, to_show : false }
  },
  [FETCH_QUICK_SELECT_FROM]: (state, action) => {
    return { ...state, from_items: action.payload }
  },
  [FETCH_QUICK_SELECT_TO]: (state, action) => {
    return { ...state, to_items: action.payload }
  },
}, initialState)

export default QuickSelectStationsReducer