import React from 'react';

const BusSeatView = ({ seatNumber, state, onSeatClick }) => {

  let className = (state === 'busy' || state === 'selected') ? ' seat-' + state : '';
  className    += (state === 'unavailable') ? ' seat-busy' : '';

  let seatInfo = seatNumber;
  if (state === 'busy') {
    seatInfo = <div className="car-state-icon  car-state-icon-busy" />;
  } else if (state === 'selected') {
    seatInfo = <div className="car-state-icon  car-state-icon-selected" />;
  }

  return (
    <div className={"map-place-seat d-inline-block text-center rounded m-2 " + className} onClick={() => onSeatClick(seatNumber, state) }>
      <div className="map-place-seat-title font-weight-medium small">
        {seatInfo}
      </div>
    </div>
  );
};

module.exports = BusSeatView;