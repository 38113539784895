// @flow
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { fetch_bus_segments_action } from './action'

import Step2View from 'vokzal-platform/components/step2/view'

Step2View.propTypes = {
    trainError: PropTypes.string,
    busError: PropTypes.string,
    otherDates: PropTypes.object
};

const mapStateToProps = state => {
    return {
        trainError: (state.step2.error) ? state.step2.error.message : null,
        busError: (state.step2_bus.error) ? state.step2.step2_bus.message : null,
        otherDates: (state.step2.error) ? state.step2.error.other_dates : null
    }
}

const mapDispatchToProps = dispatch => {

  dispatch(fetch_bus_segments_action());

  return {}
}

const Step2Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(Step2View)

export default Step2Container