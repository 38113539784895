import { handleActions } from 'redux-actions'

import { HeadMenuItems } from '../../../../data/menu';

import { LOAD_MENU } from './action'

const HeadMenuReducer = handleActions({
    [LOAD_MENU]: (state, action) => {
        return {...state};
    },
}, { items: HeadMenuItems })

export default HeadMenuReducer