import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import i18n from 'vokzal-platform/i18n';

import { showCountiesModalAction } from '../countries_modal/action';

import CountryTextInputView from 'vokzal-platform/components/step4/country_text_input/view';

CountryTextInputView.propTypes = {
  show: PropTypes.bool.isRequired,
  ticketNumber: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  fieldsetClass: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onCountryClick: PropTypes.func.isRequired,
};


const mapStateToPropsDocumentCountry = (state, ownProps) => {

  const p = state.step3_passengers.passengers;

  const name = 'document_country_title'
  const value = p[ownProps.ticketNumber - 1][name];

  const show = value !== '';

  return {
    name, value, show,
    label: i18n.t('document_country'),
    validationRule : 'alpha'
  }
};

const mapDispatchToPropsDocumentCountry = dispatch => {
  return {
    onCountryClick : (value) => {
      dispatch(showCountiesModalAction({value}));
    }
  }
};

const CountryTextInputContainer = connect(
  mapStateToPropsDocumentCountry,
  mapDispatchToPropsDocumentCountry
)(CountryTextInputView);

export default CountryTextInputContainer
