import { handleActions } from 'redux-actions'

import { raspisanieInitialStatePlatform } from 'vokzal-platform/components/pedia/raspisanie/reducer';

import { RASPISANIE_FETCH_COMPLETE_ACTION, SHOW_ORDER_FORM } from "./action";

let initialState = { loaded: false }

initialState = Object.assign({}, initialState, raspisanieInitialStatePlatform())

const PediaRaspisanieReducer = handleActions({
    [RASPISANIE_FETCH_COMPLETE_ACTION]: (state, action) => {
      return { ...state, ...action.payload }
    },
    [SHOW_ORDER_FORM]: (state, action) => {

      const schedule = state.schedule.map(t => {
        t.showOrderForm = (t.number === action.payload);
        return t;
      });

      return { ...state, schedule }
    },
}, initialState)

export default PediaRaspisanieReducer