// @flow
import PropTypes from 'prop-types';
import { SeatNumbersStrijPropType } from '../../../../../types';

import StrijCabinView from 'vokzal-platform/components/step3/strij/base/strij_cabin/view'

StrijCabinView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  cabinInfo : SeatNumbersStrijPropType
};

const StrijCabin = StrijCabinView

export default StrijCabin