// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import PediaRaspisanieBusView from 'vokzal-platform/components/pedia/raspisanie_bus/view'
import {fetch_pedia_schedule_bus} from "../../../api/fetch_pedia_schedule_bus";
import { post_process_schedule } from '../../../../src/api/fetch_pedia_station';
import {raspisanieFetchCompleteAction, showOrderFormAction} from "./action";

import {searchFormUrlParamsStep2Bus} from "../../../utils/url_params";

import { SearchResultBusPropType } from '../../../types';

import { showStep2Plain } from 'vokzal-platform/managers/navigation'


PediaRaspisanieBusView.propTypes = {
  loaded: PropTypes.bool.isRequired,
  urlWithParams: PropTypes.string,
  items: PropTypes.arrayOf(SearchResultBusPropType),
  onDateSelected: PropTypes.func.isRequired,
};

class PediaRaspisanieContainer extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      fetch_pedia_schedule_bus(this.props.urlWithParams, this.props.onDataLoaded);
    }
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    return <PediaRaspisanieBusView {...this.props} />;
  }
}


const mapStateToProps = state => {

     const searchUrlWithParams = searchFormUrlParamsStep2Bus(state.search);

     return {
       loaded: state.pedia_raspisanie_bus.loaded,
       urlWithParams: state.pedia_raspisanie_bus.urlWithParams,
       searchUrlWithParams,
       items: state.pedia_raspisanie_bus.schedule,
       cityFrom: state.pedia_raspisanie_bus.cityFrom,
       cityTo: state.pedia_raspisanie_bus.cityTo,
       averageDistance: state.pedia_raspisanie_bus.averageDistance,
       averageWaytime: state.pedia_raspisanie_bus.averageWaytime,
       trainsCount: state.pedia_raspisanie_bus.trainsCount,
       citiesPassthru: state.pedia_raspisanie_bus.citiesPassthru,
       tariffs: state.pedia_raspisanie_bus.tariffs,
       show: state.step3_bus.show_modal
    }
}

export const onRaspisanieLoaded = (dispatch, time_spent, response) => {

  let result = {};

  const t = post_process_schedule(response.runs);
  result.schedule = t.map(o => {
    o.prices = (o.prices) ? o.prices : {};

    return o;
  })
  result.title = response.title;
  result.subTitle = response.subTitle;
  result.image = { caption: 'default' };
  result.loaded = true;

  dispatch(raspisanieFetchCompleteAction({ time_spent, ...result }));
}

const mapDispatchToProps = dispatch => {

    return {
      onDataLoaded : (time_spent, response) => {
          onRaspisanieLoaded(dispatch, time_spent, response);
      },

      onDateSelected: (searchUrlWithParams) => {
        if (searchUrlWithParams) {
          showStep2Plain(dispatch, searchUrlWithParams);
        }
      }
    }
}


PediaRaspisanieContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PediaRaspisanieContainer)

export default PediaRaspisanieContainer