// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux'

import Step3View from 'vokzal-platform/components/step3/view'

class Step3Container extends Component {
  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    return <Step3View {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

Step3Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(Step3Container)

export default Step3Container