import React from 'react';
import PropTypes from 'prop-types';

import CircleButton from '../../../../src/components/step3/circle_button';

const PassengersCountRow = ({ type, title, price, counter, canIncrement, canDecrement, onCounterIncrement, onCounterDecrement }) => (
  <div className="passengers-count-row col-sm-4 col-lg-2 pr-2 pl-2">
    <div className="passengers-count-title font-weight-medium small text-center">{title}</div>

    <div className="row passengers-count-controls justify-content-center no-gutters">
      <div className="col-12 col-sm-4 order-sm-last increment">
        <CircleButton className={(!canIncrement) ? 'inactive' : ''} onClick={() => onCounterIncrement(canIncrement, type)}>+</CircleButton>
      </div>
      <div className="col-12 col-sm-4 passengers-count-counter d-flex justify-content-center align-items-center">{counter}</div>
      <div className="col-12 col-sm-4 order-sm-first decrement">
        <CircleButton className={(!canDecrement) ? 'inactive' : ''} onClick={() => onCounterDecrement(canDecrement, type)}>&ndash;</CircleButton>
      </div>
    </div>

    {(false) ? <div className="price text-center">{price}</div> : ''}
  </div>
);

PassengersCountRow.propTypes = {
  type : PropTypes.oneOf(['adult', 'child', 'infant']).isRequired,
  title : PropTypes.string.isRequired,
  price : PropTypes.number.isRequired,
  counter : PropTypes.number.isRequired,
  canIncrement : PropTypes.bool.isRequired,
  canDecrement : PropTypes.bool.isRequired,
  onCounterIncrement : PropTypes.func.isRequired,
  onCounterDecrement : PropTypes.func.isRequired,
};

module.exports = PassengersCountRow;