import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import queryString from 'query-string';
import { showStep4 } from 'vokzal-platform/managers/navigation'

import { searchFormUrlToPassengersData } from '../../../utils/url_params';

import OrderButtonView from 'vokzal-platform/components/step3/order_button/view'
import {setStep4Action} from "../../home/search_form/action";

OrderButtonView.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  urlParams: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const queryObject = queryString.parse(location.search);
  const type = queryObject.trainNumber ? 'train' : 'bus';
  const data = {};
  if (queryObject.busID) {
    data.busID = queryObject.busID;
    data.fromBusStop = queryObject.fromBusStop;
    data.toBusStop = queryObject.toBusStop;
    data.busNumber = queryObject.busNumber;
  }

  const urlParams = searchFormUrlToPassengersData(state, type, data);
  return { isEnabled:true, urlParams };
};

const mapDispatchToProps = dispatch => {
  return {
    onButtonClick: (isEnabled, urlParams) => {
      if (isEnabled) {
        showStep4(dispatch, urlParams);
        dispatch(setStep4Action({ step4: urlParams }));
      }
    }
  }

};

const OrderButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderButtonView);

export default OrderButtonContainer