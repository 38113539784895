import { handleActions } from 'redux-actions'
import { aboutInitialStatePlatform } from 'vokzal-platform/components/home/about/reducer';

import { ABOUT_FETCH } from './action'

let aboutNewsInitialState = { items: [], show : false };

aboutNewsInitialState = Object.assign({}, aboutNewsInitialState, aboutInitialStatePlatform())

const AboutReducer = handleActions({
    [ABOUT_FETCH]: (state, action) => {
        return {...state, items : action.payload};
    },
}, aboutNewsInitialState)

export default AboutReducer