// @flow
import { handleActions } from 'redux-actions'
import { HIDE_DETAILS_ACTION, SHOW_DETAILS_ACTION, STEP2_SELECT_CAR_TYPE_ACTION, SET_TRAIN_NUMBER_ACTION, STEP2_BUS_PRICE_ACTION, ACTIVE_CATEGORY_SELECT_ACTION } from './action'

import { step2TrainDetailsInitialStatePlatform } from 'vokzal-platform/components/step2/train_details/reducer';

let initialState = {
  show : false, train : {}, price : { adult : 0, child : 0, infant : 0 }
}

initialState = Object.assign({}, initialState, step2TrainDetailsInitialStatePlatform())

const TrainDetailsReducer = handleActions({
  [HIDE_DETAILS_ACTION]: (state, action) => {
    return { ...state, show: action.meta.show };
  },
  [SHOW_DETAILS_ACTION]: (state, action) => {
    return { ...state, train: action.payload, show: action.meta.show };
  },
  [SET_TRAIN_NUMBER_ACTION]: (state, action) => {
    return { ...state, train: action.payload};
  },
  [STEP2_SELECT_CAR_TYPE_ACTION]: (state, action) => {
    const car_category = action.payload.carCategory;
    const car_service = action.payload.service;
    const prices = action.payload.prices ? action.payload.prices : state.train.prices;
    const key = (prices.hasOwnProperty(car_service)) ? car_service : car_category;

    const price = prices.hasOwnProperty(key) ? { adult: prices[key].price, child: Math.round(prices[key].price / 2) } : prices;
    price.infant = 0;

    return { ...state, car_category, car_service, price };
  },
  [STEP2_BUS_PRICE_ACTION]: (state, action) => {
    const price = typeof action.payload === 'object' ? action.payload : { adult: action.payload, child: Math.round(action.payload / 2) };
    return { ...state, price };
  },
  [ACTIVE_CATEGORY_SELECT_ACTION]: (state, action) => {
    return { ...state, car_category: action.payload.type }
  },
}, initialState)

export default TrainDetailsReducer
