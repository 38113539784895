// @flow
import { createAction } from 'redux-actions'

export const STEP2_ALTERNATIVE_CHANGE_DATE_ACTION      = 'STEP2_ALTERNATIVE/DATE/CHANGE'
export const step2AlternativeChangeDateAction          = createAction(STEP2_ALTERNATIVE_CHANGE_DATE_ACTION)

export const STEP2_ALTERNATIVE_SET_BUS_FORM_ACTION     = 'STEP2_ALTERNATIVE/BUS_FORM/SET'
export const step2AlternativeSetBusFormAction          = createAction(STEP2_ALTERNATIVE_SET_BUS_FORM_ACTION)

export const STEP2_ALTERNATIVE_SET_TRAIN_FORM_ACTION   = 'STEP2_ALTERNATIVE/TRAIN_FORM/SET'
export const step2AlternativeSetTrainFormAction        = createAction(STEP2_ALTERNATIVE_SET_TRAIN_FORM_ACTION)