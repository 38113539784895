import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { select_bus_seat, deselect_bus_seat} from '../action';

import BusSeatView from 'vokzal-platform/components/step3bus/bus_seat/view'

BusSeatView.propTypes = {
  seatNumber: PropTypes.number.isRequired,
  state: PropTypes.oneOf(['available', 'unavailable', 'busy', 'selected']).isRequired,
  onSeatClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {

  const isAvailable = state.step3_bus_seats.seats.indexOf(ownProps.seatNumber) !== -1;
  let seatState = (isAvailable) ? 'available' : 'busy';

  if (seatState === 'available' && !state.step3_passengers.can_select_seat) {
    seatState = 'unavailable';
  }

  const isSelected = state.step3_passengers.selected_seats.filter(
    ((item, index, array) => ( item.seatNumber === ownProps.seatNumber ) )
  ).length === 1;
  seatState = (isSelected) ? 'selected' : seatState;

  return { state : seatState }
};

const mapDispatchToProps = dispatch => {
  return {
    onSeatClick : (seatNumber, seatState) => {
      if (seatState === 'selected') {
        dispatch(deselect_bus_seat(seatNumber));
      } else if (seatState === 'available')  {
        dispatch(select_bus_seat({seatNumber}));
      }
    }
  }
};

const BusSeatContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BusSeatView);

export default BusSeatContainer;

