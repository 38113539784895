// @flow
import PropTypes from 'prop-types';
import { SeatNumbersStrijLuxPropType } from '../../../../../src/types/';

import StrijLuxMapView from 'vokzal-platform/components/step3/strij/strij_lux_map/view'

StrijLuxMapView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersStrijLuxPropType).isRequired
};

const StrijLuxMap = StrijLuxMapView

export default StrijLuxMap