// @flow
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import moment from 'moment'

import DepartureArrivalView from 'vokzal-platform/components/step2/departure_arrival/view'

DepartureArrivalView.propTypes = {
  type : PropTypes.oneOf(['train', 'bus']).isRequired,
  departureTime : PropTypes.string.isRequired,
  arrivalTime : PropTypes.string,
  wayTime : PropTypes.string.isRequired,
  departureName : PropTypes.string.isRequired,
  arrivalName : PropTypes.string.isRequired
};


const mapStateToPropsTrain = (state, ownProps) => {

  const t = ownProps.trainItem

  const type = 'train'
  const departureTime = moment(t.departure.timeFull).utcOffset('+03:00').format('HH:mm')
  const arrivalTime = moment(t.arrival.timeFull).utcOffset('+03:00').format('HH:mm')
  const wayTime = t.wayTime.output.time
  const departureName = t.departure.objStation.name
  const arrivalName = t.arrival.objStation.name

  return { type, departureTime, arrivalTime, wayTime, departureName, arrivalName }
}

const mapStateToPropsBus = (state, ownProps) => {

  const b = ownProps.busItem;

  const type = 'bus'
  const departureTime = b.departure.time
  const arrivalTime = b.arrival.time
  const wayTime = b.time_in_way
  const departureName = (b.departure.description) ? b.departure.description : b.departure.name
  const arrivalName = (b.arrival.description) ? b.arrival.description : b.arrival.name

  return { type, departureTime, arrivalTime, wayTime, departureName, arrivalName }
}

export const DepartureArrivalTrainContainer = connect(
  mapStateToPropsTrain
)(DepartureArrivalView)

export const DepartureArrivalBusContainer = connect(
  mapStateToPropsBus
)(DepartureArrivalView)