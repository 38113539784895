import { createAction } from 'redux-actions'

export const LOAD_PEDIA_BUS              = 'PEDIA/BUS/LOAD'
export const load_pedia_bus_action       = createAction(LOAD_PEDIA_BUS);

export const BUS_FETCH_COMPLETE_ACTION   = 'PEDIA/BUS/FETCH/COMPLETE'
export const busFetchCompleteAction      = createAction(BUS_FETCH_COMPLETE_ACTION)

export const BUS_URL_STORE_ACTION       = 'PEDIA/BUS/URL/STORE'
export const busUrlStoreAction          = createAction(BUS_URL_STORE_ACTION)
