import React from 'react';

const CabinTypeView = ({ onSelectMF }) => (
    <div className="mf-select">
        <div className="arrow">
            <h2>Выберите тип купе:</h2>
            <ul>
                <li onClick={() => onSelectMF('Ж')}>
                    <label>Женское</label>
                </li>
                <li onClick={() => onSelectMF('М')}>
                    <label>Мужское</label>
                </li>
                <li onClick={() => onSelectMF('С')}>
                    <label>Смешанное</label>
                </li>
            </ul>
        </div>
    </div>

);

module.exports = CabinTypeView;