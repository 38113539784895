// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import PediaBusCalendarView from 'vokzal-platform/components/pedia/bus_calendar/view'

PediaBusCalendarView.propTypes = {
};

const mapStateToProps = state => {
     return { }
}

const PediaBusCalendarContainer = connect(
  mapStateToProps,
)(PediaBusCalendarView)

export default PediaBusCalendarContainer