// @flow

import { handleActions, combineActions } from 'redux-actions'

import { TabBusEmpty, TabTrainEmpty } from '../../../../src/types';


import { step2TabsInitialStatePlatform } from 'vokzal-platform/components/step2/search_result_tabs/reducer';

import { STEP2_TAB_CLICK_ACTION, STEP2_ADD_TAB_ACTION } from './action'

let step2TabsInitialState = {
  tabs: [], active_tab: null, counter: 0
}
step2TabsInitialState = Object.assign({}, step2TabsInitialState, step2TabsInitialStatePlatform())

const Step2TabsReducer = handleActions({
  [STEP2_TAB_CLICK_ACTION]: (state, action) => {

    const active_tab = action.payload;

    const tabs = state.tabs.map(t => {
      t.isActive = t.code === active_tab;
      return t;
    })

    return { ...state, active_tab, tabs }
  },
  [STEP2_ADD_TAB_ACTION]: (state, action) => {
    const code = action.payload.code;
    let tabs = state.tabs;
    let counter = state.counter + 1;

    const hasTab = (tabs.length > 0)
      ? tabs.map(t => ((t.code === code))).reduce((a, b) => a || b)
      : false;

    if (!hasTab) {
      let t = (code === 'buses') ? TabBusEmpty : TabTrainEmpty;
      t.price = action.payload.price;
      t.isActive = t.code === state.active_tab
      tabs.push(t);
    } else {
      tabs = tabs.map(t => {
        t.price = (t.code === code && (t.price === null || action.payload.price < t.price)) ? action.payload.price : t.price;
        return t;
      })
    }

    return { ...state, tabs, counter }
  },
}, step2TabsInitialState)

export default Step2TabsReducer

