import React, {lazy, Suspense} from 'react';

import i18n from 'vokzal-platform/i18n';

import { PediaPreLoader } from '../../../../src/components/shared/preloader';


import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';
import PediaHeaderImage from '../../../../src/components/pedia/header_image';
import PediaHeader from '../../../../src/components/pedia/header';

import PediaTabGroup from '../../../../src/components/pedia/tab_group';
import PediaTabContent from '../../../../src/components/pedia/tab_content';

import PediaRailroadDetailsPopularRoutes from '../../../../src/components/pedia/railroad_details_popular_routes';


const PediaRailroadMap = lazy(() => import('../../../../src/components/pedia/railroad_map'));
const FooterInfo = lazy(() => import('../../../../src/components/shared/footer_info/index'));
const FooterLinks = lazy(() => import('../../../../src/components/home/footer_links/index'));


const PediaRailroadDetailsView = ({ loaded, preview, description }) => (
    <div className="pedia">
        <div className="pedia-block">
          <div className="pedia-header-block">
            <PediaHeaderImage />

            <HeadMenuPedia />

            <PediaHeader type='railroad_details' />

            <PediaTabGroup type="railroad_details" />
          </div>

          <PediaTabContent type="info">
            <div className="p-3">
              <PediaRailroadDetailsPopularRoutes />

              <div className="page-section mt-4">
                <h3 className="section-title">{''}</h3>
                <div>
                  {(preview) ? <div className="pedia-preview" dangerouslySetInnerHTML={{__html: preview}} /> : null}
                  {(description) ? <div className="pedia-description" dangerouslySetInnerHTML={{__html: description}} /> : null}
                </div>
              </div>
            </div>
          </PediaTabContent>

          <PediaTabContent type="map">
              <Suspense fallback={<div>Загрузка...</div>}>
                  <PediaRailroadMap />
              </Suspense>
          </PediaTabContent>

          <PediaPreLoader show={!loaded} />
        </div>

        <Suspense fallback={<div>Загрузка...</div>}>
            <FooterInfo titleText={i18n.t('goodbye')} />
        </Suspense>

        <Suspense fallback={<div>Загрузка...</div>}>
            <FooterLinks />
        </Suspense>
    </div>
);

module.exports = PediaRailroadDetailsView;