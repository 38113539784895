import React from 'react';
import i18n from 'vokzal-platform/i18n';

import { getSexInfo } from "../../../../../src/utils/functions";
import StrizhKupeCabin from '../../../../../src/components/step3/strij/strizh_kupe_cabin';

const StrizhKupeMapView = ({ carNumber, cabins, carSelected, kupeNumber, kupeMF, selectedFloor, cabinsCount = 4, className = '', cabinType = 0 }) => {
    let sexInfo;

    return (
    <div className={'strizh ' + className}>
        <div className="train-car-select-seats-map-horizontal row">
            <div className="info">
                <p className="toilet">{i18n.t('toilet')}</p>

            </div>

            {
                cabins.map((cabin, index) => {
                    if (index > cabinsCount) {
                        return null;
                    }
                    sexInfo = '';

                    if(carSelected.female > 0) {
                        sexInfo = kupeNumber === index && kupeMF && selectedFloor === 1 ? kupeMF : getSexInfo(carSelected, cabin);
                    }

                    return (
                        <StrizhKupeCabin key={index} carNumber={carNumber} title={index} cabinInfo={cabin} sexInfo={sexInfo} cabinType={cabinType}/>
                    )})
            }

            <div className="info">
                <p className="conductor">
                    {i18n.t('conductor_place').replace(" ", "\n")}
                </p>
            </div>
        </div>
        <div className={'row strizh ' + className}>
            <div className="korridor">
                <div>{i18n.t('korridor')}</div>
            </div>
        </div>

        <div className="train-car-select-seats-map rounded strizh p-2 d-block d-lg-none d-lg-none">
            <div className="train-car-map-service-block d-flex align-items-end flex-column">
                <div className="train-car-select-seats-map-block train-car-map-text train-car-map-toilet rounded small text-center w-40 p-2 mb-2">{i18n.t('toilet')}</div>

            </div>

            <div className="d-flex justify-content-between">
                <div className="train-car-select-seats-map-block train-car-map-text train-car-map-korridor rounded small text-center w-25 mr-2 mb-2">
                    <div className="train-car-map-korridor-label">{i18n.t('korridor')}</div>
                </div>

                <div className="w-75">
                    {
                        cabins.map((cabin, index) => {
                            if (index > cabinsCount) {
                                return null;
                            }
                            sexInfo = '';
                            if(carSelected.female > 0) {
                                sexInfo = kupeNumber === index && kupeMF && selectedFloor === 1 ? kupeMF : getSexInfo(carSelected, cabin);
                            }
                            return (
                                <StrizhKupeCabin key={index} carNumber={carNumber} title={index} cabinInfo={cabin} sexInfo={sexInfo} cabinType={cabinType} />
                            )})
                    }
                </div>

            </div>

            <div className="train-car-map-service-block-bottom d-flex justify-content-between">
                <div className="train-car-select-seats-map-block train-car-map-text train-car-map-smoking-area rounded small text-center w-50 pt-3 pl-4 pr-4 pb-3 invisible">{i18n.t('smoking_area')}</div>
                <div className="train-car-select-seats-map-block train-car-map-text train-car-map-conductor-place rounded small text-capitalize text-center w-60 mb-2 p-1">
                    {i18n.t('conductor_place').replace(" ", "\n")}
                </div>
            </div>
        </div>
    </div>

)};

module.exports = StrizhKupeMapView;