import React from 'react';
import PediaOrderBlockBus from "../../../../src/components/pedia/order_block_bus";
import {getWayTimeOutputFormat} from "../../../../src/utils/functions";

const PediaScheduleItemBusView = ({ group_name, bus, showWaitTime, reverseDepartureArrival, onBusClick }) => (

    <div className="pedia-schedule mb-1 p-2 rounded">
      <div className="pedia-schedule-train-name h6 mt-1 mb-3" onClick={() => onBusClick(bus)}>
        {bus.number} {(bus.name) ? '«'+bus.name+'»' : null }
      </div>
      <div className="pedia-schedule-route font-weight-medium small">{bus.departure.name} → {bus.arrival.name}</div>
      <div className="pedia-schedule-descr small font-light">

      </div>

      <div className="pedia-schedule-timers d-flex justify-content-between text-uppercase mt-3">
        {
          (reverseDepartureArrival)
            ?
              <div className="pedia-schedule-timer">
                <div className="small">Отправление</div>
                <div className="font-weight-bold text-left">{(bus.departure.time) ? bus.departure.time : '—' }</div>
              </div>
            :
              <div className="pedia-schedule-timer">
                <div className="small">Прибытие</div>
                <div className="font-weight-bold text-left">{(bus.arrival.time) ? bus.arrival.time : '—' }</div>
              </div>
        }

        {
          (showWaitTime)
            ?
              <div className="pedia-schedule-timer">
                <div className="small">Стоянка</div>
                <div className="font-weight-bold text-center">{(bus.wait) ? bus.wait : '—' }</div>
              </div>
            : null
        }

      {
            bus.time_in_way
          ?
          <div className="pedia-schedule-timer">
                <div className="small">В пути</div>
                <div className="text-lowercase">{getWayTimeOutputFormat(bus.time_in_way)}</div>
          </div>
          : null
      }

        {
          (reverseDepartureArrival)
            ?
              <div className="pedia-schedule-timer text-right">
                <div className="small">Прибытие</div>
                <div className="font-weight-bold text-right">{(bus.arrival.time) ? bus.arrival.time : '—' }</div>
              </div>
            :
              <div className="pedia-schedule-timer">
                <div className="small">Отправление</div>
                <div className="font-weight-bold text-right">{(bus.departure.time) ? bus.departure.time : '—' }</div>
              </div>
        }
      </div>

      <PediaOrderBlockBus scheduleGroup={group_name} bus={bus} showOrderForm={bus.showOrderForm} />
    </div>
);

module.exports = PediaScheduleItemBusView;