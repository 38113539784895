// @flow
import PropTypes from 'prop-types';
import { SeatNumbersLastochkaPropType } from '../../../../../src/types/';

import LastochkaCenterCarView from 'vokzal-platform/components/step3/lastochka/lastochka_center_car_map/view'

LastochkaCenterCarView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersLastochkaPropType).isRequired
};

const LastochkaCenterCar = LastochkaCenterCarView

export default LastochkaCenterCar