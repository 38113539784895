import React from 'react';

import BootstrapLabeledInput from './bootstrap_labeled_input';

const BootstrapInlineLabeledInputWithButton = (props) => (
  <div className="d-flex w-100">
    <BootstrapLabeledInput fieldsetClass="col-10" className={(props.className || '') + ' active'} {...props} />
    <div className="col-2 input-group-append m-0 p-0" id={(props.id) ? props.id + '-button' : null} onClick={() => props.onButtonClick() }>
      <div className="input-group-text border-left-0">
        {/*<i className="material-icons">{props.buttonIcon}</i>*/}
      </div>
    </div>
  </div>
);

module.exports = BootstrapInlineLabeledInputWithButton;