import React from 'react';

import i18n from 'vokzal-platform/i18n';

import SapsanCabin from '../../../../../src/components/step3/sapsan/base/sapsan_cabin';
import SapsanLuggageCabin from '../../../../../src/components/step3/sapsan/base/sapsan_luggage_cabin';
import SapsanWardrobe from '../../../../../src/components/step3/sapsan/base/sapsan_wardrobe';
import SapsanMeetingRoom from '../../../../../src/components/step3/sapsan/base/sapsan_meeting_room';

const SapsanFirstClassMapView = ({ carNumber, cabins, viewType, wardrobeTitle }) => {

    let placeholnder = null;
    if (viewType === 'meeting-room') {
      placeholnder = <div className="sapsan-kupe-cabin-placeholder d-flex align-items-center w-100 h-100 font-medium-light">
                        <div className="sapsan-kupe-cabin-placeholder-title text-uppercase font-other-light-again h5 font-weight-normal m-auto">{i18n.t('sapsan_kupe_placeholder')}</div>
                     </div>
    }

    return (
      <div className="train-car-select-seats-map rounded pt-2">

        <div>
            <SapsanLuggageCabin carNumber={carNumber} />

            <div className=" d-flex justify-content-between align-items-center">
                <div className="">
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[0]} extraSpace={true} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[1]} extraSpace={true} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[2]} extraSpace={true} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[3]} extraSpace={true} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[4]} extraSpace={true} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[5]} extraSpace={true} />
                </div>

                <div className="mt-3">
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[6]} extraSpace={true} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[7]} extraSpace={true} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[8]} extraSpace={true} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[9]} extraSpace={true} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[10]} extraSpace={true} />
                    <SapsanWardrobe title={wardrobeTitle} autoHeight={false} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[11]} extraSpace={true} />

                </div>
            </div>

            {placeholnder}
        </div>

        <div className="sapsan-tambur train-car-map-text d-flex align-items-center rounded small m-2">
          <div className="sapsan-tambur-title m-auto">{i18n.t('sapsan_tambur')}</div>
        </div>

        <SapsanMeetingRoom viewType={viewType} carNumber={carNumber} cabinInfo1={cabins[12]} cabinInfo2={cabins[13]} />
      </div>
    )
}

module.exports = SapsanFirstClassMapView;
