import React from 'react';

import SeatedCabinPlace from '../../../../../../src/components/step3/seated_cabin_place';

const SapsanCabinView = ({ carNumber, cabinInfo, className='', inSubBlock = false, extraSpace = false }) => (


    <div className="kupe">


        <div className="train-car-map-seated-cabin cabin-max align-items-end flex-column">
            <div className="train-car-map-seated-cabin-place-block">

                { (cabinInfo.rightWindow !== null) ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.rightWindow.number} isFront={cabinInfo.rightWindow.isFront} className="horizontal-place" /> : null }
                { (cabinInfo.rightSide !== null)   ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.rightSide.number}   isFront={cabinInfo.rightSide.isFront}   className="horizontal-place" /> : null }
            </div>

            <div className="train-car-map-seated-cabin-place-block side">
                { (cabinInfo.leftSide !== null)   ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.leftSide.number}   isFront={cabinInfo.leftSide.isFront}   className="horizontal-place" /> : null }
                { (cabinInfo.leftWindow !== null) ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.leftWindow.number} isFront={cabinInfo.leftWindow.isFront} className="horizontal-place" /> : null }
            </div>
        </div>




        <div className={"train-car-map-seated-cabin cabin-min justify-content-between mt-2 mb-2 " + ((extraSpace) ? 'pt-2 pb-2' : '')}>
            <div className="train-car-map-seated-cabin-place-block d-flex">
                { (cabinInfo.leftWindow !== null) ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.leftWindow.number} isFront={cabinInfo.leftWindow.isFront} className={(inSubBlock) ? 'ml-1 mt-2 mr-2 mb-1' : 'm-2'} /> : null }
                { (cabinInfo.leftSide !== null)   ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.leftSide.number}   isFront={cabinInfo.leftSide.isFront}   className={(inSubBlock) ? 'ml-2 mt-2 mr-2 mb-1' : 'm-2'} /> : null }
            </div>

            <div className="train-car-map-seated-cabin-place-block d-flex">
                { (cabinInfo.rightSide !== null)   ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.rightSide.number}   isFront={cabinInfo.rightSide.isFront}   className={(inSubBlock) ? 'ml-2 mt-2 mr-2 mb-1' : 'm-2'} /> : null }
                { (cabinInfo.rightWindow !== null) ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo.rightWindow.number} isFront={cabinInfo.rightWindow.isFront} className={(inSubBlock) ? 'ml-2 mt-2 mr-1 mb-1' : 'm-2'} /> : null }
            </div>
        </div>
    </div>




)
module.exports = SapsanCabinView;