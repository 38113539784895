import React from 'react';
import { Link } from 'react-router-dom';

const BlogPaginationView = ({ prev, next, onButtonClick }) => (
  <div className="blog-pagination d-flex justify-content-between mt-2">
    <Link onClick={(e) => onButtonClick(prev, e)} to={prev ? prev : '#'} className={"font-medium-light btn btn-outline-primary" + ((prev) ? '' : ' disabled')}>
      Записи свежее
    </Link>

    <Link onClick={(e) => onButtonClick(next, e)} to={next ? next : '#'} className={"font-medium-light btn btn-outline-primary" + ((next) ? '' : ' disabled')}>
      Записи старше
    </Link>
  </div>
);

module.exports = BlogPaginationView;