import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { hideDetailsAction } from './action';
import TrainDetailsView from 'vokzal-platform/components/step2/train_details/view'

TrainDetailsView.propTypes = {
  show: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return { show : state.step2_details.show };
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseClick: train => {
      dispatch(hideDetailsAction());
    }
  }
};

const TrainDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainDetailsView);

export default TrainDetailsContainer