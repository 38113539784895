import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { CountryPropType } from '../../../../src/types/';

import CountriesModalView from 'vokzal-platform/components/step4/counties_modal/view'
import { searchCounties } from '../../../../src/api/search_country_api'

import { hideCountiesModalAction } from './action'

import { formDataChangedAction } from '../action'
import {enToRus} from "../../../utils/functions";

CountriesModalView.propTypes = {
  show: PropTypes.bool.isRequired,
  ticket_number: PropTypes.number,
  selected_country: CountryPropType,
  input_value: PropTypes.string,
  countries: PropTypes.arrayOf(
    CountryPropType.isRequired
  ).isRequired,
  onItemClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => {

  return {
    show: state.countries_modal.show,
    ticket_number: state.countries_modal.ticket_number,
    selected_country: state.countries_modal.selected_country,
    input_value: state.countries_modal.input_value,
    countries : state.countries_modal.countries,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCloseClick: () => {
      dispatch(hideCountiesModalAction())
    },
    onItemClick: (position, code, title) => {
      dispatch(hideCountiesModalAction())
      dispatch(formDataChangedAction({position, field : 'document_country',       value : code}))
      dispatch(formDataChangedAction({position, field : 'document_country_title', value : title}))
    },
    onInputChange: input_value => {
      input_value = enToRus(input_value);
      searchCounties(dispatch, input_value);
    },
  }
}

const CountriesModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CountriesModalView)

export default CountriesModalContainer
