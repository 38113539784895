import { handleActions } from 'redux-actions'

import {
  USER,
  USER_LOGIN,
  // SHOW_USER_TAB,
  USER_SET_VALUE,
  USER_SET_PROFILE_VALUE,
  USER_ORDERS_FETCH_COMPLETE_ACTION,
  USER_ORDER_FETCH_COMPLETE_ACTION,
  USER_PASSENGERS_FETCH_COMPLETE_ACTION,
  USER_PASSENGER_DELETE_ACTION,
  USER_ORDER_DELETE_ACTION,
  USER_ORDER_CHECK_PASSENGER_ACTION,
  USER_ORDER_CHECK_ER_ACTION
} from '../../components/user/action'

const UserReducer = handleActions({
  [USER]: (state, action) => {
    let log_rows = state.log_rows;
    log_rows.push(action.payload);
    return { ...state, log_rows, value: action.payload }
  },
  [USER_LOGIN]: (state, action) => {
    const id = action.payload.id;
    const name = action.payload.name;
    const email = action.payload.email;
    return { ...state, id, name, email }
  },
  [USER_SET_VALUE]: (state, action) => {
    return { ...state, ...action.payload  }
  },
  [USER_SET_PROFILE_VALUE]: (state, action) => {
    const profile = state.profile;
    const payload = action.payload;
    let new_profile = Object.assign({profile, payload});
    // console.log('USER_SET_PROFILE_VALUE ', profile, payload.firstname, new_profile);
    // return { ...state}

    return { ...state, profile: {
      ...state.profile, ...action.payload
  }}},
  [USER_ORDERS_FETCH_COMPLETE_ACTION]: (state, action) => {
    const orders = action.payload.response.orders;
    const orders_pages = action.payload.response.page_count;
    return {...state, orders, orders_loaded: true, orders_pages  }
  },
  [USER_ORDER_FETCH_COMPLETE_ACTION]: (state, action) => {
    const order = action.payload.response.order;

    return {...state, order }
  },
  [USER_PASSENGERS_FETCH_COMPLETE_ACTION]: (state, action) => {
    const passengers = action.payload.response.passengers;
    return {...state, passengers, passengers_loaded: true  }
  },
  [USER_PASSENGER_DELETE_ACTION]: (state, action) => {
    const passengers = state.passengers.filter(
        (item) => (item.id !== action.payload)
    );
    return {...state, passengers  }
  },
  [USER_ORDER_DELETE_ACTION]: (state, action) => {
    const orders = state.orders.filter(
        (item) => (item.id !== action.payload)
    );
    return {...state, orders  }
  },
  [USER_ORDER_CHECK_PASSENGER_ACTION]: (state, action) => {
    // passengers.forEach(element => action.payload));
    const order = state.order;
    let passengers = order.passengers.map(el => {
      if (el.id === action.payload.id) {
        el.checked = action.payload.checked;
      }
      // console.log('USER_ORDER_CHECK_PASSENGER_ACTION ');
      // console.log(el, ' *888** ', action.payload);
      return el;

    });
    let result = {...order, passengers};
    // console.log('result ', result);

    // console.log('USER_ORDER_CHECK_PASSENGER_ACTION ', state, ' *888** ', action);
    return {...state, ...order, passengers }
  },
  [USER_ORDER_CHECK_ER_ACTION]: (state, action) => {
    console.log('USER_ORDER_CHECK_ER_ACTION ', action.payload.response.result);
    // passengers.forEach(element => action.payload));
    const response = action.payload.response.result;
    const order = state.order;
    let arr = [];

    let passengers = order.passengers.map(el => {
      if (response.hasOwnProperty(el.external_ticket_id)) {
        el.er = response[el.external_ticket_id];
        arr.push(el.er);
      }
      // console.log('USER_ORDER_CHECK_PASSENGER_ACTION ');
      // console.log(el, ' *888** ', action.payload);
      return el;

    });
    let changeEr = '';
    if (arr.length === 0) {
        changeEr = 3;        // нечего менять
    }

    order[0].changeEr = 1;
      const counts = {};

      for (const num of arr) {
          counts[num] = counts[num] ? counts[num] + 1 : 1;
      }


    let result = {...order, passengers};
    console.log('result ', result);
    console.log('arrays ', counts[0], counts[1], arr);
    if (counts[0] !== undefined && counts[1] !== undefined) {
        // есть билеты с ЭР и без в текущем списке
        changeEr = 2;        // можно менять в обе стороны
    } else if (counts[0] !== undefined) {
        // все билеты без ЭР
        changeEr = 1;        // можно устанавливать

    } else if (counts[1] !== undefined) {
        // все билеты с ЭР
        changeEr = 0;        // можно отменять
    }
     order[0].changeEr = changeEr;



    // console.log('USER_ORDER_CHECK_PASSENGER_ACTION ', state, ' *888** ', action);
    return {...state, ...order, passengers }
    // return {...state }
  },
}, {
  value: '',
  log_rows: [],
  remember: 1,
  profile: {},
  orders: {},
  order: {},
  orders_loaded: false,
  orders_current_page: 0, // текущая страница при разбивке списка заказов
  orders_limit: 20,
  passengers: {},
  passengers_loaded: false,
  loaded: false})

export default UserReducer
