// @flow

import { createAction } from 'redux-actions'

export const SHOW_QUICK_SELECT_FROM = "HOME/SEARCH_FORM/QUICK_SELECT_FROM/SHOW"
export const showQuickSelectFromAction  = createAction(SHOW_QUICK_SELECT_FROM, (show) => show, () => ({ show : true }))

export const HIDE_QUICK_SELECT_FROM = "HOME/SEARCH_FORM/QUICK_SELECT_FROM/HIDE"
export const hideQuickSelectFromAction  = createAction(HIDE_QUICK_SELECT_FROM, (show) => show, () => ({ show : false }))

export const SHOW_QUICK_SELECT_TO = "HOME/SEARCH_FORM/QUICK_SELECT_TO/SHOW"
export const showQuickSelectToAction    = createAction(SHOW_QUICK_SELECT_TO, (show) => show, () => ({ show : true }))

export const HIDE_QUICK_SELECT_TO = "HOME/SEARCH_FORM/QUICK_SELECT_TO/HIDE"
export const hideQuickSelectToAction    = createAction(HIDE_QUICK_SELECT_TO, (show) => show, () => ({ show : false }))

export const FETCH_QUICK_SELECT_FROM = "HOME/SEARCH_FORM/QUICK_SELECT_FROM/FETCH"
export const fetchQuickSelectFromAction    = createAction(FETCH_QUICK_SELECT_FROM)

export const FETCH_QUICK_SELECT_TO = "HOME/SEARCH_FORM/QUICK_SELECT_TO/FETCH"
export const fetchQuickSelectToAction    = createAction(FETCH_QUICK_SELECT_TO)
