import { createAction } from 'redux-actions';

export const STEP4_SHOW_ACTION        = 'STEP4/FORM/CHANGED'
export const step4ShowAction          = createAction(STEP4_SHOW_ACTION);

export const FORM_DATA_CHANGED_ACTION = 'STEP4/FORM/CHANGED'
export const formDataChangedAction    = createAction(FORM_DATA_CHANGED_ACTION);

export const FORM_DATA_VALIDATED_ACTION = 'STEP4/FORM/VALIDATED'
export const formDataValidatedAction    = createAction(FORM_DATA_VALIDATED_ACTION);

export const FORM_INPUT_FOCUS_ACTION    = 'STEP4/FORM/FOCUS/ACTIVE'
export const formInputFocusAction       = createAction(FORM_INPUT_FOCUS_ACTION);

export const FORM_FOCUS_LOST_ACTION     = 'STEP4/FORM/FOCUS/LOST'
export const formFocusLostAction        = createAction(FORM_FOCUS_LOST_ACTION);

export const FORM_DATA_CLEANUP_ACTION = 'STEP4/FORM/CLEANUP'
export const formDataCleanupAction    = createAction(FORM_DATA_CLEANUP_ACTION);

export const FORM_BUTTON_STATE_ACTION = 'STEP4/FORM/BUTTON/STATE'
export const changeButtonStateAction  = createAction(FORM_BUTTON_STATE_ACTION);

export const FORM_STORE_ERROR_ACTION  = 'STEP4/FORM/ERROR/STORE'
export const storeErrorAction         = createAction(FORM_STORE_ERROR_ACTION);

export const FORM_RESET_ERROR_ACTION   = 'STEP4/FORM/ERROR/RESET'
export const resetErrorAction          = createAction(FORM_RESET_ERROR_ACTION);

export const FORM_INPUT_CLEAN_ACTION   = 'STEP4/FORM/INPUT/CLEAN'
export const formInputCleanAction          = createAction(FORM_INPUT_CLEAN_ACTION);