import React from 'react';
import { connect } from 'react-redux'

import MenuView from 'vokzal-platform/components/admin/menu/view'

const mapStateToProps = () => {

  return {
    reviews: 'Отзывы',
    stats: 'Статистика',
  }
}

const MenuContainer = connect(
    mapStateToProps
)(MenuView)

export default MenuContainer