// @flow
import { handleActions } from 'redux-actions';

import { STEP4_CONTACTS_CHANGED_ACTION, STEP4_CONTACTS_CHECKED_ACTION } from './action';

export const Step4ContactsReducer = handleActions({
  [STEP4_CONTACTS_CHANGED_ACTION]: (state, action) => {
    return { ...state, ...action.payload, counter : state.counter + 1 }
  },
  [STEP4_CONTACTS_CHECKED_ACTION]: (state, action) => {
    return { ...state, ...action.payload }
  },
}, { email : '', mobile_phone : '+', is_email_valid : true, is_mobile_phone_valid : true, counter : 0, attention: 0, lk_status: 1 })