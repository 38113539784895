import React from 'react';

import SearchResultTab from '../../../../src/components/step2/search_result_tab';

const SearchResultTabsView = ({ items }) => {

  if (items.length < 2) {
    return null;
  }

  return (
    <div className="search-result-tabs small no-gutters row">
      {
        items.map(function(tab, index){
          return (<SearchResultTab key={index} {...tab} />);
        })
      }
    </div>
  );
};

module.exports = SearchResultTabsView;
