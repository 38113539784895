// @flow

import { createAction } from 'redux-actions'

export const STEP2_TRAINS_SEARCH_COMPLETE_ACTION  = 'STEP2/SEARCH/TRAINS/COMPLETE'
export const step2TrainsSearchCompleteAction      = createAction(STEP2_TRAINS_SEARCH_COMPLETE_ACTION, (show) => show, () => ({ data_loaded : true }))

export const STEP2_TRAINS_SEARCH_CLEANUP_ACTION   = 'STEP2/SEARCH/TRAINS/CLEANUP'
export const step2TrainsSearchCleanupAction       = createAction(STEP2_TRAINS_SEARCH_CLEANUP_ACTION, (show) => show, () => ({ data_loaded : false }))

export const STEP2_TRAINS_SEARCH_ERROR_ACTION    = 'STEP2/SEARCH/TRAINS/ERROR'
export const step2TrainsSearchErrorAction        = createAction(STEP2_TRAINS_SEARCH_ERROR_ACTION)

export const STEP2_MAKE_POST_SEARCH_ACTION       = 'STEP2/SEARCH/POST_SEARCH'
export const step2MakePostSearchAction           = createAction(STEP2_MAKE_POST_SEARCH_ACTION)

export const STEP2_RESET_POST_SEARCH_ACTION       = 'STEP2/RESET/POST_SEARCH'
export const step2ResetPostSearchAction           = createAction(STEP2_RESET_POST_SEARCH_ACTION)

