// @flow

import { handleActions } from 'redux-actions';

import { BUS_SEATS_LOADED_ACTION, BUS_SEATS_RESET_ACTION } from './action';

let initialState = { seats : [], auto_select : false, loaded : false };

export const Step3BusSeatsReducer = handleActions({
  [BUS_SEATS_LOADED_ACTION]: (state, action) => {
    const seats = action.payload.seatNumbers;
    const auto_select = seats.length === 0;
    return {...state, seats, auto_select, loaded : true};
  },
  [BUS_SEATS_RESET_ACTION]: (state, action) => {
      return {...initialState};
    },
}, initialState)
