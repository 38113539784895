// @flow
import { SearchResultBusPropType } from '../../../../src/types/';

import BusInfoView from 'vokzal-platform/components/step2/bus_info/view'

BusInfoView.propTypes = {
  busItem : SearchResultBusPropType.isRequired
};

const BusInfo = BusInfoView

export default BusInfo