// @flow
import PropTypes from 'prop-types';
import { SeatNumbersLastochkaPropType } from '../../../../../src/types/';

import Lastochka2GCarView from 'vokzal-platform/components/step3/lastochka/lastochka_2g_car_map/view'

Lastochka2GCarView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersLastochkaPropType).isRequired
};

const Lastochka2GCar = Lastochka2GCarView

export default Lastochka2GCar