import React from 'react';

import i18n from 'vokzal-platform/i18n';

import SapsanCabin from '../../../../../../src/components/step3/sapsan/base/sapsan_cabin';
import SapsanWardrobeCabinWithMinibar from '../../../../../../src/components/step3/sapsan/base/sapsan_wardrobe_cabin_with_minibar';

const SapsanMeetingRoomView = ({ carNumber, cabinInfo1, cabinInfo2, viewType }) => {

  let placeholnder = null;
  if (viewType === 'kupe') {
    placeholnder = <div className="sapsan-meeting-room-cabin-placeholder d-flex align-items-center w-100 h-100 font-medium-light">
                     <div className="sapsan-meeting-room-cabin-placeholder-title text-uppercase font-other-light-again h5 font-weight-normal m-auto">{i18n.t('sapsan_meeting_room_placeholder')}</div>
                   </div>
  }

  return (
      <div className="sapsan-meeting-room-cabin rounded mt-3">
        <SapsanWardrobeCabinWithMinibar />

        <SapsanCabin carNumber={carNumber} cabinInfo={cabinInfo1} inSubBlock={false} />
        <SapsanCabin carNumber={carNumber} cabinInfo={cabinInfo2} inSubBlock={false} />

        {placeholnder}
      </div>
    );
}
module.exports = SapsanMeetingRoomView;