import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import i18n from 'vokzal-platform/i18n';

import SapsanDoubleLuggageCabinView from 'vokzal-platform/components/step3/sapsan/base/sapsan_double_luggage_cabin/view'

SapsanDoubleLuggageCabinView.propTypes = {
  wardrobeTitle: PropTypes.string.isRequired,
  autoHeightWardrobe: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({ wardrobeTitle : i18n.t('sapsan_luggage'), autoHeightWardrobe : true })


const SapsanDoubleLuggageCabinContainer = connect(
  mapStateToProps
)(SapsanDoubleLuggageCabinView)

export default SapsanDoubleLuggageCabinContainer