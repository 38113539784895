
export const blogSinglePostInitialStatePlatform = () => {
  let initialState = {}
  if (typeof blog_post !== 'undefined') {
    initialState.data = blog_post;

    initialState.post_url = '';
    initialState.data = blog_post;
    initialState.data_loaded = true;
  }

  return initialState;
}