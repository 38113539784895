// @flow

import { connect } from 'react-redux'

import HomeView from 'vokzal-platform/components/home/view'

import i18n from 'vokzal-platform/i18n'

const mapStateToProps = state => {
    return { 
      phone: i18n.t('phone'),
      search_title: i18n.t('search_title'),
      for_meet: i18n.t('for_meet'),
      ticket_refund: i18n.t('ticket_refund'),
      encyclopedia_of_passengers: i18n.t('encyclopedia_of_passengers')
    }
}

const HomeContainer = connect(
    mapStateToProps
)(HomeView)

export default HomeContainer