// @flow
import PropTypes from 'prop-types';
import { SeatNumbersSeatedPropType } from '../../../../../src/types/';

import SapsanEconomPlusMapView from 'vokzal-platform/components/step3/sapsan/sapsan_econom_plus_map/view'

SapsanEconomPlusMapView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersSeatedPropType).isRequired
};

const SapsanEconomPlusMap = SapsanEconomPlusMapView

export default SapsanEconomPlusMap