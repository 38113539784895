import React from 'react';
import i18n from 'vokzal-platform/i18n'

import BootstrapLabeledInput from '../../base/bootstrap_labeled_input';

import CheckButton from '../../../../src/components/ticket_return/check_button';

const TicketReturnFormView = ({ order_number, is_order_number_valid, mobile_phone, is_mobile_phone_valid, onOrderNumberChanged, onMobilePhoneChanged }) => (
    <div className="return-ticket-form-block page-section rounded m-2 mt-4 p-2">
      <div className="section-title mb-2">
        Форма возврата жд билетов
      </div>

      <div className="return-ticket-form">
        <BootstrapLabeledInput label={i18n.t('tickets_return_code')}
                               fieldsetClass={(is_order_number_valid) ? "" : "input-invalid"}
                               name="code" value={order_number} required={true}
                               onChange={(value, inputElement) => onOrderNumberChanged(value, inputElement)} />

        <BootstrapLabeledInput label={i18n.t('tickets_return_mobile_phone')}
                               fieldsetClass={(is_mobile_phone_valid) ? "" : "input-invalid"}
                               name="mobile_phone" value={mobile_phone} required={true}
                               onChange={(value, inputElement) => onMobilePhoneChanged(value, inputElement)} />
      </div>

      <CheckButton />
    </div>
);

module.exports = TicketReturnFormView;