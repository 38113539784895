import React from 'react';

import i18n from 'vokzal-platform/i18n';

const OrderButtonView = ({ isEnabled, urlParams, onButtonClick }) => (
  <div className="order-button text-center font-weight-medium text-uppercase">
    <div className={"order-button-label " + ((!isEnabled) ? 'disabled' : '')} onClick={(isEnabled) => onButtonClick(isEnabled, urlParams)}>
      {i18n.t('book_seats')}
    </div>
  </div>
);

module.exports = OrderButtonView;