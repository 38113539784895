// @flow

export const PERFORM_SEARCH = "HOME/SEARCH_FORM/PERFORM_SEARCH"

export function perform_search(params) {
    return {
        type: PERFORM_SEARCH,
        params
    }
}
