import { connect } from 'react-redux'
import BlogPostsListView from 'vokzal-platform/components/blog/posts_list/view'

import PropTypes from 'prop-types';
import { BlogPostListItemPropType } from '../../../../src/types/';

BlogPostsListView.propTypes = {
  posts : PropTypes.arrayOf(BlogPostListItemPropType)
};

const mapStateToProps = state => ({
  posts: state.blog_posts.posts
});

const BlogPostsListContainer = connect(
  mapStateToProps
)(BlogPostsListView);

export default BlogPostsListContainer