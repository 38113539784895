// @flow
import { connect } from 'react-redux'

import UfsLogoView from 'vokzal-platform/components/shared/ufs_logo/view'

const mapStateToProps = state => {
    return { loading : state.step2_preloader.show }
}

const UfsLogoContainer = connect(
    mapStateToProps
)(UfsLogoView)

export default UfsLogoContainer