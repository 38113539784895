import { handleActions } from 'redux-actions'

import { trainInitialStatePlatform } from 'vokzal-platform/components/pedia/train/reducer';

import { LOAD_PEDIA_TRAINS_LIST, TRAIN_URL_STORE_ACTION, TRAIN_FETCH_COMPLETE_ACTION, TRAIN_SHOW_ORDER_FORM, TRAIN_SCHEDULE_SHOW_ORDER_FORM, TRAIN_OTHERS_SHOW_ORDER_FORM } from './action';

const defaultState = {
  loaded : false, currentTab: 'info', urlWithParams: null, image: { caption: "default" },
}

const initialState = Object.assign({}, defaultState, trainInitialStatePlatform())

const PediaTrainReducer = handleActions({
    [LOAD_PEDIA_TRAINS_LIST]: (state, action) => {
      return {...state}
    },
    [TRAIN_FETCH_COMPLETE_ACTION]: (state, action) => {
      const loaded = true;

      const title = action.payload.response.train.title;
      const subTitle = action.payload.response.train.ab;

      const route = action.payload.response.route;
      const coords = action.payload.response.coords;
      const trainOthers = action.payload.response.trains;
      const schedule = action.payload.response.schedule;

      const train = action.payload.response.train;
        if ( !action.payload.response.train.hasOwnProperty('showOrderForm') ) {
            train.showOrderForm = false;
        }
      return {...state, loaded, title, subTitle, train, route, coords, trainOthers, schedule }
    },
    [TRAIN_SHOW_ORDER_FORM]: (state, action) => {
        const train = state.train;
        train.showOrderForm = train.showOrderForm === false ? true : true;
        return {...state, train}
    },
    [TRAIN_SCHEDULE_SHOW_ORDER_FORM]: (state, action) => {
        const schedule = state.schedule.map(t => {
            t.showOrderForm = (t.number === action.payload);
            return t;
        });

        return { ...state, schedule }
    },
    [TRAIN_OTHERS_SHOW_ORDER_FORM]: (state, action) => {
        const trainOthers = state.trainOthers.map(t => {
            t.showOrderForm = (t.number === action.payload);
            return t;
        });

        return { ...state, trainOthers }
    },
    [TRAIN_URL_STORE_ACTION]: (state, action) => {
      let new_state = {}
      if (state.urlWithParams !== action.payload) {
        new_state = defaultState;
      }
      new_state.urlWithParams = action.payload;

      return {...state, ...new_state}
    },
}, initialState)

export default PediaTrainReducer