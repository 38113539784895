import React from 'react';

import i18n from 'vokzal-platform/i18n';

import { PediaPreLoader } from '../../../../src/components/shared/preloader';
import PediaOrderFormShortBus from "../../../../src/components/pedia/order_form_short_bus";

import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';
import PediaHeaderImage from '../../../../src/components/pedia/header_image';
import PediaHeader from '../../../../src/components/pedia/header';

import BusRow from '../../../../src/components/step2/bus_row';

import DatesModal from '../../../../src/components/home/dates_modal'

import FooterInfo from '../../../../src/components/shared/footer_info/index';
import FooterLinks from '../../../../src/components/home/footer_links/index';
import PediaBusModal from '../../../../src/components/pedia/bus_modal';

const PediaRaspisanieBusView = ({ loaded, preview, description, items, searchUrlWithParams, onDateSelected, cityFrom, cityTo, citiesPassthru, show }) => {

    const cities = [];
        if (citiesPassthru !== null) {
        const len = citiesPassthru.length - 1;
        citiesPassthru.forEach((item, i) => {
            switch (i) {
                case 0:
                    cities.push(`<a href="/stanciya-bus/${items[0]['departure']['url_name']}/${items[0]['departure']['id']}">${item['city_name']}</a>`)
                    break;
                case len:
                    cities.push(`<a href="/stanciya-bus/${items[0]['arrival']['url_name']}/${items[0]['arrival']['id']}">${item['city_name']}</a>`)
                    break;
                default:
                    cities.push(`${item['city_name']}`);
                    break;
            }
        });
    }

    const citiesStr = `По пути следования автобусы проходят через города и населённые пункты: ${cities.join(', ')}.`;
    return (
    <div className="pedia">
        {show ? <PediaBusModal className="bus-list"/> : null}
        <div className="pedia-block step2">
          <div className="pedia-header-block">
            <PediaHeaderImage />

            <HeadMenuPedia />
              <div className="p-3"></div>
            {/*<PediaHeader type="raspisanie_bus" />*/}
          </div>

          <div className="pedia-raspisanie content-tree mt-1 pt-2 pb-2">
            <div className="content-tree-row">
              <div className="content-tree-content mr-2 ml-2 mb-4 pt-2 d-flex flex-column">
                {items.length > 0 ? items.map((bus, index) => {
                    bus.arrival.busStop = {};
                    bus.departure.busStop = {};
                    bus.arrival.busStop.id = bus.arrival.parent_id ? bus.arrival.parent_id : bus.arrival.id;
                    bus.departure.busStop.id = bus.departure.parent_id ? bus.departure.parent_id : bus.departure.id;
                    bus.ab = bus.departure.name + ' - ' + bus.arrival.name;
                    return(
                <div key={index} className="train-info-wrapper rounded mb-1">
                    <div key={index} className="row">
                        <div className="col-md-9">
                            <BusRow busItem={bus} onBusClick={() => { onBusClick(bus) }} abt={true} />
                        </div>
                        <div className="col-md-3 order-button">
                            <PediaOrderFormShortBus bus={bus} />
                        </div>
                    </div>
                </div>
              )}) : null}

                  <div className="train-info-wrapper rounded mb-1 p-3">
                      <h3>Расписание движения автобусов по маршруту {cityFrom} – {cityTo}</h3>

                      {  cities.length > 0 ? <div dangerouslySetInnerHTML={{__html: citiesStr}} /> : null}

                      <p>Забронируйте прямо сейчас билет на автобус {cityFrom} – {cityTo} на сайте vokzal.ru за 2 минуты.</p>
                  </div>
              </div>
            </div>
          </div>

          <PediaPreLoader show={!loaded} />
          <DatesModal onDateSelected={(date) => onDateSelected(searchUrlWithParams, date)} />
        </div>

        <FooterInfo titleText={i18n.t('goodbye')} />
        <FooterLinks />
    </div>
)};

module.exports = PediaRaspisanieBusView;