import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { searchFormUrlParamsCarCategory } from '../../../utils/url_params';

import { CarTypes, SearchResultTrainPricePropType } from '../../../../src/types/';

import TrainCarClassSelectView from 'vokzal-platform/components/step2/train_car_class_select/view';

TrainCarClassSelectView.propTypes = {
  carCategory : PropTypes.string.isRequired,
  service : PropTypes.string,
  title : PropTypes.string.isRequired,
  urlParams:  PropTypes.string,
  priceItems: PropTypes.objectOf(SearchResultTrainPricePropType).isRequired
};


const mapStateToProps = (state, ownProps) => {

  const urlParams = searchFormUrlParamsCarCategory(state, ownProps.carCategory, ownProps.service);
  const title = (ownProps.service !== null && CarTypes.hasOwnProperty(ownProps.service)) ? CarTypes[ownProps.service] : CarTypes[ownProps.carCategory];

  return { title, urlParams, priceItems: state.step2_details.train.prices };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

const TrainCarClassSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainCarClassSelectView);

export default TrainCarClassSelectContainer