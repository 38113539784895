// @flow
import PropTypes from 'prop-types';
import { SeatNumbersLastochkaPropType } from '../../../../../src/types/';

import Lastprem_w1_v1View from 'vokzal-platform/components/step3/lastochka/lastprem_w1_v1/view'

Lastprem_w1_v1View.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersLastochkaPropType).isRequired
};

const Lastprem_w1_v1 = Lastprem_w1_v1View

export default Lastprem_w1_v1