import React from 'react';
import BootstrapLabeledSelect from '../../base/bootstrap_labeled_select';

const TextSelectView = ({ ticketNumber, name, value, values, label, fieldsetClass, onSelectChange }) => {

  return (
    <BootstrapLabeledSelect className="font-weight-bold" fieldsetClass={"font-medium-light " + (fieldsetClass || '')} label={label} name={name}
                            value={value} values={values} required={true} onChange={(newValue) => { onSelectChange(ticketNumber, newValue) }} />
  );
};

module.exports = TextSelectView;