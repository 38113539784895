// @flow
import { connect } from 'react-redux'
import i18n from 'vokzal-platform/i18n';
import queryString from 'query-string';
import { step2ShowInfoAction } from '../../step2/action'

import { StationPropType } from '../../../types';
import momentPropTypes from 'react-moment-proptypes';

import StepInfoView from 'vokzal-platform/components/shared/step_info/view'

StepInfoView.propTypes = {
  from: StationPropType.isRequired,
  to: StationPropType.isRequired,
  date: momentPropTypes.momentObj,
};

const mapStateToProps = state => {
    // console.log('state *** --- *** ', state);
    // const i = state.step_info;
    const queryObject = queryString.parse(location.search);
    const i = state.search;
    const s = state.step3_passengers.selected_seats;
    const l = s.length;
    const isBus = queryObject.busID || (state.step2_details_bus.bus && !queryObject.trainNumber) || (state.confirmation.order_form && state.confirmation.order_form.is_bus_tickets);
    const carNumber = l && !isBus ? `${i18n.t('train_car')} ${s[0].carNumber}` : '';
    let seats = '';
    if( l === 1 ){
        seats = `${i18n.t('seat')} ${s[0]['seatNumber']}${isBus ? '' : ', ' + (s[0]['isUpper'] ? 'верхнее' : 'нижнее')}`;
    } else if ( l > 1 ) {
        const result = s.reduce(function(str, current) {
            return str + ' ' + current.seatNumber.toString() + ',';
        }, '');
        seats = `${i18n.t('seats')} ${result.slice(0,-1)}`;
    }

    const from = state.search.from;
    const to = state.search.to;

    let transport = '';
    if (isBus) {
        transport = { type: 'bus'};
    }else if (state.step2_details.train.numberShort) {
        transport = { type: 'train', name: state.step2_details.train.name, number: state.step2_details.train.numberShort};
    }

    return {
        from,
        to,
        date : state.search.date_from,
        transport,
        step2_url: i.step2,
        step3_url: i.step3,
        step4_url: i.step4,
        carNumber,
        seats
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onStepInfoClick : () => {
            dispatch(step2ShowInfoAction())
        }
    }
}

const StepInfoContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StepInfoView)

export default StepInfoContainer