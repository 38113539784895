import React from 'react';
import { YMaps, Map, Placemark, ObjectManager } from 'react-yandex-maps';

const PediaRegionMapView = ({ show, mapState, stations, features, boundsSet, objectManagerInstanceRef }) => {

  if (!show) {
    return null;
  }

  return (
    <div className="pedia-map">
      <div id="map" />

      <YMaps>
        <Map state={mapState} height={200} width="100%">
          {/*{*/}
            {/*stations.map((s, index) => (*/}
              {/*<Placemark key={index}*/}
                {/*geometry={{ coordinates: [ s.coords.lat, s.coords.lon ] }}*/}
                {/*properties={{ hintContent: s.name, balloonContentBody: s.name }}*/}
              {/*/>*/}
            {/*))*/}
          {/*}*/}

          <ObjectManager
            options={{ clusterize: false, gridSize: 32, }}
            objects={{ preset: 'islands#greenDotIcon', }}
            clusters={{ preset: 'islands#greenClusterIcons', }}
            features={features}
            instanceRef={(o) => {objectManagerInstanceRef(boundsSet, o)}}
          />

        </Map>
      </YMaps>
    </div>
  )
}

module.exports = PediaRegionMapView;