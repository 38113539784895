export const railroadInitialStatePlatform = () => {
  let initialState = {}

  if (typeof pedia_railroad_list !== 'undefined') {
    initialState.loaded = true;

    initialState.groups = pedia_railroad_list;
  }

  return initialState;
}