// @flow

import { createAction } from 'redux-actions'

export const HIDE_DETAILS_ACTION           = 'STEP2/DETAILS/HIDE'
export const hideDetailsAction             = createAction(HIDE_DETAILS_ACTION, (show) => show, () => ({ show : false }))

export const SHOW_DETAILS_ACTION           = 'STEP2/DETAILS/SHOW'
export const showDetailsAction             = createAction(SHOW_DETAILS_ACTION, (show) => show, () => ({ show : true }))

export const STEP2_SELECT_CAR_TYPE_ACTION  = 'STEP2/DETAILS/CAR/SELECT'
export const step2SelectCarTypeAction      = createAction(STEP2_SELECT_CAR_TYPE_ACTION)

export const SET_TRAIN_NUMBER_ACTION       = 'STEP2/TRAIN_NUMBER/SET'
export const setTrainNumberAction          = createAction(SET_TRAIN_NUMBER_ACTION)

export const STEP2_BUS_PRICE_ACTION       = 'STEP2/BUS/PRICE'
export const step2SetBusPriceAction       = createAction(STEP2_BUS_PRICE_ACTION)

export const ACTIVE_CATEGORY_SELECT_ACTION = 'STEP2/ACTIVE_CATEGORY/SELECT'
export const setActiveCategory            = createAction(ACTIVE_CATEGORY_SELECT_ACTION);
