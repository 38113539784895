import React, { Component } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { postsStoreAction } from '../posts/action';

import { blog_fetch_posts_list } from '../../../api/blog_fetch_posts_list'


import BlogPostsContentView from 'vokzal-platform/components/blog/posts_content/view'

BlogPostsContentView.propTypes = {
  loaded : PropTypes.bool.isRequired,
  url : PropTypes.string,
  title : PropTypes.string,
};


class BlogPostsContentContainer extends Component {

  componentDidMount() {
    if (!this.props.loaded && this.props.url) {
      blog_fetch_posts_list(this.props.url, this.props.onDataLoaded, this.props.onError);
    }
  }

  componentDidUpdate() {

    if (!this.props.loaded && this.props.url) {
      blog_fetch_posts_list(this.props.url, this.props.onDataLoaded, this.props.onError);
    }
  }

  render() {
    return <BlogPostsContentView {...this.props} />;
  }
}

const mapStateToProps = state => {

  return {
    loaded: state.blog_posts.data_loaded,
    url: state.blog_posts.url,
    title: state.blog_posts.title,
  }
};

const mapDispatchToProps = dispatch => (
  {
    onError: (post) => {
      // debugger
    },
    onDataLoaded: (time_spent, data) => {
      dispatch(postsStoreAction({...data}));
    },

  }
);

BlogPostsContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogPostsContentContainer);

export default BlogPostsContentContainer