import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import i18n from 'vokzal-platform/i18n';

import { showConfirmation } from 'vokzal-platform/managers/navigation'

import { confirm_reservation } from '../../../api/confirmation';

import { searchFormUrlToConfirm, confirmationUrlWithParams } from '../../../utils/url_params';
import { validateFullFillment } from '../../../utils/passengers';

import { changeButtonStateAction, storeErrorAction, resetErrorAction } from '../action';
import { setTimerAction, reservationCompleteAction, seatChangedAction } from '../../confirmation/action';


import OrderButtonView from 'vokzal-platform/components/step4/order_button/view'

OrderButtonView.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  urlParams: PropTypes.string,
  confirmationUrlParams: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  onDataLoaded: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

const mapStateToProps = state => {

  // выполняется при любом изменении в полях формы, а также клике или фокусе
  const p = state.step3_passengers;
  const c = state.step4_contacts;

  let urlParams = '';
  let confirmationUrlParams = '';
  const travelDate = state.search.date_from.format('DD.MM.YYYY');
  let isEnabled = p.passengers.map(o => validateFullFillment(o, travelDate)).reduce((accumulator, currentValue) => currentValue && accumulator, true);

  isEnabled = isEnabled && ((c.is_email_valid && c.email !== '') && (c.is_mobile_phone_valid && c.mobile_phone !== ''));

  let isConfirmation = false;
  let buttonText = i18n.t('make_order')
  if (state.step4_passenger_data.button_state === 'CONFIRMATION') {
    buttonText = i18n.t('ordering');
    isEnabled = false;
    isConfirmation = true;
  }

/*  const is_errors = p.passengers.map(o => {
    for (let error in o.errors) {
      if (o.errors[error] !== null) {
        return false;
      }
    }
    return true;
  }); // нет ли ошибок в каждом билете*/


  // isEnabled = isEnabled && is_errors.every(elem => elem);

  const stopBuy = state.step4_documents.stop_buy;
  if (stopBuy === true) {
    isEnabled = false;// для закрытия продаж
  }


  if (isEnabled) {
    urlParams = searchFormUrlToConfirm(state);
    confirmationUrlParams = confirmationUrlWithParams(state);
  }

  return { isEnabled, buttonText, urlParams, confirmationUrlParams, isConfirmation };
};

const mapDispatchToProps = dispatch => {
  return {
    onButtonClick: (isEnabled, redirectUrlParams, confirmationUrlParams, onDataLoaded, onError) => {
      //TODO make request and validate output
      if (isEnabled) {
        // обработчик клика по кнопке оформить заказ
        // дополнительная проверка формы
        dispatch(resetErrorAction());
        dispatch(changeButtonStateAction('CONFIRMATION'));
        confirm_reservation(confirmationUrlParams, redirectUrlParams, onDataLoaded, onError);
      }
    },
    onDataLoaded: (time_spent, redirectUrlParams, response) => {
      dispatch(setTimerAction({ seconds: response.secs }));
      dispatch(reservationCompleteAction({ time_spent, ...response }));
      if (response.showSeatsNotSame) {
        dispatch(seatChangedAction());
      }

      showConfirmation(dispatch, redirectUrlParams)
    },
    onError: ({ searchUrlWithParams, code, action, message }) => {
      message = message + ' ';
      dispatch(changeButtonStateAction('NEW'));
      dispatch(storeErrorAction({ code, action, message }));
    }
  }
};

const OrderButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderButtonView);

export default OrderButtonContainer
