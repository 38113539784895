import React from 'react';

import i18n from 'vokzal-platform/i18n';

const ProcessButtonView = ({ isEnabled, urlWithParams, onButtonClick, onDataLoaded, onError }) => (
  <div className="check-button text-center font-weight-medium text-uppercase rounded mt-3 p-3">
    <div className={"check-button-label " + ((!isEnabled) ? 'disabled' : '')} onClick={() => onButtonClick(isEnabled, urlWithParams, onDataLoaded, onError)}>
      {i18n.t('process_ticket_return')}
    </div>
  </div>
);

module.exports = ProcessButtonView;