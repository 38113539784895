import {connect} from 'react-redux'
import OfferModalView from 'vokzal-platform/components/confirmation/offer_modal/view'
import {hideOfferModalAction, showOfferModalAction} from "../../confirmation/offer_modal/action";

const mapStateToProps = state => {
    return {
        show: state.offer_modal.show
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCloseClick: () => {
            dispatch(hideOfferModalAction());
        },
        onOfferClick: () => {
            dispatch(showOfferModalAction())
        },
    }
};

const OfferModalContainter = connect(
    mapStateToProps,
    mapDispatchToProps
)(OfferModalView)

export default OfferModalContainter