import React from 'react';

import { BlogPreLoader } from '../../../src/components/shared/preloader';
import HeadMenuPedia from '../../../src/components/shared/menu_pedia';

import BlogTitle from '../../../src/components/blog/title';

import HelpPostsList from '../../../src/components/help/posts_list';
import HelpSinglePostContent from '../../../src/components/help/single_post_content';

const HelpView = ({data_loaded, has_subnodes}) => (
  <div className="help">
    <div className="help-block">
      <div className="help-header-block">
        <HeadMenuPedia />

        <BlogTitle type="help_single_post" />
      </div>

      <div className="help-content pt-3 ml-3 mr-3">
        {
          (data_loaded)
            ?
            (has_subnodes) ? <HelpPostsList /> : <HelpSinglePostContent />
            : null}
      </div>

      <BlogPreLoader show={!data_loaded} />
    </div>

  </div>
);

module.exports = HelpView;