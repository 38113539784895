import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import i18n from 'vokzal-platform/i18n';

import SapsanLuggageCabinView from 'vokzal-platform/components/step3/sapsan/base/sapsan_luggage_cabin/view'

SapsanLuggageCabinView.propTypes = {
  wardrobeTitle: PropTypes.string.isRequired,
  autoHeightWardrobe: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({ wardrobeTitle : i18n.t('sapsan_luggage'), autoHeightWardrobe : false })


const SapsanLuggageCabinContainer = connect(
  mapStateToProps
)(SapsanLuggageCabinView)

export default SapsanLuggageCabinContainer