import React from 'react';
import { Link } from 'react-router-dom';

const BlogPostView = ({ id, date, permalink, title, text, onPostClick }) => (
  <div className="blog-post mb-2">
    <div className="post-date font-light small">{date}</div>
    <div className="post-title mb-1">
      <Link className="font-weight-bold" to={permalink} onClick={(e) => { onPostClick(permalink, e) }}>{title}</Link>
    </div>
    <div className="post-description mb-4">{text}</div>
  </div>
);

module.exports = BlogPostView;