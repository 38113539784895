import React from 'react';
import { Link } from 'react-router-dom';

const TrainCarClassSelectView = ({ title, carCategory, service, priceItems, urlParams, onCarClassClick }) => {

  let priceItem = null;
  if (priceItems.hasOwnProperty(carCategory)) {
    priceItem = priceItems[carCategory];
  } else if (priceItems.hasOwnProperty(service)) {
    priceItem = priceItems[service];
  }

  if (priceItem === null) {
    return null;
  }

  return (
    <div className="car-class d-flex mb-2 p-3 no-gutters" onClick={() => onCarClassClick(carCategory, service, urlParams)}>
      <div className="col-6 small font-weight-medium align-self-center">
        {title}
      </div>

      <div className="col small align-self-center text-right">
        {priceItem.seats}
      </div>
      <div className="col font-weight-bold text-right">
        {priceItem.price} &#8381;
      </div>
    </div>
  );
};

module.exports = TrainCarClassSelectView;