// @flow
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { PediaStationItemPropType, PediaStationsGroupItemPropType } from '../../../../src/types';

import PediaStationsListGroupView from 'vokzal-platform/components/pedia/stations_list_group/view';

PediaStationsListGroupView.propTypes = {
  groups: PropTypes.arrayOf(PediaStationsGroupItemPropType).isRequired,
  onLinkClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => {

  return {}
}

const mapDispatchToProps = dispatch => {
  return {

  }
};

const PediaStationsListGroupContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaStationsListGroupView)

export default PediaStationsListGroupContainer