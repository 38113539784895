// @flow
import PropTypes from 'prop-types';
import { SeatNumbersLastochkaPropType } from '../../../../../src/types/';

import Lastochka1PCarView from 'vokzal-platform/components/step3/lastochka/lastochka_1p_car_map/view'

Lastochka1PCarView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersLastochkaPropType).isRequired
};

const Lastochka1PCar = Lastochka1PCarView

export default Lastochka1PCar