import React, { Component } from 'react';

import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { blog_fetch_post } from '../../../api/blog_fetch_post'

import { postStoreAction } from './action';

import BlogSinglePostView from 'vokzal-platform/components/blog/single_post/view'


BlogSinglePostView.propTypes = {
  data_loaded: PropTypes.bool.isRequired,
  post_url: PropTypes.string.isRequired,
  onDataLoaded: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};


class BlogSinglePostContainer extends Component {

  componentDidMount() {
    if (!this.props.data_loaded && this.props.post_url) {
      blog_fetch_post(this.props.post_url, this.props.onDataLoaded, this.props.onError);
    }
  }

  render() {
    return <BlogSinglePostView {...this.props} />;
  }
}


const mapStateToProps = state => ({
  data_loaded : state.blog_single_post.data_loaded,
  post_url : state.blog_single_post.post_url
});

const mapDispatchToProps = dispatch => (
  {
    onError: (post) => {
      // debugger
    },
    onDataLoaded: (time_spent, post) => {
      dispatch(postStoreAction(post));
    },

  }
);

BlogSinglePostContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogSinglePostContainer);

export default BlogSinglePostContainer