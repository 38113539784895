// @flow
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { step2TabClickAction } from '../search_result_tabs/action'

import { showStep2Tab } from 'vokzal-platform/managers/navigation'


import SearchResultTabView from 'vokzal-platform/components/step2/search_result_tab/view'

SearchResultTabView.propTypes = {
  isActive : PropTypes.bool.isRequired,
  title : PropTypes.string.isRequired,
  code : PropTypes.string.isRequired,
  price : PropTypes.number,
  onTabClick : PropTypes.func.isRequired,
};

const mapStateToProps = state => (
  {}
)

const mapDispatchToProps = dispatch => {
  return {
    onTabClick: code => {
      showStep2Tab(dispatch, code)
      dispatch(step2TabClickAction(code))
    }
  }
}

const SearchResultTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultTabView)

export default SearchResultTabContainer