import React from 'react';
import PropTypes from 'prop-types';

const SeatPriceView = ({ title, price, discount, seatsCnt }) => (
  <div className="train-car-select-seats-price-info-block text-center">
    <div className="small">{title}</div>
    <div className="small font-weight-medium">{price} &#8381;</div>
    <div className="small font-light">{seatsCnt}</div>
  </div>
);

SeatPriceView.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  seatsCnt: PropTypes.string.isRequired,
};

module.exports = SeatPriceView;