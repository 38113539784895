import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'vokzal-platform/i18n'

import { SearchFormFields, StationEmpty } from '../../../types'
import { showDatesModalAction } from '../../home/dates_modal/action'
import {
  setStationFromAction,
  setStationToAction,
  setStationFromInfoAction,
  setStationToInfoAction,
  setTypeAction,
  setTypeDataAction
} from '../../home/search_form/action'

import { step2AlternativeSetBusFormAction, step2AlternativeSetTrainFormAction } from '../../step2/alternative/action'
import { step2TabClickAction } from '../../step2/search_result_tabs/action'
import PediaOrderFormShortBusView from 'vokzal-platform/components/pedia/order_form_short_bus/view'

PediaOrderFormShortBusView.propTypes = {
  orderButtonTitle : PropTypes.string.isRequired,
  onSelectDateClick : PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const orderButtonTitle = i18n.t('select_data_and_schedule_order');
  const s = state.step3_bus;
  const error = typeof s.error !== "undefined" && s.error ? s.error.text : null;
  const wait = typeof s.wait !== "undefined" && s.wait;

  return { orderButtonTitle, error, wait }
}

const mapDispatchToProps = dispatch => {
  return {
    onSelectDateClick: (bus) => {

      const from = (bus.departure.objStation) ? bus.departure.objStation : StationEmpty;
      const to = (bus.arrival.objStation) ? bus.arrival.objStation : StationEmpty;

      const busSearchForm = {from: bus.departure.objStation, to: bus.arrival.objStation };
      let segments = {};
      if (busSearchForm.from !== undefined && busSearchForm.from) {
        busSearchForm.from.id = parseInt(busSearchForm.from.id);
        segments.departure_bus_stop_id = busSearchForm.from.id;
      }
      if (busSearchForm.to !== undefined && busSearchForm.to) {
        busSearchForm.to.id = parseInt(busSearchForm.to.id);
        segments.arrival_bus_stop_id = busSearchForm.to.id;
      }
      busSearchForm.segments = [segments];

      busSearchForm.type = 'bus';
      bus.arrival.id = bus.arrival.busStop.id;
      bus.departure.id = bus.departure.busStop.id;

      const trainSearchForm = {};

      dispatch(step2AlternativeSetBusFormAction(busSearchForm));
      dispatch(step2AlternativeSetTrainFormAction(trainSearchForm));
      dispatch(step2TabClickAction('buses'));

      dispatch(setStationFromAction(from));
      dispatch(setStationFromInfoAction(from));
      dispatch(setStationToAction(to));
      dispatch(setStationToInfoAction(to));

      dispatch(setTypeAction('bus'));
      dispatch(setTypeDataAction({form: busSearchForm, bus: bus}));

      const selected_input = SearchFormFields.FROM_DATE;
      const date = '';

      dispatch(showDatesModalAction({selected_input, date}));
    },
  }
}


const PediaOrderFormShortBusContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaOrderFormShortBusView)

export default PediaOrderFormShortBusContainer