import React, { Component } from 'react';
import { connect } from 'react-redux'

import { fetch_bus_seats } from '../../../api/fetch_bus_seats';

import { bus_seats_loaded } from './action';
import { hide_preloader } from '../../step3/action';

import BusSeatsView from 'vokzal-platform/components/step3bus/bus_seats/view'
import {
  setStationFromAction,
  setStationFromInfoAction,
  setStationToAction,
  setStationToInfoAction
} from "../../home/search_form/action";

BusSeatsView.propTypes = {

};

class BusSeatsContainer extends Component {

  componentDidMount() {
    const url = this.props.searchUrlWithParams ? this.props.searchUrlWithParams : window.location.pathname  + window.location.search;
      fetch_bus_seats(url, this.props.onDataLoaded);
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    if (!this.props.loaded) {
      return null;
    }

    return <BusSeatsView {...this.props} />;
  }
}

const mapStateToProps = state => {
  const auto_select  = state.step3_bus_seats.auto_select;
  const loaded  = state.step3_bus_seats.loaded;

  return { loaded, auto_select }
};

const mapDispatchToProps = dispatch => {
  return {
    onDataLoaded : (time_spent, response) => {
      dispatch(hide_preloader());
      dispatch(bus_seats_loaded({ time_spent, seatNumbers: response.seatNumbers }));
      dispatch(setStationFromAction( response.from ));
      dispatch(setStationFromInfoAction(response.from));
      dispatch(setStationToAction(response.to));
      dispatch(setStationToInfoAction(response.to));
    }
  }
};

BusSeatsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BusSeatsContainer);

export default BusSeatsContainer