import React from 'react';
import { connect } from 'react-redux';

import i18n from 'vokzal-platform/i18n';

import PropTypes from 'prop-types';
import { SeatNumbersSeatedPropType } from '../../../../../src/types/';

import SapsanFirstClassMapView from 'vokzal-platform/components/step3/sapsan/sapsan_first_class_map/view'

SapsanFirstClassMapView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersSeatedPropType).isRequired,
  viewType : PropTypes.oneOf(['kupe', 'meeting-room']).isRequired
};

const mapStateToPropsKupe = state => (
  { wardrobeTitle : i18n.t('sapsan_wardrobe'), viewType : 'kupe' }
);

const mapStateToPropsMeetingRoom = state => (
  { wardrobeTitle : i18n.t('sapsan_wardrobe'), viewType : 'meeting-room' }
);

export const SapsanFirstClassMapKupeContainer = connect(
  mapStateToPropsKupe
)(SapsanFirstClassMapView);

export const SapsanFirstClassMapMeetingRoomContainer = connect(
  mapStateToPropsMeetingRoom
)(SapsanFirstClassMapView);
