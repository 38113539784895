import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { PaymentTypeOptionPropType } from '../../../types/';

import PaymentTypeView from 'vokzal-platform/components/step4/payment_type/view'

PaymentTypeView.propTypes = {
  totalPrice : PropTypes.number.isRequired,
  paymentTypes : PropTypes.arrayOf(PaymentTypeOptionPropType).isRequired
};

const mapStateToProps = state => {

  const p = state.step3_passengers;
  const paymentTypes = state.step4_documents.payment_types;
  const priceDiapason = state.step3_categories.car_category_selected.price;

  const totalPrice = p.passengers.map(
    (o, i) => {
      let ticketPrice = 0;
      if (priceDiapason.hasOwnProperty('upper') && priceDiapason.hasOwnProperty('lower')) {

        if (p.selected_seats[i].isUpper) {
          ticketPrice = priceDiapason.upper
        } else {
          ticketPrice = priceDiapason.lower
        }
        if (o.type === 'child') {
          ticketPrice = Math.round(ticketPrice / 2);
        }
      } else {
        ticketPrice = state.step2_details.price.hasOwnProperty([o.type]) ? state.step2_details.price[o.type] : 0
      }
      return ticketPrice;
    }


  ).reduce((a,b) => (a+b));

  return { totalPrice, paymentTypes };
};

const mapDispatchToProps = dispatch => {
  return {

  }

};

const PaymentTypeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentTypeView);

export default PaymentTypeContainer