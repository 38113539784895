import moment from 'moment';

import { vokzal_config } from '../config';

export const fetch_bus_seats = (searchUrlWithParams, onDataLoaded, onError) => {
  const requested_at = moment();

  fetch(vokzal_config.rest.base_url + searchUrlWithParams, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {
      //TODO error handling

      if (responseJson.hasOwnProperty('error')) {
        onError(Object.assign({searchUrlWithParams}, responseJson.error));
      } else {
        const seatNumbers = (responseJson.seatNumbers) ? responseJson.seatNumbers.map((value) => ( parseInt(value) )) : [];
        const from = responseJson.fromName;
        const to = responseJson.toName;
        onDataLoaded(moment().diff(requested_at), {seatNumbers, from, to});
      }
    });
};