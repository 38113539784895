import React from 'react';
import i18n from 'vokzal-platform/i18n';

import BootstrapLabeledInput from '../../base/bootstrap_labeled_input';
import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';

const ActivateView = ({password2, password2_error, password, password_error, onPassword2Changed, onPasswordChanged, error, success, onSubmit, repeat_error, onDataLoaded, onError}) => (
    <div className="user">
        <div className="pedia">
            <div className="pedia-block">
                <div className="pedia-header-block">
                    <HeadMenuPedia />
                    <div className="p-3"></div>
                </div>
            </div>
            {success ?
                <div className="success-message">
                    <p>Пароль для входа в личный кабинет успешно установлен</p>
                </div>
                : <div className="form-activate mt-5 mb-5">

                    <h4>Придумайте пароль</h4>
                    <span className="label">не менее 8 символов, включая строчные, заглавные буквы и цифры</span>
                    <BootstrapLabeledInput label={i18n.t('confirmation_password')}
                                           fieldsetClass={password_error ? "input-invalid" : ""}
                                           name="password" value={password} required={true}
                                           maxlength="30"
                                           type="password"
                                           onChange={(value, inputElement) => onPasswordChanged(value, password, inputElement)} />
                    {password_error ? <p className="label error">{password_error}</p> : null}
                    <span>Повторите его</span>
                    <BootstrapLabeledInput label={i18n.t('confirmation_password')}
                                           fieldsetClass={password2_error ? "input-invalid" : ""}
                                           name="password2" value={password2} required={true}
                                           maxlength="30"
                                           type="password"
                                           onChange={(value, inputElement) => onPassword2Changed(value, password2, inputElement)} />
                    {password2_error ? <span className="label error">{password2_error}</span> : null}
                    {repeat_error ? <span className="label error">{repeat_error}</span> : null}

                    {error ? <span className="label error">{error}</span> : null}
                    <div className="standart-button centered mb-3 mt-4" onClick={() => onSubmit(password, password2, onDataLoaded, onError)}>
                        <span>{i18n.t('save')}</span>
                    </div>
                </div>
            }

        </div>
    </div>
);

module.exports = ActivateView;

