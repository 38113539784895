import React from 'react';
import {Link} from "react-router-dom";
import i18n from "../../../i18n";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import LoginModal from '../../../../src/components/shared/login_modal';
import RegisterModal from '../../../../src/components/shared/register_modal';
import RenewModal from '../../../../src/components/shared/renew_modal';

const HeaderView = ({ user, onClickLogout }) => (
    <div className="header">
            <ul className="menu-auth">
                 {user ? <li><Link to="/user/profile">{user}</Link></li> : null}
                {user ? <li><div onClick={() => onClickLogout()}><span className="icon"><FontAwesomeIcon icon={faSignOutAlt} /></span></div></li> : null}
                <li className={user === null ? '' : 'd-none'}><RegisterModal text={i18n.t('register')} className=""/></li>
                <li className={user === null ? '' : 'd-none'}><LoginModal text={i18n.t('login')} className=""/></li>
                <li className="d-none"><RenewModal text={i18n.t('renew')} className=""/></li>
            </ul>
    </div>
);

module.exports = HeaderView;