// @flow
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PediaTabContentView from 'vokzal-platform/components/pedia/tab_content/view'
import { PediaTabsTrain, PediaTabsBus } from '../../../types';
PediaTabContentView.propTypes = {
  type : PropTypes.string.isRequired,
  show : PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps)  => {
  let selected = state.pedia_tab_group.currentTab;
  let items = [];
  if (ownProps.hasOwnProperty("default")) {
    if (ownProps.default === 'train') {
      items = PediaTabsTrain;
      if (!items.some(item => item.value === selected)) {
        selected = state.pedia_train.currentTab;
      }
    } else if (ownProps.default === 'bus') {
      items = PediaTabsBus;
      if (!items.some(item => item.value === selected)) {
        selected = state.pedia_bus.currentTab;
      }
    }
  }

  return { show : ownProps.type === selected }
}

const PediaTabContentContainer = connect(
  mapStateToProps
)(PediaTabContentView)

export default PediaTabContentContainer