import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import DateInputErrorView from 'vokzal-platform/components/step4/date_input_error/view';

DateInputErrorView.propTypes = {
  isValueValid: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {

  const p = state.step3_passengers.passengers[ownProps.ticketNumber - 1];

  const isValueValid = (p.validators.hasOwnProperty('birth_date')) ? p.validators['birth_date'] : true;
  const errorMessage = (p.errors.hasOwnProperty('birth_date')) ? p.errors['birth_date'] : null;

  return {
    isValueValid, errorMessage
  }
};

const DateInputErrorContainer = connect(
  mapStateToProps
)(DateInputErrorView);

export default DateInputErrorContainer