import {connect} from 'react-redux'
import LoginModalView from 'vokzal-platform/components/shared/login_modal/view'
import {hideLoginModalAction, showLoginModalAction} from "../../shared/login_modal/action";
import {userLoginAction, userSetValueAction} from "../../user/action";
import email_validator from 'email-validator';
import {user_register} from "../../../api/user_register";
// import { useCookies } from 'react-cookie';
import { getCookie, setCookie, deleteCookie } from "../../../utils/functions";

import {showRenewModalAction} from "../../shared/renew_modal/action";

const mapStateToProps = state => {
    return {
        show: state.login_modal.show,
        title: 'Вход',
        email: state.user.email,
        email_error: state.user.email_error,
        password: state.user.password,
        password_error: state.user.password_error,
        remember: state.user.remember,
        error: state.user.error,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCloseClick: () => {
            dispatch(userSetValueAction({password: '', error: ''}));
            dispatch(hideLoginModalAction());
        },
        onClick: () => {
            dispatch(showLoginModalAction())
        },
        onEmailChanged: (email) => {
            dispatch(userSetValueAction({email}))
        },
        onPasswordChanged: (password) => {
            dispatch(userSetValueAction({password}))
        },
        onRememberChanged: (remember) => {
            dispatch(userSetValueAction({remember: remember ? 0 : 1 }))
        },
        onClickRenew: () => {
            dispatch(userSetValueAction({password: '', error: ''}));
            dispatch(hideLoginModalAction());
            dispatch(showRenewModalAction())
        },
        onDataLoaded : (time_spent, response) => {
            dispatch(userSetValueAction({error: '', login: true, password: ''}));
            dispatch(hideLoginModalAction());
            // const [cookies, setCookie] = useCookies(['refresh_token']);
            // const options = {};
            // options['secure'] = true;
            // options['max-age'] = 5184000;
            // setCookie('refresh_token', response.refresh_token, options);
        },
        onError : (error) => {
            dispatch(userSetValueAction({error}))
        },
        onSubmit: (email, password, remember, onDataLoaded, onError) => {
            email = email.trim();

            let email_error ='';
            if(!email) {
                email_error = 'Укажите электронную почту';
            } else if(!email_validator.validate(email)) {
                email_error = 'Укажите корректную электронную почту';
            }
            dispatch(userSetValueAction({email_error: email_error}))

            let password_error = '';
            if(!password) {
                password_error = 'Укажите пароль';
            }
            dispatch(userSetValueAction({password_error: password_error}))

            if(!email_error && !password_error) {
                user_register('/api/token', {email, password, remember}, onDataLoaded, onError);
            }

        }
    }
};

const LoginModalContainter = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginModalView)

export default LoginModalContainter