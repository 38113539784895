import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import email_validator from 'email-validator'

import {contactsChangedAction, contactsCheckedAction} from './action'

import { webAndroidTextMaskFix } from 'vokzal-platform/managers/navigation'
import ContactDetailsView from 'vokzal-platform/components/step4/contact_details/view'

import { sanitizeMobilePhone } from '../../../utils/text_sanitizer'
import {checkLogin, getPlatform} from "../../../utils/functions";

ContactDetailsView.propTypes = {
  email: PropTypes.string.isRequired,
  is_email_valid: PropTypes.bool.isRequired,
  mobile_phone: PropTypes.string.isRequired,
  is_mobile_phone_valid: PropTypes.bool.isRequired,
  onEmailChanged: PropTypes.func.isRequired,
  onMobilePhoneChanged: PropTypes.func.isRequired,
};

class ContactDetailsContainer extends Component {
  componentDidMount() {
    if (getPlatform() === 'iPhone' ) {
      document.getElementById('phone').blur();
    }
  }

  render() {
    return <ContactDetailsView {...this.props} />;
  }
}

const mapStateToProps = state => {

  // const email = "dima@mail.ru"//state.step4_contacts.email;
  // const is_email_valid = true//state.step4_contacts.is_email_valid;
  // const mobile_phone = "+79037306230"//state.step4_contacts.mobile_phone;
  // const is_mobile_phone_valid = true//state.step4_contacts.is_mobile_phone_valid;

  let email = state.step4_contacts.email;
  let is_email_valid = state.step4_contacts.is_email_valid;
  let mobile_phone = state.step4_contacts.mobile_phone;
  let is_mobile_phone_valid = state.step4_contacts.is_mobile_phone_valid;
  let attention = state.step4_contacts.attention;
  if (global.test_payment) {
    email = "mobdim@gmail.ru"
    is_email_valid = true
    mobile_phone = "+79037306230"
    is_mobile_phone_valid = true
  }

  let lk_status = state.step4_contacts.lk_status;
  let user = checkLogin(state);
  return { email, is_email_valid, mobile_phone, is_mobile_phone_valid, attention, lk_status, user };
};

const mapDispatchToProps = dispatch => {
  return {
    onEmailChanged: (email) => {
      //TODO validate first

      email = email.trim();

      const is_email_valid = email_validator.validate(email)
      dispatch(contactsChangedAction({ email, is_email_valid }))
    },
    onMobilePhoneChanged: (mobile_phone, previous, inputElement) => {
      const sanitized = sanitizeMobilePhone(mobile_phone, previous);
      dispatch(contactsChangedAction(sanitized));

      webAndroidTextMaskFix(inputElement, sanitized.mobile_phone)
    },
    onAttentionChanged: (attention) => {
      attention = parseInt(attention) === 1 ? 0 : 1;
      dispatch(contactsChangedAction({attention}));
    },
    onClickCheck: (value) => {
      let status = value === 1 ? 0 : 1;
      dispatch(contactsCheckedAction({lk_status: status}));
    }
  }

};

ContactDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactDetailsContainer);

export default ContactDetailsContainer