import moment from 'moment';

import {connect} from 'react-redux';
import PropTypes from 'prop-types'

import i18n from 'vokzal-platform/i18n';

import {
    sanitizeAlphaRuEn,
    sanitizeAlphaRu,
    sanitizeAlphaEn,
    sanitizeDate,
    sanitizePassportRF,
    sanitizePassportZagran,
    isValidPassportRF,
    isValidPassportZagran,
    sanitizeChildDocument,
    isValidChildDocument
} from '../../../utils/text_sanitizer';
import {isValidBirthday, isValidBirthdayByTicketType, validationRulePassport, isPassport} from '../../../utils/passengers';
import {formDataChangedAction, formDataValidatedAction, formInputFocusAction, formFocusLostAction} from '../action';

import TextInputView from 'vokzal-platform/components/step4/text_input/view';

import {webAndroidTextMaskFix} from 'vokzal-platform/managers/navigation'

import getDocumentMask from '../document_mask'

TextInputView.propTypes = {
    ticketNumber: PropTypes.number.isRequired,
    ticketType: PropTypes.oneOf(['adult', 'child', 'infant']).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    isValueValid: PropTypes.bool.isRequired,
    isInFocus: PropTypes.bool.isRequired,
    isErrorInExtraField: PropTypes.bool.isRequired,
    validationRule: PropTypes.oneOf(['', 'alpha', 'alpha_num', 'date', 'passport', 'passport_zagran', 'passport_foreigh', 'child_document', 'fio_cyrillic', 'fio_latin']).isRequired,
    validationData: PropTypes.string,
    label: PropTypes.string.isRequired,
    fieldsetClass: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onFocusLostValidation: PropTypes.func.isRequired,
    info: PropTypes.object
};


const mapStateToPropsFio = (state, ownProps) => {

    const p = state.step3_passengers.passengers[ownProps.ticketNumber - 1];
    const ticketType = p.type;

    const name = 'fio'
    let value = p[name];

    if (global.test_payment) {
        value = "Задорожный Дмитрий Григорьевич"
    }

    const isValueValid = (p.validators.hasOwnProperty(name)) ? p.validators[name] : true;
    const errorMessage = (p.errors.hasOwnProperty(name)) ? p.errors[name] : null;

    const validationRule = (p['document_type'] === 'ЗП') ? 'fio_latin' : 'fio_cyrillic';

    const isInFocus = p.focus === name;

    return {
        ticketType, name, value, isValueValid, isInFocus,
        isErrorInExtraField: false, errorMessage,
        label: i18n.t('fio'),
        validationRule,
        info: p
    }
};

const mapStateToPropsDate = (state, ownProps) => {
    const p = state.step3_passengers.passengers[ownProps.ticketNumber - 1];
    const ticketType = p.type;

    const name = 'birth_date'
    let value = p[name];

    if (global.test_payment) {
        value = "20.06.1978"
    }

    const travelDate = state.search.date_from.format('DD.MM.YYYY');
    const isValueValid = (p.validators.hasOwnProperty(name)) ? p.validators[name] : true;

    const isInFocus = p.focus === name;

    const counter = (p.counters.hasOwnProperty(name)) ? p.counters[name] : 0;

    return {
        ticketType, name, value, isValueValid, isInFocus,
        isErrorInExtraField: true,
        fieldsetClass: 'col-6',
        className: 'active',
        placeholder: 'ДД.ММ.ГГГГ',
        label: i18n.t('birth_date'),
        validationRule: 'date',
        validationData: travelDate,
        counter,
        info: p
    }
};

const mapStateToPropsBirthCity = (state, ownProps) => {

    const p = state.step3_passengers.passengers[ownProps.ticketNumber - 1];
    const ticketType = p.type;

    const name = 'birth_city'
    let value = p[name];

    if (global.test_payment) {
        value = "Москва"
    }

    const isValueValid = true;
    const errorMessage = (p.errors.hasOwnProperty(name)) ? p.errors[name] : null;

    const isInFocus = p.focus === name;

    return {
        ticketType, name, value, isValueValid, isInFocus,
        isErrorInExtraField: false, errorMessage,
        label: i18n.t('birth_city'),
        validationRule: 'alpha_num'
    }
};

const mapStateToPropsPassport = (state, ownProps) => {

    const p = state.step3_passengers.passengers[ownProps.ticketNumber - 1];
    const ticketType = p.type;

    const name = 'document_number'

    let value = p[name];

    if (global.test_payment) {
        value = "4601837564"
    }

    const isValueValid = (p.validators.hasOwnProperty(name)) ? p.validators[name] : true;
    const errorMessage = (p.errors.hasOwnProperty(name)) ? p.errors[name] : null;

    const document_mask = getDocumentMask(p["document_type"])//state.step4_passenger_data.document_mask

    const isInFocus = p.focus === name;

    const counter = (p.counters.hasOwnProperty(name)) ? p.counters[name] : 0;

    // const fieldsetClass = (value && (!isValueValid || !isInFocus)) ? '' : 'border-bottom-0';
    const fieldsetClass = '';

    return {
        mask: document_mask,
        fieldsetClass,
        ticketType,
        name, value, isValueValid, isInFocus,
        isErrorInExtraField: false, errorMessage,
        label: i18n.t('document_number'),
        validationRule: validationRulePassport(p),
        counter,
        info: p
    }
};

const mapStateToPropsEmail = (state, ownProps) => {

    const p = state.step3_passengers.passengers[ownProps.ticketNumber - 1];
    const ticketType = p.type;

    const name = 'email';

    let value = p[name];

    const isValueValid = (p.validators.hasOwnProperty(name)) ? p.validators[name] : true;
    const errorMessage = (p.errors.hasOwnProperty(name)) ? p.errors[name] : null;

    const isInFocus = p.focus === name;

    const counter = (p.counters.hasOwnProperty(name)) ? p.counters[name] : 0;

    const fieldsetClass = '';

    return {
        fieldsetClass,
        ticketType,
        name, value, isValueValid, isInFocus,
        isErrorInExtraField: false, errorMessage,
        label: i18n.t('confirmation_email'),
        validationRule: '',
        counter,
        info: p
    }
};

const mapStateToPropsPhone = (state, ownProps) => {

    const p = state.step3_passengers.passengers[ownProps.ticketNumber - 1];
    const ticketType = p.type;

    const name = 'phone';

    let value = p[name];

    const isValueValid = (p.validators.hasOwnProperty(name)) ? p.validators[name] : true;
    const errorMessage = (p.errors.hasOwnProperty(name)) ? p.errors[name] : null;

    const isInFocus = p.focus === name;

    const counter = (p.counters.hasOwnProperty(name)) ? p.counters[name] : 0;

    const fieldsetClass = '';

    return {
        fieldsetClass,
        ticketType,
        name, value, isValueValid, isInFocus,
        isErrorInExtraField: false, errorMessage,
        label: i18n.t('confirmation_mobile_phone'),
        validationRule: '',
        counter,
        info: p
    }
};

const mapDispatchToProps = (dispatch) => {
    let i = 1;


    let valid = true;
    let error = null;


    return {
        onFocus: (position, field) => {
            dispatch(formInputFocusAction({position, field}));
        },

        onFocusLostValidation: (position, ticketType, field, validationRule, validationData, value, info) => {
            if (validationRule === 'date') {
                if (value.length === 10) {
                    const ticketDate = moment(validationData, "DD.MM.YYYY");

                    valid = isValidBirthday(value);
                    if (!valid) {
                        error = i18n.t('validation_error_birthday');
                    } else if (!moment(value, "DD.MM.YYYY").isSameOrBefore()) {
                        error = i18n.t('validation_error_birthday_now');
                        valid = false;
                    } else {
                        valid = isValidBirthdayByTicketType(ticketType, value, ticketDate);
                        error = null;
                        if (!valid) {
                            switch (ticketType) {
                                case 'adult'  :
                                    error = i18n.t('validation_error_birthday_adult');
                                    break;
                                case 'child'  :
                                    error = i18n.t('validation_error_birthday_child');
                                    break;
                                case 'infant' :
                                    error = i18n.t('validation_error_birthday_infant');
                                    break;
                            }
                        }
                    }
                } else {
                    valid = false;
                }
            } else if (validationRule === 'fio_cyrillic') {
                const words = value.split(" ");
                let words2 = words.filter(n => n);
                valid = (words2.length === 3 || words2.length === 4);
                error = (!valid) ? i18n.t('validation_error_fio_cyrillic') : null;
            } else if (validationRule === 'passport') {
                valid = isValidPassportRF(value);
                error = (!valid) ? i18n.t('validation_error_passport_rf') : null;
            } else if (validationRule === 'passport_zagran') {
                valid = isValidPassportZagran(value);
                error = (!valid) ? i18n.t('validation_error_passport_zagran') : null;
            } else if (validationRule === 'child_document') {
                valid = isValidChildDocument(value);
                error = (!valid) ? i18n.t('validation_error_child_document') : null;
            }

            dispatch(formDataValidatedAction({position, field, valid, error}));
        },
        onValueChange: (position, ticketType, field, validationRule, value, prevValue, inputElement, info, validationData) => {
            let sanitizedValue = value;
            let isSanitized = false;

            if (validationRule === 'alpha') {
                sanitizedValue = sanitizeAlphaRuEn(value);
                isSanitized = true;
                //TODO based on document type (ciryllic and word count)
            } else if (validationRule === 'date') {
                sanitizedValue = sanitizeDate(value, prevValue);
                isSanitized = true;
            } else if (validationRule === 'fio_cyrillic') {
                sanitizedValue = sanitizeAlphaRu(value);
                isSanitized = true;
            } else if (validationRule === 'passport') {
                sanitizedValue = sanitizePassportRF(value, prevValue);
                isSanitized = true;
            } else if (validationRule === 'passport_zagran') {
                sanitizedValue = sanitizePassportZagran(value, prevValue);
                isSanitized = true;
            } else if (validationRule === 'child_document') {
                sanitizedValue = sanitizeChildDocument(value);
                isSanitized = true;
            }

            dispatch(formFocusLostAction({position}));
            dispatch(formDataChangedAction({position, field, value: sanitizedValue}));

            if (isSanitized && inputElement) {
                webAndroidTextMaskFix(inputElement, sanitizedValue)
            }

            // исправить дублирование кода в функции onFocusLostValidation и здесь
            if (validationRule === 'date') {
                if (value.length === 10) {
                    const ticketDate = moment(validationData, "DD.MM.YYYY");

                    valid = isValidBirthday(value);
                    if (!valid) {
                        error = i18n.t('validation_error_birthday');
                    } else if (!moment(value, "DD.MM.YYYY").isSameOrBefore()) {
                        error = i18n.t('validation_error_birthday_now');
                        valid = false;
                    } else {
                        valid = isValidBirthdayByTicketType(ticketType, value, ticketDate);
                        if (!valid) {
                            switch (ticketType) {
                                case 'adult'  :
                                    error = i18n.t('validation_error_birthday_adult');
                                    break;
                                case 'child'  :
                                    error = i18n.t('validation_error_birthday_child');
                                    break;
                                case 'infant' :
                                    error = i18n.t('validation_error_birthday_infant');
                                    break;
                            }
                        } else if (info.birth_date) {
                            const age = moment().diff(moment(info.birth_date, "DD.MM.YYYY"), 'years');
                            if (isPassport(info)) {

                                if (age < 14) {
                                    error = i18n.t('validation_error_birthday_passport');
                                    valid = false;
                                }
                            }
                        }
                    }
                    dispatch(formDataValidatedAction({position, field, valid, error}));
                }
            } else if (validationRule === 'fio_cyrillic') {
                const words = value.split(" ");
                let words2 = words.filter(n => n);
                valid = (words2.length === 3 || words2.length === 4);
                error = (!valid) ? i18n.t('validation_error_fio_cyrillic') : null;
            } else if (validationRule === 'passport') {
                valid = isValidPassportRF(value);
                error = (!valid) ? i18n.t('validation_error_passport_rf') : null;
            } else if (validationRule === 'passport_zagran') {
                valid = isValidPassportZagran(value);
                error = (!valid) ? i18n.t('validation_error_passport_zagran') : null;
            } else if (validationRule === 'child_document') {
                valid = isValidChildDocument(value);
                error = (!valid) ? i18n.t('validation_error_child_document') : null;
            }
            if (valid) {
                dispatch(formDataValidatedAction({position, field, valid, error}));
            }

        }
    }
};

export const FioInputContainer = connect(
    mapStateToPropsFio,
    mapDispatchToProps
)(TextInputView);

export const DateInputContainer = connect(
    mapStateToPropsDate,
    mapDispatchToProps
)(TextInputView);

export const BirthCityInputContainer = connect(
    mapStateToPropsBirthCity,
    mapDispatchToProps
)(TextInputView);

export const PassportInputContainer = connect(
    mapStateToPropsPassport,
    mapDispatchToProps
)(TextInputView);

export const EmailInputContainer = connect(
    mapStateToPropsEmail,
    mapDispatchToProps
)(TextInputView);

export const PhoneInputContainer = connect(
    mapStateToPropsPhone,
    mapDispatchToProps
)(TextInputView);