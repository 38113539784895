import React from 'react';

import i18n from 'vokzal-platform/i18n';

import DatesModal from '../../../../src/components/home/dates_modal'

import { PediaPreLoader } from '../../../../src/components/shared/preloader';

import PediaHeaderImage from '../../../../src/components/pedia/header_image';
import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';
import PediaHeader from '../../../../src/components/pedia/header';

import PediaTabGroup from '../../../../src/components/pedia/tab_group';
import PediaTabContent from '../../../../src/components/pedia/tab_content';

import PediaBusInfo from '../../../../src/components/pedia/bus_info';
import PediaBusRoute from '../../../../src/components/pedia/bus_route';
import PediaBusCalendar from '../../../../src/components/pedia/bus_calendar';

import FooterInfo from '../../../../src/components/shared/footer_info/index';
import FooterLinks from '../../../../src/components/home/footer_links/index';

const PediaBusView = ({ loaded, searchUrlWithParams, onDateSelected }) => (
    <div className="pedia">
        <div className="pedia-block">
          <div className="pedia-header-block">

            <PediaHeaderImage />

            <HeadMenuPedia />

            <PediaHeader type="bus" />

            <PediaTabGroup type="bus" />

            <DatesModal onDateSelected={(date) => onDateSelected(searchUrlWithParams, date)} />
          </div>

          <PediaTabContent type="info" default="bus">
            <PediaBusInfo />
          </PediaTabContent>

          <PediaTabContent type="route">
            <PediaBusRoute />
          </PediaTabContent>

          <PediaTabContent type="schedule">
            <PediaBusCalendar />
          </PediaTabContent>

          <PediaPreLoader show={!loaded} />
        </div>

        <FooterInfo titleText={i18n.t('goodbye')} />
        <FooterLinks />
    </div>
);

module.exports = PediaBusView;