import {connect} from 'react-redux'
import PropTypes from 'prop-types';

import i18n from 'vokzal-platform/i18n'

import { callSupport } from 'vokzal-platform/managers/navigation'

import FooterInfoView from 'vokzal-platform/components/shared/footer_info/view'

FooterInfoView.propTypes = {
  gotQuestions : PropTypes.string.isRequired,
  contactUs : PropTypes.string.isRequired,
  phone : PropTypes.string.isRequired,
  titleText : PropTypes.string.isRequired,
  optionalText : PropTypes.string,
  email : PropTypes.string,
};

const mapStateToProps = state => {
  return {
    gotQuestions: i18n.t('got_questions'),
    contactUs: i18n.t('contact_us'),
    phone: i18n.t('phone'),
    email: i18n.t('email_support'),
    titleText: i18n.t('goodbye')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onPhoneClick: phone => {
      callSupport(dispatch, '+7' + phone);
    }
  }
}


const FooterInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterInfoView)

export default FooterInfoContainer