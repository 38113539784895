export default {
    rest: {
        base_url: "https://test2.vokzal.ru",
        fetchParams: {mode: "no-cors", credentials: "same-origin", headers: null}
    },
    dates: {
        max: 89
    },
    ga: {
        tracker: "UA-50002734-1"
    }
    ,
    rest_api: {
        // base_url: "http://user.vokzal.localhost",
        fetchParams: {headers: null}
    }
}