import React from 'react';

import i18n from 'vokzal-platform/i18n';

import TrainInfo from '../../../../src/components/step2/train_info';
import TrainCarClassSelect from '../../../../src/components/step2/train_car_class_select';

const TrainDetailsBodyView = ({ show, priceTypes, train, onCarClassClick }) => {

  if (!show) {
    return null;
  }

  return (
    <div>
      <TrainInfo trainItem={train} />

      <div className="small">{i18n.t('departure_time_msk_arrival_time_local')}</div>

      <div className="car-class-select mt-2">
        {priceTypes.map((priceType, index) => (
          <TrainCarClassSelect key={index} carCategory={priceType.carCategory} service={priceType.service}
                               onCarClassClick={(carCategory, service, urlParams) => onCarClassClick(carCategory, service, urlParams)} />
        ))}
      </div>
    </div>
  )
};

module.exports = TrainDetailsBodyView;