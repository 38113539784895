import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { postsStoreUrlAction } from '../posts/action';

import BlogPaginationView from 'vokzal-platform/components/blog/pagination/view'

BlogPaginationView.propTypes = {
  prev : PropTypes.string,
  next : PropTypes.string,
  onButtonClick : PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ...state.blog_posts.pagination
});

const mapDispatchToProps = dispatch => (
  {
    onButtonClick: (url, event) => {

      if (!url) {
        return;
      }

      dispatch(postsStoreUrlAction(url));
    },

  }
);


const BlogPaginationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogPaginationView);

export default BlogPaginationContainer