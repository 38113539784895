// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PediaStationBusView from 'vokzal-platform/components/pedia/station_bus/view'
import {stationBusFetchCompleteAction} from "./action";
import {scheduleBusFetchCompleteAction} from "../schedule_bus/action";
import {show_pedia_tab_action} from "../tab_group/action";
import {fillScheduleGroupBusAction} from "../schedule_bus/action";
import {fetch_pedia_station} from "../../../api/fetch_pedia_station";

PediaStationBusView.propTypes = {
  loaded: PropTypes.bool.isRequired,
  urlWithParams: PropTypes.string,
  preview : PropTypes.string,
  description : PropTypes.string,
};

class PediaStationBusContainer extends Component {
  componentDidMount() {
    const { items, onExpand, urlWithParams, onDataLoaded, loaded } = this.props;
    if (!loaded) {
      this.props.fetch(urlWithParams, onDataLoaded);
    }
    this.props.onExpand(items);
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    return <PediaStationBusView {...this.props} />;
  }
}


const mapStateToProps = ({ pedia_station_bus, pedia_schedule_bus }) => {
    const items = pedia_schedule_bus.loaded ? Object.keys(pedia_schedule_bus.schedule_groups) : [];
     return {
       loaded: pedia_station_bus.loaded,
       urlWithParams: pedia_station_bus.urlWithParams,
       preview: pedia_station_bus.preview,
       description: pedia_station_bus.description,
       items
    }
}

const mapDispatchToProps = dispatch => {

    return {
      onDataLoaded : (time_spent, response) => {

        const schedule = response.schedule;
        delete response.schedule;

        const schedule_groups = response.schedule_groups;
        delete response.schedule_groups;

        dispatch(stationBusFetchCompleteAction({ time_spent, response }));
        dispatch(scheduleBusFetchCompleteAction({ time_spent, schedule, schedule_groups, loaded: true }));
        dispatch(show_pedia_tab_action('info'));

        if (Object.keys(schedule_groups).length <= 5) {
          for (let key in schedule_groups) {
            dispatch(fillScheduleGroupBusAction(key));
          }
        }
      },
      onExpand: (items) => {
        if (items.length && items.length <= 5) {
          items.forEach(element => dispatch(fillScheduleGroupBusAction(element)));
        }
      },
      fetch: (urlWithParams, onDataLoaded) => {
        fetch_pedia_station(urlWithParams, onDataLoaded);
      }
    }
}


PediaStationBusContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PediaStationBusContainer)

export default PediaStationBusContainer