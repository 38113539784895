import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import { PediaSalePointCashDeskPropType } from '../../../types';

import PediaSalepointsCashDeskView from 'vokzal-platform/components/pedia/salepoints_cashdesk/view'

PediaSalepointsCashDeskView.propTypes = {
  stationFullName: PropTypes.string.isRequired,
  cashDesks: PropTypes.arrayOf(PediaSalePointCashDeskPropType)
};

const mapStateToProps = state => ({  })

const PediaSalepointsCashDeskContainer = connect(
  mapStateToProps,
)(PediaSalepointsCashDeskView)

export default PediaSalepointsCashDeskContainer