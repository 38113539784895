import React, { Component } from 'react';

import { connect } from 'react-redux'

import UnsubscribeView from 'vokzal-platform/components/user/unsubscribe/view'
import {simple_request} from "../../../api/simple_request";
import { parseGET } from "../../../utils/url_params";

class UnsubscribeContainer extends Component{
  componentDidMount() {
    const params = parseGET()
    console.log(parseGET());
    if (!this.props.loaded) {
      simple_request('/api/subscribe', {
        code: params['code'],
        email: params['email']
      }, () => {},() => {});
    }
  }

  render() {
    const params = parseGET();
    return <UnsubscribeView email={params['email']} {...this.props} />
  }
}

const mapStateToProps = () => {
  return {
    title: 'Вы отписались'

  }
}

UnsubscribeContainer = connect(
  mapStateToProps
)(UnsubscribeContainer);

export default UnsubscribeContainer