import React from 'react';

import i18n from 'vokzal-platform/i18n'

import LastochkaCabin from '../../../../../src/components/step3/lastochka/base/lastochka_cabin';
import LastochkaCabinDelimiter from '../../../../../src/components/step3/lastochka/base/lastochka_cabin_delimiter';
import LastochkaToilet from '../../../../../src/components/step3/lastochka/base/lastochka_toilet';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlassWhiskey} from "@fortawesome/free-solid-svg-icons";
import LastochkaLuggage from '../../../../../src/components/step3/lastochka/base/lastochka_luggage';
import {Pets} from "@material-ui/icons";

const Lastochka2GCarView = ({ carNumber, cabins }) => (
  <div className="train-car-select-seats-map rounded pt-2">

    <div className="train-car-select-seats-map-block train-car-map-text lastocha-service-area rounded small d-flex align-items-center rounded m-2">
      <div className="m-auto">{i18n.t('lastochka_service_area')}</div>
    </div>

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[0]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[1]} />

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[2]} />

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[3]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[4]} />

    <LastochkaCabinDelimiter />

      <div className="sapsan-animals-cabin rounded mt-2 ml-1 mr-1">
          <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[5]} inSubBlock={true} />
          <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[6]} inSubBlock={true} />

          <div className="left">
              <LastochkaToilet className="mt-3" />
              <LastochkaToilet className="mt-3" />
              <LastochkaLuggage className="mt-3" />
          </div>

          <div className="right">
              <LastochkaLuggage className="mt-3" />
              <LastochkaLuggage className="mt-3" />
              <div className="icon-glass mt-2" data-title="Кулер">
                  <FontAwesomeIcon icon={faGlassWhiskey} />
              </div>
          </div>

          <div className="clear"></div>

          <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[7]} inSubBlock={true} />
          <div className="sapsan-animals-cabin-logo">
              <div className="animal-icon" data-title="Разрешён провоз животных">
                  <Pets />
              </div>
          </div>
      </div>

    <LastochkaCabinDelimiter />

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[8]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[9]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[10]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[11]} />

    <LastochkaCabinDelimiter />

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[12]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[13]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[14]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[15]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[16]} />

  </div>
);

module.exports = Lastochka2GCarView;