import React from 'react';
import PropTypes from 'prop-types';

const TrainSeatClassInfoView = ({ description }) => (
  <div className="trains-seats-class-info small mt-2">
    {description}
  </div>
);

TrainSeatClassInfoView.propTypes = {
  description : PropTypes.string.isRequired,
};


module.exports = TrainSeatClassInfoView;