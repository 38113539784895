import { handleActions } from 'redux-actions'

import { VokzalsAndStations } from '../../../../data/home';

import { HOME_SHOW_STATIONS } from './action'

const StationsReducer = handleActions({
    [HOME_SHOW_STATIONS]: (state, action) => {
        return {...state};
    },
}, { items: VokzalsAndStations })

export default StationsReducer