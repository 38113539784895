import {handleActions} from 'redux-actions'

import {
  SHOW_DATES_MODAL_ACTION,
  HIDE_DATES_MODAL_ACTION
} from './action'

const DatesModalReducer = handleActions({
  [SHOW_DATES_MODAL_ACTION]: (state, action) => {
    return {...state, ...action.payload, show: action.meta.show}
  },
  [HIDE_DATES_MODAL_ACTION]: (state, action) => {
    return {...state, show: action.meta.show}
  },
}, {show: false, selected_date: null, selected_input: ''})

export default DatesModalReducer