import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { PediaDefaultImage } from '../../../types';

import PediaHeaderView from 'vokzal-platform/components/pedia/header/view'

PediaHeaderView.propTypes = {
  // title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  reverseColor: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {

  let o = state.pedia_station;
  let type = '';
  let data = {};
  if (ownProps.hasOwnProperty("type")) {
    type = ownProps.type;
    if (ownProps.type === 'train') {
      o = state.pedia_train;
      o.subTitle = o.hasOwnProperty("train") ? o.train.ab : '';
      data.trainType = o.hasOwnProperty("train") ? o.train.type : '';
      data.trainName = o.hasOwnProperty("train") ? o.train.trainName : '';
    } else if (ownProps.type === 'bus') {
      o = state.pedia_bus;
    } else if (ownProps.type === 'raspisanie') {
      o = state.pedia_raspisanie;
    } else if (ownProps.type === 'railroad_details') {
      o = state.pedia_railroad_details;
    } else if (ownProps.type === 'raspisanie_bus') {
      o = state.pedia_raspisanie_bus;
    } else if (ownProps.type === 'salepoint') {
      o = state.pedia_salepoints.station_info;
      const title = 'Станция '+o.stationFullName+' - кассы на вокзале для получения электронных жд билетов';
      const subTitle = '';
      o = {title, subTitle, image: PediaDefaultImage};
    }
  }

  const reverseColor = o.image.caption === "default";
  return { title : o.title, subTitle : o.subTitle, reverseColor, type, data };
}

const PediaHeaderContainer = connect(
  mapStateToProps
)(PediaHeaderView)

export default PediaHeaderContainer