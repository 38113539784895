import React, { Component } from 'react';
import { connect } from 'react-redux'

import OrderView from 'vokzal-platform/components/user/order/view'
import {user_request} from "../../../api/user_request";
import {userOrderFetchCompleteAction, userOrderCheckPassengerAction, userOrderDeleteAction, userOrderCheckErAction} from "../action";


class OrderContainer extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      const order_id = window.location.pathname.replace(/[^\d;]/g, '');
      user_request('/api/order/' + order_id, {}, this.props.onDataLoaded, this.props.onError);
    }
  }

  componentDidUpdate() {
    // console.log('componentDidUpdate !!!!!!!!!');
  }


  render() {
    return <OrderView {...this.props} />;
  }
}

const mapStateToProps = ({user}) => {
  const loaded = Object.keys(user.order).length;
  // console.log('===============', user.order.passengers);
  return {
    title: 'Редактировать',
    loaded: loaded,
    order: loaded ? user.order[0] : null,
    passengers: user.order.passengers,
    r: Math.random()
    // check:
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDataLoaded: (time_spent, response) => {

      dispatch(userOrderFetchCompleteAction({ time_spent, response }));
    },
    onDataLoadedEr: (time_spent, response) => {

      console.log('Проверить статус электронной регистрации', id, time_spent, response)
    },
    onError: (error) => {

    },
    onCheckboxChanged: (id, checked) => {
      // console.log(event)
      dispatch(userOrderCheckPassengerAction({
        id: id,
        checked: checked ? 0 : 1
      }))

    },
    onCheckEr: (id) => {
      user_request('/api/user/orderinfo/' + id, {}, (time_spent, response) => {
        dispatch(userOrderCheckErAction({ time_spent, response, param: 'check' }));
      }, () => {});
    },
    onChangeEr: (id, type, data) => {
      console.log('мена статуса ЭР', id, type, data);
      user_request('/api/user/er/' + id, {reg: type, data: data.toString()}, (time_spent, response) => {
        dispatch(userOrderCheckErAction({ time_spent, response, param: 'change'}));
      }, () => {});
    },
    onEscapeEr: (id) => {
      console.log('отменить ЭР', id)
    },
  }
}

OrderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderContainer)

export default OrderContainer