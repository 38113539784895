import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import i18n from 'vokzal-platform/i18n';

import SapsanWardrobeCabinWithMinibarView from 'vokzal-platform/components/step3/sapsan/base/sapsan_wardrobe_cabin_with_minibar/view'

SapsanWardrobeCabinWithMinibarView.propTypes = {
  wardrobeTitle: PropTypes.string.isRequired,
  minibarTitle: PropTypes.string.isRequired,
  autoHeightWardrobe: PropTypes.bool.isRequired,
  autoHeightMinibar: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({ wardrobeTitle : i18n.t('sapsan_luggage'), minibarTitle : i18n.t('sapsan_minibar'), autoHeightWardrobe : true, autoHeightMinibar : true })


const SapsanWardrobeCabinWithMinibarContainer = connect(
  mapStateToProps
)(SapsanWardrobeCabinWithMinibarView)

export default SapsanWardrobeCabinWithMinibarContainer