import 'swiper/dist/css/swiper.css';

import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'vokzal-platform/i18n';

import TrainCarNumber from '../../../../src/components/step3/train_car_number';

const TrainCarView = ({ cars, swiperEnabled, categories, onTypeChange, carCategory, prices }) => {
  let classes = "select-category font-medium-light ml-2 mr-2 mr-md-0 mb-2 mb-md-0 p-2 ";

  return (
  <div className="train-car mt-2">
    <div className="train-car-select">
      <div className="train-car-select-header font-medium-light d-flex justify-content-between align-items-end p-2">
        <div className="train-car-select-title font-weight-bold">{i18n.t('train_car')}</div>
        <div className="train-car-select-info small">Нумерация с головы поезда</div>
      </div>
      <div className="train-car-select-numbers-block">
        {categories.size > 1 ? <div className={classes + (!carCategory ? 'active' : '')} onClick={() => onTypeChange('')}>{i18n.t('any')}</div> : ''}
        {categories.has('О') ? <div className={classes + (carCategory === 'О' ? 'active' : '')} onClick={() => onTypeChange('О', prices)}>{i18n.t('car_shared_name')}</div> : ''}
        {categories.has('С') ? <div className={classes + (carCategory === 'С' ? 'active' : '')} onClick={() => onTypeChange('С', prices)}>{i18n.t('car_seat_name')}</div> : ''}
        {categories.has('П') ? <div className={classes + (carCategory === 'П' ? 'active' : '')} onClick={() => onTypeChange('П', prices)}>{i18n.t('car_platskart_name')}</div> : ''}
        {categories.has('К') ? <div className={classes + (carCategory === 'К' ? 'active' : '')} onClick={() => onTypeChange('К', prices)}>{i18n.t('car_kupe_name')}</div> : ''}
        {categories.has('Л') ? <div className={classes + (carCategory === 'Л' ? 'active' : '')} onClick={() => onTypeChange('Л', prices)}>{i18n.t('car_sv_name')}</div> : ''}
        {categories.has('М') ? <div className={classes + (carCategory === 'М' ? 'active' : '')} onClick={() => onTypeChange('М', prices)}>{i18n.t('car_soft_name')}</div> : ''}

        <div className={((swiperEnabled) ? 'swiper-container' : '') + " train-car-select-numbers mr-2"}>
          <div className="swiper-wrapper">
            {
              cars.map((item, index) => (
                <TrainCarNumber key={index} swiperEnabled={swiperEnabled} {...item} />
              ))
            }
          </div>

          <div className="swiper-button-prev swiper-button-disabled pt-2">
            <div className="font-medium-light text-center">...</div>
          </div>
          <div className="swiper-button-next swiper-button-disabled pt-2">
            <div className="font-medium-light text-center">...</div>
          </div>
        </div>
      </div>

    </div>
  </div>
)};

TrainCarView.propTypes = {
  swiperEnabled : PropTypes.bool.isRequired,
  cars : PropTypes.arrayOf(
    PropTypes.shape({
      categoryFakeID : PropTypes.number.isRequired,
      carNumber : PropTypes.string.isRequired,
      isActive : PropTypes.bool.isRequired
    })
  ).isRequired,
};

module.exports = TrainCarView;