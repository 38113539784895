import React from 'react';

const PreLoaderView = ({ show, className }) => {

  if (!show) {
    return null;
  }

  return (
    <div id="preloader" className={(className  || '') + " preloader d-flex justify-content-center pt-4"}>
      <style>{"\
                .background-general{\
                  background:transparent;\
                }\          @media (min-width: 768px){     .step2 .time-text{\
                  display:none;\
                }}\
              "}</style>
      <div className="preloader-image mt-4 mb-4" />
    </div>
  )
};

module.exports = PreLoaderView;