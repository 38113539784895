
import React, {Fragment} from 'react';

import i18n from 'vokzal-platform/i18n';

import SeatedCabin from '../../../../src/components/step3/seated_cabin';

const SeatedMapView = ({ carNumber, cabins, carSelected }) => {
    const twoStorey = carSelected.twoStorey;
    return (
        <div>

            {twoStorey ?  <Fragment>
                            <div className="floor-title second-floor">{i18n.t('second_floor')}</div>
                            <div className="train-car-select-seats-map-horizontal row">
                                <div className="info">
                                    <p className="toilet">{i18n.t('toilet')}</p>
                                    <p className="conductor">
                                        {i18n.t('conductor_place').replace(" ", "\n")}
                                    </p>
                                </div>

                                <div className="d-flex seat-map">
                                    {
                                        cabins.map((cabin, index) => (
                                            <SeatedCabin key={index} carNumber={carNumber} title={i18n.t('car_map_kupe') + ' ' + (index + 1)} cabinInfo={cabin}  secondFloor={twoStorey} twoStorey={twoStorey}/>
                                        ))
                                    }
                                </div>

                                <div className="info">
                                    <p className="toilet">{i18n.t('toilet')}</p>
                                </div>
                            </div>

                            <div className="train-car-select-seats-map rounded p-2 d-block d-lg-none">
                                <div className="train-car-map-service-block d-flex align-items-end flex-column">
                                    <div className="train-car-select-seats-map-block train-car-map-text train-car-map-toilet rounded small text-center w-40 p-1 mb-2">{i18n.t('toilet')}</div>
                                    <div className="train-car-select-seats-map-block train-car-map-text train-car-map-conductor-place rounded small text-capitalize text-center w-60 mb-2 p-1">
                                        {i18n.t('conductor_place').replace(" ", "\n")}
                                    </div>
                                </div>

                                <div className="d-flex flex-column mt-4 mb-4">
                                    {
                                        cabins.map((cabin, index) => (
                                            <SeatedCabin key={index} carNumber={carNumber} title={i18n.t('car_map_kupe') + ' ' + (index + 1)} cabinInfo={cabin}  secondFloor={twoStorey} twoStorey={twoStorey}/>
                                        ))
                                    }
                                </div>

                                <div className="train-car-map-service-block-bottom d-flex justify-content-between">
                                    <div className="train-car-select-seats-map-block train-car-map-text train-car-map-smoking-area rounded small text-center w-50 pt-3 pl-4 pr-4 pb-3">{i18n.t('smoking_area')}</div>
                                    <div className="train-car-select-seats-map-block train-car-map-text train-car-map-toilet rounded small text-capitalize text-center w-35">
                                        {i18n.t('toilet')}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
           : ''}

            {twoStorey ? <div className="floor-title first-floor">{i18n.t('first_floor')}</div> : ''}
            <div className="train-car-select-seats-map-horizontal row">
                <div className="info">
                    <p className="toilet">{i18n.t('toilet')}</p>
                    <p className="conductor">
                        {i18n.t('conductor_place').replace(" ", "\n")}
                    </p>
                </div>

                <div className="d-flex seat-map">
                    {
                        cabins.map((cabin, index) => (
                            <SeatedCabin key={index} carNumber={carNumber} title={i18n.t('car_map_kupe') + ' ' + (index + 1)} cabinInfo={cabin} twoStorey={twoStorey}/>
                        ))
                    }
                </div>

                <div className="info">
                    <p className="toilet">{i18n.t('toilet')}</p>
                </div>
            </div>

            <div className="train-car-select-seats-map rounded p-2 d-block d-lg-none">
                <div className="train-car-map-service-block d-flex align-items-end flex-column">
                    <div className="train-car-select-seats-map-block train-car-map-text train-car-map-toilet rounded small text-center w-40 p-1 mb-2">{i18n.t('toilet')}</div>
                    <div className="train-car-select-seats-map-block train-car-map-text train-car-map-conductor-place rounded small text-capitalize text-center w-60 mb-2 p-1">
                        {i18n.t('conductor_place').replace(" ", "\n")}
                    </div>
                </div>

                <div className="d-flex flex-column mt-4 mb-4">
                    {
                        cabins.map((cabin, index) => (
                            <SeatedCabin key={index} carNumber={carNumber} title={i18n.t('car_map_kupe') + ' ' + (index + 1)} cabinInfo={cabin} twoStorey={twoStorey}/>
                        ))
                    }
                </div>

                <div className="train-car-map-service-block-bottom d-flex justify-content-between">
                    <div className="train-car-select-seats-map-block train-car-map-text train-car-map-smoking-area rounded small text-center w-50 pt-3 pl-4 pr-4 pb-3 invisible">{i18n.t('smoking_area')}</div>
                    <div className="train-car-select-seats-map-block train-car-map-text train-car-map-toilet rounded small text-capitalize text-center w-35">
                        {i18n.t('toilet')}
                    </div>
                </div>
            </div>
        </div>

    )};

module.exports = SeatedMapView;