// @flow
import PropTypes from 'prop-types';
import { SeatNumbersSeatedPropType } from '../../../../../src/types/';

import SapsanEconomMapView from 'vokzal-platform/components/step3/sapsan/sapsan_econom_map/view'

SapsanEconomMapView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersSeatedPropType).isRequired
};


const SapsanEconomMap = SapsanEconomMapView

export default SapsanEconomMap