// @flow

import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import i18n from 'vokzal-platform/i18n'

import { showDatesModalAction } from '../../../components/home/dates_modal/action'
import { setDateFromAction, setDateToAction, setDateFromInfoAction } from '../../../components/home/search_form/action'

import { SearchFormFields } from '../../../types'

import DateInputView from 'vokzal-platform/components/home/date_input/view'

DateInputView.propTypes = {
  id: PropTypes.string.isRequired,
  deletable: PropTypes.bool.isRequired,
  is_disabled: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onDateClick: PropTypes.func.isRequired,
  onClearClick: PropTypes.func.isRequired
};

const mapStateToPropsFrom = (state, ownProps) => {
  // const date = state.search.date_from;
  const date = state.search.date_from_info;
  return {
      id: "date-select-from",
      deletable : (ownProps.hasOwnProperty('deletable')) ? ownProps.deletable : false,
      is_disabled : state.search.inputs_disabled,
      name: SearchFormFields.FROM_DATE,
      label: i18n.t('date_from'),
      value: date ? date.format('DD.MM.YYYY') : '',
      show: state.search.show_date_from
  }
}

const mapStateToPropsTo = (state, ownProps) => {
  return {
      id: "date-select-to",
      deletable : (ownProps.hasOwnProperty('deletable')) ? ownProps.deletable : false,
      is_disabled : state.search.inputs_disabled,
      name: SearchFormFields.TO_DATE,
      label: i18n.t('date_to'),
      value: (state.search.date_to) ? state.search.date_to.format('DD.MM.YYYY') : '',
      show: state.search.show_date_to
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDateClick: (selected_input, date) => {
        dispatch(showDatesModalAction({selected_input, date}))
    },
    onClearClick: (selected_input) => {

      switch (selected_input) {
        case SearchFormFields.FROM_DATE:
          dispatch(setDateFromAction(null));
          dispatch(setDateFromInfoAction(null));
          break;

        case SearchFormFields.TO_DATE:
          dispatch(setDateToAction(null)); break;

      }
    }
  }
}

export const FromDateContainer = connect(
  mapStateToPropsFrom,
  mapDispatchToProps
)(DateInputView)

export const ToDateContainer = connect(
    mapStateToPropsTo,
  mapDispatchToProps
)(DateInputView)
