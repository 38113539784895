import React from 'react';
import { Modal } from 'react-bootstrap';

import CountriesModalRow from '../../../../src/components/step4/countries_modal_row';
import BootstrapInput from '../../base/bootstrap_input';

import i18n from 'vokzal-platform/i18n'

const CountriesModalView = ({ show, ticket_number, selected_country, input_value, countries, onItemClick, onCloseClick, onInputChange }) => (
  <Modal show={show} animation={false} dialogClassName="m-0 h-100 resp-search-modal" backdropClassName="show resp-search-modal">
    <Modal.Header>
      <div className="d-flex flex-column">
        <div className="p-0 d-flex justify-content-between">
          <Modal.Title id="contained-modal-title-lg" componentClass="h5">{i18n.t('select_country')}</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseClick}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="p-0">
          <BootstrapInput name="station_name" className="p-0 pb-2" autoFocus={true} value={input_value} onChange={onInputChange} autocomplete="off" />
        </div>
      </div>

    </Modal.Header>
    <Modal.Body bsClass="modal-body pt-0">

      <div className="list-group">
        {
          countries.map(function(m, index){
            return (<CountriesModalRow key={index} title={m.title} onClick={() => onItemClick(ticket_number, m.value, m.title)} />);
          })
        }
      </div>
    </Modal.Body>
  </Modal>
);

module.exports = CountriesModalView;