import React from 'react';

import SapsanCabin from '../../../../../src/components/step3/sapsan/base/sapsan_cabin';
import SapsanDoubleLuggageCabin from '../../../../../src/components/step3/sapsan/base/sapsan_double_luggage_cabin';
import SapsanWardrobeCabinWithSeats from '../../../../../src/components/step3/sapsan/base/sapsan_wardrobe_cabin_with_seats';
import SapsanWardrobeCabinWithToiletAndSeats from '../../../../../src/components/step3/sapsan/base/sapsan_wardrobe_cabin_with_toilet_and_seats';

const Sapsan2RClassMapView = ({ carNumber, cabins }) => (
  <div className="train-car-select-seats-map rounded pt-2">
    <SapsanDoubleLuggageCabin carNumber={carNumber} />

    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[0]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[1]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[2]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[3]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[4]} />
      <div className="table table-left"></div>
      <div className="table table-right"></div>
      <div className="clear"></div>
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[5]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[6]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[7]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[8]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[9]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[10]} />

    <SapsanWardrobeCabinWithSeats carNumber={carNumber} cabinInfo1={cabins[11]} cabinInfo2={cabins[12]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[13]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[14]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[15]} />
      <div className="table table-left"></div>
      <div className="table table-right"></div>
      <div className="clear"></div>
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[16]} />

      <SapsanWardrobeCabinWithToiletAndSeats carNumber={carNumber} cabinInfo={cabins[17]} />
  </div>
);

module.exports = Sapsan2RClassMapView;