import React, {lazy, Suspense} from 'react';

import i18n from 'vokzal-platform/i18n';

import { PediaPreLoader } from '../../../../src/components/shared/preloader';

import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';

import PediaStationsListGroup from '../../../../src/components/pedia/stations_list_group';
import PediaRegionMap from '../../../../src/components/pedia/region_map';

import SearchForm from '../../../../src/components/shared/search_form';

const News = lazy(() => import('../../../../src/components/home/news/index'));
const FooterInfo = lazy(() => import('../../../../src/components/shared/footer_info/index'));
const FooterLinks = lazy(() => import('../../../../src/components/home/footer_links/index'));

const PediaRegionView = ({ loaded, title, country, groups, onLinkClick }) => (
    <div className="pedia">
        <div className="pedia-block">
          <div className="pedia-header-block">

            <HeadMenuPedia />

            <div className={"pedia-header-short d-flex align-items-start flex-column ml-3 mr-3 pedia-header-dark mt-4"}>
              <h1 className="h4 mb-2 font-medium-light font-weight-normal text-uppercase">{title} - станции и вокзалы</h1>
            </div>
              <SearchForm />
          </div>

            <div className="region">
                <PediaStationsListGroup groups={groups} onLinkClick={onLinkClick} />
            </div>


          <PediaRegionMap />

          <PediaPreLoader show={!loaded} />
        </div>

        <Suspense fallback={<div>Загрузка...</div>}>
            <FooterInfo titleText={i18n.t('goodbye')} />
        </Suspense>

        <Suspense fallback={<div>Загрузка...</div>}>
            <FooterLinks />
        </Suspense>

        <Suspense fallback={<div>Загрузка...</div>}>
            <News />
        </Suspense>
    </div>
);

module.exports = PediaRegionView;