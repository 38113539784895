import React from 'react';

import StrijCabin from '../../../../../src/components/step3/strij/base/strij_cabin';
import StrijToiletsCabin from '../../../../../src/components/step3/strij/base/strij_toilets_cabin';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlassWhiskey, faSuitcaseRolling} from "@fortawesome/free-solid-svg-icons";

const StrijFirstClassMapView = ({ carNumber, cabins }) => (
  <div className="train-car-select-seats-map rounded pt-2">

          <div className="left">
              <div className="icon-glass baggage" data-title="Багаж">
                  <FontAwesomeIcon icon={faSuitcaseRolling} />
              </div>
          </div>

          <div className="right">
              <div className="icon-glass" data-title="Кулер">
                  <FontAwesomeIcon icon={faGlassWhiskey} />
              </div>
          </div>

      <br />
      <br />

      <StrijCabin carNumber={carNumber} cabinInfo={cabins[0]} />
      <StrijCabin carNumber={carNumber} cabinInfo={cabins[1]} />
      <StrijCabin carNumber={carNumber} cabinInfo={cabins[2]} />
      <StrijCabin carNumber={carNumber} cabinInfo={cabins[3]} />
      <StrijCabin carNumber={carNumber} cabinInfo={cabins[4]} />
      <StrijCabin carNumber={carNumber} cabinInfo={cabins[5]} />
      <StrijCabin carNumber={carNumber} cabinInfo={cabins[6]} />

      <StrijToiletsCabin />
  </div>
);

module.exports = StrijFirstClassMapView;