import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import {
  canSubmitTicketReturnAction, errorTicketReturnDetailsAction, loadedTicketReturDetailsnAction,
  showTicketReturnDetailsnAction, fetchTicketReturnDetailsAction

} from '../return_form/action';

import { ticket_return } from '../../../api/fetch_ticket_return'
import { urlParamsTicketReturn } from '../../../utils/url_params'

import { showTicketReturnConfirmation } from 'vokzal-platform/managers/navigation'


import CheckButtonView from 'vokzal-platform/components/ticket_return/check_button/view'

CheckButtonView.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  urlWithParams: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  onDataLoaded: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

const mapStateToProps = state => {

  const tr = state.ticket_return;

  let isEnabled = tr.can_submit_form && tr.is_order_number_valid && tr.is_mobile_phone_valid;
  isEnabled = isEnabled && tr.order_number !== '' && tr.mobile_phone !== '';

  const urlWithParams = (isEnabled) ? urlParamsTicketReturn(tr) : '';
  return { isEnabled, urlWithParams };
};

const mapDispatchToProps = dispatch => {
  return {
    onButtonClick: (isEnabled, urlWithParams, onDataLoaded, onError) => {
      if (isEnabled) {
        dispatch(fetchTicketReturnDetailsAction())
        dispatch(canSubmitTicketReturnAction(false))

        ticket_return(urlWithParams, onDataLoaded, onError);
      }
    },
    onDataLoaded: (time_spent, response, urlWithParams) => {
      dispatch(loadedTicketReturDetailsnAction({ time_spent, response }));
      dispatch(showTicketReturnDetailsnAction());
      dispatch(canSubmitTicketReturnAction(true))

      showTicketReturnConfirmation(dispatch, urlWithParams);
    },
    onError: (error) => {
      dispatch(canSubmitTicketReturnAction(true))
      dispatch(errorTicketReturnDetailsAction(error));
    }
  }
};

const CheckButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckButtonView);

export default CheckButtonContainer