// @flow
import PropTypes from 'prop-types';
import { SeatNumbersLastochkaPropType } from '../../../../../types';

import LastochkaCabinView from 'vokzal-platform/components/step3/lastochka/base/lastochka_cabin/view'

LastochkaCabinView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  cabinInfo : SeatNumbersLastochkaPropType
};

const LastochkaCabin = LastochkaCabinView

export default LastochkaCabin