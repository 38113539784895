import React from 'react';

const PediaRailroadDetailsPopularRoutesView = ({ title, popular_routes, onLinkClick }) => (

  <div className="page-section mt-4">
    <h3 className="section-title">{title}</h3>
    <div>
      {popular_routes.map((p, index) => (
        <div className="mt-2" key={index}>
          <a href={p.url} title={p.title} onClick={() => { onLinkClick(p) }}>{p.text}</a>
        </div>
      ))}
    </div>
  </div>
);

module.exports = PediaRailroadDetailsPopularRoutesView;