import React from 'react';

import SeatedCabinPlace from '../../../../../../src/components/step3/seated_cabin_place';
import SeatedSidePlace from '../../../../../../src/components/step3/seated_side_place';

//TODO seated or side-seated
const LastochkaCabinPlaceView = ({ carNumber, type, seatNumber, isFront, state, onSeatClick, className }) => (
  (
    (type === 'std')
      ? <SeatedCabinPlace carNumber={carNumber} seatNumber={seatNumber} isFront={isFront} state={state} onSeatClick={onSeatClick} className={className} />
      : <SeatedSidePlace  carNumber={carNumber} seatNumber={seatNumber} isFront={isFront} state={state} onSeatClick={onSeatClick} className={className} />
  )
)

module.exports = LastochkaCabinPlaceView;