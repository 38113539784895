// @flow
import { connect } from 'react-redux'

import PropTypes from 'prop-types';
import { SearchResultTrainPropType } from '../../../../src/types/';

import TrainInfoView from 'vokzal-platform/components/step2/train_info/view'

TrainInfoView.propTypes = {
  trainItem : SearchResultTrainPropType.isRequired,
  trainLogo : PropTypes.oneOf(['sapsan', 'lastochka', 'strij'])
};

const mapStateToProps = (state, ownProps) => {

  const trainLogo = (ownProps.trainItem.type !== 'usual') ? ownProps.trainItem.type : null

  return { trainLogo }
}


const TrainInfoContainer = connect(
  mapStateToProps
)(TrainInfoView)

export default TrainInfoContainer