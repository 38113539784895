// @flow
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { webAndroidTextMaskFix } from 'vokzal-platform/managers/navigation'
import TicketReturnResultView from 'vokzal-platform/components/ticket_return/result/view'

TicketReturnResultView.propTypes = {
  orderID: PropTypes.number.isRequired
};

const mapStateToProps = state => {
    const orderID = state.ticket_return.details.orderID;
    return { orderID }
};

const TicketReturnResultContainer = connect(
    mapStateToProps
)(TicketReturnResultView)

export default TicketReturnResultContainer