import React, {lazy, Suspense} from 'react';

import i18n from 'vokzal-platform/i18n';

import { PediaPreLoader } from '../../../../src/components/shared/preloader';

import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';

import PediaStationsListGroup from '../../../../src/components/pedia/stations_list_group';

const FooterInfo = lazy(() => import('../../../../src/components/shared/footer_info/index'));
const FooterLinks = lazy(() => import('../../../../src/components/home/footer_links/index'));

const PediaRailroadView = ({ loaded, title, country, groups, onLinkClick }) => (
    <div className="pedia">
        <div className="pedia-block">
          <div className="pedia-header-block">

            <HeadMenuPedia />

            <div className={"pedia-header-short d-flex align-items-start flex-column ml-3 mr-3 pedia-header-dark mt-4"}>
              <h1 className="h4 mb-2 font-medium-light font-weight-normal text-uppercase">Железные дороги России, СНГ и стран Прибалтики</h1>
            </div>
          </div>

          <div className="pedia-description p-3">
            <p>На сайте Vokzal.ru вы можете найти наиболее обширную информацию о сетях железнодорожных путей, связывающих
              города и населенные пункты России, стран и СНГ и Прибалтики: их названия, общая протяженность, маршруты,
              проходящие по конкретным путям, станции и вокзалы, через которые проходят поезда дальнего следования.</p>
            <p>Здесь же вы найдете сведения о самых популярных поездах и самые последние новости, касающиеся железных дорог
              и путешествий по ним.</p>
            <p>Заказать билеты на поезда дальнего следования можно на сайте Вокзал.ру за 2 минуты.</p>
          </div>


          <PediaStationsListGroup groups={groups} onLinkClick={onLinkClick} />

          <PediaPreLoader show={!loaded} />
        </div>

        <Suspense fallback={<div>Загрузка...</div>}>
            <FooterInfo titleText={i18n.t('goodbye')} />
        </Suspense>

        <Suspense fallback={<div>Загрузка...</div>}>
            <FooterLinks />
        </Suspense>
    </div>
);

module.exports = PediaRailroadView;