import React from 'react';
import { connect } from 'react-redux'

import ChangePasswordView from 'vokzal-platform/components/user/change_password/view'
import {userSetValueAction} from "../action";
import {user_request} from "../../../api/user_request";

const mapStateToProps = ({user}) => {
  return {
    // title: 'Редактировать',
    // p: user.profile
    password_old: user.password_old,
    password_old_error: user.password_old_error,
    password: user.password,
    password_error: user.password_error,
    password2: user.password2,
    password2_error: user.password2_error,
    repeat_error: user.repeat_error,
    error: user.error,
    success: user.success_activation,
    password_enabled: !user.password_enabled
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onPasswordOldChanged: (password_old) => {
      dispatch(userSetValueAction({password_old}))
    },
    onPasswordChanged: (password) => {
      dispatch(userSetValueAction({password}))
    },
    onPassword2Changed: (password2) => {
      dispatch(userSetValueAction({password2}))
    },
    onDataLoaded : (time_spent, response) => {
      console.log(response);
      dispatch(userSetValueAction({success_activation: true, password: '', password2: '', error: ''}))
    },
    onError : (error) => {
      dispatch(userSetValueAction({error}))
    },
    onFocusReadonly: () => {
      dispatch(userSetValueAction({password_enabled: true}))
    },
    onSubmit: (password_old, password, password2, onDataLoaded, onError) => {
      // console.log('onSubmit', email, password);
      // password = password.trim();
      // password2 = password2.trim();

      let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d](.*){8,}$/;


      if (!password_old) {
        dispatch(userSetValueAction({password_old_error: 'Укажите текущий пароль'}))
      } else {
        dispatch(userSetValueAction({password_old_error: ''}))
      }

      if (!password) {
        // console.log('onSubmit', ' пустое поле пароль');
        dispatch(userSetValueAction({password_error: 'Укажите пароль'}))
      } else if (!re.test(password)) {
        dispatch(userSetValueAction({password_error: 'Днина пароля не менее 8 символов, включая строчные, заглавные буквы и цифры'}))
      } else {
        dispatch(userSetValueAction({password_error: ''}))
      }

      if(password !== password2) {
        // console.log('onSubmit', ' пустое поле пароль');
        dispatch(userSetValueAction({repeat_error: 'Пароли не совпадают'}))
      } else {
        dispatch(userSetValueAction({repeat_error: ''}))
      }
      if (password_old && password === password2 && re.test(password)) {
        user_request('/api/change', {password, old_password: password_old}, onDataLoaded, onError);
      }


    }
  }
}

const ChangePasswordContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePasswordView)

export default ChangePasswordContainer