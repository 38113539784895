import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import {userSetProfileValueAction, userSetValueAction} from "../action";

import EditView from 'vokzal-platform/components/user/edit/view'
import {user_request} from "../../../api/user_request";

const mapStateToProps = ({user}) => {
  return {
    title: 'Редактировать',
    p: user.profile,
    error: user.edit_error,
    success: user.edit_success,
    // p: {
    //   "status": "OK",
    //   "firstname": "Настя",
    //   "middlename": "fffffff",
    //   "surname": "yyyy",
    //   "phone": "77777",
    //   "birth_date": "12.12.1993",
    //   "email": "ai@vokzal.ru"
    // }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onChanged: (value, old, inputElement) => {
      let attribute = inputElement.getAttribute("name");
      dispatch(userSetProfileValueAction({[attribute]: value}))
    },
    onDataLoaded : (time_spent, response, post) => {
      dispatch(userSetValueAction({edit_success: 'Личные данные успешно сохранены', edit_error: ''}))
      // console.log('Данные пользователя получены ', time_spent);
    },
    onError : (error) => {
      dispatch(userSetValueAction({edit_error: 'Произошла ошибка'}))
    },
    onSubmit: (profile, onDataLoaded, onError) => {
      user_request('/api/profile/edit', {...profile}, onDataLoaded, onError);
    }
  }
}

const EditContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditView)

export default EditContainer