import React from 'react';
import { connect } from 'react-redux'

import { select_category } from '../train_seats/action';
import { clear_selected_seat } from '../action';

import TrainSeatClassView from 'vokzal-platform/components/step3/train_seat_class/view';
import {step2SelectCarTypeAction} from "../../step2/train_details/action";
import queryString from "query-string";


const mapStateToProps = state => {
  const queryObject = queryString.parse(location.search);
    return {
      sapsan: queryObject.sapsan,
      twoStorey: state.step3_categories.car_selected.twoStorey,
      priceDiapason: state.step3_categories.car_category_selected.price
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onSeatClassClick : (seatClass, sapsan) => {
      // передать сапсан в параметрах
      if (!seatClass.isActive) {
        dispatch(select_category(seatClass));
        dispatch(clear_selected_seat());
        const service = sapsan === 1 ? seatClass.code : null;
        dispatch(step2SelectCarTypeAction({ carCategory: seatClass.type, service, prices:  {
            adult: seatClass.price,
            child: Math.round(seatClass.price / 2)
          } }));
      }
    }
  }
};

const TrainSeatClassContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainSeatClassView);

export default TrainSeatClassContainer