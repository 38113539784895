import React from 'react';

import i18n from 'vokzal-platform/i18n';

import PaymentTypeOption from '../../../../src/components/step4/payment_type_option';
import OrderButton from '../../../../src/components/step4/order_button';

import PersonModal from '../../../../src/components/step4/person_modal';


const PaymentTypeView = ({ totalPrice, paymentTypes }) => (

      <div className="payment-type page-section rounded">
        <div className="row payment-type-total-price rounded-top text-center font-medium-light">
          <div className="col-md-4 font-weight-medium">{i18n.t('total_price')}</div>
          <div className="col-md-4 h4 font-weight-bold mb-0">{totalPrice} руб.
              {/*<p className="price-description small">{i18n.t('price_description')}</p>*/}
          </div>
          <div className="col-md-4 small">
              {i18n.t('tax_included')}
          </div>
        </div>
        <div className="section-title">{i18n.t('payment_type')}</div>
          {
            paymentTypes.map(
              (paymentType, index) => (
                <PaymentTypeOption key={index} paymentType={paymentType} />
            ))
          }
        <OrderButton />
          <PersonModal text={i18n.t('person_agreement')} className="person-agreement font-medium-light small"/>
      </div>

);

module.exports = PaymentTypeView;