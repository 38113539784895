import moment from 'moment';

import { vokzal_config } from '../config';

import { post_process_schedule } from './fetch_pedia_station';

export const fetch_pedia_schedule = (urlWithParams, onDataLoaded) => {
  const requested_at = moment();

  fetch(vokzal_config.rest.base_url + urlWithParams, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {
      //TODO error handling

      responseJson.schedule = post_process_schedule(responseJson.schedule);

      onDataLoaded(moment().diff(requested_at), responseJson);
    });
};
