import {make_features} from "../../../../src/api/fetch_pedia_region";
import { format_footer_links } from "../../../../src/api/home";

import moment from 'moment'


export const railroadDetailsInitialStatePlatform = () => {
  let initialState = {}

  if (typeof pedia_railroad_details_data !== 'undefined') {

    const today = moment();

    initialState.currentTab = 'info';

    initialState.loaded = true;

    initialState.station_id = pedia_railroad_details_data.station_id;
    initialState.title = pedia_railroad_details_data.title;
    initialState.subTitle = 'расписание отправления и прибытия поездов на '+today.year()+' год';
    initialState.description = pedia_railroad_details_data.description;
    initialState.route = pedia_railroad_details_data.route;
    initialState.features = make_features(pedia_railroad_details_data.route);
  }

  if (typeof pedia_railroad_popular_stations !== 'undefined') {
    initialState.popular_stations = pedia_railroad_popular_stations;
  }

  if (typeof pedia_railroad_popular_trains !== 'undefined') {
    initialState.popular_trains = pedia_railroad_popular_trains;
  }

  if (typeof pedia_railroad_popular_routes !== 'undefined') {
    initialState.popular_routes = format_footer_links(pedia_railroad_popular_routes);
  }

  return initialState;
}