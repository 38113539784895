// @flow
import PropTypes from 'prop-types';
import { SeatNumbersLastochkaPropType } from '../../../../../src/types/';

import LastochkaFirstCarView from 'vokzal-platform/components/step3/lastochka/lastochka_first_car_map/view'

LastochkaFirstCarView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersLastochkaPropType).isRequired
};

const LastochkaFirstCar = LastochkaFirstCarView

export default LastochkaFirstCar