// @flow
import { handleActions } from 'redux-actions'

import { STEP2_ALTERNATIVE_CHANGE_DATE_ACTION, STEP2_ALTERNATIVE_SET_BUS_FORM_ACTION, STEP2_ALTERNATIVE_SET_TRAIN_FORM_ACTION } from './action'

import { step2AlternativeInitialStatePlatform } from 'vokzal-platform/components/step2/alternative/reducer';

let step2AlternativeInitialState = {
  error: null,
  data_loaded: false,
  train_search_form: null,
  bus_search_form: null,
}
step2AlternativeInitialState = Object.assign({}, step2AlternativeInitialState, step2AlternativeInitialStatePlatform())

const Step2AlternativeReducer = handleActions({
  [STEP2_ALTERNATIVE_CHANGE_DATE_ACTION]: (state, action) => {

    let t = state.train_search_form;
    let b = state.bus_search_form;

    if (t !== null) {
      t.departureDate = action.payload;
    }

    if (b !== null) {
      b.departureDate = action.payload;
    }

    return { ...state  }
  },
  [STEP2_ALTERNATIVE_SET_BUS_FORM_ACTION]: (state, action) => {
    return { ...state, bus_search_form: action.payload }
  },
  [STEP2_ALTERNATIVE_SET_TRAIN_FORM_ACTION]: (state, action) => {
    return { ...state, train_search_form: action.payload }
  },
}, step2AlternativeInitialState)

export default Step2AlternativeReducer;