import { handleActions } from 'redux-actions'


import { homeExtraTextInitialStatePlatform } from 'vokzal-platform/components/home/extra_text/reducer';

let initialState = { extra_html: null };

initialState = Object.assign({}, initialState, homeExtraTextInitialStatePlatform())

const HomeExtraTextReducer = handleActions({

}, initialState)

export default HomeExtraTextReducer