import React from 'react';

import PageSection from '../../home/base/section/view';
import SectionTitle from '../../home/base/section_title/view';
import SectionRow from '../../home/base/section_row/view';

const FooterInfo = ({ gotQuestions, contactUs, phone, email, titleText, optionalText, onPhoneClick }) => (
    <PageSection className="footer-info pb-4">
        <SectionTitle className="text-center">{titleText}</SectionTitle>

        <SectionRow>
          <div onClick={() => { onPhoneClick(phone) }} title="Позвонить">
            <div className="text-center font-weight-medium">{gotQuestions}</div>
            <div className="text-center small">{contactUs}</div>
            <div className="phone h3 text-center mt-2">{phone}</div>
          </div>
        </SectionRow>
        <div className="pt-0">
            <div className="h3 text-center cursor-default">{email}</div>
        </div>
    </PageSection>
);

FooterInfo.propTypes = {
};

module.exports = FooterInfo;