import { handleActions } from 'redux-actions';
import {
    REVIEWS_FETCH_COMPLETE_ACTION,
} from './action'

const reviewsReducer = handleActions({
    [REVIEWS_FETCH_COMPLETE_ACTION]: (state, action) => {
        return { ...state, loaded: true, ...action.payload  }
    },
}, {
    error: null,
    loaded: false
})

export default reviewsReducer





