import { createAction } from 'redux-actions';

export const STEP5_SHOW_ACTION            = 'STEP5/PAGE/SHOW'
export const step5ShowAction              = createAction(STEP5_SHOW_ACTION);

export const STEP5_RESERVATION_COMPLETE_ACTION = 'STEP5/RESERVATION/COMPLETE'
export const reservationCompleteAction         = createAction(STEP5_RESERVATION_COMPLETE_ACTION);

export const STEP5_SEAT_CHANGED_ACTION    = 'STEP5/RESERVATION/SEAT_CHANGED'
export const seatChangedAction            = createAction(STEP5_SEAT_CHANGED_ACTION);

export const STEP5_SET_TIMER_ACTION       = 'STEP5/TIMER/SET'
export const setTimerAction               = createAction(STEP5_SET_TIMER_ACTION);

export const STEP5_DECREMENT_TIMER_ACTION = 'STEP5/TIMER/DECREMENT'
export const decrementTimerAction         = createAction(STEP5_DECREMENT_TIMER_ACTION);

export const SHOW_PRELOADER_ACTION        = 'STEP5/PRELOADER/SHOW'
export const showPreloaderAction          = createAction(SHOW_PRELOADER_ACTION, (show) => show, () => ({ show : true }))

export const HIDE_PRELOADER_ACTION        = 'STEP5/PRELOADER/HIDE'
export const hidePreloaderAction          = createAction(HIDE_PRELOADER_ACTION, (show) => show, () => ({ show : false }))