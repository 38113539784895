import { handleActions } from 'redux-actions'

import { salepointsInitialStatePlatform } from 'vokzal-platform/components/pedia/salepoints/reducer';

const defaultState = {
  loaded : false, urlWithParams: null, title: '', subTitle : '', description : ''
}

const initialState = Object.assign({}, defaultState, salepointsInitialStatePlatform())

const PediaSalepointsReducer = handleActions({}, initialState)

export default PediaSalepointsReducer