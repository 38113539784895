import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import queryString from "query-string";

import Smart from 'smart-plurals';
const russian = Smart.Plurals.getRule('ru');

import { returnToStep2 } from 'vokzal-platform/managers/navigation'

import { searchFormUrlParamsCarCategory } from '../../../utils/url_params';

import { fetch_car_category } from '../../../api/fetch_car_category';

import { category_search_complete } from './action';
import { hide_preloader } from '../action';

import { hideDetailsAction, step2SelectCarTypeAction} from "../../step2/train_details/action";

import TrainSeatsView from 'vokzal-platform/components/step3/train_seats/view'

TrainSeatsView.propTypes = {
  seatClasses : PropTypes.array.isRequired
};


class TrainSeatsContainer extends Component {

  componentDidMount() {
    const p = this.props;

    if (p.seatClasses.length === 0) {
      fetch_car_category(p.searchUrlWithParams, p.car_category, p.car_service, p.onDataLoaded, p.onError);
    }
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return <TrainSeatsView {...this.props} />;
  }
}

const mapStateToProps = state => {

  const c = state.step3_categories.car_categories;

  const car_category = state.step2_details.car_category;
  const car_service  = state.step2_details.car_service;

  const searchUrlWithParams = (c.length === 0) ? searchFormUrlParamsCarCategory(state, car_category, car_service) : '';
  let tmp_categories = (c.length > 0) ? c.slice(0).sort((a, b) => (b.price.withBedding - a.price.withBedding)) : [];
  let categories = [];

  //TODO split by 3 (or dynamic 2 if 4 elements)
  while (tmp_categories.length > 0) {
    const t = tmp_categories.splice(0, 2).map((item) => {

      // const seatsCnt = item.seatsCount + ' ' + russian(item.seatsCount, ['место', 'места', 'мест']);
      const isActive = state.step3_categories.car_category_selected.fakeID === item.fakeID;
      const code = item.service.code;
      const sign = item.tags.hasOwnProperty('female');
      const pet = item.pet; // места для пассажиров с животными, в стоимость уже включён талон на животное

      const count = item.cars.reduce((sum, current) => {
        return sum + current.freeSeats.length;
      }, 0);
      const seatsCnt = count + ' ' + russian(count, ['место', 'места', 'мест']);

      return { fakeID: item.fakeID, code, title : item.name, price : item.price.withBedding, seatsCnt, isActive, sign, pet, type: item.type } ;
    });

    categories.push(t);
  }

  return { searchUrlWithParams, seatClasses : categories, show : state.step3_categories.show, carCategory: car_category, car_service }
};

const mapDispatchToProps = dispatch => {
  return {
    onDataLoaded : (time_spent, result) => {
      const queryObject = queryString.parse(location.search);
      const car_category = queryObject.carCategory;
      dispatch(hideDetailsAction());
      dispatch(hide_preloader());
      dispatch(category_search_complete({ time_spent, ...result, car_category }));
      const service = queryObject.service !== 'undefined' ? queryObject.service : null;
      let adult = '';

      result.category.filter(element => {
        if (service) {
          if (service === element.service.code && element.type === car_category && !adult) {
            adult = element.price.withBedding;
            return null;
          }
        } else if (element.type === car_category && !adult) {
          adult = element.price.withBedding;
          return null;
        }
      });
      const carCategory = car_category;

      const priceItems = {
        adult: adult,
        child: Math.round(adult / 2)
      };

      dispatch(step2SelectCarTypeAction({ carCategory, service, prices: priceItems }));

    },
    onError : ({searchUrlWithParams, code, action, message}) => {
      if (action === 'toStep2') {
        returnToStep2(dispatch, searchUrlWithParams)
      }
    }
  }
};

TrainSeatsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainSeatsContainer);

export default TrainSeatsContainer