import React from 'react';
import i18n from "../../../i18n";
import BootstrapLabeledInput from '../../base/bootstrap_labeled_input';
import PersonModal from '../../../../src/components/step4/person_modal';

const EditView = ({p, success, error, onChanged, onSubmit, onDataLoaded, onError}) => (
    <div className="edit-profile">
        <h5 className="text-center">Редактировать личные данные</h5>

        {success ?
                <div className="success-message">
                        <p>{success}</p>
                </div>
        : null}

        <span className="label">Имя</span>
        <BootstrapLabeledInput name="firstname" value={p.firstname}
                               onChange={(value, inputElement) => onChanged(value, p.firstname, inputElement)} />

        <span className="label">Отчество</span>
        <BootstrapLabeledInput name="middlename" value={p.middlename}
                               onChange={(value, inputElement) => onChanged(value, p.middlename, inputElement)} />

        <span className="label">Фамилия</span>
        <BootstrapLabeledInput name="surname" value={p.surname}
                               onChange={(value, inputElement) => onChanged(value, p.surname, inputElement)} />

        <span className="label">Телефон</span>
        <BootstrapLabeledInput name="phone" value={p.phone}
                               onChange={(value, inputElement) => onChanged(value, p.phone, inputElement)} />


        <BootstrapLabeledInput name="email" value="" className="d-none"/>


        <span className="label">Дата рождения</span>
        <BootstrapLabeledInput name="birth_date" value={p.birth_date}
                               autocomplete="off"
                               onChange={(value, inputElement) => onChanged(value, p.birth_date, inputElement)}
                               onFocus="this.removeAttribute('readonly')"
        />

            {error ? <span className="label error">{error}</span> : null}
            <div className="standart-button centered mb-3 mt-4" onClick={() => onSubmit(p, onDataLoaded, onError)}>
                    <span>{i18n.t('save')}</span>
            </div>
        <PersonModal text={i18n.t('profile_agreement')} className="text-center link small"/>
    </div>
);

module.exports = EditView;