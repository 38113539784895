import React from 'react';
import moment from 'moment';
import { Form } from 'react-bootstrap';

import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';
import { PediaPreLoader } from '../../../../src/components/shared/preloader';
import {documentTypes, carType} from "../../../../src/utils/passengers";
import {starReplace} from "../../../../src/utils/functions";
import  Menu from '../../../../src/components/user/menu';

const Order = ({title, loaded, order, passengers, onCheckboxChanged, onCheckEr, onEscapeEr, onChangeEr, r}) => {
    let changeEr = [];
return (
    <div className="profile">
        <div className="pedia">
            <div className="pedia-block">
                <div className="pedia-header-block">
                    <HeadMenuPedia/>
                    <Menu/>
                    <h3>Ваша поездка</h3>
                </div>

            </div>
            {loaded ? <div className="page-section">
                <div className="table-responsive">
                    <table className="table table-hover">
                        <tbody>
                        <tr>
                            <td>{order.transport} {order.train}</td>
                            <td>
                                <div>Отправление <span className="text-nowrap">{order.departure_date} {order.departure_time}</span></div>
                                <div className="small">по московскому времени</div>
                                <p>{order.station_from.name} {(order.station_from.city && order.station_from.city !== order.station_from.name) ? '(' + order.station_from.city + ')' : null}</p>
                            </td>
                            <td><span className="text-nowrap">-></span></td>
                            <td>
                                <div>Прибытие <span className="text-nowrap">{order.arrival_date} {order.arrival_time}</span></div>
                                <div className="small">по местному времени</div>
                                <div>{order.station_to.name} {(order.station_to.city && order.station_to.city !== order.station_to.name) ? '(' + order.station_to.city + ')' : null}</div>
                            </td>
                            <td>
                                {order.status.text}
                                <div className="small">{moment(order.creation_date).format('DD.MM.YYYY')}</div>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
                {order.order_type === 'train' ? <div>
                    <h5>Вагон {order.car} <span className="text-lowercase">{carType[order.car_category]}</span></h5>
                    <p>Места {order.seats}</p>
                </div> : null
                }

                <h5>Пассажиры</h5>
                {
                    passengers.map(function (p, index) {
                        let checked = p.hasOwnProperty('checked') === false ? 1 : p.checked;
                        if (p.checked === 1){
                            changeEr.push(p.external_ticket_id + ':' + p.er);
                        }
                            // console.log('viev|||||', p);
                        return (
                        <div className="line" key={index}>
                        <div>
                            <div>{index + 1}. {p.name} {p.middlename} {p.surname}</div>
                            <div className="small">Дата рождения: {moment(p.birthdate).format('DD.MM.YYYY')}</div>
                            <div className="small">{documentTypes[p.document_type]} {starReplace(p.document_number, 3)}</div>
                            {order.email == 'ai@vokzal.ru' ? <div className="small">'ЭР:' {p.er}</div> : null}

                            {order.hasOwnProperty('changeEr') && order.changeEr < 3 ?   <div className="check">
                                <Form.Group controlId="formBasicCheckbox" className="mt-2 fs-92">
                                    <Form.Check type="checkbox" label="" checked={
                                        // p.hasOwnProperty('checked') === false ||
                                        p.checked === 1 ? "checked" : null}
                                        onChange={() => onCheckboxChanged(p.id, p.checked)}
                                    />
                                </Form.Group>

                                {/*{console.log(' *** ', p.hasOwnProperty('check') === false || p.check === 1)}*/}

                            </div> : null}

                        </div>


                        </div>
                        );
                    })
                }


                    {order.email == 'ai@vokzal.ru' ? <div>
                        {order.boarding_system_type !== 'PassengerBoardingControl'
                            ? <a href="#" onClick={() => onCheckEr(order.id)}>Изменить статус электронной регистрации</a>
                            : null}

                        {order.hasOwnProperty('changeEr') && [0,2].includes(order.changeEr)
                            ? <a href="#" onClick={() => onChangeEr(order.id, 0, changeEr)}>Отменить электронную регистрацию ***{order.changeEr}***</a>
                            : null}

                        {order.hasOwnProperty('changeEr') && [1,2].includes(order.changeEr)
                            ? <a href="#" onClick={() => onChangeEr(order.id, 1, changeEr)}>Установить электронную регистрацию***{order.changeEr}***</a>
                            : null}
                    </div> : null}





                {/*<p><a href="#" onClick={() => onDeleteOrder(o.id)}>Удалить</a></p>*/}



                {order.amount ? <div>
                    <span>Общая стоимость заказа</span>
                    <h5>{order.amount + ' р.'}</h5>
                </div> : null}

                <h5>Контактная информация</h5>
                <div>Телефон: {order.phone}</div>
                <div>Email: {order.email}</div>

                {order.pin && order.status.code == 3
                && moment(moment(order.date.date).format('YYYY-MM-DD') + ' ' + order.departure_time).isAfter(moment().subtract(2, 'hours'))
                    ? <p className="mt-2"><a href={'https://vokzal.ru/return?pin=' + order.pin}>Вернуть билет</a></p>
                    : null}

            </div> : ''
            }


        </div>

        <PediaPreLoader show={!loaded} />
    </div>
);
}


module.exports = Order;