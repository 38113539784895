import React from 'react';

import SeatedCabinPlace from '../../../../../../src/components/step3/seated_cabin_place';
import SapsanWardrobe from '../../../../../../src/components/step3/sapsan/base/sapsan_wardrobe';

const SapsanWardrobeCabinWithSeatsView = ({ carNumber, wardrobeTitle, cabinInfo1, cabinInfo2, cabinInfo3, autoHeightWardrobe }) => {

  let row1 = null;
  if (cabinInfo1) {
    row1 = <div className="train-car-map-seated-cabin d-flex justify-content-between">
              <div className="train-car-map-seated-cabin-place-block d-flex">
                { (cabinInfo1.leftWindow !== null) ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo1.leftWindow.number} isFront={cabinInfo1.leftWindow.isFront} /> : null }
                { (cabinInfo1.leftSide !== null)   ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo1.leftSide.number}   isFront={cabinInfo1.leftSide.isFront} />   : null }
              </div>
            </div>
  }

  let row2 = null;
  if (cabinInfo2) {
    row2 = <div className="train-car-map-seated-cabin d-flex justify-content-between">
              <div className="train-car-map-seated-cabin-place-block d-flex">
                { (cabinInfo2.leftWindow !== null) ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo2.leftWindow.number} isFront={cabinInfo1.leftWindow.isFront} /> : null }
                { (cabinInfo2.leftSide !== null)   ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo2.leftSide.number}   isFront={cabinInfo1.leftSide.isFront} />   : null }
              </div>
            </div>
  }

  let row3 = null;
  if (cabinInfo3) {
    row3 = <div className="train-car-map-seated-cabin d-flex justify-content-between">
              <div className="train-car-map-seated-cabin-place-block d-flex">
                { (cabinInfo3.leftWindow !== null) ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo3.leftWindow.number} isFront={cabinInfo3.leftWindow.isFront} /> : null }
                { (cabinInfo3.leftSide !== null)   ? <SeatedCabinPlace carNumber={carNumber} seatNumber={cabinInfo3.leftSide.number}   isFront={cabinInfo3.leftSide.isFront} />   : null }
              </div>
            </div>
  }



  return (

    <div className="sapsan-wardrobe-cabin d-flex justify-content-between">

      <div className="d-flex flex-column">
        {row1}
        {row2}
        {row3}
      </div>

      <SapsanWardrobe title={wardrobeTitle} autoHeight={autoHeightWardrobe} />
    </div>
  )
}

module.exports = SapsanWardrobeCabinWithSeatsView;