import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { select_seat, deselect_seat} from '../action';

import SeatedCabinPlaceView from 'vokzal-platform/components/step3/seated_cabin_place/view'

SeatedCabinPlaceView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  seatNumber: PropTypes.number.isRequired,
  isFront: PropTypes.bool.isRequired,
  state: PropTypes.oneOf(['available', 'unavailable', 'busy', 'selected']).isRequired,
  onSeatClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {

  const isAvailable = state.step3_categories.car_selected.freeSeats.indexOf(ownProps.seatNumber.toString()) !== -1;
  let seatState = (isAvailable) ? 'available' : 'busy';

  if (seatState === 'available' && !state.step3_passengers.can_select_seat) {
    seatState = 'unavailable';
  }

  const isSelected = state.step3_passengers.selected_seats.filter(
    ((item, index, array) => ( item.seatNumber === ownProps.seatNumber ) )
  ).length === 1;
  seatState = (isSelected) ? 'selected' : seatState;

  //debugger

//   var lastochka = false
//   if (state.step3_categories.car_selected.trainType !== undefined
//     && state.step3_categories.car_selected.trainType === "lastochka") {
//     lastochka = true
//   }

  return { state : seatState }
};

const mapDispatchToProps = dispatch => {
  return {
    onSeatClick : (carNumber, seatNumber, seatState, isUpper) => {
      if (seatState === 'selected') {
        dispatch(deselect_seat(seatNumber));
      } else if (seatState === 'available')  {
        dispatch(select_seat({carNumber, seatNumber, isUpper}));
      }

    }
  }
};

const SeatedCabinPlaceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SeatedCabinPlaceView);

export default SeatedCabinPlaceContainer;

