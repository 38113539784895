import React from 'react';

import StrijToilet from '../../../../../../src/components/step3/strij/base/strij_toilet';

const StrijToiletsCabinView = ({ title }) => (
  <div className={"train-car-map-seated-cabin d-flex justify-content-between mt-2 mb-2"}>
    <StrijToilet />
    <StrijToilet />
  </div>
)
module.exports = StrijToiletsCabinView;