import { minPrice, minSeats } from '../api/price';

const fastPercent = 1.1;
const pricePercent = 1.1;
const limitSeatsTrain = 10;
const limitSeatsBus = 3;

export const processBusesUiFlags = buses => {

  if (buses.length === 0) {
    return [];
  }

  const bMap = buses.map(t => ({wt: t.minutes_in_way, p: t.price, s: t.available_seats}));

  const thePrice = bMap.map(t => t.p).reduce((a, b) => ((a > b) ? b : a));
  const theWayTime = bMap.map(t => t.wt).reduce((a, b) => ((a > b) ? b : a));

  return bMap.map(t => {
    const fastest   = t.wt === theWayTime;
    const fast      = (fastest) ? false : t.wt <= theWayTime * fastPercent;
    const cheapest  = t.p === thePrice;
    const cheap     = (cheapest) ? false : t.p <= thePrice * pricePercent;
    const min_seats = t.s <= limitSeatsBus;

    return { fastest, fast, cheapest, cheap, min_seats }
  });
}

export const processTrainsUiFlags = trains => {

  if (trains.length === 0) {
    return [];
  }

  const trMap = trains.map(t => {
    const wt = parseInt(t.wayTime.time.replace(':', ''));
    const p = minPrice(t.prices).price;
    const s = minSeats(t.prices);
    return {wt, p, s}
  });

  const thePrice = trMap.map(t => t.p).reduce((a, b) => ((a > b) ? b : a));
  const theWayTime = trMap.map(t => t.wt).reduce((a, b) => ((a > b) ? b : a));

  return trMap.map(t => {

    const fastest   = t.wt === theWayTime;
    const fast      = (fastest) ? false : t.wt <= theWayTime * fastPercent;
    const cheapest  = t.p === thePrice;
    const cheap     = (cheapest) ? false : t.p <= thePrice * pricePercent;
    const min_seats = t.s <= limitSeatsTrain;

    return { fastest, fast, cheapest, cheap, min_seats }
  });
}