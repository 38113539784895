import React from 'react';

import SapsanCabin from '../../../../../src/components/step3/sapsan/base/sapsan_cabin';
import SapsanLuggageCabin from '../../../../../src/components/step3/sapsan/base/sapsan_luggage_cabin';
import SapsanAnimalsCabin from '../../../../../src/components/step3/sapsan/base/sapsan_animals_cabin';
import SapsanWardrobeCabinWithSeats from '../../../../../src/components/step3/sapsan/base/sapsan_wardrobe_cabin_with_seats';
import SapsanWardrobeCabinWithToiletAndSeats from '../../../../../src/components/step3/sapsan/base/sapsan_wardrobe_cabin_with_toilet_and_seats';
import SapsanCabinDelimiter from '../../../../../src/components/step3/sapsan/base/sapsan_cabin_delimiter';
import i18n from "../../../../i18n";

const SapsanEconomMapView = ({ carNumber, cabins }) => (
        <div>
         {/*   <div className="train-car-select-seats-map-horizontal row">
                <div className="d-flex seat-map">
                    <SapsanLuggageCabin carNumber={carNumber} />

                    <SapsanAnimalsCabin carNumber={carNumber} cabinInfo={cabins[0]} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[1]} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[2]} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[3]} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[4]} />
                    <SapsanCabinDelimiter />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[5]} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[6]} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[7]} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[8]} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[9]} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[10]} />
                    <SapsanWardrobeCabinWithSeats carNumber={carNumber} cabinInfo1={cabins[11]} cabinInfo2={cabins[12]} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[13]} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[14]} />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[15]} />
                    <SapsanCabinDelimiter />
                    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[16]} />
                    <SapsanWardrobeCabinWithToiletAndSeats carNumber={carNumber} cabinInfo={cabins[17]} />
                </div>
            </div>*/}

            {/*<div className="train-car-select-seats-map rounded pt-2 d-block d-lg-none">*/}
            <div className="train-car-select-seats-map rounded pt-2">
                <SapsanLuggageCabin carNumber={carNumber} />

                <SapsanAnimalsCabin carNumber={carNumber} cabinInfo={cabins[0]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[1]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[2]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[3]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[4]} />
                <SapsanCabinDelimiter />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[5]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[6]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[7]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[8]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[9]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[10]} />
                <SapsanWardrobeCabinWithSeats carNumber={carNumber} cabinInfo1={cabins[11]} cabinInfo2={cabins[12]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[13]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[14]} />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[15]} />
                <SapsanCabinDelimiter />
                <SapsanCabin carNumber={carNumber} cabinInfo={cabins[16]} />
                <SapsanWardrobeCabinWithToiletAndSeats carNumber={carNumber} cabinInfo={cabins[17]} />
            </div>
        </div>

    );

module.exports = SapsanEconomMapView;