// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import PediaBusInfoView from 'vokzal-platform/components/pedia/bus_info/view'

PediaBusInfoView.propTypes = {
};

const mapStateToProps = state => {

  const route_len = state.pedia_bus.route.length;

  const stops_count = (route_len - 2 > 0) ? route_len - 2 : 0;
  const max_station_time = state.pedia_bus.route.map(r => parseInt(r.stop_time)).reduce((accumulator, currentValue) => (accumulator < currentValue ? accumulator : currentValue));
  const max_station_time_city_name = max_station_time ? state.pedia_bus.route.filter(r => parseInt(r.stop_time) === max_station_time).map(r => r.point_name)[0] : null;

  const price = 0;

  const bus = state.pedia_bus.bus;
  const run_info = state.pedia_bus.run_info;
  const show = state.step3_bus.show_modal;

  return {
    bus, run_info,
    stops_count, max_station_time_city_name, max_station_time, price, show
  };
}

const PediaBusInfoContainer = connect(
  mapStateToProps
)(PediaBusInfoView)

export default PediaBusInfoContainer