import { handleActions } from 'redux-actions'

import { PopularCities } from '../../../../data/home';

import { HOME_SHOW_POPULAR_CITIES } from './action'

const PopularCitiesReducer = handleActions({
    [HOME_SHOW_POPULAR_CITIES]: (state, action) => {
        return {...state};
    },
}, { items: PopularCities })

export default PopularCitiesReducer