import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import BlogPostsView from 'vokzal-platform/components/blog/posts/view'

BlogPostsView.propTypes = {
  loaded : PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    loaded: state.blog_posts.data_loaded,
});

const BlogPostsContainer = connect(
  mapStateToProps
)(BlogPostsView);

export default BlogPostsContainer