import { handleActions } from 'redux-actions'

import { footerLinksInitialStatePlatform } from 'vokzal-platform/components/home/footer_links/reducer';


import { HOME_FETCH_FOOTER_LINKS } from './action'

let defaultState = { items: [] };

const initialState = Object.assign({}, defaultState, footerLinksInitialStatePlatform())

const FooterLinksReducer = handleActions({
    [HOME_FETCH_FOOTER_LINKS]: (state, action) => {
        return {...state, items : action.payload};
    },
}, initialState)

export default FooterLinksReducer