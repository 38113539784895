import React from 'react';
import { Collapse } from 'react-bootstrap'
import BootstrapLabeledInput from '../../base/bootstrap_labeled_input';

const TextInputView = ({
    ticketNumber, ticketType, name, value,
    isValueValid, isInFocus, isErrorInExtraField, errorMessage,
    validationRule, validationData,
    label, fieldsetClass, className, placeholder,
    onValueChange, onFocusLostValidation, onFocus, info
}) => {

  let c = (fieldsetClass) ? fieldsetClass : '';

  if (!isValueValid) {
    c +=  " input-invalid";
  } else if (isInFocus) {
    c +=  " field-in-focus";
  }

  const input = <BootstrapLabeledInput fieldsetClass={c} placeholder={placeholder} name={name} value={value}
                                   className={"font-medium-light " + (className || '')} label={label} required={true}
                                   onChange={(newValue, inputElement) => { onValueChange(ticketNumber, ticketType, name, validationRule, newValue, value, inputElement, info, validationData) }}
                                   onBlur={() => { onFocusLostValidation(ticketNumber, ticketType, name, validationRule, validationData, value, info) }}
                                   onFocus={() => { onFocus(ticketNumber, name) }}
  />;

  return (
    (!isErrorInExtraField)
      ? <div>
          {input}
          <Collapse in={!isValueValid}><div className="validation-message validation-error border-top-0 pt-0">{errorMessage}</div></Collapse>
        </div>
      : input
  )
}

module.exports = TextInputView;