import React from 'react';

import { connect } from 'react-redux';

import i18n from 'vokzal-platform/i18n';

import BusSeatsAutoView from 'vokzal-platform/components/step3bus/bus_seats_auto/view'

BusSeatsAutoView.propTypes = { };

const mapStateToProps = state => {
  return { autoSelectionText: i18n.t('auto_seats') }
};


const BusSeatsAutoContainer = connect(
  mapStateToProps
)(BusSeatsAutoView);

export default BusSeatsAutoContainer;

