import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import i18n from 'vokzal-platform/i18n';


import DocumentHelperView from 'vokzal-platform/components/step4/document_helper/view';

DocumentHelperView.propTypes = {
  show: PropTypes.bool.isRequired,
  focus: PropTypes.bool.isRequired,
  message: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {

  const p = state.step3_passengers.passengers[ownProps.ticketNumber - 1];

  const show = !p.validators.hasOwnProperty('document_number');
  const focus = p.focus === 'document_number';
  const message = (show) ? i18n.t('document_number_info_' + p.document_type) : null;

  return { show, focus, message };
};

const DocumentHelperContainer = connect(
  mapStateToProps
)(DocumentHelperView);

export default DocumentHelperContainer