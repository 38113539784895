import { connect } from 'react-redux'

import moment from 'moment'

import { vokzal_config } from '../../../config';

import {
    setDateFromAction,
    setDateToAction,
    setDateFromInfoAction, setQuickStart,
} from '../search_form/action'
import { hideDatesModalAction } from '../../../components/home/dates_modal/action'
import DatesModalView from 'vokzal-platform/components/home/dates_modal/view'

import {SearchFormFields, StationEmpty} from '../../../../src/types/';
import {showStep2Plain} from "../../../../web/managers/navigation";
import {setStep2Action} from "../search_form/action";
import {step2TrainsSearchCleanupAction} from "../../step2/trains_search_result/action";
import {step2BusesSearchCleanupAction} from "../../step2/buses_search_result/action";
import {showPreloaderAction} from "../../step2/action";
import {step2TabClickAction} from "../../step2/search_result_tabs/action";
import {perform_search} from "../search_button/action";
import {storeBusDetailsAction} from "../../step2/bus_row/action";
import {step2SetBusPriceAction} from "../../step2/train_details/action";
import {show_preloader} from "../../step3/action";
import {bus_seats_reset} from "../../step3bus/bus_seats/action";
import {fetch_bus_id} from "../../../api/fetch_bus_id";
import { busId } from '../../../utils/url_params';
import {bus_search, bus_search_error} from "../../step3bus/action";


const mapStateToProps = state => {

    return {
        show: state.dates_modal.show,
        selected_input: state.dates_modal.selected_input,
        selected_date: state.dates_modal.selected_date,
        searchFormState: state.search,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCloseClick: () => {
            dispatch(hideDatesModalAction());
        },
        onItemClick: (selected_input, date, onDateSelected, searchFormState) => {
            const today = moment().startOf('day');
            const maxDate = moment(today).add(vokzal_config.dates.max, 'day');

            if (!moment(date).isBetween(today, maxDate, null, '[]')) {
                return;
            }

            dispatch(hideDatesModalAction());

            switch (selected_input) {
                case SearchFormFields.FROM_DATE:
                    dispatch(setDateFromInfoAction(moment(date)));
                    if (onDateSelected) {
                        dispatch(setDateFromAction(moment(date)));
                    }

                    break;

                case SearchFormFields.TO_DATE:
                    dispatch(setDateToAction(moment(date))); break;

            }

            if (onDateSelected) {
              onDateSelected(date);
            } else if (searchFormState.quick_start) {
                if (searchFormState.from === StationEmpty || searchFormState.to === StationEmpty) {
                    return;
                }

                let newUrl = searchFormState.quick_start.replace(/departureDate=[\d\.]{1,}&/, `departureDate=${moment(date).format('DD.MM.YYYY')}&`);
                showStep2Plain(dispatch, newUrl);
                dispatch(setStep2Action({ step2: newUrl }));
                showStep2Plain(dispatch, newUrl);
                dispatch(step2TrainsSearchCleanupAction());
                dispatch(step2BusesSearchCleanupAction());
                dispatch(showPreloaderAction());
                dispatch(step2TabClickAction('trains'));
                dispatch(perform_search(searchFormState));
                dispatch(setQuickStart(''));
            } else if (searchFormState.type === 'bus') {
                dispatch(storeBusDetailsAction(searchFormState.type_data.bus));
                dispatch(step2SetBusPriceAction(searchFormState.type_data.bus.price));
                dispatch(show_preloader());
                dispatch(bus_seats_reset());
                searchFormState.type_data.form.date_from = moment(date);
                dispatch(bus_search({form: searchFormState.type_data.form, bus: searchFormState.type_data.bus, 'error': null, wait: true}));
                const url = busId(searchFormState.type_data.form, searchFormState.type_data.bus);
                fetch_bus_id(url, (param, data) => {
                    dispatch(bus_search({wait: false, show_modal: true, buses: data.buses}));
                }, (error) => {
                    dispatch(bus_search({wait: false}));
                    dispatch(bus_search_error(error));
                });
            }
        },
    }
};

const DatesModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DatesModalView);

export default DatesModalContainer