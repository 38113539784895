// @flow
import {connect} from 'react-redux'
import PropTypes from 'prop-types';

import Step2AlternativeView from 'vokzal-platform/components/step2/alternative/view'

Step2AlternativeView.propTypes = {
    trainError: PropTypes.string,
    busError: PropTypes.string,
    otherDates: PropTypes.object
};

const mapStateToProps = state => {

    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

const Step2AlternativeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Step2AlternativeView)

export default Step2AlternativeContainer