import {connect} from 'react-redux'
import PropTypes from 'prop-types';

import i18n from 'vokzal-platform/i18n'

import FooterView from 'vokzal-platform/components/shared/footer/view'

FooterView.propTypes = {
  about : PropTypes.string.isRequired,
  help : PropTypes.string.isRequired,
  blog : PropTypes.string.isRequired,
  copyright : PropTypes.string.isRequired,
  inn : PropTypes.string,
};

const mapStateToProps = (state) => {

  return {
    about: i18n.t('about'),
    help: i18n.t('help'),
    blog: i18n.t('blog'),
    copyright: i18n.t('copyright'),
    inn: i18n.t('inn'),
    rzd_no: i18n.t('rzd_no'),
    rzd1: i18n.t('rzd1'),
    rzd2: i18n.t('rzd2'),
  }
}

const FooterContainer = connect(
  mapStateToProps
)(FooterView)

export default FooterContainer