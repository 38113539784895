import React from 'react';

const DateInputErrorView = ({ isValueValid, errorMessage }) => {

  if (isValueValid) {
    return null;
  }

  return (<div className="validation-message validation-error small border-bottom-0">{errorMessage}</div>);
};

module.exports = DateInputErrorView;