import { createAction } from 'redux-actions';

export const BLOG_POST_STORE_URL_ACTION   = 'BLOG/POST/STORE_URL'
export const postStoreUrlAction           = createAction(BLOG_POST_STORE_URL_ACTION);

export const BLOG_POST_STORE_ACTION       = 'BLOG/POST/STORE'
export const postStoreAction              = createAction(BLOG_POST_STORE_ACTION);

export const SHOW_PRELOADER_ACTION        = 'BLOG/POSTS/PRELOADER/SHOW'
export const showPreloaderAction          = createAction(SHOW_PRELOADER_ACTION, (show) => show, () => ({ show : true }))

export const HIDE_PRELOADER_ACTION        = 'BLOG/POSTS/PRELOADER/HIDE'
export const hidePreloaderAction          = createAction(HIDE_PRELOADER_ACTION, (show) => show, () => ({ show : false }))