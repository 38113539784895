import React from 'react';

import i18n from 'vokzal-platform/i18n'

import LastochkaCabin from '../../../../../src/components/step3/lastochka/base/lastochka_cabin';
import LastochkaCabinDelimiter from '../../../../../src/components/step3/lastochka/base/lastochka_cabin_delimiter';
import LastochkaToilet from '../../../../../src/components/step3/lastochka/base/lastochka_toilet';



const Prigorod_22_v1View = ({ carNumber, cabins }) => (
    <div className="train-car-select-seats-map rounded pt-2">

        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[0]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[1]} />

        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[2]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[3]} />

        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[4]} />

        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[5]} />

        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[6]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[7]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[8]} />

        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[9]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[10]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[11]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[12]} />

        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[13]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[14]} />


        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[15]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[16]} />

        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[17]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[18]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[19]} />
        <div className="right"><LastochkaToilet /></div>
        <LastochkaCabinDelimiter />


    </div>
);

module.exports = Prigorod_22_v1View;