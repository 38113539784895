import React from 'react';
import {Link} from "react-router-dom";

const MenuView = ({ profile, orders, passengers, active, admin }) => (
    <div className="page-section user-menu">
        <div className="row">
            <div className="col-md-12">
                <ul className="">
                    <li>{active === 'profile' ? <span>{profile}</span> : <Link to="/user/profile">{profile}</Link>}</li>
                    <li>{active === 'orders' ? <span>{orders}</span> : <Link to="/user/orders">{orders}</Link>}</li>
                    <li>{active === 'passengers' ? <span>{passengers}</span> : <Link to={"/user/passengers"}>{passengers}</Link>}</li>
                    {admin ? <li><a href={"/user/admin"}>{admin}</a></li> : null}
                </ul>
            </div>
        </div>
    </div>
);

module.exports = MenuView;