import { post_process_schedule } from '../../../../src/api/fetch_pedia_station';

export const raspisanieBusInitialStatePlatform = () => {
  let initialState = {}

  if (typeof pedia_schedule_bus !== 'undefined') {
    initialState.schedule = post_process_schedule(pedia_schedule_bus.runs);
    initialState.title = pedia_schedule_bus.title;
    initialState.subTitle = pedia_schedule_bus.subTitle;
    initialState.image = { caption: 'default' };
    initialState.loaded = true;

    initialState.cityFrom = pedia_schedule_bus.cityFrom;
    initialState.cityTo = pedia_schedule_bus.cityTo;
    initialState.averageDistance = pedia_schedule_bus.averageDistance;
    initialState.averageWaytime = pedia_schedule_bus.averageWaytime;
    initialState.trainsCount = pedia_schedule_bus.trainsCount;
    initialState.citiesPassthru = pedia_schedule_bus.citiesPassthru;
    initialState.tariffs = pedia_schedule_bus.tariffs;
  }

  return initialState;
}