// @flow
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PediaStationBusMapView  from 'vokzal-platform/components/pedia/station_bus_map/view'

PediaStationBusMapView.propTypes = {
  show : PropTypes.bool.isRequired,
  mapState: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const coords = state.pedia_station_bus.coords;
  const show = !!(coords);
  const title = state.pedia_station_bus.title;
  const mapState = (show) ? { center: [coords.lon, coords.lat], zoom: 16, type: 'yandex#publicMap', controls: ['zoomControl', 'fullscreenControl']} : {}

  return { show, coords, mapState, title }
}

const mapDispatchToProps = dispatch => ({

})

const PediaStationBusMapContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaStationBusMapView)

export default PediaStationBusMapContainer