// @flow
import PropTypes from 'prop-types'

import PassengerInfoView from 'vokzal-platform/components/ticket_return/passenger_info/view'

PassengerInfoView.propTypes = {
  fio: PropTypes.string.isRequired,
  seat: PropTypes.string.isRequired,
  tarif: PropTypes.string.isRequired
};

const PassengerInfo = PassengerInfoView

export default PassengerInfo