import React from 'react';

const HelpSinglePostContentView = ({url, title, content, onBack}) => (
  <div className="help-post-content-wrapper">
    <div className="help-post-content" dangerouslySetInnerHTML={{__html: content}} />

    <button type="button" className={"btn btn-success btn-block text-uppercase mt-4 mb-4"} onClick={() => onBack(url)}>
      Назад
    </button>
  </div>
);

module.exports = HelpSinglePostContentView;