// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import moment from 'moment';
import { connect } from 'react-redux'

import { showStep2Alternative } from 'vokzal-platform/managers/navigation'

import PediaBusView from 'vokzal-platform/components/pedia/bus/view'
import {fetch_pedia_bus_list} from "../../../api/fetch_pedia_bus_list";
import {busFetchCompleteAction} from "./action";

import { step2AlternativeChangeDateAction } from '../../step2/alternative/action'

import { step2TrainsSearchCleanupAction } from '../../step2/trains_search_result/action'
import { step2BusesSearchCleanupAction } from '../../step2/buses_search_result/action'

PediaBusView.propTypes = {
  loaded: PropTypes.bool.isRequired,
  urlWithParams: PropTypes.string,
  searchUrlWithParams: PropTypes.string,
  onDateSelected: PropTypes.func.isRequired
};

class PediaBusContainer extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      fetch_pedia_bus_list(this.props.urlWithParams, this.props.onDataLoaded);
    }
  }

  render() {
    return <PediaBusView {...this.props} />;
  }
}


const mapStateToProps = state => {

 const searchUrlWithParams = '';

  return {
    loaded: state.pedia_bus.loaded,
    urlWithParams: state.pedia_bus.urlWithParams,
    searchUrlWithParams
  }
}

const mapDispatchToProps = dispatch => {

    return {
      onDataLoaded : (time_spent, response) => {
        dispatch(busFetchCompleteAction({ time_spent, response }));
      },
      onDateSelected: (searchUrlWithParams, date) => {
        if (searchUrlWithParams) {
          dispatch(step2TrainsSearchCleanupAction());
          dispatch(step2BusesSearchCleanupAction());

          dispatch(step2AlternativeChangeDateAction(moment(date)));

          searchUrlWithParams += '&departureDate=' + moment(date).format('DD.MM.YYYY')

          showStep2Alternative(dispatch, searchUrlWithParams);
        }
      }
    }
}


PediaBusContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PediaBusContainer)

export default PediaBusContainer