import React from 'react';

const PediaTabGroupView = ({ items, selected, onButtonClick, className = '' }) => (
  <div className={"pedia-header-buttons btn-toolbar justify-content-center " + className}>
    <div className="btn-group" data-toggle="buttons">
      {
        items.map((i, index) => (
          <button key={index} onClick={() => onButtonClick(i)} type="button" className={"font-medium-light btn " + ((i.value === selected) ? 'btn-outline-primary' : 'btn-primary')}>
            {i.title.toUpperCase()}
          </button>
        ))
      }
    </div>
  </div>
);

module.exports = PediaTabGroupView;