// @flow
import PropTypes from 'prop-types';

import LastochkaCabinPlaceView from 'vokzal-platform/components/step3/lastochka/base/lastochka_cabin_place/view'

LastochkaCabinPlaceView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  seatNumber: PropTypes.number.isRequired,
  isFront: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['std', 'side', 'side-disabled-person' ]).isRequired,
};

const LastochkaCabinPlace = LastochkaCabinPlaceView

export default LastochkaCabinPlace