import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import i18n from 'vokzal-platform/i18n'

import { SearchFormFields } from '../../../types'

import {searchFormUrlParamsTrain} from '../../../../src/utils/url_params';
import { setTrainNumberAction } from '../../../../src/components/step2/train_details/action';
import { step2MakePostSearchAction } from '../../../../src/components/step2/trains_search_result/action';
import { showDatesModalAction } from '../../../components/home/dates_modal/action'
import PediaOrderFormShortView from 'vokzal-platform/components/pedia/order_form_short/view'
import {setStationFromAction, setStationToAction, setStationFromInfoAction, setStationToInfoAction, setQuickStart} from "../../home/search_form/action";
import {convert_station_obj} from "../../../utils/stations";

PediaOrderFormShortView.propTypes = {
  orderButtonTitle : PropTypes.string.isRequired,
  searchUrlWithParams : PropTypes.string,
  train : PropTypes.object.isRequired,
  onSelectDateClick : PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const orderButtonTitle = i18n.t('select_departure_date');

  const searchUrlWithParams = searchFormUrlParamsTrain(state.search);

  return { orderButtonTitle, searchUrlWithParams }
}

const mapDispatchToProps = dispatch => {
  return {
    onSelectDateClick: (train, searchUrlWithParams = '') => {
      const selected_input = SearchFormFields.FROM_DATE;
      const date = '';
      dispatch(setStationFromAction(convert_station_obj(train.departure.objStation)));
      dispatch(setStationFromInfoAction(convert_station_obj(train.departure.objStation)));
      dispatch(setStationToAction(convert_station_obj(train.arrival.objStation)));
      dispatch(setStationToInfoAction(convert_station_obj(train.arrival.objStation)));
      dispatch(setTrainNumberAction({ number : train.number }));
      dispatch(step2MakePostSearchAction());
      dispatch(setQuickStart(searchUrlWithParams));
      dispatch(showDatesModalAction({selected_input, date}));
    },
  }
}


const PediaOrderFormShortContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaOrderFormShortView)

export default PediaOrderFormShortContainer