// @flow
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { PediaRegionStationPropType } from '../../../types';

import PediaRegionMapView  from 'vokzal-platform/components/pedia/region_map/view'
import {regionMapBoundsAction} from "./action";
import {make_features} from "../../../api/fetch_pedia_region";

PediaRegionMapView.propTypes = {
  show : PropTypes.bool.isRequired,
  mapState: PropTypes.object.isRequired,
  stations: PropTypes.arrayOf(PediaRegionStationPropType).isRequired,
  objectManagerInstanceRef: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const stations = state.pedia_region.stations;
  const show = stations.length > 0;
  const features = state.pedia_region.features;

  const bounds = state.pedia_region_map.bounds;
  const boundsSet = !!(bounds);

  const mapState = (show) ? { center: [59.43, 47.75], bounds, zoom: 7, type: 'yandex#publicMap', controls: ['zoomControl', 'fullscreenControl']} : {}
  return { show, mapState, stations, features, boundsSet }
}

const mapDispatchToProps = dispatch => {

  return {
    objectManagerInstanceRef : (boundsSet, instanceRef) => {
      if (!boundsSet) {
        dispatch(regionMapBoundsAction(instanceRef.getBounds()))
      }
    }
  }
}

const PediaRegionMapContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaRegionMapView)

export default PediaRegionMapContainer