import React from 'react';

import SapsanWardrobe from '../../../../../../src/components/step3/sapsan/base/sapsan_wardrobe';

const SapsanLuggageCabinView = ({ carNumber, wardrobeTitle, autoHeightWardrobe }) => (
  <div className="sapsan-wardrobe-cabin d-flex justify-content-between">

    <div />

    <SapsanWardrobe title={wardrobeTitle} autoHeight={autoHeightWardrobe} />
  </div>
);

module.exports = SapsanLuggageCabinView;