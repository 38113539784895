import React from 'react';

import i18n from 'vokzal-platform/i18n';

const AutoSeatSelectView = ({ carNumber, text }) => (
  <div className="train-car-select-seats-map rounded p-2">
    <div className="train-car-select-seats-map-block train-car-map-auto-seats rounded text-center mb-2">{text ? <div><p>{i18n.t('auto_sheme')}</p><p>{i18n.t('auto_select')}</p></div> : i18n.t('auto_seats')}</div>
  </div>
);

module.exports = AutoSeatSelectView;