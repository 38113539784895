import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import { ScheduleBusPropType } from '../../../types';

import { showTrain } from 'vokzal-platform/managers/navigation'

import { trainUrlStoreAction } from '../../pedia/train/action';

import PediaScheduleItemBusView from 'vokzal-platform/components/pedia/schedule_item_bus/view'

PediaScheduleItemBusView.propTypes = {
  group_name: PropTypes.string.isRequired,
  bus: ScheduleBusPropType.isRequired,
  // showWaitTime: PropTypes.bool.isRequired,
  onTrainClick: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({  })

const mapDispatchToProps = dispatch => {
  return {
    onTrainClick : (train, event) => {
      dispatch(trainUrlStoreAction(train.numberUrl));
      showTrain(dispatch, train.numberUrl)
    }
  }
}

const PediaScheduleItemBusContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaScheduleItemBusView)

export default PediaScheduleItemBusContainer