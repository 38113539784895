import React, {Fragment} from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from "prop-types";
import BootstrapLabeledInput from '../../base/bootstrap_labeled_input';
import i18n from "../../../i18n";
import PersonModal from '../../../../src/components/step4/person_modal';

const RegisterModalView = ({ show, onCloseClick, onClick, text, className, title, email, email_error, success, error, onEmailChanged, onSubmit, onDataLoaded, onError }) => {

    return (
        <div>
            <div className={className} onClick={() => onClick()}>
                {text}
            </div>

            <Modal show={show} animation={false} dialogClassName="auth-modal" backdropClassName="show resp-search-modal">

                <Modal.Header>
                    <Modal.Title id="contained-modal-title-lg" componentClass="h5">{title}</Modal.Title>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseClick}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>

                <Modal.Body bsClass="modal-body pt-0">

                    {success ?
                        <div className="success-message">
                            <p>Благодарим за регистрацию на нашем сервисе</p>
                            <p>На ваш адрес электронной почты отправлено письмо</p>
                        </div>
                        :
                    <Fragment>
                        <span className="label">{i18n.t('email')}</span>
                        <BootstrapLabeledInput fieldsetClass={email_error ? "input-invalid" : ""}
                                               name="email" value={email} required={true}
                                               onChange={(value, inputElement) => onEmailChanged(value, email, inputElement)} />
                        {email_error ? <span className="label error">{email_error}</span> : null}
                        {error ? <span className="label error">{error}</span> : null}
                        <div className="standart-button centered mb-3 mt-4" onClick={() => onSubmit(email, onDataLoaded, onError)}>
                            <span>{i18n.t('register')}</span>
                        </div>
                        <PersonModal text={i18n.t('register_agreement')} className="text-center link small"/>
                    </Fragment>}
                </Modal.Body>
            </Modal>
        </div>
    )};

RegisterModalView.propTypes = {
    show: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
};

module.exports = RegisterModalView;




