import React from 'react';

const UnsubscribeView = ({title, email}) => {
    return (
        <div className="unsubscribe centered">
            <h5>{title}</h5>
            <p>{email}</p>
        </div>
    )};

module.exports = UnsubscribeView;