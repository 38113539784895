import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {searchFormUrlParamsCarCategory, searchFormUrlParamsCarCategoryTrain} from '../../../utils/url_params';
import { CarTypes, SearchResultTrainPricePropType } from '../../../../src/types/';
import TrainPriceItemView from 'vokzal-platform/components/step2/train_price_item/view'

TrainPriceItemView.propTypes = {
  priceType : PropTypes.string.isRequired,
  priceItems: PropTypes.objectOf(SearchResultTrainPricePropType).isRequired,
  service : PropTypes.string,
  title : PropTypes.string.isRequired,
  urlParams:  PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  const service = ownProps.service === undefined ? null : ownProps.service;
  const urlParams = searchFormUrlParamsCarCategoryTrain(state, ownProps.priceType, service, ownProps.trainItem.number, ownProps.trainItem.sapsan, ownProps.trainItem.er);
  const title = (service !== null && CarTypes.hasOwnProperty(service)) ? CarTypes[service] : CarTypes[ownProps.priceType];
  return { title, urlParams };
};

const TrainPriceItemContainer = connect(
    mapStateToProps
)(TrainPriceItemView);

export default TrainPriceItemContainer