import { handleActions } from 'redux-actions'

import { STEP2_STORE_BUS_DETAILS_ACTION } from './action'

const BusDetailsReducer = handleActions({
  [STEP2_STORE_BUS_DETAILS_ACTION]: (state, action) => {
    return { ...state, bus : action.payload }
  },
}, { bus : null } )

export default BusDetailsReducer