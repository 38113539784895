import React from 'react';

const SearchResultTabView = ({ isActive, title, code, price, onTabClick }) => {

  const p = (
    (price)
      ? <div className="search-result-tab-price">от {price} &#8381;</div>
      : <div className="search-result-tab-price">&nbsp;</div>

  );

  return (
    <div className={"search-result-tab text-center col pb-1 " + ((isActive) ? "active" : "inactive")} onClick={() => onTabClick(code) }>
      <div className="search-result-tab-title font-weight-medium">{title}</div>
      {p}
    </div>
  );
};

module.exports = SearchResultTabView;
