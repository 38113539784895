import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import i18n from 'vokzal-platform/i18n';

import LastochkaLuggageView from 'vokzal-platform/components/step3/lastochka/base/lastochka_luggage/view'

LastochkaLuggageView.propTypes = {
  title: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({ title : i18n.t('sapsan_luggage') })


const LastochkaLuggageContainer = connect(
  mapStateToProps
)(LastochkaLuggageView)

export default LastochkaLuggageContainer