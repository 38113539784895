import React from 'react';

import { YMaps, Map, Placemark, ObjectManager } from 'react-yandex-maps';

import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';

import PediaTabGroup from '../../../../src/components/pedia/tab_group';
import PediaTabContent from '../../../../src/components/pedia/tab_content';

const AboutView = ({ company, contacts, content_about, content_press }) => (
    <div className="about">

      <div className="pedia-block">

        <HeadMenuPedia />

        <div className="mt-auto">
          <h1 className="h4 pb-4 mt-5 text-center font-medium-light font-weight-normal text-uppercase">О компании</h1>
        </div>

        <PediaTabGroup type="about_company" className="mb-4 pb-4"/>
      </div>

      <PediaTabContent type="info">
        <div className="font-weight-bold m-2">Юридическая информация:</div>
        <ul className="list-group list-group-flush mb-4">
          {Object.keys(company).map((key, index) => (
            <li key={index} className="list-group-item d-flex justify-content-between">
              <div className="font-weight-bold pr-2">{key}:</div>
              <div className="">{company[key]}</div>
            </li>
          ))}
        </ul>

        <div className="alert alert-secondary text-center h5 m-2 mb-4" role="alert">
          <p>Хотите нам что-то сообщить или предложить?</p>
          <a href="mailto:info@vokzal.ru" className="btn btn-dark">Нажмите сюда</a>
        </div>

        <div id="map" />

          <YMaps>
            <Map state={{ center: [55.767382, 37.629816], zoom: 15 }} height={200} width="100%">
              <Placemark geometry={{coordinates: [55.767382, 37.629816]}} />
            </Map>
          </YMaps>

        <div className="">

          <ul className="list-group list-group-flush mb-4">
            <li className="list-group-item d-flex justify-content-between">
              <div className="font-weight-bold pr-2">Наш адрес:</div>
              <div className="">{contacts.address}</div>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <div className="font-weight-bold pr-2">Телефон:</div>
              <div className="">{contacts.phone}</div>
            </li><li className="list-group-item d-flex justify-content-between">
              <div className="font-weight-bold pr-2">Email:</div>
              <div className="">{contacts.email}</div>
            </li>
          </ul>
        </div>

      </PediaTabContent>

      <PediaTabContent type="service">
        <div className="mr-2 ml-2">
          {content_about.map((html, index) => (
            <div key={index}
                 dangerouslySetInnerHTML={{__html: html}}
                 className="pb-4" />
          ))}

          <a href="/blog" className="btn btn-success btn-block text-uppercase">
            Читайте наш корпоративный блог
          </a>
        </div>
      </PediaTabContent>

      <PediaTabContent type="press">
        <div className="mr-2 ml-2">
          {content_press.map((o, index) => (
            <div key={index} className="card mb-4">
              {(o.img !== null) ? <img className="card-img-top" src={o.img.src} alt={o.img.alt} /> : null}
              <div className="card-body">
                <div className="card-title h5">{o.title}</div>
                <div className="card-subtitle text-muted">{o.date}</div>
                <div className="card-text" dangerouslySetInnerHTML={{__html: o.content}} />
              </div>
            </div>
          ))}
        </div>
      </PediaTabContent>
    </div>
);

module.exports = AboutView;