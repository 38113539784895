// @flow
import React, { Component } from 'react';

import { connect } from 'react-redux'

import PediaBusModalView from 'vokzal-platform/components/pedia/bus_modal/view'
import {bus_search} from "../../step3bus/action";
import { step2SetBusPriceAction } from '../../step2/train_details/action';
import {showStep3Bus} from "../../../../web/managers/navigation";

const mapStateToProps = state => {

  return {
    show: state.step3_bus.show_modal,
    buses: state.step3_bus.buses,
    searchFormState: state.search
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onCloseClick: () => {
      dispatch(bus_search({show_modal: false}));
    },
    onClickBus: (url, price) => {
      dispatch(step2SetBusPriceAction({adult: price.total_price, child: Math.floor(price.price / 2) + price.commission}));
      showStep3Bus(dispatch, url);
    }
  }
};

const PediaBusModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaBusModalView)

export default PediaBusModalContainer