import moment from 'moment'
import {vokzal_config} from '../config'
import queryString from "query-string";
import {getCookie, logout} from "../utils/functions";
import {user_register} from "./user_register";
// import {showLoginModalAction} from "../components/shared/login_modal/action";
import {showLoginModalAction} from "../components/shared/login_modal/action";

export const user_request = (searchUrlWithParams, post, onDataLoaded, onError) => {
    // const requested_at = moment();
    // проверка токена и обмен если нужно, сделать цепочку вызовов
    // let refresh_token = 'da7a3a98-63e6-4b17-94ef-f0c37eb2732a';  // getCookie('refresh_token');
    let refresh_token = getCookie('refresh_token');
    // const remember = localStorage.getItem('remember') === 1 ? 1 : 0;
    let remember = getCookie('remember_login') === 1 ? 1 : 0;
    let access_token = localStorage.getItem('access_token');
    const roles = localStorage.getItem('roles');

    // console.log('remember_login ', getCookie('remember_login'), post);
    if (process.env.NODE_ENV !== 'production') {
        console.log('refresh_token ', refresh_token);
    }
    const url = vokzal_config.rest.base_url + searchUrlWithParams;

    let exp = localStorage.getItem('exp');
    const unix = moment().unix();
    const redirect = window.location.pathname.slice(1,5) === "user"; // в личном кабинете мы или на других страницах




    // test localhost
    // remember = 1;
    // refresh_token = 'f459adf8-6c5d-43e4-aeb6-cce2ccc0039e';
    // access_token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MDQwNTI1NzEsImV4cCI6MTYwNDA1NjE3MSwicm9sZXMiOlsiUk9MRV9VU0VSIl0sInVzZXJuYW1lIjoiaWxhNGQ2dmJzeiJ9.pZJDHt7hm0ghjUItiY1sx1Cr0UkARNzxGp5q-ZtM7V03SYLKZAk3w3c_sR-UJXc_b83woabGst8k_to3eQupmvYrZCr57H1-jyEUEU_Sy0Hu-CFzRs-hBCeo5TZ4AJgQTcxtw9EhDddAhUJJWrzRWU5TQHrQl0Og3rCg2Vwf3tDMQPl7v71go5ed36gBqUbwNVQs6kVzU7hhSyczipJWAW7CxfT8BKhYAMHekaUb9gImyMcdoytHdySMgtnHuMbgauIJsE-zCR5GSDZMzzW2eRiQc_dlg48vvI7XZXCfOMwnvMusXBMt1oZ4a6q07QNFtIC1CgzVGfAo54CW-sVrGoy4299Q1eLzjXCSMRCTIimdnjUV425PxtBBdCPrfqfoXAzl2xUimx2w9W_OjbOgh7_cyhKYfN80T29MqmbqZej6w5o41pdS_ZHuoI9rmIB3waZT8SOyZWIF2tJxcDD2VuSrMf5nYBgyQxmCFl9D_LzPzkjpBGpMLXbVHf3yNsbCCNXLyPFzaUKt3_O9FT9OyKMXdA-mRgy7CU5nw9wTPPbr7WHz5aSHPmcoulAhDukEX_LS1v8pTDOS5BEI2dH-qyYQM-3fMojtsrMAIjof5auPayIEJfdFhtPdWlSyjYz_lpzmY0LnDYPoM-EkZOl121uTAcCf_lN-pwyroBdNmvo";
    // exp = '1604061571';




    if (!refresh_token ) {
        if (process.env.NODE_ENV !== 'production') {
            console.log('Токен не существует ');
        }
        if (redirect) {
            window.location.href="/";
            // dispatch(showLoginModalAction())  // передать dispatch при вызове  и прописать в параметрах
        }
    } else if (exp - 5 > unix) {
        // исправить!!!! - неправильно работает в мозилле локально
        // без этого в следующем пункте параметры не передаются
        if (process.env.NODE_ENV !== 'production') {
            console.log('Токен действителен ');
        }
        // можно запрашивать данные
        f(url, {...post, refresh_token, access_token}, onError, onDataLoaded)
    } else {
        if (process.env.NODE_ENV !== 'production') {
            console.log('Токен просрочен ');
        }
        if (refresh_token) {

            // закомментировать user_register для обновления в мозилле локально
            // и раскомментировать следующие строки
            // logout();
            // if (redirect) {
            //     window.location.href="/";
            //     //dispatch(showLoginModalAction()) // передать dispatch при вызове  и прописать в параметрах
            // }


            user_register('/api/refresh', {token: refresh_token, remember}, (time_spent, response, post) => {
                if (process.env.NODE_ENV !== 'production') {
                    console.log('Токен обновлён ');
                }
                // console.log('Токен обновлён ', time_spent, response);
                refresh_token = response.refresh_token;
                access_token = response.access_token;
                // можно запрашивать данные
                f(url, {...post, refresh_token, access_token}, onError, onDataLoaded);
            }, (error) => {
                if (process.env.NODE_ENV !== 'production') {
                    console.log('Необходимо авторизоваться ', error);
                }
                logout();
                if (redirect) {
                    window.location.href="/";
                    //dispatch(showLoginModalAction()) // передать dispatch при вызове  и прописать в параметрах
                }

                // нужно перейти на страницу логина - вывести всплывающее окно
            });
        }
    }
}

const f = (url, post, onError, onDataLoaded) => {
    let fetchParams = vokzal_config.rest_api.fetchParams;
    // fetchParams.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');

    fetchParams.body = queryString.stringify(post);

    fetchParams.method = 'POST';
    const requested_at = moment();
    fetch(url, fetchParams)
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.hasOwnProperty('error')) {
                onError(Object.assign(responseJson.error));
            } else {
                onDataLoaded(moment().diff(requested_at), responseJson);
            }
        });
}
