// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import PediaTrainInfoView from 'vokzal-platform/components/pedia/train_info/view'

PediaTrainInfoView.propTypes = {
};

const mapStateToProps = state => {
  return {
    ...state.pedia_train.train,
    trainOthers: state.pedia_train.trainOthers,
    schedule: state.pedia_train.schedule,
    // showOrderForm: state.pedia_schedule.schedule[0].showOrderForm
    showOrderForm: state.pedia_train.train.showOrderForm
  };
}

const PediaTrainInfoContainer = connect(
  mapStateToProps
)(PediaTrainInfoView)

export default PediaTrainInfoContainer