import { handleActions } from 'redux-actions'

import { raspisanieBusInitialStatePlatform } from 'vokzal-platform/components/pedia/raspisanie_bus/reducer';

import { RASPISANIE_BUS_FETCH_COMPLETE_ACTION, SHOW_ORDER_FORM_BUS } from "./action";

let initialState = { loaded: false }

initialState = Object.assign({}, initialState, raspisanieBusInitialStatePlatform())

const PediaRaspisanieBusReducer = handleActions({
    [RASPISANIE_BUS_FETCH_COMPLETE_ACTION]: (state, action) => {
      return { ...state, ...action.payload }
    },
    [SHOW_ORDER_FORM_BUS]: (state, action) => {

      const schedule = state.schedule.map(t => {
        t.showOrderForm = (t.number === action.payload);
        return t;
      });

      return { ...state, schedule }
    },
}, initialState)

export default PediaRaspisanieBusReducer