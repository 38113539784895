import React from 'react';

import SeatedCabinPlace from '../../../../../../src/components/step3/seated_cabin_place';

const StrijCabinView = ({ carNumber, cabinInfo, inSubBlock = false, extraSpace = false }) => (
  <div className={"train-car-map-seated-cabin d-flex justify-content-between " + ((extraSpace) ? 'pt-2 pb-2' : '')}>
    <div className="train-car-map-seated-cabin-place-block d-flex">
      { (cabinInfo.leftWindow !== null) ? <SeatedCabinPlace carNumber={carNumber} type={cabinInfo.leftWindow.type} seatNumber={cabinInfo.leftWindow.number} isFront={cabinInfo.leftWindow.isFront} className={(inSubBlock) ? 'ml-1 mt-2 mr-2 mb-1' : 'mt-2 mb-2 mr-1 ml-2'} /> : null }
      { (cabinInfo.leftSide !== null)   ? <SeatedCabinPlace carNumber={carNumber} type={cabinInfo.leftSide.type}   seatNumber={cabinInfo.leftSide.number}   isFront={cabinInfo.leftSide.isFront}   className={(inSubBlock) ? 'ml-2 mt-2 mr-2 mb-1' : 'mt-2 mb-2 mr-1 ml-1'} /> : null }
    </div>

    <div className="train-car-map-seated-cabin-place-block d-flex">
      { (cabinInfo.rightSide !== null)   ? <SeatedCabinPlace carNumber={carNumber} type={cabinInfo.rightSide.type}   seatNumber={cabinInfo.rightSide.number}   isFront={cabinInfo.rightSide.isFront}   className={(inSubBlock) ? 'ml-2 mt-2 mr-2 mb-1' : 'mt-2 mb-2 mr-1 ml-1'} /> : null }
      { (cabinInfo.rightWindow !== null) ? <SeatedCabinPlace carNumber={carNumber} type={cabinInfo.rightWindow.type} seatNumber={cabinInfo.rightWindow.number} isFront={cabinInfo.rightWindow.isFront} className={(inSubBlock) ? 'ml-2 mt-2 mr-1 mb-1' : 'mt-2 mb-2 mr-2 ml-1'} /> : null }
    </div>
  </div>
)
module.exports = StrijCabinView;