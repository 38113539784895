// @flow
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PediaHeaderImageView from 'vokzal-platform/components/pedia/header_image/view'

PediaHeaderImageView.propTypes = {
  caption : PropTypes.string.isRequired,
  url : PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return state.pedia_station.image
}

const PediaHeaderImageContainer = connect(
  mapStateToProps
)(PediaHeaderImageView)

export default PediaHeaderImageContainer