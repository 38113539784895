import React from 'react';
import PropTypes from 'prop-types';
import { TrainCarCategoryItemPropType } from '../../../../src/types/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMale, faFemale } from '@fortawesome/free-solid-svg-icons'
import { getCategoryType } from "../../../../src/utils/functions";
import {ChildFriendly, Pets} from "@material-ui/icons";

const TrainSeatClassView = ({ categoryItem, onSeatClassClick, twoStorey, carCategory, sapsan, priceDiapason }) => {
    const type = !carCategory ? <div className="type">
        <span className="full">{getCategoryType(categoryItem.type)}</span>
        <span className="min">{categoryItem.type}</span>
    </div> : '';

    let diapason = (priceDiapason.hasOwnProperty('upper') && priceDiapason.hasOwnProperty('lower')) ?
        'от ' + priceDiapason.upper +  ' ₽ до ' + priceDiapason.lower  +  ' ₽' : categoryItem.price + ' ₽';

    return (
      <div className={((categoryItem.isActive) ? 'active' : '') + " trains-seats-class col p-1"} onClick={() => onSeatClassClick(categoryItem, sapsan) }>
        {twoStorey ? <div className="trains-floor">Двухэтажный вагон</div>: ''}
        <div className="font-medium-light">{categoryItem.title}</div>
        {/*<div className="font-weight-medium font-medium-light ">{categoryItem.price} &#8381;</div>*/}
        <div className="font-weight-medium font-medium-light ">{diapason}</div>
        <div>{categoryItem.seatsCnt}</div>
          {
              categoryItem.sign ?
                  <div className="sex">Есть мужские и женские купе
                      <div className="icons">
                          <span className="male"><FontAwesomeIcon icon={faMale} /></span>
                          <span className="female"><FontAwesomeIcon icon={faFemale} /></span>
                      </div>
                  </div> :
                  ''
          }
          {
              categoryItem.pet.indexOf('* Ж') === 0 ?
                  <div className="sex">Места для пассажиров с животными, стоимость провоза животного включена в стоимость билета
                      <div className="sapsan-animals-cabin-logo">
                          <div className="animal-icon" data-title="Разрешён провоз животных">
                              <Pets />
                          </div>
                      </div>
                  </div> :
                  ''
          }
          {
              categoryItem.pet.indexOf('* Д') === 0 ?
                  <div className="sex">Оборудованные места для пассажиров с детьми
                      <div className="sapsan-children-cabin-logo">
                          <ChildFriendly />
                      </div>
                  </div> :
                  ''
          }
      </div>
)};

TrainSeatClassView.propTypes = {
  categoryItem : TrainCarCategoryItemPropType.isRequired,
  onSeatClassClick : PropTypes.func.isRequired,
};

module.exports = TrainSeatClassView;