import React from 'react';

import SapsanWardrobe from '../../../../../../src/components/step3/sapsan/base/sapsan_wardrobe';

const SapsanWardrobeCabinWithMinibarView = ({ wardrobeTitle, minibarTitle, autoHeightWardrobe, autoHeightMinibar }) => (
  <div className="sapsan-wardrobe-minibar-cabin d-flex justify-content-between mb-2">

    <SapsanWardrobe title={minibarTitle} autoHeight={autoHeightMinibar} />
    <SapsanWardrobe title={wardrobeTitle} autoHeight={autoHeightWardrobe} />
  </div>
);

module.exports = SapsanWardrobeCabinWithMinibarView;