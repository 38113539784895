// @flow
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import {SearchResultTrainPricePropType, TrainTypesWithCarTypes} from '../../../../src/types/';

import SapsanPricesView from 'vokzal-platform/components/step2/sapsan_prices/view'

SapsanPricesView.propTypes = {
  priceItems: PropTypes.objectOf(SearchResultTrainPricePropType).isRequired,
  onCarClassClick : PropTypes.func.isRequired,
};

import { step2SelectCarTypeAction } from "../train_details/action";
import {showStep3} from 'vokzal-platform/managers/navigation';
import {reset_step3_state} from '../../step3/train_seats/action';
import { trainUrlStoreAction } from '../../pedia/train/action';
import {setStep3Action} from "../../home/search_form/action";


const mapStateToProps = state => {

  const priceTypes = TrainTypesWithCarTypes['sapsan'].map((c) => (c.carCategory));

  let urlParams = null; let priceType = null;

  return {
    urlParams,
    carCategory:'С',
    priceTypes,
    priceType,
    train : state.step2_details.train
  };

};

const mapDispatchToProps = dispatch => {
  return {
    onCarClassClick: (carCategory, service, urlParams, priceItems, trainItem) => {
      if (service === undefined) {
        service = null;
      }
      showStep3(dispatch, urlParams);
      dispatch(trainUrlStoreAction(trainItem.numberUrl));
      dispatch(setStep3Action({ step3: urlParams }));
      dispatch(step2SelectCarTypeAction({ carCategory, service, prices: priceItems }));
      dispatch(reset_step3_state());
    }
  }
};

const SapsanPricesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SapsanPricesView);

export default SapsanPricesContainer
