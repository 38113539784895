import { make_station_groups, make_features } from '../../../../src/api/fetch_pedia_trains_list';

export const trainsListInitialStatePlatform = () => {
  let initialState = {}

  if (typeof pedia_trains_list !== 'undefined') {

    initialState.loaded = true;
    // initialState.trains = pedia_trains_list;
  }

  return initialState;
}