import React from 'react';

import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';


import LoginModal from '../../../../src/components/shared/login_modal';
import RegisterModal from '../../../../src/components/shared/register_modal';
import RenewModal from '../../../../src/components/shared/renew_modal';
import {Link} from "react-router-dom";
import i18n from "../../../i18n";
import BootstrapLabeledInput from '../../base/bootstrap_labeled_input';

const ChangePasswordView = ({title, password2, password2_error, password, password_error, password_old, password_old_error, onPasswordOldChanged, onPassword2Changed, onPasswordChanged, error, success, onSubmit, repeat_error, onDataLoaded, onError, onFocusReadonly, password_enabled}) => (
    <div className="edit-profile mt-5 mt-md-0">
            <h5 className="text-center">Изменение пароля</h5>
            {success ?
                <div className="success-message">
                        <p>Пароль для входа в личный кабинет успешно обновлён</p>
                </div>
                : null}

            <span>Текущий пароль</span>
            <BootstrapLabeledInput label={i18n.t('confirmation_password')}
                                   fieldsetClass={password_old_error ? "input-invalid" : ""}
                                   name="password" value={password_old} required={true}
                                   maxlength="30"
                                   type="password"
                                   is_disabled={password_enabled}
                                   autocomplete="off"
                                   onFocus={() => onFocusReadonly()}
                                   onChange={(value, inputElement) => onPasswordOldChanged(value, password_old, inputElement)} />
            {password_old_error ? <p className="label error">{password_old_error}</p> : null}

            <span>Придумайте пароль</span>
            <span className="label">не менее 8 символов, включая строчные, заглавные буквы и цифры</span>
            <BootstrapLabeledInput label={i18n.t('confirmation_password')}
                                   fieldsetClass={password_error ? "input-invalid" : ""}
                                   name="password" value={password} required={true}
                                   maxlength="30"
                                   type="password"
                                   is_disabled={password_enabled}
                                   autocomplete="off"
                                   onFocus={() => onFocusReadonly()}
                                   onChange={(value, inputElement) => onPasswordChanged(value, password, inputElement)} />
            {password_error ? <p className="label error">{password_error}</p> : null}
            <span>Повторите его</span>
            <BootstrapLabeledInput label={i18n.t('confirmation_password')}
                                   fieldsetClass={password2_error ? "input-invalid" : ""}
                                   name="password2" value={password2} required={true}
                                   maxlength="15"
                                   type="password"
                                   is_disabled={password_enabled}
                                   autocomplete="off"
                                   onFocus={() => onFocusReadonly()}
                                   onChange={(value, inputElement) => onPassword2Changed(value, password2, inputElement)} />
            {password2_error ? <span className="label error">{password2_error}</span> : null}
            {repeat_error ? <span className="label error">{repeat_error}</span> : null}

            {error ? <span className="label error">{error}</span> : null}
            <div className="standart-button centered mb-3 mt-4" onClick={() => onSubmit(password_old, password, password2, onDataLoaded, onError)}>
                    <span>{i18n.t('save')}</span>
            </div>
    </div>
);

module.exports = ChangePasswordView;