import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import { showContentLink } from 'vokzal-platform/managers/navigation'

import { ContentTreePropType } from '../../../types';

import { toggle_content_tree } from '../../home/action'

import ContentTreeView from 'vokzal-platform/components/home/content_tree/view'

ContentTreeView.propTypes = {
  items: PropTypes.arrayOf(ContentTreePropType).isRequired,
  onExpandClick : PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    items: state.content_tree.items
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onExpandClick: (content_tree_item) => {
      if (content_tree_item.content !== null) {
        dispatch(toggle_content_tree(content_tree_item));
      } else {
        showContentLink(dispatch, content_tree_item);
      }
    }
  }
}


const ContentTreeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentTreeView)

export default ContentTreeContainer