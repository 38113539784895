import React from 'react';
import { Modal } from 'react-bootstrap';

import StationModalRow from '../../../../src/components/home/station_modal_row';
import BootstrapInput from '../../base/bootstrap_input';

import i18n from 'vokzal-platform/i18n'

const StationModalView = ({ search_type, show, selected_input, selected_station, input_value, stations, onItemClick, onCloseClick, onEnterPress, onKeyUp, timer_id }) => (
  <Modal show={show} animation={false} dialogClassName="m-0 h-100 resp-search-modal" backdropClassName="show resp-search-modal">
    <Modal.Header>
      <div className="d-flex flex-column">
        <div className="p-0 d-flex justify-content-between">
          <Modal.Title id="contained-modal-title-lg" componentClass="h5">{selected_input === 'from' ? i18n.t('station_from') : i18n.t('station_to')}</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onCloseClick() }>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="p-0">
          <BootstrapInput name="station_name" className={'p-0 pb-2 search-' + search_type} autoFocus={true} value={input_value} onKeyUp={(input_value) => onKeyUp(input_value, search_type, timer_id)} autocomplete="off" onKeyPress={() => onEnterPress(selected_input, stations)} />

        </div>
      </div>

    </Modal.Header>
    <Modal.Body bsClass="modal-body pt-0">

      <div className="list-group">
        {
          stations.map(function(m, index){
            m.key = index;
            return (<StationModalRow {...m} onClick={() => onItemClick(selected_input, m)} />);
          })
        }
      </div>
    </Modal.Body>
  </Modal>
);

module.exports = StationModalView;