import { handleActions } from 'redux-actions'

import { trainsListInitialStatePlatform } from 'vokzal-platform/components/pedia/trains_list/reducer';

import { LOAD_PEDIA_TRAINS_LIST } from "./action";

const defaultState = {
  loaded: false, urlWithParams: null, trains: null
}

const initialState = Object.assign({}, defaultState, trainsListInitialStatePlatform())

const PediaTrainsListReducer = handleActions({
  [LOAD_PEDIA_TRAINS_LIST]: (state, action) => {
    return { ...state }
  },
}, initialState)

export default PediaTrainsListReducer