import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import UserListView from 'vokzal-platform/components/step4/user_list/view'
import { UserSelectedSeatPropType } from '../../../../src/types/';

import { documentTypesLoadedAction } from './action';

import { searchFormUrlToPassengersData } from '../../../utils/url_params';
import { fetch_document_types } from '../../../api/fetch_document_types';

import { gaTiming } from 'vokzal-platform/ga'
import queryString from "query-string";
import {changeButtonStateAction, resetErrorAction} from "../action";

UserListView.propTypes = {
  fetchDocumentTypes: PropTypes.bool.isRequired,
  urlWithParams: PropTypes.string,
  userSelectedSeats: PropTypes.arrayOf(UserSelectedSeatPropType).isRequired
};


class UserListContainer extends Component {

  componentDidMount() {
    // чтобы запрос на сервер отправлялся каждый раз при переходе с третьего шага на четвёртый
    // if (this.props.fetchDocumentTypes) {
      fetch_document_types(this.props.urlWithParams, this.props.onDataLoaded);
      this.props.onDataClean();
  }

  render() {
    return <UserListView {...this.props} />;
  }
}

const mapStateToProps = state => {
  const queryObject = queryString.parse(location.search);
  const p = state.step3_passengers;
  let s = p.selected_seats.slice(0);

  const userSelectedSeats = p.passengers.map(
    (o) => ({ type: o.type, selectedSeat: (o.type === 'infant') ? null : s.shift(), userData: o })
  );

  const fetchDocumentTypes = state.step4_documents.document_types.length === 0;
  const type = queryObject.trainNumber ? 'train' : 'bus';

  const data = {};
  if (queryObject.busID) {
    data.busID = queryObject.busID;
    data.fromBusStop = queryObject.fromBusStop;
    data.toBusStop = queryObject.toBusStop;
    data.busNumber = queryObject.busNumber;
  }
  const urlWithParams = searchFormUrlToPassengersData(state, type, data);

  return { fetchDocumentTypes, userSelectedSeats, urlWithParams };
};

const mapDispatchToProps = dispatch => {
  return {
    onDataLoaded: (dataLoadingTime, document_types, payment_types, stop_buy) => {
      dispatch(documentTypesLoadedAction({ document_types, payment_types, stop_buy }))
      gaTiming("Step 4", dataLoadingTime, "Document Types loading")
    },
    onDataClean: () => {
      dispatch(resetErrorAction());
      dispatch(changeButtonStateAction('NEW'));
    }
  }

};

UserListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListContainer);

export default UserListContainer