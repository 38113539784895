// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux'

// import { getLocation } from 'vokzal-platform/managers/geo_location'
import { searchStationsByLocation  } from '../../../api/search_station_api'

import { setGeoLocationAction } from './action'

import i18n from 'vokzal-platform/i18n'
import SearchFormView from 'vokzal-platform/components/shared/search_form/view'

SearchFormView.propTypes = {
  title: PropTypes.string.isRequired,
};


class SearchFormContainer extends Component {

  componentDidMount() {
    // let refDomain = document.referrer.split('/')[2];
    // if (this.props.geo_enabled && refDomain && refDomain !== document.location.host) {
    //   if (!this.props.geo_fetched) {
    //     getLocation(this.props.geo_coords, this.props.onGeoLocationFetched, () => {});
    //   }
    // }
  }

  render() {
    return <SearchFormView {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    geo_fetched : state.search.geo_fetched,
    geo_enabled : state.search.geo_enabled,
    geo_coords : state.search.geo_coords,
    title: i18n.t('search_title')
  };
}

const mapDispatchToProps = dispatch => ({
  onGeoLocationFetched: (position) => {
      dispatch(setGeoLocationAction(position));
      searchStationsByLocation(dispatch, position);
  },
})

SearchFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFormContainer)

export default SearchFormContainer