import React from 'react';
import i18n from 'vokzal-platform/i18n';
import OfferModal from '../../../../src/components/confirmation/offer_modal';

const TextAgreementView = ({ payment_type, commission }) => {

  const intellectmoney = <OfferModal text={i18n.t('confirmation_agreement') + commission} className="confirmation-text-agreement font-medium-light small m-3 mr-md-0"/>;
  const euroset = <div className="confirmation-text-agreement nal font-medium-light ml-1 mr-3 mb-3 mt-1 mr-md-0">
      <h4>Оплата наличными, как действовать:</h4>
      <ol>
          <li>Обратитесь в любой офис "Евросети" или "Связного".
              <div><a href="https://www.svyaznoy.ru/shops">Адреса салонов</a></div>
          </li>
          <li>Скажите кассиру, что вы заказали билеты на VOKZAL.RU и хотите оплатить их.</li>
          <li>Назовите девятизначный номер заказа.</li>
          <li>Получите пре-чек и проверьте выбранные места.</li>
          <li>Оплатите билеты и сохраните чек.</li>
          <li><strong>Ваши билеты поступят на электронную почту в течение нескольких минут!</strong></li>
      </ol>

      <div className="attension">
        <span>Внимание!</span> Места пассажиров, указанные ниже гарантируются в случае оплаты заказа в течение 15 минут. Если оплата
          произойдет позже, эти места могут быть уже проданы. В таком случае мы постараемся автоматически подобрать
          максимально похожие варианты.
          {intellectmoney}
      </div>

  </div>;
  return (  payment_type === 'euroset' ? euroset : intellectmoney);
}

;

module.exports = TextAgreementView;