import {connect} from 'react-redux'
import TextAgreementView from 'vokzal-platform/components/confirmation/text_agreement/view'

const mapStateToProps = state => {
    const commission = state.confirmation.order_form.commission.toFixed(2) + 'р.';
    return {
        payment_type : state.step4_documents.payment_type,
        commission
    }
}

const TextAgreementContainter = connect(
    mapStateToProps
)(TextAgreementView)

export default TextAgreementContainter