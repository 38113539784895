import React, {lazy, Suspense} from 'react';

import i18n from 'vokzal-platform/i18n';

import { PediaPreLoader } from '../../../../src/components/shared/preloader';

import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';

import PediaScheduleItem from '../../../../src/components/pedia/schedule_item';

import StationModal from '../../../../src/components/home/station_modal'
import DatesModal from '../../../../src/components/home/dates_modal'

import SearchForm from '../../../../src/components/shared/search_form';
const FooterInfo = lazy(() => import('../../../../src/components/shared/footer_info/index'));
const FooterLinks = lazy(() => import('../../../../src/components/home/footer_links/index'));

const PediaTrainListView = ({ loaded, title, trains }) => (
    <div className="pedia">
        <div className="pedia-block">
          <div className="pedia-header-block">

            <HeadMenuPedia />

            <div className={"pedia-header-short d-flex align-items-start flex-column ml-3 mr-3 pedia-header-dark mt-4"}>
              <h1 className="h4 mb-2 font-medium-light font-weight-normal text-uppercase">Посмотреть расписание поездов в 2021 году, забронировать ЖД-билет</h1>
            </div>
            <SearchForm />
          </div>

          <div className="description-block mt-4 pr-4 pl-4">
            <p>
              Vokzal.ru – это ресурс, где для вашего удобства собрана максимально полная и актуальная информация о поездах
              дальнего следования: их типы, номера и названия, количество и категории вагонов, а также данные о
              дополнительных услугах, которые в них предлагаются.
            </p>

            <p>
              Представлено подробное описание маршрутов поездов, курсирующих в различных направлениях по железнодорожным
              путям России, стран СНГ и Прибалтики. Указаны все населенные пункты, через которые следует маршрут, время
              отправления и прибытия поездов, расстояние между конечными точками и время в пути.
            </p>

            <p>Также вы можете узнать, на каких станциях останавливается поезд и какое количество времени затрачивается на стоянки на них. </p>
            <p>Кроме того, вы можете познакомиться с историей конкретного поезда и узнать интересные факты о нем.</p>
            <p>Билет на любой из поездов дальнего следования вы можете купить на сайте Вокзал.ру за 2 минуты в режиме реального времени.</p>
          </div>

          <div className="pedia-trains-list-block content-tree mt-1 pt-2 pb-2">
            <div className="content-tree-row">
              {trains.map((t, index) => (
                <PediaScheduleItem key={index} train={t} group_name="" showOrderForm={t.showOrderForm} showWaitTime={false} />
              ))}
            </div>
          </div>


          <PediaPreLoader show={!loaded} />

          <StationModal search_type="local" />
          <DatesModal />
        </div>

      <Suspense fallback={<div>Загрузка...</div>}>
        <FooterInfo titleText={i18n.t('goodbye')} />
      </Suspense>

      <Suspense fallback={<div>Загрузка...</div>}>
        <FooterLinks />
      </Suspense>
    </div>
);

module.exports = PediaTrainListView;