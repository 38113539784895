import React from 'react';

import SapsanCabin from '../../../../../src/components/step3/sapsan/base/sapsan_cabin';
import SapsanDoubleLuggageCabin from '../../../../../src/components/step3/sapsan/base/sapsan_double_luggage_cabin';
import SapsanWardrobeCabinWithSeats from '../../../../../src/components/step3/sapsan/base/sapsan_wardrobe_cabin_with_seats';
import SapsanCabinDelimiter from '../../../../../src/components/step3/sapsan/base/sapsan_cabin_delimiter';
import SapsanToilet from '../../../../../src/components/step3/sapsan/base/sapsan_toilet';
import LastochkaToilet from "../../lastochka/lastprem_w1_v2/view";

const Sapsan1GClassMapView = ({ carNumber, cabins }) => (
  <div className="train-car-select-seats-map rounded pt-2">
    <SapsanDoubleLuggageCabin carNumber={carNumber} />

    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[0]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[1]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[2]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[3]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[4]} />
      <div className="table table-left"></div>
      <div className="table table-right"></div>
      <div className="clear"></div>
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[5]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[6]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[7]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[8]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[9]} />


    <SapsanWardrobeCabinWithSeats carNumber={carNumber} cabinInfo1={cabins[10]} cabinInfo2={cabins[11]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[12]} />
      <div className="table table-left table-min"></div>
      <div className="table table-right table-min"></div>
      <div className="clear"></div>
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[13]} />

      <SapsanCabinDelimiter />
      <div className="right">
          <SapsanToilet className="mt-3"/>
      </div>
      <div className="clear"></div>
      <div className="right">
          <SapsanToilet className="mt-3 mb-3" />
      </div>
      <div className="clear"></div>
  </div>
);

module.exports = Sapsan1GClassMapView;