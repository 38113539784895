const stdSeat = (number,  isFront) => (
  { number,  isFront, type : 'std'}
)

const sideSeat = (number,  isFront, isDisabled) => (
  { number,  isFront, type : (isDisabled) ? 'side-disabled-person' : 'side'}
)

const reverseIsFront = (seat) => {

  if (seat !== null) {
    let s = Object.assign({}, seat);
    s.isFront = !s.isFront;

    return s;
  } else {
    return seat;
  }
}

export const SeatNumbersLastochkaHeadCar = [
  { leftWindow : stdSeat(61, false),  leftCenter : stdSeat(62, false), leftSide : null,               rightSide : null,               rightCenter : stdSeat(66, false), rightWindow : stdSeat(65, false)  },
  { leftWindow : stdSeat(59, true),   leftCenter : stdSeat(60, true),  leftSide : null,               rightSide : null,               rightCenter : stdSeat(64, true),  rightWindow : stdSeat(63, true)   },
  { leftWindow : stdSeat(55, false),  leftCenter : stdSeat(56, false), leftSide : null,               rightSide : null,               rightCenter : stdSeat(58, false), rightWindow : stdSeat(57, false)  },
  { leftWindow : stdSeat(45, false),  leftCenter : stdSeat(46, false), leftSide : null,               rightSide : stdSeat(54, false), rightCenter : stdSeat(52, false), rightWindow : stdSeat(51, false)  },
  { leftWindow : stdSeat(43, true),   leftCenter : stdSeat(44, true),  leftSide : null,               rightSide : stdSeat(50, true),  rightCenter : stdSeat(48, true),  rightWindow : stdSeat(47, true)   },
  { leftWindow : stdSeat(33, false),  leftCenter : stdSeat(34, false), leftSide : null,               rightSide : stdSeat(42, false), rightCenter : stdSeat(40, false), rightWindow : stdSeat(39, false)  },
  { leftWindow : stdSeat(31, false),  leftCenter : stdSeat(32, false), leftSide : null,               rightSide : stdSeat(38, false), rightCenter : stdSeat(36, false), rightWindow : stdSeat(35, false)  },
  { leftWindow : stdSeat(29, false),  leftCenter : stdSeat(30, false), leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null                },

  { leftWindow : sideSeat(84, false), leftCenter : null,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null                },
  { leftWindow : sideSeat(82, false), leftCenter : null,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null                },
  { leftWindow : sideSeat(80, false), leftCenter : null,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null                },
  { leftWindow : sideSeat(78, false), leftCenter : null,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null                },
  { leftWindow : sideSeat(76, false), leftCenter : null,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : sideSeat(72, false) },
  { leftWindow : sideSeat(74, false), leftCenter : null,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : sideSeat(70, false) },
  { leftWindow : null,                leftCenter : null,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : sideSeat(68, false) },

  { leftWindow : stdSeat(21, false),  leftCenter : stdSeat(22, false), leftSide : stdSeat(24, false), rightSide : null,               rightCenter : stdSeat(28, false), rightWindow : stdSeat(27, false)  },
  { leftWindow : stdSeat(17, false),  leftCenter : stdSeat(18, true),  leftSide : stdSeat(20, true),  rightSide : null,               rightCenter : stdSeat(26, true),  rightWindow : stdSeat(25, true)   },
  { leftWindow : stdSeat(9, false),   leftCenter : stdSeat(10, false), leftSide : stdSeat(12, false), rightSide : null,               rightCenter : stdSeat(16, false), rightWindow : stdSeat(15, false)  },
  { leftWindow : stdSeat(5, true),    leftCenter : stdSeat(6, true),   leftSide : stdSeat(8, true),   rightSide : null,               rightCenter : stdSeat(14, true),  rightWindow : stdSeat(13, true)   },
  { leftWindow : stdSeat(1, true),    leftCenter : stdSeat(2, true),   leftSide : null,               rightSide : null,               rightCenter : stdSeat(4, true),   rightWindow : stdSeat(3, true)    },
]

export const SeatNumbersLastochkaTailCar = SeatNumbersLastochkaHeadCar.slice(0).reverse()

export const SeatNumbersLastochkaCenterCar_2_7 = [
  { leftWindow : stdSeat(113, false),  leftCenter : stdSeat(114, false), leftSide : null,                rightSide : null,               rightCenter : stdSeat(116, false), rightWindow : stdSeat(115, false)  },
  { leftWindow : stdSeat(105, false),  leftCenter : stdSeat(106, false), leftSide : stdSeat(108, false), rightSide : null,               rightCenter : stdSeat(112, false), rightWindow : stdSeat(111, false)  },
  { leftWindow : stdSeat(101, true),   leftCenter : stdSeat(102, true),  leftSide : stdSeat(104, true),  rightSide : null,               rightCenter : stdSeat(110, true),  rightWindow : stdSeat(109, true)   },
  { leftWindow : stdSeat(93,  false),  leftCenter : stdSeat(94, false),  leftSide : stdSeat(96, false),  rightSide : null,               rightCenter : stdSeat(100, false), rightWindow : stdSeat(99, false)   },
  { leftWindow : stdSeat(89,  true),   leftCenter : stdSeat(90, true),   leftSide : stdSeat(92, true),   rightSide : null,               rightCenter : stdSeat(98, true),   rightWindow : stdSeat(97, true)    },
  { leftWindow : sideSeat(122, true),  leftCenter : null,                leftSide : null,                rightSide : null,               rightCenter : null,                rightWindow : sideSeat(124, false) },

  { leftWindow : stdSeat(85, false),   leftCenter : stdSeat(86, false),  leftSide : null,                rightSide : null,               rightCenter : stdSeat(88, false), rightWindow : stdSeat(87, false)    },
  { leftWindow : stdSeat(83, false),   leftCenter : stdSeat(84, false),  leftSide : null,                rightSide : stdSeat(80, false), rightCenter : stdSeat(78, false), rightWindow : stdSeat(77, false)    },
  { leftWindow : stdSeat(81, true),    leftCenter : stdSeat(82, true),   leftSide : null,                rightSide : stdSeat(76, true),  rightCenter : stdSeat(74, true),  rightWindow : stdSeat(73, true)     },
  { leftWindow : stdSeat(63, true),    leftCenter : stdSeat(64, true),   leftSide : null,                rightSide : stdSeat(72, true),  rightCenter : stdSeat(70, true),  rightWindow : stdSeat(69, true)     },
  { leftWindow : stdSeat(61, false),   leftCenter : stdSeat(62, false),  leftSide : null,                rightSide : stdSeat(68, true),  rightCenter : stdSeat(66, true),  rightWindow : stdSeat(65, true)     },
  { leftWindow : stdSeat(55, true),    leftCenter : stdSeat(56, true),   leftSide : null,                rightSide : stdSeat(60, false), rightCenter : stdSeat(58, false), rightWindow : stdSeat(57, false)    },
  { leftWindow : stdSeat(53, false),   leftCenter : stdSeat(54, false),  leftSide : null,                rightSide : stdSeat(52, false), rightCenter : stdSeat(50, false), rightWindow : stdSeat(49, false)    },
  { leftWindow : stdSeat(43, false),   leftCenter : stdSeat(44, false),  leftSide : null,                rightSide : stdSeat(48, true),  rightCenter : stdSeat(46, true),  rightWindow : stdSeat(45, true)     },
  { leftWindow : stdSeat(31, false),   leftCenter : stdSeat(32, false),  leftSide : null,                rightSide : stdSeat(42, false), rightCenter : stdSeat(40, false), rightWindow : stdSeat(39, false)    },
  { leftWindow : stdSeat(29, true),    leftCenter : stdSeat(30, true),   leftSide : null,                rightSide : stdSeat(38, true),  rightCenter : stdSeat(36, true),  rightWindow : stdSeat(35, true)     },
  { leftWindow : null,                 leftCenter : null,                leftSide : null,                rightSide : null,               rightCenter : stdSeat(34, true),  rightWindow : stdSeat(33, true)     },

  { leftWindow : sideSeat(118, true),  leftCenter : null,                leftSide : null,                rightSide : null,               rightCenter : null,                rightWindow : sideSeat(120, false) },
  { leftWindow : stdSeat(21,  false),  leftCenter : stdSeat(22, false),  leftSide : stdSeat(24, false),  rightSide : null,               rightCenter : stdSeat(28, false),  rightWindow : stdSeat(27,  false)  },
  { leftWindow : stdSeat(17,  true),   leftCenter : stdSeat(18, true),   leftSide : stdSeat(20, true),   rightSide : null,               rightCenter : stdSeat(26, true),   rightWindow : stdSeat(25,  true)   },
  { leftWindow : stdSeat(9,   false),  leftCenter : stdSeat(10, false),  leftSide : stdSeat(12, false),  rightSide : null,               rightCenter : stdSeat(16, false),  rightWindow : stdSeat(15,  false)  },
  { leftWindow : stdSeat(5,   true),   leftCenter : stdSeat(6,  true),   leftSide : stdSeat(8,  true),   rightSide : null,               rightCenter : stdSeat(14, true),   rightWindow : stdSeat(13,  true)   },
  { leftWindow : stdSeat(1,   true),   leftCenter : stdSeat(2,  true),   leftSide : null,                rightSide : null,               rightCenter : stdSeat(4,  true),   rightWindow : stdSeat(3,   true)   },
]

export const SeatNumbersLastochkaCenterCar = SeatNumbersLastochkaCenterCar_2_7;

// export const SeatNumbersLastochkaCenterCar = SeatNumbersLastochkaCenterCar_2_7.slice(0).reverse().map(o => (
//   {
//     leftWindow: reverseIsFront(o.rightWindow), leftCenter: reverseIsFront(o.rightCenter), leftSide: reverseIsFront(o.rightSide),
//     rightSide: reverseIsFront(o.leftSide), rightCenter: reverseIsFront(o.leftCenter), rightWindow: reverseIsFront(o.leftWindow)
//   }
// ));

export const SeatNumbersLastochka1RCar = [
  { leftWindow : sideSeat(33, false), leftCenter : sideSeat(34, true) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },
  { leftWindow : sideSeat(31, false), leftCenter : sideSeat(32, false) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },
  { leftWindow : stdSeat(107, false),  leftCenter : stdSeat(108, false), leftSide : null, rightSide : null,               rightCenter : stdSeat(112, false), rightWindow : stdSeat(111, false)  },
  { leftWindow : stdSeat(105, true),  leftCenter : stdSeat(106, true), leftSide : null, rightSide : null,               rightCenter : stdSeat(110, true), rightWindow : stdSeat(109, true)  },
  { leftWindow : stdSeat(101, false),  leftCenter : stdSeat(102, false), leftSide : null, rightSide : null,               rightCenter : stdSeat(104, false), rightWindow : stdSeat(103, false)  },
  { leftWindow : sideSeat(29, true), leftCenter : sideSeat(30, true) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },
  { leftWindow : sideSeat(27, false), leftCenter : sideSeat(28, false) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },
  { leftWindow : stdSeat(23, false),  leftCenter : stdSeat(24, false), leftSide : null, rightSide : null,               rightCenter : stdSeat(26, false), rightWindow : stdSeat(25, false)  },
  { leftWindow : stdSeat(17, true),  leftCenter : stdSeat(18, true),  leftSide : null,  rightSide : stdSeat(22, true),               rightCenter : stdSeat(20, true),  rightWindow : stdSeat(19, true)   },
  { leftWindow : stdSeat(11, false),   leftCenter : stdSeat(12, false), leftSide : null, rightSide : stdSeat(16, false),               rightCenter : stdSeat(14, false), rightWindow : stdSeat(13, false)  },
  { leftWindow : stdSeat(5, true),    leftCenter : stdSeat(6, true),   leftSide : null,   rightSide : stdSeat(10, true),               rightCenter : stdSeat(8, true),  rightWindow : stdSeat(7, true)   },
  { leftWindow : stdSeat(1, true),    leftCenter : stdSeat(2, true),   leftSide : null,               rightSide : null,               rightCenter : stdSeat(4, true),   rightWindow : stdSeat(3, true)    },
]
    // .slice(0).reverse()

export const SeatNumbersLastochka2GCar = [
  { leftWindow : stdSeat(81, false),  leftCenter : stdSeat(82, false), leftSide : null, rightSide : null,               rightCenter : stdSeat(84, false), rightWindow : stdSeat(83, false)  },
  { leftWindow : stdSeat(75, false),  leftCenter : stdSeat(76, false),  leftSide : null,  rightSide : stdSeat(80, false),               rightCenter : stdSeat(78, false),  rightWindow : stdSeat(77, false)   },
  { leftWindow : stdSeat(69, false),   leftCenter : stdSeat(70, false), leftSide : null, rightSide : stdSeat(74, false),               rightCenter : stdSeat(72, false), rightWindow : stdSeat(71, false)  },

  { leftWindow : stdSeat(63, false),    leftCenter : stdSeat(64, false),   leftSide : null,   rightSide : stdSeat(68, false),               rightCenter : stdSeat(66, false),  rightWindow : stdSeat(65, false)   },
  { leftWindow : stdSeat(59, false),    leftCenter : stdSeat(60, false),   leftSide : null,               rightSide : null,               rightCenter : stdSeat(62, false),   rightWindow : stdSeat(61, false)    },

  { leftWindow : stdSeat(55, true),  leftCenter : stdSeat(56, true), leftSide : null, rightSide : null,               rightCenter : stdSeat(58, true), rightWindow : stdSeat(57, true)  },
  { leftWindow : stdSeat(51, true),  leftCenter : stdSeat(52, true), leftSide : null, rightSide : null,               rightCenter : stdSeat(54, true), rightWindow : stdSeat(53, true)  },

  { leftWindow : null,  leftCenter : null, leftSide : null, rightSide : null,               rightCenter : stdSeat(50, false), rightWindow : stdSeat(49, false)  },
  { leftWindow : stdSeat(43, false),  leftCenter : stdSeat(44, false), leftSide : stdSeat(46, false), rightSide : null,               rightCenter : stdSeat(48, false), rightWindow : stdSeat(47, false)  },
  { leftWindow : stdSeat(37, false),  leftCenter : stdSeat(38, false), leftSide : stdSeat(40, false), rightSide : null,               rightCenter : stdSeat(42, false), rightWindow : stdSeat(41, false)  },
  { leftWindow : stdSeat(31, false),  leftCenter : stdSeat(32, false), leftSide : stdSeat(34, false), rightSide : null,               rightCenter : stdSeat(36, false), rightWindow : stdSeat(35, false)  },
  { leftWindow : stdSeat(27, false),  leftCenter : stdSeat(28, false), leftSide : null, rightSide : null,               rightCenter : stdSeat(30, false), rightWindow : stdSeat(29, false)  },

  { leftWindow : stdSeat(23, true),  leftCenter : stdSeat(24, true), leftSide : null, rightSide : null,               rightCenter : stdSeat(26, true), rightWindow : stdSeat(25, true)  },

  { leftWindow : stdSeat(17, true),  leftCenter : stdSeat(18, true),  leftSide : null,  rightSide : stdSeat(22, true),               rightCenter : stdSeat(20, true),  rightWindow : stdSeat(19, true)   },

  { leftWindow : stdSeat(11, true),   leftCenter : stdSeat(12, true), leftSide : null, rightSide : stdSeat(16, true),               rightCenter : stdSeat(14, true), rightWindow : stdSeat(13, true)  },

  { leftWindow : stdSeat(5, true),    leftCenter : stdSeat(6, true),   leftSide : null,   rightSide : stdSeat(10, true),               rightCenter : stdSeat(8, true),  rightWindow : stdSeat(7, true)   },
  { leftWindow : stdSeat(1, true),    leftCenter : stdSeat(2, true),   leftSide : null,               rightSide : null,               rightCenter : stdSeat(4, true),   rightWindow : stdSeat(3, true)    },
]



export const SeatNumbersLastochka1PCar = [
  { leftWindow : sideSeat(33, false), leftCenter : sideSeat(34, false) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },
  { leftWindow : sideSeat(31, false), leftCenter : sideSeat(32, false) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },
  { leftWindow : stdSeat(107, false),  leftCenter : stdSeat(108, false), leftSide : null, rightSide : null,               rightCenter : stdSeat(112, false), rightWindow : stdSeat(111, false)  },
  { leftWindow : stdSeat(105, true),  leftCenter : stdSeat(106, true), leftSide : null, rightSide : null,               rightCenter : stdSeat(110, true), rightWindow : stdSeat(109, true)  },
  { leftWindow : stdSeat(101, true),  leftCenter : stdSeat(102, true), leftSide : null, rightSide : null,               rightCenter : stdSeat(104, true), rightWindow : stdSeat(103, true)  },

    { leftWindow : sideSeat(29, true), leftCenter : sideSeat(30, true) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },
  { leftWindow : sideSeat(27, true), leftCenter : sideSeat(28, true) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },

  { leftWindow : stdSeat(23, true),  leftCenter : stdSeat(24, true), leftSide : null, rightSide : null,               rightCenter : stdSeat(26, true), rightWindow : stdSeat(25, true)  },
  { leftWindow : stdSeat(17, true),  leftCenter : stdSeat(18, true),  leftSide : null,  rightSide : stdSeat(22, true),               rightCenter : stdSeat(20, true),  rightWindow : stdSeat(19, true)   },
  { leftWindow : stdSeat(11, true),   leftCenter : stdSeat(12, true), leftSide : null, rightSide : stdSeat(16, true),               rightCenter : stdSeat(14, true), rightWindow : stdSeat(13, true)  },
  { leftWindow : stdSeat(5, true),    leftCenter : stdSeat(6, true),   leftSide : null,   rightSide : stdSeat(10, true),               rightCenter : stdSeat(8, true),  rightWindow : stdSeat(7, true)   },
  { leftWindow : stdSeat(1, true),    leftCenter : stdSeat(2, true),   leftSide : null,               rightSide : null,               rightCenter : stdSeat(4, true),   rightWindow : stdSeat(3, true)    },
]

export const SeatNumbersLastprem_w4_v2 = [
  { leftWindow : stdSeat(97,  false),  leftCenter : stdSeat(98, false),  leftSide : null,  rightSide : null,               rightCenter : stdSeat(100, false), rightWindow : stdSeat(99, false)   },

  { leftWindow : stdSeat(91,  false),   leftCenter : stdSeat(92, false),   leftSide : stdSeat(94, false),   rightSide : null,               rightCenter : stdSeat(96, false),   rightWindow : stdSeat(95, false)    },

    { leftWindow : stdSeat(85,  false),   leftCenter : stdSeat(86, false),   leftSide : stdSeat(88, false),   rightSide : null,               rightCenter : stdSeat(90, false),   rightWindow : stdSeat(89, false)    },

    { leftWindow : stdSeat(79,  false),   leftCenter : stdSeat(80, false),   leftSide : stdSeat(82, false),   rightSide : null,               rightCenter : stdSeat(84, false),   rightWindow : stdSeat(83, false)    },

    { leftWindow : stdSeat(75,  false),  leftCenter : stdSeat(76, false),  leftSide : null,  rightSide : null,               rightCenter : stdSeat(78, false), rightWindow : stdSeat(77, false)   },


    { leftWindow : stdSeat(69, true),   leftCenter : stdSeat(70, true),  leftSide : null,                rightSide : stdSeat(74, true), rightCenter : stdSeat(72, true), rightWindow : stdSeat(71, true)    },

    { leftWindow : stdSeat(63, true),   leftCenter : stdSeat(64, true),  leftSide : null,                rightSide : stdSeat(68, true), rightCenter : stdSeat(66, true), rightWindow : stdSeat(65, true)    },

    { leftWindow : stdSeat(57, true),   leftCenter : stdSeat(58, true),  leftSide : null,                rightSide : stdSeat(62, true), rightCenter : stdSeat(60, true), rightWindow : stdSeat(59, true)    },

    { leftWindow : stdSeat(51, true),   leftCenter : stdSeat(52, true),  leftSide : null,                rightSide : stdSeat(56, true), rightCenter : stdSeat(54, true), rightWindow : stdSeat(53, true)    },


        { leftWindow : stdSeat(45, false),   leftCenter : stdSeat(46, false),  leftSide : null,                rightSide : stdSeat(50, false), rightCenter : stdSeat(48, false), rightWindow : stdSeat(47, false)    },

    { leftWindow : stdSeat(39, false),   leftCenter : stdSeat(40, false),  leftSide : null,                rightSide : stdSeat(44, false), rightCenter : stdSeat(42, false), rightWindow : stdSeat(41, false)    },

    { leftWindow : stdSeat(33, false),   leftCenter : stdSeat(34, false),  leftSide : null,                rightSide : stdSeat(38, false), rightCenter : stdSeat(36, false), rightWindow : stdSeat(35, false)    },

    { leftWindow : stdSeat(27, false),   leftCenter : stdSeat(28, false),  leftSide : null,                rightSide : stdSeat(32, false), rightCenter : stdSeat(30, false), rightWindow : stdSeat(29, false)    },


    { leftWindow : stdSeat(23,  true),  leftCenter : stdSeat(24, true),  leftSide : null,  rightSide : null,               rightCenter : stdSeat(26, true), rightWindow : stdSeat(25, true)   },

    { leftWindow : stdSeat(17,  true),   leftCenter : stdSeat(18, true),   leftSide : stdSeat(20, true),   rightSide : null,               rightCenter : stdSeat(22, true),   rightWindow : stdSeat(21, true)    },

    { leftWindow : stdSeat(11,  true),   leftCenter : stdSeat(12, true),   leftSide : stdSeat(14, true),   rightSide : null,               rightCenter : stdSeat(16, true),   rightWindow : stdSeat(15, true)    },

    { leftWindow : stdSeat(5,  true),   leftCenter : stdSeat(6, true),   leftSide : stdSeat(8, true),   rightSide : null,               rightCenter : stdSeat(10, true),   rightWindow : stdSeat(9, true)    },

    { leftWindow : stdSeat(1,  true),  leftCenter : stdSeat(2, true),  leftSide : null,  rightSide : null,               rightCenter : stdSeat(4, true), rightWindow : stdSeat(3, true)   },
]

export const SeatNumbersLastprem_w2_v2 = [
  { leftWindow : stdSeat(105,  false),  leftCenter : stdSeat(106, false),  leftSide : null,  rightSide : null,               rightCenter : stdSeat(108, false), rightWindow : stdSeat(107, false)   },

  { leftWindow : stdSeat(99,  false),   leftCenter : stdSeat(100, false),   leftSide : stdSeat(102, false),   rightSide : null,               rightCenter : stdSeat(104, false),   rightWindow : stdSeat(103, false)    },

  { leftWindow : stdSeat(93,  false),   leftCenter : stdSeat(94, false),   leftSide : stdSeat(96, false),   rightSide : null,               rightCenter : stdSeat(98, false),   rightWindow : stdSeat(97, false)    },

  { leftWindow : stdSeat(87,  false),   leftCenter : stdSeat(88, false),   leftSide : stdSeat(90, false),   rightSide : null,               rightCenter : stdSeat(92, false),   rightWindow : stdSeat(91, false)    },

  { leftWindow : stdSeat(83,  false),  leftCenter : stdSeat(84, false),  leftSide : null,  rightSide : null,               rightCenter : stdSeat(86, false), rightWindow : stdSeat(85, false)   },


  { leftWindow : stdSeat(79, true),   leftCenter : stdSeat(80, true),  leftSide : null,                rightSide : null, rightCenter : stdSeat(82, true), rightWindow : stdSeat(81, true)    },

  { leftWindow : stdSeat(73, true),   leftCenter : stdSeat(74, true),  leftSide : null,                rightSide : stdSeat(78, true), rightCenter : stdSeat(76, true), rightWindow : stdSeat(75, true)    },

  { leftWindow : stdSeat(67, true),   leftCenter : stdSeat(68, true),  leftSide : null,                rightSide : stdSeat(72, true), rightCenter : stdSeat(70, true), rightWindow : stdSeat(69, true)    },

  { leftWindow : stdSeat(61, true),   leftCenter : stdSeat(62, true),  leftSide : null,                rightSide : stdSeat(66, true), rightCenter : stdSeat(64, true), rightWindow : stdSeat(63, true)    },

  { leftWindow : stdSeat(55, true),   leftCenter : stdSeat(56, true),  leftSide : null,                rightSide : stdSeat(60, true), rightCenter : stdSeat(58, true), rightWindow : stdSeat(57, true)    },


  { leftWindow : stdSeat(49, false),   leftCenter : stdSeat(50, false),  leftSide : null,                rightSide : stdSeat(54, false), rightCenter : stdSeat(52, false), rightWindow : stdSeat(51, false)    },

  { leftWindow : stdSeat(43, false),   leftCenter : stdSeat(44, false),  leftSide : null,                rightSide : stdSeat(48, false), rightCenter : stdSeat(46, false), rightWindow : stdSeat(45, false)    },

  { leftWindow : stdSeat(37, false),   leftCenter : stdSeat(38, false),  leftSide : null,                rightSide : stdSeat(42, false), rightCenter : stdSeat(40, false), rightWindow : stdSeat(39, false)    },

  { leftWindow : stdSeat(31, false),   leftCenter : stdSeat(32, false),  leftSide : null,                rightSide : stdSeat(36, false), rightCenter : stdSeat(34, false), rightWindow : stdSeat(33, false)    },

  { leftWindow : stdSeat(27, false),   leftCenter : stdSeat(28, false),  leftSide : null,                rightSide : null, rightCenter : stdSeat(30, false), rightWindow : stdSeat(29, false)    },

  { leftWindow : stdSeat(23,  true),   leftCenter : stdSeat(24, true),   leftSide : null,   rightSide : null,               rightCenter : stdSeat(26, true),   rightWindow : stdSeat(25, true)    },

  { leftWindow : stdSeat(17,  true),   leftCenter : stdSeat(18, true),   leftSide : stdSeat(20, true),   rightSide : null,               rightCenter : stdSeat(22, true),   rightWindow : stdSeat(21, true)    },

  { leftWindow : stdSeat(11,  true),   leftCenter : stdSeat(12, true),   leftSide : stdSeat(14, true),   rightSide : null,               rightCenter : stdSeat(16, true),   rightWindow : stdSeat(15, true)    },

  { leftWindow : stdSeat(5,  true),   leftCenter : stdSeat(6, true),   leftSide : stdSeat(8, true),   rightSide : null,               rightCenter : stdSeat(10, true),   rightWindow : stdSeat(9, true)    },

  { leftWindow : stdSeat(1,  true),  leftCenter : stdSeat(2, true),  leftSide : null,  rightSide : null,               rightCenter : stdSeat(4, true), rightWindow : stdSeat(3, true)   },
]



export const SeatNumbersLastprem_w1_v2 = [
  { leftWindow : sideSeat(57, true), leftCenter : sideSeat(58, true) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },

  { leftWindow : sideSeat(55, false), leftCenter : sideSeat(56, false) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },



  { leftWindow : stdSeat(51, true),  leftCenter : stdSeat(52, true), leftSide : null, rightSide : null,               rightCenter : stdSeat(54, true), rightWindow : stdSeat(53, true)  },

  { leftWindow : stdSeat(45, true),  leftCenter : stdSeat(46, true), leftSide : null, rightSide : stdSeat(50, true),               rightCenter : stdSeat(48, true), rightWindow : stdSeat(47, true)  },

  { leftWindow : stdSeat(39, true),  leftCenter : stdSeat(40, true), leftSide : null, rightSide : stdSeat(44, true),               rightCenter : stdSeat(42, true), rightWindow : stdSeat(41, true)  },

  { leftWindow : stdSeat(33, true),  leftCenter : stdSeat(34, true), leftSide : null, rightSide : stdSeat(38, true),               rightCenter : stdSeat(36, true), rightWindow : stdSeat(35, true)  },

  { leftWindow : stdSeat(27, true),  leftCenter : stdSeat(28, true), leftSide : null, rightSide : stdSeat(32, true),               rightCenter : stdSeat(30, true), rightWindow : stdSeat(29, true)  },





  { leftWindow : stdSeat(23,  true),   leftCenter : stdSeat(24, true),   leftSide : null,   rightSide : null,               rightCenter : stdSeat(26, true),   rightWindow : stdSeat(25, true)    },

  { leftWindow : stdSeat(17,  true),   leftCenter : stdSeat(18, true),   leftSide : stdSeat(20, true),   rightSide : null,               rightCenter : stdSeat(22, true),   rightWindow : stdSeat(21, true)    },

  { leftWindow : stdSeat(11,  true),   leftCenter : stdSeat(12, true),   leftSide : stdSeat(14, true),   rightSide : null,               rightCenter : stdSeat(16, true),   rightWindow : stdSeat(15, true)    },

  { leftWindow : stdSeat(5,  true),   leftCenter : stdSeat(6, true),   leftSide : stdSeat(8, true),   rightSide : null,               rightCenter : stdSeat(10, true),   rightWindow : stdSeat(9, true)    },

  { leftWindow : stdSeat(1, true),    leftCenter : stdSeat(2, true),   leftSide : null,               rightSide : null,               rightCenter : stdSeat(4, true),   rightWindow : stdSeat(3, true)    },
]

export const SeatNumbersLastprem_w2_v1 = [
  { leftWindow : stdSeat(97,  false),  leftCenter : stdSeat(98, false),  leftSide : null,  rightSide : null,               rightCenter : stdSeat(100, false), rightWindow : stdSeat(99, false)   },

  { leftWindow : stdSeat(87, false),   leftCenter : stdSeat(88, false),  leftSide : null,                rightSide : stdSeat(96, false), rightCenter : stdSeat(94, false), rightWindow : stdSeat(93, false)    },

  { leftWindow : stdSeat(85, true),   leftCenter : stdSeat(86, true),  leftSide : null,                rightSide : stdSeat(92, true), rightCenter : stdSeat(90, true), rightWindow : stdSeat(89, true)    },

  { leftWindow : stdSeat(77, false),   leftCenter : stdSeat(78, false),  leftSide : null,                rightSide : stdSeat(84, false), rightCenter : stdSeat(82, false), rightWindow : stdSeat(81, false)    },

  { leftWindow : stdSeat(75,  true),   leftCenter : stdSeat(76, true),   leftSide : null,   rightSide : null,               rightCenter : stdSeat(80, true),   rightWindow : stdSeat(79, true)    },



  { leftWindow : stdSeat(69,  false),   leftCenter : stdSeat(70, false),   leftSide : stdSeat(72, false),   rightSide : null,               rightCenter : stdSeat(74, false),   rightWindow : stdSeat(73, false)    },

  { leftWindow : stdSeat(63,  false),   leftCenter : stdSeat(64, false),   leftSide : stdSeat(66, false),   rightSide : null,               rightCenter : stdSeat(68, false),   rightWindow : stdSeat(67, false)    },

  { leftWindow : stdSeat(57,  false),   leftCenter : stdSeat(58, false),   leftSide : stdSeat(60, false),   rightSide : null,               rightCenter : stdSeat(62, false),   rightWindow : stdSeat(61, false)    },

  { leftWindow : stdSeat(51,  false),   leftCenter : stdSeat(52, false),   leftSide : stdSeat(54, false),   rightSide : null,               rightCenter : stdSeat(56, false),   rightWindow : stdSeat(55, false)    },

  { leftWindow : stdSeat(47,  true),   leftCenter : stdSeat(48, true),   leftSide : stdSeat(50, true),   rightSide : null,               rightCenter : stdSeat(46, false),   rightWindow : stdSeat(45, false)    },

  { leftWindow : stdSeat(39,  true),   leftCenter : stdSeat(40, true),   leftSide : stdSeat(42, true),   rightSide : null,               rightCenter : stdSeat(44, true),   rightWindow : stdSeat(43, true)    },

  { leftWindow : stdSeat(33,  true),   leftCenter : stdSeat(34, true),   leftSide : stdSeat(36, true),   rightSide : null,               rightCenter : stdSeat(38, true),   rightWindow : stdSeat(37, true)    },

  { leftWindow : stdSeat(27,  true),   leftCenter : stdSeat(28, true),   leftSide : stdSeat(30, true),   rightSide : null,               rightCenter : stdSeat(32, true),   rightWindow : stdSeat(31, true)    },

  { leftWindow : stdSeat(19,  false),  leftCenter : stdSeat(20, false),  leftSide : null,  rightSide : null,               rightCenter : stdSeat(26, false), rightWindow : stdSeat(25, false)   },

  { leftWindow : stdSeat(17, true),   leftCenter : stdSeat(18, true),  leftSide : null,                rightSide : stdSeat(24, true), rightCenter : stdSeat(22, true), rightWindow : stdSeat(21, true)    },

  { leftWindow : stdSeat(7, false),   leftCenter : stdSeat(8, false),  leftSide : null,                rightSide : stdSeat(16, false), rightCenter : stdSeat(14, false), rightWindow : stdSeat(13, false)    },

  { leftWindow : stdSeat(5, true),   leftCenter : stdSeat(6, true),  leftSide : null,                rightSide : stdSeat(12, true), rightCenter : stdSeat(10, true), rightWindow : stdSeat(9, true)    },

  { leftWindow : stdSeat(1,  true),  leftCenter : stdSeat(2, true),  leftSide : null,  rightSide : null,               rightCenter : stdSeat(4, true), rightWindow : stdSeat(3, true)   },

]


export const SeatNumbersLastprem_w1_v1 = [
  { leftWindow : sideSeat(39, false), leftCenter : sideSeat(40, true) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },
  { leftWindow : sideSeat(37, true), leftCenter : sideSeat(38, true) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },

  { leftWindow : stdSeat(33, false),  leftCenter : stdSeat(34, false), leftSide : null, rightSide : null,               rightCenter : stdSeat(36, false), rightWindow : stdSeat(35, false)  },
  { leftWindow : stdSeat(27, false),  leftCenter : stdSeat(28, false), leftSide : null, rightSide : null,               rightCenter : stdSeat(32, false), rightWindow : stdSeat(31, false)  },


  { leftWindow : stdSeat(25, true),  leftCenter : stdSeat(26, true), leftSide : null, rightSide : null,               rightCenter : stdSeat(30, true), rightWindow : stdSeat(29, true)  },


  { leftWindow : sideSeat(23, true), leftCenter : null ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },
  { leftWindow : sideSeat(21, true), leftCenter : null ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },
  { leftWindow : sideSeat(19, true), leftCenter : null ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },


  { leftWindow : null,    leftCenter : null,   leftSide : null,   rightSide : null,               rightCenter : stdSeat(18, false),  rightWindow : stdSeat(17, false)   },
  { leftWindow : stdSeat(11, false),    leftCenter : stdSeat(12, false),   leftSide : null,   rightSide : null,               rightCenter : stdSeat(16, false),  rightWindow : stdSeat(15, false)   },

  { leftWindow : stdSeat(9, true),    leftCenter : stdSeat(10, true),   leftSide : null,   rightSide : null,               rightCenter : stdSeat(14, true),  rightWindow : stdSeat(13, true)   },
  { leftWindow : stdSeat(5, true),    leftCenter : stdSeat(6, true),   leftSide : null,   rightSide : null,               rightCenter : stdSeat(8, true),  rightWindow : stdSeat(7, true)   },
  { leftWindow : stdSeat(1, true),    leftCenter : stdSeat(2, true),   leftSide : null,               rightSide : null,               rightCenter : stdSeat(4, true),   rightWindow : stdSeat(3, true)    },
]


export const SeatNumbersLastprem_w5_v1 = [
  { leftWindow : sideSeat(49, false), leftCenter : sideSeat(50, true) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },
  { leftWindow : sideSeat(47, false), leftCenter : sideSeat(48, false) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },

  { leftWindow : stdSeat(43, false),  leftCenter : stdSeat(44, false), leftSide : null, rightSide : null,               rightCenter : stdSeat(46, false), rightWindow : stdSeat(45, false)  },

  { leftWindow : stdSeat(33, false),  leftCenter : stdSeat(34, false), leftSide : null, rightSide : stdSeat(42, false),               rightCenter : stdSeat(40, false), rightWindow : stdSeat(39, false)  },

  { leftWindow : stdSeat(31, true),  leftCenter : stdSeat(32, true), leftSide : null, rightSide : stdSeat(38, true),               rightCenter : stdSeat(36, true), rightWindow : stdSeat(35, true)  },


  { leftWindow : sideSeat(29, true), leftCenter : sideSeat(30, true) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },
  { leftWindow : sideSeat(27, true), leftCenter : sideSeat(28, true) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },
  { leftWindow : sideSeat(25, true), leftCenter : sideSeat(26, true) ,               leftSide : null,               rightSide : null,               rightCenter : null,               rightWindow : null },



  { leftWindow : null,    leftCenter : null,   leftSide : null,   rightSide : null,               rightCenter : stdSeat(24, false),  rightWindow : stdSeat(23, false)   },

  { leftWindow : stdSeat(17, false),    leftCenter : stdSeat(18, false),   leftSide : null,   rightSide : null,               rightCenter : null,  rightWindow : null   },

  { leftWindow : null,    leftCenter : null,   leftSide : null,   rightSide : stdSeat(22, true),               rightCenter : stdSeat(20, true),  rightWindow : stdSeat(19, true)   },

  { leftWindow : stdSeat(7, false),    leftCenter : stdSeat(8, false),   leftSide : null,   rightSide : stdSeat(16, false),               rightCenter : stdSeat(14, false),  rightWindow : stdSeat(13, false)   },

  { leftWindow : stdSeat(5, true),    leftCenter : stdSeat(6, true),   leftSide : null,   rightSide : stdSeat(12, true),               rightCenter : stdSeat(10, true),  rightWindow : stdSeat(9, true)   },

  { leftWindow : stdSeat(1, true),    leftCenter : stdSeat(2, true),   leftSide : null,               rightSide : null,               rightCenter : stdSeat(4, true),   rightWindow : stdSeat(3, true)    },
]