import React from 'react';
import {Link} from "react-router-dom";

const SeoTextsView = () => (
    <div className="description-block mt-4 pr-4 pl-4">
        <div className="pt-2">
            <a href="/poezda/"><h2 className="title">СПРАВОЧНИК ПОЕЗДОВ</h2></a>
            <p>Час пик на дорогах, долгая процедура регистрации и получения багажа в аэропорту, - все это иногда занимает слишком много времени, особенно, когда дело касается наших двух столиц! К счастью, новый поезд, созданный концерном Siemens под именем «Velaro Rus» и переименованный в честь самой быстрой птицы на земле, способен разгоняться до 350 км./ч., проделывая расстояние от Москвы до Санкт-Петербурга всего за 3 часа и 45 минут. Для каждого, кто ценит свое время, поездка на <a className="loading" href="/pedia/поезд-сапсан">«Сапсане»</a> действительно стала отличной альтернативой авиаперелетам как по времени поездки, так и по уровню комфорта.</p>
        </div>
        <div className="pt-4">
            <h2 className="h5 text-uppercase mb-4">О сервисе онлайн покупки билетов на поезда дальнего следования
                Vokzal.ru</h2>
            <p>Сервис vokzal.ru – это электронный вокзал, созданный специально для тех, кому важен комфорт и
                оперативность.</p>

            <p>
                Удобная форма заказа на нашем сайте позволяет купить онлайн ЖД билеты на поезда дальнего следования в
                Москву, Санкт-Петербург,
                а также другие города Российской Федерации, стран СНГ и Балтии.
                Вы сможете выбрать то, что вам нужно, прямо из дома: дату отправления, время, вагон и место, внимательно
                просмотреть все данные,
                изучить расписание, наличие и цены билетов, их стоимость и в системе поиска подобрать оптимальное время
                отправления поезда.
            </p>

            <p>Вы сможете выбрать то, что вам нужно, прямо из дома:</p>

            <ul>
                <li>дату и время отправления,</li>
                <li>вагон и место,</li>
                <li>изучить расписание,</li>
                <li>узнать наличие и цены ЖД билетов,</li>
                <li>подобрать оптимальное время отправления поезда,</li>
                <li>оформить заказ и купить электронный билет.</li>
            </ul>

            <p>Теперь у каждого есть возможность оформить онлайн заказ ЖД билетов на поезд, не отходя от компьютера и не
                посещая кассы вокзала.</p>
        </div>

        <div className="pt-4">
            <h2 className="h5 text-uppercase mb-4">4 причины купить ЖД билеты на поезда онлайн</h2>

            <ol>
                <li className="mb-2">Не нужно тратить время в очередях.</li>
                <li className="mb-2">Вы сами выбираете комфортное место с помощью схемы вагона.</li>
                <li className="mb-2">Никакой вокзальной суеты, толкотни и толп с чемоданами.</li>
                <li className="mb-2">Покупку билетов можно оплатить любым удобным способом.</li>
            </ol>
        </div>


        <div className="pt-4">
            <h2 className="mb-3">ДЛЯ ТОГО КУПИТЬ ЖД БИЛЕТ ОНЛАЙН НА ПОЕЗД ДАЛЬНЕГО СЛЕДОВАНИЯ</h2>
            <ol>
                <li>
                    <h3>Сделайте заказ</h3>
                    <p>Железнодорожные билеты на поезда во все города России, СНГ и Балтии в реальном времени</p>
                </li>
                <li>
                    <h3>Оплатите</h3>
                    <p>Мы принимаем: Мир, Visa, MasterCard, Евросеть, Связной</p>
                </li>
                <li>
                    <h3>Проходите на посадку без бумажного билета*</h3>
                    <p>*с помощью услуги <a href="/help/info/electronicregistration">электронной регистрации</a>. Для
                        некоторых поездов всё же необходимо получить бумажный билет в любой кассе на вокзале или с помощью
                        терминалов самообслуживания</p>
                </li>
            </ol>
        </div>

        <div className="pt-4">
            <ul>
                <li><a href="/help/main/howtobuy">Как совершить заказ на сайте?</a></li>
                <li><a href="/help/main/howtopay">Как оплатить заказ?</a></li>
                <li><a href="/return">Возврат билета</a></li>
                <li><a href="/help/main/getticket">Где получить бумажные билеты на поезд?</a></li>
                <li><a href="/help/info/sapsan">Поезд "Сапсан"</a></li>
            </ul>
        </div>

        <div className="pt-4">
            <h2 className="h5 text-uppercase mb-4">Об электронной регистрации</h2>

            <p>
                Используя <a href="/help/info/electronicregistration">услугу электронной регистрации</a>, вы следуете на
                посадку без бумажного билета.
                Сегодня эта услуга доступна на подавляющем большинстве поездов.
                Разумеется, бывают ситуации, когда необходимо иметь традиционный билет.
                В этом случае можно получить его в кассе, предоставив номер билета и паспорт.
            </p>

            <p>Путешествуйте легко, комфортно и безопасно, купив дешевые ЖД билеты через сайт электронного вокзала!</p>
        </div>
    </div>
)

module.exports = SeoTextsView;