import React from 'react';
import {cols6, order6} from "../../../../src/utils/functions";
import {Link} from "react-router-dom";

const PediaStationsListGroupView = ({ groups, onLinkClick }) => {

  const items = groups.reduce((res, group) => {
    return res.concat(...group.links);
  }, []);
  const count = items.length;

  let result = cols6(count);

  return (
  <div className="station-groups-block row">
      {
        result.map((elem, index) => {
          let list = items.splice(0, elem);
          return list.length ? <Block list={list} i={index} key={index} onLinkClick={onLinkClick}/> : ''
        })
      }
  </div>
)};

const Block = ({ list, i, onLinkClick }) => {
  let order = order6(i);

  return (<div className={"station-group-links col-md-6 col-lg-4 pr-0 " + order}>
    {list.map((link, index) => (
        <div key={index} className="">
          <Link to={link.url} onClick={(e) => { onLinkClick(link, e) }}>{link.title}</Link>
        </div>
    ))}
  </div>)
};

module.exports = PediaStationsListGroupView;