import { handleActions } from 'redux-actions'

import { scheduleInitialStatePlatform } from 'vokzal-platform/components/pedia/schedule_bus/reducer';

import { TOGGLE_SCHEDULE_BUS_BLOCK, FILL_SCHEDULE_GROUP_BUS, SCHEHULE_BUS_FETCH_COMPLETE_ACTION } from "./action";
import { SHOW_ORDER_FORM_BUS } from "../order_block_bus/action";

let initialState = { loaded: false }

initialState = Object.assign({}, initialState, scheduleInitialStatePlatform())

const PediaScheduleBusReducer = handleActions({
    [SCHEHULE_BUS_FETCH_COMPLETE_ACTION]: (state, action) => {
      return { ...state, ...action.payload }
    },
    [TOGGLE_SCHEDULE_BUS_BLOCK]: (state, action) => {
      const city = action.payload;

      state.schedule_groups[city].isExpanded = !state.schedule_groups[city].isExpanded;
      return { ...state }
    },
    [FILL_SCHEDULE_GROUP_BUS]: (state, action) => {
      const name = action.payload;
      if (state.schedule_groups
          && state.schedule_groups.hasOwnProperty(name)
          // && state.schedule_groups[city].items.length === 0
      ) {

        state.schedule_groups[name].items = state.schedule.filter(item => (
          item.departure.name === name || item.arrival.name === name
        ));
      }

      return { ...state }
    },
}, initialState)

export default PediaScheduleBusReducer