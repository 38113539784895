// @flow
import PropTypes from 'prop-types';
import { SeatNumbersSeatedPropType } from '../../../../../src/types/';

import SapsanBuffetMapView from 'vokzal-platform/components/step3/sapsan/sapsan_buffet_map/view'

SapsanBuffetMapView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersSeatedPropType).isRequired
};

const SapsanBuffetMap = SapsanBuffetMapView

export default SapsanBuffetMap