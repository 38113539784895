import React from 'react';

import i18n from 'vokzal-platform/i18n';

import HeadMenu from '../../../../src/components/shared/menu';

import TicketReturnDetails from '../../../../src/components/ticket_return/return_details';

import FooterInfo from '../../../../src/components/shared/footer_info';

const TicketReturnConfirmationView = ({ }) => (
    <div className="ticket-return gradient-background">
      <HeadMenu />

      <TicketReturnDetails />

      <FooterInfo titleText={i18n.t('goodbye')} />
    </div>
);

module.exports = TicketReturnConfirmationView;