import React from 'react';
import { connect } from 'react-redux';

import CabinTypeView from 'vokzal-platform/components/step3/cabin_type/view'
import {kupe_select_mf} from "../action";

const mapStateToProps = state => {
    return {  }
};

const mapDispatchToProps = dispatch => {
    return {
        onSelectMF : (param) => {
            dispatch(kupe_select_mf(param));
        },
    }
};

const CabinTypeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CabinTypeView);

export default CabinTypeContainer