export const confirmationInitialStatePlatform = () => {
    let initialState = {}

    if (location.pathname.startsWith('/confirmation')) {

        if(typeof orderForm !== 'undefined' && typeof orderID !== 'undefined') {
            initialState.order_form = orderForm;
            initialState.order_id = orderID;
            initialState.order_url = orderURL;
            initialState.reserved = true;
            initialState.seat_changed = false;
            initialState.timer = secs;
        } else {
            window.location.replace('/');
        }
    }

    return initialState;
}