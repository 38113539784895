// @flow
import PropTypes from 'prop-types';
import { SeatNumbersLastochkaPropType } from '../../../../../src/types/';

import Prigorod_22_v1View from 'vokzal-platform/components/step3/lastochka/prigorod_22_v1/view'

Prigorod_22_v1View.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersLastochkaPropType).isRequired
};

const Prigorod_22_v1 = Prigorod_22_v1View

export default Prigorod_22_v1