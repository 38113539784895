import React from 'react';

import i18n from 'vokzal-platform/i18n';

const PassengerInfoView = ({ fio, seat, tarif }) => (
  <div className="ticket-return-passenger-info d-flex justify-content-between mt-2">
    <div className="font-medium-light">
      <div className="font-weight-bold">{fio}</div>
      <div className="font-weight-medium small mt-2">{i18n.t('tarif')}: {tarif}</div>
    </div>
    <div className="font-medium-light">
      <div className="">{i18n.t('seat')}</div>
      <div className="h3">{seat}</div>
    </div>

  </div>
);

module.exports = PassengerInfoView;