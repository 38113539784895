// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import PediaRaspisanieView from 'vokzal-platform/components/pedia/raspisanie/view'
import {fetch_pedia_station} from "../../../api/fetch_pedia_schedule";
import { post_process_schedule } from '../../../../src/api/fetch_pedia_station';
import {raspisanieFetchCompleteAction, showOrderFormAction} from "./action";
import { setStationFromAction, setStationToAction, setStationFromInfoAction, setStationToInfoAction } from '../../home/search_form/action'

import { convert_station_obj } from '../../../utils/stations';

import { searchFormUrlParamsTrain } from '../../../../src/utils/url_params';

import { SearchResultTrainPropType } from '../../../types';

import { showStep2Plain } from 'vokzal-platform/managers/navigation'
import {setStep2Action} from "../../home/search_form/action";
import moment from "moment";


PediaRaspisanieView.propTypes = {
  loaded: PropTypes.bool.isRequired,
  urlWithParams: PropTypes.string,
  items: PropTypes.arrayOf(SearchResultTrainPropType),
  onTrainClick: PropTypes.func.isRequired,
  onDateSelected: PropTypes.func.isRequired,
};

class PediaRaspisanieContainer extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      fetch_pedia_schedule(this.props.urlWithParams, this.props.onDataLoaded);
    }
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    return <PediaRaspisanieView {...this.props} />;
  }
}

const mapStateToProps = state => {

     const searchUrlWithParams = state.step2_details.train ? searchFormUrlParamsTrain(state.search) : '';
     const url = location.pathname.split('/');
     const backUrl = [url[0], url[1], url[3], url[2], url[5], url[4]].join('/');

     return {
         loaded: state.pedia_raspisanie.loaded,
         urlWithParams: state.pedia_raspisanie.urlWithParams,
         searchUrlWithParams,
         backUrl,
         items: state.pedia_raspisanie.schedule,
         cityFrom: state.pedia_raspisanie.cityFrom,
         cityTo: state.pedia_raspisanie.cityTo,
         averageDistance: state.pedia_raspisanie.averageDistance,
         averageWaytime: state.pedia_raspisanie.averageWaytime,
         trainsCount: state.pedia_raspisanie.trainsCount,
         citiesPassthru: state.pedia_raspisanie.citiesPassthru,
         tariffs: state.pedia_raspisanie.tariffs,
    }
}

export const onRaspisanieLoaded = (dispatch, time_spent, response) => {
  let result = {};

  const t = post_process_schedule(response.trains);
  result.schedule = t.map(o => {
    o.prices = (o.prices) ? o.prices : {};

    return o;
  })
  result.title = response.title;
  result.subTitle = response.subTitle;
  result.image = { caption: 'default' };
  result.loaded = true;
  result.cityFrom = response.cityFrom;
  result.cityTo = response.cityTo;
  result.averageDistance = response.averageDistance;
  result.averageWaytime = response.averageWaytime;
  result.trainsCount = response.trainsCount;
  result.citiesPassthru = response.citiesPassthru;
  result.tariffs = response.tariffs;
  dispatch(raspisanieFetchCompleteAction({ time_spent, ...result }));
}

const mapDispatchToProps = dispatch => {
    return {
      onDataLoaded : (time_spent, response) => {
          onRaspisanieLoaded(dispatch, time_spent, response);
      },
      onTrainClick: (train) => {
        dispatch(setStationFromAction(convert_station_obj(train.departure.objStation)));
        dispatch(setStationFromInfoAction(convert_station_obj(train.departure.objStation)));
        dispatch(setStationToAction(convert_station_obj(train.arrival.objStation)));
        dispatch(setStationToInfoAction(convert_station_obj(train.arrival.objStation)));
        dispatch(showOrderFormAction(train.number));
      },

      onDateSelected: (searchUrlWithParams, date_from) => {
          let newUrl = searchUrlWithParams.replace(/departureDate=[\d\.]{1,}&/, `departureDate=${moment(date_from).format('DD.MM.YYYY')}&`);
        if (searchUrlWithParams) {
          showStep2Plain(dispatch, newUrl);
          dispatch(setStep2Action({ step2: newUrl }));
        }
      }
    }
}


PediaRaspisanieContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PediaRaspisanieContainer)

export default PediaRaspisanieContainer