import moment from 'moment';

import { vokzal_config } from '../config';

import { searchFormUrlToConfirm } from '../utils/url_params';

export const confirm_reservation = (confirmationUrlWithParams, redirectUrlParams, onDataLoaded, onError) => {
  const requested_at = moment();
  let fetchParams = vokzal_config.rest.fetchParams;
  fetchParams.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
  fetchParams.body = confirmationUrlWithParams;
  fetchParams.method = 'POST';

  fetch(vokzal_config.rest.base_url + searchFormUrlToConfirm(), fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.hasOwnProperty('error')) {
        onError(Object.assign({ confirmationUrlWithParams }, responseJson.error));
      } else {
        onDataLoaded(moment().diff(requested_at), redirectUrlParams, responseJson);
      }
    });
}