import React from 'react';

import { CarTypes } from '../../../../src/types/';

const TrainPriceItemView = ({ priceType, priceItems, onCarClassClick, carCategory, service, urlParams, trainItem }) => {

  if (!priceItems.hasOwnProperty(priceType)) {
    return <div className="train-price-item col d-block d-md-none" />;
  }

  const priceItem = priceItems[priceType];

  return (
    <div className="row train-price-item small"
         onClick={() => onCarClassClick(carCategory, service, urlParams, priceItems, trainItem)}
    >
            <div className="col-md-5 train-price-item-title font-medium-light text-uppercase">{CarTypes[priceType]}</div>
            <div className="col-md-4 order-md-last train-price-item-price font-medium-light font-weight-bold">
                {priceItem.prefix}
                {priceItem.price} &#8381;
            </div>
            <div className="col-md-3 train-price-item-seats">{priceItem.seats}</div>
    </div>
  );
};


module.exports = TrainPriceItemView;