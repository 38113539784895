import React from 'react';

import PediaSalepointsCashDesk from '../../../../src/components/pedia/salepoints_cashdesk';

const PediaSalepointsRow = ({title, value, noWrap}) => (
  <li className="list-group-item d-flex justify-content-between">
    <div className="font-weight-bold pr-2">{title}:</div>
    <div className={(noWrap) ? "text-nowrap" : "" }>{value}</div>
  </li>
)

const PediaSalepointsView = ({ cashDesks, stationFullName, address, comment, howToReach, phone, workSinceLocal, workSinceMsk, workTillLocal, workTillMsk }) => (
    <div className="pedia-salepoint-item">

      <ul className="list-group list-group-flush">
        <PediaSalepointsRow title="Адрес" value={address} />
        <PediaSalepointsRow title="Комментарий" value={(comment) ? comment : 'нет информации'} />
        <PediaSalepointsRow title="Как добраться" value={(howToReach) ? howToReach : 'нет информации'} />
        <PediaSalepointsRow title="Режим работы (время московское)" value={(workSinceMsk) ? workSinceMsk + ' — ' + workTillMsk : '—'} noWrap={true} />
        <PediaSalepointsRow title="Режим работы (время местное)" value={(workSinceLocal) ? workSinceLocal + ' — ' + workTillLocal : '—'} noWrap={true} />
        <PediaSalepointsRow title="Телефон" value={(phone) ? phone : '—'} />
      </ul>

      <PediaSalepointsCashDesk cashDesks={cashDesks} stationFullName={stationFullName} />
    </div>
);

module.exports = PediaSalepointsView;