import moment from 'moment';

import i18n from 'vokzal-platform/i18n';

import { vokzal_config } from '../config';

export const transform_document_types = (document_types) => {
  let dt = [];

  for (let prop in document_types) {
    if (document_types.hasOwnProperty(prop)) {
      dt.push({ title: document_types[prop], value: prop })
    }
  }

  return dt;
}

export const transform_payment_types = (payment_types) => {
    const images = {
        intellectmoney: ['visa_master_ic.gif', 'mir_logo.png'],
        euroset: ['svyaznoy22.png', 'euroset.png']
    };

  return payment_types.map((payment_type) => (
    {
        title: i18n.t('payment_' + payment_type),
        value: payment_type,
        info: i18n.t('payment_info_' + payment_type),
        text: i18n.t('payment_text_' + payment_type),
        images: images.hasOwnProperty(payment_type) ? images[payment_type] : []
    }
  ));
}

export const fetch_document_types = (urlWithParams, onDataLoaded) => {
  const requested_at = moment();

  fetch(vokzal_config.rest.base_url + urlWithParams, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {
      //TODO error handling
      const dt = transform_document_types(responseJson.document_types)
      const pt = transform_payment_types(responseJson.payment_types)
      onDataLoaded(moment().diff(requested_at), dt, pt, responseJson.stop_buy);
    });
};