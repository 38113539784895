import ReactGA from 'react-ga';

import { vokzal_config } from '../src/config';

ReactGA.initialize(vokzal_config.ga.tracker, { titleCase : false });

export const gaPageView = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
}

export const gaEvent = (category, action, label = '', nonInteraction = false) => {
  ReactGA.event({ category, action, label, nonInteraction });
}

// value in milliseconds
export const gaTiming = (category, value, variable, label = null) => {
  ReactGA.timing({ category, variable, value, label });
}

export const gaClientID = () => {
  let clientID = null;

  ReactGA.ga((tracker) => {
    clientID = tracker.get('clientId');
  });

  return clientID;
}
