// export const Stations = {
//   2000000: { id: 2000000, station_name: "Москва", city_name: "Москва", region: "Московская обл.", country: "Россия", url: "/" },
//   2004000: { id: 2004000, station_name: "Санкт-Петербург", city_name: "Санкт-Петербург", region: "Ленинградская обл.", country: "Россия", url: "/" },
//   2060001: { id: 2060001, station_name: "Нижний Новгород", city_name: "Нижний Новгород", region: "Новгородсная обл.", country: "Россия", url: "/"  },
//   2064188: { id: 2064188, station_name: "Анапа", city_name: "Анапа", region: "Краснодарский край", country: "Россия", url: "/"  },
//   2060615: { id: 2060615, station_name: "Казань", city_name: "Казань", region: "Республика Татарстан", country: "Россия", url: "/"  },
//   2060340: { id: 2060340, station_name: "Владимир Пассажирский", city_name: "Владимир", region: "Владимирская обл.", country: "Россия", url: "/"  },
//   2000160: { id: 2000160, station_name: "Брянск-1 Орловский", city_name: "Брянск", region: "Брянская обл.", country: "Россия", url: "/"  },
//   2010000: { id: 2010000, station_name: "Ярославль", city_name: "Ярославль", region: "Ярославская обл.", country: "Россия", url: "/"  },
//   2014370: { id: 2014370, station_name: "Белгород", city_name: "Белгород", region: "Белгородская обл.", country: "Россия", url: "/"  },
//   2014000: { id: 2014000, station_name: "Воронеж", city_name: "Воронеж", region: "Воронежская обл.", country: "Россия", url: "/"  },
//   2020000: { id: 2020000, station_name: "Саратов-1 Пассажирский", city_name: "Саратов", region: "Саратовская обл.", country: "Россия", url: "/"  },
//   2000150: { id: 2000150, station_name: "Курск", city_name: "Курск", region: "Курская обл.", country: "Россия", url: "/"  },
//   2064110: { id: 2064110, station_name: "Новороссийск", city_name: "Новороссийск", region: "Краснодарский край", country: "Россия", url: "/"  },
//   2000140: { id: 2000140, station_name: "Орёл", city_name: "Орёл", region: "Орловская обл.", country: "Россия", url: "/"  },
//   2200000: { id: 2200000, station_name: "Киев", city_name: "Киев", region: "", country: "Украина", url: "/"  },
//   2000170: { id: 2000170, station_name: "Смоленск Центральный", city_name: "Смоленск", region: "Смоленская обл.", country: "Россия", url: "/"  },
//   2024000: { id: 2024000, station_name: "Самара", city_name: "Самара", region: "Самарская обл.", country: "Россия", url: "/"  },
//   2064150: { id: 2064150, station_name: "Адлер", city_name: "Адлер", region: "Краснодарский край", country: "Россия", url: "/"  },
//   2100000: { id: 2100000, station_name: "Минск", city_name: "Минск", region: "", country: "Беларусь", url: "/"  },
//   2000125: { id: 2000125, station_name: "Рязань", city_name: "Рязань", region: "Рязанская обл.", country: "Россия", url: "/"  },
//   2064000: { id: 2064000, station_name: "Ростов", city_name: "Ростов", region: "Ростовская обл.", country: "Россия", url: "/"  },
//   2004600: { id: 2004600, station_name: "Тверь", city_name: "Тверь", region: "Тверская обл.", country: "Россия", url: "/"  },
//   2004300: { id: 2004300, station_name: "Петрозаводск Пассажирский", city_name: "Петрозаводск", region: "Республика Карелия", country: "Россия", url: "/"  },
//   2004400: { id: 2004400, station_name: "Новгород-на-Волхове", city_name: "Великий Новгород", region: "Новгородская обл.", country: "Россия", url: "/"  },
//   2004200: { id: 2004200, station_name: "Мурманск", city_name: "Мурманск", region: "Мурманская обл.", country: "Россия", url: "/"  },
//   2004579: { id: 2004579, station_name: "Окуловка", city_name: "Окуловка", region: "Новгородская обл.", country: "Россия", url: "/"  },
//   2010041: { id: 2010041, station_name: "Вологда", city_name: "Вологда", region: "Вологодская обл.", country: "Россия", url: "/"  },
//   2000174: { id: 2000174, station_name: "Тула", city_name: "Тула", region: "Тульская обл.", country: "Россия", url: "/"  },
//   2004660: { id: 2004660, station_name: "Бологое-Московское", city_name: "Бологое", region: "Тверская обл.", country: "Россия", url: "/"  },
//   2060600: { id: 2060600, station_name: "Киров Пассажирский", city_name: "Киров", region: "Кировская обл.", country: "Россия", url: "/"  },
//   2024600: { id: 2024600, station_name: "Уфа", city_name: "Уфа", region: "Республика Башкортостан", country: "Россия", url: "/"  },
//   2030000: { id: 2030000, station_name: "Екатеринбург Пассажирский", city_name: "Екатеринбург", region: "Свердловская обл.", country: "Россия", url: "/"  },
//   2064030: { id: 2064030, station_name: "Лазаревская", city_name: "Лазаревское", region: "Краснодарский край", country: "Россия", url: "/"  },
//   2064140: { id: 2064140, station_name: "Туапсе Пассажирская", city_name: "Туапсе", region: "Краснодарский край", country: "Россия", url: "/"  },
//   2064020: { id: 2064020, station_name: "Лоо", city_name: "Сочи", region: "Краснодарский край", country: "Россия", url: "/"  },
//   2020500: { id: 2020500, station_name: "Волгоград-1", city_name: "Волгоград", region: "Волгоградская обл.", country: "Россия", url: "/"  },
//   2064010: { id: 2064010, station_name: "Хоста", city_name: "Сочи", region: "Краснодарский край", country: "Россия", url: "/"  },
//   2064015: { id: 2064015, station_name: "Майкоп", city_name: "Майкоп", region: "Республика Адыгея", country: "Россия", url: "/"  },
//   2064220: { id: 2064220, station_name: "Невинномысская", city_name: "Невинномысск", region: "Ставропольский край", country: "Россия", url: "/"  },
//   2064291: { id: 2064291, station_name: "Армавир", city_name: "Армавир", region: "Краснодарский край", country: "Россия", url: "/"  },
//   2064170: { id: 2064170, station_name: "Минеральные Воды", city_name: "Минеральные Воды", region: "Ставропольский край", country: "Россия", url: "/"  },
//   2064250: { id: 2064250, station_name: "Белореченская", city_name: "Белореченск", region: "Краснодарский край", country: "Россия", url: "/"  },
//   4200379: { id: 4200379, station_name: "Гагра", city_name: "Гагра", region: "Гагрский район", country: "Россия", url: "/"  },
//   2064050: { id: 2064050, station_name: "Кисловодск", city_name: "Кисловодск", region: "Ставропольский край", country: "Россия", url: "/"  },
//   2064788: { id: 2064788, station_name: "Краснодар-1", city_name: "Краснодар", region: "Краснодарский край", country: "Россия", url: "/"  },
//   2064130: { id: 2064130, station_name: "Сочи", city_name: "Сочи", region: "Краснодарский край", country: "Россия", url: "/"  },
// };

//лето
// export const PopularStations = [
//   //Москва
//   { from: 2000000, to: 2004000}, { from: 2000000, to: 2060001}, { from: 2000000, to: 2064188}, { from: 2000000, to: 2060615}, { from: 2000000, to: 2060340},
//   { from: 2000000, to: 2000160}, { from: 2000000, to: 2010000}, { from: 2000000, to: 2014370}, { from: 2000000, to: 2014000}, { from: 2000000, to: 2020000},
//   { from: 2000000, to: 2000150}, { from: 2000000, to: 2064110}, { from: 2000000, to: 2000140}, { from: 2000000, to: 2200000}, { from: 2000000, to: 2000170},
//   { from: 2000000, to: 2024000}, { from: 2000000, to: 2064150}, { from: 2000000, to: 2100000}, { from: 2000000, to: 2000125}, { from: 2000000, to: 2064000},
//
//   //Питер
//   { from: 2004000, to: 2000000}, { from: 2004000, to: 2004600}, { from: 2004000, to: 2004300}, { from: 2004000, to: 2064188}, { from: 2004000, to: 2004400},
//   { from: 2004000, to: 2010000}, { from: 2004000, to: 2060001}, { from: 2004000, to: 2014370}, { from: 2004000, to: 2064150}, { from: 2004000, to: 2004200},
//   { from: 2004000, to: 2004579}, { from: 2004000, to: 2010041}, { from: 2004000, to: 2064000}, { from: 2004000, to: 2064788}, { from: 2004000, to: 2064110},
//   { from: 2004000, to: 2000174}, { from: 2004000, to: 2014000}, { from: 2004000, to: 2060615}, { from: 2004000, to: 2100000}, { from: 2004000, to: 2004660},
//
//   // Нижний Новгород
//   { from: 2060001, to: 2000000}, { from: 2060001, to: 2004000}, { from: 2060001, to: 2060600},
//
//   // Нижний Новгород
//   { from: 2064188, to: 2000000}, { from: 2064188, to: 2004000}, { from: 2064188, to: 2064000}, { from: 2064188, to: 2014000}, { from: 2064188, to: 2024000},
//   { from: 2064188, to: 2024600}, { from: 2064188, to: 2030000}, { from: 2064188, to: 2000125},
//
//   // Краснодар
//   { from: 2064788, to: 2064130}, { from: 2064788, to: 2064150}, { from: 2064788, to: 2064030}, { from: 2064788, to: 2000000}, { from: 2064788, to: 2064000},
//   { from: 2064788, to: 2064140}, { from: 2064788, to: 2004000}, { from: 2064788, to: 2064020}, { from: 2064788, to: 2020500}, { from: 2064788, to: 2064010},
//
//   // Сочи
//   { from: 2064130, to: 2064788}, { from: 2064130, to: 2000000}, { from: 2064130, to: 2064000}, { from: 2064130, to: 2064140}, { from: 2064130, to: 2064015},
//   { from: 2064130, to: 2004000}, { from: 2064130, to: 2064220}, { from: 2064130, to: 2020500}, { from: 2064130, to: 2064291},
//
//   // Адлер
//   { from: 2064150, to: 2064788}, { from: 2064150, to: 2000000}, { from: 2064150, to: 2064000}, { from: 2064150, to: 2004000}, { from: 2064150, to: 2064015},
//   { from: 2064150, to: 2020500}, { from: 2064150, to: 2064140}, { from: 2064150, to: 2020000}, { from: 2064150, to: 2024000}, { from: 2064150, to: 2064220},
//   { from: 2064150, to: 2014000}, { from: 2064150, to: 2064170}, { from: 2064150, to: 2060615}, { from: 2064150, to: 2064250}, { from: 2064150, to: 2064291},
//
//   // Казань
//   { from: 2060615, to: 2000000}, { from: 2060615, to: 2004000}, { from: 2060615, to: 2030000}, { from: 2060615, to: 2064150},
//
//   // Бранск
//   { from: 2000160, to: 2000000},{ from: 2000160, to: 2004000},
//
//   // Владимир
//   { from: 2060340, to: 2000000},
//   { from: 2060340, to: 2004000},
//
//   // Ярославль
//   { from: 2010000, to: 2000000}, { from: 2010000, to: 2004000},
//
//   // Воронеж
//   { from: 2014000, to: 2000000}, { from: 2014000, to: 2004000}, { from: 2014000, to: 2064150},
//
//   // Саратов
//   { from: 2020000, to: 2000000}, { from: 2020000, to: 2004000}, { from: 2020000, to: 2064150}, { from: 2020000, to: 2024000},
//
//   // Белгород
//   { from: 2014370, to: 2000000}, { from: 2014370, to: 2004000},
//
//   // Новороссийск
//   { from: 2064110, to: 2000000}, { from: 2064110, to: 2004000}, { from: 2064110, to: 2064000}, { from: 2064110, to: 2020500},
//
//   // Курск
//   { from: 2000150, to: 2000000}, { from: 2000150, to: 2004000},
//
//   // Орёл
//   { from: 2000140, to: 2000000}, { from: 2000140, to: 2004000},
//
//   // Смоленск
//   { from: 2000170, to: 2000000}, { from: 2000170, to: 2004000},
//
//   // Ростов
//   { from: 2064000, to: 2000000}, { from: 2064000, to: 2064150}, { from: 2064000, to: 2064130}, { from: 2064000, to: 2064788},
//   { from: 2064000, to: 2004000}, { from: 2064000, to: 2064030}, { from: 2064000, to: 2064110}, { from: 2064000, to: 2064188},
//   { from: 2064000, to: 2064020}, { from: 2064000, to: 2064140}, { from: 2064000, to: 4200379}, { from: 2064000, to: 2064050},
//
//   // Тверь
//   { from: 2004600, to: 2004000}, { from: 2004600, to: 2000000},
//
//   //{ from: , to: },
// ];


// export const PopularStations = [
//     { id: 2004000, station_name: "Санкт-Петербург", city_name: "Санкт-Петербург", region: "Ленинградская обл.", country: "Россия", url: "/" },
//     { id: 2060001, station_name: "Нижний Новгород", city_name: "Нижний Новгород", region: "Новгородсная обл.", country: "Россия", url: "/"  },
//     { id: 2000000, station_name: "Челябинский вокзал", city_name: "Челябинск", region: "Челябинская обл.", country: "Россия", url: "/"  },
//     { id: 2000001, station_name: "Челябинский1 вокзал", city_name: "Челябинск1", region: "Челябинская обл.", country: "Россия", url: "/"  },
//     { id: 2000002, station_name: "Челябинский2 вокзал", city_name: "Челябинск2", region: "Челябинская обл.", country: "Россия", url: "/"  },
//     { id: 2000003, station_name: "Челябинский3 вокзал", city_name: "Челябинск3", region: "Челябинская обл.", country: "Россия", url: "/"  },
//     { id: 2000004, station_name: "Челябинский4 вокзал", city_name: "Челябинск4", region: "Челябинская обл.", country: "Россия", url: "/"  },
//     { id: 2000005, station_name: "Челябинский5 вокзал", city_name: "Челябинск5", region: "Челябинская обл.", country: "Россия", url: "/"  },
//     { id: 2000006, station_name: "Челябинский6 вокзал", city_name: "Челябинск6", region: "Челябинская обл.", country: "Россия", url: "/"  },
//     { id: 2000007, station_name: "Челябинский7 вокзал", city_name: "Челябинск7", region: "Челябинская обл.", country: "Россия", url: "/"  },
//     { id: 2000008, station_name: "Челябинский8 вокзал", city_name: "Челябинск8", region: "Челябинская обл.", country: "Россия", url: "/"  },
//     { id: 2000009, station_name: "Челябинский9 вокзал", city_name: "Челябинск9", region: "Челябинская обл.", country: "Россия", url: "/"  },
//     { id: 2000010, station_name: "Челябинский10 вокзал", city_name: "Челябинск10", region: "Челябинская обл.", country: "Россия", url: "/"  },
//     { id: 2000011, station_name: "Челябинский11 вокзал", city_name: "Челябинск11", region: "Челябинская обл.", country: "Россия", url: "/"  },
//     { id: 2000012, station_name: "Челябинский12 вокзал", city_name: "Челябинск12", region: "Челябинская обл.", country: "Россия", url: "/"  }
// ];

export const QuickSelectStations = [ 2004000, 2060001 ];

export const ContentTreeItems = [
  { isExpanded : false, id: 1, title : "Возрат билета", content : null, url : "/return", redirect: false },
  { isExpanded : false, id: 2, title : "Энциклопедия пассажира", content : null, url : "/help/info", redirect: true },
];

export const PopularCities = [
  { id: 1, title: "Баку", img: "/js/react/web/img/baku.png", price: 2450},
  { id: 2, title: "Санкт-Петербург", img: "/js/react/web/img/baku.png", price: 1850},
  { id: 3, title: "Самара", img: "/js/react/web/img/baku.png", price: 4490},

];

export const Trains = [
  { id: 1, title: "Сапсан", /*img: "/js/react/web/img/sapsan.png"*/img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAAAkBAMAAACathLCAAAAGFBMVEUAAACRkZE2NjYSEhJaWlpsbGwkJCRISEjUgujeAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAvUlEQVQ4je2RPwuDMBDFH7mgX0OKtGsG0TXo4OrQDyCFkjVQsV+/lz/i1KRTpzx4kHvwu8slQFFR0Tc1wWY/ArrxcZhVGusWb2HrJiZCCUsT0lyltbcEljPVMjMNBO1tgBfIOp6bWVmtGZAZ5wtw5SUxcWVGdGPbpLHIXT0nNne/vl2f8B3y3MNzuIdsMaHMc4MfIHsbsg7Yk9TBSUXaocZF5N5T/8TRzKuRQgXU701xuWa4KDp/jKKLiv6vDzbyGpoqYpUTAAAAAElFTkSuQmCC", url: "https://vokzal.ru/help/info/sapsan"},
  { id: 2, title: "Стриж", /*img: "/js/react/web/img/strij.png"*/img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAAAkBAMAAACathLCAAAAGFBMVEUAAACRkZE2NjYSEhJaWlpsbGwkJCRISEjUgujeAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAvUlEQVQ4je2RPwuDMBDFH7mgX0OKtGsG0TXo4OrQDyCFkjVQsV+/lz/i1KRTpzx4kHvwu8slQFFR0Tc1wWY/ArrxcZhVGusWb2HrJiZCCUsT0lyltbcEljPVMjMNBO1tgBfIOp6bWVmtGZAZ5wtw5SUxcWVGdGPbpLHIXT0nNne/vl2f8B3y3MNzuIdsMaHMc4MfIHsbsg7Yk9TBSUXaocZF5N5T/8TRzKuRQgXU701xuWa4KDp/jKKLiv6vDzbyGpoqYpUTAAAAAElFTkSuQmCC"},
  { id: 3, title: "Аллерго", /*img: "/js/react/web/img/allergo.png"*/img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAAAkBAMAAACathLCAAAAGFBMVEUAAACRkZE2NjYSEhJaWlpsbGwkJCRISEjUgujeAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAvUlEQVQ4je2RPwuDMBDFH7mgX0OKtGsG0TXo4OrQDyCFkjVQsV+/lz/i1KRTpzx4kHvwu8slQFFR0Tc1wWY/ArrxcZhVGusWb2HrJiZCCUsT0lyltbcEljPVMjMNBO1tgBfIOp6bWVmtGZAZ5wtw5SUxcWVGdGPbpLHIXT0nNne/vl2f8B3y3MNzuIdsMaHMc4MfIHsbsg7Yk9TBSUXaocZF5N5T/8TRzKuRQgXU701xuWa4KDp/jKKLiv6vDzbyGpoqYpUTAAAAAElFTkSuQmCC", url: "https://vokzal.ru/help/info/allegro" },
  { id: 4, title: "Ласточка", /*img: "/js/react/web/img/lastochka.png"*/img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAAAkBAMAAACathLCAAAAGFBMVEUAAACRkZE2NjYSEhJaWlpsbGwkJCRISEjUgujeAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAvUlEQVQ4je2RPwuDMBDFH7mgX0OKtGsG0TXo4OrQDyCFkjVQsV+/lz/i1KRTpzx4kHvwu8slQFFR0Tc1wWY/ArrxcZhVGusWb2HrJiZCCUsT0lyltbcEljPVMjMNBO1tgBfIOp6bWVmtGZAZ5wtw5SUxcWVGdGPbpLHIXT0nNne/vl2f8B3y3MNzuIdsMaHMc4MfIHsbsg7Yk9TBSUXaocZF5N5T/8TRzKuRQgXU701xuWa4KDp/jKKLiv6vDzbyGpoqYpUTAAAAAElFTkSuQmCC"},
];


export const VokzalsAndStations = [
  { id: 2004000, station_name: "Санкт-Петербург", city_name: "Санкт-Петербург", region: "Ленинградская обл.", country: "Россия", url: "/stanciya/Sankt-Peterburg/2004000" },
  { id: 2060001, station_name: "Нижний Новгород", city_name: "Нижний Новгород", region: "Новгородсная обл.", country: "Россия", url: "/stanciya/Nizhnij-Novgorod-Moskovckij/2060001"  },
  { id: 2040000, station_name: "Челябинск", city_name: "Челябинск", region: "Челябинская обл.", country: "Россия", url: "/stanciya/Chelyabinsk-Glavnyj/2040000"  },
  { id: 2000012, station_name: "Самара", city_name: "Самара", region: "Самарская обл.", country: "Россия", url: "/stanciya/Samara/2024000"  }
];
