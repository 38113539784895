import React, { Component } from 'react';
import { connect } from 'react-redux'

import PropTypes from 'prop-types';
import { SearchResultTrainPropType, TrainTypesWithCarTypesPropType, TrainTypesWithCarTypes } from '../../../../src/types/';

import { step2SelectCarTypeAction, hideDetailsAction } from '../train_details/action';
import { reset_step3_state } from '../../step3/train_seats/action';

import { searchFormUrlParamsCarCategory } from '../../../utils/url_params';

import { showStep3 } from 'vokzal-platform/managers/navigation'

import TrainDetailsBodyView from 'vokzal-platform/components/step2/train_details_body/view'

TrainDetailsBodyView.propTypes = {
  show: PropTypes.bool.isRequired,
  priceTypes: PropTypes.arrayOf(TrainTypesWithCarTypesPropType).isRequired,
  train: SearchResultTrainPropType,
  onCarClassClick : PropTypes.func.isRequired,
};

class TrainDetailsBodyContainer extends Component {
  componentDidMount() {

    if (this.props.urlParams) {
      this.props.onCarClassClick(this.props.priceType.carCategory, this.props.priceType.service, this.props.urlParams)
    }
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    return <TrainDetailsBodyView {...this.props} />;
  }
}

const mapStateToProps = state => {

  const trainType = (state.step2_details.train.sapsan === 1) ? 'sapsan' : 'usual';

  const priceTypes = TrainTypesWithCarTypes[trainType];

  let urlParams = null; let priceType = null;
  const keys = Object.keys(state.step2_details.train.prices);
  if (keys.length === 1) {
    priceType = priceTypes.filter(p => p.carCategory === keys[0])[0];
    urlParams = searchFormUrlParamsCarCategory(state, priceType.carCategory, priceType.service);
  }

  return {
    urlParams, priceType,
    priceTypes,
    show : state.step2_details.show,
    train : state.step2_details.train
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseClick: train => {
      dispatch(hideDetailsAction());
    },
    onCarClassClick: (carCategory, service, urlParams) => {

      showStep3(dispatch, urlParams)
      dispatch(hideDetailsAction());
      dispatch(step2SelectCarTypeAction({ carCategory, service }));
      dispatch(reset_step3_state());
    }
  }
};

TrainDetailsBodyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainDetailsBodyContainer);

export default TrainDetailsBodyContainer