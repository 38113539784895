// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import PediaStationView from 'vokzal-platform/components/pedia/station/view'
import {fetch_pedia_station} from "../../../api/fetch_pedia_station";
import {stationFetchCompleteAction} from "./action";
import {fillScheduleGroupAction, scheduleFetchCompleteAction} from "../schedule/action";
import {show_pedia_tab_action} from "../tab_group/action";

PediaStationView.propTypes = {
  loaded: PropTypes.bool.isRequired,
  urlWithParams: PropTypes.string,
  preview : PropTypes.string,
  description : PropTypes.string,
};

class PediaStationContainer extends Component {
  componentDidMount() {
    const { items, onExpand, urlWithParams, onDataLoaded, loaded } = this.props;
    if (!loaded) {
      this.props.fetch(urlWithParams, onDataLoaded);
    }
    this.props.onExpand(items);

  }

  componentDidUpdate(prevProps) {
  }

  render() {
    return <PediaStationView {...this.props} />;
  }
}


const mapStateToProps = ({ pedia_station, pedia_schedule }) => {
  const items = pedia_schedule.loaded ? Object.keys(pedia_schedule.schedule_groups) : [];
     return {
       loaded: pedia_station.loaded,
       urlWithParams: pedia_station.urlWithParams,
       preview: pedia_station.preview,
       description: pedia_station.description,
       items
    }
}

const mapDispatchToProps = dispatch => {
    return {
      onDataLoaded : (time_spent, response) => {
        const schedule = response.schedule;
        delete response.schedule;

        const schedule_groups = response.schedule_groups;
        delete response.schedule_groups;

        dispatch(stationFetchCompleteAction({ time_spent, response }));
        dispatch(scheduleFetchCompleteAction({ time_spent, schedule, schedule_groups, loaded: true }));
        // dispatch(show_pedia_tab_action('info'));

        if (Object.keys(schedule_groups).length <= 5) {
          for (let key in schedule_groups) {
            dispatch(fillScheduleGroupAction(key));
          }
        }
      },
      onExpand: (items) => {
        if (items.length && items.length <= 5) {
          items.forEach(element => dispatch(fillScheduleGroupAction(element)));
        }
      },
      fetch: (urlWithParams, onDataLoaded) => {
        fetch_pedia_station(urlWithParams, onDataLoaded);
      }
    }
}


PediaStationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PediaStationContainer)

export default PediaStationContainer