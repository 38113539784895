import React from 'react';

import i18n from 'vokzal-platform/i18n';

const ReserveTimerView = ({ elapsedTime, showTimer }) => {

  let timerText = null;
  if (showTimer) {
    timerText = <div>{i18n.t('remaining')} <span className="font-weight-bold">{elapsedTime}</span> {i18n.t('minutes_short')}</div>;
  } else {
    timerText = i18n.t('reserve_timeout');
  }

  return <div className="confirmation-reserve-timer">{timerText}</div>
};

module.exports = ReserveTimerView;