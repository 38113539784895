export const isEmpty = obj => {
  for(let prop in obj) {
    if(obj.hasOwnProperty(prop))
      return false;
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

//https://medium.com/@tkssharma/objects-in-javascript-object-assign-deep-copy-64106c9aefab
//Object.assign не работает
export const cloneObject = obj => {
  return JSON.parse(JSON.stringify(obj));
}