import React from 'react';
import BusRow from '../../../../src/components/step2/bus_row/index';

const BusesSearchResultView = ({ buses, uiFlags }) => (
  <div className="search-results mr-2 ml-2 mb-4 pt-2 d-flex flex-column">
    {
      buses.map(function(m, index){
        return (<BusRow key={index} busItem={m} uiFlags={uiFlags[index]} />);
      })
    }
  </div>
);

module.exports = BusesSearchResultView;