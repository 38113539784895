import {connect} from 'react-redux'
import RegisterModalView from 'vokzal-platform/components/shared/register_modal/view'
import {hideRegisterModalAction, showRegisterModalAction} from "../../shared/register_modal/action";
import {userLoginAction, userSetValueAction} from "../../user/action";
import {user_register} from "../../../api/user_register";
import email_validator from 'email-validator';
import {hideLoginModalAction} from "../login_modal/action";

const mapStateToProps = state => {
    return {
        show: state.register_modal.show,
        title: 'Регистрация',
        email: state.user.email,
        email_error: state.user.email_error,
        error: state.user.error,
        success: state.user.login
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCloseClick: () => {
            dispatch(hideRegisterModalAction());
        },
        onClick: () => {
            dispatch(showRegisterModalAction())
        },
        onEmailChanged: (email) => {
            dispatch(userSetValueAction({email}))
        },
        onDataLoaded : (time_spent, response) => {
            // console.log(response);
            // dispatch(userLoginAction(response));
            dispatch(userSetValueAction({error: '', login: true, password: ''}));
        },
        onError : (error) => {
            dispatch(userSetValueAction({error}))
        },
        onSubmit: (email, onDataLoaded, onError) => {
            // console.log('onSubmit', email, password);
            email = email.trim();

            if(!email) {
                // console.log('onSubmit', ' пустое поле email');
                dispatch(userSetValueAction({email_error: 'Укажите электронную почту'}))
            } else if(!email_validator.validate(email)) {
                dispatch(userSetValueAction({email_error: 'Укажите корректную электронную почту'}))
            } else {
                dispatch(userSetValueAction({email_error: ''}));
                user_register('/api/register', {email}, onDataLoaded, onError);
            }
        }
    }
};

const RegisterModalContainter = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterModalView)

export default RegisterModalContainter