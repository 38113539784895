import React from 'react';

import i18n from 'vokzal-platform/i18n';

const SelectedSeatsView = ({ seats }) => (
  <div className="selected-seats-block d-flex justify-content-around text-center p-2">
    {
      seats.map((seat, index) => (
        <div key={index} className="selected-seats-element">
          <div className="selected-seats-element-title small font-weight-medium">{i18n.t('seat')}</div>

          <div className="selected-seats-element-number h1 font-weight-light mb-0">
            {
              (seat.seatNumber > 0) ? seat.seatNumber : '?'
            }
          </div>
          {
            (seat.isUpper !== undefined && seat.isUpper !== null) ? <div className="selected-seats-element-details small font-light">{(seat.isUpper) ? i18n.t('seat_upper') : i18n.t('seat_lower') }</div> : ''
          }
        </div>
      ))
    }
  </div>
);

module.exports = SelectedSeatsView;