import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux'

import PassengersCountView from 'vokzal-platform/components/step3/passengers_count/view';

PassengersCountView.propTypes = {
  showInfants : PropTypes.bool.isRequired,
};

const mapStateToPropsTrain = state => ({ showInfants: true });
const mapStateToPropsBus = state => ({ showInfants: false });

const mapDispatchToProps = dispatch => {
  return { }
};

export const PassengersCountTrainContainer = connect(
  mapStateToPropsTrain,
  mapDispatchToProps
)(PassengersCountView);


export const PassengersCountBusContainer = connect(
  mapStateToPropsBus,
  mapDispatchToProps
)(PassengersCountView);