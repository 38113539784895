import {connect} from 'react-redux'
import PropTypes from 'prop-types';

import ErrorMessageView from 'vokzal-platform/components/shared/error_message/view'

ErrorMessageView.propTypes = {
  message : PropTypes.string.isRequired,
  canGoBack : PropTypes.bool.isRequired,
  info : PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {

  const canGoBack = (ownProps.hasOwnProperty("canGoBack")) ? ownProps.canGoBack : true;
  const info = (ownProps.hasOwnProperty("info")) ? ownProps.info : '';
  const step2 = typeof state.search.step2 !== 'undefined' ? state.search.step2 : '';

  return { canGoBack, info, step2 }
}

const mapDispatchToProps = dispatch => {
  return {}
}


const ErrorMessageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorMessageView)

export default ErrorMessageContainer