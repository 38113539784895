export const SeatNumbersLux = [
  { centerTopLower : 1,  centerBottomLower : 2 },
  { centerTopLower : 3,  centerBottomLower : 4 },
  { centerTopLower : 5,  centerBottomLower : 6 },
  { centerTopLower : 7,  centerBottomLower : 8 },
  { centerTopLower : 9,  centerBottomLower : 10 },
  { centerTopLower : 11, centerBottomLower : 12 },
  { centerTopLower : 13, centerBottomLower : 14 },
  { centerTopLower : 15, centerBottomLower : 16 },
  { centerTopLower : 17, centerBottomLower : 18 },
];

export const SeatNumbersKupe = [
  { centerTopUpper : 2,  centerTopLower : 1,  centerBottomUpper : 4,  centerBottomLower : 3 },
  { centerTopUpper : 6,  centerTopLower : 5,  centerBottomUpper : 8,  centerBottomLower : 7 },
  { centerTopUpper : 10, centerTopLower : 9,  centerBottomUpper : 12, centerBottomLower : 11 },
  { centerTopUpper : 14, centerTopLower : 13, centerBottomUpper : 16, centerBottomLower : 15 },
  { centerTopUpper : 18, centerTopLower : 17, centerBottomUpper : 20, centerBottomLower : 19 },
  { centerTopUpper : 22, centerTopLower : 21, centerBottomUpper : 24, centerBottomLower : 23 },
  { centerTopUpper : 26, centerTopLower : 25, centerBottomUpper : 28, centerBottomLower : 27 },
  { centerTopUpper : 30, centerTopLower : 29, centerBottomUpper : 32, centerBottomLower : 31 },
  { centerTopUpper : 34, centerTopLower : 33, centerBottomUpper : 36, centerBottomLower : 35 },
]

export const SeatNumbersPlatskart = [
  { sideUpper: 54, sideLower : 53, centerTopUpper : 2,  centerTopLower : 1,  centerBottomUpper : 4,  centerBottomLower : 3 },
  { sideUpper: 52, sideLower : 51, centerTopUpper : 6,  centerTopLower : 5,  centerBottomUpper : 8,  centerBottomLower : 7 },
  { sideUpper: 50, sideLower : 49, centerTopUpper : 10, centerTopLower : 9,  centerBottomUpper : 12, centerBottomLower : 11 },
  { sideUpper: 48, sideLower : 47, centerTopUpper : 14, centerTopLower : 13, centerBottomUpper : 16, centerBottomLower : 15 },
  { sideUpper: 46, sideLower : 45, centerTopUpper : 18, centerTopLower : 17, centerBottomUpper : 20, centerBottomLower : 19 },
  { sideUpper: 44, sideLower : 43, centerTopUpper : 22, centerTopLower : 21, centerBottomUpper : 24, centerBottomLower : 23 },
  { sideUpper: 42, sideLower : 41, centerTopUpper : 26, centerTopLower : 25, centerBottomUpper : 28, centerBottomLower : 27 },
  { sideUpper: 40, sideLower : 39, centerTopUpper : 30, centerTopLower : 29, centerBottomUpper : 32, centerBottomLower : 31 },
  { sideUpper: 38, sideLower : 37, centerTopUpper : 34, centerTopLower : 33, centerBottomUpper : 36, centerBottomLower : 35 },
]

export const SeatNumbersSeated = [
  { leftWindow : null,                              leftSide : null,                              rightSide : { number : 2,  isFront : false}, rightWindow : { number : 1,  isFront : false} },
  { leftWindow : null,                              leftSide : null,                              rightSide : { number : 4,  isFront : false}, rightWindow : { number : 3,  isFront : false} },
  { leftWindow : { number : 63, isFront : true},    leftSide : { number : 64, isFront : true},    rightSide : { number : 6,  isFront : false}, rightWindow : { number : 5,  isFront : false} },
  { leftWindow : { number : 61, isFront : true},    leftSide : { number : 62, isFront : true},    rightSide : { number : 8,  isFront : false}, rightWindow : { number : 7,  isFront : false} },
  { leftWindow : { number : 59, isFront : true},    leftSide : { number : 60, isFront : true},    rightSide : { number : 10, isFront : false}, rightWindow : { number : 9,  isFront : false} },
  { leftWindow : { number : 57, isFront : true},    leftSide : { number : 58, isFront : true},    rightSide : { number : 12, isFront : false}, rightWindow : { number : 11, isFront : false} },
  { leftWindow : { number : 55, isFront : true},    leftSide : { number : 56, isFront : true},    rightSide : { number : 14, isFront : false}, rightWindow : { number : 13, isFront : false} },
  { leftWindow : { number : 53, isFront : true},    leftSide : { number : 54, isFront : true},    rightSide : { number : 16, isFront : false}, rightWindow : { number : 15, isFront : false} },
  { leftWindow : { number : 51, isFront : true},    leftSide : { number : 52, isFront : true},    rightSide : { number : 18, isFront : false}, rightWindow : { number : 17, isFront : false} },
  { leftWindow : { number : 49, isFront : true},    leftSide : { number : 50, isFront : true},    rightSide : { number : 20, isFront : false}, rightWindow : { number : 19, isFront : false} },
  { leftWindow : { number : 47, isFront : true},    leftSide : { number : 48, isFront : true},    rightSide : { number : 22, isFront : false}, rightWindow : { number : 21, isFront : false} },
  { leftWindow : { number : 45, isFront : true},    leftSide : { number : 46, isFront : true},    rightSide : { number : 24, isFront : false}, rightWindow : { number : 23, isFront : false} },
  { leftWindow : { number : 43, isFront : true},    leftSide : { number : 44, isFront : true},    rightSide : { number : 26, isFront : false}, rightWindow : { number : 25, isFront : false} },
  { leftWindow : { number : 41, isFront : true},    leftSide : { number : 42, isFront : true},    rightSide : { number : 28, isFront : false}, rightWindow : { number : 27, isFront : false} },
  { leftWindow : { number : 39, isFront : true},    leftSide : { number : 40, isFront : true},    rightSide : { number : 30, isFront : false}, rightWindow : { number : 29, isFront : false} },
  { leftWindow : { number : 37, isFront : true},    leftSide : { number : 38, isFront : true},    rightSide : { number : 32, isFront : false}, rightWindow : { number : 31, isFront : false} },
  { leftWindow : { number : 35, isFront : true},    leftSide : { number : 36, isFront : true},    rightSide : { number : 34, isFront : false}, rightWindow : { number : 33, isFront : false} },
]