import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import equal from 'deep-equal'

import { SeatSelectedPropType, PersonPropType } from '../../../types/';
import { cloneByType } from '../../../utils/passengers';

import UserFormView from 'vokzal-platform/components/step4/user_form/view'

import { formDataCleanupAction } from '../action';
import queryString from 'query-string';

UserFormView.propTypes = {
  isCleanupButton : PropTypes.bool.isRequired,
  type : PropTypes.oneOf(['adult', 'child', 'infant']).isRequired,
  ticketNumber : PropTypes.number.isRequired,
  ticketPrice : PropTypes.number.isRequired,
  seat : SeatSelectedPropType,
  userData : PersonPropType.isRequired,
};

const mapStateToProps = (state, ownProps) => {

  const queryObject = queryString.parse(location.search);
  const stopBuy = state.step4_documents.stop_buy;
  const isBus = queryObject.busID || (state.step2_details_bus.bus && !queryObject.trainNumber);

  const p = state.step3_passengers.passengers[ownProps.ticketNumber - 1];
  const isCleanupButton = ownProps.ticketNumber === 1 || !equal(p, cloneByType(p.type));

  const price = state.step2_details.price;

  let ticketPrice = (price.hasOwnProperty(ownProps.type)) ? price[ownProps.type] : 0;

  return { isCleanupButton, ticketPrice, stopBuy, isBus, priceDiapason: state.step3_categories.car_category_selected.price };
};

const mapDispatchToProps = dispatch => {
  return {
    onCleanupClick: ticketNumber => {
      dispatch(formDataCleanupAction(ticketNumber));
    }
  }

};

const UserFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFormView);

export default UserFormContainer