import React from 'react';
import BootstrapLabeledInput from '../../base/bootstrap_labeled_input';
import BootstrapInlineLabeledInputWithButton from '../../base/bootstrap_inline_labeled_input_with_button';

const StationInputView = ({ id, is_disabled, name, label, station, onStationClick, onClearClick }) => {
    return (
        <div>
           {/* <BootstrapInlineLabeledInputWithButton id={id}
                                                   placeholder="ДД.ММ.ГГГГ" is_disabled={is_disabled}
                                                   show={true} label={label} name={name} value={station.station_name}
                                                   onClick={() => {console.log(222, name, station.station_name)}}
                                                   buttonIcon="close" onButtonClick={() => {console.log(333, name)}} />*/}


            <BootstrapLabeledInput id={id} label={label}  name={name} value={station.station_name} is_disabled={is_disabled} required={true} onClick={() => onStationClick(name, station)} />



            {/*position: absolute;*/}
            {/*background: mediumvioletred;*/}
            {/*top: 40%;*/}
            {/*right: 0;*/}


            {/*<div className="clear-icon" onClick={() => {console.log(222, name, station.station_name)} }>*/}
                {/*<button type="button" className="close" data-dismiss="modal" aria-label="Close">*/}
                    {/*<span aria-hidden="true">&times;</span>*/}
                {/*</button>*/}
            {/*</div>*/}

        </div>
)};

module.exports = StationInputView;