// @flow
import PropTypes from 'prop-types';
import { SeatNumbersSeatedPropType } from '../../../../../src/types/';

import Sapsan2RClassMapView from 'vokzal-platform/components/step3/sapsan/sapsan_2r_class_map/view'

Sapsan2RClassMapView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersSeatedPropType).isRequired
};

const Sapsan2RClassMap = Sapsan2RClassMapView

export default Sapsan2RClassMap