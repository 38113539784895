// @flow
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {SearchResultTrainPropType, Step2UiFlagsPropType} from '../../../../src/types';

import {connect} from 'react-redux'

import {searchFormUrlParamsTrain} from '../../../utils/url_params'

import {search_trains} from '../../../api/search_trains'

import {minPrice} from "../../../api/price";

import {step2TrainsSearchErrorAction, step2ResetPostSearchAction} from './action'
import {hidePreloaderAction} from '../action'
import {step2TrainsSearchCompleteAction} from '../trains_search_result/action'
import {step2BusesSegmentsCompleteAction} from '../buses_search_result/action'
import {step2AddTabAction, step2TabClickAction} from '../search_result_tabs/action'

import {showDetailsAction} from '../train_details/action'

import {onRaspisanieLoaded} from '../../pedia/raspisanie/container'
import {showRaspisanie} from 'vokzal-platform/managers/navigation'

import TrainsSearchResultView from 'vokzal-platform/components/step2/trains_search_result/view'

TrainsSearchResultView.propTypes = {
    trains: PropTypes.arrayOf(SearchResultTrainPropType).isRequired,
    uiFlags: PropTypes.arrayOf(Step2UiFlagsPropType).isRequired,
    onTrainClick: PropTypes.func.isRequired,
    onTrainReset: PropTypes.func.isRequired
};

class TrainsSearchResultContainer extends Component {

    componentDidMount() {
        if (this.props.trains.length === 0) {
            let onLoad = null;
            if (this.props.post_search) {
                onLoad = (time_spent, response) => {
                    this.props.onDataLoaded(time_spent, response);
                    const filtered = response.trains.filter(t => this.props.post_search === t.number);
                    if (filtered.length === 0) {
                        this.props.onTrainReset();
                    } else {
                        this.props.onTrainClick(filtered[0]);
                    }
                }
            } else {
                onLoad = this.props.onDataLoaded;
            }

            search_trains(this.props.searchUrlWithParams, onLoad, this.props.onRaspisanieLoaded, this.props.onError);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.trains.length > 0) {
            let onLoad = null;
            if (this.props.post_search) {
                onLoad = (time_spent, response) => {
                    this.props.onDataLoaded(time_spent, response);
                    const filtered = response.trains.filter(t => this.props.post_search === t.number);
                    if (filtered.length === 0) {
                        this.props.onTrainReset();
                    } else {
                        this.props.onTrainClick(filtered[0]);
                    }
                }
            } else {
                onLoad = this.props.onDataLoaded;
            }

            search_trains(this.props.searchUrlWithParams, onLoad, this.props.onRaspisanieLoaded, this.props.onError);
        }
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        return <TrainsSearchResultView {...this.props} />;
    }
}


const mapStateToProps = state => {

    const s = state.step2;
    const d = state.step2_details;
    const t = state.step2_tabs;

    const searchUrlWithParams = (s.trains.length === 0) ? searchFormUrlParamsTrain(state.search) : '';

    return {
        searchUrlWithParams,
        trains: s.trains,
        uiFlags: s.trains_ui_flags,
        post_search: (s.post_search && d.train.number) ? d.train.number : null,
        show: t.active_tab === 'trains'
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onDataLoaded: (time_spent, response) => {
            dispatch(hidePreloaderAction());
            dispatch(step2TrainsSearchCompleteAction({time_spent, trains: response.trains}));
            dispatch(step2BusesSegmentsCompleteAction({
                time_spent,
                segments: response.bus_search_segments,
                search_params: response.bus_search_params
            }));

            const price = response.trains.map(t => (minPrice(t.prices).price)).reduce((a, b) => ((a > b) ? b : a));
            if (price) {
                dispatch(step2AddTabAction({code: 'trains', price}));
                dispatch(step2TabClickAction('trains'));
            }
        },
        onRaspisanieLoaded: (time_spent, response) => {
            onRaspisanieLoaded(dispatch, time_spent, response);
            showRaspisanie(dispatch, response.href);
        },
        onError: (error) => {
            dispatch(hidePreloaderAction());
            dispatch(step2TrainsSearchErrorAction(error));
        },
        onTrainClick: train => {
            dispatch(showDetailsAction(train))
        },
        onTrainReset: () => {
            dispatch(step2ResetPostSearchAction())
        }
    }
};

TrainsSearchResultContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrainsSearchResultContainer);

export default TrainsSearchResultContainer