import { TabTrainEmpty, TabBusEmpty } from '../../../../src/types';
import { minPrice } from '../../../../src/api/price';
import { isEmpty } from '../../../../src/utils/object';

export const step2TabsInitialStatePlatform = () => {
  let step2InitialState = { tabs: [] }

  if (typeof trains !== 'undefined') {
    step2InitialState.active_tab = 'trains';

    if (trains.length > 0) {
      let tab = TabTrainEmpty
      tab.price = trains.map((train) => (minPrice(train.prices).price)).reduce((a, b) => ((a > b) ? b : a))
      tab.isActive = true

      step2InitialState.tabs.push(tab)
    }
  }

  if (typeof train_search_form !== 'undefined' && !isEmpty(train_search_form)) {
    step2InitialState.tabs.push(TabTrainEmpty)
  }

  if (typeof bus_search_form !== 'undefined' && !isEmpty(bus_search_form)) {
    step2InitialState.tabs.push(TabBusEmpty)
  }

  if (typeof active_tab !== 'undefined') {
    step2InitialState.tabs = step2InitialState.tabs.map(t => {
      t.isActive = active_tab === t.code
      return t;
    })

    step2InitialState.active_tab = active_tab;
  }

  return step2InitialState;
}
