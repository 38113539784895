import React from 'react';

class BootstrapSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: props.value};

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {

        const value = event.target.value;

        this.setState({value: value});

        if(this.props.hasOwnProperty("onChange")) {
            this.props.onChange(value);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value
        });
    }

    render() {
        return (
            <select name={this.props.name}
                   id={this.props.id} className={(this.props.className || '') + ' form-control'}
                   value={this.state.value}
                   placeholder={this.props.placeholder} required={this.props.required} autoFocus={this.props.autoFocus}
                   onChange={this.handleChange}
            >
                {this.props.values.map( (item, index) => (
                    <option key={index} value={item.value} className="p-0">{item.title}</option>
                ))}
            </select>
        );
    }
}

module.exports = BootstrapSelect;