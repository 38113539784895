import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from "prop-types";

const OfferModalView = ({ show, onCloseClick, onOfferClick, text, className }) => {

    return (
        <div>
            <div className={className} onClick={() => onOfferClick()}>
                {text}
            </div>

            <Modal show={show} animation={false} dialogClassName="h-100 oferta-modal" backdropClassName="show resp-search-modal">

                <Modal.Header>
                    <div className="d-flex flex-column">
                        <div className="p-0 d-flex justify-content-between">
                            <Modal.Title id="contained-modal-title-lg" componentClass="h5"><strong>ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ МЕЖДУ ПОЛЬЗОВАТЕЛЕМ И ООО "Вокзал.РУ"</strong></Modal.Title>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onCloseClick() }>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </Modal.Header>

                <Modal.Body bsClass="modal-body pt-0">
                    <div id="helpDivContent"><p> <strong>ТЕРМИНЫ<br/> </strong>Вокзал - Общество с
                        ограниченной ответственностью "Вокзал.РУ", далее по тексту - "Вокзал".<br/> <br/> Пользователь - дееспособное
                            физическое лицо, достигшее 18 лет, имеющее законное право вступать в договорные отношения с Вокзал, в том числе
                            размещать заказ или бронировать услуги на сайте www.vokzal.ru, либо использующее услуги, либо указанное в качестве
                            получателя услуги, либо иным образом пользующееся услугами, приобретенными на сайте www.vokzal.ru, исключительно для
                            личных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности.<br/> <br/> Сайт - сайт
                                www.vokzal.ru .<br/> <br/> Заказ - должным образом оформленный запрос Пользователя на получение услуг, выбранных на
                                    Сайте.<br/> <br/>&nbsp;Электронный железнодорожный билет - документ, используемый для удостоверения договора перевозки
                                        пассажира и багажа, в котором информация о железнодорожной перевозке пассажира и багажа представлена в
                                        электронно-цифровой форме, и подлежащий обмену на проездные документы на стойках регистрации электронного билета,
                                        расположенных в здании железнодорожных вокзалов.<br/> <br/>&nbsp;Система бронирования -
                                            система, содержащая информацию, касающуюся расписания, наличия мест на &nbsp;железнодорожный транспорт, тарифов и
                                            правил их применения, а также других условий предоставления предлагаемых услуг размещается на сайте www.vokzal.ru в
                                            полном соответствии с тем, как она представлена в системах бронирования непосредственными поставщиками данных услуг
                                            или их полномочными представителями. Информация в системе бронирования может в любой момент быть изменена или
                                            дополнена, в связи с этим Пользователю предлагается использовать систему бронирования в режиме "как есть".<br/> <br/>
                                                <strong>СТАТЬЯ 1. ОБЩИЕ ПОЛОЖЕНИЯ<br/> </strong></p>
                        <p>1.1. Заказывая услуги через Вокзал, Пользователь соглашается с условиями Договора
                            публичной оферты (далее - Договор), изложенными ниже.<br/> <br/> 1.2. Настоящий Договор, а также информация об
                                услугах, представленная на Сайте, являются публичной офертой в соответствии со ст. 435 и ч. 2 ст. 437 ГК РФ.<br/>
                                    <br/> 1.3. К отношениям между Пользователем и Вокзал применяются положения Гражданского Кодекса РФ, Федерального
                                        закона от 24.11.1996 № 132-ФЗ "Об основах туристской деятельности в РФ", Закона РФ от 07.02.1992 № 2300-1 "О
                                        защите прав потребителей", Приказа Министерства транспорта Российской Федерации (Минтранс России) от 23 июля
                                        2007 г. № 102 ("Об установлении формы электронного проездного документа (билета) на железнодорожном
                                        транспорте"), Постановления Правительства Российской Федерации № 111 от 02.03.2005 "Об утверждении Правил
                                        оказания услуг по перевозкам на железнодорожном транспорте пассажиров, а также грузов, багажа и грузобагажа для
                                        личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности", а также
                                        и иных правовых актов.<br/> <br/> 1.4. Все договоры о предоставлении услуг перевозки, информация о которых
                                            размещена на Сайте, заключаются Пользователем напрямую с лицами, предоставляющими эти услуги.<br/> <br/> 1.5.
                                                Вокзал оставляет за собой право вносить изменения в настоящий Договор, в связи с чем Пользователь обязуется
                                                регулярно отслеживать изменения в Договоре.<br/> <br/> 1.6. Пользователь соглашается с Договором путем
                                                    проставления отметки в виде "галочки" в графе "Я принимаю условия оферты" на последнем этапе оформления Заказа
                                                    на Сайте. Соглашаясь с условиями настоящего Договора, Пользователь подтверждает свое право и дееспособность,
                                                    финансовую состоятельность, а также сознаёт ответственность за обязательства, возложенные на него в результате
                                                    заключения настоящего Договора. Пользователь подтверждает достоверность своих личных данных, а также данных
                                                    пассажиров и принимает на себя всю ответственность за их точность, полноту и достоверность. Пользователь
                                                    принимает на себя все возможные коммерческие риски (оформление нового заказа, изменение тарифа, возврат денег и
                                                    проч.), связанные с его виновными действиями по допущению ошибок, неточностей в предоставлении данных о
                                                    пассажирах.<br/> <br/> <strong>СТАТЬЯ 2. ОФОРМЛЕНИЕ И СРОКИ ПОДТВЕРЖДЕНИЯ ЗАКАЗА<br/> </strong></p>
                        <p>2.1. Заказ оформляется Пользователем самостоятельно на Сайте. Подробности оформления
                            Заказа описаны в разделе "Как сделать заказ на сайте".<br/> <br/> 2.2. При оформлении Заказа Пользователь должен
                                заполнить все поля, указанные в Системе бронирования как "обязательные" для заполнения.<br/> <br/> 2.3.Заказы,
                                    оформленные Пользователем, носят окончательный характер. Сразу же после поступления от Пользователя оформленного
                                    Заказа и произведения оплаты Пользователю отображается его электронный билет, копия отправляется на email и
                                    мобильный телефон, в случае, если они указаны.<br/> <br/> 2.4. Изменить личные данные любого из пассажиров в
                                        оформленном Заказе невозможно. Пользователь принимает на себя все возможные коммерческие риски (оформление
                                        нового Заказа, изменение тарифа, возврат денежных средств и проч.), связанные с его виновными действиями по
                                        допущению ошибок, неточностей в предоставлении данных о пассажирах.<br/> <br/> <strong>СТАТЬЯ 3. ОПЛАТА ЗАКАЗА<br/>
                                        </strong></p>
                        <p>3.1. Стоимость Заказа указывается на Сайте. В случае наличия ошибок в указании
                            стоимости Заказа, Вокзал при первой возможности информирует об этом Пользователя. Пользователь может по своему
                            выбору подтвердить Заказ по исправленной стоимости либо аннулировать Заказ. При невозможности связаться с
                            Пользователем данный Заказ считается аннулированным. Если Заказ был оплачен, Вокзал возвращает Пользователю
                            оплаченные за Заказ денежные средства путем их перечисления на Пользовательский счет и впоследствии по
                            письменному заявлению могут быть возвращены Пользователю.<br/> <br/>&nbsp;3.2. Способы оплаты Заказа указаны на
                                Сайте в разделе "Способы оплаты".<br/> <br/> 3.3. Особенности оплаты Заказа с помощью банковских карт:<br/> <br/>
                                    3.3.1. В соответствии с положением ЦБ РФ "Об эмиссии банковских карт и об операциях, совершаемых с
                                    использованием платежных карт" от 24.12.2004 №266-П операции по банковским картам совершаются держателем карты
                                    либо уполномоченным им лицом.<br/> <br/>&nbsp;3.3.2. Порядок оплаты с помощью банковских карт указан на Сайте в
                                    разделе "Способы оплаты".<br/> <br/> 3.3.3. Авторизация операций по банковским картам осуществляется банком. Если
                                        у банка есть основания полагать, что операция носит мошеннический характер, то банк вправе отказать в
                                        осуществлении данной операции. Мошеннические операции с банковскими картами попадают под действие статьи 159 УК
                                        РФ.<br/> <br/> Согласно ст.159 УК РФ мошенничеством считается хищение чужого имущества или приобретение права на
                                            чужое имущество путем обмана или злоупотребления доверием и наказывается штрафом в размере до 120 000 рублей или
                                            в размере заработной платы или иного дохода осужденного за период до 1 года, либо обязательными работами на срок
                                            до 180 часов, либо исправительными работами на срок от 6 месяцев до 1 года, либо арестом на срок от 2 до 4
                                            месяцев, либо лишением свободы на срок до 2 лет.<br/> <br/> 3.3.4. Во избежание случаев различного рода
                                                неправомерного использования банковских карт при оплате, все Заказы, оформленные на Сайте и оплаченные
                                                банковской картой, проверяются Вокзал. Согласно Правил международных платежных систем в целях проверки личности
                                                владельца и его правомочности на использование карты Пользователь, оформивший такой Заказ, обязан по запросу,
                                                поступившему от Оператора, предоставить копию двух страниц паспорта владельца банковской карты - разворота с
                                                фотографией, а также копию банковской карты с обеих сторон (номер карты нужно закрыть, кроме последних четырех
                                                цифр) по факсу или по электронной почте в виде сканированных копий. В случае не предоставления Пользователем
                                                запрашиваемых документов или наличия сомнений в их подлинности, Вокзал оставляет за собой право без объяснения
                                                причин аннулировать Заказ. Стоимость Заказа возвращается на карту владельца.<br/> <br/> СТАТЬЯ 4. ИЗМЕНЕНИЕ
                                                    ЗАКАЗА. СЕРВИСНЫЙ СБОР<br/> 4.1. Все условия Заказа, в том числе: возврат, равно как и другие условия оказания
                                                        услуг регламентируются правилами применения тарифа на данную услугу, договором-оферты на железнодорожную
                                                        перевозку с ОАО "РЖД", договором публичной оферты Вокзал, Постановлением Правительства Российской Федерации №
                                                        111 от 02.03.2005 "Об утверждении Правил оказания услуг по перевозкам на железнодорожном транспорте пассажиров,
                                                        а также грузов, багажа и грузобагажа для личных, семейных, домашних и иных нужд, не связанных с осуществлением
                                                        предпринимательской деятельности", а также другими законодательными актами РФ. Со всеми условиями Заказа
                                                        Пользователь знакомится в процессе бронирования. В случае, если Пользователю не понятны какие-либо условия
                                                        Заказа, в том числе условия возврата, Пользователь должен уточнить необходимую ему информацию у Оператора.<br/>
                                                            <br/> 4.2. Вокзал имеет право взимать с Пользователя дополнительные сервисные сборы - за услуги агентства по
                                                                бронированию и оформлению электронных билетов, а также за возврат ранее оформленного билета. Наличие и размер
                                                                данных сборов зависит от условий, предоставляемых перевозчиком. Сервисный сбор за оформление электронного
                                                                железнодорожного билета включается в стоимость Заказа. В случае возврата билета Пользователем, Сервисный сбор не
                                                                подлежит возврату. Размер Сервисного сбора за возврат билета зависит от стоимости билета. Точную сумму
                                                                необходимо уточнять у Оператора на день возврата.<br/> <br/> 4.3. В случае, когда Пользователь по каким-либо
                                                                    причинам хочет произвести возврат оформленного электронного железнодорожного билета, он должен обратиться в
                                                                    Вокзал с просьбой оформить возврат. Возврат электронного железнодорожного билета производится Вокзал только до
                                                                    момента получения Пользователем проездных документов, оформленных на бланке ОАО "РЖД".<br/> <br/> 4.4.
                                                                        Пользователь не может оформить частичный возврат Заказа (отказ от одного места по электронному железнодорожному
                                                                        билету) в Вокзал. При необходимости возврата одного места в электронном билете Пользователь оформляет проездные
                                                                        документы на каждого из пассажиров на стойке регистрации электронного железнодорожного билета или в кассе ОАО
                                                                        "РЖД", а затем осуществляет возврат проездного документа в кассе возврата ОАО "РЖД".<br/> <br/> 4.5. Возврат
                                                                            электронного железнодорожного билета производится не позднее 3-х часов до отправления поезда (в том случае, если
                                                                            возврат оформляется в рабочие часы Вокзал). В противном случае Пользователь должен сначала получить проездной
                                                                            документ железнодорожного билета в кассах ОАО "РЖД" или на стойках регистрации электронного билета, а затем
                                                                            сдать их в кассе возврата ОАО "РЖД".<br/> <br/> 4.6. В случае возврата Пользователем электронного железнодорожного
                                                                                билета, стоимость Заказа, за вычетом фактических расходов Вокзал, перечисляется Пользователю на его банковскую
                                                                                карту. Под фактическими расходами понимаются расходы, понесенные Вокзал в целях организации исполнения Заказа
                                                                                Пользователя, в том числе оплата штрафов в пользу ОАО "РЖД" и прочих лиц, предоставляющих услуги по оформлению
                                                                                билетов, а также сервисный сбор, взимаемый Вокзал за предоставление услуг. С условиями возврата железнодорожных
                                                                                билетов Пользователь может ознакомиться на Сайте в разделе "Возврат железнодорожных билетов".<br/> <br/> <strong>СТАТЬЯ
                                                                                    5. ГАРАНТИИ И ОТВЕТСТВЕННОСТЬ СТОРОН<br/> </strong></p>
                        <p>5.1. Гарантии и ответственность Вокзал.<br/> <br/> 5.1.1. Вокзал не несет перед
                            Пользователем ответственности в случае полной или частичной неработоспособности системы и ее компонентов в
                            течение какого-либо времени, а также при отсутствии возможности доступа Пользователя к системе или несения им
                            любых косвенных или прямых затрат в связи с данными обстоятельствами. Все предложения, цены, а также условия
                            продажи могут быть: изменены без уведомления Пользователя, ограничены по времени, наличию мест и срокам
                            предварительного заказа, датам путешествия, срокам минимального или максимального пребывания в месте назначения,
                            факторами выходных дней и праздников, сезонным колебаниям цен, листам ожидания, а также забастовками и временной
                            неработоспособностью систем бронирования и/или подвержены другим изменениям, условиям и ограничениям.<br/> <br/>
                                5.1.2. Вокзал не несет ответственность за негативные последствия и убытки, возникшие в результате событий и
                                обстоятельств, находящихся вне сферы его компетенции, а также за действия (бездействие) третьих лиц, а
                                именно:<br/> <br/> - в случае невозможности выполнения принятых на себя обязательств, вследствие недостоверности,
                                недостаточности и несвоевременности сведений и документов, предоставленных Пользователем, или нарушения
                                Пользователем условий настоящего Договора или требований к документам;<br/> <br/>&nbsp;- за действия перевозчиков
                                    (изменение, отмена, перенос, задержка отправления &nbsp;поездов, за сохранность, потерю или повреждение багажа,
                                    груза, ценностей и документов Пользователя (пассажира) в течение всего срока их поездки. В этих случаях
                                    ответственность перед Пользователем (пассажиром) несут &nbsp;железнодорожные перевозчики в соответствии
                                    правилами и действующим законодательством РФ. Претензии Пользователя (пассажира) рассматриваются перевозчиками
                                    на основе законодательства РФ и правил международных перевозок;<br/> <br/> - за действия таможенных и
                                        иммиграционных властей;<br/> <br/> - вследствие ограничения права Пользователя (пассажира) на выезд из РФ
                                            компетентными органами;<br/> <br/> - за действия консульств иностранных государств, в том числе за задержку, отказ
                                                или изменение сроков выдачи въездных виз;<br/> <br/> - за последствия нарушения Пользователем (пассажиром)
                                                    таможенных и пограничных формальностей, правил проезда и провоза багажа, а также нарушения особых правил
                                                    поведения в стране временного пребывания;<br/> <br/> - за отсутствие у Пользователя (пассажира) проездных
                                                        документов, выданных ему Вокзал;<br/> <br/>&nbsp;- за неявку или опоздание Пользователя (пассажира) к месту
                                                            отправления поезда;<br/> <br/>&nbsp;- за несоблюдение Пользователем (пассажиром) установленных перевозчиком правил
                                                                поведения в вагоне поезда;<br/> <br/> - за отсутствие у Пользователя (пассажира) оформленных заграничных паспортов
                                                                    к моменту начала поездки, соответствующих документов, регулирующих вопросы вывоза детей;<br/> <br/> - за
                                                                        подлинность и правильность оформления документов (достоверность содержащихся в них сведений).<br/> <br/> 5.2.
                                                                            Пользователь (пассажир) подтверждает и гарантирует, что он ознакомлен и согласен:<br/> <br/> - и принимает на себя
                                                                                всю ответственность за подготовку всех необходимых документов для поездки. Пользователю (пассажиру) следует
                                                                                ознакомиться и исполнять все требования Страны, в которую он направляется, в том числе требования к оформлению
                                                                                документов, необходимых при выезде и прибытии, необходимости оформления виз, или иных документов для выезда
                                                                                детей, животных, необходимости оформления разрешений на вывоз оружия, на вывоз художественных ценностей и прочих
                                                                                разрешений и согласований. Вокзал не несет ответственности за незнание или несоблюдение Пользователем
                                                                                (пассажиром) данных требований.<br/> <br/> - с условиями применения тарифов перевозчиков, в том числе с условиями
                                                                                    возврата и обмена билетов;<br/> <br/> - с требованиями, предъявляемыми к заграничным паспортам и иным формальным
                                                                                        документам, в том числе, об остаточном сроке действия заграничного паспорта, необходимом для получения визы и
                                                                                        въезда в страну пребывания;<br/> <br/> - об особенностях и правилах пограничного (таможенного) контроля (режима)
                                                                                            РФ и иностранных государств;<br/> <br/> - об обязанности соблюдать таможенные и пограничные правила;<br/> <br/> - о
                                                                                                том, что Пользователь (пассажир) самостоятельно несет полную ответственность за действительность заграничных
                                                                                                паспортов, согласий на выезд несовершеннолетних детей и иных документов, необходимых для пересечения границы, за
                                                                                                достоверность сведений, содержащихся в этих документах;<br/> <br/> - о том, что депортация Пользователя
                                                                                                    (пассажира) с недействительными въездными или выездными документами осуществляется исключительно за счет
                                                                                                    Пользователя (пассажира). Пользователь (пассажир) обязан самостоятельно получить необходимую информацию в
                                                                                                    консульстве соответствующей страны.<br/> <br/>&nbsp;5.3. Стороны освобождается от ответственности за ненадлежащее
                                                                                                        исполнение или неисполнение обязательств по настоящему Договору в случае наступления обстоятельств непреодолимой
                                                                                                        силы, к таковым стороны относят следующие обстоятельства: пожар, эпидемия, землетрясение, террористический акт,
                                                                                                        наводнение, ураган, шторм, цунами, оползень, другие стихийные бедствия и катаклизмы, военные действия любого
                                                                                                        характера, повлекших невозможность надлежащего исполнения Сторонами своих обязательств и прочие обстоятельства,
                                                                                                        на которые стороны не могут повлиять и предотвратить.<br/> <br/> 5.4. О наступлении (и прекращении) указанных в
                                                                                                            пункте настоящего договора обстоятельств, Сторона, для которой возникла невозможность исполнения обязательств по
                                                                                                            настоящему договору, обязана немедленно известить другую Сторону. Неуведомление или несвоевременное уведомление
                                                                                                            об указанных обстоятельствах лишает Стороны права ссылаться на эти обстоятельства и не освобождает от
                                                                                                            ответственности по настоящему договору. Срок исполнения обязательств Сторонами по настоящему договору
                                                                                                            отодвигается соразмерно времени, в течение которого будут действовать такие обстоятельства. Если обстоятельства
                                                                                                            будут продолжаться более 14 (четырнадцати) дней, каждая из Сторон будет иметь право отказаться от исполнения
                                                                                                            обязательств по настоящему договору. Наличие указанных обстоятельств подтверждается соответствующими решениями
                                                                                                            федеральных органов государственной власти, органов государственной власти субъектов РФ, органов местного
                                                                                                            самоуправления, принимаемыми ими в соответствии с федеральными законами.<br/> <br/> 5.5. При наступлении указанных
                                                                                                                обстоятельств Стороны вправе в судебном порядке потребовать расторжения договора.<br/> <br/> <strong>СТАТЬЯ 6.
                                                                                                                    ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</strong></p>
                        <p>6.1. Вся текстовая информация и графические изображения, находящиеся на Сайте, являются собственностью
                            Вокзал.<br/> <br/> 6.2. Перепечатка, воспроизведение в любой форме, распространение, в том числе в переводе, любых
                                материалов с Сайта возможны только с письменного разрешения Вокзал.<br/> <br/> 6.3. При использовании документов и
                                    связанных с ними графических изображений, расположенных на Сайте необходимо соблюдать следующие требования:<br/>
                                        <br/> Указывать, что авторские права принадлежат Вокзал.<br/> <br/> 6.4. При перепечатке материалов ссылаться на
                                            Сайт, как на источник публикации, и указать иные источники информации, упомянутые в материале. Если материалы
                                            размещаются в Интернете, то необходимо сделать ссылку на Сайт;<br/> <br/> 6.5. Документы и связанные с ними
                                                графические изображения, расположенные на Сайте, могут быть использованы только в информационных, некоммерческих
                                                или личных целях;<br/> <br/> 6.6. Никакие документы и связанные с ними графические изображения, расположенные на
                                                    Сайте, не должны изменяться никаким способом;<br/> <br/> 6.7. Никакие графические изображения, расположенные на
                                                        Сайте, не должны использоваться отдельно от сопровождающего их текста.<br/> <br/> 6.8. Документы и связанные с
                                                            ними графические изображения, расположенные на Сайте, могут включать неточности или орфографические ошибки.
                                                            Изменение информации на Сайте производится периодически.<br/> <br/> <strong>СТАТЬЯ 7. КОНФИДЕНЦИАЛЬНОСТЬ И ЗАЩИТА
                                                                ПЕРСОНАЛЬНЫХ ДАННЫХ<br/> </strong><br/> 7.1 Вокзал использует информацию:<br/> <br/>- для отправления 14-и
                                                                значного номера заказа по СМС на мобильный телефон;<br/> - для отправления бланка электронного билета на
                                                                    e-mail.<br/> <br/> 7.2. Вокзал обязуется не разглашать полученную от Пользователя информацию.<br/> <br/>&nbsp;7.3.
                                                                        Не считается нарушением обязательства разглашения информации в соответствии с обоснованными и применимыми
                                                                        требованиями закона.<br/> <br/> 7.4. Вокзал получает информацию об ip-адресе посетителя Сайта. Данная информация
                                                                            не используется для установления личности посетителя.<br/> <br/> 7.5. Вокзал не несет ответственности за сведения,
                                                                                предоставленные Пользователем на Сайте в общедоступной форме.<br/> <br/> <strong>8. ПРОЧИЕ УСЛОВИЯ<br/>
                                                                                </strong><br/> 8.1. К отношениям между Пользователем и Вокзал применяется право Российской Федерации.<br/> <br/>
                                                                                    8.2. В случае возникновения у Пользователя вопросов он должен обратиться в Службу по работе с клиентами Вокзал
                                                                                    по телефону или через on-line чат на Сайте. В том случае, если у Пользователя имеются претензии непосредственно
                                                                                    к качеству работы Вокзал, Пользователь подает письменную претензию в срок не более 20 (Двадцати) дней, с момента
                                                                                    возникновения причины спора. В течение 10 (Десяти) дней Вокзал рассматривает претензию и направляет Пользователю
                                                                                    обоснованный ответ. Все возникающее споры Стороны будут стараться решить путем переговоров, при недостижении
                                                                                    соглашения спор будет передан на рассмотрение в судебный орган по месту нахождения Вокзал.<br/> <br/> 8.3.
                                                                                    Признание судом недействительности какого-либо положения настоящего Договора не влечет за собой
                                                                                    недействительность остальных положений.<br/> <br/> Принимаю условия оферты и подтверждаю итоговую сумму к оплате,
                                                                                        включая все налоги и сборы</p>
                        <p><br/> ООО «Вокзал.РУ»<br/> <br/> Юридический адрес: 115114, г. Москва, ул. Летниковская, д. 4, стр. 5, пом. 8<br/>
                            Почтовый адрес: 107045, г. Москва, Печатников переулок, д.20, стр.1<br/> ИНН: 9705098457<br/> КПП: 770501001<br/>
                            <br/> Банковские реквизиты:<br/> Р/С: 40702810400000013788<br/>Новый Символ (АО) г. Москва<br/> К/С:
                                30101810645250000209<br/> БИК: 044525209<br/> <br/> <br/> Генеральный директор<br/> <br/> Д. А. Непочатых
                                    _____________________</p></div>

                    <button className="close close-bottom" onClick={() => onCloseClick()}>Закрыть</button>
                </Modal.Body>
            </Modal>



        </div>
)};

OfferModalView.propTypes = {
    show: PropTypes.bool.isRequired,
    onOfferClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
};

module.exports = OfferModalView;




