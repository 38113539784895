// @flow
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { RatingWeights } from '../../../../src/types/'

import RatingView from 'vokzal-platform/components/step2/rating/view'

RatingView.propTypes = {
  stars : PropTypes.arrayOf(
    PropTypes.oneOf(['star', 'star_half', 'star_border'])
  ).isRequired
};

const mapStateToPropsTrain = (state, ownProps) => {

    const mappedRating = Object.keys(ownProps.ratingItem).map((prop) => (
        { maxRating : RatingWeights[prop], rating : (ownProps.ratingItem[prop]) ? RatingWeights[prop] : 0 }
    ));
    const maxRating = mappedRating.reduce((sum, o) => ( sum + o.maxRating ), 0);
    const rating = mappedRating.reduce((sum, o) => ( sum + o.rating ), 0);

    let stars = [];

    let starsCnt = Math.round(10/(maxRating/rating)) / 2;
    for (let i = 1; i <= 5; i++) {
        if (starsCnt - i >= 0) {
            stars.push('star');
        } else if (starsCnt - i === -0.5) {
            stars.push('star_half');
        } else if (starsCnt - i < 5) {
            stars.push('star_border');
        }
    }

    return { stars }
};

const mapStateToPropsBus = (state, ownProps) => {

    const stars = [];

    return { stars }
};

const mapDispatchToProps = dispatch => {
    return {

    }
}

export const RatingTrainContainer = connect(
    mapStateToPropsTrain,
    mapDispatchToProps
)(RatingView)

export const RatingBusContainer = connect(
    mapStateToPropsBus,
    mapDispatchToProps
)(RatingView)

