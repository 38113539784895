import React from 'react';
import i18n from 'vokzal-platform/i18n';

import OrderDetailsTrain from '../../../../src/components/confirmation/order_details_train';
import OrderDetailsBus from '../../../../src/components/confirmation/order_details_bus';

const OrderDetailsOfflineView = ({ is_bus_tickets, order_id, order_form, seat_changed }) => (
  <div className="confirmation-order-details rounded">
    <div className="confirmation-order-details-header p-2">
      <span className="font-other-light-again mr-3">
        {i18n.t('order_number')}
      </span>

      <span className="font-weight-bold font-medium-light">№{order_id}</span>
    </div>

    <div className="confirmation-order-details-body rounded">
      {(is_bus_tickets) ? <OrderDetailsBus order_form={order_form} /> : <OrderDetailsTrain order_form={order_form} /> }
    </div>

    <div className="confirmation-order-details-footer d-flex justify-content-between p-2">
      <div className="confirmation-order-details-payment-system-logo" />
      <div className="confirmation-order-details-payment-info text-right font-medium-light">
        <div className="">{i18n.t('order_price')}</div>
        <div className="h2 mb-0 font-weight-bold">{order_form.amount} руб.</div>
      </div>
    </div>
  </div>
);

module.exports = OrderDetailsOfflineView;