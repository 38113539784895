import { connect } from 'react-redux'

import PropTypes from 'prop-types';

import { PopularCityPropType } from '../../../../src/types/index';

import PopularCityView from 'vokzal-platform/components/home/popular_city/view';

PopularCityView.propTypes = {
  show: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PopularCityPropType).isRequired,
};

const mapStateToProps = state => {
    return { show: false, items : state.popular_cities.items, }
};

const mapDispatchToProps = dispatch => {
    return {
        onExpandClick: (contentTreeItem) => {
            // dispatch(toggleContentTreeAction(contentTreeItem));
        }
    }
};


const PopularCityContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PopularCityView);

export default PopularCityContainer