
import ru from '../../i18n/locales/ru'
import en from '../../i18n/locales/en'

// import ru from '../../common/i18n/locales/ru'
// import en from '../../common/i18n/locales/en'

let I18n = {};

I18n.fallbacks = true
//I18n.defaultSeparator = '/'

I18n.translations = {
    en,
    ru
}

I18n.defaultLocale = 'ru'

I18n.t = (string) => {

    return I18n.translations[I18n.defaultLocale][string];
}

export default I18n