// @flow
import { createAction } from 'redux-actions'

export const SHOW_STATION_MODAL_ACTION = "HOME/SEARCH_FORM/STATION_MODAL/SHOW"
export const HIDE_STATION_MODAL_ACTION = "HOME/SEARCH_FORM/STATION_MODAL/HIDE"
export const SEARCH_STATION_REQUEST_ACTION = "HOME/SEARCH_FORM/MODAL/STATION/SEARCH/REQUEST"
export const SEARCH_STATION_RESPONSE_ACTION = "HOME/SEARCH_FORM/MODAL/STATION/SEARCH/RESPONSE"

export const LOCAL_STATION_REQUEST_ACTION = "HOME/SEARCH_FORM/MODAL/STATION/LOCAL/REQUEST"
export const LOCAL_STATION_RESPONSE_ACTION = "HOME/SEARCH_FORM/MODAL/STATION/LOCAL/RESPONSE"

export const LOCAL_SEARCH_STATION_ACTION = "HOME/SEARCH_FORM/MODAL/STATION/SEARCH/LOCAL"

export const showStationModalAction   = createAction(SHOW_STATION_MODAL_ACTION, (show) => show, () => ({ show : true }))
export const hideStationModalAction   = createAction(HIDE_STATION_MODAL_ACTION, (show) => show, () => ({ show : false }))
export const searchStationRequestAction = createAction(SEARCH_STATION_REQUEST_ACTION)
export const searchStationResponseAction   = createAction(SEARCH_STATION_RESPONSE_ACTION)

export const localStationsRequestAction = createAction(LOCAL_STATION_REQUEST_ACTION)
export const localStationsResponseAction   = createAction(LOCAL_STATION_RESPONSE_ACTION)

export const localSearchStationAction   = createAction(LOCAL_SEARCH_STATION_ACTION)
