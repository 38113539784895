import { createAction } from 'redux-actions'

export const LOAD_PEDIA_REGION              = 'PEDIA/REGION/LOAD'
export const load_pedia_region_action       = createAction(LOAD_PEDIA_REGION);

export const SHOW_PEDIA_REGION_TAB          = 'PEDIA/REGION/TAB/SHOW'
export const show_pedia_region_tab_action   = createAction(SHOW_PEDIA_REGION_TAB);

export const HIDE_PRELOADER_ACTION          = 'PEDIA/PRELOADER/HIDE'
export const hidePreloaderAction            = createAction(HIDE_PRELOADER_ACTION, (show) => show, () => ({ show : false }))

export const REGION_URL_STORE_ACTION        = 'PEDIA/REGION/URL/STORE'
export const regionUrlStoreAction           = createAction(REGION_URL_STORE_ACTION)

export const REGION_FETCH_COMPLETE_ACTION   = 'PEDIA/REGION/FETCH/COMPLETE'
export const regionFetchCompleteAction      = createAction(REGION_FETCH_COMPLETE_ACTION)