import React from 'react';
import moment from "moment";
import {PediaPreLoader} from "../../../src/components/shared/preloader";
import  Menu from '../../../src/components/admin/menu';
import HeadMenuPedia from '../../../src/components/shared/menu_pedia';
import i18n from "../../i18n";

const AdminView = ({title, email, reviews, loaded, message, onPublish}) => {

    return (
        <div className="admin profile">
            <div className="pedia">
                <div className="pedia-block">
                    <div className="pedia-header-block">
                        <HeadMenuPedia />
                        <Menu active="reviews"/>
                        <h3>Отзывы</h3>
                    </div>
                </div>

                {message ?
                    <div className="success-message">
                        <p>{message}</p>
                    </div>
                    : null}

                {loaded && reviews ?
                    <div>
                        <div className="table-responsive table-reviews">
                            <table className="table table-hover">
                                <tbody>
                                {
                                    reviews.map(function(r, index){
                                        // console.log('отзыв', index, r);
                                        return (
                                            <tr key={index}>
                                                <td width="60%" className="br">
                                                    <p>Поезд № <strong>{r.train_number}</strong>, {r.car_type}, <strong>{moment(r.travel.date).format('DD.MM.YYYY')}</strong></p>
                                                    <p><strong>{r.from}</strong> -> <strong>{r.to}</strong></p>
                                                    <p className="message">{r.message}</p>
                                                    <p>{r.name}, номер заказа <strong>{r.order_id}</strong></p>
                                                    {r.site ? <div>
                                                        <span className="text-underline">Отзыв о работе сайта:</span>
                                                        <p>{r.site}</p>
                                                    </div> : null}
                                                </td>
                                                <td><div className="rating-result" ><table>
                                                    {r.rating ? <tr>
                                                        <td><strong>{i18n.t('rating')}</strong>(среднее)</td>
                                                        <td><strong>{r.rating}</strong></td>
                                                    </tr> : null}
                                                    {r.clean ? <tr>
                                                        <td>{i18n.t('review_clean')}</td>
                                                        <td>{r.clean}</td>
                                                    </tr> : null}
                                                    {r.comfort ? <tr>
                                                        <td>{i18n.t('review_comfort')}</td>
                                                        <td>{r.comfort}</td>
                                                    </tr> : null}
                                                    {r.service ? <tr>
                                                        <td>{i18n.t('review_service')}</td>
                                                        <td>{r.service}</td>
                                                    </tr> : null}
                                                    {r.price_quality ? <tr>
                                                        <td>{i18n.t('review_price_quality')}</td>
                                                        <td>{r.price_quality}</td>
                                                    </tr> : null}
                                                    {r.general ? <tr>
                                                        <td>{i18n.t('review_general')}</td>
                                                        <td>{r.general}</td>
                                                    </tr> : null}
                                                </table></div></td>
                                                <td className="bottom">
                                                    <p>
                                                        <a href="#" onClick={() => onPublish(r.id, 1)}>Опубликовать</a>
                                                    </p>
                                                    <p>
                                                        <a href="#" onClick={() => onPublish(r.id, 2)}>Удалить</a>
                                                    </p>
                                                    <div className="mt-5">{moment(r.created_at.date).format('DD.MM.YYYY')}</div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                                </tbody>
                            </table>

                        </div>
                    </div> : null}
            </div>

            <PediaPreLoader show={!loaded} />
        </div>
    )};

module.exports = AdminView;