import { connect } from 'react-redux'
import PropTypes from 'prop-types';


import BlogSinglePostContentView from 'vokzal-platform/components/blog/single_post_content/view'

BlogSinglePostContentView.propTypes = {
  id : PropTypes.number.isRequired,
  date : PropTypes.string.isRequired,
  permalink : PropTypes.string.isRequired,
  title : PropTypes.string.isRequired,
  text : PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  ...state.blog_single_post.data
});

const mapDispatchToProps = dispatch => (
  {
    onPostClick: (permalink, event) => {

      // debugger
    },

  }
);


const BlogSinglePostContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogSinglePostContentView);

export default BlogSinglePostContentContainer