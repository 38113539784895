// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { stationBusUrlStoreAction } from '../station_bus/action';

import PediaBusRouteView from 'vokzal-platform/components/pedia/bus_route/view'

import { PediaRouteStationPropType } from '../../../types';

PediaBusRouteView.propTypes = {
  bus : PropTypes.object.isRequired,
  route : PropTypes.arrayOf(PediaRouteStationPropType).isRequired,
  onLinkClick : PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  let coordinates = [];
  const route = state.pedia_bus.route.map((o) => {

    o.point_type = 'intermediate';
    if (o.type === 'departure') {
      o.point_type = 'begin';
    }

    if (o.type === 'arrival') {
      o.point_type = 'end';
    }

    if (o.lat && o.lon && parseInt(o.lat) > 0 && parseInt(o.lon)) {
      coordinates.push([parseFloat(o.lat), parseFloat(o.lon)]);
    }

    return o;
  });

  const bus = state.pedia_bus.bus;
  let zoom = 4;
  const mapState = coordinates.length > 0 ? { center: coordinates[0], zoom: zoom, type: 'yandex#publicMap', controls: ['typeSelector','zoomControl','rulerControl','fullscreenControl'] } : {}
  return { bus, route, coordinates, mapState };
}


const mapDispatchToProps = dispatch => {

    return {
      onLinkClick: (item, event) => {
        dispatch(stationBusUrlStoreAction(item.url));
      }
    }
}


const PediaBusRouteContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaBusRouteView)

export default PediaBusRouteContainer