import React, {Fragment} from 'react';
import PediaFormShortBus from "../../../../src/components/pedia/order_form_short_bus";
import {Map, Placemark, Polyline, YMaps} from "react-yandex-maps";

const PediaBusRouteView = ({ bus, route, onLinkClick, mapState, coordinates }) => (
    <Fragment>
        <div className="pedia-train-route pl-4 pr-4">

          <div className="page-section">
            <h3 className="section-title">Маршрут</h3>
          </div>

          <div className="route-graph">
            {route.map((point, index) => (
              <Fragment><div key={index} className="route-graph-row d-flex no-gutters">
                    <div className="timers col-3 pr-3">
                      {(point.arrival_time) ? <div className="timers-timer timer-arrival rounded small text-center font-weight-medium p-1 mb-2">{point.arrival_time}</div> : null}
                      {(point.departure_time) ? <div className="timers-timer timer-departure rounded small text-center font-weight-medium p-1 mt-2 mb-4">{point.departure_time}</div> : null}
                    </div>
                    <div className="route-graph-point col-1">
                      <div className="route-graph-line float-left h-100" />
                      <div className={"route-graph-point-" + point.point_type}>
                        <div className="pointer"><div className="center" /></div>
                      </div>
                    </div>
                    <div className="description col-8" onClick={() => { onStationClick(point.url) }}>
                      <div className={"description-title small " + (index > 0 ? 'mt-4' : '') }>
                        <a href={point.url}>{point.point_name}</a>
                        {/*<a href={point.url} onClick={(e) => { onLinkClick(point, e) }}>{point.point_name}</a>*/}
                          {/* вернуть ссылку потом */}
                        {/*{point.point_name}*/}
                      </div>
                    </div>
                  </div>
                  {(point.time_in_way) ? <div className="description-waytime small font-light mt-3">Доехать за {point.time_in_way}</div> : null}
              </Fragment>
            ))}
          </div>

          <div className="pt-4">
            <PediaFormShortBus bus={bus} />
          </div>
        </div>



        {coordinates.length > 1 ? <div className="pedia-map">

            <div id="map" />

            <YMaps>
                <Map state={mapState} height={200} width="100%" modules={['geocode']}>

                    <Polyline
                        geometry={{
                            coordinates: coordinates
                        }}
                        properties={{
                            hintContent: `Маршрут автобуса `,
                        }}
                        options={{
                            draggable: false,
                            strokeColor: '#999999',
                            strokeWidth: 3,
                            strokeStyle: 'solid'
                        }}
                    />

                    {
                        coordinates.map((m, index) => {
                            const i = index === 0 ? 0 : route.length - 1;
                            return <Placemark key={index}
                                              geometry={{
                                                  coordinates: m
                                              }}
                                              properties={{
                                                  hintContent: `${route[i].point_name}`,
                                                  balloonContentHeader: `<a href="/stanciya-bus/${route[i].busstop_url_name}/${route[i].bus_stop_id}">${route[i].point_name}</a>`,
                                                  balloonContentBody: `${route[i].type === "departure" ? 'Отправление: ' + route[i].departure_time + '<br/>' : ''}
                                ${route[i].type === "arrival" ? 'Прибытие: ' + route[i].arrival_time + '<br/>' : ''}`,
                                                  balloonContentFooter: route[i].time_in_way ? 'Время в пути ' + route[i].time_in_way : ''
                                              }}
                                              options={{
                                                  preset: route[i].point_type === "begin" ? "islands#redStretchyIcon" : "islands#greenStretchyIcon",
                                              }}
                            />;
                        })
                    }
                </Map>
            </YMaps>
        </div> : null}

    </Fragment>
);

module.exports = PediaBusRouteView;