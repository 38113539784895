import React from 'react';

import i18n from 'vokzal-platform/i18n';

import HeadMenu from '../../../../src/components/shared/menu';

import NewOrderButton from '../../../../src/components/ticket_return/new_order_button';

import FooterInfo from '../../../../src/components/shared/footer_info';

const TicketReturnResultView = ({ orderID }) => (
    <div className="ticket-return gradient-background">
      <HeadMenu />

      <div className="return-ticket-text h1 font-weight-light m-3">
        Заказ №{orderID} возвращён.
      </div>

      <div className="page-section rounded m-2 mt-4 p-2">

        <div className="section-title mb-2">
          Обращаем Ваше внимание:
        </div>

        <p>Возврат денежных средств будет осуществлён на карту, с которой осуществлялся платёж.<br/>Срок возврата зависит от банка, выпустившего карту, и может составлять до 30 дней.</p>

        <NewOrderButton />
      </div>

      <FooterInfo titleText={i18n.t('goodbye')} />
    </div>
);

module.exports = TicketReturnResultView;