import React from 'react';

import i18n from 'vokzal-platform/i18n'

import LastochkaCabin from '../../../../../src/components/step3/lastochka/base/lastochka_cabin';
import LastochkaCabinDelimiter from '../../../../../src/components/step3/lastochka/base/lastochka_cabin_delimiter';
import LastochkaToilet from '../../../../../src/components/step3/lastochka/base/lastochka_toilet';
import LastochkaLeftCabin from '../../../../../src/components/step3/lastochka/base/lastochka_left_cabin';
import LastochkaLuggage from '../../../../../src/components/step3/lastochka/base/lastochka_luggage';
import LastochkaRightCabin from '../../../../../src/components/step3/lastochka/base/lastochka_right_cabin';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlassWhiskey} from "@fortawesome/free-solid-svg-icons";

const Lastochka1PCarView = ({ carNumber, cabins }) => (
  <div className="train-car-select-seats-map rounded pt-2">

    <div className="train-car-select-seats-map-block train-car-map-text lastocha-service-area rounded small d-flex align-items-center rounded m-2">
      <div className="m-auto">{i18n.t('lastochka_service_area')}</div>
    </div>

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[0]} />
    <div className="table table-left"></div>
      <div className="clear"></div>
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[1]} />
    <LastochkaCabinDelimiter />

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[2]} />

      <div className="table table-left"></div>
      <div className="table table-right"></div>

      <div className="clear"></div>

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[3]} />

      <LastochkaCabinDelimiter />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[4]} />
      <LastochkaCabinDelimiter />
      <div className="right mb-3"><LastochkaToilet /></div>
      <hr className="clear"/>
      <div className="right"><LastochkaToilet /></div>


    <LastochkaCabinDelimiter />

      <div className="d-flex justify-content-between">
          <div>
              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[5]} />
              <LastochkaLeftCabin carNumber={carNumber} cabinInfo={cabins[6]} />
          </div>

          <div>
              <div className="table table-right"></div>
              <div className="clear"></div>

              <div className="icon-glass" data-title="Кулер">
                <FontAwesomeIcon icon={faGlassWhiskey} />
              </div>

              <LastochkaLuggage />
          </div>
      </div>

    <LastochkaCabinDelimiter />

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[7]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[8]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[9]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[10]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[11]} />

  </div>
);

module.exports = Lastochka1PCarView;