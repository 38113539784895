import { handleActions } from 'redux-actions'

import { regionInitialStatePlatform } from 'vokzal-platform/components/pedia/region/reducer';

import { SHOW_PEDIA_REGION_TAB, REGION_FETCH_COMPLETE_ACTION, REGION_URL_STORE_ACTION } from "./action";

const defaultState = {
  loaded : false, urlWithParams: null, title: '', country : '', stations : [], groups : [], features : [],
}

const initialState = Object.assign({}, defaultState, regionInitialStatePlatform())

const PediaRegionReducer = handleActions({
    [SHOW_PEDIA_REGION_TAB]: (state, action) => {
      return {...state, currentTab : action.payload}
    },
    [REGION_FETCH_COMPLETE_ACTION]: (state, action) => {
      return {...state, loaded : true, ...action.payload.response}
    },
    [REGION_URL_STORE_ACTION]: (state, action) => {

      let new_state = {}
      if (state.urlWithParams !== action.payload) {
        new_state = defaultState;
      }
      new_state.urlWithParams = action.payload;

      return {...state, ...new_state}
    },
}, initialState)

export default PediaRegionReducer