import { post_process_stations, make_station_groups } from '../../../../src/api/fetch_pedia_stations_list';

export const stationsListInitialStatePlatform = () => {
  let initialState = {}

  if (typeof pedia_stations_list !== 'undefined') {

    const tmp = post_process_stations(pedia_stations_list);
    initialState.station_groups = make_station_groups(tmp);
    initialState.loaded = true;
  }

  return initialState;
}