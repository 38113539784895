import moment from 'moment';

import { vokzal_config } from '../config';

export const fetch_car_category = (searchUrlWithParams, car_category, car_service, onDataLoaded, onError) => {
    const requested_at = moment();

    fetch(vokzal_config.rest.base_url + searchUrlWithParams, vokzal_config.rest.fetchParams)
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.hasOwnProperty('error')) {
            onError(Object.assign({searchUrlWithParams}, responseJson.error));
          } else {
            const result = Object.assign(responseJson, {car_category, car_service})
            onDataLoaded(moment().diff(requested_at), result);
          }
        });
};