import React, { Component } from 'react';

import { connect } from 'react-redux'

import StatsView from 'vokzal-platform/components/admin/stats/view';

import {user_request} from "../../../api/user_request";
import {adminStatsFetchCompleteAction} from "../../admin/action";

class StatsContainer extends Component{
    componentDidMount() {
        console.log('/api/reviews/admin', this.props);
        if (!this.props.loaded) {
            user_request('/api/admin', {}, this.props.onDataLoaded, this.props.onError);
        }
    }

    render() {
        return <StatsView {...this.props} />
    }
}

const mapStateToProps = ({admin}) => {
    const roles = localStorage.getItem('roles');
    const r = roles.split(",");
    return {
        title: 'Статистика',
        admin: r.includes('ROLE_ADMIN'),
        stats: admin.stats,
        loaded: admin.stats_loaded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDataLoaded: (time_spent, response) => {
            // console.log('response***** admin/reviews', response);

            dispatch(adminStatsFetchCompleteAction({ time_spent, response }));
        },
        onError: (error) => {},
    }
}

StatsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StatsContainer);

export default StatsContainer