import React from 'react';
import TrainPriceItem from '../../../../src/components/step2/train_price_item';

const TrainPricesView = ({ priceTypes, priceItems, onCarClassClick, carCategory, service, trainItem }) => (
  <div className="col-md-6 col-lg-5 train-prices justify-content-between no-gutters">
    {priceTypes.map((priceType, index) => (
      <TrainPriceItem
          key={index}
          priceType={priceType}
          priceItems={priceItems}
          carCategory={priceType}
          service={service}
          trainItem={trainItem}
          onCarClassClick={(carCategory, service, urlParams, priceItems, trainItem) => onCarClassClick(carCategory, service, urlParams, priceItems, trainItem)}/>
    ))}
  </div>
)

module.exports = TrainPricesView;