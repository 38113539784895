import React from 'react';
import PropTypes from 'prop-types';

import { SeatNumbersPlatskartPropType } from '../../../../src/types/';

import CabinPlace from '../../../../src/components/step3/cabin_place';
import CabinPlaceHorizontal from '../../../../src/components/step3/cabin_place_horizontal';

const PlatskartCabinView = ({ carNumber, title, cabinInfo }) => (
    <div className="kupe">

        <div className="cabin">

            <div className="cabin-general">
                <CabinPlaceHorizontal carNumber={carNumber} seatNumber={cabinInfo.centerTopLower} type="center-top" isUpper={false} />
                <CabinPlaceHorizontal carNumber={carNumber} seatNumber={cabinInfo.centerTopUpper} type="center-top" isUpper={true} />

                <CabinPlaceHorizontal carNumber={carNumber} seatNumber={cabinInfo.centerBottomUpper} type="center-bottom" isUpper={true} />
                <CabinPlaceHorizontal carNumber={carNumber} seatNumber={cabinInfo.centerBottomLower} type="center-bottom" isUpper={false} />
            </div>

            <div className="cabin-title small">{title}</div>

            <div className="cabin-side">
                <CabinPlaceHorizontal carNumber={carNumber} seatNumber={cabinInfo.sideLower} type="side" isUpper={false} />
                <CabinPlaceHorizontal carNumber={carNumber} seatNumber={cabinInfo.sideUpper} type="side" isUpper={true} />
            </div>


        </div>




        <div className="train-car-map-cabin train-car-select-seats-map-block d-flex justify-content-between align-items-center rounded mb-2 d-block d-lg-none">
            <div className="train-car-map-cabin-place-block train-car-map-cabin-side-place-block">

                <CabinPlace carNumber={carNumber} seatNumber={cabinInfo.sideLower} type="side" isUpper={false} />
                <CabinPlace carNumber={carNumber} seatNumber={cabinInfo.sideUpper} type="side" isUpper={true} />
            </div>

            <div className="train-car-map-cabin-title small">{title}</div>

            <div className="train-car-map-cabin-place-block train-car-map-cabin-center-place-block d-flex flex-column">
                <CabinPlace carNumber={carNumber} seatNumber={cabinInfo.centerTopLower} type="center-top" isUpper={false} />
                <CabinPlace carNumber={carNumber} seatNumber={cabinInfo.centerTopUpper} type="center-top" isUpper={true} />

                <CabinPlace carNumber={carNumber} seatNumber={cabinInfo.centerBottomUpper} type="center-bottom" isUpper={true} />
                <CabinPlace carNumber={carNumber} seatNumber={cabinInfo.centerBottomLower} type="center-bottom" isUpper={false} />
            </div>
        </div>



    </div>

);

PlatskartCabinView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  cabinInfo : SeatNumbersPlatskartPropType,
};

module.exports = PlatskartCabinView;