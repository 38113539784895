// @flow

import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { StationPropType } from '../../../types';

import SwitchStationsView from 'vokzal-platform/components/home/switch_stations/view'
import { setStationFromAction, setStationToAction, setStationFromInfoAction, setStationToInfoAction } from '../search_form/action'

SwitchStationsView.propTypes = {
  from: StationPropType,
  to: StationPropType,
  onSwitchClick: PropTypes.func.isRequired
};
const mapStateToProps = state => {
      return {
          from : state.search.from_info,
          to : state.search.to_info
      }
  };

  const mapDispatchToProps = dispatch => {
      return {
          onSwitchClick: (from, to) => {
              dispatch(setStationFromInfoAction(to));
              dispatch(setStationToInfoAction(from));
          }
      }
  };

const SwitchStationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchStationsView)

export default SwitchStationsContainer