import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Step4View from 'vokzal-platform/components/step4/view'

Step4View.propTypes = {
  errorMessage: PropTypes.string,
}

const mapStateToProps = state => {

  const e = state.step4_passenger_data.error

  // const document_mask = state.step4_passenger_data.document_mask


  return {
    errorMessage: (e) ? e.message : null
    // ,
    // document_mask: document_mask
  }
}

const Step4Container = connect(
  mapStateToProps
)(Step4View)

export default Step4Container