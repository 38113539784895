import React from 'react';

import { Link } from 'react-router-dom';

import i18n from 'vokzal-platform/i18n';

import PageSection from '../base/section/view';
import SectionTitle from '../base/section_title/view';
import StationRow from '../../../../src/components/home/station_row';

const StationsView = ({ items }) => (
    <PageSection className="vokzals-stations">
        <SectionTitle>
          <Link to="/stanciya">{i18n.t('vokzals_stations')}</Link>
        </SectionTitle>

        <div className="d-flex flex-wrap no-gutters">
            {
                items.map(function(m, index){
                    return (<StationRow key={index} stationItem={m} />);
                })
            }
        </div>
    </PageSection>
);

module.exports = StationsView;