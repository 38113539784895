import React, { Component } from 'react';
import { connect } from 'react-redux'

import ProfileView from 'vokzal-platform/components/user/profile/view'
import {user_request} from "../../../api/user_request";
import {userSetValueAction} from "../action";

class ProfileContainer extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      user_request('/api/profile', {}, this.props.onDataLoaded, this.props.onError);
    }
  }

  render() {
    return <ProfileView {...this.props} />;
  }
}

const mapStateToProps = ({user}) => {
  return {
    loaded: user.loaded
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDataLoaded : (time_spent, response, post) => {
      // console.log('Данные пользователя получены ', time_spent);
      // запросить данные здесь в обоих случаях
      dispatch(userSetValueAction({profile: response, loaded: true}))
    },
    onError : (error) => {
      // console.log('Не удалось получить данные пользователя')
    },
  }
}

ProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileContainer)

export default ProfileContainer