import React from 'react';

import i18n from 'vokzal-platform/i18n';

import BusMap from '../../../../src/components/step3bus/bus_map';
import BusSeatsAuto from '../../../../src/components/step3bus/bus_seats_auto';
import SelectedSeats from '../../../../src/components/step3/selected_seats';

const BusSeatsView = ({ auto_select }) => (
  <div className="bus-seats page-section rounded mb-2 mr-2 ml-2 mt-3">
    {
      (auto_select)
        ? <BusSeatsAuto />
        : <div><BusMap /><SelectedSeats /></div>
    }
  </div>
);

module.exports = BusSeatsView;