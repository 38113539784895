import React, { Component } from 'react';

import { connect } from 'react-redux'

import ReviewView from 'vokzal-platform/components/user/review/view'
import {reviewSetValueAction} from "./action";
import {simple_request} from "../../../api/simple_request";
import { parseGET } from "../../../utils/url_params";

class ReviewContainer extends Component{
  componentDidMount() {
    const params = parseGET()
    // if (!this.props.loaded) {
      simple_request('/api/review/create', {
        code: params['code'],
        order_id: params['order_id'],
        check: 1
      }, () => {},
          this.props.onErrorCheck);
    // }
    // this.props.code = code;
    // console.log(code, this.props.code);
  }

  render() {
    return <ReviewView {...this.props} />
  }
}

const mapStateToProps = ({review}) => {
  return {
    title: 'Отзыв',
    review: review,
    // error: user.edit_error,
    // success: user.edit_success,
    // p: {
    //   "status": "OK",
    //   "firstname": "Настя",
    //   "middlename": "fffffff",
    //   "surname": "yyyy",
    //   "phone": "77777",
    //   "birth_date": "12.12.1993",
    //   "email": "ai@vokzal.ru"
    // }
  }
}
//
const mapDispatchToProps = dispatch => {
  return {
    onChanged: (value, old, inputElement) => {
      // console.log(value, 8888)
      let attribute = inputElement.getAttribute("name");
      dispatch(reviewSetValueAction({[attribute]: value}))
    },
    onCheckRating: (value,  name) => {
      // console.log(value, 8888)
      dispatch(reviewSetValueAction({[name]: value}))
    },
    onDataLoaded : (time_spent, response, post) => {
      dispatch(reviewSetValueAction({edit_success: 'Данные успешно сохранены', edit_error: '', check_error: ''}))
      // console.log('Данные пользователя получены ', time_spent);
    },
    onError : (error) => {
      dispatch(reviewSetValueAction({edit_error: 'Произошла ошибка'}))
    },
    onErrorCheck : (error) => {
      dispatch(reviewSetValueAction({check_error: 'Несуществующий код для комментария'}))
    },
    onSubmit: (review, onDataLoaded, onError) => {
      review.code = parseGET()['code'];
      review.order_id = parseGET()['order_id'];
      simple_request('/api/review/create', {...review}, onDataLoaded, onError);
    }
  }
};
//
ReviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewContainer);

export default ReviewContainer