import React from 'react';
import i18n from "../../../i18n";
import { Link } from "react-router-dom";

const StepInfoView = ({ from, to, date, transport, stepNumber, step2_url, step3_url, step4_url, carNumber, seats }) => {

    let dateBlock, dateBlock1 = null;
    if (date.isValid()) {
      dateBlock = (
        <div className="step-info-date d-inline-block">
            &nbsp;::&nbsp; {date.format('D MMMM')}, <span className="text-capitalize">{(date) ? date.format('ddd') : ''}</span>
        </div>
      );
        dateBlock1 = (
            <div className="step-info-date d-inline-block">
                {date.format('D MMMM')}, <span className="text-capitalize">{(date) ? date.format('ddd') : ''}</span>
            </div>
        );
    }

    return (
       <div>
        <div className="step-info small ml-3 mt-2 mb-4 d-block d-md-none">
            <div className="step-info-title d-inline-block">
            {from.city_name} › {to.city_name}
            </div>
            {dateBlock}
        </div>
           <div className="row step-list">
               <div className="col-15">
                   <Link to="/">
                       <div className="step mw-100">
                           <StepNumber>1</StepNumber>
                           <div className="">{from.city_name} › {to.city_name}</div>
                           {dateBlock1}
                       </div>
                   </Link>
               </div>
               <div className="col-15">
                   {step2_url ? <Link to={step2_url}>
                       <div className={"step " + ((stepNumber === 2) ? 'active' : '')}>
                           <StepNumber>2</StepNumber>
                           <div className="text">
                               {transport ? i18n.t(transport.type) : i18n.t('choose_transport')}
                           </div>
                           <div className="text text-current">
                               {transport.type === 'train' ? `${transport.number} ${ transport.name ? `«${transport.name}»` : ''}` : ''}
                           </div>
                       </div>
                   </Link> :  <div className={"step " + ((stepNumber === 2) ? 'active' : '')}>
                       <StepNumber>2</StepNumber>
                       <div className="text">
                           {transport ? i18n.t(transport.type) : i18n.t('choose_transport')}
                       </div>
                       <div className="text text-current">
                           {transport.type === 'train' ? `${transport.number} ${ transport.name ? `«${transport.name}»` : ''}` : ''}
                       </div>
                   </div>}

               </div>
               <div className="col-15">
                   {step3_url ? <Link to={step3_url}>
                       <div className={"step " + ((stepNumber === 3) ? 'active' : '')}>
                           <StepNumber>3</StepNumber>
                           {carNumber ? (<div className="text text-current">{carNumber}</div>) : <div className="text">{i18n.t('pasengers')}</div>}
                           {seats ? (<div className="text text-current">{seats}</div>) : null}
                       </div>
                   </Link> : <div className={"step " + ((stepNumber === 3) ? 'active' : '')}>
                       <StepNumber>3</StepNumber>
                       {carNumber ? (<div className="text text-current">{carNumber}</div>) : <div className="text">{i18n.t('pasengers')}</div>}
                       {seats ? (<div className="text text-current">{seats}</div>) : null}
                   </div>}
               </div>
               <div className="col-15">
                   {step4_url ? <Link to={step4_url}>
                       <div className={"step " + ((stepNumber === 4) ? 'active' : '')}>
                           <StepNumber>4</StepNumber>
                           <div className="text">{i18n.t('registration')}</div>
                       </div>
                   </Link> :  <div className={"step " + ((stepNumber === 4) ? 'active' : '')}>
                       <StepNumber>4</StepNumber>
                       <div className="text">{i18n.t('registration')}</div>
                   </div>}
               </div>
               <div className="col-15">
                   <div className={"step step5 " + ((stepNumber === 5) ? 'active' : '')}>
                       <StepNumber>5</StepNumber>
                       <div className="text">{i18n.t('tickets')}</div>
                   </div>
               </div>
           </div>
      </div>
    );
}

const StepNumber = ({ children }) => (
    <span className="step-number rounded-circle">{children}</span>
);

module.exports = StepInfoView;