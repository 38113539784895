// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { showTrain } from 'vokzal-platform/managers/navigation'

import PediaTrainView from 'vokzal-platform/components/pedia/train/view'
import {fetch_pedia_trains_list} from "../../../api/fetch_pedia_trains_list";
import {trainFetchCompleteAction} from "./action";


PediaTrainView.propTypes = {
  loaded: PropTypes.bool.isRequired,
  urlWithParams: PropTypes.string,


  // onLinkClick : PropTypes.func.isRequired,
};

class PediaTrainContainer extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      const url = window.location.pathname;
      fetch_pedia_trains_list(url, this.props.onDataLoaded);
    }
  }

  componentDidUpdate(prevProps) {
    const url = window.location.pathname;
    if (prevProps.location.pathname !== url) {
      fetch_pedia_trains_list(url, this.props.onDataLoaded);
    }
  }

  render() {
    return <PediaTrainView {...this.props} />;
  }
}


const mapStateToProps = state => {
     return {
       loaded: state.pedia_train.loaded, // раскомментирвать
       // loaded: true,
       // trainNumber: state.pedia_train.train.number, // раскомментирвать
       trainNumber: state.step2_details.train.numberShort ?
           state.step2_details.train.numberShort :
           (state.pedia_train.train && state.pedia_train.train.number ? state.pedia_train.train.number : window.location.pathname.split('/').pop()), // раскомментирвать
       // trainNumber: "007М", //"846М", //"043Л",
       loadedReviews: state.reviews.loaded,
       urlWithParams: state.pedia_train.urlWithParams,
    }
}

const mapDispatchToProps = dispatch => {

    return {
      onDataLoaded : (time_spent, response) => {
        dispatch(trainFetchCompleteAction({ time_spent, response }));
      },
      onLinkClick : (item, event) => {
        // dispatch(stationUrlStoreAction(item.url));
        showTrain(dispatch, item, event)
      }
    }
}


PediaTrainContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PediaTrainContainer)

export default PediaTrainContainer