import { handleActions } from 'redux-actions'

import { Trains } from '../../../../data/home';

import { HOME_SHOW_TRAINS } from './action'

const ContentTreeReducer = handleActions({
    [HOME_SHOW_TRAINS]: (state, action) => {
        return {...state};
    },
}, { items: Trains })

export default ContentTreeReducer