import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import i18n from 'vokzal-platform/i18n';

import LastochkaToiletView from 'vokzal-platform/components/step3/lastochka/base/lastochka_toilet/view'

LastochkaToiletView.propTypes = {
  title: PropTypes.string.isRequired,
  autoHeight: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({ title : i18n.t('toilet') })


const LastochkaToiletContainer = connect(
  mapStateToProps
)(LastochkaToiletView)

export default LastochkaToiletContainer