import React, {Fragment}  from 'react';
import { Close, Remove } from '@material-ui/icons';

const PlusButtonView = ({className, isExpanded}) => {
   className = (className || '') + " material-icons align-middle plus-button" + ((isExpanded) ? ' expanded' : '');
   return (
       <Fragment>
           {(isExpanded) ?
               <Remove className={className}/>
               :
               <Close className={className}/>
           }
       </Fragment>
   );
}

module.exports = PlusButtonView;