import queryString from 'query-string';

export const step2TrainDetailsInitialStatePlatform = () => {
  let initialState = {}

  if (location.pathname.startsWith('/step')) {
    const queryObject = queryString.parse(location.search);
    initialState.train      = { number : queryObject.trainNumber, er : queryObject.er ? queryObject.er : 0, sapsan : 0, numberShort : queryObject.trainNumber ? queryObject.trainNumber.slice(0, 4) : null  };

    initialState.car_category      = queryObject.carCategory ? queryObject.carCategory : null;
    initialState.car_service      = queryObject.service ? queryObject.service : null;

    if(typeof category !== 'undefined' && category) {
      const adult = Math.min(parseInt(category.map(element => element.price.withBedding)));
      initialState.price = {
        adult: adult,
        child: Math.round(adult / 2)
      };
    }

    if (queryObject.busID && typeof tariffs !== 'undefined') {
      initialState.price = {
        adult: tariffs.adult,
        child: tariffs.child
      };
    }

  } else if (location.pathname.startsWith('/confirmation')) {
    if(typeof trainNumber !== 'undefined') {
      initialState.train      = { number : trainNumber, er : typeof er !== 'undefined' ? er : 0, sapsan : 0, numberShort : trainNumber.slice(0, 4)  };
    }

    initialState.car_category      = typeof carCategory !== 'undefined' ? carCategory : null;
    initialState.car_service      = typeof service !== 'undefined' ? service : null;

    if(typeof category !== 'undefined' && category) {
      const adult = Math.min(parseInt(category.map(element => element.price.withBedding)));
      initialState.price = {
        adult: adult,
        child: Math.round(adult / 2)
      };
    }
  }

  return initialState;
}