import { post_process_schedule } from '../../../../src/api/fetch_pedia_station';

export const raspisanieInitialStatePlatform = () => {
  let initialState = {}

  if (typeof pedia_schedule !== 'undefined') {
    const t = post_process_schedule(pedia_schedule.trains);
    initialState.schedule = t.map(o => {
      o.prices = (o.prices) ? o.prices : {};

      return o;
    })
    initialState.title = pedia_schedule.title;
    initialState.subTitle = pedia_schedule.subTitle;
    initialState.image = { caption: 'default' };
    initialState.cityFrom = pedia_schedule.cityFrom;
    initialState.cityTo = pedia_schedule.cityTo;
    initialState.averageDistance = pedia_schedule.averageDistance;
    initialState.averageWaytime = pedia_schedule.averageWaytime;
    initialState.trainsCount = pedia_schedule.trainsCount;
    initialState.citiesPassthru = pedia_schedule.citiesPassthru;
    initialState.tariffs = pedia_schedule.tariffs;
    initialState.loaded = true;
  }

  return initialState;
}