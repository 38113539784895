import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { StationPropType, SearchFormFields } from '../../../../src/types';

import { searchStations } from '../../../../src/api/search_station_api'

import {hideStationModalAction, localSearchStationAction, searchStationRequestAction} from './action'

import { setStationFromAction, setStationToAction, setStationFromInfoAction, setStationToInfoAction } from '../search_form/action';
import {
  showQuickSelectToAction,
  showQuickSelectFromAction,
  hideQuickSelectToAction
} from '../quick_select_stations/action';

import StationModalView from 'vokzal-platform/components/home/station_modal/view'
import {enToRus} from "../../../utils/functions";

StationModalView.propTypes = {
  search_type: PropTypes.oneOf(['local', 'global']).isRequired,
  show: PropTypes.bool.isRequired,
  selected_input: PropTypes.string.isRequired,
  selected_station: StationPropType,
  input_value: PropTypes.string.isRequired,
  stations: PropTypes.arrayOf(
    StationPropType.isRequired
  ).isRequired,
  onItemClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {

  const search_type = (ownProps.hasOwnProperty('search_type')) ? ownProps['search_type'] : 'global';

  return {
    show: state.station_modal.show,
    selected_input: state.station_modal.selected_input,
    selected_station: state.station_modal.selected_station,
    input_value: state.station_modal.input_value,
    stations: state.station_modal.stations,
    search_type,
    timer_id: state.station_modal.timer_id,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCloseClick: () => {
      dispatch(hideStationModalAction())
    },
    onItemClick: (selected_input, station) => {
      dispatch(hideStationModalAction())
      switch (selected_input) {
        case SearchFormFields.FROM:
          dispatch(setStationFromInfoAction(station))
          dispatch(showQuickSelectToAction(station))
          break

        case SearchFormFields.TO:
          dispatch(setStationToInfoAction(station))
          dispatch(showQuickSelectFromAction(station))
          dispatch(hideQuickSelectToAction())
          break

      }
    },
    onInputChange: (input_value, search_type) => {
      if (search_type === 'local') {
        // dispatch(localSearchStationAction(input_value))
      } else {
        // console.log('onInputChange ', input_value);
        // searchStations(dispatch, input_value);
      }
    },
    onKeyUp: (input_value, search_type, timer_id) => {
      clearTimeout(timer_id);
      let searchString = enToRus(input_value);
      let timerId = setTimeout(() => {

        if (search_type === 'local') {
          dispatch(localSearchStationAction(searchString))
        } else {
          searchStations(dispatch, searchString);
        }
      }, 500);

      dispatch(searchStationRequestAction({timer_id: timerId, input_value: searchString}))
    },
    onTouchEnd: (input_value, search_type) => {
      // input_value = enToRus(input_value);

      if (search_type === 'local') {
        dispatch(localSearchStationAction(enToRus(input_value)))
      } else {
        searchStations(dispatch, enToRus(input_value));
      }
    },
    onEnterPress: (selected_input, stations) => {
      const station = stations[0];
      station.key = 0;

      dispatch(hideStationModalAction())

      switch (selected_input) {
        case SearchFormFields.FROM:
          // dispatch(setStationFromAction(station))

          dispatch(setStationFromInfoAction(station))

          dispatch(showQuickSelectToAction(station))
          break;

        case SearchFormFields.TO:
          // dispatch(setStationToAction(station))

          dispatch(setStationToInfoAction(station))

          dispatch(showQuickSelectFromAction(station))

          dispatch(showQuickSelectFromAction(station))
          break;
      }

    },
  }
}

const StationModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StationModalView)

export default StationModalContainer
