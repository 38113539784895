// @flow
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { show_pedia_tab_action } from './action';

import { PediaTabGroupItemPropType, PediaTabsTrain, PediaTabsStation, PediaTabsBus, PediaTabsRailroadDetails, PediaTabsAboutCompany } from '../../../types';

import PediaTabGroupView from 'vokzal-platform/components/pedia/tab_group/view'

PediaTabGroupView.propTypes = {
  type: PropTypes.oneOf(['train', 'station', 'bus', 'railroad_details', 'about_company']).isRequired,
  items: PropTypes.arrayOf(PediaTabGroupItemPropType).isRequired,
  selected: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  let selected = state.pedia_tab_group.currentTab;
  let items = [];
  if (ownProps.hasOwnProperty("type")) {
    switch(ownProps.type) {
      case 'train':
        items = PediaTabsTrain;
        if (!items.some(item => item.value === selected) ) {
          selected = state.pedia_train.currentTab;
        }
        break;
      case 'station': items = PediaTabsStation; break;
      case 'bus':
        items = PediaTabsBus;
        if (!items.some(item => item.value === selected) ) {
          selected = state.pedia_bus.currentTab;
        }
        break;
      case 'railroad_details': items = PediaTabsRailroadDetails; break;
      case 'about_company': items = PediaTabsAboutCompany; break;
    }
  }

  return { items, selected }
}

const mapDispatchToProps = dispatch => {
  return {
    onButtonClick : (item) => {
      dispatch(show_pedia_tab_action(item.value))
    }
  }
};

const PediaTabGroupContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaTabGroupView)

export default PediaTabGroupContainer