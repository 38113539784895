import { post_process_schedule_bus, make_schedule_groups } from '../../../../src/api/fetch_pedia_station_bus';

export const scheduleInitialStatePlatform = () => {
  let initialState = {}

  if (typeof pedia_station_bus_data !== 'undefined') {

    initialState.schedule = post_process_schedule_bus(pedia_station_bus_data.schedule);
    initialState.schedule_groups = make_schedule_groups(pedia_station_bus_data.id, initialState.schedule);
    initialState.loaded = true;
  }

  return initialState;
}