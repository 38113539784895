import React from 'react';
import i18n from 'vokzal-platform/i18n';
import moment from 'moment';

const OrderDetailsTrainView = ({ order_form }) => {

  const seatsString = order_form.packages.map((p) => (p.seats)).reduce((a, b) => ( a + ', ' + b ));
  let price = 0;

  return <div className="p-2">
      <div className="row info mt-0">
          <div className="col-sm-3 title">Номер поезда</div>
          <div className="col-sm-9 head">{order_form.trainNumber}</div>
      </div>
      <div className="row info">
          <div className="col-sm-3 title">Отправление</div>
          <div className="col-sm-9">
              <div className="head">{order_form.from}</div>
              <div className="text">{order_form.departureDate}</div>
          </div>
      </div>
      <div className="row info">
          <div className="col-sm-3 title">Прибытие</div>
          <div className="col-sm-9">
              <div className="head">{order_form.to}</div>
              <div className="text">{order_form.arrivalDate}</div>
          </div>
      </div>
      <div className="row info border-0">
          <div className="col-sm-3 title">{i18n.t('train_car')}</div>
          <div className="col-sm-9"><span className="head">№{order_form.carNumber}</span><span className="text">({order_form.carCategory})</span></div>
      </div>

      <h4>Проверьте зарезервированные места и сведения о пассажирах</h4>
      <div className="row info d-none d-sm-flex">
          <div className="col-sm-3 title">Номер места</div>
          <div className="col-sm-6 title passenger-info">Сведения о пассажире</div>
          <div className="col-sm-3 title tarif">Тариф</div>
      </div>
      {order_form.packages.map((p, index) => {
          price = parseFloat(p.price).toFixed(2);
          return (<div className="row info" key={index}>
              <div className="col-sm-3 seat"><span className="d-inline-block d-sm-none">Место</span> {p.seats}</div>
              <div className="col-sm-6 title doc">
                  <div>{p.fio}</div>
                  <div>{p.document}</div>
                  <div>{i18n.t('birth_date')}: {moment(p.birthdate).format('DD.MM.YYYY')}</div>
              </div>
              <div className="col-sm-3 title tarif"><span className="d-inline-block d-sm-none">Тариф</span> {isNaN(price) ? 0 : price}р.</div>
          </div>);
      })}

      <div className="row info border-0">
          <div className="col-sm-5">Сервисный сбор</div>
          <div className="col-sm-3 offset-sm-4 title tarif">{order_form.commission.toFixed(2) + 'р.'}</div>
      </div>
  </div>
}

module.exports = OrderDetailsTrainView;