// @flow

import { createAction } from 'redux-actions'

export const SEAT_SELECT_ACTION            = 'STEP3/SEAT/SELECT'
export const select_seat                   = createAction(SEAT_SELECT_ACTION);

export const SEAT_DESELECT_ACTION          = 'STEP3/SEAT/DESELECT'
export const deselect_seat                 = createAction(SEAT_DESELECT_ACTION);

export const SEAT_CLEAR_ACTION             = 'STEP3/SEAT/CLEAR'
export const clear_selected_seat           = createAction(SEAT_CLEAR_ACTION);

export const KUPE_SELECT_ACTION             = 'STEP3/KUPE/SELECT'
export const kupe_select_mf                 = createAction(KUPE_SELECT_ACTION);

export const CAR_CATEGORY_SEARCH_ACTION    = 'STEP3/CAR_CATEGORY/SEARCH'
export const category_search_complete      = createAction(CAR_CATEGORY_SEARCH_ACTION);

export const SHOW_PRELOADER_ACTION         = 'STEP3/SEARCH/PRELOADER/SHOW'
export const show_preloader                = createAction(SHOW_PRELOADER_ACTION, (show) => show, () => ({ show : true }))

export const HIDE_PRELOADER_ACTION         = 'STEP3/SEARCH/PRELOADER/HIDE'
export const hide_preloader                = createAction(HIDE_PRELOADER_ACTION, (show) => show, () => ({ show : false }))