export const preProcessPostUrls = posts => (
  posts.map(
    p => {
      p.permalink = p.permalink.replace('https://vokzal.ru', '')
      return p;
    }
  )
)

export const preProcessPagination = pagination => {

  pagination.prev = (pagination.prev) ? pagination.prev.replace('https://vokzal.ru', '') : pagination.prev;
  pagination.next = (pagination.next) ? pagination.next.replace('https://vokzal.ru', '') : pagination.next;

  return pagination;
}