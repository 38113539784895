import React from 'react';

import BootstrapInlineLabeledInput from '../../base/bootstrap_inline_labeled_input';
import BootstrapInlineLabeledInputWithButton from '../../base/bootstrap_inline_labeled_input_with_button';

const DateInputView = ({ id, deletable, is_disabled, show, name, label, value, onDateClick, onClearClick }) => (
  (deletable)
    ? <BootstrapInlineLabeledInputWithButton id={id}
        placeholder="ДД.ММ.ГГГГ" is_disabled={is_disabled}
        show={show} label={label} name={name} value={value}
        onClick={() => onDateClick(name, value)}
        buttonIcon="close" onButtonClick={() => onClearClick(name)} />
    : <BootstrapInlineLabeledInput id={id}
        placeholder="ДД.ММ.ГГГГ" is_disabled={is_disabled}
        show={show} label={label} name={name} value={value}
        onClick={() => onDateClick(name, value)}
        buttonIcon="close" />
);

module.exports = DateInputView;