export const secondsToTime = (secs) => {
  const hours = Math.floor(secs / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;

  let seconds = Math.ceil(divisor_for_seconds);
  seconds = (seconds < 10) ? '0'+seconds : seconds;

  return { "h": hours, "m": minutes, "s": seconds };
}