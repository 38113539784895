import moment from 'moment';

import { vokzal_config } from '../config';

export const fetch_pedia_stations_list = (urlWithParams, onDataLoaded) => {
  const requested_at = moment();

  fetch(vokzal_config.rest.base_url + urlWithParams, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {
      //TODO error handling

      const tmp = post_process_stations(responseJson);
      responseJson = make_station_groups(tmp);

      onDataLoaded(moment().diff(requested_at), responseJson);
    });
};

export const post_process_stations = (schedule) => (
  schedule.map(i => {
    i.isExpanded = false;

    return i;
  })
)

export const make_station_groups = (pre_groups) => (
  pre_groups.map((group) => {
    const subgroups = group.links.reduce((groups, item) => {
      const title = item.title.substr(0, 1);

      groups[title] = groups[title] || { title, links: [] };
      groups[title].links.push(item);

      return groups;
    }, {});

    group.subgroups = Object.values(subgroups);

    delete group.links;
    return group;
  })
)
