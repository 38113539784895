import { format_news } from "../../../../src/api/home";

export const newsInitialStatePlatform = () => {
  let initialState = {}

  if (typeof news !== 'undefined') {
    initialState.items = format_news(news);
    initialState.show = true;
  }

  return initialState;
}