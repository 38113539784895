import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import i18n from 'vokzal-platform/i18n';

import { SeatNumbersSeatedPropType } from '../../../../../types/index';

import SapsanWardrobeCabinWithSeatsView from 'vokzal-platform/components/step3/sapsan/base/sapsan_wardrobe_cabin_with_seats/view'

SapsanWardrobeCabinWithSeatsView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  wardrobeTitle: PropTypes.string.isRequired,
  cabinInfo1 : SeatNumbersSeatedPropType,
  cabinInfo2 : SeatNumbersSeatedPropType,
  cabinInfo3 : SeatNumbersSeatedPropType,
  autoHeightWardrobe: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const autoHeightWardrobe = (ownProps.cabinInfo3 !== undefined && ownProps.cabinInfo3 !== null);
  return { wardrobeTitle : i18n.t('sapsan_wardrobe'), autoHeightWardrobe }
}

const SapsanWardrobeCabinWithSeatsContainer = connect(
  mapStateToProps
)(SapsanWardrobeCabinWithSeatsView)

export default SapsanWardrobeCabinWithSeatsContainer