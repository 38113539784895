import { connect } from 'react-redux'

import OrderChangeView from 'vokzal-platform/components/confirmation/order_change/view'

const mapStateToProps = state => {
  const s = state.search;
  return {
    step2: s.step2,
    step3: s.step3,
    step4: s.step4
  }
}

const OrderChangeContainer = connect(
  mapStateToProps
)(OrderChangeView)

export default OrderChangeContainer