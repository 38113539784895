import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { paymentTypeChangedAction } from './action';

import PaymentTypeOptionView from 'vokzal-platform/components/step4/payment_type_option/view'


import { PaymentTypeOptionPropType } from '../../../types';

PaymentTypeOptionView.propTypes = {
  isActive : PropTypes.bool.isRequired,
  paymentType : PaymentTypeOptionPropType,
  onPaymentTypeClick : PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {

  const isActive = state.step4_documents.payment_type === ownProps.paymentType.value

  return { isActive };
};

const mapDispatchToProps = dispatch => {
  return {
    onPaymentTypeClick: (paymentType) => {
      dispatch(paymentTypeChangedAction(paymentType.value))
    }
  }

};

const PaymentTypeOptionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentTypeOptionView);

export default PaymentTypeOptionContainer