import { handleActions } from 'redux-actions'

import { REGION_MAP_BOUNDS_ACTION } from "./action";

const initialState = { bounds : null }

const PediaRegionMapReducer = handleActions({
    [REGION_MAP_BOUNDS_ACTION]: (state, action) => {
      return {...state, bounds: action.payload}
    },
}, initialState)

export default PediaRegionMapReducer