import React from 'react';

const PediaHeaderImageView = ({ url, caption }) => {
    if (caption !== 'default') {
        return (
            <div className="pedia-header-image">
                <img className="img-fluid" src={url} title={caption} alt={caption} />
            </div>
        )
    } else {
        return null
    }
};

module.exports = PediaHeaderImageView;