// @flow

import { handleActions, combineActions } from 'redux-actions'
import moment from 'moment'

import { processTrainsUiFlags, processBusesUiFlags } from '../../utils/ui';

import { PERFORM_SEARCH } from '../../components/home/search_button/action'

import { step2InitialStatePlatform, step2BusInitialStatePlatform } from 'vokzal-platform/components/step2/reducer';

import { SHOW_PRELOADER_ACTION, HIDE_PRELOADER_ACTION } from './action'

import { STEP2_BUSES_SEGMENTS_COMPLETE_ACTION, STEP2_BUSES_SEARCH_COMPLETE_ACTION, STEP2_BUSES_SEARCH_CLEANUP_ACTION } from './buses_search_result/action'
import {
  STEP2_TRAINS_SEARCH_COMPLETE_ACTION, STEP2_TRAINS_SEARCH_CLEANUP_ACTION, STEP2_TRAINS_SEARCH_ERROR_ACTION,
  STEP2_MAKE_POST_SEARCH_ACTION, STEP2_RESET_POST_SEARCH_ACTION
} from './trains_search_result/action'

let step2InitialState = {
  error: null,
  data_loaded: false,
  post_search: false,
  trains: [],
  trains_ui_flags: []
}
step2InitialState = Object.assign({}, step2InitialState, step2InitialStatePlatform())

let step2BusInitialState = {
  error: null,
  segments: [],
  segments_processed: 0,
  buses: [],
  buses_ui_flags: []
}
step2BusInitialState = Object.assign({}, step2BusInitialState, step2BusInitialStatePlatform())

export const Step2Reducer = handleActions({
  [PERFORM_SEARCH]: (state, action) => {
    return { ...state, searchFormState: action.payload }
  },
  [STEP2_TRAINS_SEARCH_COMPLETE_ACTION]: (state, action) => {
    const trains = action.payload.trains;
    const trains_ui_flags = processTrainsUiFlags(trains);
    return { ...state, trains, trains_ui_flags, data_loaded: action.meta.data_loaded }
  },
  [STEP2_TRAINS_SEARCH_CLEANUP_ACTION]: (state, action) => {
    return { ...state, error: null, trains: [], data_loaded: action.meta.data_loaded }
  },
  [STEP2_TRAINS_SEARCH_ERROR_ACTION]: (state, action) => {
    return { ...state, error: action.payload, data_loaded: true }
  },
  [STEP2_MAKE_POST_SEARCH_ACTION]: (state, action) => {
    return { ...state, post_search: true};
  },
  [STEP2_RESET_POST_SEARCH_ACTION]: (state, action) => {
    return { ...state, post_search: false};
  },
}, step2InitialState)

export const Step2PreloaderReducer = handleActions({
  [combineActions(SHOW_PRELOADER_ACTION, HIDE_PRELOADER_ACTION)]: (state, action) => {
    return { ...state, show: action.meta.show }
  },
}, { show: !step2InitialState.data_loaded })

export const Step2BusReducer = handleActions({
  [STEP2_BUSES_SEGMENTS_COMPLETE_ACTION]: (state, action) => {
    let search_params = action.payload.search_params;
    search_params.departureDate = moment(search_params.departureDate, 'DD.MM.YYYY');

    return { ...state, segments: action.payload.segments, search_params }
  },
  [STEP2_BUSES_SEARCH_COMPLETE_ACTION]: (state, action) => {
    let buses = state.buses.concat(action.payload.buses.data);

    let segments_processed = state.segments_processed + 1;
    if (segments_processed === state.segments.length) {
      //TODO show tab here

      buses.sort(function (a, b) {
        if (a.departure.time > b.departure.time) return 1;
        if (a.departure.time < b.departure.time) return -1;
        return 0;
      });
    }

    const buses_ui_flags = processBusesUiFlags(buses);

    //TODO merge here
    return { ...state, segments_processed, buses, buses_ui_flags }
  },
  [STEP2_BUSES_SEARCH_CLEANUP_ACTION]: (state, action) => {
    return { ...state, segments: [], buses: [] }
  }
}, step2BusInitialState)
