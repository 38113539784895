// import UfsLogoView from 'vokzal-platform/components/shared/ufs_logo/view'
//
// const UfsLogo = UfsLogoView
//
// export default UfsLogo
//
//
//
// // @flow

import UfsLogoContainer from './container'

const UfsLogo = UfsLogoContainer

export default UfsLogo