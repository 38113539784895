// @flow
import PropTypes from 'prop-types';
import { SeatNumbersSeatedPropType } from '../../../../../src/types/';

import Sapsan1GClassMapView from 'vokzal-platform/components/step3/sapsan/sapsan_1g_class_map/view'

Sapsan1GClassMapView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersSeatedPropType).isRequired
};

const Sapsan1GClassMap = Sapsan1GClassMapView

export default Sapsan1GClassMap