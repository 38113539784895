import { handleActions } from 'redux-actions'

import { HeadMenuPediaItems } from '../../../../data/menu_pedia';

import { LOAD_MENU_PEDIA } from './action'

const HeadMenuPediaReducer = handleActions({
    [LOAD_MENU_PEDIA]: (state, action) => {
        return {...state};
    },
}, { items: HeadMenuPediaItems })

export default HeadMenuPediaReducer