
export const salepointsInitialStatePlatform = () => {
  let initialState = {}

  const ints = ['rzdSalePointId', 'salePointId', 'stationId', 'regionId', 'roadId'];
  const bools = ['creditCardSupport', 'eticketSupport'];

  const cashDesksInts = ['cashDeskId', 'salePointId'];
  const cashDesksBools = bools.concat(['returnSupport']);

  if (typeof stationInfo !== 'undefined') {
    ints.map(key => { stationInfo[key] = parseInt(stationInfo[key]) });
    bools.map(key => { stationInfo[key] = stationInfo[key] === '1' });

    initialState.station_info = stationInfo;

    initialState.loaded = true;
  }

  if (typeof salePoints !== 'undefined') {
    salePoints = salePoints.map(p => {
      ints.map(key => { p[key] = parseInt(p[key]) });
      bools.map(key => { p[key] = p[key] === '1' });

      p.cashDesks = p.cashDesks.map(c => {
        cashDesksInts.map(key => { c[key] = parseInt(c[key]) });
        cashDesksBools.map(key => { c[key] = c[key] === '1' });
        return c;
      });

      return p;
    });

    initialState.sale_points = salePoints;

    initialState.loaded = true;
  }

  return initialState;
}