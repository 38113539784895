import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLifeRing} from '@fortawesome/free-regular-svg-icons'

const OrderButtonView = ({ isEnabled, buttonText, urlParams, confirmationUrlParams, onButtonClick, onDataLoaded, onError, isConfirmation }) => {
    return (
        <div className="ordering">

            {isConfirmation ?
                <div className="wait">
                    <span className="icon"><FontAwesomeIcon icon={faLifeRing} /></span>
                    <span className="icon"><FontAwesomeIcon icon={faLifeRing} /></span>
                    <span className="icon-right"><FontAwesomeIcon icon={faLifeRing} /></span>
                    <span className="icon-right"><FontAwesomeIcon icon={faLifeRing} /></span>
                </div> :
                <Fragment>
                    {isEnabled ? null : <p className="all-validation">Заполните все поля</p>}
                    <div className="order-button text-center font-weight-medium text-uppercase rounded">
                        <div className={"order-button-label " + ((!isEnabled) ? 'disabled' : '')} onClick={() => onButtonClick(isEnabled, urlParams, confirmationUrlParams, onDataLoaded, onError)}>
                            {buttonText}
                        </div>
                    </div>
                </Fragment>

            }
        </div>
    );
}

module.exports = OrderButtonView;