import Swiper from 'swiper';

export const quickSelectResultLimiterPlatform = (stations) => {
  return stations;
}

export const quick_select_from_enable_swiper = () => {
  let mySwiper = new Swiper('.quick-select-stations-from.swiper-container', {
    nextButton: '.quick-select-stations-from.swiper-container .swiper-button-next',
    prevButton: '.quick-select-stations-from.swiper-container .swiper-button-prev',
    slidesPerView: 'auto',
    freeMode: true
  })
}

export const quick_select_to_enable_swiper = () => {
  let mySwiper = new Swiper('.quick-select-stations-to.swiper-container', {
    nextButton: '.quick-select-stations-to.swiper-container .swiper-button-next',
    prevButton: '.quick-select-stations-to.swiper-container .swiper-button-prev',
    slidesPerView: 'auto',
    freeMode: true
  })
}