import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import OrderDetailsOnlineView from 'vokzal-platform/components/confirmation/order_details_online/view'

OrderDetailsOnlineView.propTypes = {
  is_bus_tickets: PropTypes.bool.isRequired,
  order_id: PropTypes.number.isRequired,
  order_form: PropTypes.object.isRequired,
  seat_changed: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {

  const is_bus_tickets = (state.confirmation.order_form) ? state.confirmation.order_form.is_bus_tickets : false;

  return {
    is_bus_tickets,
    order_id : state.confirmation.order_id,
    order_form : state.confirmation.order_form,
    seat_changed : state.confirmation.seat_changed,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}


const OrderDetailsOnlineContainter = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetailsOnlineView)

export default OrderDetailsOnlineContainter