// @flow
import { connect } from 'react-redux'

import PropTypes from 'prop-types';
import { SearchResultBusPropType, Step2UiFlagsPropType } from '../../../../src/types';

import { step2SetBusPriceAction } from '../../step2/train_details/action';
import { storeBusDetailsAction } from './action';
import { show_preloader } from '../../step3/action';
import { bus_seats_reset } from '../../step3bus/bus_seats/action';

import { searchFormUrlParamsBusDetails } from '../../../../src/utils/url_params';

import { showStep3Bus } from 'vokzal-platform/managers/navigation'

import BusRowView from 'vokzal-platform/components/step2/bus_row/view'

BusRowView.propTypes = {
  busItem : SearchResultBusPropType.isRequired,
  uiFlags: Step2UiFlagsPropType,
  onBusClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  searchFormState : state.search
})


const mapDispatchToProps = dispatch => {
  return {
    onBusClick: (searchFormState, bus) => {
      dispatch(storeBusDetailsAction(bus));
      dispatch(step2SetBusPriceAction(bus.price));

      dispatch(show_preloader());
      dispatch(bus_seats_reset());

      const step3Url = searchFormUrlParamsBusDetails(searchFormState, bus);
      showStep3Bus(dispatch, step3Url)
    }
  }
}

const BusRowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BusRowView)

export default BusRowContainer