import moment from 'moment'

import {vokzal_config} from '../config'

export const search_trains = (searchUrlWithParams, onDataLoaded, onRaspisanieLoaded, onError) => {

    if (searchUrlWithParams === '') {
        return;
    }

    const requested_at = moment()

    fetch(vokzal_config.rest.base_url + searchUrlWithParams, vokzal_config.rest.fetchParams)
        .then((response) => {
            const offset = vokzal_config.rest.base_url.length;
            if (response.url.substring(offset, offset + 12) === "/raspisanie/") {
                response.json().then((responseJson) => {
                    if (responseJson.hasOwnProperty('error')) {
                        onError(responseJson.error);
                    } else {
                        onRaspisanieLoaded(moment().diff(requested_at), responseJson);
                    }
                });
            } else {
                response.json().then((responseJson) => {
                    if (responseJson.hasOwnProperty('error')) {
                        if (responseJson.hasOwnProperty('other_dates')) {
                            responseJson.error.other_dates = responseJson.other_dates;
                        }
                        onError(responseJson.error);
                    } else {
                        onDataLoaded(moment().diff(requested_at), responseJson);
                    }
                });
            }
        });
}
