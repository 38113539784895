import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

import { SeatNumbersLux, SeatNumbersKupe, SeatNumbersPlatskart, SeatNumbersSeated } from './seat_numbers/usual';
import { SeatNumbersLastochkaHeadCar, SeatNumbersLastochkaTailCar, SeatNumbersLastochkaCenterCar, SeatNumbersLastochkaCenterCar_2_7, SeatNumbersLastochka1RCar, SeatNumbersLastochka2GCar, SeatNumbersLastochka1PCar, SeatNumbersLastprem_w4_v2, SeatNumbersLastprem_w2_v2, SeatNumbersLastprem_w1_v2, SeatNumbersLastprem_w2_v1,
    SeatNumbersLastprem_w1_v1, SeatNumbersLastprem_w5_v1 } from './seat_numbers/lastochka';
import { SeatNumbersStrijLuxCar, SeatNumbersStrijFirstClassCar, SeatNumbersStrijEconomCar,
    SeatNumbersStrizh_1_2k_v1, SeatNumbersStrizh_1_1u_v1, SeatNumbersStrizh_1_1a_v1 } from './seat_numbers/strij';
import {
  SeatNumbersSapsanEconomPlus, SeatNumbersSapsanEconom, SeatNumbersSapsanBuffet,
  SeatNumbersSapsanBusinessClass, SeatNumbersSapsanFirstClass, SeatNumbersSapsan2RClass,
  SeatNumbersSapsan1GClass, SeatNumbersSapsanW10V2Class
} from './seat_numbers/sapsan';
import { Prigor_19_v1, Prigor_20_v1, Prigor_21_v1, Prigor_22_v1, Prigor_23_v1 } from './seat_numbers/prigorod';


import i18n from 'vokzal-platform/i18n'

if (!Object.assign) {
  Object.defineProperty(Object, 'assign', {
    enumerable: false,
    configurable: true,
    writable: true,
    value: function(target, firstSource) {
      'use strict';
      if (target === undefined || target === null) {
        throw new TypeError('Cannot convert first argument to object');
      }

      var to = Object(target);
      for (var i = 1; i < arguments.length; i++) {
        var nextSource = arguments[i];
        if (nextSource === undefined || nextSource === null) {
          continue;
        }

        var keysArray = Object.keys(Object(nextSource));
        for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
          var nextKey = keysArray[nextIndex];
          var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
          if (desc !== undefined && desc.enumerable) {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
      return to;
    }
  });
}

export const HeadMenuPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
});


export const StationPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    station_name: PropTypes.string.isRequired,
    city_name: PropTypes.string.isRequired,
    region: PropTypes.string,
    country: PropTypes.string,
    url: PropTypes.string
});

export const SearchFormPropType = PropTypes.shape({
    from: StationPropType,
    to: StationPropType,
    date_from: momentPropTypes.momentObj,
    // date_to: momentPropTypes.momentObj
});

export const ContentTreePropType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    url: PropTypes.string,
    isExpanded: PropTypes.bool.isRequired
});

export const NewsPropType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
});

export const FooterLinksPropType = PropTypes.shape({
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
});

export const TrainPropType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired
});

export const SearchResultStationPropType = PropTypes.shape({
    station_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
});

export const SearchResultTrainDepartureArrivalPropType = PropTypes.shape({
    objStation: SearchResultStationPropType.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    timeFull: PropTypes.string,
    output: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string.isRequired,
    }).isRequired
});

export const SearchResultBusDepartureArrivalPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    timezone: PropTypes.number,
    date: PropTypes.string,
    date_db: PropTypes.string,
    time: PropTypes.string,
    url_name: PropTypes.string.isRequired,
});

export const SearchResultTrainPricePropType = PropTypes.shape({
    prefix: PropTypes.string,
    price: PropTypes.number.isRequired,
    price_max: PropTypes.number,
    seats: PropTypes.string,
});

export const SearchResultBusSegmentPropType = PropTypes.shape({
    departure_bus_stop_id: PropTypes.number.isRequired,
    arrival_bus_stop_id: PropTypes.number.isRequired,
});

export const SearchResultBusPropType = PropTypes.shape({
    run_number : PropTypes.string.isRequired,
    carrier : PropTypes.string.isRequired,
    time_in_way : PropTypes.string.isRequired,
    transport : PropTypes.string,
    price : PropTypes.number,
    comission : PropTypes.number,
    departure: SearchResultBusDepartureArrivalPropType.isRequired,
    arrival: SearchResultBusDepartureArrivalPropType.isRequired,
});

export const TrainTypesPropType = PropTypes.oneOf(['usual', 'sapsan', 'lastochka', 'allegro', 'strij', 'prigorod']);

export const Step2UiFlagsPropType = PropTypes.shape({
  fastest: PropTypes.bool.isRequired,
  fast: PropTypes.bool.isRequired,
  cheapest: PropTypes.bool.isRequired,
  cheap: PropTypes.bool.isRequired,
  min_seats: PropTypes.bool.isRequired,
});

export const SearchResultTrainPropType = PropTypes.shape({
    from: PropTypes.string.isRequired,
    fromParent: SearchResultStationPropType.isRequired,
    fromStationObj: SearchResultStationPropType.isRequired,
    to: PropTypes.string.isRequired,
    toParent: SearchResultStationPropType.isRequired,
    toStationObj: SearchResultStationPropType.isRequired,
    name: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    numberShort: PropTypes.string.isRequired,
    numberUrl: PropTypes.string.isRequired,
    category: PropTypes.string,
    departure: SearchResultTrainDepartureArrivalPropType.isRequired,
    arrival: SearchResultTrainDepartureArrivalPropType.isRequired,
    er: PropTypes.oneOf(['0', '1']).isRequired,
    sapsan: PropTypes.oneOf([0, 1]),
    // allegro: PropTypes.oneOf([0, 1]).isRequired,
    prices: PropTypes.objectOf(SearchResultTrainPricePropType).isRequired,
    type: TrainTypesPropType,
});

export const ScheduleTrainPropType = PropTypes.shape({
  from: SearchResultStationPropType.isRequired,
  to: SearchResultStationPropType.isRequired,
  name: PropTypes.string,
  number: PropTypes.string.isRequired,
  numberShort: PropTypes.string.isRequired,
  numberUrl: PropTypes.string.isRequired,
  category: PropTypes.string,
  departure: PropTypes.string.isRequired,
  arrival: PropTypes.string.isRequired,
  wait: PropTypes.string,
});

export const ScheduleBusPropType = PropTypes.shape({
  from: SearchResultStationPropType.isRequired,
  to: SearchResultStationPropType.isRequired,
  name: PropTypes.string,
  number: PropTypes.string.isRequired,
  numberShort: PropTypes.string.isRequired,
  numberUrl: PropTypes.string.isRequired,
  category: PropTypes.string,
  departure: PropTypes.object.isRequired,
  arrival: PropTypes.object.isRequired,
  wait: PropTypes.string,
});

export const PopularCityPropType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired
});

export const SearchResultTabPropType = PropTypes.shape({
    code: PropTypes.oneOf(['trains', 'buses']).isRequired,
    title: PropTypes.string.isRequired,
    price: PropTypes.number,
    isActive: PropTypes.bool.isRequired
});

export const TrainCarCategoryItemPropType = PropTypes.shape({
    fakeID: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    seatsCnt: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired
});

export const TabTrainEmpty = { code: 'trains', title: i18n.t('trains'), price: null, isActive: false };
export const TabBusEmpty   = { code: 'buses',  title: i18n.t('buses'),  price: null, isActive: false };

export const StationEmpty = { id: 0, station_name: "", city_name: "", region: "", country: "", url: "/" };

export const SeatEmpty = { carNumber: 0,seatNumber: 0, isUpper : null };

export const SearchFormFields = {
    FROM : "from", TO : "to",
    FROM_DATE : "from_date", TO_DATE : "to_date",
};

export const CarTypes = {
    'М' : i18n.t('car_soft'),
    'Л' : i18n.t('car_lux'),
    'К' : i18n.t('car_kupe'),
    'П' : i18n.t('car_platskart'),
    'С' : i18n.t('car_seat'),
    'О' : i18n.t('car_shared'),

    '1Р' : i18n.t('car_sapsan_meeting_room'),
    '1С' : i18n.t('car_sapsan_business'),
    '1В' : i18n.t('car_sapsan_first'),
    '2В' : i18n.t('car_sapsan_econom_plus'),
    '2С' : i18n.t('car_sapsan_econom'),
    '2Е' : i18n.t('car_sapsan_bistro'),
    '2Р' : i18n.t('car_sapsan_base'),
    '1Ж' : i18n.t('car_sapsan_kupe_sut'),
    '2Ю' : i18n.t('car_sapsan_family'),
};

export const TrainTypesWithCarTypes = {
    'usual' : [
        { carCategory : "О", service : null },
        { carCategory : "С", service : null },
        { carCategory : "П", service : null },
        { carCategory : "К", service : null },
        { carCategory : "Л", service : null },
        { carCategory : "М", service : null },
    ],
    'sapsan' : [

        { carCategory : "С", service : "2С" },
        { carCategory : "С", service : "2В" },
        { carCategory : "С", service : "2Е" },
        { carCategory : "С", service : "1С" },
        { carCategory : "С", service : "1В" },
        { carCategory : "С", service : "1Р" },

    ]
}


export const SeatNumbersByCarSchema = {
    'lastochka': {
        'LASTOCHKA_W1_V1': SeatNumbersLastochkaHeadCar,
        'LASTOCHKA_W2_V1': SeatNumbersLastochkaCenterCar,
        'LASTOCHKA_W3_V1': SeatNumbersLastochkaHeadCar,
        'LASTOCHKA_W4_V1': SeatNumbersLastochkaHeadCar,
        'LASTOCHKA_W5_V1': SeatNumbersLastochkaHeadCar,
        'LASTPREM_W1_V1': SeatNumbersLastprem_w1_v1,
        'LASTPREM_W2_V1': SeatNumbersLastprem_w2_v1,
        'LASTPREM_W3_V1': SeatNumbersLastprem_w2_v1,
        'LASTPREM_W4_V1': SeatNumbersLastprem_w2_v1,
        'LASTPREM_W5_V1': SeatNumbersLastprem_w5_v1,
        'LASTPREM_W1_V2': SeatNumbersLastprem_w1_v2,
        'LASTPREM_W2_V2': SeatNumbersLastprem_w2_v2,
        'LASTPREM_W3_V2': SeatNumbersLastochka2GCar,
        'LASTPREM_W4_V2': SeatNumbersLastprem_w4_v2,
        'LASTPREM_W5_V2': SeatNumbersLastochka1PCar,
    },
    'prigorod': {
        'PRIGOR_19_V1': Prigor_19_v1,
        'PRIGOR_20_V1': Prigor_20_v1,
        'PRIGOR_21_V1': Prigor_21_v1,
        'PRIGOR_22_V1': Prigor_22_v1,
        'PRIGOR_23_V1': Prigor_23_v1,
    },
    'strij': {
        'STRIZH_1_2K_V1': SeatNumbersStrizh_1_2k_v1,
        'STRIZH_1_1U_V1': SeatNumbersStrizh_1_1u_v1,
        'STRIZH_1_1I_V1': SeatNumbersStrizh_1_1a_v1,
        'STRIZH_1_1A_V1': SeatNumbersStrizh_1_1a_v1,
    }
};

export const SeatNumbersByCarTypes = {
    'usual' : {
      'Л': SeatNumbersLux,
      'К': SeatNumbersKupe,
      'П': SeatNumbersPlatskart,
      'С': SeatNumbersSeated,
    },

    'sapsan' : {
      '2В': SeatNumbersSapsanEconomPlus,
      '2С': SeatNumbersSapsanEconom,
      '2Е': SeatNumbersSapsanBuffet,
      '1С': SeatNumbersSapsanBusinessClass,
      '1В': SeatNumbersSapsanFirstClass,
      '1Р' : SeatNumbersSapsanFirstClass,

      '2Р': SeatNumbersSapsan2RClass,
      '1Ж': SeatNumbersSapsan1GClass,
      '2Ю': SeatNumbersSapsanW10V2Class,
      0: SeatNumbersSapsanEconom,
    },

    'lastochka' : {
      '2С' : {
        '1' : SeatNumbersLastochkaHeadCar,
        '2' : SeatNumbersLastochkaCenterCar_2_7,
        '3' : SeatNumbersLastochkaCenterCar,
        '4' : SeatNumbersLastochkaCenterCar,
        '5' : SeatNumbersLastprem_w5_v1,
        '6' : SeatNumbersLastochkaHeadCar,
        '7' : SeatNumbersLastochkaCenterCar_2_7,
        '8' : SeatNumbersLastochkaCenterCar,
        '9' : SeatNumbersLastochkaCenterCar,
        '10' : SeatNumbersLastochkaHeadCar,
      },
      '1С' : {
        '1' : SeatNumbersLastprem_w1_v1,
        0: SeatNumbersLastprem_w1_v1,
      },
      '2В' : {
        '3' : SeatNumbersLastprem_w2_v1,
        '10' : SeatNumbersLastochkaCenterCar,
         0: SeatNumbersLastochkaCenterCar,
      },
      '2Е' : {
        '2' : SeatNumbersLastprem_w2_v1,
        '4' : SeatNumbersLastochkaCenterCar,
        '7' : SeatNumbersLastochkaCenterCar,
        '8' : SeatNumbersLastochkaCenterCar,
        '9' : SeatNumbersLastochkaCenterCar,
         0: SeatNumbersLastochkaCenterCar,
      },
      '1Р' : {
        '5' : SeatNumbersLastochka1RCar,
        '10' : SeatNumbersLastochka1RCar,
         0: SeatNumbersLastochka1RCar,
      },
      '2Ж' : {
        '2': SeatNumbersLastprem_w2_v1,
        '3': SeatNumbersLastprem_w2_v1,
        '4': SeatNumbersLastprem_w2_v2,
        '5': SeatNumbersLastprem_w5_v1,
         0: SeatNumbersLastochka2GCar
      },
      '1П' : {
         0: SeatNumbersLastochka1PCar
      },
      '2М' : {
         0: SeatNumbersLastprem_w1_v1
      },
      '2П' : {
         '1': SeatNumbersLastochka1PCar,
         '2': SeatNumbersLastprem_w4_v2,
         '3': SeatNumbersLastochka2GCar,
         0: SeatNumbersLastochka1PCar
      },
       0: SeatNumbersLastochkaCenterCar
    },

    'strij' : {
      '1Е' : SeatNumbersStrijLuxCar,
      '1Э' : SeatNumbersStrijLuxCar,
      '1Т' : SeatNumbersStrijLuxCar,
      '1Р' : SeatNumbersStrijFirstClassCar,
      '2В' : SeatNumbersStrijEconomCar,
      '2С' : SeatNumbersStrijEconomCar,
      '2Ж' : SeatNumbersStrijEconomCar,
      '2Р' : SeatNumbersStrijEconomCar,
      '2А' : SeatNumbersStrizh_1_2k_v1,
      0: SeatNumbersStrijEconomCar,
    }
};

export const RatingWeights = {
    'Firm' : 1, 'DepartureTime' : 1, 'ArrivalTime' : 1, 'pop' : 1, 'FromIsStart': 1, 'ToIsFinish' : 1, 'ER' : 1,  'bestWayTime' : 1
};

export const SeatNumbersPlatskartPropType = PropTypes.shape({
    sideUpper : PropTypes.number.isRequired,
    sideLower : PropTypes.number.isRequired,
    centerTopUpper : PropTypes.number.isRequired,
    centerTopLower : PropTypes.number.isRequired,
    centerBottomUpper : PropTypes.number.isRequired,
    centerBottomLower : PropTypes.number.isRequired
});

export const SeatNumbersKupePropType = PropTypes.shape({
    centerTopUpper : PropTypes.number.isRequired,
    centerTopLower : PropTypes.number.isRequired,
    centerBottomUpper : PropTypes.number.isRequired,
    centerBottomLower : PropTypes.number.isRequired
});

export const SeatNumbersSvPropType = PropTypes.shape({
    centerTopLower : PropTypes.number.isRequired,
    centerBottomLower : PropTypes.number.isRequired
});

export const SeatNumbersStrijLuxPropType = PropTypes.shape({
  centerTopLower : PropTypes.number.isRequired,
  centerBottomLower : PropTypes.number.isRequired,
  inverted : PropTypes.bool.isRequired,
});

export const SeatNumbersSeatedPlacePropType = PropTypes.shape({
  number : PropTypes.number.isRequired,
  isFront : PropTypes.bool.isRequired
});

export const SeatNumbersSeatedPropType = PropTypes.shape({
    leftWindow  : SeatNumbersSeatedPlacePropType,
    leftSide    : SeatNumbersSeatedPlacePropType,
    rightSide   : SeatNumbersSeatedPlacePropType,
    rightWindow : SeatNumbersSeatedPlacePropType,
});

export const SeatNumbersLastochkaPropType = PropTypes.shape({
    leftWindow  : SeatNumbersLastochkaPlacePropType,
    leftCenter  : SeatNumbersLastochkaPlacePropType,
    leftSide    : SeatNumbersLastochkaPlacePropType,
    rightSide   : SeatNumbersLastochkaPlacePropType,
    rightCenter : SeatNumbersLastochkaPlacePropType,
    rightWindow : SeatNumbersLastochkaPlacePropType,
});

export const SeatSelectedPropType = PropTypes.shape({
    carNumber: PropTypes.number,
    seatNumber: PropTypes.number.isRequired,
    isUpper: PropTypes.bool,
});

export const SeatNumbersLastochkaPlacePropType = PropTypes.shape({
  carNumber: PropTypes.number,
  seatNumber: PropTypes.number.isRequired,
  isUpper: PropTypes.bool,
  type: PropTypes.oneOf(['std', 'side']).isRequired,
});

export const FormSelectPropType = PropTypes.shape({
    title : PropTypes.string.isRequired,
    value : PropTypes.string.isRequired
});

export const UserSelectedSeatPropType = PropTypes.shape({
  type : PropTypes.oneOf(['adult', 'child', 'infant']),
  selectedSeat : SeatSelectedPropType
});

const PersonEmpty = { fio : '', birth_date : '',
    // birth_city : '',
    gender : '', document_number : '', document_country : 'RUS', document_country_title : '', counters : {}, validators : {}, errors : {}, focus: '' }
export const AdultEmpty  = Object.assign({}, PersonEmpty, { type : 'adult', document_type : 'ПН' });
export const ChildEmpty  = Object.assign({}, PersonEmpty, { type : 'child', document_type : 'СР' });
export const InfantEmpty = Object.assign({}, PersonEmpty, { type : 'infant', document_type : 'СР'});

export const PersonPropType = PropTypes.shape({
  type : PropTypes.oneOf(['adult', 'child', 'infant']).isRequired,
  fio : PropTypes.string.isRequired,
  birth_date : PropTypes.string.isRequired,
  // birth_city : PropTypes.string.isRequired,
  gender : PropTypes.oneOf(['', 'M', 'F']).isRequired,
  document_number : PropTypes.string.isRequired,
  document_type : PropTypes.string.isRequired,
  document_country : PropTypes.string,
  document_country_title : PropTypes.string
});

export const DocumentTypePropType = PropTypes.shape({
  title : PropTypes.string.isRequired,
  value : PropTypes.string.isRequired
});

export const PaymentTypeOptionPropType = PropTypes.shape({
  title : PropTypes.string.isRequired,
  value : PropTypes.string.isRequired
});

export const CountryPropType = PropTypes.shape({
  title : PropTypes.string.isRequired,
  value : PropTypes.string.isRequired
});

export const TrainTypesWithCarTypesPropType = PropTypes.shape({
  carCategory : PropTypes.string.isRequired,
  service : PropTypes.string
});

export const PediaTabGroupItemPropType = PropTypes.shape({
  title : PropTypes.string.isRequired,
  value : PropTypes.string.isRequired,
});

export const PediaMapCoordsPropType = PropTypes.shape({
  lat : PropTypes.number.isRequired,
  lon : PropTypes.number.isRequired,
});

export const PediaStationItemPropType = PropTypes.shape({
  title : PropTypes.string.isRequired,
  url   : PropTypes.string.isRequired,
});

export const PediaStationsGroupItemPropType = PropTypes.shape({
  title  : PropTypes.string.isRequired,
  links  : PropTypes.arrayOf(PediaStationItemPropType).isRequired,
});

export const PediaStationsGroupWithSubgroupItemPropType = PropTypes.shape({
  title  : PropTypes.string.isRequired,
  subgroups : PropTypes.arrayOf(PediaStationsGroupItemPropType).isRequired,
});

export const PediaRegionStationPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  coords: PediaMapCoordsPropType,
});

export const PediaRouteStationPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  point_type: PropTypes.oneOf(['begin', 'intermediate', 'end']).isRequired,
  name: PropTypes.string.isRequired,
  distance: PropTypes.number.isRequired,
  city: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  departure: PropTypes.string.isRequired,
  arrival: PropTypes.string.isRequired,
  wait: PropTypes.string,
  wayTime: PropTypes.string,
});

export const PediaSalePointsStationInfoPropType = PropTypes.shape({
  address: PropTypes.string,
  cityId: PropTypes.string,
  cityName: PropTypes.string,
  comment: PropTypes.string,
  creditCardSupport: PropTypes.bool.isRequired,
  eticketSupport: PropTypes.bool.isRequired,
  howToReach: PropTypes.string,
  originalName: PropTypes.string,
  phone: PropTypes.string,
  regionId: PropTypes.number,
  regionName: PropTypes.string,
  roadId: PropTypes.number,
  roadName: PropTypes.string,
  rzdSalePointId: PropTypes.number,
  salePointId: PropTypes.number,
  stationFullName: PropTypes.string,
  stationId: PropTypes.number,
  workSinceLocal: PropTypes.string,
  workSinceMsk: PropTypes.string,
  workTillLocal: PropTypes.string,
  workTillMsk: PropTypes.string,
});

export const PediaSalePointCashDeskPropType = PropTypes.shape({
  cashDeskId: PropTypes.number,
  salePointId: PropTypes.number,
  creditCardSupport: PropTypes.bool.isRequired,
  eticketSupport: PropTypes.bool.isRequired,
  returnSupport: PropTypes.bool.isRequired,
  comment: PropTypes.string,
  number: PropTypes.string,
  workTime: PropTypes.string,
});

export const PediaSalePointPropType = PropTypes.shape({
  cashDesks: PropTypes.arrayOf(PediaSalePointCashDeskPropType),
  address: PropTypes.string,
  cityId: PropTypes.string,
  cityName: PropTypes.string,
  comment: PropTypes.string,
  creditCardSupport: PropTypes.bool.isRequired,
  eticketSupport: PropTypes.bool.isRequired,
  howToReach: PropTypes.string,
  originalName: PropTypes.string,
  phone: PropTypes.string,
  regionId: PropTypes.number,
  regionName: PropTypes.string,
  roadId: PropTypes.number,
  roadName: PropTypes.string,
  rzdSalePointId: PropTypes.number,
  salePointId: PropTypes.number,
  stationFullName: PropTypes.string,
  stationId: PropTypes.number,
  workSinceLocal: PropTypes.string,
  workSinceMsk: PropTypes.string,
  workTillLocal: PropTypes.string,
  workTillMsk: PropTypes.string
});

export const PediaTabsStation = [
  {title: "Описание", value: "info"},
  {title: "Расписание", value: "schedule"},
];

export const PediaTabsBus = [
  {title: "Описание", value: "info"},
  {title: "Маршрут", value: "route"},
  // {title: "График", value: "schedule"},
];

export const PediaTabsRailroadDetails = [
  {title: "Описание", value: "info"},
  {title: "Карта", value: "map"},
];

export const PediaTabsTrain = [
  {title: "Описание", value: "info"},
  {title: "Маршрут", value: "route"},
  {title: "Карта", value: "map"},
  {title: "Отзывы", value: "reviews"},
  // {title: "График", value: "schedule"},
];

export const PediaTabsAboutCompany = [
  {title: "Контакты",     value: "info"},
  {title: "О сервисе",    value: "service"},
  {title: "Пресса о нас", value: "press"},
];

export const PediaDefaultImage = { caption : "default", url : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAABlBMVEX///////9VfPVsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAACklEQVQImWNgAAAAAgAB9HFkpgAAAABJRU5ErkJggg==" }

export const BlogPostListItemPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  permalink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
});

export const HelpPostListItemPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});