// @flow
import PropTypes from 'prop-types';
import { SeatNumbersSeatedPropType } from '../../../../../src/types/';

import StrijEconomMapView from 'vokzal-platform/components/step3/strij/strij_econom_map/view'

StrijEconomMapView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersSeatedPropType).isRequired
};

const StrijEconomMap = StrijEconomMapView

export default StrijEconomMap