import React from 'react';
import { Link } from 'react-router-dom';

import SectionRow from '../base/section_row/view';

const StationRowView = ({ stationItem, onLinkClick }) => (
    <SectionRow className="station-row col-6">
        <Link to={stationItem.url} onClick={(e) => { onLinkClick(stationItem, e) }}>{stationItem.station_name}</Link>
    </SectionRow>
);

module.exports = StationRowView;