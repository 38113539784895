// @flow

import {
  Step2PreLoaderContainer, Step2AlternativePreLoaderContainer, Step3PreLoaderContainer,
  ConfirmationPreLoaderContainer, PediaPreLoaderContainer,
  TicketReturnPreLoaderContainer, BlogPreLoaderContainer
} from './container'

export const Step2PreLoader = Step2PreLoaderContainer
export const Step2AlternativePreLoader = Step2AlternativePreLoaderContainer
export const Step3PreLoader = Step3PreLoaderContainer
export const ConfirmationPreLoader = ConfirmationPreLoaderContainer
export const PediaPreLoader = PediaPreLoaderContainer
export const BlogPreLoader = BlogPreLoaderContainer