import React from 'react';
import moment from 'moment';

import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';
import { PediaPreLoader } from '../../../../src/components/shared/preloader';
import  Menu from '../../../../src/components/user/menu';
import ReactPaginate from 'react-paginate';

const OrdersView = ({title, loaded, orders, onDeleteOrder, current_page, count, limit, onPaginationClick, onDataLoaded, onError}) => (
    <div className="profile">
        <div className="pedia">
            <div className="pedia-block">
                <div className="pedia-header-block">
                    <HeadMenuPedia />

                    <Menu active="orders"/>
                    <h3>Ваши поездки</h3>
                </div>
            </div>

            {loaded && orders ?
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Тип</th>
                            <th scope="col">Маршрут</th>
                            <th scope="col">Дата</th>
                            <th scope="col">Цена</th>
                            <th scope="col">Статус</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            orders.map(function(o, index){
                                // console.log('order ', o);
                                if (o.station_to) {
                                    return (
                                        <tr key={index}>
                                            <td>{o.transport} {o.train}</td>
                                            <td><span>{o.station_from.name} {(o.station_from.city && o.station_from.city !== o.station_from.name) ? '(' + o.station_from.city + ')' : null}
                                                <span className="text-nowrap">&nbsp;->&nbsp;</span>
                                                {o.station_to.name} {(o.station_to.city && o.station_to.city !== o.station_to.name) ? '(' + o.station_to.city + ')' : null}</span></td>
                                            <td>{/*{moment('10.12', 'DD.MM').locale('ru').format('LL')}*/}
                                                <span className="text-nowrap">{o.departure_date} {o.departure_time}</span>
                                                <div>Прибытие</div>
                                                <span className="text-nowrap">{o.arrival_date} {o.arrival_time}</span></td>
                                            <td className="text-nowrap">{o.amount ? o.amount + ' р.' : ''}</td>
                                            <td>{o.status.text}</td>
                                            <td>
                                                <p><a href={'orders/' + o.id}>Подробнее</a></p>
                                                <p><a href="#" onClick={() => onDeleteOrder(o.id)}>Удалить</a></p>
                                            </td>
                                        </tr>
                                    );
                                }

                            })
                        }
                        </tbody>
                    </table>

                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={count}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={(page) => onPaginationClick(page.selected, limit, onDataLoaded, onError)}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        previousClassName={'page'}
                        nextClassName={'page'}
                        disabledClassName={'disabled'}
                        pageClassName={'page'}
                        initialPage={current_page}
                    />
                </div> : null}
            {loaded && !orders ?<h5>Заказов пока нет</h5> : null}
        </div>

        <PediaPreLoader show={!loaded} />
    </div>
);

module.exports = OrdersView;