import React from 'react';

import i18n from 'vokzal-platform/i18n';

const NewOrderButtonView = ({ onButtonClick }) => (
  <div className="check-button text-center font-weight-medium text-uppercase rounded mt-3 p-3">
    <div className="check-button-label" onClick={() => onButtonClick()}>
      {i18n.t('new_order')}
    </div>
  </div>
);

module.exports = NewOrderButtonView;