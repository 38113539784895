// @flow
import PropTypes from 'prop-types';
import { SeatNumbersSeatedPropType } from '../../../../../types/index';

import SapsanAnimalsCabinView from 'vokzal-platform/components/step3/sapsan/base/sapsan_animals_cabin/view'

SapsanAnimalsCabinView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  cabinInfo : SeatNumbersSeatedPropType,
};

const SapsanAnimalsCabin = SapsanAnimalsCabinView

export default SapsanAnimalsCabin