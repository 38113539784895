// @flow
import PropTypes from 'prop-types';
import { SeatNumbersSeatedPropType } from '../../../../../types/index';

import SapsanChildrenCabinView from 'vokzal-platform/components/step3/sapsan/base/sapsan_children_cabin/view'

SapsanChildrenCabinView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  cabinInfo1 : SeatNumbersSeatedPropType,
  cabinInfo2 : SeatNumbersSeatedPropType,
  cabinInfo3 : SeatNumbersSeatedPropType,
};

const SapsanChildrenCabin = SapsanChildrenCabinView

export default SapsanChildrenCabin