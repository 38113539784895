import moment from 'moment'

import {vokzal_config} from '../config'

//TODO combine actions for web and mobile
import {
  searchStationRequestAction,
  searchStationResponseAction
} from '../components/home/station_modal/action'

import { setStationFromAction, setStationFromInfoAction } from '../components/home/search_form/action';
import { showQuickSelectToAction, hideQuickSelectFromAction } from '../components/home/quick_select_stations/action';

import { geoUrlWithParams } from '../utils/url_params';

export const searchStations = function (dispatch, searchString) {

  const requested_at = moment()

  fetch(vokzal_config.rest.base_url + "/index/suggest?q=" + searchString, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson.map(function (m, index) {
        return {id: parseInt(m.station_id), station_name: m.name, description: m.geo, url: "/"}
      })
    })
    .then((responseJson) => {
      return responseJson.map(function (m, index) {
        let a = m.description.split(",")
        let o = {}
        if (a.length === 1) {
          o = {city_name: '', region: '', country: a[0]}
        } else if (a.length === 2) {
          o = {city_name: a[0], region: '', country: a[1]}
        } else if (a.length === 3) {
          o = {city_name: a[0], region: a[1], country: a[2]}
        }

        return {...m, ...o}
      })
    })
    .then((stations) => {

      //TODO error handling
      dispatch(searchStationResponseAction({input_value: searchString, requested_at, stations}))
    })
}

export const searchStationsByLocation = function (dispatch, geo) {
  const requested_at = moment()

  const urlParams = geoUrlWithParams(geo);

  fetch(vokzal_config.rest.base_url + urlParams, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((station) => {

      if (station) {
        dispatch(setStationFromAction(station))
        dispatch(setStationFromInfoAction(station))
        dispatch(hideQuickSelectFromAction())
        dispatch(showQuickSelectToAction(station))
      }
    })
}