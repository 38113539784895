import React from 'react';

import HeadMenu from '../../../src/components/shared/menu';
import { Step3PreLoader } from '../../../src/components/shared/preloader';

import StepInfo from '../../../src/components/shared/step_info';

import { PassengersCountBus } from '../../../src/components/step3/passengers_count';
import BusSeats from '../../../src/components/step3bus/bus_seats';
import OrderButton from '../../../src/components/step3/order_button';

const Step3BusView = () => (
  <div className="step3 gradient-background">
    <HeadMenu stepNumber={3} />

    <StepInfo  stepNumber={3}/>

    <PassengersCountBus />

    <Step3PreLoader />
    <BusSeats />

    <OrderButton />
  </div>
);

module.exports = Step3BusView;