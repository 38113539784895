import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { showHome } from 'vokzal-platform/managers/navigation'


import NewOrderButtonView from 'vokzal-platform/components/ticket_return/new_order_button/view'

NewOrderButtonView.propTypes = {
  onButtonClick : PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return { };
};

const mapDispatchToProps = dispatch => {
  return {
    onButtonClick : () => {
      showHome(dispatch)
    },
  }
};

const NewOrderButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewOrderButtonView);

export default NewOrderButtonContainer