// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import PediaRailroadView from 'vokzal-platform/components/pedia/railroad/view'
import {fetch_pedia_railroad} from "../../../api/fetch_pedia_railroad";
import {hidePreloaderAction, railroadFetchCompleteAction} from "./action";
import { railroadDetailsUrlStoreAction } from '../railroad_details/action';

import { PediaStationsGroupItemPropType } from '../../../types';

PediaRailroadView.propTypes = {
  loaded: PropTypes.bool.isRequired,
  urlWithParams: PropTypes.string,
  groups : PropTypes.arrayOf(PediaStationsGroupItemPropType).isRequired,
  onLinkClick : PropTypes.func.isRequired,
};

class PediaRailroadContainer extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      fetch_pedia_railroad(this.props.urlWithParams, this.props.onDataLoaded);
    }
  }

  render() {
    return <PediaRailroadView {...this.props} />;
  }
}


const mapStateToProps = state => {

     return {
       loaded: state.pedia_railroad.loaded,
       urlWithParams: state.pedia_railroad.urlWithParams,
       groups: state.pedia_railroad.groups,
    }
}

const mapDispatchToProps = dispatch => {

    return {
      onDataLoaded : (time_spent, response) => {
        dispatch(hidePreloaderAction());
        dispatch(railroadFetchCompleteAction({ time_spent, response }));
      },
      onLinkClick : (item, event) => {
        dispatch(railroadDetailsUrlStoreAction(item.url));
      }
    }
}


PediaRailroadContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PediaRailroadContainer)

export default PediaRailroadContainer