import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import KupeMapView from 'vokzal-platform/components/step3/kupe_map/view'
import { SeatNumbersKupePropType } from '../../../../src/types/';

KupeMapView.propTypes = {
    carNumber : PropTypes.number.isRequired,
    cabins : PropTypes.arrayOf(SeatNumbersKupePropType).isRequired
};

const mapStateToProps = (state, ownProps) => {

    const carSelected = state.step3_categories.car_selected;
    const seatsSelected = state.step3_passengers.selected_seats;

    let kupeNumber; // номер купе для данного места
    let selectedFloor = ''; // выбранный этаж
    if (seatsSelected.length > 0) {
        selectedFloor = seatsSelected[0].seatNumber > 80 ? 1 : 0;
        const seat = seatsSelected[0].seatNumber > 80 ? seatsSelected[0].seatNumber - 80 : seatsSelected[0].seatNumber;
        ownProps.cabins.forEach(function(item, i) {
            for (let key in item) {
                // тело цикла выполняется для каждого свойства объекта
                if (item[key] === seat) {
                    kupeNumber = i;
                    break;
                }
            }
            return;
        });
    }
    const kupeMF = state.step3_passengers.select_mf;

    return { carSelected, kupeNumber, kupeMF, selectedFloor }
};

const KupeMapContainer = connect(
    mapStateToProps
)(KupeMapView);

export default KupeMapContainer