// @flow

import { createAction } from 'redux-actions'

export const TOGGLE_CONTENT_TREE_ACTION     = 'HOME/CONTENT_TREE/TOOGLE'
export const toggleContentTreeAction     = createAction(TOGGLE_CONTENT_TREE_ACTION)

export const SHOW_POPULAR_CITIES_ACTION     = 'HOME/POPULAR_CITIES/SHOW'
export const showPopularCitiesAction     = createAction(SHOW_POPULAR_CITIES_ACTION)

export const SHOW_TRAINS_ACTION            = 'HOME/TRAINS/SHOW'
export const showTrainsAction            = createAction(SHOW_TRAINS_ACTION)

export const SHOW_STATIONS_ACTION          = 'HOME/STATIONS/SHOW'
export const showStationsAction          = createAction(SHOW_STATIONS_ACTION)

export const SHOW_NEWS_ACTION              = 'HOME/NEWS/SHOW'
export const showNewsAction              = createAction(SHOW_NEWS_ACTION)

export const SHOW_FOOTER_LINKS_ACTION       = 'HOME/FOOTER_LINKS/SHOW'
export const showFooterLinksAction       = createAction(SHOW_FOOTER_LINKS_ACTION)

