import React, {Component, Fragment} from 'react';

import HeadMenu from '../../../src/components/shared/menu';
import StepInfo from '../../../src/components/shared/step_info';

import ErrorMessage from '../../../src/components/shared/error_message';

import RequirementsText from '../../../src/components/step4/requirements_text';
import UserList from '../../../src/components/step4/user_list';
import ContactDetails from '../../../src/components/step4/contact_details';
import PaymentType from '../../../src/components/step4/payment_type';
import CountriesModal from '../../../src/components/step4/countries_modal';
import UfsLogo from '../../../src/components/shared/ufs_logo';

class Step4 extends Component {
    render() {
        const { errorMessage } = this.props;
        return (
            <Fragment>
                <div className="step4 gradient-background">
                    <HeadMenu stepNumber={4} />
                    <StepInfo stepNumber={4}/>

                    <div className="background-general">
                        <RequirementsText />
                        <UserList />
                        <ContactDetails />
                        {(errorMessage) ? <ErrorMessage message={errorMessage} canGoBack={false} page="confirmation"/> : null}
                        <PaymentType />
                        <CountriesModal />
                        <UfsLogo className="md-text-black pt-4"/>
                    </div>

                </div>
            </Fragment>
        );
    }
}

module.exports = Step4;