import React from 'react';

import Smart from 'smart-plurals';

import SapsanType from '../../../../src/components/step2/sapsan_type';
import { minPrice } from "../../../../src/api/price"
import TrainPriceItem from '../../../../src/components/step2/train_price_item';

const SapsanPricesView = ({ priceItems, onCarClassClick, carCategory, service, trainItem }) => {

  const priceItem = minPrice(priceItems);
  let seats = Object.keys(priceItems).map((key) => {
    return (priceItems[key].hasOwnProperty("seats")) ? parseInt(priceItems[key].seats.split(' ')[0]) : 0;
  }).reduce((a, b) => (a + b));

  if (seats > 0 ) {
    const russian = Smart.Plurals.getRule('ru');
    seats += ' ' + russian(seats, ['место', 'места', 'мест']);
  } else {
    seats = null;
  }

    return (
        <div className="col-md-6 col-lg-5 train-prices justify-content-between no-gutters">
            <div className="d-none d-md-block">
                {['2С', '2В', '2Е', '1С', '1В', '1Р', '2Р', '1Ж', '2Ю'].map((priceType, index) => (
                    <TrainPriceItem
                        key={index}
                        priceType={priceType}
                        priceItems={priceItems}
                        carCategory={carCategory}
                        service={priceType}
                        trainItem={trainItem}
                        onCarClassClick={(carCategory, service, urlParams, priceItems, trainItem) => onCarClassClick(carCategory, service, urlParams, priceItems, trainItem)}
                    />
                ))}
            </div>

            <div className="train-price-sapsan d-md-none">
                <div className="train-price-item small col align-self-center">
                    <div className="font-light">{seats}</div>
                    <div className="font-weight-medium">от {priceItem.price} &#8381;</div>
                </div>
                <div className="train-price-item small col">
                    <SapsanType priceType="2С" priceItems={priceItems}/>
                    <SapsanType priceType="2В" priceItems={priceItems}/>
                    <SapsanType priceType="2Е" priceItems={priceItems}/>
                </div>
                <div className="train-price-item small col">
                    <SapsanType priceType="1С" priceItems={priceItems}/>
                    <SapsanType priceType="1В" priceItems={priceItems}/>
                    <SapsanType priceType="1Р" priceItems={priceItems}/>
                </div>
            </div>
        </div>
    );
};

module.exports = SapsanPricesView;