import React from 'react';
import { connect } from 'react-redux'

import TrainSeatClassInfoView from 'vokzal-platform/components/step3/train_seat_class_info/view';

const mapStateToProps = state => ({
  description : state.step3_categories.car_category_selected.sign.name,
});

const TrainSeatClassInfoContainer = connect(
  mapStateToProps,
)(TrainSeatClassInfoView);

export default TrainSeatClassInfoContainer