import {handleActions} from 'redux-actions'

import {
    SHOW_PERSON_MODAL_ACTION,
    HIDE_PERSON_MODAL_ACTION
} from './action'

const PersonModalReducer = handleActions({
    [SHOW_PERSON_MODAL_ACTION]: (state, action) => {
        return {...state, show: action.meta.show}
    },
    [HIDE_PERSON_MODAL_ACTION]: (state, action) => {
        return {...state, show: action.meta.show}
    },
}, {show: false})

export default PersonModalReducer