// @flow
import { push } from 'react-router-redux'
import queryString from 'query-string';
import moment from 'moment';

import { searchFormUrlParamsTrain } from '../../src/utils/url_params';

export const showMenuLink = (menuItem) => {
  window.location.href = menuItem.url;
}

export const showContentLink = (dispatch, contentTreeItem) => {
  if (contentTreeItem.redirect) {
    window.location.href = contentTreeItem.url;
  } else {
    dispatch(push(contentTreeItem.url));
  }
}

export const extractParentHelpLink = (permalink) => {
  const parent = permalink.substring(6, permalink.lastIndexOf("/"));
  return  (parent === '/') ? "" : parent;
}

export const showFooterLink = (footerLinkItem) => {
  window.location.href = footerLinkItem.url;
}

export const showRaspisanie = (dispatch, raspisanieUrl) => {

  const u = new URL(raspisanieUrl);

  dispatch(push(u.pathname));
}

export const returnToStep2 = (dispatch, step3Url) => {

  if (step3Url) {
    const queryObject = queryString.parse(step3Url.split('?')[1]);

    let searchFormState = {}
    if (queryObject.departureDate) {
      searchFormState.date_from = moment(queryObject.departureDate, 'DD.MM.YYYY');
      searchFormState.date_from_info = moment(queryObject.departureDate, 'DD.MM.YYYY');
    }

    if (queryObject.fromCode) {
      searchFormState.from = { id : queryObject.fromCode };
      searchFormState.from_info = { id : queryObject.fromCode };
    }

    if (queryObject.toCode) {
      searchFormState.to = { id : queryObject.toCode };
      searchFormState.to_info = { id : queryObject.toCode };
    }

    const step2Url = searchFormUrlParamsTrain(searchFormState);
    dispatch(push(step2Url))
  }
}

export const showStep2Plain = (dispatch, searchUrlWithParams) => {

  if (searchUrlWithParams) {
    dispatch(push(searchUrlWithParams));
  }
}

export const showStep2Alternative = (dispatch, searchUrlWithParams) => {

  if (searchUrlWithParams) {
    dispatch(push(searchUrlWithParams));
  }
}

export const showStep2Tab = (dispatch, code) => {
  dispatch(push(location.pathname + location.search + '#' + code))
}

export const showStep3 = (dispatch, searchUrlWithParams) => {

  if (searchUrlWithParams) {
    dispatch(push(searchUrlWithParams));
  }
}

export const showStep4 = (dispatch, searchUrlWithParams) => {

  if (searchUrlWithParams) {
    dispatch(push(searchUrlWithParams));
  }
}

export const showConfirmation = (dispatch, searchUrlWithParams) => {
  if (searchUrlWithParams) {
    dispatch(push(searchUrlWithParams));
  }
}

export const showStep3Bus = (dispatch, step3Url) => {
  dispatch(push(step3Url))
}

export const paymentStep = (dispatch, urlParams) => {
  dispatch(push(urlParams))
  window.location.reload()
}

export const webAndroidTextMaskFix = (inputElement, value) => {
//отсюда https://github.com/text-mask/text-mask/blob/master/core/src/createTextMaskInputElement.js#L177

  if (!inputElement || !value) {
    return;
  }

  const isAndroid = typeof navigator !== 'undefined' && /Android/i.test(navigator.userAgent)
  const defer = typeof requestAnimationFrame !== 'undefined' ? requestAnimationFrame : setTimeout

  if (isAndroid) {
    defer(() => {
      inputElement.value = value;
      inputElement.setSelectionRange(value.length, value.length, 'none');
    }, 0)
  } else {
    inputElement.value = value;
    inputElement.setSelectionRange(value.length, value.length, 'none');
  }
}

export const showTicketReturnConfirmation = (dispatch, urlParams) => {
  dispatch(push(urlParams))
}

export const showTicketReturnResult = (dispatch, urlParams) => {
  dispatch(push(urlParams))
}

export const showTrain = (dispatch, urlParams) => {
  dispatch(push(urlParams))
}

export const showHome = (dispatch) => {
  dispatch(push('/'))
}

export const callSupport = (dispatch, phone) => {
  window.location.href = 'tel:'+phone;
}