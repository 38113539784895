// @flow

import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import TicketReturnInfoView from 'vokzal-platform/components/ticket_return/return_info/view'

import i18n from 'vokzal-platform/i18n'

TicketReturnInfoView.propTypes = {
  ticketsReturnText: PropTypes.string.isRequired
};


const mapStateToProps = state => {
    return {
      ticketsReturnText: i18n.t('tickets_return'),
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}


const TicketReturnInfoContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TicketReturnInfoView)

export default TicketReturnInfoContainer