// @flow

import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { webAndroidTextMaskFix } from 'vokzal-platform/managers/navigation'
import TicketReturnDetailsView from 'vokzal-platform/components/ticket_return/return_details/view'

import i18n from 'vokzal-platform/i18n'

TicketReturnDetailsView.propTypes = {
  orderID: PropTypes.number.isRequired,
  orderNumber: PropTypes.string.isRequired,
  orderAmount: PropTypes.number.isRequired,
  rzdFine: PropTypes.number.isRequired,
  vokzalComission: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  errorMessage: PropTypes.string
};

const mapStateToProps = state => {
    const orderNumber = state.ticket_return.order_number;
    const errorMessage = (state.ticket_return.fetch_error) ? state.ticket_return.fetch_error.message : null;
    return { ...state.ticket_return.details, orderNumber, errorMessage }
};

const TicketReturnDetailsContainer = connect(
    mapStateToProps
)(TicketReturnDetailsView)

export default TicketReturnDetailsContainer