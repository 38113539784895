import { format_footer_links } from "../../../../src/api/home";

export const footerLinksInitialStatePlatform = () => {
  let initialState = {}

  if (typeof popular_routes !== 'undefined') {
    initialState.items = format_footer_links(popular_routes);
  }

  return initialState;
}