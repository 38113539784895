import React from 'react';

import { StationFrom, StationTo } from '../../../../src/components/home/station_input'
import { FromDate } from '../../../../src/components/home/date_input'

const PediaOrderFormView = ({ orderButtonTitle, trainNumber, searchUrlWithParams, searchForm, onButtonClick, onSelectDateClick }) => (
  <div className="pedia-schedule-order-form mb-2">
    <div className="pedia-schedule-order-inputs mb-3">
        <FromDate />

        <StationFrom />
        <StationTo />
    </div>

    <button type="button"
            className={"btn btn-success btn-block text-uppercase " + ((searchUrlWithParams) ? '' : 'disabled')}
            onClick={() => onButtonClick(trainNumber, searchUrlWithParams, searchForm)}
    >{orderButtonTitle}</button>
  </div>
);

module.exports = PediaOrderFormView;