export const trainInitialStatePlatform = () => {
  let initialState = {}

  if (typeof pedia_train !== 'undefined') {

    initialState.loaded = true;

    initialState.title = pedia_train.train.title;
    initialState.subTitle = pedia_train.train.ab;

    initialState.route = pedia_train.route;
    initialState.train = pedia_train.train;
    initialState.coords = pedia_train.coords;
    initialState.trainOthers = pedia_train.trains;
    initialState.schedule = pedia_train.schedule;
  }

  return initialState;
}