// @flow
import PropTypes from 'prop-types';
import { SearchResultTrainPricePropType } from '../../../../src/types/';

import SapsanTypeView from 'vokzal-platform/components/step2/sapsan_type/view'

SapsanTypeView.propTypes = {
  priceType : PropTypes.string.isRequired,
  priceItems: PropTypes.objectOf(SearchResultTrainPricePropType).isRequired
};


const SapsanType = SapsanTypeView

export default SapsanType

