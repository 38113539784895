import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import { ScheduleTrainPropType } from '../../../types';

import { showTrain } from 'vokzal-platform/managers/navigation'

import { trainUrlStoreAction } from '../../pedia/train/action';

import PediaScheduleItemView from 'vokzal-platform/components/pedia/schedule_item/view'

PediaScheduleItemView.propTypes = {
  group_name: PropTypes.string.isRequired,
  train: ScheduleTrainPropType.isRequired,
  // showWaitTime: PropTypes.bool.isRequired,
  onTrainClick: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({  })

const mapDispatchToProps = dispatch => {
  return {
    onTrainClick : (train, event) => {
      dispatch(trainUrlStoreAction(train.numberUrl));
      showTrain(dispatch, train.numberUrl)
    }
  }
}


const PediaScheduleItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaScheduleItemView)

export default PediaScheduleItemContainer