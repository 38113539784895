import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { postStoreUrlAction } from '../single_post/action';

import BlogPostView from 'vokzal-platform/components/blog/post/view'

BlogPostView.propTypes = {
  id : PropTypes.number.isRequired,
  date : PropTypes.string.isRequired,
  permalink : PropTypes.string.isRequired,
  title : PropTypes.string.isRequired,
  text : PropTypes.string.isRequired,
  onPostClick : PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  text: (ownProps.text.length > 20 ) ? ownProps.text.substr(0, 100) + '...' : ownProps.text
});

const mapDispatchToProps = dispatch => (
  {
    onPostClick: (permalink, event) => {

      dispatch(postStoreUrlAction(permalink));

      },

  }
);


const BlogPostContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogPostView);

export default BlogPostContainer