// @flow
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import i18n from 'vokzal-platform/i18n'

import StationInputView from 'vokzal-platform/components/home/station_input/view'

import { SearchFormFields, StationPropType } from '../../../types'

import { showStationModalAction } from '../station_modal/action'


StationInputView.propTypes = {
  id: PropTypes.string.isRequired,
  is_disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  station: StationPropType,
  onStationClick: PropTypes.func.isRequired
};

const mapStateToPropsFrom = state => {
  const station = state.search.from_info;
  return {
    id : "station-select-from",
    is_disabled : state.search.inputs_disabled,
    name: SearchFormFields.FROM,
    label: i18n.t('station_from'),
    station: station
  }
};
const mapStateToPropsTo = state => {
    const station = state.search.to_info;
  return {
    id : "station-select-to",
    is_disabled : state.search.inputs_disabled,
    name: SearchFormFields.TO,
    label: i18n.t('station_to'),
    station: station
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onStationClick: (selected_input, station) => {
      dispatch(showStationModalAction({ selected_input, station }))
    }
  }
};

export const FromStationContainer = connect(
  mapStateToPropsFrom,
  mapDispatchToProps
)(StationInputView)

export const ToStationContainer = connect(
  mapStateToPropsTo,
  mapDispatchToProps
)(StationInputView)
