import React from 'react';
import moment from "moment";
import {PediaPreLoader} from "../../../../src/components/shared/preloader";
import  Menu from '../../../../src/components/admin/menu';
import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';
import i18n from 'vokzal-platform/i18n'

const StatsView = ({title, stats, loaded}) => {

    return (
        <div className="admin profile">
            <div className="pedia">
                <div className="pedia-block">
                    <div className="pedia-header-block">
                        <HeadMenuPedia />
                        <Menu active="stats"/>
                        <h3>Статистика</h3>
                    </div>
                </div>

                         {loaded && stats ?
                    <div>
                        <div className="table-responsive table-stats">
                            <table className="table table-hover">
                                <tbody>

                                            <tr key="1">
                                                <td>Зарегистрированных пользователей</td>
                                                <td>{stats.users}</td>
                                            </tr>
                                            <tr key="2">
                                                <td>Отзывов</td>
                                                <td>{stats.reviews}</td>
                                            </tr>


                                </tbody>
                            </table>

                        </div>
                    </div> : null}
            </div>

            <PediaPreLoader show={!loaded} />
        </div>
    )};

module.exports = StatsView;