import React from 'react';
import { connect } from 'react-redux'

import { select_car_number } from '../train_seats/action';
import { clear_selected_seat } from '../action';

import TrainCarNumberView from 'vokzal-platform/components/step3/train_car_number/view'

const mapStateToProps = state => ( { } );

const mapDispatchToProps = dispatch => {
  return {
    onCarNumberClick : (isClickable, carNumber) => {
      if (isClickable) {
        dispatch(select_car_number(carNumber));
        dispatch(clear_selected_seat());
      }
    }
  }
};

const TrainCarNumberContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainCarNumberView);

export default TrainCarNumberContainer