import React from 'react';
import { Link } from 'react-router-dom';

const HelpPostView = ({ title, href, items, onPostClick }) => (
  <div className="help-post mb-2">
    <div className="post-title mb-1">
      <Link className="font-weight-bold" to={href} onClick={(e) => { onPostClick(href, title, e) }}>{title}</Link>
    </div>
  </div>
);

module.exports = HelpPostView;