// @flow

import { handleActions } from 'redux-actions'

import { TEST_WD } from '../../components/test_wd/action'

const TestWdReducer = handleActions({
  [TEST_WD]: (state, action) => {
    let log_rows = state.log_rows;
    log_rows.push(action.payload);
    return { ...state, log_rows, value: action.payload }
  },
}, { value: '', log_rows: [] })

export default TestWdReducer
