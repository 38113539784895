import {handleActions} from 'redux-actions'

import countries from '../../../api/countries';

import {
  SHOW_COUNTRIES_MODAL_ACTION,
  HIDE_COUNTRIES_MODAL_ACTION,
  SEARCH_COUNTRY_RESPONSE_ACTION, SEARCH_COUNTRY_REQUEST_ACTION
} from './action'

const all_countries = countries;

const CountriesModalReducer = handleActions({
  [SHOW_COUNTRIES_MODAL_ACTION]: (state, action) => {
    return {...state, ...action.payload, show: action.meta.show}
  },
  [HIDE_COUNTRIES_MODAL_ACTION]: (state, action) => {
    return {...state, show: action.meta.show}
  },
  [SEARCH_COUNTRY_RESPONSE_ACTION]: (state, action) => {
    return {...state, countries: action.payload.counties}
  },
  [SEARCH_COUNTRY_REQUEST_ACTION]: (state, action) => {
    return {...state, input_value: action.payload.input_value, selected_date: action.payload.requested_at}
  },
}, {show: false, selected_date: null, ticket_number: null, countries : all_countries, input_value: ''})

export default CountriesModalReducer