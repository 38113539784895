// @flow
import PropTypes from 'prop-types'

import PlusButtonView from 'vokzal-platform/components/shared/plus_button/view'

PlusButtonView.propTypes = {
  className: PropTypes.string,
  isExpanded: PropTypes.bool
};

const PlusButton = PlusButtonView

export default PlusButton