import { createAction } from 'redux-actions'

export const LOAD_PEDIA_STATIONS             = 'PEDIA/STATIONS/LOAD'
export const load_pedia_stations_action      = createAction(LOAD_PEDIA_STATIONS);

export const HIDE_PRELOADER_ACTION           = 'PEDIA/STATIONS/PRELOADER/HIDE'
export const hidePreloaderAction             = createAction(HIDE_PRELOADER_ACTION, (show) => show, () => ({ show : false }))

export const STATIONS_URL_STORE_ACTION       = 'PEDIA/STATIONS/URL/STORE'
export const stationsUrlStoreAction          = createAction(STATIONS_URL_STORE_ACTION)

export const STATIONS_FETCH_COMPLETE_ACTION  = 'PEDIA/STATIONS/FETCH/COMPLETE'
export const stationFetchCompleteAction      = createAction(STATIONS_FETCH_COMPLETE_ACTION)

export const TOGGLE_STATIONS_GROUP_BLOCK     = 'PEDIA/STATIONS/GROUP/TOOGLE'
export const togglStationsGroupBlockAction   = createAction(TOGGLE_STATIONS_GROUP_BLOCK);