import React from 'react';
import ContentTreeRow from "../../../../src/components/shared/content_tree_row";

import DatesModal from '../../../../src/components/home/dates_modal'

import PediaScheduleItemBus from "../../../../src/components/pedia/schedule_item_bus";

const PediaScheduleBusView = ({ title, orderButtonTitle, items, searchUrlWithParams, onExpandClick, onDateSelected }) => (
  <div className="pedia-schedule-block content-tree mt-1 pt-2 pb-2">
    <div className="pedia-schedule-title h4 font-weight-bold text-uppercase m-3">{title}</div>
    {
      items.map((group, index) => (
        <ContentTreeRow key={index} title={group.name} isExpanded={group.isExpanded} onExpandClick={() => onExpandClick(group.name)}>
          {
            group.items.map((i, index2) => (
              <PediaScheduleItemBus key={index2} group_name={group.name} bus={i} showOrderForm={i.showOrderForm} reverseDepartureArrival={true} showTime={true}/>
            ))
          }
        </ContentTreeRow>
      ))
    }

    <DatesModal onDateSelected={(date) => onDateSelected(searchUrlWithParams, date)} />
  </div>
);

module.exports = PediaScheduleBusView;