import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import i18n from 'vokzal-platform/i18n'

import { showContentLink } from 'vokzal-platform/managers/navigation'

import { searchFormUrlParamsStep2Bus } from '../../../utils/url_params';

import { ScheduleBusPropType } from '../../../types';
import { togglScheduleBusBlockAction, fillScheduleGroupBusAction } from '../../pedia/schedule_bus/action'
import { step2TrainsSearchCleanupAction } from '../../step2/trains_search_result/action'
import { step2BusesSearchCleanupAction } from '../../step2/buses_search_result/action'


import { showStep2Plain } from 'vokzal-platform/managers/navigation'

import PediaScheduleBusView from 'vokzal-platform/components/pedia/schedule_bus/view'

PediaScheduleBusView.propTypes = {
  title : PropTypes.string.isRequired,
  items: PropTypes.arrayOf(ScheduleBusPropType).isRequired,
  searchUrlWithParams : PropTypes.string,
  onExpandClick : PropTypes.func.isRequired,
  onDateSelected : PropTypes.func.isRequired
};

const mapStateToProps = state => {

  const items = Object.keys(state.pedia_schedule_bus.schedule_groups).map(key => (state.pedia_schedule_bus.schedule_groups[key]));
  const title = i18n.t('schedule_bus_title') + ' ' + state.pedia_station_bus.title;

  const searchUrlWithParams = searchFormUrlParamsStep2Bus(state.search)

  return { title, items, searchUrlWithParams }
}

const mapDispatchToProps = dispatch => {
  return {
    onExpandClick: (item) => {
      dispatch(fillScheduleGroupBusAction(item));
      dispatch(togglScheduleBusBlockAction(item));
    },
    onDateSelected: (searchUrlWithParams) => {
      if (searchUrlWithParams) {
        dispatch(step2TrainsSearchCleanupAction());
        dispatch(step2BusesSearchCleanupAction());
        showStep2Plain(dispatch, searchUrlWithParams);
      }
    }
  }
}


const PediaScheduleBusContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaScheduleBusView)

export default PediaScheduleBusContainer