import React, {lazy, Suspense} from 'react';

import { StationFrom, StationTo } from '../../../../src/components/home/station_input'
import { FromDate } from '../../../../src/components/home/date_input'

import { QuickSelectionFrom, QuickSelectionTo } from '../../../../src/components/home/quick_select_stations'

import SearchButton from '../../../../src/components/home/search_button'

const SwitchStations = lazy(() => import('../../../../src/components/home/switch_stations'));
const StationModal = lazy(() => import('../../../../src/components/home/station_modal'));
const DatesModal = lazy(() => import('../../../../src/components/home/dates_modal'));


const SearchFormView = ({ title }) => (
  <div className="p-3">
      <h1 className="h4 search-title">{title}</h1>

    <form className="home-search-form">
<div className="row">
    <div className="col-md-8">
        <div className="stations">

            <Suspense fallback={<div>Загрузка...</div>}>
                <SwitchStations />
            </Suspense>

            <div className="row">
                <div className="col-sm-6">
                    <StationFrom />
                    <div className="d-block d-sm-none">
                        <QuickSelectionFrom />
                    </div>
                </div>
                <div className="col-sm-6">
                    <StationTo />
                </div>
            </div>
            <QuickSelectionTo />
        </div>
    </div>
    <div className="col-md-4">
        <div className="dates-select form-inline">
            <FromDate deletable={false} />
        </div>
    </div>
</div>

        <div className="row">
            <div className="col-md-4 offset-md-4 col-sm-6 offset-sm-3 ">
                <SearchButton />
            </div>
        </div>

        <Suspense fallback={<div>Загрузка...</div>}>
          <StationModal />
        </Suspense>

        <Suspense fallback={<div>Загрузка...</div>}>
           <DatesModal />
        </Suspense>

    </form>
  </div>
);

module.exports = SearchFormView;