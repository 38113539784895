import Cookies from 'universal-cookie';

export const mainInitialStatePlatform = () => {
  let initialState = { application_type: 'web' };

  const cookies = new Cookies();
  if (cookies.get('application_type')) {
    initialState.application_type = (cookies.get('application_type') === 'mobile') ? 'mobile' : 'web';
  }

  return initialState;
}