import React from 'react';

const CountriesModalRowView = ({title, onClick}) => (
    <div className="station-details-item list-group-item d-flex flex-column p-0 pt-3" onClick={onClick}>
      <div className="d-flex justify-content-between">
        <h4 className="mb-1 text-truncate">{title}</h4>
        <h5 className="country mb-1 ml-3">{}</h5>
      </div>

      <div className="d-flex justify-content-between">
        <h6 className="station m-0 text-truncate small">{}</h6>
        <h6 className="region m-0 ml-3 text-truncate font-light small">{}</h6>
      </div>
    </div>
)

module.exports = CountriesModalRowView;