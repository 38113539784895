const stdSeat = (number,  isFront) => (
  { number,  isFront, type : 'std'}
)

const sideSeat = (number,  isFront, isDisabled) => (
  { number,  isFront, type : (isDisabled) ? 'side-disabled-person' : 'side'}
)

export const Prigor_19_v1 = [
  { leftWindow : stdSeat(1, true),  leftCenter : stdSeat(2, true), leftSide : stdSeat(2, true), rightSide : null,               rightCenter : null, rightWindow : stdSeat(4, true)  },

  { leftWindow : stdSeat(5, true),  leftCenter : stdSeat(6, true), leftSide : stdSeat(7, true), rightSide : null,               rightCenter : stdSeat(8, true), rightWindow : stdSeat(9, true)  },

  { leftWindow : stdSeat(10, true),  leftCenter : stdSeat(11, true), leftSide : stdSeat(12, true), rightSide : null,               rightCenter : stdSeat(13, true), rightWindow : stdSeat(14, true)  },

  { leftWindow : stdSeat(15, true),  leftCenter : stdSeat(16, true), leftSide : stdSeat(17, true), rightSide : null,               rightCenter : stdSeat(18, true), rightWindow : stdSeat(19, true)  },

  { leftWindow : stdSeat(20, true),  leftCenter : stdSeat(21, true), leftSide : stdSeat(22, true), rightSide : null,               rightCenter : stdSeat(23, true), rightWindow : stdSeat(24, true)  },

  { leftWindow : stdSeat(25, true),  leftCenter : stdSeat(26, true), leftSide : stdSeat(27, true), rightSide : null,               rightCenter : stdSeat(28, true), rightWindow : stdSeat(29, true)  },

  { leftWindow : stdSeat(30, true),  leftCenter : stdSeat(31, true), leftSide : stdSeat(32, true), rightSide : null,               rightCenter : stdSeat(33, true), rightWindow : stdSeat(34, true)  },

  { leftWindow : stdSeat(35, false),  leftCenter : stdSeat(36, false), leftSide : null, rightSide : null, rightCenter : stdSeat(37, true), rightWindow : stdSeat(38, true)  },

  { leftWindow : stdSeat(39, false),  leftCenter : stdSeat(40, false), leftSide : null, rightSide : stdSeat(41, false),               rightCenter : stdSeat(42, false), rightWindow : stdSeat(43, false)  },

  { leftWindow : stdSeat(44, false),  leftCenter : stdSeat(45, false), leftSide : null, rightSide : stdSeat(46, false),               rightCenter : stdSeat(47, false), rightWindow : stdSeat(48, false)  },

  { leftWindow : stdSeat(49, false),  leftCenter : stdSeat(50, false), leftSide : null, rightSide : stdSeat(51, false),               rightCenter : stdSeat(52, false), rightWindow : stdSeat(53, false)  },

  { leftWindow : stdSeat(54, false),  leftCenter : stdSeat(55, false), leftSide : null, rightSide : stdSeat(56, false),               rightCenter : stdSeat(57, false), rightWindow : stdSeat(58, false)  },

  { leftWindow : stdSeat(59, false),  leftCenter : stdSeat(60, false), leftSide : null, rightSide : stdSeat(61, false),               rightCenter : stdSeat(62, false), rightWindow : stdSeat(63, false)  },

  { leftWindow : stdSeat(64, false),  leftCenter : stdSeat(65, false), leftSide : null, rightSide : stdSeat(66, false),               rightCenter : stdSeat(67, false), rightWindow : stdSeat(68, false)  },

  { leftWindow : stdSeat(69, false),  leftCenter : stdSeat(70, false), leftSide : null, rightSide : stdSeat(71, false),               rightCenter : stdSeat(72, false), rightWindow : stdSeat(73, false)  },

]

export const Prigor_20_v1 = [
  { leftWindow : null,  leftCenter : null, leftSide : null, rightSide : null, rightCenter : null, rightWindow : stdSeat(1, true)  },

  { leftWindow : null,  leftCenter : null, leftSide : null, rightSide : null, rightCenter : stdSeat(2, true), rightWindow : stdSeat(3, true)  },

  { leftWindow : stdSeat(4, true),  leftCenter : stdSeat(5, true), leftSide : stdSeat(6, true), rightSide : null,               rightCenter : stdSeat(7, true), rightWindow : stdSeat(8, true)  },

  { leftWindow : stdSeat(9, true),  leftCenter : stdSeat(10, true), leftSide : stdSeat(11, true), rightSide : null,               rightCenter : stdSeat(12, true), rightWindow : stdSeat(13, true)  },

  { leftWindow : stdSeat(14, true),  leftCenter : stdSeat(15, true), leftSide : stdSeat(16, true), rightSide : null,               rightCenter : stdSeat(17, true), rightWindow : stdSeat(18, true)  },

  { leftWindow : stdSeat(19, true),  leftCenter : stdSeat(20, true), leftSide : stdSeat(21, true), rightSide : null,               rightCenter : stdSeat(22, true), rightWindow : stdSeat(23, true)  },

  { leftWindow : stdSeat(25, true),  leftCenter : stdSeat(26, true), leftSide : stdSeat(27, true), rightSide : null,               rightCenter : stdSeat(28, true), rightWindow : stdSeat(29, true)  },

  { leftWindow : stdSeat(24, true),  leftCenter : stdSeat(25, true), leftSide : stdSeat(26, true), rightSide : null,               rightCenter : stdSeat(27, true), rightWindow : stdSeat(28, true)  },

  { leftWindow : stdSeat(29, false),  leftCenter : stdSeat(30, false), leftSide : null, rightSide : null, rightCenter : stdSeat(31, true), rightWindow : stdSeat(32, true)  },

  { leftWindow : stdSeat(33, false),  leftCenter : stdSeat(34, false), leftSide : null, rightSide : stdSeat(35, false),               rightCenter : stdSeat(36, false), rightWindow : stdSeat(37, false)  },

  { leftWindow : stdSeat(38, false),  leftCenter : stdSeat(39, false), leftSide : null, rightSide : stdSeat(40, false),               rightCenter : stdSeat(41, false), rightWindow : stdSeat(42, false)  },

  { leftWindow : stdSeat(43, false),  leftCenter : stdSeat(44, false), leftSide : null, rightSide : stdSeat(45, false),               rightCenter : stdSeat(46, false), rightWindow : stdSeat(47, false)  },

  { leftWindow : stdSeat(48, false),  leftCenter : stdSeat(49, false), leftSide : null, rightSide : stdSeat(50, false),               rightCenter : stdSeat(51, false), rightWindow : stdSeat(52, false)  },

  { leftWindow : stdSeat(53, false),  leftCenter : stdSeat(54, false), leftSide : null, rightSide : stdSeat(55, false),               rightCenter : stdSeat(56, false), rightWindow : stdSeat(57, false)  },

  { leftWindow : stdSeat(58, false),  leftCenter : stdSeat(59, false), leftSide : null, rightSide : stdSeat(60, false),               rightCenter : stdSeat(61, false), rightWindow : stdSeat(62, false)  },

  { leftWindow : stdSeat(63, false),  leftCenter : stdSeat(64, false), leftSide : null, rightSide : stdSeat(65, false),               rightCenter : stdSeat(66, false), rightWindow : stdSeat(67, false)  },

]

export const Prigor_23_v1 = [
  { leftWindow : stdSeat(1, true),  leftCenter : stdSeat(2, true), leftSide : stdSeat(2, true), rightSide : null,               rightCenter : null, rightWindow : stdSeat(4, true)  },

  { leftWindow : stdSeat(5, true),  leftCenter : stdSeat(6, true), leftSide : stdSeat(7, true), rightSide : null,               rightCenter : stdSeat(8, true), rightWindow : stdSeat(9, true)  },

  { leftWindow : stdSeat(10, true),  leftCenter : stdSeat(11, true), leftSide : stdSeat(12, true), rightSide : null,               rightCenter : stdSeat(13, true), rightWindow : stdSeat(14, true)  },

  { leftWindow : stdSeat(15, true),  leftCenter : stdSeat(16, true), leftSide : null, rightSide : null, rightCenter : stdSeat(17, true), rightWindow : stdSeat(18, true)  },

  { leftWindow : stdSeat(19, false),  leftCenter : stdSeat(20, false), leftSide : null, rightSide : stdSeat(21, false),               rightCenter : stdSeat(22, false), rightWindow : stdSeat(23, false)  },

  { leftWindow : stdSeat(24, false),  leftCenter : stdSeat(25, false), leftSide : null, rightSide : stdSeat(26, false),               rightCenter : stdSeat(27, false), rightWindow : stdSeat(28, false)  },

  { leftWindow : stdSeat(29, false),  leftCenter : stdSeat(30, false), leftSide : null, rightSide : stdSeat(31, false),               rightCenter : stdSeat(32, false), rightWindow : stdSeat(33, false)  },

  { leftWindow : stdSeat(34, false),  leftCenter : stdSeat(35, false), leftSide : null, rightSide : null, rightCenter : null, rightWindow : null  },
]

export const Prigor_21_v1 = [
  { leftWindow : stdSeat(1, false),  leftCenter : null, leftSide : null, rightSide : null,               rightCenter : stdSeat(115, false), rightWindow : stdSeat(114, false)  },

  { leftWindow : stdSeat(2, true),  leftCenter : stdSeat(3, true), leftSide : stdSeat(4, true), rightSide : stdSeat(111, true),               rightCenter : stdSeat(112, true), rightWindow : stdSeat(113, true)  },

  { leftWindow : stdSeat(7, false),  leftCenter : stdSeat(6, false), leftSide : stdSeat(5, false), rightSide : stdSeat(110, false),               rightCenter : stdSeat(109, false), rightWindow : stdSeat(108, false)  },

  { leftWindow : stdSeat(8, true),  leftCenter : stdSeat(9, true), leftSide : stdSeat(10, true), rightSide : stdSeat(105, true),               rightCenter : stdSeat(106, true), rightWindow : stdSeat(107, true)  },

  { leftWindow : stdSeat(13, false),  leftCenter : stdSeat(12, false), leftSide : stdSeat(11, false), rightSide : stdSeat(104, false),               rightCenter : stdSeat(103, false), rightWindow : stdSeat(102, false)  },

  { leftWindow : stdSeat(14, true),  leftCenter : stdSeat(15, true), leftSide : stdSeat(16, true), rightSide : stdSeat(99, true),               rightCenter : stdSeat(100, true), rightWindow : stdSeat(101, true)  },

  { leftWindow : stdSeat(19, false),  leftCenter : stdSeat(18, false), leftSide : stdSeat(17, false), rightSide : stdSeat(98, false),               rightCenter : stdSeat(97, false), rightWindow : stdSeat(96, false)  },

  { leftWindow : stdSeat(20, true),  leftCenter : stdSeat(21, true), leftSide : stdSeat(22, true), rightSide : stdSeat(93, true),               rightCenter : stdSeat(94, true), rightWindow : stdSeat(95, true)  },

  { leftWindow : stdSeat(25, false),  leftCenter : stdSeat(24, false), leftSide : stdSeat(23, false), rightSide : stdSeat(92, false),               rightCenter : stdSeat(91, false), rightWindow : stdSeat(90, false)  },

  { leftWindow : stdSeat(26, true),  leftCenter : stdSeat(27, true), leftSide : stdSeat(28, true), rightSide : stdSeat(87, true),               rightCenter : stdSeat(88, true), rightWindow : stdSeat(89, true)  },

  { leftWindow : stdSeat(31, false),  leftCenter : stdSeat(30, false), leftSide : stdSeat(29, false), rightSide : stdSeat(86, false),               rightCenter : stdSeat(85, false), rightWindow : stdSeat(84, false)  },

  { leftWindow : stdSeat(32, true),  leftCenter : stdSeat(33, true), leftSide : stdSeat(34, true), rightSide : stdSeat(81, true),               rightCenter : stdSeat(82, true), rightWindow : stdSeat(83, true)  },

  { leftWindow : stdSeat(37, false),  leftCenter : stdSeat(36, false), leftSide : stdSeat(35, false), rightSide : stdSeat(80, false),               rightCenter : stdSeat(79, false), rightWindow : stdSeat(78, false)  },

  { leftWindow : stdSeat(38, true),  leftCenter : stdSeat(39, true), leftSide : stdSeat(40, true), rightSide : stdSeat(75, true), rightCenter : stdSeat(76, true), rightWindow : stdSeat(77, true)  },

  { leftWindow : stdSeat(43, false),  leftCenter : stdSeat(42, false), leftSide : stdSeat(41, false), rightSide : stdSeat(74, false),               rightCenter : stdSeat(73, false), rightWindow : stdSeat(72, false)  },

  { leftWindow : stdSeat(44, true),  leftCenter : stdSeat(45, true), leftSide : stdSeat(46, true), rightSide : stdSeat(69, true), rightCenter : stdSeat(70, true), rightWindow : stdSeat(71, true)  },

  { leftWindow : stdSeat(49, false),  leftCenter : stdSeat(48, false), leftSide : stdSeat(47, false), rightSide : stdSeat(68, false),               rightCenter : stdSeat(67, false), rightWindow : stdSeat(66, false)  },

  { leftWindow : stdSeat(50, true),  leftCenter : stdSeat(51, true), leftSide : stdSeat(52, true), rightSide : stdSeat(63, true), rightCenter : stdSeat(64, true), rightWindow : stdSeat(65, true)  },

  { leftWindow : stdSeat(55, false),  leftCenter : stdSeat(54, false), leftSide : stdSeat(53, false), rightSide : stdSeat(62, false),               rightCenter : stdSeat(61, false), rightWindow : stdSeat(60, false)  },

  { leftWindow : stdSeat(56, true),  leftCenter : stdSeat(57, true), leftSide : null, rightSide : null, rightCenter : stdSeat(58, true), rightWindow : stdSeat(59, true)  },
]

export const Prigor_22_v1 = [
  { leftWindow : stdSeat(1, false),  leftCenter : null, leftSide : null, rightSide : null,               rightCenter : stdSeat(109, false), rightWindow : stdSeat(108, false)  },

  { leftWindow : stdSeat(2, true),  leftCenter : stdSeat(3, true), leftSide : stdSeat(4, true), rightSide : stdSeat(105, true),               rightCenter : stdSeat(106, true), rightWindow : stdSeat(107, true)  },

  { leftWindow : stdSeat(7, false),  leftCenter : stdSeat(6, false), leftSide : stdSeat(5, false), rightSide : stdSeat(104, false),               rightCenter : stdSeat(103, false), rightWindow : stdSeat(102, false)  },

  { leftWindow : stdSeat(8, true),  leftCenter : stdSeat(9, true), leftSide : stdSeat(10, true), rightSide : stdSeat(99, true),               rightCenter : stdSeat(100, true), rightWindow : stdSeat(101, true)  },

  { leftWindow : stdSeat(13, false),  leftCenter : stdSeat(12, false), leftSide : stdSeat(11, false), rightSide : stdSeat(98, false),               rightCenter : stdSeat(97, false), rightWindow : stdSeat(96, false)  },

  { leftWindow : stdSeat(14, true),  leftCenter : stdSeat(15, true), leftSide : stdSeat(16, true), rightSide : stdSeat(93, true),               rightCenter : stdSeat(94, true), rightWindow : stdSeat(95, true)  },

  { leftWindow : stdSeat(19, false),  leftCenter : stdSeat(18, false), leftSide : stdSeat(17, false), rightSide : stdSeat(92, false),               rightCenter : stdSeat(91, false), rightWindow : stdSeat(90, false)  },

  { leftWindow : stdSeat(20, true),  leftCenter : stdSeat(21, true), leftSide : stdSeat(22, true), rightSide : stdSeat(87, true),               rightCenter : stdSeat(88, true), rightWindow : stdSeat(89, true)  },

  { leftWindow : stdSeat(25, false),  leftCenter : stdSeat(24, false), leftSide : stdSeat(23, false), rightSide : stdSeat(86, false),               rightCenter : stdSeat(85, false), rightWindow : stdSeat(84, false)  },

  { leftWindow : stdSeat(26, true),  leftCenter : stdSeat(27, true), leftSide : stdSeat(28, true), rightSide : stdSeat(81, true),               rightCenter : stdSeat(82, true), rightWindow : stdSeat(83, true)  },

  { leftWindow : stdSeat(31, false),  leftCenter : stdSeat(30, false), leftSide : stdSeat(29, false), rightSide : stdSeat(80, false),               rightCenter : stdSeat(79, false), rightWindow : stdSeat(78, false)  },

  { leftWindow : stdSeat(32, true),  leftCenter : stdSeat(33, true), leftSide : stdSeat(34, true), rightSide : stdSeat(75, true),               rightCenter : stdSeat(76, true), rightWindow : stdSeat(77, true)  },

  { leftWindow : stdSeat(37, false),  leftCenter : stdSeat(36, false), leftSide : stdSeat(35, false), rightSide : stdSeat(74, false),               rightCenter : stdSeat(73, false), rightWindow : stdSeat(72, false)  },

  { leftWindow : stdSeat(38, true),  leftCenter : stdSeat(39, true), leftSide : stdSeat(40, true), rightSide : stdSeat(69, true), rightCenter : stdSeat(70, true), rightWindow : stdSeat(71, true)  },

  { leftWindow : stdSeat(43, false),  leftCenter : stdSeat(42, false), leftSide : stdSeat(41, false), rightSide : stdSeat(68, false),               rightCenter : stdSeat(67, false), rightWindow : stdSeat(66, false)  },

  { leftWindow : stdSeat(44, true),  leftCenter : stdSeat(45, true), leftSide : stdSeat(46, true), rightSide : stdSeat(63, true), rightCenter : stdSeat(64, true), rightWindow : stdSeat(65, true)  },

  { leftWindow : stdSeat(49, false),  leftCenter : stdSeat(48, false), leftSide : stdSeat(47, false), rightSide : stdSeat(62, false),               rightCenter : stdSeat(61, false), rightWindow : stdSeat(60, false)  },

  { leftWindow : stdSeat(50, true),  leftCenter : stdSeat(51, true), leftSide : stdSeat(52, true), rightSide : null, rightCenter : stdSeat(58, true), rightWindow : stdSeat(59, true)  },

  { leftWindow : stdSeat(55, false),  leftCenter : stdSeat(54, false), leftSide : stdSeat(53, false), rightSide : null,               rightCenter : null, rightWindow : null  },

  { leftWindow : stdSeat(56, true),  leftCenter : stdSeat(57, true), leftSide : null, rightSide : null, rightCenter : null, rightWindow : null  },
]