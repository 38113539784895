import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import ActivateView from 'vokzal-platform/components/user/activate/view'

import {userSetValueAction} from "../action";
import {user_renew} from "../../../api/user_renew";
import queryString from 'query-string';

ActivateView.propTypes = {
  onEmailChanged: PropTypes.func.isRequired,
  onPasswordChanged: PropTypes.func.isRequired,
};
class ActivateContainer extends Component {
  render() {
    return <ActivateView {...this.props} />;
  }
}


const mapStateToProps = state => {

  // console.log(777, localStorage.getItem('token') );

  return {
    password: state.user.password,
    password_error: state.user.password_error,
    password2: state.user.password2,
    password2_error: state.user.password2_error,
    repeat_error: state.user.repeat_error,
    error: state.user.error,
    success: state.user.success_activation
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onPasswordChanged: (password) => {
      dispatch(userSetValueAction({password}))
    },
    onPassword2Changed: (password2) => {
      dispatch(userSetValueAction({password2}))
    },
    onDataLoaded : (time_spent, response) => {
      console.log(response);
      dispatch(userSetValueAction({success_activation: true, password: '', password2: '', error: ''}))
    },
    onError : (error) => {
      dispatch(userSetValueAction({error}))
    },
    // fetch: (urlWithParams, onDataLoaded) => {
    //   user_login(urlWithParams, onDataLoaded);
    // },
    onSubmit: (password, password2, onDataLoaded, onError) => {
      // console.log('onSubmit', email, password);
      // password = password.trim();
      // password2 = password2.trim();

      let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d](.*){8,}$/;

      if (!password) {
        // console.log('onSubmit', ' пустое поле пароль');
        dispatch(userSetValueAction({password_error: 'Укажите пароль'}))
      } else if (!re.test(password)) {
        dispatch(userSetValueAction({password_error: 'Днина пароля не менее 8 символов, включая строчные, заглавные буквы и цифры'}))
      } else {
        dispatch(userSetValueAction({password_error: ''}))
      }

       if(password !== password2) {
        // console.log('onSubmit', ' пустое поле пароль');
        dispatch(userSetValueAction({repeat_error: 'Пароли не совпадают'}))
      } else {
        dispatch(userSetValueAction({repeat_error: ''}))
      }
      if (password === password2 && re.test(password)) {
        const queryObject = queryString.parse(location.search);
        user_renew('/api/activate', {password, code: queryObject.code}, onDataLoaded, onError);
      }


    }
  }
}

ActivateContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivateContainer)

export default ActivateContainer