// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux'
import { SearchResultBusPropType, Step2UiFlagsPropType } from '../../../../src/types';

import { searchFormUrlParamsBus } from '../../../../src/utils/url_params';
import { step2BusesSearchCompleteAction } from './action'
import { step2AddTabAction } from '../search_result_tabs/action'

import { search_buses } from '../../../../src/api/search_buses'

import BusesSearchResultView from 'vokzal-platform/components/step2/buses_search_result/view'

import { minPriceBus } from "../../../api/price";

BusesSearchResultView.propTypes = {
  buses: PropTypes.arrayOf(SearchResultBusPropType.isRequired).isRequired,
  uiFlags: PropTypes.arrayOf(Step2UiFlagsPropType).isRequired
};


class BusesSearchResultContainer extends Component {

  componentDidMount() {

    if (this.props.buses.length === 0 && this.props.segments.length > 0) {
      this.props.segments.map((segment) => {
        const searchUrlParams = searchFormUrlParamsBus(segment, this.props.search_params);
        search_buses(searchUrlParams, this.props.onBusesLoaded);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.segments !== this.props.segments) {
      this.props.segments.map((segment) => {
        const searchUrlParams = searchFormUrlParamsBus(segment, this.props.search_params);
        search_buses(searchUrlParams, this.props.onBusesLoaded);
      });
    }
  }

  render() {

    if (!this.props.show) {
      return null;
    }

    return <BusesSearchResultView {...this.props} />;
  }
}


const mapStateToProps = state => {

  const searchUrlWithParams = (state.step2.buses === 0) ? searchFormUrlParamsTrain(state.search_form) : '';

  return {
    searchUrlWithParams,
    segments: state.step2_bus.segments,
    search_params: state.step2_bus.search_params,
    buses : state.step2_bus.buses,
    uiFlags : state.step2_bus.buses_ui_flags,
    show : state.step2_tabs.active_tab === 'buses'
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onBusesLoaded : (time_spent, buses) => {
      dispatch(step2BusesSearchCompleteAction({ time_spent, buses }));

      const price = (buses.data.length > 0) ? minPriceBus(buses.data) : null;
      if (price) {
        dispatch(step2AddTabAction({ code: 'buses', price }));
      }
    }
  }
};

BusesSearchResultContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BusesSearchResultContainer);

export default BusesSearchResultContainer