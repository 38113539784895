import React from 'react';
import ContentTreeRow from '../../../../src/components/shared/content_tree_row';

const ContentTreeView = ({ items, onExpandClick }) => (
    <div className="content-tree mt-4">
        {
            items.map(function(m, index){
              return (<ContentTreeRow key={index} title={m.title} isExpanded={m.isExpanded} onExpandClick={() => onExpandClick(m)}>{m.content}</ContentTreeRow>);
            })
        }
    </div>
);

module.exports = ContentTreeView;
