import React from 'react';
import i18n from 'vokzal-platform/i18n';

import StrijToilet from '../../../../../src/components/step3/strij/base/strij_toilet';
import StrijCabin from '../../../../../src/components/step3/strij/base/strij_cabin';

const StrijLuxMapView = ({ carNumber, cabins }) => (
  <div className="train-car-select-seats-map rounded p-2">

    <div className="d-flex justify-content-between">
      <div className="train-car-select-seats-map-block train-car-map-text train-car-map-korridor rounded small text-center w-25 mr-2 mb-2">
        <div className="train-car-map-korridor-label">{i18n.t('korridor')}</div>
      </div>

      <div className="w-75">
        <StrijToilet className="right"/>
        <div className="clear"></div>
        <div className="table table-right"></div>
        <div className="clear"></div>
        <StrijCabin carNumber={carNumber} cabinInfo={cabins[0]} />

        <div className="mb-4"></div>

        <StrijCabin carNumber={carNumber} cabinInfo={cabins[1]} />
        <div className="table table-right"></div>
        <div className="clear"></div>
        <StrijToilet className="right"/>
        <div className="clear mb-4"></div>

        <StrijToilet className="right"/>
        <div className="clear"></div>
        <div className="table table-right"></div>
        <div className="clear"></div>
        <StrijCabin carNumber={carNumber} cabinInfo={cabins[2]} />

        <div className="mb-4"></div>

        <StrijCabin carNumber={carNumber} cabinInfo={cabins[3]} />
        <div className="table table-right"></div>
        <div className="clear"></div>
        <StrijToilet className="right"/>
        <div className="clear mb-4"></div>

        <StrijToilet className="right"/>
        <div className="clear"></div>
        <div className="table table-right"></div>
        <div className="clear"></div>
        <StrijCabin carNumber={carNumber} cabinInfo={cabins[4]} />
     </div>

    </div>
  </div>
);

module.exports = StrijLuxMapView;