import React from 'react';
import TrainRow from '../../../../src/components/step2/train_row/index';
import i18n from "../../../i18n";

const TrainsSearchResultView = ({ trains, uiFlags, onTrainClick }) => (
  <div className="search-results mr-2 ml-2 mb-4 pt-2 d-flex flex-column pb-4">
    <div className="time-text font-light small">
      {i18n.t('departure_time_msk_arrival_time_local')}
    </div>
    {
      trains.map((m, index) =>  (
        <div key={index} className="train-info-wrapper">
          <TrainRow trainItem={m} uiFlags={uiFlags[index]} onTrainClick={() => onTrainClick(m) } />
        </div>
      ))
    }
  </div>
);

module.exports = TrainsSearchResultView;