export const busInitialStatePlatform = () => {
  let initialState = {}

  if (typeof pedia_bus !== 'undefined') {

    initialState.loaded = true;

    initialState.title = pedia_bus.bus.title;
    initialState.subTitle = pedia_bus.bus.ab;

    initialState.route = pedia_bus.route;
    initialState.run_info = pedia_bus.run_info;

    initialState.bus = pedia_bus.bus;
  }

  return initialState;
}