// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SearchResultTrainPropType } from '../../../../src/types';

import { connect } from 'react-redux'

import { searchFormUrlParamsTrainAlternative } from '../../../utils/url_params'

import { search_trains } from '../../../api/search_trains'

import { minPrice } from "../../../api/price";

import { hidePreloaderAction } from '../action'
import { step2TrainsSearchCompleteAction } from '../trains_search_result/action'
import { step2BusesSegmentsCompleteAction } from '../buses_search_result/action'
import { step2AddTabAction } from '../search_result_tabs/action'

import { showDetailsAction } from '../train_details/action'

import { onRaspisanieLoaded } from '../../pedia/raspisanie/container'
import { showRaspisanie } from 'vokzal-platform/managers/navigation'

import TrainsSearchResultAlternativeView from 'vokzal-platform/components/step2/trains_search_result_alternative/view'

TrainsSearchResultAlternativeView.propTypes = {
  trains: PropTypes.arrayOf(SearchResultTrainPropType).isRequired,
  showPreloader: PropTypes.bool.isRequired,
  onTrainClick: PropTypes.func.isRequired
};

class TrainsSearchResultAlternativeContainer extends Component {

  componentDidMount() {
    if (this.props.trains.length === 0) {
      search_trains(this.props.searchUrlWithParams, this.props.onDataLoaded, null, this.props.onError);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.trains.length === 0) {
      search_trains(this.props.searchUrlWithParams, this.props.onDataLoaded, null, this.props.onError);
    }
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return <TrainsSearchResultAlternativeView {...this.props} />;
  }
}


const mapStateToProps = state => {

  const s = state.step2_alternative.train_search_form;
  const t = state.step2_tabs;

  const trains = state.step2.trains;

  const searchUrlWithParams = (s !== null && trains.length === 0) ? searchFormUrlParamsTrainAlternative(s) : '';

  return {
    searchUrlWithParams,
    trains,
    show: t.active_tab === 'trains',
    showPreloader: !state.step2.data_loaded
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onDataLoaded: (time_spent, response) => {
      dispatch(hidePreloaderAction());
      dispatch(step2TrainsSearchCompleteAction({ time_spent, trains: response.trains }));

      const price = response.trains.map(t => (minPrice(t.prices).price)).reduce((a, b) => ((a > b) ? b : a));
      if (price) {
        dispatch(step2AddTabAction({ code: 'trains', price }));
      }
    },
    onError: (error) => {
      dispatch(hidePreloaderAction());
      dispatch(step2TrainsSearchErrorAction(error));
    },
    onTrainClick: train => {
      dispatch(showDetailsAction(train))
    }
  }
};

TrainsSearchResultAlternativeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainsSearchResultAlternativeContainer);

export default TrainsSearchResultAlternativeContainer