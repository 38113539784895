import { createAction } from 'redux-actions'

export const ADMIN_SET_VALUE       = 'ADMIN/SET/VALUE'
export const adminSetValueAction          = createAction(ADMIN_SET_VALUE)

export const ADMIN_REVIEWS_FETCH_COMPLETE_ACTION       = 'ADMIN/REVIEWS/FETCH/COMPLETE'
export const adminReviewsFetchCompleteAction          = createAction(ADMIN_REVIEWS_FETCH_COMPLETE_ACTION)

export const ADMIN_REVIEW_DELETE_ACTION       = 'ADMIN/REVIEW/DELETE'
export const adminReviewDeleteAction          = createAction(ADMIN_REVIEW_DELETE_ACTION)

export const ADMIN_STATS_FETCH_COMPLETE_ACTION       = 'ADMIN/STATS/FETCH/COMPLETE'
export const adminStatsFetchCompleteAction          = createAction(ADMIN_STATS_FETCH_COMPLETE_ACTION)