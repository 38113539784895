import moment from "moment/moment";
import { processTrainsUiFlags } from '../../../src/utils/ui';

export const step2InitialStatePlatform = () => {

  let step2InitialState = {}
  if (typeof trains !== 'undefined') {
    step2InitialState.trains = trains;
    step2InitialState.trains_ui_flags = processTrainsUiFlags(trains);
    step2InitialState.data_loaded = true;
  }

  if (typeof error !== 'undefined') {
    step2InitialState.error = error;
    step2InitialState.data_loaded = true;
    if (typeof other_dates !== 'undefined') {
      step2InitialState.error.other_dates = other_dates;
    }
  }

  if (typeof fromName !== 'undefined' && typeof toName !== 'undefined') {
    step2InitialState.from_name = fromName;
    step2InitialState.to_name = toName;
  }

  return step2InitialState;
}

export const step2BusInitialStatePlatform = () => {
  let step2BusInitialState = {}

  // if (typeof bus_search_segments === 'undefined') {
  //   bus_search_segments =
  // }


  if (typeof bus_search_segments !== 'undefined') {
    step2BusInitialState.segments = bus_search_segments;
    // step2BusInitialState.segments = [{"departure_bus_stop_id":"68","arrival_bus_stop_id":"69"},{"departure_bus_stop_id":"68","arrival_bus_stop_id":"124188"}];
    step2BusInitialState.search_params = bus_search_params;
    step2BusInitialState.search_params.departureDate = moment(step2BusInitialState.search_params.departureDate, 'DD.MM.YYYY');
  }

  if (typeof bus_error !== 'undefined') {
    step2BusInitialState.error = error;
  }

  return step2BusInitialState;
}