// @flow
import PropTypes from 'prop-types';
import { SeatNumbersSeatedPropType } from '../../../../../src/types/';

import StrijFirstClassMapView from 'vokzal-platform/components/step3/strij/strij_first_class_map/view'

StrijFirstClassMapView.propTypes = {
  carNumber : PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(SeatNumbersSeatedPropType).isRequired
};

const StrijFirstClassMap = StrijFirstClassMapView

export default StrijFirstClassMap