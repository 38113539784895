import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import i18n from 'vokzal-platform/i18n'

import { searchTrainRoute } from '../../../../src/api/search_train_route_api';

import { showOrderFormAction } from './action'
import { fillScheduleGroupAction } from '../schedule/action'
import { trainShowOrderForm, trainScheduleShowOrderForm, trainOthersShowOrderForm } from '../../pedia/train/action';

import PediaOrderBlockView from 'vokzal-platform/components/pedia/order_block/view'

PediaOrderBlockView.propTypes = {
  scheduleGroup : PropTypes.string.isRequired,
  orderButtonTitle : PropTypes.string.isRequired,
  showOrderForm : PropTypes.bool.isRequired,
  onOrderClick : PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const orderButtonTitle = i18n.t('schedule_order');
  return { orderButtonTitle }
}

const mapDispatchToProps = dispatch => {
  return {
    onOrderClick: (scheduleGroup, trainNumber, one = false, type = false) => {
      if (one) {
        dispatch(trainShowOrderForm());
      } else if (type === 'trainSchedule') {
        dispatch(trainScheduleShowOrderForm(trainNumber));
      } else if (type === 'trainOthers') {
        dispatch(trainOthersShowOrderForm(trainNumber));
      } else {
        dispatch(showOrderFormAction(trainNumber));
      }

      dispatch(fillScheduleGroupAction(scheduleGroup));

      // searchTrainRoute(dispatch, trainNumber)
    }
  }
}


const PediaOrderBlockContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PediaOrderBlockView)

export default PediaOrderBlockContainer