import { handleActions } from 'redux-actions';
import {
    REVIEW_SET_VALUE,
} from './action'

const reviewReducer = handleActions({
        [REVIEW_SET_VALUE]: (state, action) => {
            return { ...state, ...action.payload  }
        },
}, {
})

export default reviewReducer
