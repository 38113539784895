// @flow
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { testWdAction } from './action'

import TestWdView from 'vokzal-platform/components/test_wd/view'

TestWdView.propTypes = {
  val: PropTypes.string,
  log_rows: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => {

  return {
      val: state.test_wd.value,
      log_rows: state.test_wd.log_rows
  }
}

const mapDispatchToProps = dispatch => {

  return {
    onChange: (e) => {
      dispatch(testWdAction(e))
    }
  }
}

const TestWdContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TestWdView)

export default TestWdContainer