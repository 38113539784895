import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { showFooterLink } from 'vokzal-platform/managers/navigation'

import { FooterLinksPropType } from '../../../../src/types/index';
import FooterLinksRowView from 'vokzal-platform/components/home/footer_links_row/view'

FooterLinksRowView.propTypes = {
  footerLinkItem : FooterLinksPropType.isRequired,
  onLinkClick : PropTypes.func.isRequired
};


const mapStateToProps = state => ({  });

const mapDispatchToProps = dispatch => {
  return {
    onLinkClick: (footerLinkItem) => {
      showFooterLink(footerLinkItem)
    }
  }
};


const FooterLinksRowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterLinksRowView);

export default FooterLinksRowContainer