import moment from 'moment';

import { vokzal_config } from '../config';


export const fetch_pedia_station_bus = (urlWithParams, onDataLoaded) => {
  const requested_at = moment();

  fetch(vokzal_config.rest.base_url + urlWithParams, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {
      //TODO error handling

      responseJson.schedule = post_process_schedule_bus(responseJson.schedule);
      responseJson.schedule_groups = make_schedule_groups(responseJson.station_id, responseJson.schedule);

      onDataLoaded(moment().diff(requested_at), responseJson);
    });
};

export const post_process_schedule_bus = (schedule) => (
  schedule.map(i => {
    i.showOrderForm = true;

    return i;
  })
)

export const make_schedule_groups = (id, schedule) => {
    const groups = schedule.reduce((groups, item) => {
        let val = '';
        if (id === item.arrival.id) {
            val = item.departure.name;
        } else {
            val = item.arrival.name;
        }

        groups[val] = groups[val] || {name: val, isExpanded: false, items: []};

        return groups;
    }, {});
    if (Object.keys(groups).length <= 5) {
        for (let key in groups) {
            groups[key].isExpanded = true;
        }
    }

    return groups;
}