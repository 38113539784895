import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import i18n from 'vokzal-platform/i18n';

import SapsanToiletView from 'vokzal-platform/components/step3/sapsan/base/sapsan_toilet/view'

SapsanToiletView.propTypes = {
  title: PropTypes.string.isRequired,
  autoHeight: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({ title : i18n.t('toilet') })


const SapsanToiletContainer = connect(
  mapStateToProps
)(SapsanToiletView)

export default SapsanToiletContainer