import { connect } from 'react-redux'

import PropTypes from 'prop-types';
import { FooterLinksPropType } from '../../../../src/types';

import FooterLinksView from 'vokzal-platform/components/home/footer_links/view';

FooterLinksView.propTypes = {
  items: PropTypes.arrayOf(FooterLinksPropType).isRequired
};

const mapStateToProps = state => {
    return { items : state.footer_links.items, }
};

const mapDispatchToProps = dispatch => {
    return {
        onExpandClick: (contentTreeItem) => {
            // dispatch(toggleContentTreeAction(contentTreeItem));
        }
    }
};


const FooterLinksContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(FooterLinksView);

export default FooterLinksContainer