import React from 'react';

import BusInfo from '../../../../src/components/step2/bus_info';
import BusPrices from '../../../../src/components/step2/bus_prices';

const BusRowView = ({ searchFormState, busItem, uiFlags, onBusClick, abt = false }) => {

  const baseCss = "bus-additional-item pl-1 pr-1 mr-1 ml-2 bus-additional-";

  let cssClass = '';

  let preCustomUI = [];
  if (typeof(uiFlags) !== 'undefined' && uiFlags.fastest) {
    preCustomUI.push((
      <div key={0} className={baseCss + "fastest"}>Самый быстрый</div>
    ));
  }

  if (typeof(uiFlags) !== 'undefined' && uiFlags.cheapest) {
    preCustomUI.push((
      <div key={1} className={baseCss + "cheapest"}>Лучшая цена</div>
    ));
  }

  if (typeof(uiFlags) !== 'undefined' && uiFlags.cheap) {
    preCustomUI.push((
      <div key={2} className={baseCss + "cheap"}>Дешёвый</div>
    ));
  }

  if (typeof(uiFlags) !== 'undefined' && uiFlags.fast) {
    preCustomUI.push((
      <div key={3} className={baseCss + "fast"}>Быстрый</div>
    ));
  }

  if (typeof(uiFlags) !== 'undefined' && uiFlags.min_seats) {
    preCustomUI.push((
      <div key={4} className={baseCss + "min-seats"}>Мало мест</div>
    ));
  }

  let customUI = null;
  if (preCustomUI.length > 0) {
    customUI = (
      <div className="bus-additional small d-flex ml-2 mr-2">{preCustomUI}</div>
    )
    cssClass += " card-extra-flags";
  }

  return (
    <div className={"bus-row rounded mb-1 " + cssClass} onClick={() => onBusClick(searchFormState, busItem)}>
      {customUI}
      <BusInfo busItem={busItem}/>
      <BusPrices busItem={busItem} abt={abt}/>
    </div>
  )
}

module.exports = BusRowView;