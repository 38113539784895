// @flow

import { createAction } from 'redux-actions'

export const BUS_SEAT_SELECT_ACTION            = 'STEP3BUS/SEAT/SELECT'
export const select_bus_seat                   = createAction(BUS_SEAT_SELECT_ACTION);

export const BUS_SEAT_DESELECT_ACTION          = 'STEP3BUS/SEAT/DESELECT'
export const deselect_bus_seat                 = createAction(BUS_SEAT_DESELECT_ACTION);

export const BUS_SEAT_CLEAR_ACTION             = 'STEP3BUS/SEAT/CLEAR'
export const clear_selected_bus_seat           = createAction(BUS_SEAT_CLEAR_ACTION);

export const BUS_SEARCH_ACTION                  = 'STEP3BUS/FORM/SET'
export const bus_search                         = createAction(BUS_SEARCH_ACTION);

export const BUS_SEARCH_ERROR_ACTION            = 'STEP3BUS/ERROR/SET'
export const bus_search_error                   = createAction(BUS_SEARCH_ERROR_ACTION);