import { createAction } from 'redux-actions'

export const TICKET_RETURN_SUBMIT_ACTION      = 'TICKET_RETURN/FORM/SUBMIT'
export const submitTicketReturnAction         = createAction(TICKET_RETURN_SUBMIT_ACTION)

export const TICKET_RETURN_CAN_SUBMIT_ACTION  = 'TICKET_RETURN/FORM/CAN_SUBMIT'
export const canSubmitTicketReturnAction      = createAction(TICKET_RETURN_CAN_SUBMIT_ACTION)


export const ORDER_NUMBER_CHANGED_ACTION      = 'TICKET_RETURN/FORM/ORDER_NUMBER/CHANGED'
export const orderNumberChangedAction         = createAction(ORDER_NUMBER_CHANGED_ACTION)

export const MOBILE_PHONE_CHANGED_ACTION      = 'TICKET_RETURN/FORM/MOBILE_PHONE/CHANGED'
export const mobilePhoneChangedAction         = createAction(MOBILE_PHONE_CHANGED_ACTION)

export const SHOW_RETURN_DETAILS_ACTION       = 'TICKET_RETURN/DETAILS/SHOW'
export const showTicketReturnDetailsnAction   = createAction(SHOW_RETURN_DETAILS_ACTION)

export const HIDE_RETURN_DETAILS_ACTION       = 'TICKET_RETURN/DETAILS/HIDE'
export const hideTicketReturnDetailsnAction   = createAction(HIDE_RETURN_DETAILS_ACTION)

export const TICKET_RETURN_DETAILS_ACTION     = 'TICKET_RETURN/DETAILS/LOADED'
export const loadedTicketReturDetailsnAction  = createAction(TICKET_RETURN_DETAILS_ACTION)

export const FETCH_RETURN_DETAILS_ACTION      = 'TICKET_RETURN/DETAILS/FETCH'
export const fetchTicketReturnDetailsAction   = createAction(FETCH_RETURN_DETAILS_ACTION)

export const ERROR_RETURN_DETAILS_ACTION      = 'TICKET_RETURN/DETAILS/ERROR'
export const errorTicketReturnDetailsAction   = createAction(ERROR_RETURN_DETAILS_ACTION)
