import React from 'react';

const DepartureArrivalView = ({ type, departureTime, arrivalTime, wayTime, departureName, arrivalName }) => {

  return (
    <div className={type+"-departure-arrival mb-2"}>
      <div className={type+"-departure-arrival-time d-flex justify-content-between align-items-center"}>

        <div className={type+"-departure-arrival-item"}>
          <div className="h5 font-weight-bold mb-0">{departureTime}</div>
        </div>

        <div className={type+"-departure-arrival-arrow"} />

        <div className="small font-other-light">
          {wayTime}
        </div>

        <div className={type+"-departure-arrival-arrow"} />

        <div className={type+"-departure-arrival-item text-right"}>
          <div className="h5 font-weight-bold mb-0">{arrivalTime}</div>
        </div>
      </div>

      <div className={type+"-departure-arrival-cities d-flex justify-content-between"}>
        <div className={type+"-departure-arrival-city small"}>{departureName}</div>
        <div className={type+"-departure-arrival-city small text-right"}>{arrivalName}</div>
      </div>
    </div>
  );
};

export default DepartureArrivalView;