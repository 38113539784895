// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import i18n from 'vokzal-platform/i18n'

import { connect } from 'react-redux'

import PediaStationsListView from 'vokzal-platform/components/pedia/stations_list/view'
import {fetch_pedia_stations_list} from "../../../api/fetch_pedia_stations_list";
import {hidePreloaderAction, stationFetchCompleteAction, togglStationsGroupBlockAction} from "./action";

import { PediaStationsGroupWithSubgroupItemPropType } from '../../../types';
import {regionUrlStoreAction} from "../region/action";

PediaStationsListView.propTypes = {
  loaded: PropTypes.bool.isRequired,
  groups : PropTypes.arrayOf(PediaStationsGroupWithSubgroupItemPropType).isRequired,
};

class PediaStationsListContainer extends Component {
  componentDidMount() {

    if (!this.props.loaded) {
      fetch_pedia_stations_list(this.props.urlWithParams, this.props.onDataLoaded);
    }
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    return <PediaStationsListView {...this.props} />;
  }
}


const mapStateToProps = state => {

    return {
      loaded: state.pedia_stations_list.loaded,
      title: i18n.t('stations_catalog'),
      urlWithParams: state.pedia_stations_list.urlWithParams,
      groups: state.pedia_stations_list.station_groups
    }
}

const mapDispatchToProps = dispatch => {

    return {
      onDataLoaded : (time_spent, response) => {
        dispatch(hidePreloaderAction());
        dispatch(stationFetchCompleteAction({ time_spent, response }));
      },

      onExpandClick: (item) => {
        dispatch(togglStationsGroupBlockAction(item));
      },
      onLinkClick : (item, event) => {
        dispatch(regionUrlStoreAction(item.url));
      }
    }
}


PediaStationsListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PediaStationsListContainer)

export default PediaStationsListContainer