import React from 'react';
import { Star, StarHalf, StarBorder } from '@material-ui/icons';

const RatingView = ({ stars }) => (
    <div className="search-result-rating d-flex ml-1">
      {stars.map( (item, index) => {
        if (item === 'star') {
          return <Star key={index}/>
        } else if (item === 'star_border') {
          return <StarBorder key={index}/>
        } else if (item === 'star_half') {
          return <StarHalf key={index}/>
        }
      })}
    </div>
  );

module.exports = RatingView;
