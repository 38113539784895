import React from 'react';

import i18n from 'vokzal-platform/i18n';

const OrderButtonView = ({ urlParams, onButtonClick }) => (
  <div className="order-button text-center font-weight-medium text-uppercase rounded mr-2 ml-2 p-3 mr-md-0" onClick={() => { onButtonClick(urlParams) }}>
    <div className="order-button-label">
      {i18n.t('pay_order')}
    </div>
  </div>
);

module.exports = OrderButtonView;