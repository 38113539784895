// @flow
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import PreLoaderView from 'vokzal-platform/components/shared/preloader/view'

PreLoaderView.propTypes = {
  show: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

const mapStateToPropsStep2 = state => {
  return { show : state.step2_preloader.show }
};

const mapStateToPropsStep3 = state => {
  return { show : state.step3_preloader.show }
};

const mapStateToPropsConfirmation = state => {
  return { show : state.confrmation_preloader.show }
};

const mapStateToPropsPedia = state => {
  return { className : "gray" }
};

export const Step2PreLoaderContainer = connect(
  mapStateToPropsStep2
)(PreLoaderView);

export const Step2AlternativePreLoaderContainer = connect()(PreLoaderView);

export const Step3PreLoaderContainer = connect(
  mapStateToPropsStep3
)(PreLoaderView);

export const ConfirmationPreLoaderContainer = connect(
  mapStateToPropsConfirmation
)(PreLoaderView);

export const PediaPreLoaderContainer = connect(
  mapStateToPropsPedia
)(PreLoaderView);

export const BlogPreLoaderContainer = connect(
  mapStateToPropsPedia
)(PreLoaderView);

