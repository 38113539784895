import React from 'react';
import { Collapse } from 'react-bootstrap'

import PlusButton from '../../../../src/components/shared/plus_button';

const ContentTreeRowView = ({ title, isExpanded, onExpandClick, children }) => (
    <div className="content-tree-row">
        <div className="content-tree-header d-flex justify-content-between" onClick={onExpandClick}>
            <div className="content-tree-title font-weight-bold">{title}</div>
            {(children !== null) ? <PlusButton className="blue-item" isExpanded={isExpanded} /> : null }
        </div>

        <Collapse in={isExpanded}>
            <div className="content-tree-content pt-3">
                {children}
            </div>
        </Collapse>
    </div>
);

module.exports = ContentTreeRowView;