import { handleActions } from 'redux-actions'

import { RAILROAD_MAP_BOUNDS_ACTION } from "./action";

const initialState = { bounds : null }

const PediaRailroadMapReducer = handleActions({
    [RAILROAD_MAP_BOUNDS_ACTION]: (state, action) => {
      return {...state, bounds: action.payload}
    },
}, initialState)

export default PediaRailroadMapReducer