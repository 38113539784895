// @flow

import { handleActions } from 'redux-actions'
import { 
  INDICATORVIEW_VISIBILITY,
  INDICATORVIEW_STARTWORKING
} from './action'

const initialState = {
  visible: false,
  working: false
}

const IndicatorReducer = handleActions({
  [INDICATORVIEW_VISIBILITY]: (state, action) => {
    return {...state, visible: action.payload, working: false}
  },
  [INDICATORVIEW_STARTWORKING]: (state, action) => {
    return {...state, working: true}
  }
}, initialState)

export default IndicatorReducer