import React from 'react';

import i18n from 'vokzal-platform/i18n';

import BootstrapLabeledInput from '../../base/bootstrap_labeled_input';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCircle} from "@fortawesome/free-regular-svg-icons";

const ContactDetailsView = ({ email, previous_email, is_email_valid, mobile_phone, previous_mobile_phone, is_mobile_phone_valid, attention, onEmailChanged, onMobilePhoneChanged, onAttentionChanged, onClickCheck, lk_status, user }) => (
  <div className="contact-details page-section rounded">
    <div className="section-title mb-2">{i18n.t('confirmation_contact_details')}</div>
    <div className="row contact-details-form col-80">
        <div className="col-md-6 pr-md-0">
            <BootstrapLabeledInput label={i18n.t('confirmation_email')}
                                   fieldsetClass={(is_email_valid) ? "" : "input-invalid"}
                                   name="email" value={email} required={true}
                                   onChange={(value, inputElement) => onEmailChanged(value, email, inputElement)} />
        </div>
        <div className="col-md-6 pr-md-0 pl-md-0">
            <BootstrapLabeledInput label={i18n.t('confirmation_mobile_phone')}
                                   fieldsetClass={(is_mobile_phone_valid) ? "" : "input-invalid"}
                                   name="phone" value={mobile_phone} required={true}
                                   id="phone"
                                   maxlength="17"
                                   onChange={(value, inputElement) => onMobilePhoneChanged(value, mobile_phone, inputElement)} />
            <span className="validation-message top validation-info small">Например, +79161234567</span>
        </div>
    </div>

      {/*<BootstrapLabeledInput name="attention" type="checkbox" fieldsetClass={"attention"} label={i18n.t('confirmation_attention')}*/}
                             {/*value={attention}*/}
                             {/*onChange={value => onAttentionChanged(value)}*/}
      {/*/>*/}
      <p className="contact-info">Контактные данные необходимы для отправки электронного билета, информации о заказе, а также для связи с пассажиром в случае изменений в расписании поезда.</p>

      {user ? null : <div className="lk-create-check" onClick={() => onClickCheck(lk_status)}>
          {lk_status ? <div className="check-button active"><FontAwesomeIcon icon={faCheckCircle} /></div> : <div className="check-button"><FontAwesomeIcon icon={faCircle} /></div>}
          <div className="check-button-label">Создать личный кабинет если его нет</div>
      </div>}
  </div>
);

module.exports = ContactDetailsView;