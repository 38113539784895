import React from 'react';
import PropTypes from 'prop-types';

const CabinPlaceHorizontalView = ({ carNumber, seatNumber, type, isUpper, state, priceDiapason, onSeatClick }) => {

  let className = (isUpper) ? 'place-front' : 'place-back';
  className += (type !== 'side') ? ' ' + type : '';
  className += (state === 'busy' || state === 'selected') ? ' seat-' + state : '';
  className += (state === 'unavailable') ? ' seat-busy' : '';

  let seatInfo = seatNumber;
  if (state === 'busy') {
    seatInfo = <div className="car-state-icon car-state-icon-busy" />;
  } else if (state === 'selected') {
    seatInfo = <div className="car-state-icon car-state-icon-selected" />;
  }

  let dt = '';
  if (state !== 'busy' && priceDiapason.hasOwnProperty('upper') && priceDiapason.hasOwnProperty('lower')) {
    if (isUpper) {
      dt = priceDiapason.upper +  " ₽, верхнее"
    } else {
      dt = priceDiapason.lower + " ₽, нижнее"
    }
  } else {
    className += ' after-no';
  }

  return (
    <div className={className} data-title={dt} onClick={() => onSeatClick(carNumber, seatNumber, state, isUpper)}>
      <div className="place-title">
        {seatInfo}
      </div>
    </div>
  );
};

CabinPlaceHorizontalView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  seatNumber: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['side', 'center-top', 'center-bottom', 'sv-top', 'sv-bottom']).isRequired,
  isUpper: PropTypes.bool.isRequired,
  state: PropTypes.oneOf(['available', 'unavailable', 'busy', 'selected']).isRequired,
  onSeatClick: PropTypes.func.isRequired,
};

module.exports = CabinPlaceHorizontalView;