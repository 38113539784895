import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { confirm_reservation } from '../../../api/confirmation';
import { confirmationUrlWithParams } from '../../../utils/url_params';

import OrderBlockView from 'vokzal-platform/components/confirmation/order_block/view'

OrderBlockView.propTypes = {
  show: PropTypes.bool.isRequired,
  paymentType: PropTypes.string.isRequired,
  // confirmationUrlWithParams: PropTypes.string.isRequired,
};

class OrderBlockContainer extends Component {

  componentDidMount() {
    if (this.props.show === false) {
      // confirm_reservation(this.props.confirmationUrlWithParams, this.props.onDataLoaded, this.props.onError);
    }
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return <OrderBlockView {...this.props} />;
  }
}


const mapStateToProps = state => ({
  show: state.confirmation.reserved,
  paymentType: state.step4_documents.payment_type,
  // confirmationUrlWithParams: confirmationUrlWithParams(state)
});

const mapDispatchToProps = dispatch => {
  return {
  }
};

OrderBlockContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderBlockContainer);

export default OrderBlockContainer