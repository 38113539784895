import { alpha } from 'validator/lib/alpha';

import { conformToMask } from 'text-mask-core'

const textValidatorRuEn =
  '' +
  alpha['ru-RU'].source.substr(2, alpha['ru-RU'].source.length - 5) +
  alpha['en-US'].source.substr(2, alpha['en-US'].source.length - 5) +
  '\\s' + '\-' +
  ''
  ;

const textValidatorRu =
  '' +
  alpha['ru-RU'].source.substr(2, alpha['ru-RU'].source.length - 5) +
  '\\s' + '\-' +
  ''
  ;

const textValidatorEn =
  '' +
  alpha['en-US'].source.substr(2, alpha['en-US'].source.length - 5) +
  '\\s' + '\-' +
  ''
  ;

//function from validator/lib/whitelist + ignore case
function whitelist(str, chars) {
  return str.replace(new RegExp('[^' + chars + ']+', 'gi'), '');
}


export const sanitizeAlphaRuEn = (input) => (
  whitelist(input, textValidatorRuEn)
  // whitelist(input, textValidator).trim()
);

export const sanitizeAlphaRu = (input) => (
  whitelist(input, textValidatorRu)
  // whitelist(input, textValidator).trim()
);


export const sanitizeAlphaEn = (input) => (
  whitelist(input, textValidatorEn)
  // whitelist(input, textValidator).trim()
);


export const sanitizeDate = (input, previous) => {
  const results = conformToMask(input, [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/], { guide: false, previousConformedValue: previous })
  return results.conformedValue;
};

export const sanitizePassportRF = (input, previous) => {
  const results = conformToMask(input, [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], { guide: false, previousConformedValue: previous })
  return results.conformedValue;
};

export const sanitizePassportZagran = (input, previous) => {
  const results = conformToMask(input, [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], { guide: false, previousConformedValue: previous })
  return results.conformedValue;
};

export const sanitizeChildDocument = input => {
  return input.replace(/[^IVXivxа-яА-ЯёЁ0-9-]+/g, '');
};

export const isValidPassportRF = (input) => {
  input = input.replace(/ /g, '');
  return /^[0-9]{10}$/.test(input)
}

export const isValidPassportZagran = (input) => {
  input = input.replace(/ /g, '');
  return /^[0-9]{9}$/.test(input)
}

export const isValidChildDocument = input => {
  return /^[IVXivx]{1,6}-?[а-яА-ЯёЁ]{2}-?[0-9]{6}$/.test(input)
}

export const sanitizeMobilePhone = (input, previous) => {

  // input = (input[0] === '+' || input[0] === '7' || input[0] === '8') ? input : '7' + input;

  const results = conformToMask(input, ['+', /\d/, '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/], { guide: false, previousConformedValue: previous })

  const is_mobile_phone_valid = results.conformedValue.length === 17;

  return { mobile_phone: results.conformedValue, is_mobile_phone_valid };
};

export const sanitizeOrderNumber = (input) => {
  const results = conformToMask(input, [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/], { guide: false })

  const is_order_number_valid = results.conformedValue.length === 18;

  return { order_number: results.conformedValue, is_order_number_valid };
}

