import React from 'react';

import i18n from 'vokzal-platform/i18n'

import LastochkaCabin from '../../../../../src/components/step3/lastochka/base/lastochka_cabin';
import LastochkaLuggage from '../../../../../src/components/step3/lastochka/base/lastochka_luggage';


import LastochkaCabinDelimiter from '../../../../../src/components/step3/lastochka/base/lastochka_cabin_delimiter';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlassWhiskey} from "@fortawesome/free-solid-svg-icons";

const Lastprem_w4_v2View = ({ carNumber, cabins }) => (
  <div className="train-car-select-seats-map rounded pt-2">

    <div className="train-car-select-seats-map-block train-car-map-text lastocha-service-area rounded small d-flex align-items-center rounded m-2">
      <div className="m-auto">{i18n.t('lastochka_service_area')}</div>
    </div>

    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[0]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[1]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[2]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[3]} />
    <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[4]} />

    <LastochkaCabinDelimiter />

      <div className="left">
          <div className="icon-glass" data-title="Кулер">
              <FontAwesomeIcon icon={faGlassWhiskey} />
          </div>
      </div>

      <div className="right">
          <LastochkaLuggage />
      </div>

      <div className="clear"></div>

        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[5]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[6]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[7]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[8]} />

        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[9]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[10]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[11]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[12]} />

          <div className="left">
              <LastochkaLuggage className="mt-3" />
          </div>

          <div className="right">
              <LastochkaLuggage className="mt-3" />
          </div>
        <div className="clear"></div>
        <LastochkaCabinDelimiter />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[13]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[14]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[15]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[16]} />
        <LastochkaCabin carNumber={carNumber} cabinInfo={cabins[17]} />

  </div>
);

module.exports = Lastprem_w4_v2View;