// @flow
import { handleActions, combineActions } from 'redux-actions';

import {
  TICKET_RETURN_SUBMIT_ACTION, ORDER_NUMBER_CHANGED_ACTION, MOBILE_PHONE_CHANGED_ACTION, TICKET_RETURN_DETAILS_ACTION,
  SHOW_RETURN_DETAILS_ACTION, HIDE_RETURN_DETAILS_ACTION, TICKET_RETURN_CAN_SUBMIT_ACTION, FETCH_RETURN_DETAILS_ACTION,
  ERROR_RETURN_DETAILS_ACTION
} from './action';

import { returnTicketConfirmationInitialStatePlatform } from 'vokzal-platform/components/ticket_return/return_form/reducer';


let intialState = {
  can_submit_form : true,
  order_number : '', is_order_number_valid : true,
  mobile_phone : '+7', is_mobile_phone_valid : true,
  show_form : true,  fetch_error : null,
  details_loaded : false, details : {}
};

intialState = Object.assign({}, intialState, returnTicketConfirmationInitialStatePlatform())


const TicketReturnFormReducer = handleActions({
  [combineActions(ORDER_NUMBER_CHANGED_ACTION, MOBILE_PHONE_CHANGED_ACTION)]: (state, action) => {
    return { ...state, ...action.payload }
  },
  [TICKET_RETURN_SUBMIT_ACTION]: (state, action) => {
    return { ...state, ...action.payload }
  },
  [TICKET_RETURN_DETAILS_ACTION]: (state, action) => {
    return { ...state, details_loaded: true, details: action.payload.response }
  },
  [SHOW_RETURN_DETAILS_ACTION]: (state, action) => {
    return { ...state, show_form : false }
  },
  [HIDE_RETURN_DETAILS_ACTION]: (state, action) => {
    return { ...state, show_form : true }
  },
  [FETCH_RETURN_DETAILS_ACTION]: (state, action) => {
    return { ...state }
  },
  [TICKET_RETURN_CAN_SUBMIT_ACTION]: (state, action) => {
    return { ...state, can_submit_form : action.payload }
  },
  [ERROR_RETURN_DETAILS_ACTION]: (state, action) => {
    return { ...state, fetch_error : action.payload }
  }
}, intialState)

export default TicketReturnFormReducer