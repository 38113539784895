import React from 'react';

import HelpPost from '../../../../src/components/help/post';


const HelpPostsListView = ({ url, subnodes, canGoBack, onBack }) => (
  <div className="help-posts-list-wrapper">
    <div className="help-posts-list">
      {subnodes.map((p, index) => (
        <HelpPost key={index} {...p} />
      ))}
    </div>

    {(canGoBack)
      ? <button type="button" className={"btn btn-success btn-block text-uppercase mt-4 mb-4"} onClick={() => onBack(url)}>
          Назад
        </button>
      : null
    }
  </div>
);

module.exports = HelpPostsListView;