import React from 'react';

import { PopularCityPropType } from '../../../../src/types/index';

const PopularCityRow = ({ popularCityItem }) => (
    <div className="popular-city-row">
        {popularCityItem.title}
    </div>
);

PopularCityRow.propTypes = {
    popularCityItem : PopularCityPropType.isRequired,
};

module.exports = PopularCityRow;