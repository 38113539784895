import { createAction } from 'redux-actions'

export const LOAD_PEDIA_TRAIN              = 'PEDIA/TRAIN/LOAD'
export const load_pedia_train_action       = createAction(LOAD_PEDIA_TRAIN);

export const TRAIN_FETCH_COMPLETE_ACTION   = 'PEDIA/TRAIN/FETCH/COMPLETE'
export const trainFetchCompleteAction      = createAction(TRAIN_FETCH_COMPLETE_ACTION)

export const TRAIN_URL_STORE_ACTION       = 'PEDIA/TRAIN/URL/STORE'
export const trainUrlStoreAction          = createAction(TRAIN_URL_STORE_ACTION)

export const TRAIN_SHOW_ORDER_FORM       = 'PEDIA/TRAIN/SHOW/ORDER/FORM'
export const trainShowOrderForm          = createAction(TRAIN_SHOW_ORDER_FORM)

export const TRAIN_SCHEDULE_SHOW_ORDER_FORM       = 'PEDIA/TRAIN/SHEDULE/SHOW/ORDER/FORM'
export const trainScheduleShowOrderForm          = createAction(TRAIN_SCHEDULE_SHOW_ORDER_FORM)

export const TRAIN_OTHERS_SHOW_ORDER_FORM       = 'PEDIA/TRAIN/OTHERS/SHOW/ORDER/FORM'
export const trainOthersShowOrderForm          = createAction(TRAIN_OTHERS_SHOW_ORDER_FORM)
