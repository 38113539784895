import React from 'react';

import SapsanWardrobe from '../../../../../../src/components/step3/sapsan/base/sapsan_wardrobe';

const SapsanDoubleLuggageCabinView = ({ carNumber, wardrobeTitle }) => (
  <div className="sapsan-wardrobe-cabin d-flex justify-content-between">
    <SapsanWardrobe title={wardrobeTitle} />
    <SapsanWardrobe title={wardrobeTitle} />
  </div>
);

module.exports = SapsanDoubleLuggageCabinView;