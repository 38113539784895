import React, {Fragment} from 'react';

import HeadMenu from '../../../src/components/shared/menu';
import StepInfo from '../../../src/components/shared/step_info';
import OrderBlock from '../../../src/components/confirmation/order_block';
import UfsLogo from '../../../src/components/shared/ufs_logo';

const ConfirmationView = ({}) => (
    <Fragment>
      <div className="confirmation gradient-background">
        <HeadMenu stepNumber={5} />
        <StepInfo  stepNumber={5}/>
        <OrderBlock />
      </div>
        <UfsLogo/>
    </Fragment>
);

module.exports = ConfirmationView;