import moment from 'moment';

import { vokzal_config } from '../config';

export const fetch_pedia_trains_list = (urlWithParams, onDataLoaded) => {

  const requested_at = moment();

  fetch(vokzal_config.rest.base_url + urlWithParams, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {
      //TODO error handling

      onDataLoaded(moment().diff(requested_at), responseJson.pedia_train_data);
    });
};