import moment from 'moment';

import { vokzal_config } from '../config';

export const fetch_pedia_station = (urlWithParams, onDataLoaded) => {
  const requested_at = moment();

  fetch(vokzal_config.rest.base_url + urlWithParams, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {
      //TODO error handling

      responseJson.schedule = post_process_schedule(responseJson.schedule);
      responseJson.schedule_groups = make_schedule_groups(responseJson.station_id, responseJson.schedule);

      onDataLoaded(moment().diff(requested_at), responseJson);
    });
};

export const post_process_schedule = (schedule) => (
  schedule.map(i => {
    i.showOrderForm = false; // показать кнопку "выбрать дату и купить билет" в расписании станции
    return i;
  })
)

export const make_schedule_groups = (station_id, schedule) => {
    const groups = schedule.reduce((groups, item) => {
        let val = '';
        if (station_id === item.to.station_id) {
            val = item.from.city;
        } else {
            val = item.to.city;
        }
        groups[val] = groups[val] || { name: val, isExpanded: false, items: [] };
        return groups;
    }, {});
    if (Object.keys(groups).length <= 5) {
        for (let key in groups) {
            groups[key].isExpanded = true;
        }
    }

    return groups;
}