// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import PediaRailroadDetailsView from 'vokzal-platform/components/pedia/railroad_details/view'
import {fetch_pedia_railroad_details} from "../../../api/fetch_pedia_railroad_details";
import {railroadDetailsFetchCompleteAction} from "./action";
import {show_pedia_tab_action} from "../tab_group/action";

PediaRailroadDetailsView.propTypes = {
  loaded: PropTypes.bool.isRequired,
  urlWithParams: PropTypes.string,
  title : PropTypes.string.isRequired,
  description : PropTypes.string
};

class PediaRailroadDetailsContainer extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      fetch_pedia_railroad_details(this.props.urlWithParams, this.props.onDataLoaded);
    }
  }

  render() {
    return <PediaRailroadDetailsView {...this.props} />;
  }
}


const mapStateToProps = state => {
     return {
       loaded: state.pedia_railroad_details.loaded,
       urlWithParams: state.pedia_railroad_details.urlWithParams,
       title: state.pedia_railroad_details.title,
       description: state.pedia_railroad_details.description
    }
}

const mapDispatchToProps = dispatch => {

    return {
      onDataLoaded : (time_spent, response) => {
        dispatch(railroadDetailsFetchCompleteAction({ time_spent, response }));
        dispatch(show_pedia_tab_action('info'));
      }
    }
}


PediaRailroadDetailsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PediaRailroadDetailsContainer)

export default PediaRailroadDetailsContainer