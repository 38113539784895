import React from 'react';
import UserForm from '../../../../src/components/step4/user_form';

const UserListView = ({ documentTypes, userSelectedSeats, urlWithParams }) => (
  <div className="user-list m-2 mt-3">
    {
      userSelectedSeats.map((u, index) => (
        <UserForm key={index} ticketNumber={index+1} type={u.type} seat={u.selectedSeat} userData={u.userData} documentTypes={documentTypes} />
      ))
    }
  </div>
);

module.exports = UserListView;