import React, { lazy, Suspense } from 'react';

import i18n from 'vokzal-platform/i18n';

import HeadMenu from '../../../src/components/shared/menu';

import SearchForm from '../../../src/components/home/search_form';
import ContentTree from '../../../src/components/home/content_tree';
import Trains from '../../../src/components/home/trains';
import PopuparCity from '../../../src/components/home/popular_city';
import SeoTexts from '../../../src/components/home/seo_texts';
import Stations from '../../../src/components/home/stations';
const FooterInfo = lazy(() => import('../../../src/components/shared/footer_info'));
const News = lazy(() => import('../../../src/components/home/news'));
const FooterLinks = lazy(() => import('../../../src/components/home/footer_links'));

const HomeView = () => (
    <div>
        <div className="resp-search-block gradient-background">
            <HeadMenu stepNumber={0} />
            <SearchForm />
        </div>

        <ContentTree />
        <Trains />
        <div className="container-fluid">
            <Stations />
            <PopuparCity />
            <SeoTexts />
            <Suspense fallback={<div>Загрузка...</div>}>
                <News />
            </Suspense>
        </div>
        <Suspense fallback={<div>Загрузка...</div>}>
            <FooterInfo titleText={i18n.t('goodbye')} />
        </Suspense>

        <Suspense fallback={<div>Загрузка...</div>}>
            <FooterLinks />
        </Suspense>
    </div>
);

module.exports = HomeView;