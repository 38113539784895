import React, {Fragment} from 'react';

import PediaOrderBlock from "../../../../src/components/pedia/order_block";

import Smart from 'smart-plurals';
import PediaScheduleItem from '../../../../src/components/pedia/schedule_item';
const russian = Smart.Plurals.getRule('ru');

const stopsPlurals = ['остановку', 'остановки', 'остановок'];

const PediaTrainInfoView = ({ isMain, showOrderForm, number, title, fromName, fromStationTime, toName, ab, toStationTime, maxStationTime, maxStationTimeCityName, stopsCount, avgPrice, priceList, description, trainName, trainOthers, schedule, type = 0 }) => {
    return (
  <div className="pedia-train-info">
      {type === 0 ? <div className="page-section pb-4">
          <h3 className="section-title">Курсирует</h3>

          <div className="d-flex flex-wrap no-gutters pt-2">
            <div className="col-6">
              <div className="small">Время отправления от станции {fromName}</div>
              <div className="h4 font-weight-bold mb-0">{fromStationTime}</div>
            </div>
            <div className="col-6">
              <div className="small">Прибытие на станцию {toName}</div>
              <div className="h4 font-weight-bold mb-0">{toStationTime}</div>
            </div>
          </div>

          <PediaOrderBlock scheduleGroup="" trainNumber={number} showOrderForm={showOrderForm} one={true}/>

        </div> : null}

    <div className="page-section mt-4">
      <h3 className="section-title">Описание</h3>
      <div>
          {type === 0 ? <Fragment><p>
          { (isMain) ? 'Поезд '+title+': время ' : 'Время ' }
            отправления от станции {fromName} - {fromStationTime}, прибытие на станцию {toName} - {toStationTime}
        </p>

        <p>
          {
            (parseInt(stopsCount) > 0)
              ? 'На пути следования поезд делает '+stopsCount+' '+russian(stopsCount, stopsPlurals) + ' и дольше всего поезд стоит на станции '+maxStationTimeCityName+' – '+maxStationTime
              : 'На пути следования поезд не делает остановок.'
          }
        </p></Fragment> : <div>Поезд {title} курсирует по маршруту {ab}.</div>}

        {
            (parseInt(avgPrice) > 0)
              ? <div>
                  <p>Средняя стоимость билета на поезд {title} составляет {avgPrice}, из них: </p>
                  <ul>
                    {priceList.map((p, index) => <li key={index}>{p}</li>)}
                  </ul>
                </div>
              : null
        }

        <p>
            Забронируйте прямо сейчас ЖД-билет на поезд {title} на сайте Вокзал.ру за 2 минуты.
        </p>
      </div>
    </div>

      {typeof description !== 'undefined' && description !== null ?
        <div className="page-section train-history">

            <h2>История поезда и интересные факты</h2>
            <h3>{description.title}</h3>

            <div className="row descr-img">
                {description.images.map(
                    (img, index) => <div className="col-sm-6 col-lg-4" key={index}><img className="img-fluid centered" width="320" height="240" src={img.url} alt={img.caption}/></div>
                )}
            </div>

            <div dangerouslySetInnerHTML={{ __html: description.text }} />
        </div>
          : null
      }

      {trainOthers.length > 0 ? <Fragment>
          <h3>Посмотреть расписание и купить билет на поезд {trainName}</h3>

          <div className="pedia-trains-list-block content-tree mt-1 pt-2 pb-2">
              <div className="content-tree-row">
                  {trainOthers.map((t, index) => {
                      if ( !t.hasOwnProperty('showOrderForm') ) {
                          t.showOrderForm = false;
                      }
                      return (
                          <PediaScheduleItem key={index} train={t} group_name="" showOrderForm={t.showOrderForm} showTime={true} reverseDepartureArrival={true} type="trainOthers"/>
                      )})}
              </div>
          </div></Fragment> : null}

      {schedule.length > 0 ? <Fragment>
      <h3>Другие поезда по маршруту {ab}</h3>

      <div className="pedia-trains-list-block content-tree mt-1 pt-2 pb-2">
          <div className="content-tree-row">
              {schedule.map((t, index) => {
                  if ( !t.hasOwnProperty('showOrderForm') ) {
                      t.showOrderForm = false;
                  }
                  return (
                  <PediaScheduleItem key={index} train={t} group_name="" showOrderForm={t.showOrderForm} showTime={true} reverseDepartureArrival={true} type="trainSchedule"/>
              )})}
          </div>
      </div></Fragment> : null}

      {type === 0 ?
      <div className="pt-4">
      <PediaOrderBlock scheduleGroup="" trainNumber={number} showOrderForm={showOrderForm} one={true} />
    </div> : null}
  </div>

)};

module.exports = PediaTrainInfoView;