import moment from 'moment';

import { vokzal_config } from '../config';

import { preProcessPostUrls, preProcessPagination } from '../utils/blog';


export const blog_fetch_posts_list = (urlWithParams, onDataLoaded, onError) => {
  const requested_at = moment();

  fetch(vokzal_config.rest.base_url + urlWithParams, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {

      if (responseJson.hasOwnProperty('error')) {
        onError(responseJson.error);
      } else {

        responseJson.pagination = preProcessPagination(responseJson.pagination)
        responseJson.posts = preProcessPostUrls(responseJson.posts)

        onDataLoaded(moment().diff(requested_at), responseJson);
      }
    });
};