import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { StationPropType } from '../../../../src/types';

import StationRowView from 'vokzal-platform/components/home/station_row/view'

import { stationUrlStoreAction } from '../../pedia/station/action';

StationRowView.propTypes = {
  stationItem : StationPropType.isRequired,
  onLinkClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({  });

const mapDispatchToProps = dispatch => {
  return {
    onLinkClick: (stationItem, event) => {
      dispatch(stationUrlStoreAction(stationItem.url))
    }
  }
};


const StationRowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StationRowView);

export default StationRowContainer