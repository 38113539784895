import React from 'react';

import SapsanCabin from '../../../../../src/components/step3/sapsan/base/sapsan_cabin';
import SapsanLuggagePlusTextCabin from '../../../../../src/components/step3/sapsan/base/sapsan_luggage_plus_text_cabin';
import SapsanWardrobeCabinWithSeats from '../../../../../src/components/step3/sapsan/base/sapsan_wardrobe_cabin_with_seats';
import SapsanChildrenCabin from '../../../../../src/components/step3/sapsan/base/sapsan_children_cabin';
import SapsanCabinDelimiter from '../../../../../src/components/step3/sapsan/base/sapsan_cabin_delimiter';
import SapsanExitDelimiter from '../../../../../src/components/step3/sapsan/base/sapsan_exit_delimiter';

import i18n from 'vokzal-platform/i18n';

const SapsanEconomPlusMapView = ({ carNumber, cabins }) => (
  <div className="train-car-select-seats-map rounded pt-2">
    <SapsanLuggagePlusTextCabin carNumber={carNumber} additionalText={"ВЫХОДА НЕТ!"} />

    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[0]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[1]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[2]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[3]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[4]} />
    <SapsanCabinDelimiter />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[5]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[6]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[7]} />
    <SapsanWardrobeCabinWithSeats carNumber={carNumber} cabinInfo1={cabins[8]} cabinInfo2={cabins[9]} />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[10]} />
    <SapsanCabinDelimiter />
    <SapsanCabin carNumber={carNumber} cabinInfo={cabins[11]} />
    <SapsanExitDelimiter />
    <SapsanChildrenCabin carNumber={carNumber} cabinInfo1={cabins[12]} cabinInfo2={cabins[13]} cabinInfo3={cabins[14]} />

    <div className="train-car-select-seats-map-block train-car-map-text sapsan-service-area rounded small d-flex align-items-center rounded m-2">
        <div className="m-auto">{i18n.t('sapsan_service_area')}</div>
    </div>

  </div>
);

module.exports = SapsanEconomPlusMapView;