import React from 'react';

import LastochkaCabinPlace from '../../../../../../src/components/step3/lastochka/base/lastochka_cabin_place';

const LastochkaLeftCabinView = ({ carNumber, cabinInfo, inSubBlock = false, extraSpace = false }) => (
  <div className={"train-car-map-seated-cabin " + ((extraSpace) ? 'pt-2 pb-2' : '')}>
    <div className="train-car-map-seated-cabin-place-block d-flex">
      { (cabinInfo.leftWindow !== null) ? <LastochkaCabinPlace carNumber={carNumber} type={cabinInfo.leftWindow.type} seatNumber={cabinInfo.leftWindow.number} isFront={cabinInfo.leftWindow.isFront} className={(inSubBlock) ? 'ml-1 mt-2 mr-2 mb-1' : 'mt-2 mb-2 mr-1 ml-2'} /> : null }
      { (cabinInfo.leftCenter !== null) ? <LastochkaCabinPlace carNumber={carNumber} type={cabinInfo.leftCenter.type} seatNumber={cabinInfo.leftCenter.number} isFront={cabinInfo.leftCenter.isFront} className={(inSubBlock) ? 'ml-1 mt-2 mr-2 mb-1' : 'mt-2 mb-2 mr-1 ml-1'} /> : null }
      { (cabinInfo.leftSide !== null)   ? <LastochkaCabinPlace carNumber={carNumber} type={cabinInfo.leftSide.type}   seatNumber={cabinInfo.leftSide.number}   isFront={cabinInfo.leftSide.isFront}   className={(inSubBlock) ? 'ml-2 mt-2 mr-2 mb-1' : 'mt-2 mb-2 mr-1 ml-1'} /> : null }
    </div>
  </div>
)
module.exports = LastochkaLeftCabinView;