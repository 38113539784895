import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import i18n from 'vokzal-platform/i18n';

import SapsanLuggagePlusTextCabinView from 'vokzal-platform/components/step3/sapsan/base/sapsan_luggage_cabin/view'

SapsanLuggagePlusTextCabinView.propTypes = {
  wardrobeTitle: PropTypes.string.isRequired,
  additionalText: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({ wardrobeTitle : i18n.t('sapsan_luggage') })


const SapsanLuggageCabinPlusTextContainer = connect(
  mapStateToProps
)(SapsanLuggagePlusTextCabinView)

export default SapsanLuggageCabinPlusTextContainer