import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import i18n from 'vokzal-platform/i18n';

import { SeatNumbersSeatedPropType } from '../../../../../types/index';

import LastochkaToiletWithSeatsView from 'vokzal-platform/components/step3/lastochka/base/lastochka_toilet_with_seats/view'

LastochkaToiletWithSeatsView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  cabinInfo1 : SeatNumbersSeatedPropType,
  cabinInfo2 : SeatNumbersSeatedPropType,
  cabinInfo3 : SeatNumbersSeatedPropType,
  autoHeightToilet: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return { autoHeightToilet : true }
}

const LastochkaToiletWithSeatsContainer = connect(
  mapStateToProps
)(LastochkaToiletWithSeatsView)

export default LastochkaToiletWithSeatsContainer