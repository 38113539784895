import React, { Component } from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types';

import AboutView from 'vokzal-platform/components/home/about/view';

AboutView.propTypes = {
  company: PropTypes.object.isRequired,
  contacts: PropTypes.object.isRequired,
  content_about: PropTypes.arrayOf(PropTypes.string).isRequired,
  content_press: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapStateToProps = state => {
    return {
      company: state.about.prefs.company,
      contacts: state.about.prefs.contacts,
      content_about: state.about.content.about,
      content_press: state.about.content.press,
    }
};

const AboutContainer = connect(
    mapStateToProps
)(AboutView);

export default AboutContainer