import React from 'react';

import i18n from 'vokzal-platform/i18n';

import { PediaPreLoader } from '../../../../src/components/shared/preloader';


import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';
import PediaHeaderImage from '../../../../src/components/pedia/header_image';
import PediaHeader from '../../../../src/components/pedia/header';

import PediaSalepointsItem from '../../../../src/components/pedia/salepoints_item';

import FooterInfo from '../../../../src/components/shared/footer_info/index';
import FooterLinks from '../../../../src/components/home/footer_links/index';

const PediaSalepointsView = ({ loaded, station_info, sale_points }) => {

  let text = [];
  if (station_info.cityName) {
    text.push(
      <div key="0">
        Станция <span className="font-weight-bold">{station_info.stationFullName}</span>
        находится в населенном пункте
        <span className="font-weight-bold">{station_info.cityName}</span>
      </div>
    );

    if (station_info.regionName) {
      text.push(
        <div key="1">
          в регионе <span className="font-weight-bold">{station_info.regionName}</span>
        </div>
      );
    }
  }

  return (
    <div className="pedia">
      <div className="pedia-block">
        <div className="pedia-header-block">
          <PediaHeaderImage />

          <HeadMenuPedia />

          <PediaHeader type='salepoint' />
        </div>

        <div className="pedia-salepoints-text p-3">
          {text}
          <div>
            Железная дорога станции - <span className="font-weight-bold">{station_info.roadName}</span>
          </div>
        </div>

        <div className="pedia-salepoints">
          <h3 className="p-3 mb-0">Кассы на железнодорожном вокзале</h3>

          {
            (sale_points)
              ?
                <div>
                  <div className="p-3">Ниже мы перечислим места, где можно оформить проездной документ для электронного жд билета, купленного через интернет.</div>
                  {sale_points.map((s, index) => (
                    <PediaSalepointsItem key={index} {...s} />
                  ))}
                </div>
              : <div className="p-3">К сожалению, у нас нет информации о точках продажи билетов на этой станции</div>
          }
        </div>

        <PediaPreLoader show={!loaded} />
      </div>

      <FooterInfo titleText={i18n.t('goodbye')} />
      <FooterLinks />
    </div>
  );
}

module.exports = PediaSalepointsView;