import React, {Fragment} from 'react';
import PediaOrderBlock from "../../../../src/components/pedia/order_block";
import { Link } from 'react-router-dom';

const PediaScheduleItemView = ({ group_name, train, showWaitTime = false, reverseDepartureArrival, onTrainClick, showTime = false, type = false }) => {
    return (<div className="row pedia-schedule mb-1 p-2 rounded m-0">
          <div className="col-sm-6 col-md-4 col-lg-3 p-0 pl-sm-2 pr-sm-2">
                {/*<div className="pedia-schedule-train-name h6 mt-1" onClick={() => onTrainClick(train)}>*/}
                <div className="pedia-schedule-train-name h6 mt-1">
                    <Link to={train.numberUrl} > {train.number} </Link>
                      {train.hasOwnProperty('nameUrl') && train.nameUrl !== '' && train.nameUrl !== 'undefined' ? <Link to={train.nameUrl}>«{train.name}»</Link> : null }
                </div>
                <div className="pedia-schedule-route font-weight-medium small">
                      {train.hasOwnProperty('raspisanieUrl') ? <a href={train.raspisanieUrl} title={train.raspisanieTitle}> {train.from.city} → {train.to.city} </a> : <Fragment>{train.from.city} → {train.to.city}</Fragment>}
                </div>
                <div className="pedia-schedule-descr small font-light">
                      {(!train.is_main_train) ? '*беспересадочный вагон' : null}
                </div>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-5 p-0 pl-sm-2 pr-sm-2">
                <div className="pedia-schedule-timers d-flex justify-content-between text-uppercase mt-3">
                      {
                            (reverseDepartureArrival)
                                ?
                                <div className="pedia-schedule-timer">
                                      <div className="small">Отправление</div>
                                      <div className="font-weight-bold text-left">{(train.departure) ? train.departure : '—' }</div>
                                      {train.hasOwnProperty('departureStationName') ? <a className="small" href={train.departureStationUrl}> {train.departureStationName} </a> : null}
                                </div>
                                :
                                <div className="pedia-schedule-timer">
                                      <div className="small">Прибытие</div>
                                      <div className="font-weight-bold">{(train.arrival) ? train.arrival : '—' }</div>
                                      {train.hasOwnProperty('arrivalStationName') ? <a className="small" href={train.arrivalStationUrl}> {train.arrivalStationName} </a> : null}
                                </div>
                      }

                      {
                            (showWaitTime)
                                ?
                                <div className="pedia-schedule-timer">
                                      <div className="small text-left">Стоянка</div>
                                      <div className="font-weight-bold text-left">{(train.wait) ? train.wait : '—' }</div>
                                </div>
                                : null
                      }

                      {
                            (reverseDepartureArrival)
                                ?
                                <div className="pedia-schedule-timer">
                                      <div className="small">Прибытие</div>
                                      <div className="font-weight-bold">{(train.arrival) ? train.arrival : '—' }</div>
                                      {train.hasOwnProperty('arrivalStationName') ? <a className="small" href={train.arrivalStationUrl}> {train.arrivalStationName} </a> : null}
                                </div>
                                :
                                <div className="pedia-schedule-timer">
                                      <div className="small">Отправление</div>
                                      <div className="font-weight-bold">{(train.departure) ? train.departure : '—' }</div>
                                      {train.hasOwnProperty('departureStationName') ? <a className="small" href={train.departureStationUrl}> {train.departureStationName} </a> : null}
                                </div>
                      }
                      {
                            (showTime)
                                ?
                                <div className="pedia-schedule-timer">
                                      <div className="small">В пути</div>
                                      <div className="schedule-timer font-weight-bold">{(train.wait) ? train.wait : '—' }</div>
                                </div>
                                : null
                      }
                </div>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 p-0 pl-sm-2 pr-sm-2">
                <PediaOrderBlock scheduleGroup={group_name} trainNumber={train.number} showOrderForm={train.showOrderForm} type={type}/>
          </div>
    </div>)
};

module.exports = PediaScheduleItemView;