// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux'
import { SearchResultBusPropType  } from '../../../../src/types';

import { step2BusesSearchCompleteAction } from '../buses_search_result/action'

import { searchFormUrlParamsBus } from '../../../../src/utils/url_params';
import { step2AddTabAction } from '../search_result_tabs/action'

import { search_buses } from '../../../../src/api/search_buses'

import BusesSearchResultAlternativeView from 'vokzal-platform/components/step2/buses_search_result_alternative/view'

import { minPriceBus } from "../../../api/price";

BusesSearchResultAlternativeView.propTypes = {
  buses: PropTypes.arrayOf(SearchResultBusPropType).isRequired,
  showPreloader: PropTypes.bool.isRequired,
};


class BusesSearchResultAlternativeContainer extends Component {

  componentDidMount() {

    if (this.props.buses.length === 0 && this.props.segments.length > 0) {
      this.props.segments.map((segment) => {
        const searchUrlParams = searchFormUrlParamsBus(segment, this.props.search_params);
        search_buses(searchUrlParams, this.props.onBusesLoaded);
      });
    }
  }

  componentDidUpdate(prevProps) {

    if (prevProps.segments !== this.props.segments || prevProps.departureDate !== this.props.departureDate) {
      this.props.segments.map((segment) => {
        const searchUrlParams = searchFormUrlParamsBus(segment, this.props.search_params);
        search_buses(searchUrlParams, this.props.onBusesLoaded);
      });
    }
  }

  render() {

    if (!this.props.show) {
      return null;
    }

    return <BusesSearchResultAlternativeView {...this.props} />;
  }
}


const mapStateToProps = state => {

  const s = state.step2_alternative.bus_search_form;
  const t = state.step2_tabs;

  const buses = state.step2_bus.buses;

  return {
    segments: s.segments,
    search_params: s,
    buses,
    departureDate : s.departureDate,
    show : t.active_tab === 'buses',
    showPreloader: !state.step2_bus.data_loaded
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onBusesLoaded : (time_spent, buses) => {
      dispatch(step2BusesSearchCompleteAction({ time_spent, buses }));

      const price = (buses.data.length > 0) ? minPriceBus(buses.data) : null;
      if (price) {
        dispatch(step2AddTabAction({ code: 'buses', price }));
      }
    }
  }
};

BusesSearchResultAlternativeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BusesSearchResultAlternativeContainer);

export default BusesSearchResultAlternativeContainer