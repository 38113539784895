import { connect } from 'react-redux';
import PropTypes from 'prop-types'


import i18n from 'vokzal-platform/i18n';
import AddDateTo from 'vokzal-platform/components/home/add_date_to/view'

AddDateTo.propTypes = {
  show: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired
};

import { show_date_to } from '../action';

const mapStateToPropsFrom = state => {
    return {
        label: i18n.t('date_to_add'),
        show: state.search.show_add_date_to
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onButtonClick: () => {
            dispatch(show_date_to())
        }
    }
};

const AddDateToContainer = connect(
    mapStateToPropsFrom,
    mapDispatchToProps
)(AddDateTo);

export default AddDateToContainer
