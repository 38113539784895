// @flow
import { handleActions, combineActions } from 'redux-actions';

import {
  HELP_POST_STORE_URL_ACTION,
  HELP_TITLE_ACTION,
  HELP_POST_STORE_ACTION,
  SHOW_PRELOADER_ACTION, HIDE_PRELOADER_ACTION
} from './action';

import { helpInitialStatePlatform } from 'vokzal-platform/components/help/reducer';

let helpInitialState = {
  error: null,
  data: null,
  url: "/help/",
  data_loaded: false,
}
helpInitialState = Object.assign({}, helpInitialState, helpInitialStatePlatform())

const HelpSinglePostReducer = handleActions({
  [HELP_POST_STORE_URL_ACTION]: (state, action) => {
    return { ...state, url: "/help/" + action.payload, data_loaded: false }
  },
  [HELP_TITLE_ACTION]: (state, action) => {
    return { ...state, data: action.payload }
  },
  [HELP_POST_STORE_ACTION]: (state, action) => {
    return { ...state, data_loaded: true, ...action.payload }
  },
  [combineActions(SHOW_PRELOADER_ACTION, HIDE_PRELOADER_ACTION)]: (state, action) => {
    return { ...state, show: action.meta.show }
  },
}, helpInitialState)

export default HelpSinglePostReducer

