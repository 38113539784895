// @flow
import PropTypes from 'prop-types';

import BusSeatsRowView from 'vokzal-platform/components/step3bus/bus_seats_row/view'

BusSeatsRowView.propTypes = {
  seats : PropTypes.array.isRequired
};

const BusSeatsRow = BusSeatsRowView

export default BusSeatsRow