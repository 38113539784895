import React from 'react';

const TicketReturnInfoView = ({ ticketsReturnText }) => (
    <div className="return-ticket-info mb-4">

      <div className="return-ticket-text h1 font-weight-light m-3">
        {ticketsReturnText}
      </div>

      <div className="page-section rounded m-2 mt-4 p-2">
        <div className="font-weight-medium">
          Вы можете воспользоваться формой возврата, если:
          <ul>
            <li>заказ сделан на сайте vokzal.ru</li>
            <li>заказ оплачен банковской картой Visa или MasterCard</li>
          </ul>
        </div>

        <p>Во всех остальных случаях необходимо сначала получить бланки оригиналов билетов в кассах или в терминалах самообслуживания на вокзалах, а затем сдать их в кассу возврата.</p>

        <p>
          Подробную информацию о правилах возврата электронных билетов вы можете прочитать
          <a href="https://vokzal.ru/help/info/return">здесь</a>.
        </p>

      </div>
    </div>
);

module.exports = TicketReturnInfoView;