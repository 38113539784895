import React from 'react';
import moment from 'moment';

import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';
import { PediaPreLoader } from '../../../../src/components/shared/preloader';
import {documentTypes} from "../../../../src/utils/passengers";
import  Menu from '../../../../src/components/user/menu';

const Passengers = ({title, loaded, passengers, onDeletePassenger}) => (
    <div className="profile">
        <div className="pedia">
            <div className="pedia-block">
                <div className="pedia-header-block">
                    <HeadMenuPedia />
                    <Menu active="passengers"/>
                    <h3>Пассажиры</h3>
                </div>
            </div>

            {loaded && passengers ?
                <div>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <tbody>
                            {
                                passengers.map(function(p, index){
                                    return (
                                        <tr key={index}>
                                            <td>{p.name} {p.middlename} {p.surname}</td>
                                            <td>
                                                <div>Дата рождения</div>
                                                <div>{p.birthdate ? moment(p.birthdate).format('DD.MM.YYYY') : null}</div>
                                            </td>
                                            <td>
                                                <div>{documentTypes[p.document_type]}</div>
                                                <div>{p.document_number}</div>
                                            </td>
                                            <td><a href="#" onClick={() => onDeletePassenger(p.id, p.document_number)}>Удалить пассажира</a></td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>

                    </div>
                </div> : null}
        </div>

        <PediaPreLoader show={!loaded} />
    </div>
);

module.exports = Passengers;