import React from 'react';

import HeadMenu from '../../../../src/components/shared/menu';

import StepInfo from '../../../../src/components/shared/step_info';
import DatesSelect from '../../../../src/components/step2/dates_select';
import SearchResultTabs from '../../../../src/components/step2/search_result_tabs';

import TrainsSearchResultAlternative from '../../../../src/components/step2/trains_search_result_alternative';
import BusesSearchResultAlternative from '../../../../src/components/step2/buses_search_result_alternative';

import TrainDetails from '../../../../src/components/step2/train_details';

const Step2AlternativeView = ({  }) => (
  <div className="step2 gradient-background">
      <HeadMenu stepNumber={2} />

      <StepInfo  stepNumber={2}/>

      <DatesSelect />

      <SearchResultTabs />

      <TrainsSearchResultAlternative />
      <BusesSearchResultAlternative />

      <TrainDetails />
  </div>
);

module.exports = Step2AlternativeView;