import React, {lazy, Suspense} from 'react';

import i18n from 'vokzal-platform/i18n';

import { PediaPreLoader } from '../../../../src/components/shared/preloader';

import ContentTreeRow from "../../../../src/components/shared/content_tree_row";

import HeadMenuPedia from '../../../../src/components/shared/menu_pedia';

import PediaStationsListGroup from '../../../../src/components/pedia/stations_list_group';

import SearchForm from '../../../../src/components/shared/search_form';

const News = lazy(() => import('../../../../src/components/home/news/index'));
const FooterLinks = lazy(() => import('../../../../src/components/home/footer_links/index'));
const FooterInfo = lazy(() => import('../../../../src/components/shared/footer_info/index'));

const PediaStationsListView = ({ loaded, title, groups, onExpandClick, onLinkClick }) => {
    return (
        <div className="pedia-stations">
            <div className="pedia-block content-tree">
              <div className="pedia-header-block">

                <HeadMenuPedia />

                <div className={"pedia-header-short d-flex align-items-start flex-column ml-3 mr-3 pedia-header-dark mt-4"}>
                  <h1 className="h4 mb-2 font-medium-light font-weight-normal text-uppercase">{title}</h1>
                </div>
                  <SearchForm />
              </div>
              {
                groups.map((group, index) => (
                  <ContentTreeRow key={index} title={group.title} isExpanded={group.isExpanded} onExpandClick={() => onExpandClick(group.title)}>
                    <PediaStationsListGroup groups={group.subgroups} onLinkClick={onLinkClick} />
                  </ContentTreeRow>
                ))
              }
              <PediaPreLoader show={!loaded} />
            </div>

            <Suspense fallback={<div>Загрузка...</div>}>
                <FooterInfo titleText={i18n.t('goodbye')} />
            </Suspense>

            <Suspense fallback={<div>Загрузка...</div>}>
                <FooterLinks />
            </Suspense>

            <Suspense fallback={<div>Загрузка...</div>}>
                <News />
            </Suspense>
        </div>
)};

module.exports = PediaStationsListView;