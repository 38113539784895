// @flow
import { handleActions } from 'redux-actions'
import {
  SHOW_STATION_MODAL_ACTION,
  HIDE_STATION_MODAL_ACTION,
  SEARCH_STATION_REQUEST_ACTION,
  SEARCH_STATION_RESPONSE_ACTION,
  LOCAL_STATION_REQUEST_ACTION,
  LOCAL_STATION_RESPONSE_ACTION,
  LOCAL_SEARCH_STATION_ACTION
} from './action'

import { PopularStationsFrom } from '../../../../data/popular_stations_from_full'

import { StationEmpty } from '../../../types/index'

const initialState = {
  show: false,
  selected_station: StationEmpty,
  popular_stations: PopularStationsFrom.slice(0, 100),
  stations: [],
  selected_input: '',
  input_value: '',
  initial_value: '',
  timer_id: null
}

const StationModalReducer = handleActions({
  [SHOW_STATION_MODAL_ACTION]: (state, action) => {
    const input_value = action.payload.station.station_name

    let show_stations = state.stations;
    if (show_stations.length === 0) {
      // show_stations = state.popular_stations
      //   .map( (item) => ( item.to) )
      //   .filter( ((item, index, array) => ( array.indexOf(item, index + 1) < 0 ) ) )
      //   .map( (item) => (Stations[item]) )
      show_stations = state.popular_stations;
    }

    return { ...state, ...action.payload, show: action.meta.show, input_value, stations: show_stations};
  },
  [HIDE_STATION_MODAL_ACTION]: (state, action) => {
    return { ...state, show: action.meta.show }
  },
  [SEARCH_STATION_REQUEST_ACTION]: (state, action) => {
    return { ...state, ...action.payload }
  },
  [SEARCH_STATION_RESPONSE_ACTION]: (state, action) => {
    const search_stations = (action.payload.input_value !== '') ? action.payload.stations : PopularStationsFrom.slice(0, 100)
    return { ...state, ...action.payload, stations: search_stations }
  },
  [LOCAL_STATION_REQUEST_ACTION]: (state, action) => {
    return { ...state, ...action.payload }
  },
  [LOCAL_STATION_RESPONSE_ACTION]: (state, action) => {
    const search_stations = action.payload.stations;

    return { ...state, ...action.payload, stations: search_stations, local_stations: search_stations }
  },
  [LOCAL_SEARCH_STATION_ACTION]: (state, action) => {

    const input_value = action.payload;
    const search_stations = (input_value !== '') ? state.local_stations.filter(item => item.station_name.indexOf(input_value) === 0) : state.local_stations
    return { ...state, ...action.payload, stations: search_stations }
  },
}, initialState)

export default StationModalReducer