import React from 'react';

import { vokzal_config } from '../../../../src/config';

import Calendar from 'react-widgets/lib/Calendar'

 import 'react-widgets/dist/css/react-widgets.css';
// import '../../../css/react-widgets.css';


import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

moment.locale('ru');
momentLocalizer();


class DatesModalMonth extends React.Component {
    constructor(props) {
        super(props);

        this.state = { selectedDate : props.selectedDate };

        this.classNames = { weekend : 'weekend', today : 'today', active : 'active', inactive : 'inactive' };

        this.today = moment();
        this.maxDate = moment(this.today).add(vokzal_config.dates.max, 'day');

        this.startDate = moment().add(props.offset, 'month').startOf('month');
        this.endDate = moment(this.startDate).endOf('month');

        this.monthTitle = this.startDate.format('MMMM');
        this.year = this.startDate.format('YYYY');

        this.dayComponent = this.dayComponent.bind(this);
        this.onCurrentDateChange = this.onCurrentDateChange.bind(this);
    }

    dayComponent({date, label}) {

        let className = 'day-cell p-1 ';
        if (moment(date).isoWeekday() > 5) {
            className += this.classNames.weekend + ' ';
        }

        if (moment(date).isBefore(this.today, 'day') || moment(date).isAfter(this.maxDate, 'day')) {
            className += this.classNames.inactive + ' ';
        }

        let day = '';
        if (moment(date).isBetween(this.startDate, this.endDate, null, '[]')) {
            day = label;

            if (moment(date).isSame(this.today, 'day')) {
                className += this.classNames.today + ' ';
            }

            if (moment(date).isSame(this.state.selectedDate, 'day')) {
                className += this.classNames.active + ' ';
            }
        }

        return (
            <span className={className}>
                {day}
            </span>
        );
    }

    onCurrentDateChange(selectedDate) {

        if (moment(selectedDate).isBefore(this.today, 'day') || moment(selectedDate).isAfter(this.maxDate, 'day')) {
            return;
        }

        this.setState({selectedDate});
        this.props.handleClick(selectedDate);
    }

    render() {

        return (
            <div className="month-details-item list-group-item p-0 pb-3" data-state={this.props}>
                <div className="month-details-title">
                    <span className="month-title">{this.monthTitle}</span>
                    <span className="year-title ml-1">{this.year}</span>
                </div>

                <Calendar
                    min={this.startDate.toDate()}
                    max={this.endDate.toDate()}
                    footer={false}
                    dateFormat={dt => String(dt.getDate())}
                    dayComponent={this.dayComponent}
                    currentDate={this.startDate.toDate()}
                    onCurrentDateChange={() => {}}
                    onChange={this.onCurrentDateChange}
                />
            </div>
        );
    }
}

module.exports = DatesModalMonth;