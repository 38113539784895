import React from 'react';

import i18n from 'vokzal-platform/i18n';

import BusSeatsRow from '../../../../src/components/step3bus/bus_seats_row';

const BusMapView = ({ seatsGroups }) => (
  <div>
    <div className="p-2">
      <div className="section-title mb-2">{i18n.t('select_seats')}</div>
    </div>

    <div className="bus-select-seats-map-block rounded m-2 pt-4">
      <div className="font-other-light text-center small">{i18n.t('bus_map_notification')}</div>

      {seatsGroups.map( (seats, index) => (
        <BusSeatsRow key={index} seats={seats} />
      ))}
    </div>
  </div>
);

module.exports = BusMapView;