import React from 'react';

import LastochkaCabinPlace from '../../../../../../src/components/step3/lastochka/base/lastochka_cabin_place';

const LastochkaRightCabinView = ({ carNumber, cabinInfo, inSubBlock = false, extraSpace = false }) => (
  <div className={"train-car-map-seated-cabin train-car-select-seats-seated-map-block " + ((extraSpace) ? 'pt-2 pb-2' : '')}>
    <div className="train-car-map-seated-cabin-place-block d-flex justify-content-end">
      { (cabinInfo.rightSide !== null)   ? <LastochkaCabinPlace carNumber={carNumber} type={cabinInfo.rightSide.type}   seatNumber={cabinInfo.rightSide.number}   isFront={cabinInfo.rightSide.isFront}   className={(inSubBlock) ? 'ml-2 mt-2 mr-2 mb-1' : 'mt-2 mb-2 mr-1 ml-1'} /> : null }
      { (cabinInfo.rightCenter !== null) ? <LastochkaCabinPlace carNumber={carNumber} type={cabinInfo.rightCenter.type} seatNumber={cabinInfo.rightCenter.number} isFront={cabinInfo.rightCenter.isFront} className={(inSubBlock) ? 'ml-2 mt-2 mr-2 mb-1' : 'mt-2 mb-2 mr-1 ml-1'} /> : null }
      { (cabinInfo.rightWindow !== null) ? <LastochkaCabinPlace carNumber={carNumber} type={cabinInfo.rightWindow.type} seatNumber={cabinInfo.rightWindow.number} isFront={cabinInfo.rightWindow.isFront} className={(inSubBlock) ? 'ml-2 mt-2 mr-1 mb-1' : 'mt-2 mb-2 mr-2 ml-1'} /> : null }
    </div>
  </div>
)
module.exports = LastochkaRightCabinView;