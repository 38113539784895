import moment from 'moment';

import { vokzal_config } from '../config';

export const blog_fetch_post = (urlWithParams, onDataLoaded, onError) => {
  const requested_at = moment();

  fetch(vokzal_config.rest.base_url + urlWithParams, vokzal_config.rest.fetchParams)
    .then((response) => response.json())
    .then((responseJson) => {

      if (responseJson.hasOwnProperty('error')) {
        onError(responseJson.error);
      } else {
        onDataLoaded(moment().diff(requested_at), responseJson);
      }
    });
};