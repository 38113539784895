import React from 'react';

import labels from 'vokzal-platform/i18n';

import PageSection from '../base/section/view';
import SectionTitle from '../base/section_title/view';
import TrainRow from '../train_row/view';

const TrainsView = ({ show, items }) => {

  if (!show) {
    return null;
  }

  return (
        <PageSection className="trains">
            <SectionTitle>{labels.trains}</SectionTitle>

            <div className="d-flex flex-wrap no-gutters">
                {
                    items.map(function(m, index){
                        return (<TrainRow key={index} trainItem={m} />);
                    })
                }
            </div>
        </PageSection>
    );
}
module.exports = TrainsView;