import React from 'react';
import i18n from "../../../i18n";
import { Link } from 'react-router-dom';

const HeadMenuStepNumber = ({ children }) => (
  <span className="step-number rounded-circle float-right mt-3 mr-3 p-2 d-block d-md-none">{children}</span>
);

const HeadMenuView = ({ showMenu, items, stepNumber, onMenuClick }) => {

  if (!showMenu) {
    return null;
  }

  return (
    <div className="row menu ml-0 mr-0">
      <nav className={"col-lg-10 col-xl-9" +
      " navbar navbar-expand-md navbar-light bg-light rounded-right " + ((stepNumber > 0) ? 'd-sm-inline-flex w-sm-65' : '')}>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>

        {/*<Link className="navbar-brand" to="/"><img src="/img/logo.gif" alt="logo" width="308" height="37" className="img-fluid logo"/></Link>*/}
        <a className="navbar-brand" href="/"><img src="/img/logo.gif" alt="logo" width="308" height="37" className="img-fluid logo"/></a>

        <div className="collapse navbar-collapse pl-3" id="navbarsExampleDefault">
          <ul className="navbar-nav mr-auto">
            {items.map((menuItem, index) => (
              <li key={index} className="nav-item" onClick={() => { onMenuClick(menuItem) }}>
                <a className="nav-link" href={menuItem.url}>{menuItem.title}</a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
      { (stepNumber === 0) ? <div className="col-lg-2 col-xl-3 d-none d-lg-block d-xl-block pl-lg-1 support"><div className="row"><div className="col-xl-6 text">{i18n.t('support')} </div><div className="col-xl-6 phone">{i18n.t('phone_min')}</div></div></div> : ''}
      { (stepNumber > 0) ? <HeadMenuStepNumber>{stepNumber}</HeadMenuStepNumber> : ''}
    </div>
  )
};

module.exports = HeadMenuView;