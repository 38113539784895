// @flow
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'vokzal-platform/i18n'

import SearchButtonView from 'vokzal-platform/components/home/search_button/view'

import { showStep2Plain} from 'vokzal-platform/managers/navigation'

import { searchFormUrlParamsTrain, raspisanieUrlParams } from '../../../utils/url_params'
import { perform_search } from './action'
import { step2TrainsSearchCleanupAction } from '../../step2/trains_search_result/action'
import { step2BusesSearchCleanupAction } from '../../step2/buses_search_result/action'
import { showPreloaderAction } from '../../step2/action'
import { step2TabClickAction } from '../../step2/search_result_tabs/action'
import { StationEmpty, SearchFormPropType } from "../../../types";
import {setStep2Action} from "../search_form/action";

import {setDateFromAction, setStationFromAction, setStationToAction} from '../search_form/action';

SearchButtonView.propTypes = {
  urlParams: PropTypes.string.isRequired,
  searchFormState: SearchFormPropType.isRequired,
  onSearchButtonClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    urlParams: searchFormUrlParamsTrain(state.search),
    searchFormState: state.search,
    title: i18n.t('search_button')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSearchButtonClick: (searchFormState, urlParams) => {
      if ((searchFormState.from === StationEmpty && searchFormState.from_info === StationEmpty) || (searchFormState.to === StationEmpty && searchFormState.to_info === StationEmpty)) {
        return;
      }

      dispatch(setStationFromAction(searchFormState.from_info));
      dispatch(setStationToAction(searchFormState.to_info));
      dispatch(setDateFromAction(searchFormState.date_from_info));

      showStep2Plain(dispatch, urlParams);
      dispatch(setStep2Action({ step2: urlParams }));

      dispatch(step2TrainsSearchCleanupAction());
      dispatch(step2BusesSearchCleanupAction());
      dispatch(showPreloaderAction());
      dispatch(step2TabClickAction('trains'));
      dispatch(perform_search(searchFormState));
    }
  }
}

const SearchButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchButtonView)

export default SearchButtonContainer
