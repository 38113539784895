import React from 'react';
import TrainInfo from '../../../../src/components/step2/train_info';

import TrainPrices from '../../../../src/components/step2/train_prices';
import SapsanPrices from '../../../../src/components/step2/sapsan_prices';

const TrainRowView = ({ trainItem, uiFlags, onTrainClick }) => {
  const baseCss = "train-additional-item train-additional-";

  let cssClass = ((trainItem.sapsan === 1) ? 'sapsan' : 'usual');
  cssClass += (typeof(uiFlags) !== 'undefined' && uiFlags.fastest) ? " train-fastest" : '';
  cssClass += (typeof(uiFlags) !== 'undefined' && uiFlags.cheapest) ? " train-cheapest" : '';

  let preCustomUI = [];
  if (typeof(uiFlags) !== 'undefined' && uiFlags.fastest) {
    preCustomUI.push((
      <div key={0} className={baseCss + "fastest"}>Самый быстрый</div>
    ));
  }

  if (typeof(uiFlags) !== 'undefined' && uiFlags.cheapest) {
    preCustomUI.push((
      <div key={1} className={baseCss + "cheapest"}>Лучшая цена</div>
    ));
  }

  if (typeof(uiFlags) !== 'undefined' && uiFlags.cheap) {
    preCustomUI.push((
      <div key={2} className={baseCss + "cheap"}>Дешёвый</div>
    ));
  }

  if (typeof(uiFlags) !== 'undefined' && uiFlags.fast) {
    preCustomUI.push((
      <div key={3} className={baseCss + "fast"}>Быстрый</div>
    ));
  }

  if (typeof(uiFlags) !== 'undefined' && uiFlags.min_seats) {
    preCustomUI.push((
      <div key={4} className={baseCss + "min-seats"}>Мало мест</div>
    ));
  }

  let customUI = null;
  if (preCustomUI.length > 0) {
    customUI = (
      <div className="train-additional small d-flex">{preCustomUI}</div>
    )
    cssClass += " card-extra-flags";
  }

  return (
    <div className={"row train-row " + cssClass} onClick={() => onTrainClick(trainItem)}>
      <TrainInfo trainItem={trainItem} customUI={customUI}/>
      {
        (trainItem.sapsan === 1)
          ? <SapsanPrices priceItems={trainItem.prices} trainItem={trainItem}/>
          : <TrainPrices priceItems={trainItem.prices} trainItem={trainItem}/>
      }
    </div>
  )
}

module.exports = TrainRowView;