
export const stationInitialStatePlatform = () => {
  let initialState = {}

  if (typeof pedia_station_data !== 'undefined') {
    initialState.currentTab = 'info';

    initialState.loaded = true;

    initialState.station_id = pedia_station_data.station_id;
    initialState.title = pedia_station_data.title;
    initialState.subTitle = pedia_station_data.subTitle;
    initialState.preview = pedia_station_data.preview;
    initialState.description = pedia_station_data.description;
    initialState.coords = pedia_station_data.coords;

    if (pedia_station_data.image) {
      initialState.image = pedia_station_data.image;
    }
  }

  return initialState;
}