export const minPrice = (priceItems) => {
  let min_class = Object.keys(priceItems)[0]
  Object.keys(priceItems).map((key) => {
      if (priceItems[min_class].price > priceItems[key].price) {
          min_class = key
      }
      return key
  })

  return priceItems[min_class]
}

export const minPriceBus = (buses) => {
  return buses.map((bus) => (bus.price)).reduce((a, b) => ((a > b) ? b : a))
}

export const minSeats = (priceItems) => {
  let min_class = Object.keys(priceItems)[0]
  const seats = s => {
    return parseInt(s.split(' ')[0]);
  }

  Object.keys(priceItems).map((key) => {
    if (seats(priceItems[min_class].seats) > seats(priceItems[key].seats)) {
      min_class = key
    }
    return key
  })

  return seats(priceItems[min_class].seats)
}