import React from 'react';

import BusSeat from '../../../../src/components/step3bus/bus_seat';

const BusSeatsRowView = ({ seats }) => (
  <div className="bus-seats-row m-2 d-flex justify-content-between">
    {seats.map( (seat, index) => (
          <BusSeat key={index} seatNumber={seat} />
        )
     )}
  </div>
);

module.exports = BusSeatsRowView;