import React from 'react';
import PropTypes from 'prop-types';

const CabinPlaceView = ({ carNumber, seatNumber, type, isUpper, state, priceDiapason, onSeatClick }) => {

  // console.log(' 111*** ', carNumber, seatNumber, type, isUpper, state);

  let className = (isUpper) ? 'train-car-map-place-front' : 'train-car-map-place-back';
  className += (type === 'side') ? ' text-center' : ' pl-2';
  className += (type !== 'side') ? ' cabin-place-' + type : '';
  className += (state === 'busy' || state === 'selected') ? ' seat-' + state : '';
  className += (state === 'unavailable') ? ' seat-busy' : '';

  let seatInfo = seatNumber;

  if (state === 'busy') {
    seatInfo = <div className="car-state-icon  car-state-icon-busy" />;
  } else if (state === 'selected') {
    seatInfo = <div className="car-state-icon  car-state-icon-selected" />;
  }

  let dt = '';
  if (state !== 'busy' && priceDiapason.hasOwnProperty('upper') && priceDiapason.hasOwnProperty('lower')) {
      if (isUpper) {
          dt = priceDiapason.upper +  " ₽, верхнее"
      } else {
          dt = priceDiapason.lower + " ₽, нижнее"
      }
  } else {
      className += ' after-no';
  }

  return (
    <div className={"train-car-map-place rounded pt-2 " + className} data-title={dt} onClick={() => onSeatClick(carNumber, seatNumber, state, isUpper) }>
      <div className="train-car-map-place-title font-weight-medium small">
        {seatInfo}
      </div>
    </div>
  );
};

CabinPlaceView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  seatNumber: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['side', 'center-top', 'center-bottom', 'sv-top', 'sv-bottom']).isRequired,
  isUpper: PropTypes.bool.isRequired,
  state: PropTypes.oneOf(['available', 'unavailable', 'busy', 'selected']).isRequired,
  onSeatClick: PropTypes.func.isRequired,
};

module.exports = CabinPlaceView;