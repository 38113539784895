import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import i18n from 'vokzal-platform/i18n';
import { SeatNumbersSvPropType } from '../../../../src/types/';
import SvCabin from '../../../../src/components/step3/sv_cabin';
import {getSexInfo} from "../../../../src/utils/functions";

const SvMapView = ({ carNumber, cabins, carSelected, kupeNumber, kupeMF, selectedFloor }) => {
    let sexInfo;
    const twoStorey = carSelected.twoStorey;

    return (
    <div>
        {twoStorey ?  <Fragment>
                <div className="floor-title second-floor">{i18n.t('second_floor')}</div>

                <div className="train-car-select-seats-map-horizontal row">
                    <div className="info">
                        <p className="toilet">{i18n.t('toilet')}</p>
                        <p className="conductor">
                            {i18n.t('conductor_place').replace(" ", "\n")}
                        </p>
                    </div>
                    {
                        cabins.map((cabin, index) => {
                            if (index > 7) {
                                return null;
                            }
                            sexInfo = '';
                            if(carSelected.female > 0) {
                                sexInfo = kupeNumber === index && kupeMF && selectedFloor === 1 ? kupeMF : getSexInfo(carSelected, cabin, 1);
                            }
                            return (
                                <SvCabin key={index} carNumber={carNumber} title={index} cabinInfo={cabin} sexInfo={sexInfo} secondFloor={twoStorey} twoStorey={twoStorey}/>
                            )})
                    }
                    <div className="info">
                        <p className="toilet">{i18n.t('toilet')}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="korridor">
                        <div>{i18n.t('korridor')}</div>
                    </div>
                </div>

                <div className="train-car-select-seats-map rounded p-2 d-block d-lg-none">
                    <div className="train-car-map-service-block d-flex align-items-end flex-column">
                        <div className="train-car-select-seats-map-block train-car-map-text train-car-map-toilet rounded small text-center w-40 p-1 mb-2">{i18n.t('toilet')}</div>
                        <div className="train-car-select-seats-map-block train-car-map-text train-car-map-conductor-place rounded small text-capitalize text-center w-60 mb-2 p-1">
                            {i18n.t('conductor_place').replace(" ", "\n")}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between">
                        <div className="train-car-select-seats-map-block train-car-map-text train-car-map-korridor rounded small text-center w-25 mr-2 mb-2">
                            <div className="train-car-map-korridor-label">{i18n.t('korridor')}</div>
                        </div>

                        <div className="w-75">
                            {
                                cabins.map((cabin, index) => {
                                    if (index > 7) {
                                        return null;
                                    }
                                    sexInfo = '';
                                    if(carSelected.female > 0) {
                                        sexInfo = kupeNumber === index && kupeMF && selectedFloor === 1 ? kupeMF : getSexInfo(carSelected, cabin, 1);
                                    }
                                    return(
                                        <SvCabin key={index} carNumber={carNumber} title={index} cabinInfo={cabin} sexInfo={sexInfo} secondFloor={twoStorey} twoStorey={twoStorey}/>
                                    )})
                            }
                        </div>

                    </div>

                    <div className="train-car-map-service-block-bottom d-flex justify-content-between">
                        <div className="train-car-select-seats-map-block train-car-map-text train-car-map-smoking-area rounded small text-center w-50 pt-3 pl-4 pr-4 pb-3">{i18n.t('smoking_area')}</div>
                        <div className="train-car-select-seats-map-block train-car-map-text train-car-map-toilet rounded small text-capitalize text-center w-35">
                            {i18n.t('toilet')}
                        </div>
                    </div>
                </div>

            </Fragment>
            : ''}

        {twoStorey ? <div className="floor-title first-floor">{i18n.t('first_floor')}</div> : ''}
        <div className="train-car-select-seats-map-horizontal row">
            <div className="info">
                <p className="toilet">{i18n.t('toilet')}</p>
                <p className="conductor">
                    {i18n.t('conductor_place').replace(" ", "\n")}
                </p>
            </div>
            {
                cabins.map((cabin, index) => {
                    if (twoStorey && index > 7) {
                        return null;
                    }

                    sexInfo = '';
                    if(carSelected.female > 0) {
                        sexInfo = kupeNumber === index && kupeMF && !selectedFloor ? kupeMF : getSexInfo(carSelected, cabin);
                    }

                    return (
                        <SvCabin key={index} carNumber={carNumber} title={index} cabinInfo={cabin} sexInfo={sexInfo} twoStorey={twoStorey}/>
                )})
            }
            <div className="info">
                <p className="toilet">{i18n.t('toilet')}</p>
            </div>
        </div>
        <div className="row">
            <div className="korridor">
                <div>{i18n.t('korridor')}</div>
            </div>
        </div>

        <div className="train-car-select-seats-map rounded p-2 d-block d-lg-none">
            <div className="train-car-map-service-block d-flex align-items-end flex-column">
                <div className="train-car-select-seats-map-block train-car-map-text train-car-map-toilet rounded small text-center w-40 p-1 mb-2">{i18n.t('toilet')}</div>
                <div className="train-car-select-seats-map-block train-car-map-text train-car-map-conductor-place rounded small text-capitalize text-center w-60 mb-2 p-1">
                    {i18n.t('conductor_place').replace(" ", "\n")}
                </div>
            </div>

            <div className="d-flex justify-content-between">
                <div className="train-car-select-seats-map-block train-car-map-text train-car-map-korridor rounded small text-center w-25 mr-2 mb-2">
                    <div className="train-car-map-korridor-label">{i18n.t('korridor')}</div>
                </div>

                <div className="w-75">
                    {
                        cabins.map((cabin, index) => {
                            if (twoStorey && index > 7) {
                                return null;
                            }

                            sexInfo = '';
                            if(carSelected.female > 0) {
                                sexInfo = kupeNumber === index && kupeMF && !selectedFloor ? kupeMF : getSexInfo(carSelected, cabin);
                            }

                            return(
                                <SvCabin key={index} carNumber={carNumber} title={index} cabinInfo={cabin} sexInfo={sexInfo} twoStorey={twoStorey}/>
                        )})
                    }
                </div>

            </div>

            <div className="train-car-map-service-block-bottom d-flex justify-content-between">
                <div className="train-car-select-seats-map-block train-car-map-text train-car-map-smoking-area rounded small text-center w-50 pt-3 pl-4 pr-4 pb-3 invisible">{i18n.t('smoking_area')}</div>
                <div className="train-car-select-seats-map-block train-car-map-text train-car-map-toilet rounded small text-capitalize text-center w-35">
                    {i18n.t('toilet')}
                </div>
            </div>
        </div>
    </div>

)};

SvMapView.propTypes = {
  carNumber: PropTypes.number.isRequired,
  cabins : PropTypes.arrayOf(
    SeatNumbersSvPropType
  ).isRequired
};

module.exports = SvMapView;