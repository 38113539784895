import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { helpStoreUrlAction, helpTitleAction } from '../action';

import { HelpPostListItemPropType } from '../../../../src/types/';


import HelpPostView from 'vokzal-platform/components/help/post/view'

HelpPostView.propTypes = {
  title : PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  items : PropTypes.arrayOf(HelpPostListItemPropType),
  onPostClick : PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = dispatch => (
  {
    onPostClick: (permalink, title, event) => {

      dispatch(helpStoreUrlAction(permalink));
      dispatch(helpTitleAction({ title }));
      },

  }
);


const HelpPostContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpPostView);

export default HelpPostContainer